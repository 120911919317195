// @flow
import React from 'react';
import { Platform } from 'react-native';
import { View, Button, Image, Card, Alert, Link, Text } from 'components/core';
import { Icon } from 'components/common';
import { useDispatch } from 'libs/graphql';
import { createDict, Trans, useIntl, CurrencyNumber } from 'libs/intl';
import { useToggleState, Domain, useAlerts } from 'libs/misc';
import { useNavigate, useLocation } from 'libs/routing';
import { StyleSheet, css, useMedia } from 'libs/ui';
import RegisterOrLoginModal from 'views/_shared/RegisterOrLoginModal';
import OrganizationLicensePurchaseModal from 'views/_shared/OrganizationLicensePurchaseModal';
import UserLicensePurchaseModal from './UserLicensePurchaseModal';
import { createCourseMembership } from './mutations';

type Props = {
  course: Entity<GQLCourse>,
  viewer: ?Entity<GQLUser>,
  domain: Entity<GQLDomain>,
};

const styles = StyleSheet.create({
  logo: css`
    border-radius: 0.25rem;
    margin-horizontal: 0.25rem;
  `,
  visaLogo: css`
    height: 20px;
    width: 31px;
  `,
  mastercardLogo: css`
    height: 20px;
    width: 32px;
  `,
  sepaLogo: css`
    height: 20px;
    width: 50px;
  `,
  klarnaLogo: css`
    height: 20px;
    width: 47px;
  `,
  priceText: css`
    font-weight: bold;
    font-size: 2rem;
    font-family: Titillium Web;
    line-height: 2.5rem;
  `,
  columnDesktop: css`
    min-width: 50%;
    flex-direction: column;
    flex-shrink: 1;
  `,
  orderSummaryBoxDesktop: css`
    max-width: 23.5rem;
    margin-left: 1rem;
  `,
  icon: css`
    width: 1.5rem;
  `,
});

const dict = createDict({
  get_access: 'Get access',
  view_plans: 'View plans',
  buy_licenses: 'Buy Licenses',
  prices_start_at: 'Prices start at',
  vat_incl: 'VAT incl.',
  plus_vat: 'plus VAT',
  no_subscriptions_no_hidden_fees: 'No subscriptions, no hidden fees!',
  this_is_waiting_for_you: 'This is waiting for you:',
  exams: '{count} {count, plural, =1 {Exam} other {Exams}}',
  flashcard_sets: '{count} {count, plural, =1 {Flashcard set} other {Flashcard sets}}',
  videos: '{count} {count, plural, =1 {Video} other {Videos}}',
  articles: '{count} {count, plural, =1 {Article} other {Articles}}',
  documents: '{count} {count, plural, =1 {Document} other {Documents}}',
  results_learning_analytics: 'Results and learning analytics to track your success',
  all_tools_to_communicate_with_other_course_members:
    'All tools to communicate with other course members',
  you_need_a_license_for_this_course_bought_by_organization:
    'You need a license for this course. Your trainer or administrator can buy a license for you.',
  join_course: 'Join Course',
  course_paid_message_for_trial_trainee:
    'This course is a paid course. You can join the course because you are currently on a free trial.',
  course_paid_message_for_trial_trainer:
    'This course is a paid course. You and your trainees can join the course because you are currently on a free trial.',
  you_are_now_a_member_of_this_course: 'You are now a member of this course.',
  exam_mode_and_quiz_mode: 'Exam mode and quiz mode',
  successfully_joined_the_course: 'Successfully joined the course.',
  this_course_is_not_included_in_your_free_trial: 'This course is not included in your free trial.',
  you_need_to: 'You need to',
  register: 'register',
  or: 'or',
  login: 'log in',
  to_buy_this_course: 'to buy this course.',
  this_course_is_included_in_the_offer: 'This course is included in the offer',
  and_others: 'and others',
  professional_support_from_our_azubinet_team: 'Professional support from our Azubinet team',
  please_register_or_log_in_to_buy_this_product: 'Please register or log in to buy this product.',
});

const getPricings = (products, business) => {
  const pricings = [];

  products.forEach((product) => {
    product.offers.forEach((offer) => {
      if (business) {
        offer.businessPrices.forEach((pricing) => {
          pricings.push(pricing);
        });
      } else if (offer.price) {
        pricings.push(offer.price);
      }
    });
  });

  return pricings;
};

const getLowestPricing = (pricings) => {
  if (pricings.length === 0) {
    return null;
  }

  return pricings.reduce((a, b) =>
    Math.min(a.price.amount, b.price.amount) === a.price.amount ? a : b,
  );
};

function PaidAccess({ course, viewer, domain }: Props): React$Node {
  const buyCourseLicenseModal = useToggleState(false);
  const guestModal = useToggleState(false);
  const dispatch = useDispatch();
  const media = useMedia();
  const intl = useIntl();

  const navigate = useNavigate();
  const location = useLocation();
  const alerts = useAlerts();

  const viewerIsOrganizationAdminOrTrainer = viewer && viewer.isOrganizationAdminOrTrainer;

  const pricings = getPricings(course.products, viewerIsOrganizationAdminOrTrainer);
  const lowestPricing = getLowestPricing(pricings);

  const viewerIsOnTrial =
    course.viewerIsOnTrialUntil && new Date() < new Date(course.viewerIsOnTrialUntil);

  return (
    <>
      <Card styleName="mb-3">
        <Card.Body>
          <View styleName="flex-lg-row-reverse flex-grow-1 flex-shrink-1">
            <View style={media.up('lg') && styles.orderSummaryBoxDesktop}>
              {course.allowsTrial && viewerIsOnTrial && viewer && (
                <Alert color="info" styleName="mb-lg-0">
                  {!viewerIsOrganizationAdminOrTrainer && (
                    <Trans {...dict('course_paid_message_for_trial_trainee')} />
                  )}
                  {viewerIsOrganizationAdminOrTrainer && (
                    <Trans {...dict('course_paid_message_for_trial_trainer')} />
                  )}
                  <Button
                    color="primary"
                    onPress={() => {
                      dispatch(createCourseMembership(course.id, viewer.id)).then(() => {
                        navigate('/courses/join', {
                          state: { courseUrl: location.pathname + location.search },
                        });

                        alerts.add({
                          color: 'success',
                          content: <Trans {...dict('you_are_now_a_member_of_this_course')} />,
                        });
                      });
                    }}
                    styleName="mt-3"
                  >
                    <Trans {...dict('join_course')} />
                  </Button>
                </Alert>
              )}
              {(!course.allowsTrial || (course.allowsTrial && !viewerIsOnTrial)) && (
                <>
                  {!viewerIsOrganizationAdminOrTrainer &&
                    !domain.permissions.usersCanBuyCourses && (
                      <Alert color="warning" styleName="mb-lg-0">
                        {!course.allowsTrial && viewerIsOnTrial && (
                          <Trans
                            {...dict('this_course_is_not_included_in_your_free_trial')}
                            styleName="mb-3"
                          />
                        )}
                        <Trans
                          {...dict('you_need_a_license_for_this_course_bought_by_organization')}
                        />
                      </Alert>
                    )}
                  {((!viewerIsOrganizationAdminOrTrainer &&
                    domain.permissions.usersCanBuyCourses) ||
                    viewerIsOrganizationAdminOrTrainer) && (
                    <>
                      <Alert color="info" styleName="mb-lg-0">
                        <>
                          {course.products.length > 0 && lowestPricing && (
                            <>
                              {pricings.length > 1 && <Trans small {...dict(`prices_start_at`)} />}
                              {domain.permissions.usersCanBuyCourses &&
                                !viewerIsOrganizationAdminOrTrainer && (
                                  <>
                                    <CurrencyNumber
                                      value={lowestPricing.price.amount}
                                      code={lowestPricing.price.currency.code}
                                      style={styles.priceText}
                                    />
                                    <Trans styleName="py-2" small {...dict(`vat_incl`)} />
                                  </>
                                )}
                              {viewerIsOrganizationAdminOrTrainer && (
                                <>
                                  <CurrencyNumber
                                    value={lowestPricing.price.amount / 1.19}
                                    code={lowestPricing.price.currency.code}
                                    style={styles.priceText}
                                  />
                                  <Trans styleName="py-2" small {...dict(`plus_vat`)} />
                                </>
                              )}
                              <Text styleName="pt-2 pb-3">
                                {course.products.length > 0 && (
                                  <Text>
                                    <Trans {...dict('this_course_is_included_in_the_offer')} />{' '}
                                    <Text bold>{course.products[0].name}</Text>
                                    {course.products.length > 1 && (
                                      <>
                                        {' '}
                                        <Trans {...dict('and_others')} />
                                      </>
                                    )}
                                    .{' '}
                                  </Text>
                                )}
                                <Trans {...dict('no_subscriptions_no_hidden_fees')} />
                              </Text>
                            </>
                          )}
                          {(viewerIsOrganizationAdminOrTrainer ||
                            domain.permissions.usersCanBuyCourses) && (
                            <>
                              {Platform.OS !== 'web' && (
                                <Button
                                  color="primary"
                                  to={Domain.url(`/courses/${course.slug}`)}
                                  size="lg"
                                  external
                                >
                                  <Trans {...dict('get_access')} />
                                </Button>
                              )}
                              {Platform.OS === 'web' && (
                                <Button
                                  color="primary"
                                  onPress={
                                    viewer ? buyCourseLicenseModal.toggle : guestModal.toggle
                                  }
                                  size="lg"
                                >
                                  {viewerIsOrganizationAdminOrTrainer && (
                                    <Trans {...dict('buy_licenses')} />
                                  )}
                                  {!viewerIsOrganizationAdminOrTrainer && (
                                    <Trans {...dict('view_plans')} />
                                  )}
                                </Button>
                              )}
                            </>
                          )}
                          {domain.permissions.usersCanBuyCourses && !viewer && (
                            <View styleName="jc-start ai-start">
                              <Text bold styleName="pt-3">
                                <Trans {...dict('you_need_to')} />{' '}
                                <Link to="/register">
                                  <Trans {...dict('register')} />
                                </Link>{' '}
                                <Trans {...dict('or')} />{' '}
                                <Link to="/login">
                                  <Trans {...dict('login')} />
                                </Link>
                                {intl.locale === 'de' && ','}{' '}
                                <Trans {...dict('to_buy_this_course')} />
                              </Text>
                            </View>
                          )}
                          {!viewerIsOrganizationAdminOrTrainer &&
                            !domain.permissions.usersCanBuyCourses && (
                              <Trans
                                {...dict(
                                  'you_need_a_license_for_this_course_bought_by_organization',
                                )}
                              />
                            )}
                          {!viewerIsOrganizationAdminOrTrainer &&
                            domain.permissions.usersCanBuyCourses && (
                              <View styleName="flex-row flex-wrap mt-3">
                                <Image
                                  source="/img/pages/visaLogo.png"
                                  style={[styles.logo, styles.visaLogo]}
                                />
                                <Image
                                  source="/img/pages/mastercardLogo.png"
                                  style={[styles.logo, styles.mastercardLogo]}
                                />
                                <Image
                                  source="/img/pages/sepaLogo.png"
                                  style={[styles.logo, styles.sepaLogo]}
                                />
                                <Image
                                  source="/img/pages/klarna.png"
                                  style={[styles.logo, styles.klarnaLogo]}
                                />
                              </View>
                            )}
                        </>
                      </Alert>
                      {viewer && viewerIsOrganizationAdminOrTrainer ? (
                        <OrganizationLicensePurchaseModal
                          products={course.products}
                          organizations={viewer.organizationMemberships.map(
                            (membership) => membership.organization,
                          )}
                          modal={buyCourseLicenseModal}
                        />
                      ) : (
                        <UserLicensePurchaseModal
                          course={course}
                          viewer={viewer}
                          modal={buyCourseLicenseModal}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </View>
            <View styleName="flex-column flex-grow-1 flex-shrink-1">
              <Trans bold styleName="mb-3" {...dict(`this_is_waiting_for_you`)} />
              <View styleName="flex-lg-row flex-grow-1 flex-shrink-1">
                <View style={media.up('lg') && styles.columnDesktop}>
                  {course.examActivitiesCount > 0 && (
                    <View styleName="flex-row flex-shrink-1 mb-1">
                      <View style={styles.icon}>
                        <Icon glyph="award" />
                      </View>
                      <Trans
                        {...dict('exams', {
                          count: course.examActivitiesCount,
                        })}
                      />
                    </View>
                  )}
                  {course.quizActivitiesCount > 0 && (
                    <View styleName="flex-row flex-shrink-1 mb-1">
                      <View style={styles.icon}>
                        <Icon glyph="cards" />
                      </View>
                      <Trans
                        {...dict('flashcard_sets', {
                          count: course.quizActivitiesCount,
                        })}
                      />
                    </View>
                  )}
                  {course.videoActivitiesCount > 0 && (
                    <View styleName="flex-row flex-shrink-1 mb-1">
                      <View style={styles.icon}>
                        <Icon glyph="play-circled" />
                      </View>
                      <Trans {...dict('videos', { count: course.videoActivitiesCount })} />
                    </View>
                  )}
                  {course.articleActivitiesCount > 0 && (
                    <View styleName="flex-row flex-shrink-1 mb-1">
                      <View style={styles.icon}>
                        <Icon glyph="article" />
                      </View>
                      <Trans {...dict('articles', { count: course.articleActivitiesCount })} />
                    </View>
                  )}
                  {course.documentActivitiesCount > 0 && (
                    <View styleName="flex-row flex-shrink-1 mb-1">
                      <View style={styles.icon}>
                        <Icon glyph="doc" />
                      </View>
                      <Trans {...dict('documents', { count: course.documentActivitiesCount })} />
                    </View>
                  )}
                </View>
                <View style={media.up('lg') && styles.columnDesktop}>
                  <View styleName="flex-row flex-shrink-1 mb-1">
                    <View style={styles.icon}>
                      <Icon glyph="clock" />
                    </View>
                    <Trans {...dict('exam_mode_and_quiz_mode')} />
                  </View>
                  <View styleName="flex-row flex-shrink-1 mb-1">
                    <View style={styles.icon}>
                      <Icon glyph="chart-line" />
                    </View>
                    <Trans {...dict('results_learning_analytics')} />
                  </View>
                  {Domain.is('www.azubinet.de') && (
                    <View styleName="flex-row flex-shrink-1 mb-1">
                      <View style={styles.icon}>
                        <Icon glyph="smile" />
                      </View>
                      <Trans {...dict('professional_support_from_our_azubinet_team')} />
                    </View>
                  )}
                  {domain.permissions.usersCanDiscuss && (
                    <View styleName="flex-row flex-shrink-1">
                      <View style={styles.icon}>
                        <Icon glyph="chat" />
                      </View>
                      <Trans {...dict('all_tools_to_communicate_with_other_course_members')} />
                    </View>
                  )}
                </View>
              </View>
            </View>
          </View>
        </Card.Body>
      </Card>
      {!viewer && (
        <RegisterOrLoginModal modal={guestModal}>
          <Trans {...dict('please_register_or_log_in_to_buy_this_product')} />
        </RegisterOrLoginModal>
      )}
    </>
  );
}

export default PaidAccess;
