// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql, selectByRoot } from 'libs/graphql';
import { getVersion } from 'utils/courses';
import { ACTIVITY_EDITOR_SIDEBAR_FRAGMENT } from '../_shared/ActivityEditorSidebar/fragments';
import {
  QUIZ_CARD_TEXT_FRAGMENT,
  QUIZ_CARD_CHOICE_FRAGMENT,
  QUIZ_CARD_ORDERING_FRAGMENT,
  QUIZ_CARD_MATCHING_FRAGMENT,
} from '../_shared/QuizCard/fragments';
import { ACTIVITY_EDITOR_TITLE_FRAGMENT } from '../_shared/ActivityEditorTitle/fragments';
import { ATTACHMENTS_FRAGMENT } from '../_shared/MediaGalleryModal';

const QuizEditor = {
  name: 'Entry',
  bundle: () => import('./QuizEditor'),
};

const QUIZ_EDITOR_QUERY = gql`
  query CourseQuiz(
    $slug: String!
    $courseSlug: String!
    $versionNumber: Int
    $specificVersion: String
  ) {
    course(slug: $courseSlug) {
      categories {
        name
      }
      quizActivity(slug: $slug) {
        ...ActivityEditorTitleFragment
        ...ActivityEditorSidebarFragment
        slug
        version(versionNumber: $versionNumber, specificVersion: $specificVersion) {
          versionNumber
          isDeprecated
          releasedAt
          item {
            cardsCount
            downloadable
            totalBoxes
            resultsCount
            cards {
              ...QuizCardChoiceFragment
              ...QuizCardTextFragment
              ...QuizCardOrderingFragment
              ...QuizCardMatchingFragment
            }
            ...AttachmentsFragment
          }
        }

        author {
          id
        }
        viewerCanUpdate
      }
      viewerIsAdmin
    }
  }

  ${QUIZ_CARD_TEXT_FRAGMENT}
  ${QUIZ_CARD_CHOICE_FRAGMENT}
  ${QUIZ_CARD_ORDERING_FRAGMENT}
  ${QUIZ_CARD_MATCHING_FRAGMENT}
  ${ATTACHMENTS_FRAGMENT}
  ${ACTIVITY_EDITOR_TITLE_FRAGMENT}
  ${ACTIVITY_EDITOR_SIDEBAR_FRAGMENT}
`;

const Container = createAsyncContainer(QuizEditor, (props) => {
  const {
    params: { slug, courseSlug },
    searchParams,
  } = props;

  const versionNumber = getVersion(searchParams.get('version'));
  const variables = {
    slug,
    courseSlug,
    versionNumber,
    specificVersion: versionNumber ? null : 'LATEST',
  };

  const loaders = {
    query: query(QUIZ_EDITOR_QUERY, { variables }),
  };

  const selectors = {
    course: select(QUIZ_EDITOR_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
