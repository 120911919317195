// @flow
import React, { useEffect } from 'react';
import { createDict, Trans } from 'libs/intl';
import { Page } from 'components/layout';
import { Card } from 'components/core';
import { useNavigate, useLocation } from 'libs/routing';

const dict = createDict({
  joining_course: 'Joining course...',
});

function Join(): React$Node {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state.courseUrl) {
      navigate(location.state.courseUrl);
    }
  }, []);

  return (
    <Page windowTitle={dict('joining_course')}>
      <Page.Main size={8} styleName="mx-auto">
        <Card styleName="mb-3">
          <Card.Body styleName="ai-center">
            <Trans {...dict('joining_course')} />
          </Card.Body>
        </Card>
      </Page.Main>
    </Page>
  );
}

export default Join;
