// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';
import { Icon, FooterLink } from 'components/common';
import { View, Container, Text, Image } from 'components/core';
import ViewSwitcher from './ViewSwitcher';

const styles = StyleSheet.create({
  footer: css`
    background-color: $gray-800;
    width: 100%;
    margin-top: 5rem;
    padding-top: 0;
    flex-shrink: 0;
  `,
  container: css`
    padding: 1rem;
    @include media-breakpoint-down(sm) {
      max-width: 560px;
    }
  `,
  divider: css`
    @include media-breakpoint-down(sm) {
      border-bottom-width: 1px;
      border-bottom-color: rgba(255, 255, 255, 0.05);
      margin-bottom: 1rem;
      align-items: center;
    }
  `,
  copyright: css`
    padding-top: 1rem;
    text-align: center;
  `,
  text: css`
    color: $gray-400;
  `,
  image: css`
    width: 150px;
    height: 44px;
    align-self: center;
  `,
  icon: css`
    width: 20px;
    justify-content: center;
    flex-direction: row;
    margin-right: 5px;
  `,
});

const dict = createDict({
  imprint: 'Imprint',
  terms_of_use: 'Terms of Use',
  data_policy: 'Data Policy',
  faq: 'FAQ',
});

const currentYear = new Date().getFullYear();

function FooterVerkehrserziehung(): React$Node {
  return (
    <View role="contentinfo" style={styles.footer}>
      <Container style={styles.container}>
        <View styleName="flex-md-row ai-md-center jc-md-between" style={styles.divider}>
          <View styleName="mb-3 ai-center">
            <ViewSwitcher />
            <View styleName="flex-row ai-center ai-md-start">
              <View style={styles.icon}>
                <Icon small style={[styles.text]} glyph="cart-plus" />
              </View>
              <Text small style={[styles.text]}>
                <FooterLink to="https://www.heinrich-vogel-shop.de/" external>
                  Heinrich Vogel Shop
                </FooterLink>
              </Text>
            </View>
          </View>
          <View styleName="mb-3">
            <Text small style={styles.text} styleName="text-center">
              <FooterLink to="/faq">
                <Trans {...dict('faq')} />
              </FooterLink>{' '}
              &middot;{' '}
              <FooterLink to="/legal-contact">
                <Trans {...dict('imprint')} />
              </FooterLink>{' '}
              &middot;{' '}
              <FooterLink to="/terms-of-use">
                <Trans {...dict('terms_of_use')} />
              </FooterLink>{' '}
              &middot;{' '}
              <FooterLink to="/privacy-policy">
                <Trans {...dict('data_policy')} />
              </FooterLink>
            </Text>
          </View>
        </View>
        <Image
          source="/img/organizations/verkehrserziehung/verlag-heinrich-vogel.png"
          style={styles.image}
          label="Fahrradprüfung Testbogen online"
        />
        <Text small style={[styles.copyright, styles.text]}>
          © TECVIA GMBH {currentYear}
        </Text>
      </Container>
    </View>
  );
}

export default FooterVerkehrserziehung;
