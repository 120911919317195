// @flow
import React from 'react';
import { View, Image, Jumbotron, Text, Button } from 'components/core';
import { BrandHeading, BrandLargeText } from 'components/pages';
import { StyleSheet, css, useMedia } from 'libs/ui';

const styles = StyleSheet.create({
  jumbotron: css`
    background-color: #f3f5f6;
    align-items: center;
    padding-bottom: 8rem;
  `,
  jumbotronDesktop: css`
    margin-top: -2rem;
  `,
  jumbotronMobile: css`
    margin-top: -4rem;
    padding-bottom: 2rem;
  `,
  content: css`
    flex-shrink: 1;
  `,
  contentDesktop: css`
    margin: 2rem;
  `,
  heading: css`
    margin-bottom: 2rem;
  `,
  imageDesktop: css`
    width: 500px;
    height: 255px;
    margin-vertical: 4rem;
    margin-right: 2rem;
    margin-left: 3rem;
    border-radius: 0.5rem;
  `,
  imageMobile: css`
    width: 464px;
    height: 264px;
    margin-vertical: 2rem;
  `,
});

function HeroSection(): React$Node {
  const media = useMedia();

  return (
    <Jumbotron
      style={[styles.jumbotron, media.up('md') ? styles.jumbotronDesktop : styles.jumbotronMobile]}
      styleName="flex-md-row"
    >
      <View style={[styles.content, media.up('md') && styles.contentDesktop]}>
        <BrandHeading size={1} style={styles.heading}>
          <Text>Willkommen auf StBK.Azubinet</Text>
        </BrandHeading>
        <BrandLargeText>
          Auf der Lernplattform der Steuerberaterkammer Niedersachsen lernst du gemeinsam mit
          anderen Auszubildenden - in der Berufsschule, im Betrieb und landesweit, am Schreibtisch
          und unterwegs.
        </BrandLargeText>
        <View styleName="ai-md-start mt-3 mb-5 mb-md-0 flex-md-row">
          <Button to="/login" size="lg" styleName="mt-3 mt-md-0" color="dark">
            <Text>Zur Anmeldung</Text>
          </Button>
          <Button
            to="https://www.stbk-niedersachsen.de/de/ihre_kammer/aktuelle_meldungen/pr%C3%BCfungsvorbereitung_mit_der_lernplattform_stbk_azubinet/"
            external
            size="lg"
            styleName="mt-3 mt-md-0 ml-md-3"
            color="secondary"
          >
            <Text>Zugang anfordern</Text>
          </Button>
        </View>
      </View>
      {media.up('sm') && (
        <Image
          source="/img/home/devices-stbk.png"
          style={media.up('md') ? styles.imageDesktop : styles.imageMobile}
        />
      )}
    </Jumbotron>
  );
}

export default HeroSection;
