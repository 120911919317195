// @flow
import { makeUtilities, StyleSheet } from 'bootstrap-rn';

const utilities = StyleSheet.create(
  makeUtilities((u) => ({
    justifyContent: {
      ...u.justifyContent,
      class: 'jc',
    },
    alignItems: {
      ...u.alignItems,
      class: 'ai',
    },
    alignContent: {
      ...u.alignContent,
      class: 'ac',
    },
    alignSelf: {
      ...u.alignSelf,
      class: 'as',
    },
    marginLeft: {
      ...u.marginStart,
      class: 'ml',
    },
    marginRight: {
      ...u.marginEnd,
      class: 'mr',
    },
    negativeMarginLeft: {
      ...u.negativeMarginStart,
      class: 'ml',
    },
    negativeMarginRight: {
      ...u.negativeMarginEnd,
      class: 'mr',
    },
    paddingLeft: {
      ...u.paddingStart,
      class: 'pl',
    },
    paddingRight: {
      ...u.paddingEnd,
      class: 'pr',
    },
  })),
);

export default (utilities: Object);
