// @flow
import React from 'react';
import { Image, Grid } from 'components/core';
import { StyleSheet, css, useMedia } from 'libs/ui';
import BrandHeading from './BrandHeading';
import BrandLargeText from './BrandLargeText';

type Props = {
  icon: string,
  feature: string,
  text: string,
  size?: number,
};

const styles = StyleSheet.create({
  container: css`
    align-items: center;
    padding-vertical: 2rem;
  `,
  containerDesktop: css`
    padding-horizontal: 2rem;
  `,
  containerDesktopLarge: css`
    padding-horizontal: 4rem;
  `,
  containerMobile: css`
    padding-horizontal: 1rem;
  `,
  icon: css`
    width: 60px;
    height: 60px;
    margin-bottom: 1rem;
  `,
  heading: css`
    margin-bottom: 1rem;
    text-align: center;
  `,
  text: css`
    text-align: center;
  `,
});

const defaultProps = {
  size: 4,
};

function FeaturesGridItem(props: Props): React$Node {
  const { icon, feature, text, size } = props;
  const media = useMedia();

  return (
    <Grid.Box
      size={12}
      sizeMd={size}
      style={[
        styles.container,
        media.up('md') ? styles.containerDesktop : styles.containerMobile,
        media.up('md') && size && size >= 6 && styles.containerDesktopLarge,
      ]}
    >
      <Image source={icon} style={styles.icon} label="Icon" resizeMode="contain" />
      <BrandHeading size={4} style={styles.heading}>
        {feature}
      </BrandHeading>
      <BrandLargeText style={styles.text}>{text}</BrandLargeText>
    </Grid.Box>
  );
}

FeaturesGridItem.defaultProps = defaultProps;

export default FeaturesGridItem;
