// @flow
import React from 'react';
import { Button, Heading, Text, View } from 'components/core';
import { Icon, LargeText } from 'components/common';
import { Section } from 'components/pages';
import { createDict, Trans, useIntl } from 'libs/intl';
import { useValueState, useToggleState, useDocumentTitle } from 'libs/misc';
import { StyleSheet, css, useMedia } from 'libs/ui';
import FreeCoursesInfoBox from 'views/_shared/FreeCoursesInfoBox';
import PopularCourses from './PopularCourses';
import CoursesList from './CoursesList';
import ProfessionsModal from './ProfessionsModal';

type Props = {
  viewer: ?Entity<GQLUser>,
};

const dict = createDict({
  course: 'Course',
  public_courses: 'Public Courses',
  create_course: 'Create course',
  results: 'Results',
  your_profession: 'Your profession',
});

const styles = StyleSheet.create({
  containerDesktop: css`
    flex-grow: 1;
    flex-shrink: 0;
    padding-horizontal: 1rem;
    margin-bottom: 2rem;
  `,
  containerMobile: css`
    flex-grow: 1;
    flex-shrink: 1;
    padding: 1rem;
    margin-bottom: 1rem;
  `,
});

function Courses({ viewer }: Props): React$Node {
  useDocumentTitle(dict('public_courses'));
  const intl = useIntl();
  const value = useValueState(0);
  const showDefault = useToggleState(true);
  const modal = useToggleState(false);
  const media = useMedia();

  return (
    <>
      <Section color="white" styleName="jc-md-center ai-md-center">
        <View style={[media.up('md') ? styles.containerDesktop : styles.containerMobile]}>
          <View styleName="flex-md-row jc-md-between mb-md-5 mb-3">
            <Heading size={2} styleName="pr-3">
              <Icon glyph="globe" styleName="mr-3" />
              <Trans {...dict('public_courses')} />
            </Heading>
            {media.up('md') && (
              <>
                {viewer && (
                  <Button to="/courses/create" color="success" size="lg">
                    <Trans {...dict('create_course')} />
                  </Button>
                )}
                {!viewer && (
                  <Button to="/login" color="success" size="lg">
                    <Trans {...dict('create_course')} />
                  </Button>
                )}
              </>
            )}
          </View>
          <LargeText>
            {intl.locale === 'de'
              ? 'Hier findest du die öffentlichen Kurse, die Probeklausuren, Lernkarten, Dokumente und weitere Materialien beinhalten. Wenn du keinen passenden Kursen findest, kannst du selbst einen erstellen und deine Freunde einladen.'
              : 'Here you find the public courses that include exams, flashcards, documents and more. If you can`t find what you`re searching for, you can also create a new course and invite your friends.'}
          </LargeText>
          {media.down('sm') && (
            <View styleName="mt-3">
              {viewer && (
                <Button to="/courses/create" color="success" size="lg">
                  <Trans {...dict('create_course')} />
                </Button>
              )}
              {!viewer && (
                <Button to="/login" color="success" size="lg">
                  <Trans {...dict('create_course')} />
                </Button>
              )}
            </View>
          )}
        </View>
        <FreeCoursesInfoBox>
          <Button onPress={modal.toggle} size="lg">
            <Text>{intl.locale === 'de' ? 'Dein Ausbildungsberuf' : 'Your Profession'}</Text>
          </Button>
        </FreeCoursesInfoBox>
      </Section>
      <Section color="gray" styleName="ai-md-center jc-md-center">
        <View style={media.up('md') ? styles.containerDesktop : styles.containerMobile}>
          <View styleName="flex-row jc-md-start ai-md-start mb-4">
            {media.up('md') && (
              <Heading size={2}>
                <Icon glyph="graduation-cap" styleName="mr-3" />
              </Heading>
            )}
            <Button size="lg" onPress={modal.toggle}>
              {showDefault.on && <Trans {...dict('your_profession')} />}
              {!showDefault.on && <Text>{value.value}</Text>}
            </Button>
          </View>
          <CoursesList value={value} showDefault={showDefault} />
          <PopularCourses />
        </View>
      </Section>
      <ProfessionsModal value={value} modal={modal} showDefault={showDefault} />
    </>
  );
}

export default Courses;
