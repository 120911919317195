// @flow
import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { Text, Badge, View } from 'components/core';
import { Icon } from 'components/common';
import { injectTooltip } from 'libs/ui';

type Props = {|
  course: Entity<GQLCourse>,
|};

const dict = createDict({
  private: 'Private',
  restricted: 'Restricted',
  subject_to_licensing: 'Subject to licensing',
});

const ViewTooltip = injectTooltip(View);

function CoursePropertiesText({ course }: Props): React$Node {
  const courseIsRestricted =
    course.restricted || course.emailRestriction || course.organizationRestriction;

  return (
    <Text>
      {course.isPrivate && (
        <Text>
          &nbsp;{' '}
          <Badge color="secondary">
            <Trans {...dict('private')} />
          </Badge>
        </Text>
      )}
      {courseIsRestricted && (
        <Text>
          &nbsp;{' '}
          <Badge color="secondary">
            <Trans {...dict('restricted')} />
          </Badge>
        </Text>
      )}
      {course.paid && (
        <Text>
          &nbsp;{' '}
          <ViewTooltip
            tooltip={{
              title: <Trans {...dict('subject_to_licensing')} />,
              trigger: 'hover',
              placement: 'top',
            }}
          >
            <Icon glyph="star-empty" color="muted" />
          </ViewTooltip>
        </Text>
      )}
    </Text>
  );
}

export default CoursePropertiesText;
