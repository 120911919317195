// @flow
import React from 'react';
import { ListGroup } from 'components/core';
import BundleCompact from './BundleCompact';

type Props = {
  onClose?: () => void,
};

function DrogistBundles({ onClose }: Props): React$Node {
  return (
    <ListGroup>
      <BundleCompact
        to="/courses/bQQcC1bvpB"
        title="Erstmal reinschnuppern"
        price="kostenlose Lernmaterialien und Community"
        onClose={onClose}
      />
      <BundleCompact
        to="/professions/drogistin-drogist"
        title="Lernpaket Drogist/-in"
        price="ab 20,95 EUR"
        paid
      />
    </ListGroup>
  );
}

export default DrogistBundles;
