// @flow
import React from 'react';
import { OverlayProvider } from 'libs/overlays';
import { ErrorPage, Loading } from 'components/common';
import { useApp } from 'libs/foundation';
import ErrorAlert from './ErrorAlert';
import Container from './Container';
import CookieConsent from './CookieConsent';
import Foundation from './Foundation';

type Props = {
  errorCode?: ?ErrorType,
};

function App({ errorCode }: Props): React$Node {
  const app = useApp();

  if (errorCode) {
    return (
      <Foundation>
        <ErrorPage code={errorCode} />
      </Foundation>
    );
  }

  if (app.loading) {
    return <Loading />;
  }

  return (
    <OverlayProvider>
      <Foundation>
        {/* Initialize error handling of react-transporter. */}
        <ErrorAlert />

        {/* Render routes if app has been booted successfully. */}
        <Container />

        {/* Show cookie alert if not accepted before. */}
        <CookieConsent />
      </Foundation>
    </OverlayProvider>
  );
}

export default App;
