// @flow
import React from 'react';
import { Grid, View, Text } from 'components/core';
import { useIntl } from 'libs/intl';
import FeaturesBox from '../../_shared/FeaturesBox';

function FeaturesBoxContent(): React$Node {
  const intl = useIntl();
  return (
    <>
      <Grid>
        {intl.locale === 'en' && (
          <>
            <View styleName="flex-md-row flex-shrink-1">
              <FeaturesBox title="Study Courses" icon="users" type="orange">
                <Text>Collaborate with other apprentices, no matter what your profession is</Text>
              </FeaturesBox>
              <FeaturesBox title="Exams &amp; Solutions" icon="award" type="orange">
                <Text>Thousands of exercises and previous exams with solutions</Text>
              </FeaturesBox>
              <FeaturesBox title="Flashcards" icon="cards" type="orange">
                <Text>Learn with free flashcards for your final exams</Text>
              </FeaturesBox>
            </View>
            <View styleName="flex-md-row flex-shrink-1">
              <FeaturesBox title="Articles &amp; Videos" icon="lightbulb" type="blue">
                <Text>Help yourself with articles and tutorial videos</Text>
              </FeaturesBox>
              <FeaturesBox title="Reviews" icon="star-half-alt" type="blue">
                <Text>Reviews from former learners on their exams</Text>
              </FeaturesBox>
              <FeaturesBox title="Own Material" icon="folder-open" type="blue">
                <Text>Add and share your own flashcard sets and upload your materials</Text>
              </FeaturesBox>
            </View>
            {/* TEMPORARILY HIDE NEW FEATURES
            <FeaturesBox
              title="Study Coins"
              icon="money"
              type="blue">
              <Text>Collect study coins for your shared materials</Text>
            </FeaturesBox>
            <FeaturesBox
              title="Rewards"
              icon="award"
              type="blue">
              <Text>Get rewarded for shared content &amp; redeem rewards from your study coins</Text>
            </FeaturesBox> */}
          </>
        )}
        {intl.locale === 'de' && (
          <>
            <View styleName="flex-md-row flex-shrink-1">
              <FeaturesBox title="Kurse" icon="users" type="orange">
                <Text>
                  Egal für welches Fach - finde dich mit anderen Azubis, Studenten und Schülern
                  zusammen
                </Text>
              </FeaturesBox>
              <FeaturesBox title="Klausuren &amp; Lösungen" icon="award" type="orange">
                <Text>
                  Tausende Übungsaufgaben und Probeklausuren sowie Lösungen für alte Klausuren
                </Text>
              </FeaturesBox>
              <FeaturesBox title="Lernbox" icon="cards" type="orange">
                <Text>Lerne mit kostenlosen Lernkarten von anderen Nutzern im Quiz-Modus</Text>
              </FeaturesBox>
            </View>
            <View styleName="flex-md-row flex-shrink-1">
              <FeaturesBox title="Artikel &amp; Videos" icon="lightbulb" type="blue">
                <Text>
                  Artikel, Zusammenfassungen und Erklärvideos in der Wissensdatenbank helfen dir
                </Text>
              </FeaturesBox>
              <FeaturesBox title="Reviews" icon="star-half-alt" type="blue">
                <Text>
                  Erfahrungsberichte deiner Vorgänger zu ihren Prüfungen aus den vergangenen Jahren
                </Text>
              </FeaturesBox>
              <FeaturesBox title="Eigene Materialien" icon="folder-open" type="blue">
                <Text>
                  Erstelle und teile deine eigenen Lernkarten-Sets und lade deine Mitschriften hoch
                </Text>
              </FeaturesBox>
            </View>
            {/* TEMPORARILY HIDE NEW FEATURES
            <FeaturesBox
              title="Lerntaler"
              icon="money"
              type="blue">
              <Text>Sammle Lerntaler, wenn andere deine Lernkarten, Mitschriften und Reviews nutzen und bewerten</Text>
            </FeaturesBox>
            <FeaturesBox
              title="Rewards"
              icon="award"
type="blue"
><Text>Belohne dich für deine geteilten Lernerfahrungen, indem du Lerntaler gegen Prämien eintauscht</Text>
            </FeaturesBox>
 */}
          </>
        )}
      </Grid>
    </>
  );
}

export default FeaturesBoxContent;
