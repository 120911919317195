// @flow
import { useContext } from 'react';
import { useActive, useLink } from 'libs/routing';
// $FlowFixMe[import-type-as-value]
import { FormikContext } from 'formik';

const modifiers = {
  useFormField(props, ref) {
    // $FlowFixMe[incompatible-call]
    const formik = useContext(FormikContext);

    return {
      ...props,
      ref,
      onKeyPress(event) {
        if (props.onKeyPress) props.onKeyPress(event);

        // Submit form on enter (if not textarea)
        if (formik && event.keyCode === 13 && !props.multiline) {
          event.preventDefault();

          event.target.blur();

          formik.submitForm();
        }
      },
    };
  },
  useTabbable(props, ref) {
    const activeProps = useActive(props);
    return { ...activeProps, ref };
  },
  useActionable(props, ref) {
    const linkProps = useLink(props);
    return { ...linkProps, ref };
  },
};

export default (modifiers: Object);
