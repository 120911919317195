// @flow
import React from 'react';
import { Markdown } from 'components/markdown';
import { Text, View } from 'components/core';
import { TaskChoice } from 'components/tasks';

type Props = {
  piece: Entity<GQLPairingPiece>,
  last: boolean,
};

function PieceAccounting({ piece, last }: Props): React$Node {
  return (
    <>
      <TaskChoice last={last}>
        <TaskChoice.Label>
          <View styleName="flex-row flex-grow-1 flex-shrink-1 ai-start">
            <Text styleName="mr-2">{piece.key}.</Text>
            <Markdown variant="article" source={piece.text} />
          </View>
        </TaskChoice.Label>
      </TaskChoice>
    </>
  );
}

export default PieceAccounting;
