// @flow
import React from 'react';
import { Platform } from 'react-native';
import { StyleSheet, css } from 'libs/ui';
import { View } from 'bootstrap-rn';

type Props = {
  children: React$Node,
  spacing: boolean,
  ordered: boolean,
  start?: ?number,
};

const styles = StyleSheet.create({
  list: css`
    padding-left: 2rem;
  `,
  spacing: css`
    margin-bottom: 1rem;
  `,
});

const listStyle = { fontFamily: 'Roboto', paddingLeft: '2rem', margin: 0 };

function List(props: Props): React$Node {
  const { children, spacing, ordered, start } = props;

  if (Platform.OS === 'web') {
    return (
      <View style={spacing && styles.spacing}>
        {ordered ? (
          <ol style={listStyle} start={start}>
            {children}
          </ol>
        ) : (
          <ul style={listStyle}>{children}</ul>
        )}
      </View>
    );
  }

  return (
    <View style={[styles.list, spacing && styles.spacing]}>
      {React.Children.map(children, (child, i) => {
        return React.cloneElement(child, { index: start ? start - 1 + i : i, ordered });
      })}
    </View>
  );
}

export default List;
