// @flow
import { gql, createMutation, Link } from 'libs/graphql';

const PUT_QUIZ_CARD_RESULT = gql`
  mutation PutQuizCardResult($input: QuizCardResultPutInput!) {
    quizCardResult_put(input: $input) {
      correctCount
      wrongCount
      box
      loopAnswer
    }
  }
`;

export function putQuizCardResult(
  quiz: Entity<GQLQuiz>,
  card: Object,
  correct: boolean,
  repeatMode: boolean,
): MutationAction {
  return createMutation(PUT_QUIZ_CARD_RESULT, {
    variables: {
      input: {
        quizId: quiz.id,
        cardId: card.id,
        correct,
        repeatMode,
      },
    },
    updater: (store, data) => {
      // $FlowFixMe[speculation-ambiguous]
      store.update(card.__typename, card.id, (record) => {
        record.set('viewerActiveResult', new Link(data.roots.quizCardResult_put));
      });

      if (quiz.viewerActiveResult) {
        store.update('QuizResult', quiz.viewerActiveResult.id, (record) => {
          if (correct) {
            record.set('correctCount', (value) => value + 1);
          } else {
            record.set('wrongCount', (value) => value + 1);
          }

          const addToProgress = (addend) => (value) => {
            const current = value.split('/', 2);

            const cardsCount = quiz.cards.length;
            const factor = (cardsCount * addend[1]) / parseInt(current[1], 10);

            const nominator = parseInt(current[0], 10) * factor + addend[0];
            const denominator = parseInt(current[1], 10) * factor;

            return `${nominator}/${denominator}`;
          };

          if (!quiz.totalBoxes) {
            record.set('progress', addToProgress([1, 1]));
          } else if (!repeatMode) {
            const previousBox = card?.viewerActiveResult?.box || 1;
            const delta = correct ? 1 : 1 - previousBox;

            record.set('progress', addToProgress([delta, quiz.totalBoxes - 1]));
          }
        });
      }
    },
  });
}

const CREATE_QUIZ_RESULT = gql`
  mutation CreateQuizResult($input: QuizResultCreateInput!) {
    quizResult_create(input: $input) {
      correctCount
      wrongCount
      progress
      completedAt
      repeatMode
    }
  }
`;

export function createQuizResult(quizId: Id): MutationAction {
  return createMutation(CREATE_QUIZ_RESULT, {
    variables: {
      input: {
        quizId,
      },
    },
    updater: (store, data) => {
      store.update('Quiz', quizId, (record) => {
        record.set('viewerActiveResult', new Link(data.roots.quizResult_create));
      });
    },
  });
}
