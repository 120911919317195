// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';

const NewChecklist = {
  name: 'NewChecklist',
  bundle: () => import('./NewChecklist'),
};

const CHECKLIST_CREATE_QUERY = gql`
  query ChecklistCreateQuery {
    viewer {
      organizationMemberships {
        organization {
          name
          viewerIsAdmin
          viewerIsTrainer
        }
      }
    }
  }
`;

const Container = createAsyncContainer(NewChecklist, () => {
  const selectors = {
    viewer: select(CHECKLIST_CREATE_QUERY, {}),
  };

  const loaders = {
    query: query(CHECKLIST_CREATE_QUERY, {}),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
