// @flow
import React from 'react';
import { View, Button, Text, Modal, CloseButton } from 'components/core';
import { createDict, Trans } from 'libs/intl';

type Props = {
  modal: ToggleState,
  children: React$Node,
};

const dict = createDict({
  register_or_login: 'Register or log in',
  register: 'Register',
  login: 'Login',
  or: 'or',
});

function RegisterOrLoginModal({ modal, children }: Props): React$Node {
  return (
    <>
      <Modal visible={modal.on} onToggle={modal.toggle}>
        <Modal.Header styleName="flex-row">
          <Modal.Title>
            <Trans {...dict('register_or_login')} />
          </Modal.Title>
          <CloseButton onPress={modal.toggle} />
        </Modal.Header>
        <Modal.Body>
          <View styleName="ai-center">
            {children}
            <View styleName="mt-3 flex-row flex-grow-1 flex-shrink-1 ai-center jc-center">
              <Button to="/register" color="success" size="lg">
                <Trans {...dict('register')} />
              </Button>
              <Text>
                {' '}
                <Trans styleName="px-2" {...dict('or')} />{' '}
              </Text>
              <Button to="/login" color="secondary" size="lg">
                <Trans {...dict('login')} />
              </Button>
            </View>
          </View>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RegisterOrLoginModal;
