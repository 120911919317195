// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';
import { getVersion } from 'utils/courses';
import { DOMAIN_QUERY } from 'libs/foundation/queries';
import { COMMENTS_FRAGMENT } from 'views/_shared/Comments';
import { ACTIVITY_DETAILS_TITLE_FRAGMENT } from '../_shared/ActivityDetailsTitle/fragments';
import { ACTIVITY_VIEWER_RESULT_FRAGMENT } from '../_shared/ActivityViewerResult/fragments';
import { ACTIVITY_DETAILS_SIDEBAR_FRAGMENT } from '../_shared/ActivityDetailsSidebar/fragments';

const Article = {
  name: 'Article',
  bundle: () => import('./Article'),
};

const COURSE_ARTICLE_QUERY = gql`
  query CourseArticleEntry(
    $slug: String!
    $courseSlug: String!
    $postId: ClientID
    $versionNumber: Int
    $specificVersion: String
  ) {
    course(slug: $courseSlug) {
      viewerIsAdmin
      viewerIsMember
      viewerIsObserver
      restricted
      isPrivate
      emailRestriction
      organizationRestriction
      categories {
        name
      }
      articleActivity(slug: $slug) {
        ...ActivityDetailsTitleFragment
        ...ActivityViewerResultFragment
        ...ActivityDetailsSidebarFragment
        title
        slug
        version(versionNumber: $versionNumber, specificVersion: $specificVersion) {
          versionNumber
          releasedAt
          item {
            text
            downloadable
            createdAt
            updatedAt
            ...CommentsFragment
          }
        }
        author {
          name
          avatar {
            file {
              name
              extension
              info {
                image {
                  height
                  width
                }
              }
            }
          }
          createdAt
        }
        viewerCanUpdate
      }
    }
  }

  ${COMMENTS_FRAGMENT}
  ${ACTIVITY_DETAILS_TITLE_FRAGMENT}
  ${ACTIVITY_VIEWER_RESULT_FRAGMENT}
  ${ACTIVITY_DETAILS_SIDEBAR_FRAGMENT}
`;

const Container = createAsyncContainer(Article, (props) => {
  const {
    params: { slug, courseSlug },
    searchParams,
  } = props;

  const versionNumber = getVersion(searchParams.get('version'));
  const variables = {
    slug,
    courseSlug,
    postId: searchParams.get('post_id'),
    versionNumber,
    specificVersion: versionNumber ? null : 'CURRENT',
  };

  const loaders = {
    query: query(COURSE_ARTICLE_QUERY, { variables }),
  };

  const selectors = {
    course: select(COURSE_ARTICLE_QUERY, { variables }),
    domain: select(DOMAIN_QUERY, {}),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
