// @flow
import { useMemo } from 'react';
import { useLocation } from 'libs/routing';

export default function usePdfView(): boolean {
  const location = useLocation();

  return useMemo(() => {
    return location.pathname.startsWith('/pdf');
  }, [location.pathname]);
}
