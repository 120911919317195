// @flow
import React, { useEffect, useMemo, useRef } from 'react';
import { Platform } from 'react-native';
import PwaContext from './PwaContext';

type Props = {
  children: React$Node,
};

function PwaProvider({ children }: Props): React$Node {
  const pwa = useRef({
    beforeInstallPrompt: null,
    dismissed: false,
    listeners: [],
  });

  const updateBeforeInstallPrompt = (beforeInstallPrompt) => {
    if (pwa.current.dismissed) {
      return;
    }

    pwa.current.beforeInstallPrompt = beforeInstallPrompt;

    pwa.current.listeners.forEach((handleBeforeInstallPrompt) => {
      handleBeforeInstallPrompt(beforeInstallPrompt);
    });
  };

  useEffect(() => {
    if (Platform.OS !== 'web') {
      return () => {};
    }

    const ready = (event) => {
      event.preventDefault();

      updateBeforeInstallPrompt(event);

      event.userChoice.then(({ outcome }) => {
        if (outcome === 'accepted') {
          updateBeforeInstallPrompt(null);
        }
      });
    };

    window.addEventListener('beforeinstallprompt', ready);

    return () => {
      window.removeEventListener('beforeinstallprompt', ready);
    };
  }, []);

  const value = useMemo(
    () => ({
      get beforeInstallPrompt() {
        return pwa.current.beforeInstallPrompt;
      },
      subscribe(listener: (beforeInstallPrompt: any) => void) {
        pwa.current.listeners.push(listener);

        const unsubscribe = () => {
          const index = pwa.current.listeners.indexOf(listener);

          pwa.current.listeners.splice(index, 1);
        };

        return unsubscribe;
      },
      dismissBeforeInstallPrompt() {
        updateBeforeInstallPrompt(null);

        pwa.current.dismissed = true;
      },
    }),
    [],
  );

  return <PwaContext.Provider value={value}>{children}</PwaContext.Provider>;
}

export default PwaProvider;
