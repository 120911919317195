// @flow
import React from 'react';
import { Text, Image, Grid } from 'components/core';
import { Icon, LargeText } from 'components/common';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { Domain } from 'libs/misc';

type Props = {
  image?: ?string,
  feature: string,
  text: string,
  icon: string,
};

const styles = StyleSheet.create({
  imageDesktop: css`
    width: 5rem;
    height: 5rem;
  `,
  imageMobile: css`
    width: 3rem;
    height: 5rem;
  `,
  iconDesktop: css`
    font-size: 3rem;
    line-height: 3rem * $line-height-base;
    margin-bottom: 0.5rem;
    color: $brand-secondary;
  `,
  iconMobile: css`
    font-size: 2.5rem;
    line-height: 2.5rem * $line-height-base;
    margin-top: 1rem;
    color: $brand-secondary;
  `,
  text: css`
    font-family: 'Titillium Web';
    font-size: 1.5rem;
    line-height: 1.5rem * $line-height-base;
    font-weight: bold;
    line-height: 3rem;
    text-align: center;
    padding-bottom: 0.5rem;
  `,
  textSmall: css`
    text-align: center;
  `,
});

const defaultProps = {
  image: undefined,
};

function Feature(props: Props): React$Node {
  const { image, feature, text, icon } = props;
  const media = useMedia();

  return (
    <Grid.Box size={12} sizeMd={4} styleName="ai-center ai-center">
      <>
        {Domain.is('www.azubinet.de') && (
          <Image
            source={image}
            style={media.up('md') ? styles.iconDesktop : styles.iconMobile}
            label="Icon"
            resizeMode="contain"
          />
        )}
        {!Domain.is('www.azubinet.de') && (
          <Icon glyph={icon} style={media.up('md') ? styles.iconDesktop : styles.iconMobile} />
        )}
      </>
      <Text style={styles.text}>{feature}</Text>
      <LargeText style={styles.textSmall}>{text}</LargeText>
    </Grid.Box>
  );
}
Feature.defaultProps = defaultProps;

export default Feature;
