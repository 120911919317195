// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import { VIEWER_MENU_FRAGMENT } from 'views/(app)/_layout/Menu/fragments';
import { COURSES_DROPDOWN_FRAGMENT } from './fragments';

// We need to define VIEWER_QUERY in an extra file to prevent import cycles.
// See eslint rule import/no-cycle.
export const VIEWER_QUERY: DocumentNode = gql`
  query ViewerQuery {
    viewer {
      name
      firstName
      isOrganizationAdminOrTrainer
      unreadNotificationsCount
      lastEvaluatedWithAiAt
      canCreateBlogs
      avatar {
        file {
          name
          extension
          info {
            image {
              height
              width
            }
          }
        }
        largeFile {
          name
          extension
          info {
            image {
              height
              width
            }
          }
        }
      }
      courseMemberships(withTeams: true) {
        role
        licensed {
          validUntil
        }
        createdAt
        course {
          ...CoursesDropdownFragment
        }
      }
      organizationMemberships {
        role
        organization {
          name
          slug
          viewerIsAdmin
          viewerIsTrainer
        }
      }
      ...ViewerMenuFragment
    }
  }

  ${VIEWER_MENU_FRAGMENT}
  ${COURSES_DROPDOWN_FRAGMENT}
`;
