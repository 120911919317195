// @flow
import React, { useContext } from 'react';
import { Platform } from 'react-native';
import { View, Pressable, Image as BaseImage } from 'components/core';
import { StyleSheet, css } from 'libs/ui';
import { Domain } from 'libs/misc';
import { useImageDimensions } from 'libs/image';
import Context from '../Context';
import ParagraphContext from './ParagraphContext';

type Props = {
  url: string,
  title?: string,
  alt?: string,
  inline?: boolean,
};

const styles = StyleSheet.create({
  container: css`
    max-width: 100%;
  `,
  image: css`
    width: 100%;
    flex-grow: 1;
  `,
});

function FlexImage({ url, title, alt, inline }: Props): React$Node {
  const { allowLinkedImages } = useContext(Context);
  const paragraph = useContext(ParagraphContext);
  const paragraphWidth = paragraph?.width;

  const dimensions = useImageDimensions(url);

  return (
    <View
      style={[
        styles.container,
        {
          width: dimensions ? dimensions.width : null,
        },
        paragraphWidth && { maxWidth: paragraphWidth },
      ]}
    >
      {dimensions && (
        <>
          {Platform.OS === 'web' && allowLinkedImages && !inline ? (
            <Pressable to={Domain.url(url)} title={title} external style={styles.container}>
              <BaseImage
                source={{ uri: url }}
                title={title}
                aria-label={alt}
                resizeMode="contain"
                style={[
                  styles.image,
                  {
                    aspectRatio: dimensions.width / dimensions.height,
                  },
                ]}
              />
            </Pressable>
          ) : (
            <BaseImage
              source={{ uri: url }}
              title={title}
              aria-label={alt}
              resizeMode="contain"
              style={[
                styles.image,
                {
                  aspectRatio: dimensions.width / dimensions.height,
                },
              ]}
            />
          )}
        </>
      )}
    </View>
  );
}

export default FlexImage;
