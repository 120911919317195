// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { Card as BaseCard } from 'bootstrap-rn';

type Props = {
  children: React$Node,
  style?: any,
  textStyle?: any,
};

const styles = StyleSheet.create({
  header: css`
    @include media-breakpoint-down(xs) {
      border-radius: 0;
    }
  `,
  headerText: css`
    font-weight: 500;
    letter-spacing: 0.5px;
  `,
});

const CardHeader = React.forwardRef<Props, any>((props, ref) => {
  const { children, style, textStyle, ...elementProps } = props;

  return (
    <BaseCard.Header
      {...elementProps}
      ref={ref}
      style={[styles.header, style]}
      textStyle={[styles.headerText, textStyle]}
    >
      {children}
    </BaseCard.Header>
  );
});

CardHeader.displayName = 'CardHeader';

export default (CardHeader: React$AbstractComponent<Props, any>);
