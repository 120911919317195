// @flow
import React from 'react';
import { Image as BaseImage } from 'bootstrap-rn';
import getSource from './getSource';

type Props = {
  source: string | Object,
  label?: string,
};

const Image = React.forwardRef<Props, any>((props, ref) => {
  const { source, label, ...elementProps } = props;

  return <BaseImage {...elementProps} ref={ref} source={getSource(source)} aria-label={label} />;
});

Image.displayName = 'Image';

export default (Image: React$AbstractComponent<Props, any>);
