// @flow
import { createAsyncContainer } from 'libs/container';

const VerkehrserziehungTeachersParents = {
  name: 'VerkehrserziehungTeachersParents',
  bundle: () => import('./VerkehrserziehungTeachersParents'),
};

const Container = createAsyncContainer(VerkehrserziehungTeachersParents, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
