// @flow
import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { useValueState, useToggleState } from 'libs/misc';
import { useSearchParams } from 'libs/routing';
import { getVisibleItems } from 'utils/misc';
import { Editor } from 'components/editor';
import { Form } from 'components/forms';
import { Icon, Avatar } from 'components/common';
import { Pressable, Text, View, Link } from 'components/core';
import { useDispatch } from 'libs/graphql';
import Thread from './Thread';
import { createThread } from './mutations';

type Props = {
  courseId: Id,
  itemType: 'TaskIntro' | 'TaskPart' | 'OpenQuestionSolution',
  itemId: Id,
  editorPlaceholder?: ?Intlized$Message,
  viewer: Entity<GQLUser>,
  item: Entity<GQLTaskIntro> | Entity<GQLTaskPart> | Entity<GQLOpenQuestionSolution>,
  showForm?: boolean,
  style?: ?Object,
};

const dict = createDict({
  comment: 'Comment',
  comment_placeholder: 'Comment...',
  show_more_comments: '{count} more {count, plural, =1 {comment} other {comments}}',
});

const styles = StyleSheet.create({
  caret: css`
    position: absolute;
    border-width: 0.625rem;
    border-color: transparent;
    border-bottom-color: $gray-100;
    top: -1.25rem;
    right: 1.875rem;
    border-radius: 0.125rem;
  `,
  container: css`
    padding-horizontal: 0.75rem;
    background-color: $gray-100;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.125rem;
  `,
  containerDesktop: css`
    margin-left: 2.75rem;
  `,
  icon: css`
    font-size: 0.75rem;
  `,
  status: css`
    margin-vertical: 0.5rem;
  `,
  showMore: css`
    border-top-width: 1px;
    border-top-color: $gray-300;
    padding-vertical: 0.75rem;
    align-items: center;
  `,
  showMoreLink: css`
    font-size: 0.875rem;
  `,
  input: css`
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-top-width: 1px;
    border-top-color: $gray-300;
    flex-direction: row;
  `,
  avatar: css`
    flex-basis: 2.75rem;
  `,
  editor: css`
    flex-grow: 1;
    flex-shrink: 1;
  `,
  fakeEditor: css`
    border-width: 1px;
    border-style: solid;
    border-top-color: $gray-400;
    border-bottom-color: $gray-400;
    border-left-color: $gray-400;
    border-right-color: $gray-400;
    background-color: #fff;
    padding-vertical: 0.4375rem;
    padding-horizontal: 0.5rem;
    overflow: hidden;
    @include platform(web) {
      cursor: text;
    }
  `,
  fakeEditorText: css`
    line-height: 1rem;
    font-size: 0.875rem;
    color: $gray-500;
  `,
});

function Comments(props: Props): React$Node {
  const [searchParams] = useSearchParams();
  const selectedPostId = searchParams.get('post_id');
  const status = useToggleState(false);
  const expanded = useToggleState(false);
  const added = useValueState([]);
  const media = useMedia();

  const {
    viewer,
    courseId,
    item,
    itemId,
    itemType,
    editorPlaceholder,
    showForm,
    style,
    ...elementProps
  } = props;
  const dispatch = useDispatch();

  const visibleThreads = expanded.on
    ? item.comments
    : getVisibleItems(item.comments, item.comment, added.value, 2);

  return (
    <View
      {...elementProps}
      style={[styles.container, media.up('md') && styles.containerDesktop, style]}
    >
      <View style={styles.caret} />
      <View style={styles.status} styleName="flex-row jc-between ai-center">
        <Icon glyph="chat" color="muted" styleName="mr-2" style={styles.icon} />
        <Text styleName="flex-grow-1" color="muted" small>
          {item.comments.length}
        </Text>
        <Text small>
          <Link onPress={() => status.setOn()}>
            <Trans {...dict('comment')} />
          </Link>
        </Text>
      </View>
      {(status.on || item.comments.length > 0 || showForm) && (
        <Form
          initialValues={{
            text: null,
          }}
          onSubmit={async (values, actions) => {
            try {
              const data = await dispatch(
                createThread(courseId, itemType, itemId, null, values.text, selectedPostId),
              );

              added.set((value) => [...value, data.roots.thread_create.link[1]]);

              actions.resetForm();
            } catch (err) {
              status.setOn();
              throw err;
            }

            status.setOff();
          }}
        >
          <View style={styles.input}>
            <View style={styles.avatar}>
              <Avatar avatar={viewer.avatar} />
            </View>
            <View style={styles.editor}>
              {!status.on && (
                <Pressable
                  onPress={() => {
                    status.setOn();
                  }}
                  style={styles.fakeEditor}
                >
                  <Trans
                    {...(editorPlaceholder || dict('comment_placeholder'))}
                    style={styles.fakeEditorText}
                  />
                </Pressable>
              )}
              {status.on && (
                <>
                  <Editor
                    name="text"
                    size="sm"
                    placeholder={editorPlaceholder || dict('comment_placeholder')}
                    autoFocus
                  />
                  <View styleName="flex-row jc-end mt-n2">
                    <Form.Button type="submit" size="sm">
                      <Trans {...dict('comment')} />
                    </Form.Button>
                  </View>
                </>
              )}
            </View>
          </View>
        </Form>
      )}
      {visibleThreads.map((thread) => (
        <Thread
          courseId={courseId}
          itemType={itemType}
          itemId={itemId}
          viewer={viewer}
          thread={thread}
          key={thread.id}
        />
      ))}
      {item.comments.length > visibleThreads.length && (
        <View style={styles.showMore}>
          <Link style={styles.showMoreLink} onPress={() => expanded.setOn()}>
            <Trans
              {...dict('show_more_comments', {
                count: item.comments.length - visibleThreads.length,
              })}
            />
          </Link>
        </View>
      )}
    </View>
  );
}

export default Comments;
