// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query, selectByRoot } from 'libs/graphql';

const MembershipList = {
  name: 'MembershipList',
  bundle: () => import('./MembershipList'),
};

const ORGANIZATION_MEMBERSHIPS_QUERY = gql`
  query OrganizationMembershipsQuery($slug: String!) {
    organization(slug: $slug) {
      name
      city
      slug
      description
      memberships {
        role
        ownsUser
        user {
          name
          state
          lastSeenAt
          selfRegistered
          avatar {
            file {
              name
              extension
              info {
                image {
                  height
                  width
                }
              }
            }
          }
          checklistResults {
            checklist {
              todosCount
            }
            todoResultsCount
            doneUntil
          }
        }
      }
      # viewerTraineeMemberships are queried to update the menu. The following fields must stay in sync with ViewerMenuFragment.
      viewerTraineeMemberships {
        user {
          name
          lastSeenAt
          avatar {
            file {
              name
              extension
              info {
                image {
                  height
                  width
                }
              }
            }
          }
          checklistResults {
            checklist {
              slug
              name
              todosCount
            }
            doneUntil
            todoResultsCount
          }
        }
      }
      viewerIsAdmin
      viewerIsTrainer
    }
  }
`;

const Container = createAsyncContainer(MembershipList, (props) => {
  const {
    params: { slug },
  } = props;

  const variables = { slug };

  const loaders = {
    query: query(ORGANIZATION_MEMBERSHIPS_QUERY, { variables }),
  };

  const selectors = {
    organization: select(ORGANIZATION_MEMBERSHIPS_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
