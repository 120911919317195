// @flow
import React from 'react';
import { ListGroup } from 'components/core';
import BundleCompact from './BundleCompact';

type Props = {
  onClose?: () => void,
};

function FachinformatikerBundles({ onClose }: Props): React$Node {
  return (
    <ListGroup>
      <BundleCompact
        to="/courses/p2LNBzMtRS"
        title="Erstmal reinschnuppern"
        price="kostenlose Lernmaterialien und Community"
        onClose={onClose}
      />
      <BundleCompact
        to="/professions/fachinformatikerin-fachinformatiker"
        title="Abschlussprüfung Teil 1 (IT-Berufe)"
        price="ab 20,95 EUR"
        paid
      />
    </ListGroup>
  );
}

export default FachinformatikerBundles;
