// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const ReportCollectionForm = {
  name: 'ReportCollectionForm',
  bundle: () => import('./ReportCollectionForm'),
};

const VIEWER_ACTIVE_REPORT_COLLECTION_QUERY = gql`
  query ReportListQuery {
    viewer {
      name
      avatar {
        file {
          name
          extension
          info {
            image {
              height
              width
            }
          }
        }
      }
      activeReportCollection {
        type
      }
    }
  }
`;

const Container = createAsyncContainer(ReportCollectionForm, () => {
  const loaders = {
    query: query(VIEWER_ACTIVE_REPORT_COLLECTION_QUERY, {}),
  };

  const selectors = {
    viewer: select(VIEWER_ACTIVE_REPORT_COLLECTION_QUERY, {}),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
