// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const QUIZ_ACTIONS_FRAGMENT: DocumentNode = gql`
  fragment QuizActionsFragment on QuizActivity {
    slug
    versions {
      item {
        cardsCount
        viewerLatestResult {
          correctCount
          wrongCount
          number
          progress
          completedAt
        }
        resultsCount
        totalBoxes
      }
    }
  }
`;
