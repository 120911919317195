// @flow
import { createAsyncContainer } from 'libs/container';

const NewBlog = {
  name: 'NewBlog',
  bundle: () => import('./NewBlog'),
};

const Container = createAsyncContainer(NewBlog, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
