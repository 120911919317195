// @flow
import React from 'react';
import { Platform } from 'react-native';
import { StyleSheet, css } from 'libs/ui';
import { Text, View } from 'bootstrap-rn';

type Props = {
  children: React$Node,
  ordered: boolean,
  index: number,
};

const styles = StyleSheet.create({
  listItem: css`
    flex-direction: row;
  `,
  marker: css`
    width: 2rem;
    position: absolute;
    margin-left: -2rem;
    text-align: right;
  `,
  markerUnordered: css`
    padding-right: 0.75rem;
  `,
  markerOrdered: css`
    padding-right: 0.375rem;
  `,
});

function ListItem(props: Props): React$Node {
  const { children, ordered, index } = props;

  if (Platform.OS === 'web') {
    return <li>{children}</li>;
  }

  return (
    <View style={styles.listItem}>
      {!ordered && <Text style={[styles.marker, styles.markerUnordered]}>&bull;</Text>}
      {ordered && <Text style={[styles.marker, styles.markerOrdered]}>{index + 1}.</Text>}
      <View styleName="flex-shrink-1">{children}</View>
    </View>
  );
}

export default ListItem;
