// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { Text } from 'components/core';

type Props = {
  value: React$Node,
  style?: any,
};

const styles = StyleSheet.create({
  text: css`
    background-color: rgba(243, 156, 18, 0.25);
    border-radius: 0.125rem;
    padding-horizontal: 0.125rem;
  `,
});

const defaultProps = {
  style: null,
};

function ClozeGap({ value, style }: Props): React$Node {
  return <Text style={[styles.text, style]}>{value || '      '}</Text>;
}

ClozeGap.defaultProps = defaultProps;

export default ClozeGap;
