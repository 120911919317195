// @flow
import { createContainer } from 'libs/container';
import { select, gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import MultipleChoice from './MultipleChoice';

export const MULTIPLE_CHOICE_EXAM_FRAGMENT: DocumentNode = gql`
  fragment MultipleChoiceExamFragment on MultipleChoice {
    text
    type
    viewerRunningExamSolution {
      optionValues {
        position
        value
      }
    }
    options {
      position
      text
    }
  }
`;

const Container = createContainer(MultipleChoice, (props) => {
  const selectors = {
    multipleChoice: select(MULTIPLE_CHOICE_EXAM_FRAGMENT, {
      entry: ['MultipleChoice', props.id],
    }),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
