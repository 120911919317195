class Fraction {
  constructor(value) {
    const [numerator, denominator] = value.split('/');

    this.numerator = parseInt(numerator, 10);
    this.denominator = parseInt(denominator, 10);
  }

  toFloat() {
    return this.numerator / this.denominator;
  }

  toFixed(x) {
    return this.toFloat().toFixed(x);
  }
}

export default Fraction;
