// @flow
import React from 'react';
import { Page } from 'components/layout';
import { Outlet } from 'libs/routing';
import CourseSettingsNav from './CourseSettingsNav';

function CourseSettingsLayout(): React$Node {
  return (
    <Page>
      <Page.Aside size={3} styleName="mx-auto mb-3 mb-md-0">
        <CourseSettingsNav />
      </Page.Aside>
      <Page.Main size={9}>
        <Outlet />
      </Page.Main>
    </Page>
  );
}

export default CourseSettingsLayout;
