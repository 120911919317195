// @flow
import React from 'react';
import { Text, ListGroup, Modal } from 'components/core';
import { createDict, Trans } from 'libs/intl';

type Props = {
  value: ValueState,
  modal: ToggleState,
  showDefault: ToggleState,
};

const dict = createDict({
  choose_your_profession: 'Choose your profession',
});

function ProfessionsModal({ value, modal, showDefault }: Props): React$Node {
  return (
    <Modal visible={modal.on} onToggle={modal.toggle}>
      <Modal.Header>
        <Modal.Title>
          <Trans {...dict('choose_your_profession')} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body styleName="p-0">
        <ListGroup flush>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Automobilkaufleute');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Automobilkaufleute</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Bankkaufleute');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Bankkaufleute</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Drogist/-innen');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Drogist/-innen</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Fachinformatiker/-innen');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Fachinformatiker/-innen</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Fachkräfte für Lagerlogistik');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Fachkräfte für Lagerlogistik</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Fachkräfte für Systemgastronomie');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Fachkräfte für Systemgastronomie</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Fachkräfte im Gastgewerbe');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Fachkräfte im Gastgewerbe</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Fachlagerist/-innen');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Fachlagerist/-innen</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Florist/-innen');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Florist/-innen</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Hotelfachleute');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Hotelfachleute</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Industriekaufleute');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Industriekaufleute</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Kaufleute für Büromanagement');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Kaufleute für Büromanagement</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Kaufleute im E-Commerce');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Kaufleute im E-Commerce</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Kaufleute im Einzelhandel');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Kaufleute im Einzelhandel</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Kaufleute im Gesundheitswesen');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Kaufleute im Gesundheitswesen</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Kaufleute im Groß- und Außenhandel');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Kaufleute im Groß- und Außenhandel (alte AO)</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Kaufleute für Groß- und Außenhandelsmanagement');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Kaufleute für Groß- und Außenhandelsmanagement (neue AO)</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Kaufleute für Spedition und Logistikdienstleistung');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Kaufleute für Spedition und Logistikdienstleistung</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Köche');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Köche</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Medizinische Fachangestellte');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Medizinische Fachangestellte</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Personaldienstleistungskaufleute');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Personaldienstleistungskaufleute</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Restaurantfachleute');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Restaurantfachleute</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Veranstaltungskaufleute');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Veranstaltungskaufleute</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            onPress={() => {
              value.set('Verkäufer/-innen');
              showDefault.setOff();
              modal.toggle();
            }}
          >
            <Text>Verkäufer/-innen</Text>
          </ListGroup.ItemAction>
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
}

export default ProfessionsModal;
