// @flow
import { createAsyncContainer } from 'libs/container';

const ResendWelcomeEmail = {
  name: 'ResendWelcomeEmail',
  bundle: () => import('./ResendWelcomeEmail'),
};

const Container = createAsyncContainer(ResendWelcomeEmail, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
