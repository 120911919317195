// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { View } from 'components/core';

type Props = {
  children: React$Node,
};

const styles = StyleSheet.create({
  container: css`
    padding-vertical: 0.5rem;
    flex-grow: 1;
    flex-shrink: 1;
  `,
});

function TaskChoiceLabel({ children }: Props): React$Node {
  return (
    <View style={styles.container} styleName="as-start as-md-center">
      {children}
    </View>
  );
}

export default TaskChoiceLabel;
