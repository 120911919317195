// @flow
import { createAsyncContainer } from 'libs/container';

const VerifyAccount = {
  name: 'VerifyAccount',
  bundle: () => import('./VerifyAccount'),
};

const Container = createAsyncContainer(VerifyAccount, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
