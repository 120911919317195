// @flow
import { isOptimistic } from 'libs/graphql';

export default function getVisibleItems<Item: { +id: Id }>(
  items: Array<Item>,
  activeItem: ?Item,
  exceptions: Array<Id> = [],
  first: number = 1,
): Array<Item> {
  let count = 0;

  return items.filter((item) => {
    // Add optimistic responses and exceptions to selection.
    if (isOptimistic(item) || exceptions.indexOf(item.id) !== -1) {
      return true;
    }

    // Add item to selection if active or first is not reached.
    if ((activeItem && activeItem.id === item.id) || count < first) {
      count += 1;

      return true;
    }

    return false;
  });
}
