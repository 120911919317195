// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';

const CourseSettingsPermissions = {
  name: 'CourseSettingsPermissions',
  bundle: () => import('./CourseSettingsPermissions'),
};

const COURSE_SETTINGS_PERMISSIONS_QUERY = gql`
  query CourseSettingsPermissionsQuery($courseSlug: String!) {
    course(slug: $courseSlug) {
      name
      organization {
        ownsDomain
        domain {
          isMain
        }
      }
      viewerIsAdmin
      permissions {
        membersCanCopy
        membersCanCreateTasks
        membersCanCreateQuizzes
        membersCanCreateVideos
        membersCanCreateArticles
        membersCanCreateDocuments
        membersCanCreateSurveys
      }
    }
  }
`;

const Container = createAsyncContainer(CourseSettingsPermissions, (props) => {
  const {
    params: { courseSlug },
  } = props;

  const variables = { courseSlug };

  const loaders = {
    query: query(COURSE_SETTINGS_PERMISSIONS_QUERY, {
      variables,
    }),
  };

  const selectors = {
    course: select(COURSE_SETTINGS_PERMISSIONS_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
