// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { View, Text } from 'components/core';
import { Icon, LargeBadge } from 'components/common';

type Props = {
  variant: 'sample' | 'correct' | 'wrong' | 'user',
  optionOrder: string,
  optionOrderSample?: string,
};

const styles = StyleSheet.create({
  text: css`
    font-size: 1rem;
  `,
  badge: css`
    width: 2rem;
    align-items: center;
  `,
});

const defaultProps = {
  optionOrderSample: undefined,
};

function TaskChoiceItemMatch({ variant, optionOrder, optionOrderSample }: Props): React$Node {
  return (
    <>
      {/* Sample Solution */}
      {variant === 'sample' && (
        <>
          <Icon styleName="mb-2" glyph="ok-circled2" />
          <Text>{optionOrder}</Text>
        </>
      )}

      {/* User has chosen the correct/wrong answer/option */}
      {variant === 'correct' && (
        <>
          <LargeBadge color="secondary" styleName="mr-2">
            {optionOrder}
          </LargeBadge>
          <View style={styles.badge}>
            <Icon color="success" glyph="ok-circled2" style={styles.text} />
          </View>
        </>
      )}
      {variant === 'wrong' && (
        <>
          <LargeBadge color="danger" styleName="mr-2">
            {optionOrder}
          </LargeBadge>
          <LargeBadge color="success">{optionOrderSample}</LargeBadge>
        </>
      )}
      {/* UserSolution to vote for */}
      {variant === 'user' && (
        <>
          <LargeBadge color="secondary">{optionOrder}</LargeBadge>
        </>
      )}
    </>
  );
}

TaskChoiceItemMatch.defaultProps = defaultProps;

export default TaskChoiceItemMatch;
