// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const TeamsDocumentsList = {
  name: 'TeamsDocumentsList',
  bundle: () => import('./TeamsDocumentsList'),
};

const ORGANIZATION_TEAM_DOCUMENTS_QUERY = gql`
  query OrganizationTeamDocumentsQuery($slug: String!, $teamSlug: String!) {
    organization(slug: $slug) {
      name
      slug
      team(teamSlug: $teamSlug) {
        name
        slug
        documents {
          title
          author {
            name
          }
          file {
            name
            encodedName
            extension
            info {
              size
              mimeType
            }
          }
        }
        viewerCanUpdate
      }
    }
  }
`;

const Container = createAsyncContainer(TeamsDocumentsList, (props) => {
  const {
    params: { slug, teamSlug },
  } = props;

  const variables = { slug, teamSlug };

  const loaders = {
    query: query(ORGANIZATION_TEAM_DOCUMENTS_QUERY, { variables }),
  };

  const selectors = {
    organization: select(ORGANIZATION_TEAM_DOCUMENTS_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
