// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import {
  ACTIVITY_VERSION_ITEM_ATTACHMENTS_ARTICLE_FRAGMENT,
  ACTIVITY_VERSION_ITEM_ATTACHMENTS_EXAM_FRAGMENT,
  ACTIVITY_VERSION_ITEM_ATTACHMENTS_QUIZ_FRAGMENT,
  ACTIVITY_VERSION_ITEM_ATTACHMENTS_SURVEY_FRAGMENT,
  ACTIVITY_VERSION_ITEM_ATTACHMENTS_VIDEO_FRAGMENT,
} from './MediaGallery/fragments';
import { ACTIVITY_REFERENCES_FRAGMENT } from '../ActivityReferences/fragments';
import { ACTIVITY_INFO_MODAL_FRAGMENT } from '../ActivityInfoModal/fragments';

export const ACTIVITY_EDITOR_SIDEBAR_FRAGMENT: DocumentNode = gql`
  fragment ActivityEditorSidebarFragment on CourseActivity {
    published
    license
    hasCopies
    version(versionNumber: $versionNumber, specificVersion: $specificVersion) {
      versionNumber
      ...ActivityVersionItemAttachmentsArticleFragment
      ...ActivityVersionItemAttachmentsExamFragment
      ...ActivityVersionItemAttachmentsQuizFragment
      ...ActivityVersionItemAttachmentsSurveyFragment
      ...ActivityVersionItemAttachmentsVideoFragment
    }
    versions {
      versionNumber
      current
      isDeprecated
      releasedAt
      createdAt
    }
    ...ActivityReferencesFragment
    ...ActivityInfoModalFragment
  }

  ${ACTIVITY_REFERENCES_FRAGMENT}
  ${ACTIVITY_INFO_MODAL_FRAGMENT}
  ${ACTIVITY_VERSION_ITEM_ATTACHMENTS_ARTICLE_FRAGMENT}
  ${ACTIVITY_VERSION_ITEM_ATTACHMENTS_EXAM_FRAGMENT}
  ${ACTIVITY_VERSION_ITEM_ATTACHMENTS_QUIZ_FRAGMENT}
  ${ACTIVITY_VERSION_ITEM_ATTACHMENTS_SURVEY_FRAGMENT}
  ${ACTIVITY_VERSION_ITEM_ATTACHMENTS_VIDEO_FRAGMENT}
`;
