// @flow
import React, { useMemo, useEffect } from 'react';
import { Animated, Easing, Platform } from 'react-native';
import { View } from 'components/core';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  children: React$Node,
};

const styles = StyleSheet.create({
  container: css`
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  `,
});

const AnimatedView = Animated.createAnimatedComponent(View);

const getAnimationStyle = (animation) => {
  return {
    opacity: animation.interpolate({
      inputRange: [0, 0.5, 1],
      outputRange: [0, 1, 0],
    }),
  };
};

function TheaterLoading({ children }: Props): React$Node {
  const role = Platform.OS === 'web' ? 'status' : null;

  const animation = useMemo(() => new Animated.Value(0), []);

  useEffect(() => {
    const duration = 1500;

    Animated.loop(
      Animated.timing(animation, {
        toValue: 1,
        duration,
        easing: Easing.linear,
        useNativeDriver: Platform.OS !== 'web',
      }),
    ).start();
  }, []);

  return (
    <View style={styles.container}>
      <AnimatedView role={role} aria-hidden style={getAnimationStyle(animation)}>
        {children}
      </AnimatedView>
    </View>
  );
}

export default TheaterLoading;
