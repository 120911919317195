// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query, selectByRoot } from 'libs/graphql';

const Store = {
  name: 'Offer',
  bundle: () => import('./Offer'),
};

const STORE_OFFER_QUERY = gql`
  query OfferQuery($id: ClientID!) {
    product(slug: $slug) {
      name
      courses {
        name
      }
      professions {
        name
        slug
        products {
          id
          name
          courses {
            name
          }
          professions {
            name
          }
          offers {
            duration
            price {
              min
              price {
                amount
                currency {
                  code
                }
              }
            }
            businessPrices {
              min
              price {
                amount
                currency {
                  code
                }
              }
            }
          }
        }
      }
      description
      offers {
        duration
        price {
          min
          price {
            amount
            currency {
              code
            }
          }
        }
        businessPrices {
          min
          price {
            amount
            currency {
              code
            }
          }
        }
      }
      organization {
        slug
      }
      courses {
        slug
        name
        caption
        examActivitiesCount
        quizActivitiesCount
        videoActivitiesCount
        articleActivitiesCount
        documentActivitiesCount
        surveyActivitiesCount
        topicsCount
        membershipsCount
        viewerIsMember
      }
    }
  }
`;

const Container = createAsyncContainer(Store, (props) => {
  const {
    params: { productId },
  } = props;

  const variables = { id: productId };

  const loaders = {
    query: query(STORE_OFFER_QUERY, { variables }),
  };

  const selectors = {
    product: select(STORE_OFFER_QUERY, { variables }),
    viewer: selectByRoot('viewer', (viewer) =>
      viewer
        .join(['courseMemberships', { withTeams: true }], (courseMembership) =>
          courseMembership.join('course').join('licensed'),
        )
        .join('organizationMemberships', (organizationMembership) =>
          organizationMembership.join('organization'),
        ),
    ),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
