// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';
import { ACTIVITIES_FRAGMENT } from '../_shared/ActivityList/fragments';

const VideoList = {
  name: 'VideoList',
  bundle: () => import('./VideoList'),
};

const VIDEO_LIST_QUERY = gql`
  query CourseVideoList($courseSlug: String!, $activityType: CourseActivityType) {
    course(slug: $courseSlug) {
      videoActivitiesCount
      sections {
        videosDescription
      }
      restricted
      emailRestriction
      organizationRestriction
      isPrivate
      ...ActivitiesFragment
      viewerCanCreateVideos
      viewerIsObserver
      viewerIsMember
      viewerIsAdmin
    }
  }

  ${ACTIVITIES_FRAGMENT}
`;

const Container = createAsyncContainer(VideoList, (props) => {
  const {
    params: { courseSlug },
    searchParams,
  } = props;

  const loaders = {
    query: query(VIDEO_LIST_QUERY, {
      variables: {
        courseSlug,
        activityType: 'Video',
        // Add activityId to reload query if an activity has been copied
        activityId: searchParams.get('activity_id'),
      },
    }),
  };

  const selectors = {
    course: select(VIDEO_LIST_QUERY, { variables: { courseSlug } }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
