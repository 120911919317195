// @flow
import { createAsyncContainer } from 'libs/container';

const TermsOfUse = {
  name: 'TermsOfUse',
  bundle: () => import('./TermsOfUse'),
};

const Container = createAsyncContainer(TermsOfUse, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
