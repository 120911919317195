// @flow
import { createContainer } from 'libs/container';
import { select } from 'libs/graphql';
import { MENU_CHECKLIST_TODO_CUSTOM_FRAGMENT } from './fragments';
import ChecklistTodoCustom from './ChecklistTodoCustom';

const Container = createContainer(ChecklistTodoCustom, (props) => {
  const selectors = {
    item: select(MENU_CHECKLIST_TODO_CUSTOM_FRAGMENT, {
      entry: ['ChecklistTodoCustom', props.id],
    }),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
