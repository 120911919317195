// @flow
import React from 'react';
import { Platform } from 'react-native';
import { StyleSheet, css } from 'libs/ui';
import { View } from 'bootstrap-rn';

const styles = StyleSheet.create({
  thematicBreak: css`
    height: 1px;
    background-color: $gray-300;
    margin-bottom: 1rem;
  `,
});

function ThematicBreak(): React$Node {
  const role = Platform.OS === 'web' ? 'separator' : null;

  return <View role={role} style={styles.thematicBreak} />;
}

export default ThematicBreak;
