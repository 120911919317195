// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { View, Button } from 'components/core';
import { Icon } from 'components/common';
import TaskChoiceCounter from './TaskChoiceCounter';

type Props = {
  variant: 'true' | 'false',
  count?: ?number,
  checked: boolean,
  onPress: (SyntheticEvent<*>) => void,
};

const styles = StyleSheet.create({
  icon: css`
    font-size: 1.125rem;
    margin-horizontal: 0.5rem;
  `,
  solution: css`
    flex-basis: 2.25rem;
  `,
  label: css`
    width: 5rem;
    flex: 0 0 auto;
  `,
  labelDefault: css`
    color: $gray-400;
  `,
  labelActive: css`
    color: #0097bc;
  `,
});

const defaultProps = {
  count: null,
};

function ChoiceButton(props: Props): React$Node {
  const { variant, count, checked, onPress: handlePress, ...elementProps } = props;

  return (
    <Button
      {...elementProps}
      color="secondary"
      outline
      onPress={handlePress}
      style={styles.label}
      active={checked}
    >
      <View styleName="flex-row jc-center ai-center">
        <View style={styles.solution}>
          {variant === 'true' && (
            <Icon
              glyph="ok"
              style={[styles.icon, checked ? styles.labelActive : styles.labelDefault]}
            />
          )}
          {variant === 'false' && (
            <Icon
              glyph="cancel"
              style={[styles.icon, checked ? styles.labelActive : styles.labelDefault]}
            />
          )}
        </View>
        {count !== undefined && count !== null && (
          <TaskChoiceCounter variant={variant}>{count}</TaskChoiceCounter>
        )}
      </View>
    </Button>
  );
}

ChoiceButton.defaultProps = defaultProps;

export default ChoiceButton;
