// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

const PAIRING_PROPOSED_SOLUTION_FIELDS = gql`
  {
    pairs {
      left
      right
    }
    explanation
    author {
      name
      avatar {
        file {
          name
          extension
          info {
            image {
              height
              width
            }
          }
        }
      }
      createdAt
    }
    votesTrueCount
    votesFalseCount
    viewerVote {
      value
    }
    createdAt
    ...CommentsFragment
  }
`;

export const PAIRING_FRAGMENT: DocumentNode = gql`
  fragment PairingFragment on Pairing {
    text
    type
    pieces {
      text
      key
      side
    }
    sampleSolution {
      pairs {
        left
        right
      }
      explanation
      ...CommentsFragment
    }
    proposedSolution(id: $solutionId, postId: $postId) ${PAIRING_PROPOSED_SOLUTION_FIELDS}
    proposedSolutions ${PAIRING_PROPOSED_SOLUTION_FIELDS}
  }
`;
