// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const VIDEO_FILE_EDITOR_FRAGMENT: DocumentNode = gql`
  fragment VideoFileEditorFragment on VideoFile {
    file {
      encodedName
      info {
        size
        mimeType
      }
    }
  }
`;
