// @flow
import React from 'react';
import { View } from 'components/core';
import { StyleSheet, css } from 'libs/ui';
import { BrandHeading, BrandLargeText } from 'components/pages';
import { createDict, Trans } from 'libs/intl';

const styles = StyleSheet.create({
  container: css`
    flex-grow: 1;
    flex-shrink: 1;
    margin-vertical: $spacer5;
  `,
  heading: css`
    padding-vertical: 1rem;
    text-align: center;
  `,
});

const dict = createDict({
  support_your_apprentices_online: 'Support your apprentices online',
  catch_a_glimpse_first: 'Catch a glimpse first!',
});

function InfoActionBox(): React$Node {
  return (
    <View style={styles.container} styleName="mx-3 mx-md-0">
      <BrandLargeText align="center" styleName="as-center">
        <Trans {...dict('support_your_apprentices_online')} />
      </BrandLargeText>
      <BrandHeading style={styles.heading} align="center" size={1} styleName="as-center">
        <Trans {...dict('catch_a_glimpse_first')} />
      </BrandHeading>
    </View>
  );
}

export default InfoActionBox;
