// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const VIDEO_LINK_FRAGMENT: DocumentNode = gql`
  fragment VideoLinkFragment on VideoLink {
    provider
    videoId
  }
`;
