// @flow
import React, { useContext } from 'react';
import { StyleSheet, css } from 'libs/ui';
import { Nav as BaseNav } from 'bootstrap-rn';

type Props = {
  children: React$Node,
  style?: any,
};

const styles = StyleSheet.create({
  tabs: css`
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 0.125rem;
    margin-bottom: 0;
  `,
  pills: css`
    &:hover {
      background-color: $transparent-100;
    }
    &:active {
      background-color: $transparent-100;
    }
  `,
});

const NavLink = React.forwardRef<Props, any>((props, ref) => {
  const { children, style, ...elementProps } = props;

  const { variant } = useContext(BaseNav.Context);

  return (
    <BaseNav.Link
      {...elementProps}
      ref={ref}
      style={[variant === 'tabs' && styles.tabs, variant === 'pills' && styles.pills, style]}
    >
      {children}
    </BaseNav.Link>
  );
});

NavLink.displayName = 'NavLink';

export default (NavLink: React$AbstractComponent<Props, any>);
