// @flow
import React from 'react';
import { View, Button } from 'components/core';
import { createDict, Trans } from 'libs/intl';
import { useDispatch } from 'libs/graphql';
import { useToggleState, useAlerts } from 'libs/misc';
import { useNavigate, useLocation } from 'libs/routing';
import { createCourseMembership } from './mutations';

type Props = {
  course: Entity<GQLCourse>,
  viewer: Entity<GQLUser>,
};

const dict = createDict({
  organization_restricted_error:
    'You must be a user of the course owner organization in order to join the course.',
  email_restricted_error: 'You must have a valid email address of the course owner organization.',
  join_course: 'Join Course',
  you_are_now_a_member_of_this_course: 'You are now a member of this course.',
});

function AccessAllowedDefault({ course, viewer }: Props): React$Node {
  const dispatch = useDispatch();
  const emailRestrictedError = useToggleState(false);
  const organizationRestrictedError = useToggleState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const alerts = useAlerts();

  return (
    <View styleName="ai-md-center">
      <Button
        color="primary"
        onPress={() => {
          dispatch(createCourseMembership(course.id, viewer.id, null)).then(
            () => {
              navigate('/courses/join', {
                state: { courseUrl: location.pathname + location.search },
              });

              alerts.add({
                color: 'success',
                content: <Trans {...dict('you_are_now_a_member_of_this_course')} />,
              });
            },
            (error) => {
              if (error.data.errors[0].extensions.code === 'EMAIL_RESTRICTED') {
                emailRestrictedError.setOn();
              }
              if (error.data.errors[0].extensions.code === 'ORGANIZATION_RESTRICTED') {
                organizationRestrictedError.setOn();
              }
            },
          );
        }}
      >
        <Trans {...dict('join_course')} />
      </Button>
      {organizationRestrictedError.on && (
        <Trans color="danger" {...dict('organization_restricted_error')} styleName="mt-3" />
      )}
      {emailRestrictedError.on && (
        <Trans color="danger" {...dict('email_restricted_error')} styleName="mt-3" />
      )}
    </View>
  );
}

export default AccessAllowedDefault;
