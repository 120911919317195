// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const QUIZ_CARD_TEXT_FRAGMENT: DocumentNode = gql`
  fragment QuizCardTextFragment on TextCard {
    text
    hint
    answer {
      text
    }
  }
`;

export const QUIZ_CARD_CHOICE_FRAGMENT: DocumentNode = gql`
  fragment QuizCardChoiceFragment on ChoiceCard {
    text
    hint
    single
    answers {
      text
      explanation
      correct
    }
  }
`;

export const QUIZ_CARD_ORDERING_FRAGMENT: DocumentNode = gql`
  fragment QuizCardOrderingFragment on OrderingCard {
    text
    hint
    answers {
      text
      position
    }
  }
`;

export const QUIZ_CARD_MATCHING_FRAGMENT: DocumentNode = gql`
  fragment QuizCardMatchingFragment on MatchingCard {
    text
    hint
    answers {
      text
      matchingText
    }
  }
`;
