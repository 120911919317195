// @flow
import React from 'react';
import { Container as BaseContainer } from 'bootstrap-rn';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  children: React$Node,
  style?: any,
};

const styles = StyleSheet.create({
  container: css`
    min-width: 320px;

    @include media-breakpoint-down(xs) {
      padding: 0;
    }
  `,
});

const Container = React.forwardRef<Props, any>((props, ref) => {
  const { children, style, ...elementProps } = props;

  return (
    <BaseContainer {...elementProps} ref={ref} style={[styles.container, style]}>
      {children}
    </BaseContainer>
  );
});

Container.displayName = 'Container';

export default (Container: React$AbstractComponent<Props, any>);
