// @flow
import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { Alert, View, Link, Button, Text } from 'components/core';
import { Domain } from 'libs/misc';

type Props = {
  course: Entity<GQLCourse>,
  viewer: ?Entity<GQLUser>,
};

const dict = createDict({
  please_register_or_log_in_to_join_courses: 'Please register or log in to join courses.',
  register: 'Register',
  login: 'Login',
  or: 'or',
  you_need_a_valid_license_for_this_course: 'You need a valid license for this course.',
  manage_licenses: 'Manage licenses',
  do_you_have_a_valid_license_code: 'Do you have a valid license code?',
  redeem_license_code: 'Redeem license code',
  i_dont_have_an_license_code_yet: "I don't have an license code yet",
});

function AccessPaidVogelspot({ course, viewer }: Props): React$Node {
  const viewerIsOrganizationAdminOrTrainer = viewer && viewer.isOrganizationAdminOrTrainer;

  return (
    <Alert color="info" styleName="ai-center mb-3">
      {!viewer && (
        <View styleName="ai-center my-3">
          <Trans {...dict('please_register_or_log_in_to_join_courses')} styleName="flex-grow-1" />
          <View styleName="mt-3 flex-md-row flex-grow-1 flex-shrink-1 ai-center jc-center">
            <Button to="/register" color="success" size="lg">
              <Trans {...dict('register')} />
            </Button>
            <Text styleName="mx-2 my-2 my-md-0">
              {' '}
              <Trans {...dict('or')} />{' '}
            </Text>
            <Button to="/login" color="secondary" size="lg">
              <Trans {...dict('login')} />
            </Button>
          </View>
        </View>
      )}
      {viewer && (
        <>
          {viewerIsOrganizationAdminOrTrainer ? (
            <>
              <Trans {...dict('you_need_a_valid_license_for_this_course')} />
              <Button
                to={`/organizations/${viewer.organizationMemberships[0].organization.slug}/licenses`}
                styleName="as-center mt-3"
              >
                <Trans {...dict('manage_licenses')} />
              </Button>
            </>
          ) : (
            <>
              <Trans {...dict('do_you_have_a_valid_license_code')} />
              <Button to="/settings/licenses" styleName="mt-3">
                <Trans {...dict('redeem_license_code')} />
              </Button>
            </>
          )}
          {Domain.is('www.vogelspot.de') && (
            <View styleName="as-start mt-3">
              {course.slug === 'nnAFoQPvQx' && (
                <Link
                  styleName="mt-3"
                  to="https://www.heinrich-vogel-shop.de/shop/aus-und-weiterbildung/berufskraftfahrer-ausbildung-fif/vogelspot-fuer-berufskraftfahrer-lkw-omnibus.html"
                  external
                >
                  <Trans {...dict('i_dont_have_an_license_code_yet')} />
                </Link>
              )}
              {course.slug === 'cWhuGE5AUg' && (
                <Link
                  styleName="mt-3"
                  to="https://www.heinrich-vogel-shop.de/shop/aus-und-weiterbildung/gefahrgut-und-abfall/gefahrgut/vogelspot-erstschulung-basis-adr.html"
                  external
                >
                  <Trans {...dict('i_dont_have_an_license_code_yet')} />
                </Link>
              )}
              {course.slug === 'MChhrcYu8O' && (
                <Link
                  styleName="mt-3"
                  to="https://www.heinrich-vogel-shop.de/shop/aus-und-weiterbildung/gefahrgut-und-abfall/gefahrgut/vogelspot-auffrischungsschulung-adr.html"
                  external
                >
                  <Trans {...dict('i_dont_have_an_license_code_yet')} />
                </Link>
              )}
              {course.slug === 'sRDEM1zCd3' && (
                <Link
                  styleName="mt-3"
                  to="https://www.heinrich-vogel-shop.de/shop/aus-und-weiterbildung/gefahrgut-und-abfall/gefahrgut/vogelspot-aufbaukurs-tank-adr.html"
                  external
                >
                  <Trans {...dict('i_dont_have_an_license_code_yet')} />
                </Link>
              )}
              {course.slug === 'o22UXQvS1M' && (
                <Link
                  styleName="mt-3"
                  to="https://www.heinrich-vogel-shop.de/shop/gueterverkehr/fachbuecher-software/vogelspot-fuer-speditionskaufleute.html"
                  external
                >
                  <Trans {...dict('i_dont_have_an_license_code_yet')} />
                </Link>
              )}
            </View>
          )}
          {Domain.is('www.xn--fahrradprfung-4ob.de') && (
            <Link
              styleName="mt-3"
              to="https://www.heinrich-vogel-shop.de/suche.html?searchphrase=fahrradpr%C3%BCfung.de+online"
              external
            >
              <Text>Zugangscode erwerben</Text>
            </Link>
          )}
        </>
      )}
    </Alert>
  );
}

export default AccessPaidVogelspot;
