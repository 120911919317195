// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';

const Report = {
  name: 'Report',
  bundle: () => import('./Report'),
};

const REPORT_QUERY = gql`
  query ReportCollectionQuery($collectionId: ClientID!, $reportId: ClientID!) {
    reportCollection(collectionId: $collectionId) {
      type
      trainee {
        name
      }
      type
      startDate
      endDate
      reportsStartDate
      report(reportId: $reportId) {
        beginAt
        endAt
        completed
        approved
        userNote
        trainerNote
        createdAt
        entries {
          date
          category
          status
          activities {
            text
            duration
          }
        }
      }
    }
  }
`;

const Container = createAsyncContainer(Report, (props) => {
  const {
    params: { collectionId, reportId },
  } = props;

  const loaders = {
    query: query(REPORT_QUERY, { variables: { collectionId, reportId } }),
  };

  const selectors = {
    reportCollection: select(REPORT_QUERY, { variables: { collectionId, reportId } }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
