// @flow
import { createAsyncContainer } from 'libs/container';

const LegalNoteContact = {
  name: 'LegalNoteContact',
  bundle: () => import('./LegalNoteContact'),
};

const Container = createAsyncContainer(LegalNoteContact, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
