// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const VIDEO_FILE_FRAGMENT: DocumentNode = gql`
  fragment VideoFileFragment on VideoFile {
    file {
      encodedName
      info {
        mimeType
      }
    }
  }
`;
