// @flow
import { gql, createMutation } from 'libs/graphql';

const CREATE_INQUIRY = gql`
  mutation CreateInquiry($input: InquiryCreateInput!) {
    inquiry_create(input: $input)
  }
`;

export function createInquiry(
  firstName: string,
  lastName: string,
  position: string,
  email: string,
  phone: string,
  organization: string,
  ref: number,
): MutationAction {
  return createMutation(CREATE_INQUIRY, {
    variables: {
      input: {
        firstName,
        lastName,
        position,
        email,
        phone,
        organization,
        ref,
      },
    },
  });
}
