// @flow
import { createAsyncContainer } from 'libs/container';

const PrivacyPolicy = {
  name: 'PrivacyPolicy',
  bundle: () => import('./PrivacyPolicy'),
};

const Container = createAsyncContainer(PrivacyPolicy, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
