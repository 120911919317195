// @flow
import React, { useRef, createRef, useEffect } from 'react';
import { Platform, Dimensions } from 'react-native';
import { Nav, ScrollView } from 'components/core';
import { matchPath, useLocation } from 'libs/routing';

type Props = {
  children: React$Node,
};

const makeChildren = (children, location) => {
  const tabs = [];

  const clonedChildren = React.Children.map(children, (child) => {
    if (!child || !child.props.to) {
      return child;
    }

    const tab = {
      path: child.props.to,
      end: child.props.end,
      caseSensitive: child.props.caseSensitive,
      ref: createRef(),
    };
    tabs.push(tab);
    return React.cloneElement(child, { ref: tab.ref });
  });

  const activeTab = tabs.find((tab) =>
    matchPath(tab.end ? tab.path : `${tab.path}/*`, location.pathname),
  );

  return [clonedChildren, activeTab];
};

function ScrollableNav({ children, ...props }: Props): React$Node {
  const location = useLocation();

  const scrollbar = useRef();

  const [clonedChildren, activeTab] = makeChildren(children, location);

  useEffect(() => {
    if (activeTab && activeTab.ref.current) {
      activeTab.ref.current.measure((x, _y, width, _height, pageX) => {
        if (pageX < 0) {
          // $FlowFixMe[incompatible-use]
          scrollbar.current.scrollTo({ x });
        } else {
          const { width: pageWidth } = Dimensions.get('window');

          if (pageX + width + 16 > pageWidth) {
            // $FlowFixMe[incompatible-use]
            scrollbar.current.scrollTo({ x: x + width + 16 - pageWidth });
          }
        }
      });
    }
  }, [location.pathname]);

  return (
    <ScrollView
      horizontal
      // $FlowFixMe[incompatible-type]
      ref={scrollbar}
      showsHorizontalScrollIndicator={Platform.OS === 'web'}
      bounces={false}
    >
      <Nav {...props}>{clonedChildren}</Nav>
    </ScrollView>
  );
}

ScrollableNav.Link = Nav.Link;

export default ScrollableNav;
