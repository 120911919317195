// @flow
import React, { useEffect, useMemo } from 'react';
import { useFixedElement } from 'bootstrap-rn';
import { Button, View } from 'components/core';
import { useValueState } from 'libs/misc';
import { createDict, Trans } from 'libs/intl';
import { usePwa } from 'libs/foundation';
import { StyleSheet, css } from 'libs/ui';
import { Overlay } from 'libs/overlays';

const dict = createDict({
  you_can_add_the_app_to_the_home_screen: 'You can add the app to your home screen.',
  add: 'Add',
  dismiss: 'Dismiss',
});

const styles = StyleSheet.create({
  prompt: css`
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1030;
    padding: 1rem;
    background-color: #fff;
    border-top-width: 1px;
    border-color: $gray-300;
  `,
});

function InstallPwaPrompt(): React$Node {
  const pwa = usePwa();
  const beforeInstallPrompt = useValueState(pwa.beforeInstallPrompt);
  const ref = useFixedElement();

  useEffect(() => {
    const unsubscribe = pwa.subscribe((value) => {
      beforeInstallPrompt.set(value);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const isMobile = useMemo(() => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  }, []);

  if (!isMobile || !beforeInstallPrompt.value) {
    return null;
  }

  return (
    <Overlay style={styles.prompt}>
      <View ref={ref}>
        <Trans
          {...dict('you_can_add_the_app_to_the_home_screen')}
          styleName="mb-3 as-center text-center"
        />
        <View styleName="flex-row as-center">
          <Button
            onPress={() => {
              pwa.dismissBeforeInstallPrompt();
            }}
            color="secondary"
            styleName="mr-3"
          >
            <Trans {...dict('dismiss')} />
          </Button>
          <Button
            onPress={() => {
              if (beforeInstallPrompt.value) {
                beforeInstallPrompt.value.prompt();
              }
            }}
          >
            <Trans {...dict('add')} />
          </Button>
        </View>
      </View>
    </Overlay>
  );
}

export default InstallPwaPrompt;
