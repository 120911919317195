// @flow

export default function getPairLeftKey(
  piece: Entity<GQLPairingPiece>,
  pairs: Array<Entity<GQLPairingSolutionPair>>,
): ?number {
  const item = pairs.find(
    (pair) => ((piece.side && piece.side === 'RIGHT') || !piece.side) && pair.right === piece.key,
  );

  return item ? item.left : null;
}
