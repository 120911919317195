// @flow
import { createAsyncContainer } from 'libs/container';

const LanguageTest = {
  name: 'LanguageTest',
  bundle: () => import('./LanguageTest'),
};

const Container = createAsyncContainer(LanguageTest, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
