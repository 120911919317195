// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const CLOZE_FRAGMENT: DocumentNode = gql`
  fragment ClozeFragmentEditor on Cloze {
    text
    gaps {
      key
    }
    sampleSolution {
      gapValues {
        key
        value
      }
      explanation
    }
  }
`;

export const MULTIPLE_CHOICE_FRAGMENT: DocumentNode = gql`
  fragment MultipleChoiceFragmentEditor on MultipleChoice {
    text
    type
    options {
      position
      text
    }
    sampleSolution {
      optionValues {
        position
        value
      }
      explanation
    }
  }
`;

export const OPEN_QUESTION_FRAGMENT: DocumentNode = gql`
  fragment OpenQuestionFragmentEditor on OpenQuestion {
    text
    sampleSolution {
      text
      explanation
    }
  }
`;

export const PAIRING_FRAGMENT: DocumentNode = gql`
  fragment PairingFragmentEditor on Pairing {
    text
    type
    pieces {
      text
      side
      key
    }
    sampleSolution {
      pairs {
        left
        right
      }
      explanation
    }
  }
`;

export const TRUE_FALSE_FRAGMENT: DocumentNode = gql`
  fragment TrueFalseFragmentEditor on TrueFalse {
    text
    statements {
      position
      text
    }
    sampleSolution {
      statementValues {
        position
        value
      }
      explanation
    }
  }
`;
