// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import { VIDEO_ACTIVITY_CAPTION_FILE_FRAGMENT } from './VideoFile/fragments';
import { VIDEO_ACTIVITY_CAPTION_LINK_FRAGMENT } from './VideoLink/fragments';

export const VIDEO_ACTIVITY_CAPTION_FRAGMENT: DocumentNode = gql`
  fragment VideoActivityCaptionFragment on VideoActivity {
    version(specificVersion: "CURRENT") {
      item {
        downloadable
        type {
          ...VideoActivityCaptionFileFragment
          ...VideoActivityCaptionLinkFragment
        }
      }
    }
  }

  ${VIDEO_ACTIVITY_CAPTION_FILE_FRAGMENT}
  ${VIDEO_ACTIVITY_CAPTION_LINK_FRAGMENT}
`;
