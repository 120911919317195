// @flow
import React from 'react';
import { Provider as TransporterProvider } from 'react-transporter';
import MenuProvider from './MenuProvider';
import OptionsProvider from './OptionsProvider';
import PwaProvider from './PwaProvider';

type Props = {
  client: Object,
  children: React$Node,
};

function AppProvider({ client, children }: Props): React$Node {
  return (
    <TransporterProvider client={client}>
      <MenuProvider>
        <OptionsProvider client={client}>
          <PwaProvider>{children}</PwaProvider>
        </OptionsProvider>
      </MenuProvider>
    </TransporterProvider>
  );
}

export default AppProvider;
