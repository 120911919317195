// @flow
import React from 'react';
import { Page } from 'components/layout';
import { Outlet } from 'libs/routing';
import ProductAside from './ProductAside';

function ProductLayout(): React$Node {
  return (
    <Page>
      <ProductAside />
      <Page.Main size={9}>
        <Outlet />
      </Page.Main>
    </Page>
  );
}

export default ProductLayout;
