// @flow
import React from 'react';
import { View, Text, Button, ImageBackground, Image } from 'components/core';
import { LargeText } from 'components/common';
import { Section } from 'components/pages';
import { useDocumentTitle, useToggleState, Domain } from 'libs/misc';
import { StyleSheet, css, useMedia } from 'libs/ui';
import HeroBox from './HeroBox';
import Content from './Content';
import FeaturesContent from './FeaturesContent';
import RequestAccountModal from './RequestAccountModal';

type Props = {
  domain: Entity<GQLDomain>,
};

const styles = StyleSheet.create({
  presentationSectionWidthDesktop: css`
    align-items: center;
    justify-content: center;
    max-width: 1172px;
  `,
  backgroundGrey: css`
    background-color: #f3f3f6;
  `,
  backgroundWhite: css`
    background-color: #fff;
  `,
  imageBackground: css`
    width: 100%;
    max-width: 1920px;
    height: 930px;
    position: absolute;
    margin-top: -0.5rem;
  `,
  dimensions: css`
    max-width: 300x;
    max-height: 100px;
  `,
  azubinetLogo: css`
    width: 280px;
    height: 48px;
  `,
});

function LandingOthers({ domain }: Props): React$Node {
  useDocumentTitle('Startseite Lernplattform');
  const media = useMedia();
  const requestModal = useToggleState(false);

  return (
    <>
      <View style={styles.backgroundWhite}>
        {media.up('md') && (
          <ImageBackground
            source="img/pages/bg-splash-trade-schools2.jpg"
            style={styles.imageBackground}
          />
        )}
        <View styleName="flex-shrink-1 jc-center ai-center mt-5 mt-md-0">
          <HeroBox />
        </View>
        <Section color="white" styleName="ai-center jc-center">
          <View styleName="jc-center ai-center my-md-5 my-3">
            {domain.logo && domain.logo.info.image && (
              <Image
                source={`/files/${domain.logo.id}/${domain.logo.name}`}
                resizeMode="contain"
                style={[
                  styles.dimensions,
                  {
                    height: domain.logo.info.image.height,
                    width: domain.logo.info.image.width,
                  },
                ]}
              />
            )}
            {!domain.logo && (
              <Image
                source="/img/azubinet.png"
                label="Azubinet"
                resizeMode="contain"
                style={[styles.azubinetLogo, styles.dimensions]}
              />
            )}
          </View>
          {!Domain.is('netto.azubinet.de') &&
            !Domain.is('budni.azubinet.de') &&
            !Domain.is('reisser.azubinet.de') && (
              <View styleName="flex-md-row ai-md-center jc-md-center mt-3 mt-md-0 mx-3 mx-md-0">
                <Button color="dark" to="/login" size="lg">
                  <Text>Anmelden</Text>
                </Button>
              </View>
            )}
          {(Domain.is('netto.azubinet.de') ||
            Domain.is('budni.azubinet.de') ||
            Domain.is('reisser.azubinet.de')) && (
            <View styleName="ai-md-center jc-md-center mt-3 mt-md-0 mx-3 mx-md-0">
              <LargeText>Noch keinen Account?</LargeText>
              <Button color="dark" onPress={requestModal.toggle} styleName="mt-2 mb-4" size="lg">
                <Text>Zugang anfordern</Text>
              </Button>
              <LargeText>Bereits registriert?</LargeText>
              <Button color="secondary" to="/login" styleName="my-2" size="lg">
                <Text>Anmelden</Text>
              </Button>
            </View>
          )}
          <View style={media.up('md') ? styles.presentationSectionWidthDesktop : undefined}>
            <Content />
          </View>
        </Section>
        <Section color="gray">
          <View styleName="flex-shrink-1 jc-center ai-center">
            <FeaturesContent />
          </View>
        </Section>
        <Section>
          <View styleName="flex-md-row flex-shrink-1 jc-md-center ai-md-center mx-3 mx-md-0">
            <Button color="dark" to="/login" styleName="mr-md-2 mb-2 mb-md-0" size="lg">
              <Text>Anmelden</Text>
            </Button>
            <Button
              to="https://www.azubinet.de"
              external
              color="secondary"
              styleName="ml-md-2 mt-2 mt-md-0"
              size="lg"
            >
              <Text>Azubinet Home</Text>
            </Button>
          </View>
        </Section>
      </View>
      <RequestAccountModal modal={requestModal} />
    </>
  );
}

export default LandingOthers;
