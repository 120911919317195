// @flow
import React, { useId } from 'react';
import { Checkbox, FormCheck, Text } from 'bootstrap-rn';
import useFormField from './useFormField';
import Field from './Field';

type Props = {
  name: string,
  niceName?: Intlized$Message | string | null,
  title?: Intlized$Message | string | null,
  info?: Intlized$Message | string | null,
  disabled?: boolean,
  onValueChange?: (any) => void,
  label: React$Node,
};

const FormCheckbox = React.forwardRef<Props, any>((props, ref) => {
  const {
    name,
    niceName,
    title,
    label,
    info,
    disabled = false,
    onValueChange,
    ...elementProps
  } = props;

  const field = useFormField(name, niceName, title);
  const id = useId();

  return (
    <Field
      title={title}
      error={field.error}
      touched={field.touched}
      info={info}
      labelID={id}
      elementProps={elementProps}
    >
      <FormCheck
        aria-labelledby={title && id}
        invalid={field.touched && !!field.error}
        disabled={disabled}
        styleName="mt-2"
      >
        <Checkbox
          ref={ref}
          name={name}
          value={field.value}
          onValueChange={(nextValue) => {
            field.setValue(nextValue, onValueChange);
          }}
          onBlur={() => {
            field.setTouched();
          }}
          aria-labelledby={id}
          id={`${id}-action`}
        />
        <FormCheck.Label htmlFor={`${id}-action`} id={id}>
          {typeof label === 'string' ? <Text>{label}</Text> : label}
        </FormCheck.Label>
      </FormCheck>
    </Field>
  );
});

export default (FormCheckbox: React$AbstractComponent<Props, any>);
