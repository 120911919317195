// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const OrganizationList = {
  name: 'OrganizationList',
  bundle: () => import('./OrganizationList'),
};

const DOMAIN_ORGANIZATIONS_QUERY = gql`
  query DomainOrganizationsQuery($slug: String!) {
    organization(slug: $slug) {
      ownsDomain
      domain {
        organizations(excludeDomainOwner: true) {
          name
          proUntil
          trialUntil
        }
      }
      viewerIsAdmin
    }
  }
`;

const Container = createAsyncContainer(OrganizationList, (props) => {
  const {
    params: { slug },
  } = props;

  const variables = { slug };

  const loaders = {
    query: query(DOMAIN_ORGANIZATIONS_QUERY, { variables }),
  };

  const selectors = {
    organization: select(DOMAIN_ORGANIZATIONS_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
