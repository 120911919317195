// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import { DOCUMENT_ACTIVITY_VERSION_ICON_FRAGMENT } from '../DocumentActivityVersionIcon/fragments';

export const ACTIVITY_EDITOR_TITLE_FRAGMENT: DocumentNode = gql`
  fragment ActivityEditorTitleFragment on CourseActivity {
    title
    published
    version(versionNumber: $versionNumber, specificVersion: $specificVersion) {
      ...DocumentActivityVersionIconFragment
    }
  }

  ${DOCUMENT_ACTIVITY_VERSION_ICON_FRAGMENT}
`;
