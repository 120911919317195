// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query, selectByRoot } from 'libs/graphql';

const NewTeam = {
  name: 'NewTeam',
  bundle: () => import('./NewTeam'),
};

const ORGANIZATION_QUERY = gql`
  query OrganizationQuery($slug: String!) {
    organization(slug: $slug) {
      name
      slug
      viewerIsAdmin
      viewerIsMember
    }
  }
`;

const Container = createAsyncContainer(NewTeam, (props) => {
  const {
    params: { slug },
  } = props;

  const variables = { slug };

  const loaders = {
    query: query(ORGANIZATION_QUERY, { variables }),
  };

  const selectors = {
    organization: select(ORGANIZATION_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
