// @flow
import React from 'react';
import { useIntl } from 'libs/intl';
import { FeatureStripe } from 'components/pages';
import { Modal, Text, CloseButton, View } from 'components/core';
import { Video } from 'components/common';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { useToggleState } from 'libs/misc';

const styles = StyleSheet.create({
  videoDesktop: css`
    width: 798px;
    height: 449px;
  `,
  videoMobile: css`
    width: 100%;
  `,
});

function PresentationSection(): React$Node {
  const intl = useIntl();
  const modal = useToggleState(false);
  const media = useMedia();

  return (
    <>
      {intl.locale === 'en' && (
        <>
          <FeatureStripe
            title="Master your exams"
            content="Join our online study courses to prepare on your exams with your workmates and classmates. Take tests, quiz with flashcards, watch videos, read articles and ask questions to master your exams."
            image="/img/home/presentation-section-1.png"
            textPosition="left"
            buttonOnPress
            onPress={modal.toggle}
            textButton="Watch tutorial video"
            icon="play-circled"
          />
          <FeatureStripe
            title="High-quality materials"
            content="Our exams, flashcards and videos are following the guidelines and previous exams of the IHK and/or the official syllabus."
            image="/img/home/presentation-section-2.png"
            textPosition="right"
            buttonLink
            to="/file/Azubinet/WiSo-II-Probeklausur-1-Leseprobe.pdf"
            textButton="Mock Exam als PDF"
            external
            icon="file-pdf"
          />
          <FeatureStripe
            title="Benefit from others and share your experiences"
            content="You want to know about the last years' exams? What issues were covered and which topics were really tough? You can use free reviews from former users. Done with your exam? Share your materials and add your reviews."
            image="/img/home/presentation-section-3.png"
            textPosition="left"
            buttonLink
            to="cashback"
            textButton="Learn more"
          />
          <FeatureStripe
            title="Platform for your company"
            content="Your trainers and instructors may use Azubinet as well. You can use Azubinet for any kind of topics you're covering during your apprenticeship, either materials for seminars or company-own study materials."
            image="/img/home/presentation-section-4.png"
            textPosition="right"
            buttonLink
            to="business"
            textButton="For Business"
          />
        </>
      )}
      {intl.locale === 'de' && (
        <>
          <FeatureStripe
            title="Meistere deine Prüfungen"
            content="In unseren interaktiven Kursen bereitest du dich mit anderen Auszubildenden auf deine Prüfungen vor: Wissenstests absolvieren, mit Karteikarten üben, Lernvideos schauen, Artikel durchstöbern, Fragen stellen und vieles mehr."
            image="/img/home/presentation-section-1.png"
            textPosition="left"
            buttonOnPress
            onPress={modal.toggle}
            textButton="Beispielvideo ansehen"
            icon="play-circled"
          />
          <FeatureStripe
            title="Hochwertige Lerninhalte"
            content="Lerne mit tausenden Karteikarten, Prüfungssimulationen und Videos. Unsere Lernmaterialien orientieren sich an den originalen IHK-Prüfungen, den Prüfungskatalogen und den Rahmenlehrplänen jedes einzelnen Berufes."
            image="/img/home/presentation-section-2.png"
            textPosition="right"
            buttonLink
            to="/file/Azubinet/WiSo-II-Probeklausur-1-Leseprobe.pdf"
            textButton="Beispielklausur als PDF"
            external
            icon="file-pdf"
          />
          <FeatureStripe
            title="Lernen in der Community"
            content="Das Azubinet-Team hilft dir weiter, wenn du Probleme beim Lernen hast. Nutze außerdem die Erfahrungsberichte, die von deinen Vorgängern erstellt wurden und teile deine eigenen Erfahrungen!"
            image="/img/home/presentation-section-3.png"
            textPosition="left"
            buttonLink
            to="cashback"
            textButton="Mehr erfahren"
          />
          <FeatureStripe
            title="Plattform für deinen Betrieb"
            content="Dein Ausbildungsbetrieb und deine Schule können Azubinet ebenfalls nutzen. Mit den zusätzlichen Funktionen für Ausbildungsbetriebe bleiben alle Azubis vom ersten Tag der Ausbildung an am Ball."
            image="/img/home/presentation-section-4.png"
            textPosition="right"
            buttonLink
            to="business"
            textButton="Für Betriebe"
          />
        </>
      )}
      {modal.on && (
        <Modal visible={modal.on} onToggle={modal.toggle} size="lg">
          <Modal.Header styleName="flex-row">
            <Modal.Title>
              {intl.locale === 'de' ? (
                <Text>Beispiel-Erklärvideo</Text>
              ) : (
                <Text>One of our tutorial videos</Text>
              )}
            </Modal.Title>
            <CloseButton onPress={modal.toggle} />
          </Modal.Header>
          <Modal.Body styleName="p-0">
            <View style={[media.up('md') ? styles.videoDesktop : styles.videoMobile]}>
              <Video
                source={{ uri: '/video/store/optimale-bestellmenge-beispielaufgabe-720p.mp4' }}
                poster="/img/store/grosshandel-optimale-bestellmenge-beispielaufgabe.jpg"
                controls
              />
            </View>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default PresentationSection;
