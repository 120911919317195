// @flow

export default function getOptionValue(
  option: Entity<GQLMultipleChoiceOption>,
  optionValues: Array<Entity<GQLMultipleChoiceSolutionOptionValue>>,
): ?boolean {
  const item = optionValues.find((optionValue) => {
    return optionValue.position === option.position;
  });

  if (!item) {
    return null;
  }

  return item.value;
}
