// @flow
import React from 'react';
import { Platform } from 'react-native';
import { StyleSheet, css } from 'libs/ui';
import { View } from 'bootstrap-rn';

type Props = {
  children: React$Node,
};

const styles = StyleSheet.create({
  tableBody: css``,
});

function TableBody(props: Props): React$Node {
  const { children } = props;

  const role = Platform.OS === 'web' ? 'rowgroup' : null;

  return (
    <View role={role} style={styles.tableBody}>
      {children}
    </View>
  );
}

export default TableBody;
