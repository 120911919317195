// @flow
import { useState, useEffect } from 'react';
import { Image as handler } from 'react-native';
import { Domain } from 'libs/misc';

type ImageDimensions = {
  height: number,
  width: number,
};

export default function useImageDimensions(url: string): ?ImageDimensions {
  const [dimensions, setDimensions] = useState(null);

  useEffect(() => {
    try {
      handler.getSize(Domain.url(url), (width, height) => {
        setDimensions({ width, height });
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, []);

  return dimensions;
}
