// @flow
import React, { useId } from 'react';
import { Picker } from 'bootstrap-rn';
import { useIntl } from 'libs/intl';
import useFormField from './useFormField';
import Field from './Field';

type Props = {
  name: string,
  niceName?: Intlized$Message | string | null,
  title?: Intlized$Message | string | null,
  info?: Intlized$Message | string | null,
  disabled?: boolean,
  onValueChange?: (any) => void,
  placeholder?: Intlized$Message | string | null,
  size?: 'sm' | 'lg' | null,
  options: Array<{
    value: any,
    label: any,
  }>,
  inputStyle?: any,
};

const FormPicker = React.forwardRef<Props, any>((props, ref) => {
  const {
    name,
    niceName,
    title,
    placeholder = '',
    size,
    options,
    info,
    disabled = false,
    onValueChange,
    inputStyle,
    ...elementProps
  } = props;

  const field = useFormField(name, niceName, title);
  const id = useId();
  const intl = useIntl();

  return (
    <Field
      title={title}
      error={field.error}
      touched={field.touched}
      info={info}
      labelID={id}
      elementProps={elementProps}
    >
      <Picker
        ref={ref}
        aria-labelledby={title && id}
        name={name}
        selectedValue={field.value}
        onValueChange={(nextValue) => {
          field.setValue(nextValue, onValueChange);
        }}
        onBlur={() => {
          field.setTouched();
        }}
        placeholder={
          placeholder && typeof placeholder === 'object' ? intl.trans(placeholder) : placeholder
        }
        size={size}
        invalid={field.touched && !!field.error}
        disabled={disabled}
        style={inputStyle}
      >
        {options.map((option) => (
          <Picker.Item label={option.label} value={option.value} key={option.value} />
        ))}
      </Picker>
    </Field>
  );
});

export default (FormPicker: React$AbstractComponent<Props, any>);
