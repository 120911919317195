// @flow
import { createAsyncContainer } from 'libs/container';

const Career = {
  name: 'Career',
  bundle: () => import('./Career'),
};

const Container = createAsyncContainer(Career, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
