// @flow
import React from 'react';
import { createDict, Trans, TimeAgo, useIntl } from 'libs/intl';
import { Editor } from 'components/editor';
import { Form } from 'components/forms';
import { StyleSheet, css } from 'libs/ui';
import { useToggleState, useAlerts } from 'libs/misc';
import { useAnchor } from 'libs/scroll';
import { useSearchParams } from 'libs/routing';
import { isOptimistic, useDispatch, useMutation } from 'libs/graphql';
import { Markdown } from 'components/markdown';
import { Icon, Avatar, ConfirmationModal } from 'components/common';
import { View, Text, Button } from 'components/core';
import {
  putReaction,
  deletePost,
  updatePost,
  deleteThread,
} from 'views/_shared/Comments/mutations';
import ProfilePopover from 'views/_shared/ProfilePopover';
import ProfileLinkPopover from 'views/_shared/ProfileLinkPopover';

type Props = {
  courseId: Id,
  itemType: 'TaskIntro' | 'TaskPart' | 'OpenQuestionSolution',
  itemId: Id,
  post: Entity<GQLThreadPost>,
  onReplyPress: () => void,
  last: boolean,
  thread: Entity<GQLThread>,
  opening?: boolean,
};

const dict = createDict({
  reply: 'Reply',
  post: 'post',
  write_a_post: 'Write a post...',
  edit: 'Edit',
  cancel: 'Cancel',
  delete_post_and_replies: 'Delete post and replies',
  delete_post: 'Delete post',
  do_you_really_want_to_delete_this_post_and_all_replies:
    'Do you really want to delete this post and all replies?',
  do_you_really_want_to_delete_this_post: 'Do you really want to delete this post?',
  post_and_all_replies_have_been_deleted: 'Post and all replies have been deleted.',
  post_has_been_deleted: 'Post has been deleted.',
  delete: 'Delete',
});

const styles = StyleSheet.create({
  container: css`
    flex-direction: row;
    margin-bottom: 0.25rem;
    border-left-width: 0.125rem;
    border-left-color: #c8d1d3;
    margin-left: 0.9375rem;
    padding-left: 0.9375rem;
  `,
  containerLast: css`
    margin-left: 1.0625rem;
    border-left-width: 0;
  `,
  avatar: css`
    flex-basis: 2rem;
    margin-left: -2rem;
  `,
  avatarBottom: css`
    background-color: #f5f5f5;
    border-bottom-width: 0.25rem;
    border-bottom-color: #fafafa;
  `,
  text: css`
    flex-basis: auto;
    flex-shrink: 1;
    flex-grow: 1;
    margin-bottom: 0.5rem;
    margin-horizontal: 0.75rem;
  `,
  info: css`
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
    flex-direction: row;
    flex-shrink: 1;
    align-items: center;
    justify-content: space-between;
  `,
  actions: css`
    flex-direction: row;
    margin-top: 0.25rem;
    margin-left: -0.5rem;
    height: 1.75rem;
  `,
  active: css`
    color: #0097bc;
  `,
  actionButtonGroup: css`
    flex-direction: row;
    margin-left: 1rem;
    margin-right: -0.75rem;
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  `,
  actionButton: css`
    width: 2rem;
    align-items: center;
  `,
  smallText: css`
    font-size: 0.875rem;
  `,
});

const defaultProps = {
  opening: false,
};

function Post({
  courseId,
  itemType,
  itemId,
  post,
  onReplyPress: handleReplyPress,
  last,
  thread,
  opening,
}: Props): React$Node {
  const deletePostMutation = useMutation();
  const dispatch = useDispatch();
  const loading = useToggleState(false);
  const alerts = useAlerts();
  const intl = useIntl();

  const [searchParams] = useSearchParams();
  const selectedPostId = searchParams.get('post_id');

  const ref = useAnchor(selectedPostId === post.id);

  const handleChange = (value) => {
    // block action if loading
    if (loading.on) {
      return;
    }

    loading.setOn();
    dispatch(putReaction(post.id, post.viewerReaction, value)).finally(() => {
      loading.setOff();
    });
  };

  const value = post.viewerReaction && post.viewerReaction.value;

  const deletePostModal = useToggleState(false);
  const editPost = useToggleState(false);

  return (
    <View style={[styles.container, last && styles.containerLast]} ref={ref}>
      <View style={styles.avatar}>
        <ProfilePopover user={post.author}>
          <Avatar avatar={post.author.avatar} />
        </ProfilePopover>
        <View style={styles.avatarBottom} />
      </View>
      <View style={styles.text}>
        <View style={styles.info}>
          <View styleName="flex-row flex-shrink-1 ai-center jc-start">
            <Text>
              <ProfileLinkPopover user={post.author}>
                <Text small>{post.author.name}</Text>
              </ProfileLinkPopover>{' '}
              <TimeAgo value={post.createdAt} color="muted" small />
            </Text>
          </View>
          {(post.viewerCanUpdate || post.viewerCanDelete) && (
            <View style={styles.actionButtonGroup}>
              {post.viewerCanUpdate && (
                <Button
                  color="light"
                  size="sm"
                  onPress={() => {
                    editPost.setOn();
                  }}
                  style={styles.actionButton}
                >
                  <Icon glyph="edit" small />
                </Button>
              )}
              {post.viewerCanDelete && (
                <Button
                  color="light"
                  size="sm"
                  onPress={() => {
                    deletePostModal.setOn();
                  }}
                  style={styles.actionButton}
                >
                  <Icon glyph="trash-empty" small />
                </Button>
              )}
            </View>
          )}
        </View>
        {!editPost.on && <Markdown source={post.text} textStyle={styles.smallText} />}
        {editPost.on && (
          <Form
            initialValues={{
              text: post.text,
            }}
            validate={{
              text: 'required',
            }}
            onSubmit={async (values) => {
              await dispatch(updatePost(post.id, values.text));

              editPost.setOff();
            }}
            styleName="mr-n2"
          >
            <Editor
              name="text"
              size="sm"
              niceName={dict('post')}
              placeholder={dict('write_a_post')}
            />
            <View styleName="flex-row jc-end mt-n2">
              <Button
                size="sm"
                color="link"
                onPress={() => {
                  editPost.setOff();
                }}
                styleName="mr-2"
              >
                <Trans {...dict('cancel')} />
              </Button>
              <Form.Button size="sm" type="submit">
                <Trans {...dict('edit')} />
              </Form.Button>
            </View>
          </Form>
        )}

        <View style={styles.actions}>
          {!isOptimistic(post) && (
            <>
              <Button
                color="light"
                size="sm"
                onPress={() => {
                  handleChange(value === 'THUMB_UP' ? null : 'THUMB_UP');
                }}
                styleName="flex-row ai-center"
              >
                <Icon
                  glyph="thumbs-up"
                  styleName="mr-2"
                  style={value === 'THUMB_UP' && styles.active}
                />
                <Text color="success">{post.reactionsThumbUpCount}</Text>
              </Button>
              <Button
                color="light"
                size="sm"
                onPress={() => {
                  handleChange(value === 'THUMB_DOWN' ? null : 'THUMB_DOWN');
                }}
                styleName="flex-row ai-center"
              >
                <Icon
                  glyph="thumbs-down"
                  styleName="mr-2"
                  style={value === 'THUMB_DOWN' && styles.active}
                />
                <Text color="danger">{post.reactionsThumbDownCount}</Text>
              </Button>
              <Button color="light" size="sm" onPress={handleReplyPress}>
                <Trans {...dict('reply')} />
              </Button>
            </>
          )}
        </View>
      </View>

      <ConfirmationModal
        color="danger"
        button={<Trans {...dict(opening ? 'delete_post_and_replies' : 'delete_post')} />}
        visible={deletePostModal.on}
        onClose={deletePostModal.toggle}
        loading={deletePostMutation.loading}
        onSubmit={() => {
          if (opening) {
            deletePostMutation
              .dispatch(deleteThread(thread.id, courseId, itemType, itemId))
              .then(() => {
                alerts.add({
                  color: 'success',
                  content: intl.trans(dict('post_and_all_replies_have_been_deleted')),
                });
                deletePostModal.setOff();
              })
              .catch(() => {});
          } else {
            deletePostMutation
              .dispatch(deletePost(thread.id, post.id))
              .then(() => {
                alerts.add({
                  color: 'success',
                  content: intl.trans(dict('post_has_been_deleted')),
                });
                deletePostModal.setOff();
              })
              .catch(() => {});
          }
        }}
      >
        <Trans
          {...dict(
            opening
              ? 'do_you_really_want_to_delete_this_post_and_all_replies'
              : 'do_you_really_want_to_delete_this_post',
          )}
        />
      </ConfirmationModal>
    </View>
  );
}

Post.defaultProps = defaultProps;

export default Post;
