// @flow
import React from 'react';
import Form from 'components/forms/Form';
import Input from './Input';

type Props = {
  name: string,
  attachable?: Entity<
    GQLArticle | GQLDocument | GQLSurvey | GQLQuiz | GQLTaskCollection | GQLVideo | GQLBlog,
  >,
};

const Editor = React.forwardRef<Props, any>((props, ref) => {
  const { name, attachable, ...elementProps } = props;

  return (
    <Form.Input
      {...elementProps}
      name={name}
      ref={ref}
      element={<Input name={name} attachable={attachable} />}
      multiline
    />
  );
});

export default (Editor: React$AbstractComponent<Props, any>);
