// @flow
import React from 'react';
import { Icon } from 'components/common';
import { View, Text, Button } from 'components/core';
import { StyleSheet, css, useMedia } from 'libs/ui';

type Props = {
  bkf: ToggleState,
  hazmat: ToggleState,
  haulage: ToggleState,
  taxi: ToggleState,
  spedi: ToggleState,
};

const styles = StyleSheet.create({
  darken: css`
    background-color: #55a428;
  `,
  gridBox: css`
    align-items: flex-start;
  `,
  gridBoxDesktop: css`
    width: 20%;
  `,
  gridBoxMobile: css`
    width: 100%;
  `,
  button: css`
    height: 9rem;
    width: 100%;
  `,
  icon: css`
    font-size: 3rem;
    line-height: 2rem * $line-height-base;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    color: #fff;
  `,
  iconBus: css`
    font-size: 2.5rem;
    line-height: 2rem * $line-height-base;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    color: #fff;
  `,
  iconBuilding: css`
    font-size: 2.5rem;
    line-height: 2rem * $line-height-base;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    color: #fff;
  `,
  text: css`
    font-size: 1rem;
    color: #fff;
  `,
});

function TargetGroupsDesktop({ bkf, hazmat, haulage, taxi, spedi }: Props): React$Node {
  const media = useMedia();

  return (
    <View styleName="flex-md-row flex-grow-1 p-0 m-0">
      <View
        style={[
          styles.gridBox,
          bkf.on && styles.darken,
          media.up('md') ? styles.gridBoxDesktop : styles.gridBoxMobile,
        ]}
        styleName="p-0 m-0"
      >
        <Button
          color="light"
          onPress={() => {
            bkf.setOn();
            hazmat.setOff();
            haulage.setOff();
            taxi.setOff();
            spedi.setOff();
          }}
          style={[styles.gridBox, styles.button]}
        >
          <View styleName="flex-column flex-grow-1 flex-shrink-1">
            <View styleName="flex-row ai-center jc-center">
              <Icon glyph="truck" style={[styles.gridBox, styles.icon]} styleName="mr-1" />
              <Icon glyph="bus" style={[styles.gridBox, styles.iconBus]} styleName="ml-1" />
            </View>
            <Text style={styles.text}>BKF-Ausbildung</Text>
          </View>
        </Button>
      </View>
      <View
        style={[
          styles.gridBox,
          hazmat.on && styles.darken,
          media.up('md') ? styles.gridBoxDesktop : styles.gridBoxMobile,
        ]}
        styleName="p-0 m-0"
      >
        <Button
          color="light"
          onPress={() => {
            bkf.setOff();
            hazmat.setOn();
            haulage.setOff();
            taxi.setOff();
            spedi.setOff();
          }}
          style={[styles.gridBox, styles.button]}
        >
          <View styleName="flex-column flex-grow-1">
            <Icon glyph="fire-1" style={[styles.gridBox, styles.icon]} />
            <Text style={styles.text}>Gefahrgut</Text>
          </View>
        </Button>
      </View>
      <View
        style={[
          styles.gridBox,
          haulage.on && styles.darken,
          media.up('md') ? styles.gridBoxDesktop : styles.gridBoxMobile,
        ]}
        styleName="p-0 m-0"
      >
        <Button
          color="light"
          onPress={() => {
            bkf.setOff();
            hazmat.setOff();
            haulage.setOn();
            taxi.setOff();
            spedi.setOff();
          }}
          style={[styles.gridBox, styles.button]}
        >
          <View styleName="flex-column flex-grow-1 flex-shrink-1">
            <View styleName="flex-row ai-center jc-center">
              <Icon glyph="building-filled" style={[styles.iconBuilding]} styleName="mr-2" />
              <Icon glyph="truck" style={[styles.icon]} styleName="ml-2" />
            </View>
            <Text style={styles.text}>Fachkunde Güterkraftverkehr</Text>
          </View>
        </Button>
      </View>
      <View
        style={[
          styles.gridBox,
          taxi.on && styles.darken,
          media.up('md') ? styles.gridBoxDesktop : styles.gridBoxMobile,
        ]}
        styleName="p-0 m-0"
      >
        <Button
          color="light"
          onPress={() => {
            bkf.setOff();
            hazmat.setOff();
            haulage.setOff();
            taxi.setOn();
            spedi.setOff();
          }}
          style={[styles.gridBox, styles.button]}
        >
          <View styleName="flex-column flex-grow-1 flex-shrink-1">
            <View styleName="flex-row ai-center jc-center">
              <Icon glyph="person" style={[styles.iconBuilding]} styleName="mr-2" />
              <Icon glyph="taxi" style={[styles.iconBuilding]} styleName="ml-2" />
            </View>
            <Text style={styles.text}>Fachkunde Taxi</Text>
          </View>
        </Button>
      </View>
      <View
        style={[
          styles.gridBox,
          spedi.on && styles.darken,
          media.up('md') ? styles.gridBoxDesktop : styles.gridBoxMobile,
        ]}
        styleName="p-0 m-0"
      >
        <Button
          color="light"
          onPress={() => {
            bkf.setOff();
            hazmat.setOff();
            haulage.setOff();
            taxi.setOff();
            spedi.setOn();
          }}
          style={[styles.gridBox, styles.button]}
        >
          <View styleName="flex-column flex-grow-1 flex-shrink-1">
            <View styleName="flex-row ai-center jc-center">
              <Icon glyph="ship" style={[styles.iconBuilding]} styleName="mr-2" />
              <Icon glyph="truck" style={[styles.iconBuilding]} styleName="ml-2" />
              <Icon glyph="flight" style={[styles.iconBuilding]} styleName="ml-2" />
            </View>
            <Text style={styles.text}>Abschlussprüfung Speditionskaufleute</Text>
          </View>
        </Button>
      </View>
    </View>
  );
}

export default TargetGroupsDesktop;
