// @flow
import React from 'react';
import { SafeAreaView } from 'react-native';
import { StyleSheet, css } from 'libs/ui';
import FixedView from './FixedView';

type Props = {
  children: Array<React$Element<*>>,
};

const styles = StyleSheet.create({
  stack: css`
    right: 0;
    left: 0;
    max-width: 496px;
    margin-horizontal: auto;
    z-index: 1040;
    // Workaround for Android as Android does not support z-index.
    elevation: 1;
  `,
});

function AlertsStack({ children }: Props): React$Node {
  return (
    <FixedView style={styles.stack}>
      <SafeAreaView>{children.reverse()}</SafeAreaView>
    </FixedView>
  );
}

export default AlertsStack;
