// @flow
import { Api } from 'libs/misc';
import handleAttributeValue from './handleAttributeValue';

type ValidateFunction = (
  value: any,
  parameters: string[],
  key: string,
  values: Object,
) => boolean | Promise<any>;

const requireParameterCount = (count, parameters, rule) => {
  if (parameters.length < count) {
    throw new Error(`Validation rule ${rule} requires at least ${count} parameters.`);
  }
};

// This object imitates the Laravel validation methods.
const rules = {
  alpha(value, parameters) {
    if (parameters[0] === 'ascii') {
      return typeof value === 'string' && value.search(/^[a-zA-Z]+$/u) !== -1;
    }

    return typeof value === 'string' && value.search(/^[\p{L}\p{M}]+$/u) !== -1;
  },
  alpha_dash(value, parameters) {
    if (typeof value !== 'string' && typeof value !== 'number') {
      return false;
    }

    if (parameters[0] === 'ascii') {
      return value.search(/^[a-zA-Z0-9_-]+$/u) !== -1;
    }

    return value.search(/^[\p{L}\p{M}\p{N}_-]+$/u) !== -1;
  },
  alpha_num(value, parameters) {
    if (typeof value !== 'string' && typeof value !== 'number') {
      return false;
    }

    if (parameters[0] === 'ascii') {
      return value.search(/^[a-zA-Z0-9]+$/u) !== -1;
    }

    return value.search(/^[\p{L}\p{M}\p{N}]+$/u) !== -1;
  },
  confirmed(value, parameters, key, data) {
    return value === data[`${key}_confirmation`];
  },
  distinct(value, parameters, key, data) {
    if (!value) {
      return true;
    }

    const values = [];

    handleAttributeValue(key, data, (_, v) => {
      values.push(v);
    });

    return new Set(values).size === values.length;
  },
  email(value) {
    return !value || value.search(/^\S+@\S+\.\S+$/) !== -1;
  },
  exists(value, parameters, key) {
    requireParameterCount(1, parameters, 'exists');

    const body = new FormData();

    body.append('rule', `exists:${parameters[0]}`);
    body.append('key', key);
    body.append('value', value);

    return Api.post('/api/validation', { body });
  },
  gt(value, parameters) {
    requireParameterCount(1, parameters, 'gt');

    return !value || (rules.numeric(parameters[0]) && Number(value) > parameters[0]);
  },
  gte(value, parameters) {
    requireParameterCount(1, parameters, 'gte');

    return !value || (rules.numeric(parameters[0]) && Number(value) >= parameters[0]);
  },
  lt(value, parameters) {
    requireParameterCount(1, parameters, 'lt');

    return !value || (rules.numeric(parameters[0]) && Number(value) < parameters[0]);
  },
  lte(value, parameters) {
    requireParameterCount(1, parameters, 'lte');

    return !value || (rules.numeric(parameters[0]) && Number(value) <= parameters[0]);
  },
  max(value, parameters) {
    requireParameterCount(1, parameters, 'max');

    return !value || value.length <= parameters[0];
  },
  min(value, parameters) {
    requireParameterCount(1, parameters, 'min');

    return !value || value.length >= parameters[0];
  },
  numeric(value) {
    return !value || Number.isInteger(Number(value));
  },
  required(value) {
    return (Array.isArray(value) && value.length > 0) || !!value;
  },
  required_if(value, parameters, key, data) {
    requireParameterCount(2, parameters, 'required_if');

    if (!data[parameters[0]]) {
      return true;
    }

    const other = data[parameters[0]];

    const values = parameters.slice(1);

    if (values.includes(other)) {
      return rules.required(value);
    }

    return true;
  },
  required_with(value, parameters, key, data) {
    requireParameterCount(1, parameters, 'required_with');

    if (!data[parameters[0]]) {
      return true;
    }

    const other = data[parameters[0]];

    if (other !== null && other !== undefined) {
      return rules.required(value);
    }

    return true;
  },
  unique(value, parameters, key) {
    requireParameterCount(1, parameters, 'unique');

    const body = new FormData();

    body.append('rule', `unique:${parameters[0]}`);
    body.append('key', key);
    body.append('value', value);

    return Api.post('/api/validation', { body });
  },
};

export default function getValidationRule(key: string): ValidateFunction {
  return rules[key];
}
