// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const MULTIPLE_CHOICE_RESULT_FRAGMENT: DocumentNode = gql`
  fragment MultipleChoiceResultFragment on MultipleChoice {
    text
    type
    sampleSolution {
      optionValues {
        position
        value
      }
      explanation
    }
    examAttemptSolution(slug: $slug, attemptId: $attemptId) {
      optionValues {
        position
        value
      }
    }
    options {
      position
      text
    }
  }
`;
