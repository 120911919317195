// @flow
import React from 'react';
import BundleCompact from './BundleCompact';

type Props = {
  onClose?: () => void,
};

function KaufleuteImGrossUndAussenhandelBundles({ onClose }: Props): React$Node {
  return (
    <>
      <BundleCompact
        to="/courses/wnHFB8q97X"
        title="Erstmal reinschnuppern"
        price="kostenlose Lernmaterialien und Community"
        onClose={onClose}
      />
    </>
  );
}

export default KaufleuteImGrossUndAussenhandelBundles;
