// @flow
import { createAsyncContainer } from 'libs/container';
import { select, query, gql } from 'libs/graphql';
import { getVersion } from 'utils/courses';
import { DOMAIN_QUERY } from 'libs/foundation/queries';
import { COMMENTS_FRAGMENT } from 'views/_shared/Comments';
import { CLOZE_FRAGMENT } from './Cloze/fragments';
import { MULTIPLE_CHOICE_FRAGMENT } from './MultipleChoice/fragments';
import { OPEN_QUESTION_FRAGMENT } from './OpenQuestion/fragments';
import { PAIRING_FRAGMENT } from './Pairing/fragments';
import { TRUE_FALSE_FRAGMENT } from './TrueFalse/fragments';
import { ACTIVITY_DETAILS_TITLE_FRAGMENT } from '../_shared/ActivityDetailsTitle/fragments';
import { ACTIVITY_VIEWER_RESULT_FRAGMENT } from '../_shared/ActivityViewerResult/fragments';
import { EXAM_ACTIONS_FRAGMENT } from '../_shared/ExamActions/fragments';
import { ACTIVITY_DETAILS_SIDEBAR_FRAGMENT } from '../_shared/ActivityDetailsSidebar/fragments';

const TaskCollection = {
  name: 'TaskCollection',
  bundle: () => import('./TaskCollection'),
};

const TASK_COLLECTION_QUERY = gql`
  query CourseTaskCollection(
    $slug: String!
    $courseSlug: String!
    $solutionId: ClientID
    $postId: ClientID
    $versionNumber: Int
    $specificVersion: String
  ) {
    course(slug: $courseSlug) {
      slug
      viewerIsAdmin
      viewerIsMember
      viewerIsObserver
      examActivity(slug: $slug) {
        ...ActivityDetailsTitleFragment
        ...ActivityViewerResultFragment
        ...ExamActionsFragment
        ...ActivityDetailsSidebarFragment
        title
        slug
        published
        versions {
          item {
            exam {
              attemptsCount
            }
          }
        }
        version(versionNumber: $versionNumber, specificVersion: $specificVersion) {
          versionNumber
          releasedAt
          current
          item {
            info
            sampleSolution
            numberingFormat
            downloadable
            partNumberingFormat
            tasks {
              title
              position
              intro {
                text
                commentable
                ...CommentsFragment
              }
              parts {
                points
                position
                title
                ...CommentsFragment
                type {
                  ...ClozeFragment
                  ...MultipleChoiceFragment
                  ...OpenQuestionFragment
                  ...PairingFragment
                  ...TrueFalseFragment
                }
              }
            }
            updatedAt
            createdAt
          }
        }

        updatedAt
        createdAt
        author {
          name
          avatar {
            file {
              name
              extension
              info {
                image {
                  height
                  width
                }
              }
            }
          }
        }
        viewerCanUpdate
      }
    }
  }

  ${CLOZE_FRAGMENT}
  ${MULTIPLE_CHOICE_FRAGMENT}
  ${OPEN_QUESTION_FRAGMENT}
  ${TRUE_FALSE_FRAGMENT}
  ${PAIRING_FRAGMENT}
  ${COMMENTS_FRAGMENT}
  ${ACTIVITY_DETAILS_TITLE_FRAGMENT}
  ${ACTIVITY_VIEWER_RESULT_FRAGMENT}
  ${EXAM_ACTIONS_FRAGMENT}
  ${ACTIVITY_DETAILS_SIDEBAR_FRAGMENT}
`;

const VIEWER_RUNNING_TASK_EXAM_QUERY = gql`
  query ViewerRunningExamQuery {
    viewerRunningTaskExam {
      course {
        slug
      }
      viewerRunningAttempt {
        expiredAt
      }
    }
  }
`;

const Container = createAsyncContainer(TaskCollection, (props) => {
  const {
    params: { slug, courseSlug },
    searchParams,
  } = props;

  const versionNumber = getVersion(searchParams.get('version'));
  const variables = {
    slug,
    courseSlug,
    solutionId: searchParams.get('solution_id'),
    postId: searchParams.get('post_id'),
    item: {
      type: 'TaskCollection',
      slug,
    },
    versionNumber,
    specificVersion: versionNumber ? null : 'CURRENT',
  };

  const loaders = {
    query: query(TASK_COLLECTION_QUERY, { variables }),
    viewerRunningTaskExamQuery: query(VIEWER_RUNNING_TASK_EXAM_QUERY),
  };

  const selectors = {
    course: select(TASK_COLLECTION_QUERY, { variables }),
    viewerRunningTaskExam: select(VIEWER_RUNNING_TASK_EXAM_QUERY),
    domain: select(DOMAIN_QUERY, {}),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
