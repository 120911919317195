// @flow
import React, { useEffect } from 'react';
import { onError } from 'react-transporter';
import { useAlerts } from 'libs/misc';
import { createDict, Trans } from 'libs/intl';

const errorCodes = ['UNAUTHENTICATED', 'FORBIDDEN', 'BAD_USER_INPUT'];

const dict = createDict({
  whooops_something_went_wrong: 'Whooops. Something went wrong.',
});

function ErrorAlert(): React$Node {
  const alerts = useAlerts();

  useEffect(() => {
    onError((error, request) => {
      // Do not throw an alert for queries or non-standard mutation errors.
      if (
        request.type === 'TRANSPORTER_QUERY' ||
        (error.type === 'GraphQLError' &&
          error.data.errors[0].extensions?.code &&
          !errorCodes.includes(error.data.errors[0].extensions?.code))
      ) {
        return;
      }

      alerts.add({
        color: 'danger',
        content: <Trans {...dict('whooops_something_went_wrong')} />,
      });
    });
  }, []);

  return null;
}

export default ErrorAlert;
