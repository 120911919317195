// @flow
import { createAsyncContainer } from 'libs/container';

const Community = {
  name: 'Community',
  bundle: () => import('./Community'),
};

const Container = createAsyncContainer(Community, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
