// @flow
import React from 'react';
import { LargeText } from 'components/common';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  children: React$Node,
  style?: any,
};

const styles = StyleSheet.create({
  azubinetColor: css`
    color: #001f3f;
  `,
});

const BrandLargeText = React.forwardRef<Props, any>((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { children, style, ...elementProps } = props;

  return (
    <LargeText {...elementProps} ref={ref} style={[styles.azubinetColor, styles.textStyle, style]}>
      {children}
    </LargeText>
  );
});

export default (BrandLargeText: React$AbstractComponent<Props, any>);
