// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { View } from 'components/core';
import QuizCardBack from './QuizCardBack';
import QuizCardFront from './QuizCardFront';

type Props = {
  children: React$Node,
  style?: any,
};

const styles = StyleSheet.create({
  card: css`
    background-color: $gray-100;
    border-radius: 0.5rem;

    @include media-breakpoint-up(lg) {
      width: 100%;
      flex-direction: row;
      flex-grow: 1;
    }
  `,
});

function QuizCard({ children, style, ...elementProps }: Props): React$Node {
  return (
    <View {...elementProps} style={[styles.card, style]}>
      {children}
    </View>
  );
}

QuizCard.Back = QuizCardBack;
QuizCard.Front = QuizCardFront;

export default QuizCard;
