// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql, selectByRoot } from 'libs/graphql';

const CourseSettingsMembers = {
  name: 'CourseSettingsMembers',
  bundle: () => import('./CourseSettingsMembers'),
};

const COURSE_SETTINGS_MEMBERS_QUERY = gql`
  query CourseSettingsMembersQuery($courseSlug: String!) {
    course(slug: $courseSlug) {
      description
      paid
      organization {
        viewerIsAdmin
        viewerIsTrainer
        ownsDomain
        domain {
          isMain
        }
      }
      memberships {
        role
        licensed {
          unlimited
          validUntil
        }
        createdAt
        user {
          name
          avatar {
            file {
              name
              extension
              info {
                image {
                  height
                  width
                }
              }
            }
          }
          createdAt
        }
      }
      teams {
        name
        slug
        organization {
          slug
        }
      }
      viewerIsAdmin
    }
  }
`;

const Container = createAsyncContainer(CourseSettingsMembers, (props) => {
  const {
    params: { courseSlug },
  } = props;

  const variables = { courseSlug };

  const loaders = {
    query: query(COURSE_SETTINGS_MEMBERS_QUERY, {
      variables,
    }),
  };

  const selectors = {
    course: select(COURSE_SETTINGS_MEMBERS_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
