// @flow
import { createContainer } from 'libs/container';
import { selectByRoot } from 'libs/graphql';
import Store from './Store';

const Container = createContainer(Store, () => {
  const selectors = {
    viewer: selectByRoot('viewer', (viewer) =>
      viewer.join('organizationMemberships', (organizationMembership) =>
        organizationMembership.join('organization'),
      ),
    ),
  };

  return {
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
