// @flow
import React from 'react';
import { Grid, Container } from 'components/core';
import { StyleSheet, css } from 'libs/ui';
import PageHeader from './PageHeader';
import PageTitle from './PageTitle';
import PageMain from './PageMain';
import PageAside from './PageAside';
import useDocumentTitle from '../../libs/misc/useDocumentTitle';

type Props = {
  children: React$Node,
  windowTitle?: Intlized$Message | string,
};

const styles = StyleSheet.create({
  container: css`
    margin-top: 2rem;
  `,
});

function Page({ children, windowTitle, ...elementProps }: Props): React$Node {
  if (windowTitle) {
    useDocumentTitle(windowTitle);
  }

  return (
    <Container {...elementProps} style={styles.container}>
      <Grid>{children}</Grid>
    </Container>
  );
}

Page.Header = PageHeader;
Page.Title = PageTitle;
Page.Main = PageMain;
Page.Aside = PageAside;

export default Page;
