// @flow
import matchOpeningTag from '../utils/matchOpeningTag';
import isClosingTag from '../utils/isClosingTag';
import getTagAttributes from '../utils/getTagAttributes';
import validateTag from '../utils/validateTag';
import wrapTable from '../utils/wrapTable';
import countTrailingSpaces from '../utils/countTrailingSpaces';

const allowedTags = {
  div: { type: 'paragraph', inline: true },
  table: { type: 'table' },
  thead: { type: 'tableHead' },
  tbody: { type: 'tableBody' },
  tr: { type: 'tableRow' },
  th: { type: 'tableCell', inline: true },
  td: { type: 'tableCell', inline: true },
  hr: { type: 'thematicBreak' },
};

export default function htmlBlock() {
  const { Parser } = this;
  const tokenizers = Parser.prototype.blockTokenizers;

  function tokenizeHtmlBlock(eat, value, silent) {
    const self = this;
    const tag = matchOpeningTag(value, 0);

    if (!tag) {
      return undefined;
    }

    const def = allowedTags[tag.name];

    if (!def) {
      return undefined;
    }

    const openingLength = tag.source[0].length;

    // Tag is self-closing tag.
    if (tag.selfClosing) {
      if (silent) {
        return true;
      }

      return eat(value.slice(0, openingLength))({
        type: def.type,
        htmlTag: validateTag(tag),
        ...getTagAttributes(tag),
      });
    }

    // $FlowFixMe[incompatible-use]
    const closingLength = tag.source[1].length;
    let index = openingLength;
    let openings = 1;

    while (index < value.length) {
      // Potential tag found.
      const innerTag = matchOpeningTag(value, index);

      if (innerTag && innerTag.name === tag.name) {
        // Marker is another opening marker.
        index += innerTag.source[0].length;
        openings += 1;
      } else if (isClosingTag(value, index, tag)) {
        // Update openings count.
        index += closingLength;
        openings -= 1;

        // Marker is closing marker, eat value.
        if (openings === 0) {
          if (silent) {
            return true;
          }

          const now = eat.now();

          const innerValue = value.slice(openingLength, index - closingLength);
          const offset = countTrailingSpaces(innerValue);

          now.column += openingLength + offset;
          now.offset += openingLength + offset;

          const trimmedInnerValue = innerValue.slice(
            offset,
            innerValue.length - countTrailingSpaces(innerValue, true),
          );

          let children;

          if (def.inline) {
            children = self.tokenizeInline(trimmedInnerValue, now);
          } else {
            const exit = self.enterBlock();
            children = self.tokenizeBlock(trimmedInnerValue, now);
            exit();
          }

          const htmlTag = validateTag(tag, children);

          // Table does not use thead and tbody tags, so add them.
          if (htmlTag.name === 'table' && htmlTag.validated && children[0].type === 'tableRow') {
            children = wrapTable(children, true);
          }

          return eat(value.slice(0, index + countTrailingSpaces(value.slice(index))))({
            type: def.type,
            htmlTag,
            ...getTagAttributes(tag),
            children,
          });
        }
      }

      index += 1;
    }

    return undefined;
  }

  tokenizers.html = tokenizeHtmlBlock;
}
