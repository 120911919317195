// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const DOMAIN_QUERY: DocumentNode = gql`
  query DomainQuery {
    domain {
      secure
      host
      allowsTrials
      allowsSubdomains
      allowsAiEvaluations
      hasReportCollections
      searchType
      googleAnalyticsId
      ownerOrganization {
        name
        website
        viewerIsAdmin
        viewerIsTrainer
      }
      pwa {
        id
      }
      permissions {
        usersCanSelfRegister
        usersCanCreateInquiries
        usersCanCreateCourses
        usersCanBuyCourses
        usersCanDiscuss
        usersCanUploadAvatars
      }
      theme {
        primaryColor
        secondaryColor
        tertiaryColor
        backgroundColor
        buttonColor
      }
      seo {
        title
        description
      }
      logo {
        name
        extension
        info {
          image {
            height
            width
          }
        }
      }
      navbarLogo {
        name
        extension
        info {
          image {
            height
            width
          }
        }
      }
      favicon {
        name
      }
      primaryCourse {
        slug
        name
      }
    }
  }
`;
