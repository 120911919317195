// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';
import Header from 'components/layout/Header';
import OrganizationHeader from './OrganizationHeader';

const ORGANIZATION_QUERY = gql`
  query OrganizationQuery($slug: String!) {
    organization(slug: $slug) {
      name
      slug
      ownsDomain
      membershipsCount
      relatedCoursesCount
      relatedChecklistsCount
      teamsCount
      licensesCount
      productsCount
      viewerIsAdmin
      viewerIsTrainer
      isPublisher
    }
  }
`;

const options = {
  async: { loading: Header, error: () => null },
};

const Container = createAsyncContainer(
  OrganizationHeader,
  (props) => {
    const {
      params: { slug },
    } = props;

    const variables = {
      slug,
    };

    const loaders = {
      query: query(ORGANIZATION_QUERY, { variables }),
    };

    const selectors = {
      organization: select(ORGANIZATION_QUERY, { variables }),
    };

    return { loaders, selectors };
  },
  options,
);

export default (Container: React$AbstractComponent<any, mixed>);
