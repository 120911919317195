// @flow
import { Link, createMutation, gql } from 'libs/graphql';

const UPDATE_OR_CREATE_SOLUTION = gql`
  mutation UpdateOrCreateSolution($input: MultipleChoiceExamSolutionUpdateOrCreateInput!) {
    multipleChoiceExamSolution_updateOrCreate(input: $input) {
      optionValues {
        position
        value
      }
    }
  }
`;

const updateViewerSolution = (link) => (record) => {
  record.set('viewerRunningExamSolution', link);
};

export function updateOrCreateSolution(
  multipleChoiceId: Id,
  examAttemptId: Id,
  position: number,
  value: ?boolean,
): MutationAction {
  const optionValues =
    value !== null
      ? [
          {
            position,
            value,
          },
        ]
      : [];
  const optionValuePositionsToRemove = value === null ? [position] : [];

  return createMutation(UPDATE_OR_CREATE_SOLUTION, {
    variables: {
      input: {
        multipleChoiceId,
        examAttemptId,
        optionValues,
        optionValuePositionsToRemove,
      },
    },
    updater: (store, data) => {
      // Update link
      const link = new Link(data.roots.multipleChoiceExamSolution_updateOrCreate);
      store.update('MultipleChoice', multipleChoiceId, updateViewerSolution(link));
    },
  });
}
