// @flow
import React from 'react';
import { Icon, TheaterLoading } from 'components/common';
import { StyleSheet, css } from 'libs/ui';

const styles = StyleSheet.create({
  icon: css`
    color: $transparent-200;
    font-size: 10rem;
    line-height: 10rem;
  `,
});

function TaskExamLoading(): React$Node {
  return (
    <TheaterLoading>
      <Icon glyph="award" style={styles.icon} />
    </TheaterLoading>
  );
}

export default TaskExamLoading;
