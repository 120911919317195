// @flow
import { createContainer } from 'libs/container';
import { select, gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import Pairing from './Pairing';

export const PAIRING_EXAM_FRAGMENT: DocumentNode = gql`
  fragment PairingExamFragment on Pairing {
    text
    type
    pieces {
      text
      key
      side
    }
    viewerRunningExamSolution {
      pairs {
        left
        right
      }
    }
  }
`;

const Container = createContainer(Pairing, (props) => {
  const selectors = {
    pairing: select(PAIRING_EXAM_FRAGMENT, {
      entry: ['Pairing', props.id],
    }),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
