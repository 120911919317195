// @flow
import React from 'react';
import { Icon } from 'components/common';
import { View, Button } from 'components/core';
import { BrandHeading, BrandText } from 'components/pages';
import { createDict, Trans } from 'libs/intl';
import { StyleSheet, css, useMedia } from 'libs/ui';

const styles = StyleSheet.create({
  heading: css`
    margin-bottom: 2rem;
  `,
  iconDesktop: css`
    font-size: 3rem;
    line-height: 3rem * $line-height-base;
  `,
  iconMobile: css`
    font-size: 2.5rem;
    line-height: 2.5rem * $line-height-base;
  `,
  buttonDesktop: css`
    padding: 2rem;
  `,
  buttonMobile: css`
    padding: 1.5rem;
  `,
});

const dict = createDict({
  follow_us: 'Follow us',
});

function SocialMedia(): React$Node {
  const media = useMedia();
  return (
    <>
      <View styleName="jc-center ai-center">
        <BrandHeading size={1} style={styles.heading}>
          <Trans {...dict('follow_us')} />
        </BrandHeading>
        <View styleName="flex-row jc-center ai-md-start ai-center flex-wrap">
          <Button
            color="light"
            to="https://www.instagram.com/azubinet"
            external
            styleName="mr-2"
            style={media.up('md') ? styles.buttonDesktop : styles.buttonMobile}
          >
            <BrandText style={media.up('md') ? styles.iconDesktop : styles.iconMobile}>
              <Icon glyph="instagram" />
            </BrandText>
          </Button>
          <Button
            color="light"
            to="https://www.facebook.com/azubinet"
            external
            styleName="mr-2"
            style={media.up('md') ? styles.buttonDesktop : styles.buttonMobile}
          >
            <BrandText style={media.up('md') ? styles.iconDesktop : styles.iconMobile}>
              <Icon glyph="facebook" />
            </BrandText>
          </Button>
          <Button
            color="light"
            to="https://www.youtube.com/channel/UC1wmDvadQv92GBTUZF8VI3A"
            external
            style={media.up('md') ? styles.buttonDesktop : styles.buttonMobile}
          >
            <BrandText style={media.up('md') ? styles.iconDesktop : styles.iconMobile}>
              <Icon glyph="youtube" />
            </BrandText>
          </Button>
        </View>
      </View>
    </>
  );
}

export default SocialMedia;
