// @flow
import React from 'react';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';
import { Icon, FooterLink } from 'components/common';
import { View, Container, Grid, Text, Image } from 'components/core';
import ViewSwitcher from './ViewSwitcher';

const styles = StyleSheet.create({
  footer: css`
    background-color: $gray-800;
    width: 100%;
    margin-top: 5rem;
    padding-top: 0;
    flex-shrink: 0;
  `,
  footerDesktop: css`
    min-height: 10rem;
  `,
  footerMobile: css`
    min-height: 16rem;
  `,
  container: css`
    padding: 1rem;
  `,
  containerMobile: css`
    max-width: 560px;
  `,
  infoMobile: css`
    border-bottom-width: 1px;
    border-bottom-color: rgba(255, 255, 255, 0.05);
    margin-bottom: 1rem;
    align-items: center;
  `,
  copyright: css`
    padding-top: 1rem;
    text-align: center;
  `,
  text: css`
    color: $gray-400;
  `,
  image: css`
    width: 200px;
    height: 33px;
    margin-top: 1rem;
  `,
  icon: css`
    width: 20px;
    justify-content: center;
    flex-direction: row;
    margin-right: 5px;
  `,
});

const dict = createDict({
  imprint: 'Imprint',
  terms_of_use: 'Terms of Use',
  data_policy: 'Data Policy',
  faq: 'FAQ',
});

const currentYear = new Date().getFullYear();

function FooterVogel(): React$Node {
  const media = useMedia();

  return (
    <View
      role="contentinfo"
      style={[styles.footer, media.up('md') ? styles.footerDesktop : styles.footerMobile]}
    >
      <Container style={[styles.container, media.down('sm') && styles.containerMobile]}>
        <Grid>
          <Grid.Box size={12} sizeMd={6} style={media.down('sm') && styles.infoMobile}>
            {/* language
            <LocaleSwitcher /> */}

            {/* desktop/mobile toggle */}
            <ViewSwitcher />
            <View styleName="flex-row ai-center ai-md-start">
              <View style={styles.icon}>
                <Icon small style={[styles.text]} glyph="cart-plus" />
              </View>
              <Text small style={[styles.text]}>
                <FooterLink to="https://www.heinrich-vogel-shop.de/" external>
                  Heinrich Vogel Shop
                </FooterLink>
              </Text>
            </View>
            <View styleName="flex-row ai-center ai-md-start">
              <View style={styles.icon}>
                <Icon small style={styles.text} glyph="facebook" />
              </View>
              <Text small style={styles.text}>
                <FooterLink to="https://www.facebook.com/heinrichvogelshop" external>
                  Verlag Heinrich Vogel auf Facebook
                </FooterLink>
              </Text>
            </View>
          </Grid.Box>
          <Grid.Box size={12} sizeMd={6}>
            <View styleName="mb-4 ai-center ai-md-end jc-md-end">
              <Text style={styles.text} small>
                <FooterLink to="/faq">
                  <Trans {...dict('faq')} />
                </FooterLink>
              </Text>
              <Text style={styles.text} small>
                <FooterLink to="/legal-contact">
                  <Trans {...dict('imprint')} />
                </FooterLink>
              </Text>
              <Text style={styles.text} small>
                <FooterLink to="/terms-of-use">
                  <Trans {...dict('terms_of_use')} />
                </FooterLink>
              </Text>
              <Text style={styles.text} small>
                <FooterLink to="/privacy-policy">
                  <Trans {...dict('data_policy')} />
                </FooterLink>
              </Text>
              <Image source="/img/organizations/vogel/tecvia.png" style={styles.image} />
            </View>
          </Grid.Box>
        </Grid>
        <Text small style={[styles.copyright, styles.text]}>
          © TECVIA GMBH {currentYear}
        </Text>
      </Container>
    </View>
  );
}

export default FooterVogel;
