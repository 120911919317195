// @flow
import React from 'react';
import { AppRegistry } from 'react-native';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider } from 'libs/foundation';
import cookie from 'cookie';
import { Provider as IntlProvider } from 'intlized-components';
import { Provider as UIProvider } from 'bootstrap-rn';
import { Domain, Cookies, Api } from 'libs/misc';
import * as Sentry from '@sentry/react';
import App from 'views/_root/App';
import createHeaders from 'bootstrap/createHeaders';
import bootstrap from 'bootstrap/bootstrap';

export default (ctx: Object, client: Object, initialState: Object) => {
  // eslint-disable-next-line no-underscore-dangle
  Domain.init(window.location.hostname, window.__MOCK_HOST__);

  const cookies = {
    get(name) {
      return cookies.all()[name];
    },
    set(name, value, options) {
      document.cookie = cookie.serialize(name, value, options);
    },
    all() {
      return cookie.parse(document.cookie || '');
    },
  };

  Cookies.init(cookies);

  if (!__DEV__) {
    Sentry.init({
      dsn: 'https://a6405d4240a34626ab1b63b4153aa5f0@o1216268.ingest.sentry.io/6358288',
    });
  }

  Api.init((url, options) =>
    fetch(url, {
      ...options,
      // Set credentials to same-origin to support older browsers (i.e. Firefox < 61).
      credentials: 'same-origin',
      headers: createHeaders(options, {
        // Csrf
        'X-Csrf-Token': initialState.csrf,
      }),
    }),
  );

  bootstrap(ctx).then(
    ({ ui, intl, transporter, error }) => {
      function Root() {
        return (
          <BrowserRouter basename={ctx.basename}>
            <UIProvider {...ui}>
              <IntlProvider {...intl}>
                <AppProvider client={transporter}>
                  <App errorCode={error} />
                </AppProvider>
              </IntlProvider>
            </UIProvider>
          </BrowserRouter>
        );
      }

      AppRegistry.registerComponent('Root', () => Root);

      AppRegistry.runApplication('Root', {
        initialProps: {},
        rootTag: document.getElementById('content'),
      });
    },
    (err) => {
      // eslint-disable-next-line no-console
      console.error(err);
    },
  );
};
