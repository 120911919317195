// @flow
import type { HtmlTag, ValidatedHtmlTag, AstNode } from './types';

const validateTableRows = (rows?: AstNode[], head: boolean = false): boolean => {
  return (
    rows !== undefined &&
    rows.length > 0 &&
    rows.every(
      (row) =>
        row.type === 'tableRow' &&
        row.children !== undefined &&
        row.children.length > 0 &&
        row.children.every(
          (cell) =>
            cell.htmlTag && (cell.htmlTag.name === 'th' || (!head && cell.htmlTag.name === 'td')),
        ),
    )
  );
};

const setInnerTableTagsValidated = (children: AstNode[], depth: number = 1): void => {
  // depth=1 => Tag is thead or tbody,
  // depth=2 => Tag is tr,
  // depth=3 => Tag is th or td.
  // => All tables have a maximum depth of 3.
  if (depth > 3) {
    return;
  }

  children.forEach((child) => {
    if (child.htmlTag) {
      // eslint-disable-next-line no-param-reassign
      child.htmlTag.validated = true;
    }

    if (child.children) {
      setInnerTableTagsValidated(child.children, depth + 1);
    }
  });
};

const validateTable = (parts?: AstNode[]): boolean => {
  if (!parts) {
    return false;
  }

  let validated;

  if (parts.length === 2 && parts[0].type === 'tableHead' && parts[1].type === 'tableBody') {
    // Table contains of a thead and tbody tag.
    validated = validateTableRows(parts[0].children) && validateTableRows(parts[1].children);

    if (validated) {
      setInnerTableTagsValidated(parts);
    }
  } else if (parts.length === 1 && parts[0].type === 'tableBody') {
    // Table only has a tbody tag.
    validated = validateTableRows(parts[0].children);

    if (validated) {
      setInnerTableTagsValidated(parts);
    }
  } else {
    // Table might have only tr tags.
    validated = validateTableRows(parts);

    if (validated) {
      // We do not have part definitions like thead or tbody, so begin at a
      // depth of 2.
      setInnerTableTagsValidated(parts, 2);
    }
  }

  return validated;
};

const tableTags = ['table', 'thead', 'tbody', 'tr', 'th', 'td'];

export default function validateTag(tag: HtmlTag, children?: AstNode[]): ValidatedHtmlTag {
  let validated;

  if (!tableTags.includes(tag.name)) {
    // Set validated to true for all tags except table tags.
    validated = true;
  } else if (tag.name === 'table') {
    // Validate table syntax.
    validated = validateTable(children);
  } else {
    // Set validated to false for inner table tags.
    validated = false;
  }

  return { ...tag, validated };
}
