// @flow
import { Domain, Api, Analytics } from 'libs/misc';
import { StyleSheet } from 'libs/ui';
import { fetchMessages } from 'libs/intl';
import { TransporterNetwork, TransporterClient } from 'react-transporter';
import fetchDomainInfo from './fetchDomainInfo';
import createTheme from './createTheme';
import modifiers from './modifiers';
import utilities from './utilities';

const getLocale = (ctx) => {
  // Pin locale to de if locale is set by http header for all domains except
  // www.examunity.com and www.azubinet.de.
  if (
    ctx.intl.localeSource === 'header' &&
    !Domain.is('www.examunity.com') &&
    !Domain.is('www.azubinet.de')
  ) {
    return 'de';
  }

  return ctx.intl.locale;
};

const resolveRequest = (promise) =>
  promise.then(
    (value) => ({ status: 'fulfilled', value }),
    (error) => ({ status: 'rejected', reason: error.message }),
  );

export default function bootstrap(ctx: AppContext): Object {
  // Create transporter network.
  const network = new TransporterNetwork((query, variables) =>
    Api.raw('/api/graph', {
      method: 'post',
      body: JSON.stringify({
        query,
        variables,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }),
  );

  // Create store
  const transporter = new TransporterClient({
    network,
  });

  // Get locale
  const locale = getLocale(ctx);

  // Fetch domain settings and messages
  const promises = Promise.all([
    resolveRequest(fetchDomainInfo()),
    resolveRequest(fetchMessages(locale)),
  ]).then(([domain, messages]) => {
    // Build style sheet
    // TODO: Especially on Android it takes more than 10s to build the styles.
    // We need to optimize this in bootstrap-rn.
    if (domain.status === 'fulfilled') {
      const { theme } = domain.value;
      StyleSheet.build(createTheme(theme));
    } else {
      StyleSheet.build(createTheme(null));
    }

    // Initialize (not activate) google analytics service.
    if (domain.status === 'fulfilled') {
      const { googleAnalyticsId } = domain.value;
      Analytics.init(googleAnalyticsId);
    }

    // Configure ui
    const ui = {
      ssrViewport: ctx.device === 'mobile' ? 'xs' : 'lg',
      utilities,
      modifiers,
    };

    // Configure intl
    const intl = {
      messages: messages.value,
      locale,
      defaultLocale: ctx.intl.defaultLocale,
      locales: ctx.intl.locales,
    };

    return {
      ui,
      intl,
      transporter,
      error: /* messages.reason || */ domain.reason,
      domain: domain.value,
    };
  });

  return promises;
}
