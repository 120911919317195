// @flow
import { createDict } from 'libs/intl';

type ValidationName =
  | 'alpha'
  | 'alpha_dash'
  | 'alpha_num'
  | 'confirmed'
  | 'distinct'
  | 'email'
  | 'exists'
  | 'gt'
  | 'gte'
  | 'lt'
  | 'lte'
  | 'max'
  | 'min'
  | 'numeric'
  | 'required'
  | 'required_if'
  | 'required_with'
  | 'unique';

type ValidationRule = {|
  name: ValidationName,
  parameters: string[],
|};

const dict = createDict('validation', {
  alpha: 'The {attribute} field must only contain letters.',
  alpha_dash: 'The {attribute} field must only contain letters, numbers, dashes, and underscores.',
  alpha_num: 'The {attribute} field must only contain letters and numbers.',
  confirmed: 'The {attribute} field confirmation does not match.',
  distinct: 'The {attribute} field has a duplicate value.',
  email: 'The {attribute} field must be a valid email address.',
  exists: 'The selected {attribute} is invalid.',
  gt: 'The {attribute} field must be greater than {value}.',
  gte: 'The {attribute} field must be greater than or equal to {value}.',
  lt: 'The {attribute} field must be less than {value}.',
  lte: 'The {attribute} field must be less than or equal to {value}.',
  max: 'The {attribute} field must not be greater than {max} characters.',
  min: 'The {attribute} field must be at least {min} characters.',
  numeric: 'The {attribute} field must be a number.',
  required: 'The {attribute} field is required.',
  required_if: 'The {attribute} field is required.', // when {other} is {value}.
  required_with: 'The {attribute} field is required.', // when {value} is present.
  unique: 'The {attribute} has already been taken.',
});

const variables = {
  exists(parameters) {
    return {
      table: parameters[0],
    };
  },
  gt(parameters) {
    return {
      value: parameters[0],
    };
  },
  gte(parameters) {
    return {
      value: parameters[0],
    };
  },
  lt(parameters) {
    return {
      value: parameters[0],
    };
  },
  lte(parameters) {
    return {
      value: parameters[0],
    };
  },
  max(parameters) {
    return {
      max: parameters[0],
    };
  },
  mimes(parameters) {
    return {
      values: parameters.join(', '),
    };
  },
  min(parameters) {
    return {
      min: parameters[0],
    };
  },
  /* required_if(parameters) {
    return {
      other: parameters[0], // TODO: Get nice name
      value: parameters[1], // TODO: Get label of value
    }
  }, */
  /* required_with(parameters) {
    return {
      values: parameters[0], // TODO: Get nice name
    }
  }, */
  unique(parameters) {
    return {
      table: parameters[0],
    };
  },
};

const getValue = (value, intl) => {
  if (value && typeof value === 'object') {
    return intl.trans(value);
  }

  return value;
};

export default function formatError(
  intl: Object,
  name: string,
  niceName?: Intlized$Message | string | null,
  title?: Intlized$Message | string | null,
  error?: ValidationRule | Intlized$Message | string,
): ?string {
  if (!error) {
    return null;
  }

  // Error is string.
  if (typeof error === 'string') {
    return error;
  }

  // Error is rule object.
  if (error.name && error.parameters) {
    return intl.trans(
      dict(error.name, {
        attribute: getValue(niceName, intl) || getValue(title, intl) || name,
        // $FlowFixMe[prop-missing]
        ...(variables[error.name] && variables[error.name](error.parameters)),
      }),
    );
  }

  // Error is translation object.
  return intl.trans(error);
}
