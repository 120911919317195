// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { View } from 'components/core';
import { convertActivityType } from 'utils/courses';
import Icon from './Icon';

type Props = {
  glyph?: string,
  activity?: Object,
  size?: 'sm',
  style?: any,
};

const styles = StyleSheet.create({
  iconWrapper: css`
    background-color: $transparent-200;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
  `,
  icon: css`
    color: $gray-700;
    font-size: 1rem;
    height: 2rem;
    min-width: 2rem;
    width: 2rem;
    line-height: 2rem;
    text-align: center;
  `,
  iconSmWrapper: css`
    background-color: $transparent-200;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.75rem;
  `,
  iconSm: css`
    font-size: 0.875rem;
    color: $gray-700;
    height: 1.5rem;
    min-width: 1.5rem;
    width: 1.5rem;
    text-align: center;
  `,
});

const getGlyph = (glyph, activity) => {
  if (glyph) {
    return glyph;
  }

  if (!activity) {
    return 'block';
  }

  return convertActivityType(activity, {
    Article: 'article',
    Document: 'doc',
    Exam: 'award',
    Quiz: 'cards',
    Survey: 'chart-bar',
    Video: 'play-circled',
  });
};

function ActivityIcon({ glyph, activity, size, style, ...props }: Props): React$Node {
  const realGlyph = getGlyph(glyph, activity);

  return (
    <View {...props} style={[size === 'sm' ? styles.iconSmWrapper : styles.iconWrapper, style]}>
      {realGlyph ? (
        <Icon glyph={realGlyph} style={size === 'sm' ? styles.iconSm : styles.icon} />
      ) : (
        <View style={size === 'sm' ? styles.iconSm : styles.icon} />
      )}
    </View>
  );
}

export default ActivityIcon;
