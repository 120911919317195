// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';
import { CHECKLIST_TODO_FRAGMENT } from '../_shared/ChecklistTodo/fragments';

const ChecklistEditor = {
  name: 'ChecklistEditor',
  bundle: () => import('./ChecklistEditor'),
};

const CHECKLIST_QUERY = gql`
  query ChecklistQuery($slug: String!) {
    checklist(slug: $slug) {
      slug
      name
      organization {
        id
      }
      isPrivate
      canBeSetPublic
      todosCount
      todos {
        ...ChecklistTodoFragment
      }
      used
      viewerCanUpdate
      viewerCanDelete
    }
  }

  ${CHECKLIST_TODO_FRAGMENT}
`;

const Container = createAsyncContainer(ChecklistEditor, (props) => {
  const {
    params: { slug },
  } = props;

  const loaders = {
    query: query(CHECKLIST_QUERY, { variables: { slug } }),
  };

  const selectors = {
    checklist: select(CHECKLIST_QUERY, { variables: { slug } }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
