// @flow
import { Fraction } from 'libs/misc';

const getPoints = (
  sourceTaskPoints: Entity<GQLTaskExamPoints>[],
  taskPosition: number,
  taskPartPosition: ?number,
) => {
  const taskPoints = sourceTaskPoints.find((item) => item.position === taskPosition);

  if (!taskPoints) {
    throw new Error(`Task points for task ${taskPosition.toString()} not found.`);
  }

  if (!taskPartPosition) {
    return taskPoints.points;
  }

  const taskPartPoints = taskPoints.parts.find((item) => item.position === taskPartPosition);

  if (!taskPartPoints) {
    throw new Error(
      `Task points for task part ${taskPosition.toString()}.${taskPartPosition.toString()} not found.`,
    );
  }

  return taskPartPoints.points;
};

const getExamAttemptPoints = (
  sourceTaskPoints: Entity<GQLTaskExamAttemptResultPoints>[],
  taskPosition: number,
  taskPartPosition: ?number,
) => {
  const taskPoints = sourceTaskPoints.find((item) => item.position === taskPosition);

  if (!taskPoints) {
    return null;
  }

  if (!taskPartPosition) {
    const points = new Fraction(taskPoints.points);

    return parseFloat(points.toFixed(2));
  }

  const taskPartPoints = taskPoints.parts.find((item) => item.position === taskPartPosition);

  if (!taskPartPoints) {
    return null;
  }

  const points = new Fraction(taskPartPoints.points);

  return parseFloat(points.toFixed(2));
};

export default function useTaskExamRating(taskExam: Entity<GQLTaskExam>): TaskExamRating {
  const taskExamResult = taskExam.attempt.result;

  return {
    get exam() {
      return taskExam;
    },
    get result() {
      if (!taskExamResult) {
        throw new Error('No exam result');
      }

      return taskExamResult;
    },
    taskPoints(taskPosition, taskPartPosition) {
      return taskExamResult
        ? getExamAttemptPoints(taskExamResult.taskPoints, taskPosition, taskPartPosition)
        : null;
    },
    taskMaxPoints(taskPosition, taskPartPosition) {
      return getPoints(taskExam.taskPoints, taskPosition, taskPartPosition);
    },
    points() {
      if (!taskExamResult) {
        return 0;
      }

      const points = new Fraction(taskExamResult.points);

      return parseFloat(points.toFixed(2));
    },
    evaluatedPoints() {
      return taskExamResult ? taskExamResult.evaluatedPoints : 0;
    },
    isPassed(increment = 0) {
      if (!taskExamResult) {
        throw new Error('No exam result');
      }

      const { points, passingScore } = taskExam;

      if (typeof passingScore !== 'number') {
        return null;
      }

      const resultPoints = new Fraction(taskExamResult.points);

      // Do not multiply or divide, so that we have an exact result without floating points.
      return (
        (resultPoints.numerator + increment * resultPoints.denominator) * 100 >=
        passingScore * resultPoints.denominator * points
      );
    },
  };
}
