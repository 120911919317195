// @flow
import React, { useContext } from 'react';
import { Platform } from 'react-native';
import { StyleSheet, css } from 'libs/ui';
import { ScrollView, View } from 'bootstrap-rn';
import Context from '../Context';

type Props = {
  children: React$Node,
  spacing: boolean,
};

const styles = StyleSheet.create({
  table: css`
    border: 1px solid $gray-900;
  `,
  spacing: css`
    margin-bottom: 1rem;
  `,
});

function Table(props: Props): React$Node {
  const { children, spacing } = props;

  const { handleTableScroll } = useContext(Context);

  const role = Platform.OS === 'web' ? 'table' : null;

  return (
    <ScrollView
      onScroll={handleTableScroll}
      scrollEventThrottle={16}
      bounces={false}
      horizontal
      style={spacing && styles.spacing}
    >
      <View role={role} style={styles.table}>
        {children}
      </View>
    </ScrollView>
  );
}

export default Table;
