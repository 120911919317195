// @flow
import React from 'react';
import { ErrorPage } from 'components/common';

type Props = {
  query?: any,
  viewer: ?Entity<GQLUser>,
};

const getCode = (error) => {
  if (!error) {
    return null;
  }

  if (error.type === 'NetworkError') {
    return 'NETWORK_ERROR';
  }

  if (error.type === 'HttpError') {
    return 'REQUEST_ERROR';
  }

  if (error.type === 'GraphQLError') {
    const code = error.data.errors[0].extensions?.code;

    if (code === 'BAD_USER_INPUT') {
      return 'PAGE_NOT_FOUND';
    }

    if (code === 'UNAUTHENTICATED') {
      return 'UNAUTHENTICATED';
    }

    if (code === 'FORBIDDEN') {
      return 'FORBIDDEN';
    }

    if (!code) {
      return 'REQUEST_ERROR';
    }
  }

  return null;
};

function ErrorSelector(props: Props): React$Node {
  const { query, viewer } = props;

  return <ErrorPage code={getCode(query && query.error)} viewer={viewer} />;
}

export default ErrorSelector;
