// @flow
import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { Divider, Icon, OptionButton } from 'components/common';
import { Text, View, Nav } from 'components/core';
import { StyleSheet, css } from 'libs/ui';
import { Domain, useToggleState, useValueState } from 'libs/misc';
import { Form } from 'components/forms';
import { useMenu } from 'libs/foundation';
import FindCoursesModal from 'views/_shared/FindCoursesModal';
import MenuWrapper from './MenuWrapper';
import MenuChecklistDetails from './MenuChecklistDetails';
import MenuTrainees from './MenuTrainees';
import MenuChecklists from './MenuChecklists';
import MenuCourses from './MenuCourses';
import MenuTeams from './MenuTeams';
import CreateModal from './CreateModal';

type Props = {
  viewer: Entity<GQLUser>,
  domain: Entity<GQLDomain>,
};

const styles = StyleSheet.create({
  organizationIconWrapper: css`
    background-color: $dark;
    width: 2rem;
    height: 2rem;
    border-radius: 0.25rem;
    margin-right: 0.75rem;
  `,
  organizationIcon: css`
    text-align: center;
    color: #f5f5f5;
    flex-shrink: 0;
    width: 2rem;
    height: 2rem;
    font-size: 1.25rem;
    line-height: 2rem;
  `,
  indented: css`
    padding-horizontal: 0.75rem;
  `,
});

const dict = createDict({
  administration: 'Administration',
});

function Menu({ viewer, domain }: Props): React$Node {
  const { organizationMemberships } = viewer;

  const menu = useMenu();
  const createModal = useToggleState(false);

  // Define states here, so that they are not resetted on menu close.
  const traineesExpanded = useToggleState(true);
  const checklistsExpanded = useToggleState(true);
  const checklistTodosExpanded = useToggleState(true);
  const coursesExpanded = useToggleState(true);
  const bundlesModal = useToggleState(false);
  const teamsExpanded = useToggleState(true);

  // Only list organizations where the viewer is admin or trainer.
  const administrableOrganizationMemberships = organizationMemberships.filter(
    ({ organization }) => organization.viewerIsAdmin || organization.viewerIsTrainer,
  );

  const selectedMembershipId = useValueState(
    administrableOrganizationMemberships.length > 0
      ? administrableOrganizationMemberships[0].id
      : null,
  );
  const selectedMembership = selectedMembershipId.value
    ? administrableOrganizationMemberships.find(
        (membership) => membership.id === selectedMembershipId.value,
      )
    : null;

  const activeChecklistResultId = useValueState(null);
  const activeChecklistResult = activeChecklistResultId.value
    ? viewer.checklistResults.find((result) => result.id === activeChecklistResultId.value)
    : null;

  const viewerCanCreateCourses =
    domain.permissions.usersCanCreateCourses || domain.ownerOrganization.viewerIsAdmin;

  const membershipOptions = administrableOrganizationMemberships.map((membership) => ({
    value: membership.id,
    label: membership.organization.name,
  }));

  return (
    <>
      <MenuWrapper
        activeChecklistResultId={activeChecklistResultId}
        selectedMembershipId={selectedMembershipId}
      >
        {activeChecklistResult ? (
          <MenuChecklistDetails
            checklistResult={activeChecklistResult}
            expanded={checklistTodosExpanded}
            onClose={() => {
              activeChecklistResultId.set(null);
            }}
          />
        ) : (
          <>
            <View styleName="flex-grow-1">
              {selectedMembership && (
                <View>
                  {administrableOrganizationMemberships.length > 1 && (
                    <Form
                      initialValues={{
                        selection: selectedMembershipId.value,
                      }}
                      onSubmit={() => {}}
                    >
                      <Form.Picker
                        name="selection"
                        options={membershipOptions}
                        onValueChange={(value) => {
                          selectedMembershipId.set(value);
                        }}
                      />
                    </Form>
                  )}
                  <Nav variant="pills" styleName="flex-column">
                    <Nav.Link
                      to={`/organizations/${selectedMembership.organization.slug}`}
                      onPress={() => {
                        menu.clear();
                      }}
                      style={styles.indented}
                    >
                      <View style={styles.organizationIconWrapper}>
                        <Icon glyph="bank" style={styles.organizationIcon} />
                      </View>
                      <View styleName="flex-shrink-1">
                        {administrableOrganizationMemberships.length === 1 ? (
                          <>
                            <Text numberOfLines={1}>{selectedMembership.organization.name}</Text>
                            <Trans {...dict('administration')} color="muted" small />
                          </>
                        ) : (
                          <Trans {...dict('administration')} />
                        )}
                      </View>
                    </Nav.Link>
                  </Nav>
                  <MenuTrainees membership={selectedMembership} expanded={traineesExpanded} />
                  <Divider />
                </View>
              )}
              <MenuChecklists
                viewer={viewer}
                expanded={checklistsExpanded}
                onSelect={(checklistResult) => {
                  activeChecklistResultId.set(checklistResult.id);
                }}
              />
              <MenuCourses viewer={viewer} expanded={coursesExpanded} bundlesModal={bundlesModal} />
              <MenuTeams viewer={viewer} expanded={teamsExpanded} />
            </View>
            {(viewerCanCreateCourses || !Domain.is('www.vogelspot.de')) && (
              <OptionButton onPress={createModal.toggle} styleName="as-center mt-5">
                <Icon glyph="plus" />
              </OptionButton>
            )}
          </>
        )}
        {/* Modals need to be inside MenuBody for iOS. Otherwise modals on an offcanvas are not displayed on iOS. */}
        <CreateModal modal={createModal} domain={domain} />
        <FindCoursesModal modal={bundlesModal} />
      </MenuWrapper>
    </>
  );
}

export default Menu;
