// @flow
import React from 'react';
import { Nav, Badge, Text } from 'components/core';
import { createDict, Trans } from 'libs/intl';
import { StyleSheet, css } from 'libs/ui';
import { useParams } from 'libs/routing';

type Props = {
  organization: ?Entity<GQLOrganization>,
  domain: Entity<GQLDomain>,
};

const styles = StyleSheet.create({
  nav: css`
    margin: 0;
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column;
  `,
});

const dict = createDict({
  settings: 'Settings',
  permissions: 'Permissions',
  pending_inquiries: 'Pending inquiries',
  design: 'Design',
  analytics_seo: 'Analytics & SEO',
  organizations: 'Organizations',
  subdomains: 'Subdomains',
  users: 'Users',
  license_offers: 'License Offers',
  web_app: 'Web App',
});

function DomainNav({ organization, domain }: Props): React$Node {
  const { slug } = useParams();

  return (
    <Nav variant="pills" style={styles.nav}>
      <Nav.Link to={`/organizations/${slug}/domain`} end>
        <Trans {...dict('settings')} />
      </Nav.Link>
      <Nav.Link to={`/organizations/${slug}/domain/users`} styleName="jc-between">
        <Trans {...dict('users')} />
        <Badge color="light">
          <Text>{organization ? organization.domain.usersCount : <Text>&nbsp;</Text>}</Text>
        </Badge>
      </Nav.Link>
      {domain.allowsTrials && (
        <Nav.Link to={`/organizations/${slug}/domain/organizations`} styleName="jc-between">
          <Trans {...dict('organizations')} />
          <Badge color="light">
            <Text>
              {organization ? organization.domain.organizationsCount : <Text>&nbsp;</Text>}
            </Text>
          </Badge>
        </Nav.Link>
      )}
      {domain.permissions.usersCanCreateInquiries && (
        <Nav.Link to={`/organizations/${slug}/domain/pending-inquiries`} styleName="jc-between">
          <Trans {...dict('pending_inquiries')} />
          <Badge color={domain.inquiriesCount > 0 ? 'warning' : 'light'}>
            <Text>{organization ? organization.domain.inquiriesCount : <Text>&nbsp;</Text>}</Text>
          </Badge>
        </Nav.Link>
      )}
      {domain.allowsSubdomains && (
        <Nav.Link to={`/organizations/${slug}/domain/subdomains`} styleName="jc-between">
          <Trans {...dict('subdomains')} />
          <Badge color="light">
            <Text>{organization ? organization.domain.subDomainsCount : <Text>&nbsp;</Text>}</Text>
          </Badge>
        </Nav.Link>
      )}
      <Nav.Link to={`/organizations/${slug}/domain/design`}>
        <Trans {...dict('design')} />
      </Nav.Link>
      <Nav.Link to={`/organizations/${slug}/domain/permissions`}>
        <Trans {...dict('permissions')} />
      </Nav.Link>
      <Nav.Link to={`/organizations/${slug}/domain/seo`}>
        <Trans {...dict('analytics_seo')} />
      </Nav.Link>
      <Nav.Link to={`/organizations/${slug}/domain/pwa`}>
        <Trans {...dict('web_app')} />
      </Nav.Link>
    </Nav>
  );
}

export default DomainNav;
