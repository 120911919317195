// @flow
import { createContainer } from 'libs/container';
import { selectByRoot } from 'libs/graphql';
import ProfileLinkPopover from './ProfileLinkPopover';

const Container = createContainer(ProfileLinkPopover, () => {
  const selectors = {
    viewer: selectByRoot('viewer'),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
