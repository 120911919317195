// @flow
import React from 'react';
import { Platform } from 'react-native';
import AppLayout from '../../(app)/_layout';

function PagesLayout(): React$Node {
  return <AppLayout variant={Platform.OS === 'web' ? 'pages' : undefined} />;
}

export default PagesLayout;
