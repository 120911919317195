// @flow
import React from 'react';
import { Nav as BaseNav } from 'bootstrap-rn';
import NavLink from './NavLink';

type Props = {};

const Nav = React.forwardRef<Props, any>((props, ref) => {
  const { ...elementProps } = props;

  return <BaseNav {...elementProps} ref={ref} />;
});

Nav.displayName = 'Nav';

// $FlowFixMe[prop-missing]
Nav.Link = NavLink;

export default (Nav: any);
