// @flow
import { createContainer } from 'libs/container';
import { selectByRoot } from 'libs/graphql';
import ProfilePopover from './ProfilePopover';

const Container = createContainer(ProfilePopover, () => {
  const selectors = {
    viewer: selectByRoot('viewer'),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
