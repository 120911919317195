// @flow
import React, { useEffect } from 'react';
import { useToggleState, Cookies } from 'libs/misc';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { FooterLink } from 'components/common';
import { Text } from 'components/core';

const styles = StyleSheet.create({
  text: css`
    color: $text-muted;
  `,
});

const handleClick = () => {
  const viewCookie = Cookies.get('view');
  let view;
  if (viewCookie === 'desktop') {
    view = 'mobile';
  } else if (viewCookie === 'mobile') {
    view = 'desktop';
  } else if (document.getElementsByName('viewport')[0].getAttribute('content') === 'width=1080') {
    view = 'mobile';
  } else {
    view = 'desktop';
  }

  Cookies.set('view', view);

  window.location.reload();
};

function ViewSwitcher(): React$Node {
  const switcher = useToggleState(false);
  const media = useMedia();

  useEffect(() => {
    // enable view switcher for mobile devices
    if (window && 'ontouchstart' in window && !switcher.on) {
      switcher.setOn();
    }
  });

  if (!switcher.on) return null;

  return (
    <Text style={styles.text} small styleName="mb-3">
      <FooterLink onPress={handleClick}>
        {media.down('sm') && <Text>Desktop-Ansicht</Text>}
        {media.up('md') && <Text>Mobile Ansicht</Text>}
      </FooterLink>
    </Text>
  );
}

export default ViewSwitcher;
