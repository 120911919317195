// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const DomainSettings = {
  name: 'DomainSettings',
  bundle: () => import('./DomainSettings'),
};

const DOMAIN_SETTINGS_QUERY = gql`
  query OrganizationDomainSettingsQuery($slug: String!) {
    organization(slug: $slug) {
      ownsDomain
      domain {
        hasReportCollections
        searchType
        ownerOrganization {
          courses {
            name
          }
        }
        primaryCourse {
          name
        }
      }
      viewerIsAdmin
    }
  }
`;

const Container = createAsyncContainer(DomainSettings, (props) => {
  const {
    params: { slug },
  } = props;

  const variables = { slug };

  const loaders = {
    query: query(DOMAIN_SETTINGS_QUERY, { variables }),
  };

  const selectors = {
    organization: select(DOMAIN_SETTINGS_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
