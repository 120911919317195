// @flow
import React from 'react';
import { useIntl, createDict } from 'libs/intl';
import { Text } from 'components/core';

type Props = {
  taskNumber: string,
  task: Entity<GQLTask>,
};

const dict = createDict({
  task: 'Task',
});

function TaskTitle({ taskNumber, task }: Props): React$Node {
  const intl = useIntl();

  if (task.title) {
    return (
      <Text>
        {taskNumber}. {task.title}
      </Text>
    );
  }

  return (
    <Text>
      {intl.trans(dict('task'))} {taskNumber}
    </Text>
  );
}

export default TaskTitle;
