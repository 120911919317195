// @flow

import { useEffect, useState, useRef } from 'react';

const createNewObject = () => ({});

export default function useViewerHasRunningTaskExam(exam: ?Entity<GQLTaskExam>): boolean {
  const expiredAt = exam?.viewerRunningAttempt?.expiredAt;
  const [, setValue] = useState(createNewObject);
  const timeoutRef = useRef();

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (expiredAt) {
      timeoutRef.current = setTimeout(
        () => {
          setValue(createNewObject());
        },
        new Date(expiredAt) - new Date(),
      );
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [expiredAt]);

  return !!expiredAt && new Date() < new Date(expiredAt);
}
