// @flow
import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { Text, Badge } from 'components/core';

type Props = {
  points: ?number,
};

const dict = createDict({
  points: 'Points',
});

function TaskPartPoints({ points }: Props): React$Node {
  if (points === null) {
    return null;
  }

  return (
    <Badge color="light" styleName="as-end mb-3">
      <Text>
        <Trans {...dict('points')} />: {points}
      </Text>
    </Badge>
  );
}

export default TaskPartPoints;
