// @flow
import React from 'react';
import { Markdown } from 'components/markdown';
import { Text, View } from 'components/core';
import { LargeBadge } from 'components/common';
import { formatNumber } from 'utils/tasks';
import { TaskChoice } from 'components/tasks';

type Props = {
  piece: Entity<GQLPairingPiece>,
  last: boolean,
};

function PieceMatching({ piece, last }: Props): React$Node {
  const convertNumber = formatNumber(piece.key, 'ALPHABETIC');

  return (
    <>
      {piece.side === 'LEFT' && (
        <View styleName="flex-row flex-grow-1 flex-shrink-1 ai-start my-2">
          <LargeBadge color="secondary" styleName="mr-2">
            {piece.key}
          </LargeBadge>
          <Markdown variant="article" source={piece.text} />
        </View>
      )}
      {piece.side === 'RIGHT' && (
        <TaskChoice last={last}>
          <TaskChoice.Label>
            <View styleName="flex-row flex-grow-1 flex-shrink-1 ai-start">
              <Text>{convertNumber}. </Text>
              <Markdown variant="article" source={piece.text} />
            </View>
          </TaskChoice.Label>
        </TaskChoice>
      )}
    </>
  );
}

export default PieceMatching;
