// @flow
import React from 'react';
import BundleCompact from './BundleCompact';

type Props = {
  onClose?: () => void,
};

function KaufleuteFuerGrossUndAussenhandelsmanagementBundles({ onClose }: Props): React$Node {
  return (
    <>
      <BundleCompact
        to="/courses/wnHFB8q97X"
        title="Erstmal reinschnuppern"
        price="kostenlose Lernmaterialien und Community"
        onClose={onClose}
      />
      <BundleCompact
        to="/professions/kauffrau-kaufmann-fuer-gross-und-aussenhandelsmanagement"
        title="Lernpaket FR Großhandel"
        price="ab 26,95 EUR"
        paid
      />
      <BundleCompact
        to="/professions/kauffrau-kaufmann-fuer-gross-und-aussenhandelsmanagement"
        title="Lernpaket FR Außenhandel"
        price="ab 24,95 EUR"
        paid
      />
    </>
  );
}

export default KaufleuteFuerGrossUndAussenhandelsmanagementBundles;
