// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { View, Text } from 'components/core';

type Props = {
  htmlTag: any,
  children: React$Node,
  spacing: boolean,
};

const styles = StyleSheet.create({
  spacing: css`
    margin-bottom: 1rem;
  `,
});

function Html({ htmlTag, children, spacing }: Props): React$Node {
  if (!htmlTag) {
    return null;
  }

  const content = (
    <>
      <Text>{htmlTag.source[0]}</Text>
      {children}
      {htmlTag.source[1] && <Text>{htmlTag.source[1]}</Text>}
    </>
  );

  if (htmlTag.name === 'div' || htmlTag.name === 'table') {
    return <View style={spacing && styles.spacing}>{content}</View>;
  }

  return content;
}

export default Html;
