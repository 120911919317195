// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

const OPEN_QUESTION_PROPOSED_SOLUTION_FIELDS = gql`
  {
    text
    explanation
    author {
      name
      avatar {
        file {
          name
          extension
          info {
            image {
              height
              width
            }
          }
        }
      }
      createdAt
    }
    votesTrueCount
    votesFalseCount
    viewerVote {
      value
    }
    createdAt
    ...CommentsFragment
  }
`;

export const OPEN_QUESTION_FRAGMENT: DocumentNode = gql`
  fragment OpenQuestionFragment on OpenQuestion {
    text
    sampleSolution {
      text
      explanation
      ...CommentsFragment
    }
    proposedSolution(id: $solutionId, postId: $postId) ${OPEN_QUESTION_PROPOSED_SOLUTION_FIELDS}
    proposedSolutions ${OPEN_QUESTION_PROPOSED_SOLUTION_FIELDS}
  }
`;
