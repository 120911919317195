// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { View } from 'components/core';

type Props = {
  children: React$Node,
  style?: any,
};

const styles = StyleSheet.create({
  badge: css`
    height: 1.625rem;
    width: 1.625rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.8125rem;
    background-color: $gray-200;
  `,
});

function QuizBadge({ children, style, ...elementProps }: Props): React$Node {
  return (
    <View {...elementProps} style={[styles.badge, style]}>
      {children}
    </View>
  );
}

export default QuizBadge;
