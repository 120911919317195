// @flow
import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { useAlerts } from 'libs/misc';
import { View } from 'components/core';
import { Form } from 'components/forms';
import { Editor } from 'components/editor';
import { useDispatch } from 'libs/graphql';
import { createAnnouncement } from './mutations';

type Props = {
  announceableId: Id,
  announceableType: string,
};

const dict = createDict({
  announcement: 'announcement',
  write_an_announcement: 'Write an announcement...',
  create_announcement: 'Create Announcement',
  announcement_created_successfully: 'Announcement created successfully.',
});

function AnnouncementForm({ announceableId, announceableType }: Props): React$Node {
  const dispatch = useDispatch();
  const alerts = useAlerts();

  return (
    <Form
      initialValues={{
        text: null,
      }}
      validate={{
        text: 'required',
      }}
      onSubmit={async (values, actions) => {
        await dispatch(createAnnouncement(announceableId, announceableType, values.text));

        alerts.add({
          color: 'success',
          content: <Trans {...dict('announcement_created_successfully')} />,
        });

        actions.resetForm();
      }}
    >
      <Editor
        name="text"
        niceName={dict('announcement')}
        placeholder={dict('write_an_announcement')}
      />
      <View styleName="flex-row jc-end">
        <Form.Button type="submit">
          <Trans {...dict('create_announcement')} />
        </Form.Button>
      </View>
    </Form>
  );
}

export default AnnouncementForm;
