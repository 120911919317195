// @flow
import { createContainer } from 'libs/container';
import { select, gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import MediaGalleryModal from './MediaGalleryModal';

export const ATTACHMENTS_FRAGMENT: DocumentNode = gql`
  fragment AttachmentsFragment on Attachable {
    files {
      createdAt
      name
      encodedName
      extension
      info {
        image {
          height
          width
        }
      }
    }
  }
`;

const Container = createContainer(MediaGalleryModal, (props) => {
  const { attachableType, attachableId } = props;

  const selectors = {
    attachable: select(ATTACHMENTS_FRAGMENT, {
      entry: [attachableType, attachableId],
    }),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
