// @flow
import { createContainer } from 'libs/container';
import { select, gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import OpenQuestion from './OpenQuestion';

export const OPEN_QUESTION_EXAM_FRAGMENT: DocumentNode = gql`
  fragment OpenQuestionExamFragment on OpenQuestion {
    text
    viewerRunningExamSolution {
      text
    }
  }
`;

const Container = createContainer(OpenQuestion, (props) => {
  const selectors = {
    openQuestion: select(OPEN_QUESTION_EXAM_FRAGMENT, {
      entry: ['OpenQuestion', props.id],
    }),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
