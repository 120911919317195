// @flow
import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { Icon, ScrollableNav } from 'components/common';
import { View, Text, Badge, Heading } from 'components/core';
import { Header } from 'components/layout';

type Props = {|
  organization: Entity<GQLOrganization>,
|};

const dict = createDict({
  team_of_organization: 'Team of {name}',
  start: 'Start',
  members: 'Members',
  courses: 'Courses',
  statistics: 'Statistics',
  settings: 'Settings',
  documents: 'Documents',
});

const styles = StyleSheet.create({
  heading: css`
    flex-direction: row;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-horizontal: 1rem;
    min-height: 4rem;

    @include media-breakpoint-up(md) {
      margin-top: 2.5rem;
      margin-bottom: 1.5rem;
    }
  `,
  iconWrapper: css`
    background-color: $teal;
    width: 4rem;
    height: 4rem;
    border-radius: 0.5rem;
  `,
  icon: css`
    text-align: center;
    color: #f5f5f5;
    flex-shrink: 0;
    width: 4rem;
    height: 4rem;
    font-size: 2.25rem;
    line-height: 4rem;
  `,
  subtitle: css`
    margin-top: 0.25rem;
    font-size: 1.125rem;
    line-height: 1.125rem * $line-height-base;
    color: $gray-700;
  `,
  nav: css`
    margin-bottom: 0;
    margin-right: 0.75rem;
    border-bottom-width: 0;
    flex-wrap: nowrap;
  `,
  divider: css`
    border-right-color: $gray-300;
    border-right-width: 1px;
    margin-horizontal: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  `,
});

function TeamHeader({ organization }: Props): React$Node {
  const { team } = organization;

  const media = useMedia();

  return (
    <Header>
      <View style={styles.heading}>
        {media.up('lg') && (
          <View style={styles.iconWrapper} styleName="mr-3">
            <Icon glyph="users" style={styles.icon} />
          </View>
        )}
        <View styleName="flex-grow-1 flex-shrink-1 jc-center">
          <Heading size={4}>{team.name}</Heading>
          <Text style={styles.subtitle}>
            <Trans {...dict('team_of_organization', { name: organization.name })} />
          </Text>
        </View>
      </View>
      <ScrollableNav variant="tabs" style={styles.nav}>
        <ScrollableNav.Link to={`/organizations/${organization.slug}/teams/${team.slug}`} end>
          <Trans {...dict('start')} />
        </ScrollableNav.Link>
        <View style={styles.divider} />
        <ScrollableNav.Link to={`/organizations/${organization.slug}/teams/${team.slug}/members`}>
          <Trans {...dict('members')} />
          <Badge color="light" styleName="ml-1">
            <Text>{team.membershipsCount}</Text>
          </Badge>
        </ScrollableNav.Link>
        <View style={styles.divider} />
        <ScrollableNav.Link to={`/organizations/${organization.slug}/teams/${team.slug}/courses`}>
          <Trans {...dict('courses')} />
          <Badge color="light" styleName="ml-1">
            <Text>{team.coursesCount}</Text>
          </Badge>
        </ScrollableNav.Link>
        <View style={styles.divider} />
        <ScrollableNav.Link to={`/organizations/${organization.slug}/teams/${team.slug}/documents`}>
          <Trans {...dict('documents')} />
          <Badge color="light" styleName="ml-1">
            <Text>{team.documentsCount}</Text>
          </Badge>
        </ScrollableNav.Link>
        {team.viewerIsAdmin && (
          <>
            <View style={styles.divider} />
            <ScrollableNav.Link to={`/organizations/${organization.slug}/teams/${team.slug}/stats`}>
              <Trans {...dict('statistics')} />
            </ScrollableNav.Link>
            <View style={styles.divider} />
            <ScrollableNav.Link
              to={`/organizations/${organization.slug}/teams/${team.slug}/settings`}
            >
              <Trans {...dict('settings')} />
            </ScrollableNav.Link>
          </>
        )}
      </ScrollableNav>
    </Header>
  );
}

export default TeamHeader;
