// @flow
import React from 'react';
import { View } from 'components/core';
import { StyleSheet, css } from 'libs/ui';

const styles = StyleSheet.create({
  spacing: css`
    height: $spacer5;
  `,
});

function Spacing(): React$Node {
  return <View style={styles.spacing} />;
}

export default Spacing;
