// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';
import Header from 'components/layout/Header';
import TeamHeader from './TeamHeader';

const ORGANIZATION_TEAM_QUERY = gql`
  query OrganizationTeamQuery($slug: String!, $teamSlug: String!) {
    organization(slug: $slug) {
      name
      slug
      team(teamSlug: $teamSlug) {
        name
        slug
        documentsCount
        membershipsCount
        coursesCount
        viewerIsAdmin
      }
    }
  }
`;

const options = {
  async: { loading: Header, error: () => null },
};

const Container = createAsyncContainer(
  TeamHeader,
  (props) => {
    const {
      params: { slug, teamSlug },
    } = props;

    const variables = {
      slug,
      teamSlug,
    };

    const loaders = {
      query: query(ORGANIZATION_TEAM_QUERY, { variables }),
    };

    const selectors = {
      organization: select(ORGANIZATION_TEAM_QUERY, { variables }),
    };

    return { loaders, selectors };
  },
  options,
);

export default (Container: React$AbstractComponent<any, mixed>);
