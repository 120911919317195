// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const SEARCH_RESULT_COURSE_FRAGMENT: DocumentNode = gql`
  fragment SearchResultCourseFragment on Course {
    slug
    name
    caption
    restricted
    emailRestriction
    organizationRestriction
    isPrivate
    activitiesCount
    paid
    author {
      name
    }
    organization {
      name
    }
    viewerIsMember
  }
`;
