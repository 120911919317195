// @flow
import React from 'react';
import { Domain } from 'libs/misc';
import AccessDenied from './AccessDenied';
import AccessAllowed from './AccessAllowed';
import AccessPaid from './AccessPaid';
import AccessPaidCode from './AccessPaidCode';

type Props = {
  course: Entity<GQLCourse>,
  viewer: ?Entity<GQLUser>,
  domain: Entity<GQLDomain>,
};

function CourseAccessBox({ course, viewer, domain }: Props): React$Node {
  const viewerCanBuyCourse = course.paid && !course.isPrivate;

  return (
    <>
      {!viewerCanBuyCourse && (
        <>
          {!course.viewerCanJoin && <AccessDenied course={course} viewer={viewer} />}
          {course.viewerCanJoin && viewer && <AccessAllowed course={course} viewer={viewer} />}
        </>
      )}
      {viewerCanBuyCourse && (
        <>
          {Domain.is('www.vogelspot.de') || Domain.is('www.xn--fahrradprfung-4ob.de') ? (
            <AccessPaidCode course={course} viewer={viewer} />
          ) : (
            <AccessPaid course={course} viewer={viewer} domain={domain} />
          )}
        </>
      )}
    </>
  );
}

export default CourseAccessBox;
