// @flow
import React from 'react';
import { View, Text, Dropdown, Button } from 'components/core';
import { Icon, OptionButton } from 'components/common';
import { StyleSheet, css } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';
import { useValueState } from 'libs/misc';
import { useDispatch } from 'libs/graphql';
import { updateOrCreateSolution } from '../mutations';
import type { TaskExamControls } from '../../useExamControls';

type Props = {
  pairing: Entity<GQLPairing>,
  examAttemptId: Id,
  controls: TaskExamControls,
  viewerExamSolution: ?Entity<GQLPairingSolution>,
};

const dict = createDict({
  solution_proposal: 'Solution Proposal',
  propose_solution: 'Propose solution',
  debit: 'Debit',
  credit: 'Credit',

  choose: 'Choose',
});

const styles = StyleSheet.create({
  titleText: css`
    color: $text-muted;
  `,
  divider: css`
    border-bottom-width: 1px;
    border-color: $gray-200;
  `,
  left: css`
    align-items: flex-start;
    width: 50%;
    border-right-width: 1px;
    border-color: $gray-200;
  `,
  right: css`
    align-items: flex-end;
    width: 50%;
  `,
  icon: css`
    font-size: 0.75rem;
    margin: 0rem;
  `,
});

function SolutionFormAccounting({
  pairing,
  examAttemptId,
  controls,
  viewerExamSolution,
}: Props): React$Node {
  const dispatch = useDispatch();

  const initialDebitSolution =
    viewerExamSolution && viewerExamSolution.pairs.filter((solution) => solution.left === 1);
  const initialCreditSolution =
    viewerExamSolution && viewerExamSolution.pairs.filter((solution) => solution.left === 2);

  const initialDebitPieces =
    initialDebitSolution &&
    initialDebitSolution.map((debit) => {
      return pairing.pieces.filter((piece) => piece.key === debit.right)[0];
    });
  const debitPieces = useValueState(initialDebitPieces || []);

  const initialCreditPieces =
    initialCreditSolution &&
    initialCreditSolution.map((credit) => {
      return pairing.pieces.filter((piece) => piece.key === credit.right)[0];
    });
  const creditPieces = useValueState(initialCreditPieces || []);

  const handleRemoveSelectedAccount = (dropdownPiece, type) => {
    if (controls.loading) {
      return;
    }

    controls.block();
    dispatch(updateOrCreateSolution(pairing.id, examAttemptId, dropdownPiece.key, null)).finally(
      () => {
        controls.unblock();

        const pieces = type === 'DEBIT' ? debitPieces : creditPieces;
        const newPieces = pieces.value.filter((piece) => piece.id !== dropdownPiece.id);
        pieces.set(newPieces);
      },
    );
  };

  return (
    <>
      <View styleName="my-3">
        <View styleName="flex-row flex-grow-1 flex-shrink-1 jc-between">
          <View style={styles.left} styleName="py-2">
            <Trans {...dict('debit')} style={styles.titleText} />
          </View>
          <View style={styles.right} styleName="py-2">
            <Trans {...dict('credit')} style={styles.titleText} />
          </View>
        </View>
        <View style={styles.divider} />
        <View styleName="flex-row flex-grow-1 flex-shrink-1 jc-between">
          <View style={styles.left} styleName="py-3">
            <Dropdown>
              <Button toggle={Dropdown} color="secondary" outline styleName="mb-3">
                <Trans {...dict('choose')} />
              </Button>
              <Dropdown.Menu>
                {pairing.pieces.map((dropdownPiece, index) => (
                  <Dropdown.Item
                    key={dropdownPiece.id}
                    onPress={() => {
                      if (controls.loading) {
                        return;
                      }

                      const debitPieceFound = debitPieces.value.filter(
                        (piece) => piece.id === dropdownPiece.id,
                      );

                      if (!debitPieceFound.length) {
                        controls.block();
                        dispatch(
                          updateOrCreateSolution(pairing.id, examAttemptId, dropdownPiece.key, 1),
                        ).finally(() => {
                          controls.unblock();

                          debitPieces.value.push(dropdownPiece);

                          const newCreditPieces = creditPieces.value.filter(
                            (piece) => piece.id !== dropdownPiece.id,
                          );
                          creditPieces.set(newCreditPieces);
                        });
                      }
                    }}
                  >
                    <Text>
                      {index + 1}. {dropdownPiece.text}
                    </Text>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {debitPieces.value &&
              debitPieces.value.map((piece) => (
                <View key={piece.id} styleName="flex-row ai-center jc-end mb-2">
                  <Text align="left">{piece.text}</Text>
                  <OptionButton
                    onPress={() => {
                      handleRemoveSelectedAccount(piece, 'DEBIT');
                    }}
                    styleName="ml-2"
                  >
                    <Icon glyph="cancel" style={styles.icon} />
                  </OptionButton>
                </View>
              ))}
          </View>
          <View style={styles.right} styleName="py-3">
            <Dropdown>
              <Button toggle={Dropdown} color="secondary" outline styleName="mb-3">
                <Trans {...dict('choose')} />
              </Button>
              <Dropdown.Menu end>
                {pairing.pieces.map((dropdownPiece, index) => (
                  <Dropdown.Item
                    key={dropdownPiece.id}
                    onPress={() => {
                      if (controls.loading) {
                        return;
                      }

                      const creditPieceFound = creditPieces.value.filter(
                        (piece) => piece.id === dropdownPiece.id,
                      );

                      if (!creditPieceFound.length) {
                        controls.block();
                        dispatch(
                          updateOrCreateSolution(pairing.id, examAttemptId, dropdownPiece.key, 2),
                        ).finally(() => {
                          controls.unblock();

                          creditPieces.value.push(dropdownPiece);

                          const newDebitPieces = debitPieces.value.filter(
                            (piece) => piece.id !== dropdownPiece.id,
                          );
                          debitPieces.set(newDebitPieces);
                        });
                      }
                    }}
                  >
                    <Text>
                      {index + 1}. {dropdownPiece.text}
                    </Text>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {creditPieces.value &&
              creditPieces.value.map((piece) => (
                <View key={piece.id} styleName="flex-row ai-center jc-end mb-2">
                  <Text align="right">{piece.text}</Text>
                  <OptionButton
                    onPress={() => {
                      handleRemoveSelectedAccount(piece, 'CREDIT');
                    }}
                    styleName="ml-2"
                  >
                    <Icon glyph="cancel" style={styles.icon} />
                  </OptionButton>
                </View>
              ))}
          </View>
        </View>
      </View>
    </>
  );
}

export default SolutionFormAccounting;
