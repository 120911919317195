// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { Toast as BaseToast, Link, Text, View, CloseButton } from 'components/core';

type Props = {
  title?: ?string,
  content: React$Node,
  link?: ?string,
  dismiss: () => void,
  pauseTimeout: () => void,
  resumeTimeout: () => void,
  dismissible?: boolean,
};

const styles = StyleSheet.create({
  close: css`
    position: absolute;
    top: 1px;
    right: 1px;
    margin: 0;
    padding: 0.375rem;
  `,
});

const defaultProps = {
  title: null,
  link: null,
  dismissible: true,
};

function Toast({
  dismiss,
  pauseTimeout,
  resumeTimeout,
  title,
  content,
  link,
  dismissible,
}: Props): React$Node {
  const inner = (
    <>
      {title && (
        <BaseToast.Header>
          <Text>{title}</Text>
        </BaseToast.Header>
      )}
      <BaseToast.Body>
        <Text>{content}</Text>
      </BaseToast.Body>
    </>
  );

  return (
    <BaseToast onMouseEnter={pauseTimeout} onMouseLeave={resumeTimeout}>
      {link && (
        <Link
          to={link}
          onPress={() => {
            dismiss();
          }}
        >
          {inner}
        </Link>
      )}
      {!link && <View>{inner}</View>}
      {dismissible && <CloseButton onPress={dismiss} style={styles.close} />}
    </BaseToast>
  );
}

Toast.defaultProps = defaultProps;

export default Toast;
