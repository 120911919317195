// @flow
import { createAsyncContainer } from 'libs/container';
import { selectByRoot } from 'libs/graphql';

const VerifyEmail = {
  name: 'VerifyEmail',
  bundle: () => import('./VerifyEmail'),
};

const Container = createAsyncContainer(VerifyEmail, () => {
  return {
    selectors: {
      viewer: selectByRoot('viewer'),
    },
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
