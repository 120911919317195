// @flow
import React from 'react';
import { View } from 'components/core';
import { Form } from 'components/forms';
import { StyleSheet, css } from 'libs/ui';
import ClozeTemplate from './ClozeTemplate';

type Props = {
  name: string,
  cloze: Entity<GQLCloze>,
};

const styles = StyleSheet.create({
  text: css`
    line-height: 2.5rem;
  `,
  input: css`
    width: 6rem;
  `,
});

function ClozeInput({ cloze, name }: Props): React$Node {
  return (
    <ClozeTemplate
      cloze={cloze}
      gaps={(gap) => (
        <View style={styles.input} key={gap.key}>
          <Form.Input name={`${name}[${gap.key}]`} size="sm" styleName="mb-n2" />
        </View>
      )}
      textStyle={styles.text}
    />
  );
}

export default ClozeInput;
