// @flow
import React from 'react';
import { Cookies } from 'libs/misc';
import { StyleSheet, css } from 'libs/ui';
import { useIntl } from 'libs/intl';
import { Icon, FooterLink } from 'components/common';
import { Text } from 'components/core';
import fetchMessages from 'libs/intl/fetchMessages';

const styles = StyleSheet.create({
  text: css`
    color: $text-muted;
  `,
});

function LocaleSwitcher(): React$Node {
  const intl = useIntl();

  return (
    <Text style={styles.text} small styleName="mb-3">
      <Icon glyph="globe" />{' '}
      <FooterLink
        onPress={() => {
          Cookies.set('lang', 'en');

          fetchMessages('en').then((messages) => {
            intl.changeLocale('en', messages);
          });
        }}
      >
        English
      </FooterLink>
      <Text styleName="mx-1">|</Text>
      <FooterLink
        onPress={() => {
          Cookies.set('lang', 'de');

          fetchMessages('de').then((messages) => {
            intl.changeLocale('de', messages);
          });
        }}
      >
        Deutsch
      </FooterLink>
    </Text>
  );
}

export default LocaleSwitcher;
