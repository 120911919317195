// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query, selectByRoot } from 'libs/graphql';

const TeamCourseList = {
  name: 'TeamCourseList',
  bundle: () => import('./TeamCourseList'),
};

const ORGANIZATION_TEAM_COURSES_QUERY = gql`
  query OrganizationQuery($slug: String!, $teamSlug: String!) {
    organization(slug: $slug) {
      name
      slug
      team(teamSlug: $teamSlug) {
        name
        slug
        courses {
          name
          slug
          caption
          restricted
          isPrivate
          organizationRestriction
          emailRestriction
        }
        viewerCanUpdate
      }
    }
  }
`;

const Container = createAsyncContainer(TeamCourseList, (props) => {
  const {
    params: { slug, teamSlug },
  } = props;

  const variables = { slug, teamSlug };

  const loaders = {
    query: query(ORGANIZATION_TEAM_COURSES_QUERY, { variables }),
  };

  const selectors = {
    organization: select(ORGANIZATION_TEAM_COURSES_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
