// @flow
import React from 'react';
import { View, Button, Text, Link, Badge } from 'components/core';
import { Icon } from 'components/common';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { useToggleState } from 'libs/misc';
import { createDict, Trans } from 'libs/intl';
import BrandHeading from './BrandHeading';
import BrandText from './BrandText';

type Props = {
  to: string,
  title: string,
  children: React$Node,
  price: string,
  paid?: boolean,
  bestseller?: boolean,
  newYears?: boolean,
};

const styles = StyleSheet.create({
  boxDesktop: css`
    min-height: 14rem;
    width: 24.125rem;
    background-color: #ebfafa;
    border-radius: 0.1875rem;
    padding: 1rem;
    margin: 0.5rem;
  `,
  boxMobile: css`
    background-color: #ebfafa;
    padding: 1rem;
    flex-grow: 1;
    flex-shrink: 1;
    margin-bottom: 0.5rem;
  `,
  heading: css`
    text-align: left;
    text-transform: none;
    margin-bottom: 1rem;
  `,
  badge: css`
    flex-direction: row;
    background-color: #ff6186;
    margin-bottom: 0.25rem;
  `,
});

const dict = createDict({
  courses_included: 'Courses included',
  view_plans: 'View plans',
  join_for_free: 'Join for free',
  new_years_special: 'NEW YEARS SPECIAL',
});

const defaultProps = {
  paid: false,
  bestseller: false,
  newYears: false,
};

function FeaturedBundle(props: Props): React$Node {
  const { to, title, children, price, paid, bestseller, newYears } = props;
  const media = useMedia();
  const featuredCourses = useToggleState(false);

  return (
    <View style={[media.up('md') ? styles.boxDesktop : styles.boxMobile]}>
      <View styleName="flex-column flex-grow-1 flex-shrink-1 jc-between">
        <View styleName="flex-column flex-grow-1 flex-shrink-1 jc-md-start">
          <BrandHeading size={5} style={styles.heading} numberOfLines={2}>
            {title}
          </BrandHeading>
          <Text styleName="mb-2">
            <Link onPress={featuredCourses.toggle}>
              <Icon glyph="book-open" styleName="mr-2" />
              <Trans {...dict('courses_included')} />
            </Link>
          </Text>
          {featuredCourses.on && <>{children}</>}
        </View>
        <View styleName="flex-row mt-2">
          <View styleName="flex-column">
            {newYears && (
              <View styleName="flex-grow-1 flex-shrink-1 jc-end ai-start mr-2">
                <Badge style={[styles.badge]}>
                  <Trans {...dict('new_years_special')} />
                </Badge>
              </View>
            )}
          </View>
          {bestseller && (
            <View styleName="flex-grow-1 flex-shrink-1 jc-end ai-start">
              <Badge style={[styles.badge]}>
                <Text>BESTSELLER</Text>
              </Badge>
            </View>
          )}
          <View styleName="flex-grow-1 flex-shrink-1 jc-end ai-end">
            <BrandText bold>{price}</BrandText>
          </View>
        </View>
        <Button to={to} size="lg" styleName="mt-3">
          <Text>
            {paid && <Trans {...dict('view_plans')} />}
            {!paid && <Trans {...dict('join_for_free')} />}
          </Text>
        </Button>
      </View>
    </View>
  );
}

FeaturedBundle.defaultProps = defaultProps;

export default FeaturedBundle;
