// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const ACTIVITY_VERSION_ITEM_ATTACHMENTS_ARTICLE_FRAGMENT: DocumentNode = gql`
  fragment ActivityVersionItemAttachmentsArticleFragment on ArticleActivityVersion {
    item {
      files {
        id
      }
    }
  }
`;

export const ACTIVITY_VERSION_ITEM_ATTACHMENTS_EXAM_FRAGMENT: DocumentNode = gql`
  fragment ActivityVersionItemAttachmentsExamFragment on ExamActivityVersion {
    item {
      files {
        id
      }
    }
  }
`;

export const ACTIVITY_VERSION_ITEM_ATTACHMENTS_QUIZ_FRAGMENT: DocumentNode = gql`
  fragment ActivityVersionItemAttachmentsQuizFragment on QuizActivityVersion {
    item {
      files {
        id
      }
    }
  }
`;

export const ACTIVITY_VERSION_ITEM_ATTACHMENTS_SURVEY_FRAGMENT: DocumentNode = gql`
  fragment ActivityVersionItemAttachmentsSurveyFragment on SurveyActivityVersion {
    item {
      files {
        id
      }
    }
  }
`;

export const ACTIVITY_VERSION_ITEM_ATTACHMENTS_VIDEO_FRAGMENT: DocumentNode = gql`
  fragment ActivityVersionItemAttachmentsVideoFragment on VideoActivityVersion {
    item {
      files {
        id
      }
    }
  }
`;
