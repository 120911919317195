// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const MENU_CHECKLIST_TODO_ACTIVITY_FRAGMENT: DocumentNode = gql`
  fragment MenuChecklistTodoActivityFragment on CourseActivity {
    title
    slug
    course {
      slug
      name
    }
    viewerCanAccess
    viewerResult {
      createdAt
    }
  }
`;
