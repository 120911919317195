// @flow
import React from 'react';

type Props = {
  videoId: string,
  start?: number,
  end?: number,
  maxWidth?: number,
};

function YoutubeVideoPlayer({ videoId, start, end, maxWidth }: Props): React$Node {
  const startParam = start ? `&start=${start}` : '';
  const endParam = end ? `&end=${end}` : '';

  return (
    <iframe
      title="Youtube Video"
      src={`https://www.youtube-nocookie.com/embed/${videoId}?modestbranding=1&rel=0${startParam}${endParam}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      style={{
        width: '100%',
        maxWidth,
        aspectRatio: 16 / 9,
      }}
    />
  );
}

export default YoutubeVideoPlayer;
