// @flow
import React, { useMemo } from 'react';
import { Well } from 'components/common';
import { Modal, Button, View, Text, Paragraph, CloseButton, Link } from 'components/core';
import { StyleSheet, css } from 'libs/ui';
import { useDispatch } from 'libs/graphql';
import { Form } from 'components/forms';
import { createDict, CurrencyNumber, Trans } from 'libs/intl';
import { isPurchasableProduct } from 'utils/products';
import { useNavigate } from 'libs/routing';
import { updateOrCreateProductOfferPurchase } from './mutations';

type Props = {
  course: Entity<GQLCourse>,
  modal: ToggleState,
};

const styles = StyleSheet.create({
  box: css`
    padding: 1rem;
    border-width: 1px;
    border-color: $gray-200;
    border-radius: 0.25rem;
  `,
});

const dict = createDict({
  buy_course_license: 'Buy Course License',
  choose_a_bundle_that_includes_this_course: 'Choose a bundle that includes this course:',
  suitable_for: 'Suitable for',
  course: 'Course',
  name: 'Name',
  no_course_licenses_added_yet: 'No course license added yet.',
  cancel: 'Cancel',
  close: 'Close',
  unlimited_for: 'Unlimited for',
  x_months_for: '{duration} {count, plural, =1 {month} other {months}} for',
  courses_included: 'Courses included',
  this_course: 'this course',
  month: 'month',
  offers: 'Offers',
  checkout: 'Checkout',
});

const getProduct = (products, productId) => {
  const product = products.find((p) => p.id === productId);

  if (!product) {
    throw new Error('Product not found.');
  }

  return product;
};

const getOfferOptions = (product) => {
  return product.offers
    .filter((offer) => offer.price)
    .map((offer) => {
      return {
        value: offer.id,
        label: (
          <Text>
            {offer.duration ? (
              <Trans {...dict('x_months_for', { duration: offer.duration })} />
            ) : (
              <Trans {...dict('unlimited_for')} />
            )}{' '}
            <CurrencyNumber
              // $FlowFixMe[incompatible-use]
              value={offer.price.price.amount}
              // $FlowFixMe[incompatible-use]
              code={offer.price.price.currency.code}
            />
          </Text>
        ),
      };
    });
};

function UserLicensePurchaseModal({ course, modal }: Props): React$Node {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const products = useMemo(
    () =>
      course.products
        .filter((product) => product.offers.some((offer) => offer.price))
        .filter(isPurchasableProduct),
    [],
  );

  const productOptions = useMemo(
    () =>
      products.map((product) => {
        return { value: product.id, label: product.name };
      }),
    [],
  );

  return (
    <Modal visible={modal.on} onToggle={modal.toggle}>
      <Modal.Header styleName="flex-row">
        <Modal.Title>
          <Trans {...dict('buy_course_license')} />
        </Modal.Title>
        <CloseButton onPress={modal.toggle} />
      </Modal.Header>
      {products.length === 0 ? (
        <Modal.Body>
          <Well styleName="py-5">
            <Paragraph styleName="mb-4" align="center">
              <Trans color="muted" {...dict('no_course_licenses_added_yet')} />
            </Paragraph>
            <Button onPress={modal.toggle} color="success" size="lg">
              <Trans {...dict('close')} />
            </Button>
          </Well>
        </Modal.Body>
      ) : (
        <Form
          initialValues={{
            product: products[0].id,
            offer: getOfferOptions(products[0])[0]?.value || null,
          }}
          validate={{
            product: 'required',
            offer: 'required',
          }}
          onSubmit={async (values) => {
            const product = getProduct(products, values.product);
            const offer = product.offers.find((o) => o.id === values.offer);

            if (!offer || !offer.price) {
              throw new Error('Offer not found.');
            }

            const data = await dispatch(updateOrCreateProductOfferPurchase(offer.price.id));

            const { link } = data.roots.productOfferPurchase_updateOrCreate;
            navigate(`/checkout/${link[1]}`);
          }}
        >
          {(form) => {
            const product = getProduct(products, form.values.product);

            return (
              <>
                <Modal.Body>
                  <Trans {...dict('choose_a_bundle_that_includes_this_course')} styleName="mb-3" />
                  <Form.Picker
                    name="product"
                    options={productOptions}
                    onValueChange={(value) => {
                      const newProductOptions = getOfferOptions(getProduct(products, value));

                      if (newProductOptions.length === 1) {
                        form.setFieldValue('offer', newProductOptions[0].value, false);
                      } else {
                        form.setFieldValue('offer', null, false);
                      }
                    }}
                  />
                  <View style={styles.box} styleName="mb-3">
                    {product.professions.length > 0 && (
                      <View styleName="ai-start mb-3">
                        <Trans bold small {...dict('suitable_for')} />
                        {product.professions.map((profession) => (
                          <Link key={profession.id} to={`/professions/${profession.slug}`} small>
                            {profession.name}
                          </Link>
                        ))}
                      </View>
                    )}
                    <View styleName="ai-start">
                      <Trans bold small {...dict('courses_included')} />
                      {product.courses.map((productCourse) => (
                        <React.Fragment key={productCourse.id}>
                          {productCourse.slug === course.slug ? (
                            <Text small>
                              {productCourse.name}{' '}
                              <Text color="muted">
                                (<Trans {...dict('this_course')} />)
                              </Text>
                            </Text>
                          ) : (
                            <Link to={`/courses/${productCourse.slug}`} small>
                              {productCourse.name}
                            </Link>
                          )}
                        </React.Fragment>
                      ))}
                    </View>
                  </View>
                  <View style={styles.box} styleName="py-2">
                    <Form.Choice
                      name="offer"
                      niceName={dict('offers')}
                      options={getOfferOptions(product)}
                      styleName="mb-0"
                    />
                  </View>
                </Modal.Body>
                <Modal.Footer styleName="flex-row jc-end ai-end">
                  <Button color="light" onPress={modal.toggle} styleName="mr-3">
                    <Trans {...dict('cancel')} />
                  </Button>
                  <Form.Button type="submit" disabled={!form.values.offer}>
                    <Trans {...dict('checkout')} />
                  </Form.Button>
                </Modal.Footer>
              </>
            );
          }}
        </Form>
      )}
    </Modal>
  );
}

export default UserLicensePurchaseModal;
