// @flow
import React from 'react';
import { Outlet } from 'libs/routing';
import TeamHeader from './TeamHeader';

function TeamLayout(): React$Node {
  return (
    <>
      <TeamHeader />
      <Outlet />
    </>
  );
}

export default TeamLayout;
