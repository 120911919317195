// @flow
import { createContainer } from 'libs/container';
import { selectByRoot } from 'libs/graphql';
import VogelSPOT from './VogelSPOT';

const Container = createContainer(VogelSPOT, () => {
  const selectors = {
    viewer: selectByRoot('viewer'),
  };

  return {
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
