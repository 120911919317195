// @flow
import { createAsyncContainer } from 'libs/container';
import { select, query, gql, selectByRoot } from 'libs/graphql';
import { CLOZE_EXAM_FRAGMENT } from './Cloze';
import { MULTIPLE_CHOICE_EXAM_FRAGMENT } from './MultipleChoice';
import { OPEN_QUESTION_EXAM_FRAGMENT } from './OpenQuestion';
import { PAIRING_EXAM_FRAGMENT } from './Pairing';
import { TRUE_FALSE_EXAM_FRAGMENT } from './TrueFalse';
import TaskExamLoading from './TaskExamLoading';

const TaskExam = {
  name: 'TaskExam',
  bundle: () => import('./TaskExam'),
};

const VIEWER_RUNNING_TASK_EXAM_QUERY = gql`
  query ViewerRunningExamQuery($courseSlug: String!) {
    course(slug: $courseSlug) {
      examActivityForRunningAttempt {
        title
        slug
        versionForRunningAttempt {
          item {
            info
            numberingFormat
            partNumberingFormat
            tasks {
              position
              title
              intro {
                text
              }
              parts {
                points
                position
                title
                type {
                  ...ClozeExamFragment
                  ...MultipleChoiceExamFragment
                  ...OpenQuestionExamFragment
                  ...PairingExamFragment
                  ...TrueFalseExamFragment
                }
              }
            }
            exam {
              slug
              duration
              points
              taskPoints {
                position
                points
                parts {
                  position
                  points
                }
              }
              viewerRunningAttempt {
                number
                expiredAt
              }
            }
          }
        }
      }
    }
  }

  ${CLOZE_EXAM_FRAGMENT}
  ${MULTIPLE_CHOICE_EXAM_FRAGMENT}
  ${OPEN_QUESTION_EXAM_FRAGMENT}
  ${PAIRING_EXAM_FRAGMENT}
  ${TRUE_FALSE_EXAM_FRAGMENT}
`;

const options = {
  async: {
    loading: TaskExamLoading,
  },
};

const Container = createAsyncContainer(
  TaskExam,
  (props) => {
    const {
      params: { courseSlug },
    } = props;

    const variables = {
      courseSlug,
    };

    const loaders = {
      query: query(VIEWER_RUNNING_TASK_EXAM_QUERY, { variables }),
    };

    const selectors = {
      course: select(VIEWER_RUNNING_TASK_EXAM_QUERY, { variables }),
      viewer: selectByRoot('viewer'),
    };

    return {
      loaders,
      selectors,
    };
  },
  options,
);

export default (Container: React$AbstractComponent<any, mixed>);
