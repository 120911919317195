// @flow
import React from 'react';
import { Text, View } from 'components/core';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { useIntl } from 'libs/intl';
import { FeaturesGrid, BrandHeading } from 'components/pages';

const styles = StyleSheet.create({
  featuresCard: css`
    background-color: #f3f5f6;
  `,
  featuresCardDesktop: css`
    align-items: center;
    justify-content: center;
  `,
  featuresCardMobile: css`
    padding: 1rem;
  `,
  container: css`
    align-items: center;
    justify-content: center;
  `,
  heading: css`
    text-align: center;
  `,
  caption: css`
    padding-top: 2rem;
    padding-bottom: 4rem;
    text-align: center;
  `,
});

function FeaturesSection(): React$Node {
  const media = useMedia();
  const intl = useIntl();

  return (
    <View
      style={[
        styles.featuresCard,
        media.down('sm') ? styles.featuresCardMobile : styles.featuresCardDesktop,
      ]}
    >
      <View style={styles.container}>
        <BrandHeading size={1} style={styles.heading}>
          <Text align="center">{intl.locale === 'de' ? 'Darum Azubinet' : 'Why Azubinet'}</Text>
        </BrandHeading>
      </View>
      <BrandHeading style={styles.caption} align="center" size={4}>
        {intl.locale === 'de'
          ? 'Vorteile für Ausbildungsbetriebe'
          : 'Benefits for training companies'}
      </BrandHeading>
      <FeaturesGrid>
        <FeaturesGrid.Item
          icon="/img/home/feature-laptop.png"
          feature={intl.locale === 'de' ? 'Erklärvideos' : 'Tutorial Videos'}
          text={
            intl.locale === 'de'
              ? 'Schwierige Themen in wenigen Minuten zusammengefasst'
              : 'Difficult topics explained within a couple of minutes'
          }
        />
        <FeaturesGrid.Item
          icon="/img/home/feature-exam.png"
          feature={intl.locale === 'de' ? 'Klausuren auf IHK-Niveau' : 'Authentic mock exams'}
          text={
            intl.locale === 'de'
              ? 'Hunderte Klausuren wie in den IHK-Zwischen- und Abschlussprüfungen'
              : 'Easily create your own materials and share them with your learners'
          }
        />
        <FeaturesGrid.Item
          icon="/img/home/feature-flashcards.png"
          feature={intl.locale === 'de' ? 'Lernkarten-Sets' : 'Flashcard Sets'}
          text={
            intl.locale === 'de'
              ? 'Über 10.000 Lernkarten zu Schul- und Prüfungsthemen'
              : '10,000+ flashcards for topics covered in school and in exams'
          }
        />
        <FeaturesGrid.Item
          icon="/img/home/feature-laptop.png"
          feature={
            intl.locale === 'de' ? 'Die Lerngruppe geht online' : 'Study groups are moving online'
          }
          text={
            intl.locale === 'de'
              ? 'Das Azubinet-Team und andere Azubis helfen bei Problemen'
              : 'Our Azubinet team and other trainees help your trainees when they experience problems'
          }
        />
        <FeaturesGrid.Item
          icon="/img/home/feature-docs.png"
          feature={intl.locale === 'de' ? 'Autorentools' : 'Authoring Tools'}
          text={
            intl.locale === 'de'
              ? 'Erstellen Sie eigene Materialien wie Lernkarten, Umfragen oder Checklisten'
              : 'Create your own materials such as flashcards, surveys and checklists'
          }
        />
        <FeaturesGrid.Item
          icon="/img/home/feature-stats.png"
          feature={intl.locale === 'de' ? 'Wissensweitergabe' : 'Knowledge Management'}
          text={
            intl.locale === 'de'
              ? 'Mit Reviews und Lernkarten geben Azubis ihr Wissen an ihre Nachfolger weiter'
              : 'Materials like reviews and flashcards can be used by your future apprentices'
          }
        />
      </FeaturesGrid>
    </View>
  );
}

export default FeaturesSection;
