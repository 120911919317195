// @flow
import React from 'react';
import { Card, Paragraph } from 'components/core';
import { Well } from 'components/common';
import { Page } from 'components/layout';
import { createDict, Trans } from 'libs/intl';

const dict = createDict({
  you_dont_have_access_to_this_page: "You don't have access to this page.",
});

function ViewerHasNoAccess(): React$Node {
  return (
    <Page windowTitle={dict('you_dont_have_access_to_this_page')} styleName="mx-auto">
      <Page.Main size={12}>
        <Card>
          <Card.Body>
            <Well styleName="py-5">
              <Paragraph lead styleName="mb-4">
                <Trans {...dict('you_dont_have_access_to_this_page')} />
              </Paragraph>
            </Well>
          </Card.Body>
        </Card>
      </Page.Main>
    </Page>
  );
}

export default ViewerHasNoAccess;
