// @flow
import React from 'react';
import { Text } from 'components/core';
import { useIntl } from 'intlized-components';

type Props = {
  value: number,
  code: string,
  options?: Object,
};

function CurrencyNumber({ value, code, options, ...textProps }: Props): React$Node {
  const intl = useIntl();

  const currencyOptions = {
    ...options,
    style: 'currency',
    currency: code,
    minimumFractionDigits: 2,
  };

  // Divide value by 100, because we save price amounts as integers.
  return <Text {...textProps}>{intl.number(value / 100, currencyOptions)}</Text>;
}

export default CurrencyNumber;
