// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import { DOCUMENT_FILE_ICON_FRAGMENT } from './DocumentFileIcon/fragments';

export const DOCUMENT_ACTIVITY_VERSION_ICON_FRAGMENT: DocumentNode = gql`
  fragment DocumentActivityVersionIconFragment on DocumentActivityVersion {
    item {
      type {
        ...DocumentFileIconFragment
      }
    }
  }

  ${DOCUMENT_FILE_ICON_FRAGMENT}
`;
