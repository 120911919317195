// @flow
import React from 'react';
import { ListGroup } from 'components/core';
import BundleCompact from './BundleCompact';

type Props = {
  onClose?: () => void,
};

function AutomobilkaufleuteBundles({ onClose }: Props): React$Node {
  return (
    <ListGroup>
      <BundleCompact
        to="/courses/5Hb1wZWF7Q"
        title="Erstmal reinschnuppern"
        price="kostenlose Lernmaterialien und Community"
        onClose={onClose}
      />
      <BundleCompact
        to="/professions/automobilkauffrau-automobilkaufmann"
        title="Lernpaket Automobilkaufleute"
        price="ab 26,95 EUR"
        paid
      />
    </ListGroup>
  );
}

export default AutomobilkaufleuteBundles;
