// @flow
import React from 'react';
import { Nav } from 'components/core';
import { createDict, Trans } from 'libs/intl';
import { StyleSheet, css } from 'libs/ui';
import { useParams } from 'libs/routing';

const styles = StyleSheet.create({
  nav: css`
    margin: 0;
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column;
  `,
});

const dict = createDict({
  settings: 'Settings',
  members: 'Members',
  permissions: 'Permissions',
  access: 'Access',
  categories: 'Categories',
});

function CourseSettingsNav(): React$Node {
  const { courseSlug } = useParams();

  return (
    <Nav variant="pills" style={styles.nav}>
      <Nav.Link to={`/courses/${courseSlug}/settings`} end>
        <Trans {...dict('settings')} />
      </Nav.Link>
      <Nav.Link to={`/courses/${courseSlug}/settings/access`}>
        <Trans {...dict('access')} />
      </Nav.Link>
      <Nav.Link to={`/courses/${courseSlug}/settings/members`}>
        <Trans {...dict('members')} />
      </Nav.Link>
      <Nav.Link to={`/courses/${courseSlug}/settings/permissions`}>
        <Trans {...dict('permissions')} />
      </Nav.Link>
      <Nav.Link to={`/courses/${courseSlug}/settings/categories`}>
        <Trans {...dict('categories')} />
      </Nav.Link>
    </Nav>
  );
}

export default CourseSettingsNav;
