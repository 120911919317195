// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { Text } from 'components/core';

// $FlowFixMe[cannot-resolve-module]
import config from '../../../public/fonts/fontello/config.json';

type Props = {
  glyph: string,
  style?: any,
};

const styles = StyleSheet.create({
  default: css`
    font-family: 'fontello';
    font-weight: normal;
    font-style: normal;
  `,
});

const glyphMap = {};
config.glyphs.forEach((glyph) => {
  glyphMap[glyph.css] = glyph.code;
});

const getGlyph = (glyph) => {
  const code = glyphMap[glyph];

  if (code && typeof code === 'number') {
    return String.fromCharCode(code);
  }

  return '?';
};

function Icon({ glyph, style, ...props }: Props): React$Node {
  return (
    <Text {...props} style={[styles.default, style]}>
      {getGlyph(glyph)}
    </Text>
  );
}

export default Icon;
