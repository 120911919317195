// @flow
import React from 'react';
import { View, Image, Button, Text } from 'components/core';
import { Icon } from 'components/common';
import { StyleSheet, css, useMedia } from 'libs/ui';
import BrandHeading from './BrandHeading';
import BrandLargeText from './BrandLargeText';

type Props = {
  title: string,
  content: string,
  textPosition: string,
  image: string,
  buttonLink?: boolean,
  buttonOnPress?: boolean,
  to?: any,
  onPress?: any,
  textButton?: string,
  external?: boolean,
  icon?: string,
};

const styles = StyleSheet.create({
  container: css`
    align-items: center;
    padding-horizontal: 1rem;
  `,
  content: css`
    flex-shrink: 1;
  `,
  heading: css`
    padding-bottom: 1.5rem;
  `,
  textMobile: css`
    text-align: center;
  `,
  imageLeft: css`
    margin-left: 4rem;
  `,
  imageRight: css`
    margin-right: 4rem;
  `,
  imageDesktop: css`
    width: 444px;
    height: 254px;
    margin-vertical: 3rem;
  `,
  imageMobile: css`
    width: 320px;
    height: 184px;
    margin-top: 4rem;
    margin-bottom: 2rem;
    margin-right: 0;
    margin-left: 0;
  `,
});

function FeatureStripe(props: Props): React$Node {
  const {
    title,
    content,
    textPosition,
    image,
    buttonLink = false,
    buttonOnPress = false,
    to,
    onPress,
    textButton,
    external,
    icon = false,
  } = props;
  const media = useMedia();

  const textLeft = textPosition === 'left' && media.up('md');

  return (
    <View style={styles.container} styleName={textLeft ? 'flex-md-row' : 'flex-md-row-reverse'}>
      <View style={styles.content}>
        <BrandHeading style={[styles.heading, media.down('sm') && styles.textMobile]} size={2}>
          {title}
        </BrandHeading>
        <BrandLargeText style={[media.down('sm') && styles.textMobile]}>{content}</BrandLargeText>
        {buttonLink && (
          <View styleName="jc-center ai-center jc-md-start ai-md-start mt-3">
            <Button to={to} size="lg" external={external}>
              {icon && <Icon glyph={icon} styleName="mr-2" />}
              <Text>{textButton}</Text>
            </Button>
          </View>
        )}
        {buttonOnPress && (
          <View styleName="jc-center ai-center jc-md-start ai-md-start mt-3">
            <Button onPress={onPress} size="lg">
              {icon && <Icon glyph={icon} styleName="mr-2" />}
              <Text>{textButton}</Text>
            </Button>
          </View>
        )}
      </View>
      <Image
        source={image}
        label={title}
        style={[
          textLeft ? styles.imageLeft : styles.imageRight,
          media.up('md') ? styles.imageDesktop : styles.imageMobile,
        ]}
      />
    </View>
  );
}

export default FeatureStripe;
