// @flow
import React from 'react';
import { Modal, Text, Image, CloseButton } from 'components/core';
import { StyleSheet, css, useMedia } from 'libs/ui';

type Props = {
  modal: ToggleState,
};

const styles = StyleSheet.create({
  imageDesktop: css`
    height: 800px;
    width: 566px;
  `,
  imageMobile: css`
    height: 400px;
    width: 283px;
  `,
});

function EFREModal({ modal }: Props): React$Node {
  const media = useMedia();
  return (
    <Modal visible={modal.on} onToggle={modal.toggle} size="lg">
      <Modal.Header styleName="flex-row">
        <Modal.Title>
          <Text>EFRE Messebeteiligung</Text>
        </Modal.Title>
        <CloseButton onPress={modal.toggle} />
      </Modal.Header>
      <Modal.Body styleName="jc-center ai-center">
        <Text>
          Das Vorhaben der Examunity UG (haftungsbeschränkt) &bdquo;Teilnahme an der Messe Learntec
          2022&rdquo; wird im Rahmen des Programms &bdquo;Messebeteiligungen für Unternehmen&rdquo;
          finanziell durch die Europäische Union und das Land Sachsen-Anhalt unterstützt.
        </Text>
        <Image
          source="/img/home/EFRE-Messe-Banner.png"
          label="Messebeteiligung"
          style={media.up('md') ? styles.imageDesktop : styles.imageMobile}
        />
      </Modal.Body>
    </Modal>
  );
}

export default EFREModal;
