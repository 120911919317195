// @flow
import React from 'react';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { Icon, FooterLink } from 'components/common';
import { View, Container, Grid, Text } from 'components/core';
import LocaleSwitcher from './LocaleSwitcher';
import ViewSwitcher from './ViewSwitcher';

type Props = {
  domain: Entity<GQLDomain>,
};

const styles = StyleSheet.create({
  footer: css`
    background-color: $gray-800;
    width: 100%;
    margin-top: 5rem;
    padding-top: 0;
    flex-shrink: 0;
  `,
  footerDesktop: css`
    min-height: 10rem;
  `,
  footerMobile: css`
    min-height: 16rem;
  `,
  container: css`
    padding: 1rem;
  `,
  containerMobile: css`
    max-width: 560px;
  `,
  infoMobile: css`
    border-bottom-width: 1px;
    border-bottom-color: rgba(255, 255, 255, 0.05);
    margin-bottom: 1rem;
    align-items: center;
  `,
  copyright: css`
    padding-top: 1rem;
    text-align: center;
  `,
  text: css`
    color: $gray-400;
  `,
});

const currentYear = new Date().getFullYear();

function FooterOthers({ domain }: Props): React$Node {
  const media = useMedia();

  return (
    <View
      role="contentinfo"
      style={[styles.footer, media.up('md') ? styles.footerDesktop : styles.footerMobile]}
    >
      <Container style={[styles.container, media.down('sm') && styles.containerMobile]}>
        <Grid>
          <Grid.Box size={12} sizeMd={9} style={media.down('sm') && styles.infoMobile}>
            {/* language */}
            <LocaleSwitcher />
            {/* desktop/mobile toggle */}
            <ViewSwitcher />
            {domain.ownerOrganization.website !== null && (
              <View styleName="mb-3 flex-row ai-center ai-md-start">
                <Text style={styles.text} small>
                  <Icon glyph="info-circled-alt" />
                  &nbsp;
                  <FooterLink to={domain.ownerOrganization.website} external>
                    Website von {domain.ownerOrganization.name}
                  </FooterLink>
                </Text>
              </View>
            )}
          </Grid.Box>
          <Grid.Box size={12} sizeMd={3}>
            {/* links */}
            <View styleName="mb-4 ai-center ai-md-start jc-md-end mt-3 mt-md-0">
              <Text style={styles.text} small>
                <FooterLink to="/">
                  <Text>Home</Text>
                </FooterLink>
              </Text>
              <Text style={styles.text} small>
                <FooterLink to="/legal-contact">
                  <Text>Impressum &amp; Kontakt</Text>
                </FooterLink>
              </Text>
              <Text style={styles.text} small>
                <FooterLink to="/privacy-policy">
                  <Text>Datenschutzerklärung</Text>
                </FooterLink>
              </Text>
              <Text style={styles.text} small>
                <FooterLink to="/terms-of-use">
                  <Text>Nutzungsbedingungen</Text>
                </FooterLink>
              </Text>
            </View>
            {/* external links */}
          </Grid.Box>
        </Grid>
        <Text small style={[styles.copyright, styles.text]}>
          © Examunity UG {currentYear}
        </Text>
      </Container>
    </View>
  );
}

export default FooterOthers;
