// @flow
import { createContainer } from 'libs/container';
import { selectByRoot, select, gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import Announcements from './Announcements';

const ANNOUNCEMENT_FIELDS = gql`
  {
    text
    createdAt
    announceable {
      ... on Course {
        name
        slug
      }
      ... on Organization {
        name
        slug
      }
      ... on OrganizationTeam {
        name
        slug
        organization {
          slug
        }
      }
    }
    author {
      name
      avatar {
        file {
          name
          extension
          info {
            image {
              height
              width
            }
          }
        }
      }
      createdAt
    }
  }
`;

export const ANNOUNCEMENTS_FRAGMENT: DocumentNode = gql`
  fragment AnnouncementsFragment on Announceable {
        announcement(id: $announcementId) ${ANNOUNCEMENT_FIELDS}
        announcements ${ANNOUNCEMENT_FIELDS}
  }
`;

const Container = createContainer(Announcements, (props) => {
  const { announceableType, announceableId, searchParams } = props;

  const variables = {
    announcementId: searchParams.get('announcement_id'),
  };

  const selectors = {
    viewer: selectByRoot('viewer', (viewer) =>
      viewer.join('avatar', (avatar) =>
        avatar.join('file', (file) => file.join('info', (info) => info.join('image'))),
      ),
    ),
    announceable: select(ANNOUNCEMENTS_FRAGMENT, {
      entry: [announceableType, announceableId],
      variables,
    }),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
