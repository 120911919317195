// @flow
import React from 'react';
import { View, Text, Image, Button, Link } from 'components/core';
import { BrandHeading, BrandLargeText, BrandText } from 'components/pages';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { useToggleState } from 'libs/misc';
import FirstYearModal from './FirstYearModal';

const styles = StyleSheet.create({
  box: css`
    background-color: #fff;
  `,
  title: css`
    padding-bottom: 1rem;
  `,
  imageDesktop: css`
    width: 420px;
    height: 261px;
    border-radius: 0.25rem;
  `,
  logoKiehl: css`
    height: 30px;
    width: 55px;
  `,
  logoNWB: css`
    height: 30px;
    width: 55px;
    border-radius: 0.25rem;
  `,
});

function Content(): React$Node {
  const media = useMedia();
  const modal = useToggleState(false);

  return (
    <>
      <View styleName="flex-md-row mx-3 mb-5 my-md-5" style={[styles.box]}>
        {media.up('md') && (
          <View styleName="jc-center ai-center mr-md-5">
            <Image
              source="/img/organizations/stbk-nds/check-stbk.png"
              label="Lernplattform"
              style={styles.imageDesktop}
              resizeMode="contain"
            />
          </View>
        )}
        <View styleName="jc-start ai-start flex-grow-1 flex-shrink-1">
          <BrandHeading
            style={[styles.title]}
            align={media.up('md') ? 'center' : undefined}
            size={3}
          >
            <Text>Zwischen- und Abschlussprüfungen</Text>
          </BrandHeading>
          <BrandLargeText>
            Bereite dich mit anderen Auszubildenden auf die Zwischen- und Abschlussprüfung vor und
            tausche Mitschriften und Fragen aus. Erstelle und teile Karteikarten mit anderen
            Lernenden und teste dein Wissen mit über 750 Karteikarten und zahlreichen
            Musterklausuren - für Auszubildenden zur / zum Steuerfachangestellten in Niedersachsen
            kostenlos!
          </BrandLargeText>
        </View>
      </View>
      <View styleName="flex-md-row mx-3 mb-5 my-md-5" style={[styles.box]}>
        <View styleName="jc-start ai-start flex-grow-1 flex-shrink-1">
          <BrandHeading
            style={[styles.title]}
            align={media.up('md') ? 'center' : undefined}
            size={3}
          >
            <Text>Berufsschule</Text>
          </BrandHeading>
          <BrandLargeText>
            Finde dich mit deinen Klassenkameraden in Kursen für Berufsschule zusammen, um gemeinsam
            mit Tests und Karteikarten für die nächste Klassenarbeit zu lernen, sich auf einen
            Vortrag vorzubereiten, Dokumente auszutauschen und sich gegenseitig zu helfen.
          </BrandLargeText>
        </View>
        {media.up('md') && (
          <View styleName="jc-center ai-center ml-md-5">
            <Image
              source="/img/organizations/stbk-nds/connected-stbk.png"
              label="Lernplattform"
              style={styles.imageDesktop}
              resizeMode="contain"
            />
          </View>
        )}
      </View>
      <View styleName="flex-md-row mx-3 mb-5 my-md-5" style={[styles.box]}>
        {media.up('md') && (
          <View styleName="jc-center ai-center mr-md-5">
            <Image
              source="/img/organizations/stbk-nds/file-searching-stbk.png"
              label="Lernplattform"
              style={styles.imageDesktop}
              resizeMode="contain"
            />
          </View>
        )}
        <View styleName="jc-start ai-start flex-grow-1 flex-shrink-1">
          <BrandHeading
            style={[styles.title]}
            align={media.up('md') ? 'center' : undefined}
            size={3}
          >
            <Text>Ausbildungsbegleitendes Lernen</Text>
          </BrandHeading>
          <BrandLargeText>
            Unser{' '}
            <Link onPress={modal.toggle}>
              <Text bold>Kurs für das 1. Lehrjahr</Text>
            </Link>{' '}
            mit zahlreichen Erklärvideos, hunderten Karteikarten und vielen Tests - abgestimmt auf
            die neue Ausbildungsverordnung (AO)! So lernst du vom ersten Tag der Ausbildung digital,
            mobil und in der Azubinet-App. Außerdem mit zahlreichen Inhalten unserer
            Content-Partner, die dir prüfungs- und ausbildungsrelevantes Wissen vermitteln.
          </BrandLargeText>
          <BrandText styleName="mt-3">
            Diese Inhalte sind optional verfügbar und kostenpflichtig. Unser Kurs für das 1.
            Lehrjahr ist <Text bold>im August 2023 kostenlos</Text> verfügbar.
          </BrandText>
          <View styleName="my-3 flex-md-row flex-wrap ai-md-center">
            <BrandLargeText bold>Mit Lerninhalten von</BrandLargeText>
            <View styleName="flex-row flex-wrap ai-start">
              <Image
                source="/img/home/logo-kiehl.png"
                label="Kiehl"
                style={styles.logoKiehl}
                styleName="ml-md-3 m-2"
              />
              <Image
                source="/img/home/logo-nwb.png"
                label="NWB-Verlag"
                style={styles.logoNWB}
                styleName="m-2"
              />
            </View>
          </View>
        </View>
      </View>
      <View styleName="ai-md-center jc-md-center mx-3 mx-md-0 mt-3 mb-5 flex-md-row">
        <Button to="/login" size="lg" color="dark">
          <Text>Zur Anmeldung</Text>
        </Button>
        <Button
          to="https://www.stbk-niedersachsen.de/de/ihre_kammer/aktuelle_meldungen/pr%C3%BCfungsvorbereitung_mit_der_lernplattform_stbk_azubinet/"
          external
          size="lg"
          styleName="mt-3 mt-md-0 ml-md-3"
          color="secondary"
        >
          <Text>Zugang anfordern</Text>
        </Button>
      </View>
      <View styleName="flex-md-row mx-3 my-md-5" style={[styles.box]}>
        <View styleName="jc-md-start ai-md-start flex-grow-1 flex-shrink-1">
          <BrandHeading
            style={[styles.title]}
            align={media.up('md') ? 'center' : undefined}
            size={3}
          >
            <Text>Im Betrieb</Text>
          </BrandHeading>
          <BrandLargeText>
            Deine Ausbilder/-innen haben die Möglichkeit, dir das komplette Angebot auf
            StBK.Azubinet freizuschalten und individuell zu begleiten. So hast du alles auf einer
            Plattform: Kurse für die Prüfungen und zum Lernen für die Berufsschule, einen
            betriebsinternen Bereich und den landesweiten Austausch mit anderen Auszubildenen.
          </BrandLargeText>
          <Button to="/business" color="dark" styleName="mt-3" size="lg">
            <Text>Mehr erfahren</Text>
          </Button>
        </View>
        {media.up('md') && (
          <View styleName="jc-center ai-center ml-md-5">
            <Image
              source="/img/organizations/stbk-nds/online-learning-stbk.png"
              label="Lernplattform"
              style={styles.imageDesktop}
              resizeMode="contain"
            />
          </View>
        )}
      </View>

      <FirstYearModal modal={modal} />
    </>
  );
}

export default Content;
