// @flow
import React from 'react';
import { View, Button, Image, Grid } from 'components/core';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';
import { Section, BrandHeading, BrandText, HighlightStripe, Spacing } from 'components/pages';
import { useToggleState } from 'libs/misc';
import FreeCoursesInfoBox from 'views/_shared/FreeCoursesInfoBox';
import ProfessionsFreeCoursesModal from 'views/_shared/ProfessionsFreeCoursesModal';
import TeaserVideo from '../../../_shared/AzubinetTeaserVideo';

type Props = {
  viewer: ?Entity<GQLUser>,
  teaserVideo?: boolean,
};

const styles = StyleSheet.create({
  heading: css`
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  `,
  teaserText: css`
    font-size: 1.375rem;
  `,
  textAlign: css`
    text-align: center;
  `,
  stepDesktop: css`
    width: 5rem;
    height: 5rem;
    margin: 1rem 2rem 1rem 1rem;
  `,
  stepMobile: css`
    width: 3rem;
    height: 3rem;
    margin: 1rem 1rem 1rem 0rem;
  `,
  logo: css`
    border-radius: 0.25rem;
    margin: 0.5rem;
  `,
  visaLogo: css`
    height: 20px;
    width: 34px;
  `,
  mastercardLogo: css`
    height: 20px;
    width: 34px;
  `,
  sepaLogo: css`
    height: 20px;
    width: 50px;
  `,
  klarnaLogo: css`
    height: 20px;
    width: 47px;
  `,
});

const dict = createDict({
  bundles_on_azubinet: 'Bundles on Azubinet',
  bundles_text_one: 'On Azubinet you get specially tailored bundles for your profession.',
  bundles_text_two:
    'Either you want to learn for the first day from your apprenticeship onwards or you need a last-minute bundle a few weeks before your final exams - on Azubinet, we offer you different plans for your specific needs.',
  bold_text_guest:
    'Register now, join our free courses or buy your bundle and get started immediately!',
  bold_text_viewer: 'Join our free courses or buy your bundle and get started immediately!',
  bold_text_trainer: 'Choose the right bundles and assign them to your trainees immediately!',
  register_for_free: 'Register for free',
  bundles_text_one_business: 'On Azubinet you find specially tailored bundles for your trainees.',
  bundles_text_two_business:
    'Either you are looking for plans to support your trainees the first day onwoards or for bundles to prepare on the final exams - on Azubinet, we offer you different plans for your specific needs.',
  how_it_works: 'How it works',
  choose_the_right_bundle: 'Choose the right bundle',
  pay_with_your_preferred_method: 'Pay with your preferred method',
  get_started_immediately: 'Get started immediately!',
  step_one_business: 'You choose the products and plans you want to purchase for your trainees.',
  step_two_business:
    "You buy the amount of licenses. Right after your purchase you can assign these licenses to your trainees' accounts.",
  step_three_business:
    'We will send you an invoice of your purchase. You track the learning progress and accompany your trainees.',
  step_four_business:
    'Once the duration of the plan has ended, their access to the courses will be deactivated. You can renew their licenses if needed. No subscription, no auto-renewal!',
  you_are_a_trainer_text: 'You are a trainer? Discover Azubinet for training companies!',
  learn_more: 'Learn more',
  no_subscription_no_hidden_fees: 'No subscription, no hidden fees!',
  find_free_courses: 'Find free courses',
});

const defaultProps = {
  teaserVideo: false,
};

function ContentSection({ viewer, teaserVideo }: Props): React$Node {
  const media = useMedia();
  const modal = useToggleState(false);

  const viewerIsOrganizationAdminOrTrainer = viewer && viewer.isOrganizationAdminOrTrainer;

  return (
    <>
      {!viewerIsOrganizationAdminOrTrainer && (
        <Section color="white">
          <HighlightStripe styleName="p-md-4 ai-center jc-center">
            <BrandText style={styles.teaserText} bold>
              <Trans {...dict('you_are_a_trainer_text')} />
            </BrandText>
            <Button to="/business" size="lg" styleName="mt-3">
              <Trans {...dict('learn_more')} />
            </Button>
          </HighlightStripe>
        </Section>
      )}
      <Section color="white">
        <View styleName="ai-md-center jc-md-center mx-3 mx-md-0">
          <BrandHeading size={1} style={styles.heading}>
            <Trans {...dict('bundles_on_azubinet')} />
          </BrandHeading>
          {!viewerIsOrganizationAdminOrTrainer && (
            <View styleName="jc-md-center ai-md-center">
              <BrandText
                style={[styles.teaserText, media.up('md') && styles.textAlign]}
                styleName="pt-3"
              >
                <Trans {...dict('bundles_text_one')} />
              </BrandText>
              <BrandText
                style={[styles.teaserText, media.up('md') && styles.textAlign]}
                styleName="pt-3"
              >
                <Trans {...dict('bundles_text_two')} />
              </BrandText>

              <BrandText
                style={[styles.teaserText, media.up('md') && styles.textAlign]}
                bold
                styleName="pt-3"
              >
                {!viewer && <Trans {...dict('bold_text_guest')} />}
                {viewer && !viewerIsOrganizationAdminOrTrainer && (
                  <Trans {...dict('bold_text_viewer')} />
                )}
                {viewer && viewerIsOrganizationAdminOrTrainer && (
                  <Trans {...dict('bold_text_trainer')} />
                )}
              </BrandText>
              <BrandText
                style={[styles.teaserText, media.up('md') && styles.textAlign]}
                bold
                styleName="pt-3"
              >
                <Trans {...dict('no_subscription_no_hidden_fees')} />
              </BrandText>
            </View>
          )}
          {!viewer && !viewerIsOrganizationAdminOrTrainer && (
            <Button styleName="mt-4" color="success" size="lg" to="/register">
              <Trans {...dict('register_for_free')} />
            </Button>
          )}
          {viewer && viewerIsOrganizationAdminOrTrainer && (
            <View styleName="jc-md-center ai-md-center">
              <BrandText
                style={[styles.teaserText, media.up('md') && styles.textAlign]}
                styleName="pt-3"
              >
                <Trans {...dict('bundles_text_one_business')} />
              </BrandText>
              <BrandText
                style={[styles.teaserText, media.up('md') && styles.textAlign]}
                styleName="pt-3"
              >
                <Trans {...dict('bundles_text_two_business')} />
              </BrandText>
            </View>
          )}
        </View>
      </Section>
      <Section color="white">
        <>
          {!viewerIsOrganizationAdminOrTrainer && (
            <>
              <View styleName="jc-md-center ai-md-center mx-3 mx-md-0">
                <BrandHeading size={1} style={styles.heading} align="center">
                  <Trans {...dict('how_it_works')} />
                </BrandHeading>
                <Grid styleName="ai-start">
                  <Grid.Box
                    size={6}
                    sizeMd={3}
                    styleName="flex-column flex-grow-1 flex-shrink-1 ai-center jc-center p-3"
                  >
                    <Image
                      source="/img/pages/community-one.png"
                      style={media.up('md') ? styles.stepDesktop : styles.stepMobile}
                    />
                    <BrandText style={[styles.teaserText, styles.textAlign]}>
                      <Trans align="center" {...dict('register_for_free')} />
                    </BrandText>
                  </Grid.Box>
                  <Grid.Box
                    size={6}
                    sizeMd={3}
                    styleName="flex-column flex-grow-1 flex-shrink-1 ai-center jc-center p-3"
                  >
                    <Image
                      source="/img/pages/community-two.png"
                      style={media.up('md') ? styles.stepDesktop : styles.stepMobile}
                    />
                    <BrandText style={[styles.teaserText, media.up('md') && styles.textAlign]}>
                      <Trans {...dict('choose_the_right_bundle')} />
                    </BrandText>
                  </Grid.Box>
                  <Grid.Box
                    size={6}
                    sizeMd={3}
                    styleName="flex-column flex-grow-1 flex-shrink-1 ai-center jc-center p-3"
                  >
                    <Image
                      source="/img/pages/community-three.png"
                      style={media.up('md') ? styles.stepDesktop : styles.stepMobile}
                    />
                    <View styleName="flex-column flex-shrink-1">
                      <BrandText style={[styles.teaserText, media.up('md') && styles.textAlign]}>
                        <Trans {...dict('pay_with_your_preferred_method')} />
                      </BrandText>
                      {media.up('md') && (
                        <View styleName="flex-row flex-wrap flex-grow-1 flex-shrink-1 jc-center ai-center mt-2">
                          <Image
                            source="/img/pages/visaLogo.png"
                            style={[styles.logo, styles.visaLogo]}
                          />
                          <Image
                            source="/img/pages/mastercardLogo.png"
                            style={[styles.logo, styles.mastercardLogo]}
                          />
                          <Image
                            source="/img/pages/sepaLogo.png"
                            style={[styles.logo, styles.sepaLogo]}
                          />
                          <Image
                            source="/img/pages/klarna.png"
                            style={[styles.logo, styles.klarnaLogo]}
                          />
                        </View>
                      )}
                    </View>
                  </Grid.Box>
                  <Grid.Box
                    size={6}
                    sizeMd={3}
                    styleName="flex-column flex-grow-1 flex-shrink-1 ai-center jc-center p-3"
                  >
                    <Image
                      source="/img/pages/community-four.png"
                      style={media.up('md') ? styles.stepDesktop : styles.stepMobile}
                    />
                    <BrandText style={[styles.teaserText, styles.textAlign]}>
                      <Trans {...dict('get_started_immediately')} />
                    </BrandText>
                  </Grid.Box>
                </Grid>
              </View>
              {teaserVideo && (
                <View styleName="my-5 ai-md-center">
                  <TeaserVideo />
                </View>
              )}
              <Spacing />
              <FreeCoursesInfoBox>
                <Button onPress={modal.toggle} size="lg">
                  <Trans {...dict('find_free_courses')} />
                </Button>
              </FreeCoursesInfoBox>
              <Spacing />
            </>
          )}
          {viewer && viewerIsOrganizationAdminOrTrainer && (
            <Grid styleName="mx-3 mx-md-0">
              <Grid.Box
                size={12}
                sizeMd={3}
                styleName="flex-row flex-md-column flex-grow-1 flex-shrink-1 ai-center my-2"
              >
                <Image
                  source="/img/pages/community-one.png"
                  style={media.up('md') ? styles.stepDesktop : styles.stepMobile}
                />
                <BrandText style={[styles.teaserText, media.up('md') && styles.textAlign]}>
                  <Trans {...dict('step_one_business')} />
                </BrandText>
              </Grid.Box>
              <Grid.Box
                size={12}
                sizeMd={3}
                styleName="flex-row flex-md-column flex-grow-1 flex-shrink-1 ai-center my-2"
              >
                <Image
                  source="/img/pages/community-two.png"
                  style={media.up('md') ? styles.stepDesktop : styles.stepMobile}
                />
                <BrandText style={[styles.teaserText, media.up('md') && styles.textAlign]}>
                  <Trans {...dict('step_two_business')} />
                </BrandText>
              </Grid.Box>
              <Grid.Box
                size={12}
                sizeMd={3}
                styleName="flex-row flex-md-column flex-grow-1 flex-shrink-1 ai-center my-2"
              >
                <Image
                  source="/img/pages/community-three.png"
                  style={media.up('md') ? styles.stepDesktop : styles.stepMobile}
                />
                <BrandText style={[styles.teaserText, media.up('md') && styles.textAlign]}>
                  <Trans {...dict('step_three_business')} />
                </BrandText>
              </Grid.Box>
              <Grid.Box
                size={12}
                sizeMd={3}
                styleName="flex-row flex-md-column flex-grow-1 flex-shrink-1 ai-center my-2"
              >
                <Image
                  source="/img/pages/community-four.png"
                  style={media.up('md') ? styles.stepDesktop : styles.stepMobile}
                />
                <BrandText style={[styles.teaserText, media.up('md') && styles.textAlign]}>
                  <Trans {...dict('step_four_business')} />
                </BrandText>
              </Grid.Box>
            </Grid>
          )}
        </>
      </Section>
      <ProfessionsFreeCoursesModal modal={modal} />
    </>
  );
}

ContentSection.defaultProps = defaultProps;

export default ContentSection;
