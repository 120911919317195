// @flow
import React from 'react';
import { Avatar } from 'components/common';
import { Navbar, Image, Nav, Container, View, Text } from 'components/core';
import { StyleSheet, css, useMedia } from 'libs/ui';

type Props = {
  viewer?: ?Entity<GQLUser>,
};

const styles = StyleSheet.create({
  logo: css`
    width: 150px;
    height: 43px;
  `,
  collapseMobile: css`
    margin-top: 1rem;
    border-radius: 0.125rem;
    background-color: #f5f5f5;
    padding: 0.5rem 1rem;
    align-items: flex-start;
  `,
  navbarLink: css`
    color: #405767;
    &:focus-visible {
      color: #405767;
    }
    &:hover {
      text-decoration-color: #405767;
      text-decoration-line: underline;
      color: #405767;
    }
  `,
  highlightButtonDesktop: css`
    border-color: #405767;
    border-style: solid;
    border-radius: 0.1875rem;
    border-width: 1px;
  `,
});

const defaultProps = {
  viewer: null,
};

function NavigationVerkehrserziehung({ viewer }: Props): React$Node {
  const media = useMedia();

  return (
    <>
      <View styleName="bg-white">
        <Container styleName="bg-white">
          <Navbar expand="md" styleName="bg-white px-3 py-3 py-md-4 flex-grow-1">
            <Navbar.Brand to="/">
              <Image
                resizeMode="contain"
                source="/img/organizations/verkehrserziehung/verkehrserziehung.png"
                style={styles.logo}
                label="Fahrradführerschein"
              />
            </Navbar.Brand>
            <Navbar.Toggler />
            <Navbar.Collapse
              styleName="flex-grow-1 jc-start jc-md-end"
              style={media.down('sm') && styles.collapseMobile}
            >
              <Nav>
                <Nav.Link to="/teachers-parents" textStyle={styles.navbarLink}>
                  <Text>Für Lehrkräfte und Eltern</Text>
                </Nav.Link>
                {!viewer && (
                  <>
                    <Nav.Link
                      to="/login"
                      textStyle={styles.navbarLink}
                      style={media.up('md') && styles.highlightButtonDesktop}
                      styleName="ml-md-3"
                    >
                      <Text bold={media.up('md')}>Anmelden</Text>
                    </Nav.Link>
                    <Nav.Link to="/register" textStyle={styles.navbarLink} styleName="ml-md-3">
                      <Text>Registrieren</Text>
                    </Nav.Link>
                  </>
                )}
                {viewer && (
                  <Nav.Link
                    to="/dashboard"
                    textStyle={styles.navbarLink}
                    styleName="pr-md-0 mr-md-0 mt-3 mt-md-0 ml-md-3"
                  >
                    {media.down('sm') && (
                      <Avatar avatar={viewer.avatar} size="sm" styleName="mr-2" />
                    )}
                    <Text>Angemeldet als {viewer.firstName}</Text>
                    {media.up('md') && <Avatar avatar={viewer.avatar} styleName="ml-2" />}
                  </Nav.Link>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </View>
    </>
  );
}

NavigationVerkehrserziehung.defaultProps = defaultProps;

export default NavigationVerkehrserziehung;
