// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, query, select } from 'libs/graphql';

const NewVideo = {
  name: 'NewVideo',
  bundle: () => import('./NewVideo'),
};

const COURSE_VIDEO_QUERY = gql`
  query VideoList($courseSlug: String!) {
    course(slug: $courseSlug) {
      viewerCanCreateVideos
      categories {
        name
      }
      organization {
        id
      }
    }
  }
`;

const Container = createAsyncContainer(NewVideo, (props) => {
  const {
    params: { courseSlug },
  } = props;

  const variables = { courseSlug };

  const loaders = {
    query: query(COURSE_VIDEO_QUERY, { variables }),
  };

  const selectors = {
    course: select(COURSE_VIDEO_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
