// @flow

type TaskPartTypeConversion = {|
  'Cloze.*'?: any,
  'MultipleChoice.MULTIPLE'?: any,
  'MultipleChoice.SINGLE'?: any,
  'MultipleChoice.*'?: any,
  'OpenQuestion.*'?: any,
  'TrueFalse.*'?: any,
  'Pairing.ORDERING'?: any,
  'Pairing.MATCHING'?: any,
  'Pairing.ACCOUNTING'?: any,
  'Pairing.*'?: any,
  '*'?: any,
|};

export default function convertTaskPartType(
  taskPart: Entity<GQLTaskPart>,
  conversion: TaskPartTypeConversion,
): any {
  const item = taskPart.type;

  // sub task part type is defined
  if (item.type && conversion[`${item.__typename}.${item.type}`] !== undefined) {
    return conversion[`${item.__typename}.${item.type}`];
  }

  // task part type is defined
  if (conversion[`${item.__typename}.*`] !== undefined) {
    return conversion[`${item.__typename}.*`];
  }

  if (conversion['*'] !== undefined) {
    return conversion['*'];
  }

  throw new Error(`Unknown task part type. [${item.__typename}.${item.type ? item.type : '*'}]`);
}
