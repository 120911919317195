// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { Blockquote as BaseBlockquote } from 'bootstrap-rn';

type Props = {
  children: React$Node,
  style?: any,
};

const styles = StyleSheet.create({
  blockquote: css`
    border-left-width: 0.25rem;
    border-color: $gray-300;
    padding-horizontal: 0.75rem;
  `,
});

const Blockquote = React.forwardRef<Props, any>((props, ref) => {
  const { children, style, ...elementProps } = props;

  return (
    <BaseBlockquote {...elementProps} ref={ref} style={[styles.blockquote, style]}>
      {children}
    </BaseBlockquote>
  );
});

Blockquote.displayName = 'Blockquote';

export default (Blockquote: React$AbstractComponent<Props, any>);
