// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const InquiryList = {
  name: 'InquiryList',
  bundle: () => import('./InquiryList'),
};

const DOMAIN_INQUIRIES_QUERY = gql`
  query DomainInquiriesQuery($slug: String!) {
    organization(slug: $slug) {
      ownsDomain
      domain {
        permissions {
          usersCanCreateInquiries
        }
        inquiries {
          organization {
            name
          }
          user {
            name
            primaryEmail {
              email
            }
          }
          acceptedAt
        }
      }
      viewerIsAdmin
    }
  }
`;

const Container = createAsyncContainer(InquiryList, (props) => {
  const {
    params: { slug },
  } = props;

  const variables = { slug };

  const loaders = {
    query: query(DOMAIN_INQUIRIES_QUERY, { variables }),
  };

  const selectors = {
    organization: select(DOMAIN_INQUIRIES_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
