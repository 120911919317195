// @flow
// $FlowFixMe[missing-export]
import { useHref, useLinkClickHandler } from 'react-router-dom';

const defaultHrefAttrs = {
  download: false,
  target: '_blank',
  rel: 'noopener noreferrer',
};

export default function useLink(props: Object): Object {
  const { to, external, relative, replace, state, onPress: handlePress, ...restProps } = props;

  if (!to) {
    return props;
  }

  if (external) {
    return {
      role: 'link',
      href: to,
      hrefAttrs: { ...defaultHrefAttrs, ...external },
      onPress: handlePress,
      ...restProps,
    };
  }

  // $FlowFixMe[extra-arg]
  const href = useHref(to, { relative });
  const internalOnClick = useLinkClickHandler(to, {
    replace,
    state,
    // target,
    // preventScrollReset,
    relative,
  });

  return {
    role: 'link',
    href,
    onPress: (event) => {
      if (handlePress) handlePress(event);

      if (event.defaultPrevented) {
        return;
      }

      internalOnClick(event);
    },
    ...restProps,
  };
}
