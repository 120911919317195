// @flow
import React from 'react';
import { Text } from 'components/core';
import { StyleSheet, css } from 'libs/ui';
import { Number as FormattedNumber } from 'libs/intl';
import Fraction from 'libs/misc/Fraction';

type Props = {
  value: string,
};

const styles = StyleSheet.create({
  text: css`
    font-size: 0.625rem;
    padding-left: 0.125rem;
  `,
});

function FractionNumber({ value }: Props): React$Node {
  const { numerator, denominator } = new Fraction(value);
  const quotient = numerator / denominator;

  // Return number as float if there are two or less decimals.
  if (Number.isInteger(quotient * 100)) {
    return <FormattedNumber value={parseFloat(quotient.toFixed(2))} />;
  }

  const wholeNumber = Math.floor(quotient);
  const newNumerator = numerator - denominator * wholeNumber;

  return (
    <Text>
      {wholeNumber > 0 && <Text>{wholeNumber}</Text>}
      <Text style={styles.text}>
        {newNumerator}/{denominator}
      </Text>
    </Text>
  );
}

export default FractionNumber;
