// @flow
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import type { TaskExamControls } from '../useExamControls';

type Props = {
  controls: TaskExamControls,
};

function AutoSubmit({ controls }: Props): React$Node {
  const form = useFormikContext();

  useEffect(() => {
    controls.setInterceptor(() => {
      if (!form.dirty) {
        return null;
      }

      return form.submitForm().then(() => {
        // Reset form, so that form.dirty is false again.
        form.resetForm({ values: form.values });
      });
    });

    return () => {
      controls.unsetInterceptor();
    };
  }, [form.dirty]);

  return null;
}

export default AutoSubmit;
