// @flow
import React from 'react';
import { Text, Modal, ListGroup, CloseButton, View } from 'components/core';
import { Icon } from 'components/common';
import { StyleSheet, css } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';

type Props = {
  modal: ToggleState,
  navigation?: boolean,
};

const defaultProps = {
  navigation: false,
};

const dict = createDict({
  choose_profession: 'Choose profession',
});

const styles = StyleSheet.create({
  iconView: css`
    width: 2rem;
    align-items: center;
    justify-content: center;
  `,

  icon: css`
    font-size: 1.375rem;
    margin-right: 0.5rem;
  `,
  text: css`
    font-size: 1rem;
  `,
  row: css`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 1;
  `,
});

function ProfessionsModal({ modal, navigation }: Props): React$Node {
  return (
    <Modal visible={modal.on} onToggle={modal.toggle}>
      <Modal.Header styleName="flex-row">
        <Modal.Title>
          <Trans {...dict('choose_profession')} />
        </Modal.Title>
        <CloseButton onPress={modal.toggle} />
      </Modal.Header>
      <Modal.Body styleName="p-0">
        <ListGroup flush>
          <ListGroup.ItemAction
            to="/professions/automobilkauffrau-automobilkaufmann"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="cab" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Automobilkaufleute
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions/drogistin-drogist"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="shopping-basket" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Drogist/-innen
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions/fachinformatikerin-fachinformatiker"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="code" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Fachinformatiker/-innen
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions/fachkraft-fuer-lagerlogistik"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="cubes" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Fachkräfte für Lagerlogistik
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions/fachlageristin-fachlagerist"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="cubes" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Fachlagerist/-innen
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions/floristin-florist"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="garden" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Florist/-innen
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions/hotelfachfrau-hotelfachmann"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="bed" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Hotelfachleute
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions/industriekauffrau-industriekaufmann"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="industry" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Industriekaufleute
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions/kauffrau-kaufmann-fuer-bueromanagement"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="briefcase" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Kaufleute für Büromanagement
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions/kauffrau-kaufmann-fuer-gross-und-aussenhandelsmanagement"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="ship" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Kaufleute für Groß- und Außenhandelsmanagement
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions/kauffrau-kaufmann-fuer-spedition-und-logistikdienstleistung"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="truck" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Kaufleute für Spedition und Logistikdienstleistung
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions/kauffrau-kaufmann-im-e-commerce"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="at" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Kaufleute im E-Commerce
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions/kauffrau-kaufmann-im-einzelhandel"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="cart-plus" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Kaufleute im Einzelhandel
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions/kauffrau-kaufmann-im-gesundheitswesen"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="hospital" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Kaufleute im Gesundheitswesen
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions/kauffrau-kaufmann-im-gross-und-aussenhandel"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="ship" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Kaufleute im Groß- und Außenhandel
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions/koechin-koch"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="food" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Köche
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions/medizinische-fachangestellte-medizinischer-fachangestellter"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="hospital" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Medizinische Fachangestellte
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions/personaldienstleistungskauffrau-personaldienstleistungskaufmann"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="users" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Personaldienstleistungskaufleute
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions/restaurantfachfrau-restaurantfachmann"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="coffee" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Restaurantfachleute
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions/steuerfachangestellte-steuerfachangestellter"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="book-open" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Steuerfachangestellte
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions/verkaeuferin-verkaeufer"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
          >
            <View style={styles.iconView}>
              <Icon glyph="cart-plus" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Verkäufer/-innen
            </Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction
            to="/professions"
            style={styles.row}
            onPress={() => {
              if (navigation) {
                modal.setOff();
              }
            }}
            end
          >
            <View style={styles.iconView}>
              <Icon glyph="search" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Alle Berufe
            </Text>
          </ListGroup.ItemAction>
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
}

ProfessionsModal.defaultProps = defaultProps;

export default ProfessionsModal;
