// @flow
import { createAsyncContainer } from 'libs/container';

const Partners = {
  name: 'Partners',
  bundle: () => import('./Partners'),
};

const Container = createAsyncContainer(Partners, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
