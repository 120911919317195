// @flow
import { createAsyncContainer } from 'libs/container';

const FAQ = {
  name: 'FAQ',
  bundle: () => import('./FAQ'),
};

const Container = createAsyncContainer(FAQ, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
