// @flow
import { useState } from 'react';

export default function useToggleState(initialOn: boolean): ToggleState {
  const [on, setOn] = useState(initialOn);

  return {
    on,
    setOn: () => {
      setOn(true);
    },
    setOff: () => {
      setOn(false);
    },
    toggle: () => {
      setOn((prevOn) => !prevOn);
    },
    set: (nextOn: boolean) => {
      setOn(nextOn);
    },
    reset: () => {
      setOn(initialOn);
    },
  };
}
