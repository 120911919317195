// @flow
import React, { useMemo } from 'react';
import { Video, YoutubeVideo } from 'components/common';
import { Text } from 'components/core';
import { StyleSheet, css } from 'libs/ui';
import { getYoutubeId } from 'utils/videos';
import FlexImage from './FlexImage';

type Props = {
  url: string,
  // eslint-disable-next-line react/require-default-props
  title?: string,
  // eslint-disable-next-line react/require-default-props
  alt?: string,
};

const styles = StyleSheet.create({
  video: css`
    max-width: 100%;

    @include media-breakpoint-up(md) {
      max-width: 560px;
    }
  `,
});

const videoRE = /^.*\.(mp4|webm)/;
const audioRE = /^.*\.(ogg|mp3)/;

const useYoutubeVideoProps = (url) => {
  return useMemo(() => {
    const videoId = getYoutubeId(url);

    if (!videoId) {
      return null;
    }

    const searchParams = new URLSearchParams(url);
    const start = parseInt(searchParams.get('start'), 10);
    const end = parseInt(searchParams.get('end'), 10);

    return {
      videoId,
      start,
      end,
    };
  }, [url]);
};

function Image({ url, title, alt }: Props): React$Node {
  // 1) Media object is Youtube video.
  const youtubeVideoProps = useYoutubeVideoProps(url);
  if (youtubeVideoProps) {
    return <YoutubeVideo {...youtubeVideoProps} maxWidth={560} />;
  }

  // 2) Media object is external url -> do not allow!
  if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
    return alt ? <Text color="muted">{alt}</Text> : null;
  }

  // 3) Media object is video.
  const video = url.match(videoRE);
  if (video) {
    return <Video source={{ uri: url, type: `video/${video[1]}` }} style={styles.video} controls />;
  }

  // 3) Media object is audio.
  const audio = url.match(audioRE);
  if (audio) {
    return null; // TODO
  }

  // 4) Media object is image.
  return <FlexImage url={url} title={title} alt={alt} />;
}

export default Image;
