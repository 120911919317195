// @flow
import React from 'react';
import { Text, View, Button, Image, Jumbotron } from 'components/core';
import { BrandHeading, BrandLargeText } from 'components/pages';
import { StyleSheet, css, useMedia } from 'libs/ui';

const styles = StyleSheet.create({
  jumbotron: css`
    background-color: #f3f5f6;
    align-self: center;
    align-items: center;
  `,
  icon: css`
    color: #fff;
  `,
  imageDesktop: css`
    width: 260px;
    height: 185px;
    margin-left: 2rem;
    margin-right: 4rem;
  `,
  imageMobile: css`
    width: 141px;
    height: 100px;
    margin-bottom: 2rem;
  `,
  heading: css`
    margin-bottom: 2rem;
  `,
  content: css`
    text-align: left;
    margin-bottom: 2rem;
  `,
  highlightText: css`
    font-weight: bold;
    text-align: left;
    margin-bottom: 1rem;
  `,
});

function CompaniesInfo(): React$Node {
  const media = useMedia();

  return (
    <Jumbotron style={styles.jumbotron} styleName="flex-md-row">
      <Image
        source="/img/organizations/stbk-nds/learning-stbk.png"
        style={media.up('md') ? styles.imageDesktop : styles.imageMobile}
      />
      <View styleName="flex-column flex-shrink-1">
        <BrandHeading style={styles.heading} size={2}>
          <Text>Für ausbildende Kanzleien</Text>
        </BrandHeading>
        <BrandLargeText style={styles.content}>
          Begleiten Sie Ihre Auszubildenden individuell und auf der Höhe der Zeit. Verfolgen Sie den
          Lernfortschritt, um auf Probleme einzugehen, fügen Sie eigene Lerninhalte hinzu und holen
          Sie Ihre Azubis dort ab, wo sie sich aufhalten: digital und mobil. Beziehen Sie zusätzlich
          Lernmaterialien von unseren Content-Partnern, um Ihre Azubis bereits vom ersten Tag an
          bestmöglich zu unterstützen. Für Mitglieder der Steuerberaterkammer Niedersachsen zu
          vergünstigten Konditionen.
        </BrandLargeText>
        <BrandLargeText style={styles.highlightText}>
          Testen Sie Azubinet für Ausbilder/-innen jetzt 14 Tage kostenlos!
        </BrandLargeText>
        <View styleName="flex-md-row ai-md-start">
          <Button to="/business" size="lg" color="dark" styleName="mr-md-3 mb-3 mb-md-0">
            <Text>Mehr erfahren</Text>
          </Button>
          <Button to="/request-access" size="lg" color="secondary">
            <Text>Testzugang für Betriebe</Text>
          </Button>
        </View>
      </View>
    </Jumbotron>
  );
}

export default CompaniesInfo;
