// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';

const NewQuiz = {
  name: 'NewQuiz',
  bundle: () => import('./NewQuiz'),
};

const COURSE_QUERY = gql`
  query CourseQuery($courseSlug: String!) {
    course(slug: $courseSlug) {
      viewerCanCreateQuizzes
      categories {
        name
      }
    }
  }
`;

const Container = createAsyncContainer(NewQuiz, (props) => {
  const {
    params: { courseSlug },
  } = props;

  const variables = { courseSlug };

  const loaders = {
    query: query(COURSE_QUERY, {
      variables,
    }),
  };

  const selectors = {
    course: select(COURSE_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
