// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql, selectByRoot } from 'libs/graphql';
import { getVersion } from 'utils/courses';
import { ACTIVITY_EDITOR_SIDEBAR_FRAGMENT } from '../_shared/ActivityEditorSidebar/fragments';
import {
  CLOZE_FRAGMENT,
  MULTIPLE_CHOICE_FRAGMENT,
  OPEN_QUESTION_FRAGMENT,
  TRUE_FALSE_FRAGMENT,
  PAIRING_FRAGMENT,
} from './fragments';
import { ACTIVITY_EDITOR_TITLE_FRAGMENT } from '../_shared/ActivityEditorTitle/fragments';
import { ATTACHMENTS_FRAGMENT } from '../_shared/MediaGalleryModal';

const TaskCollection = {
  name: 'TaskCollection',
  bundle: () => import('./TaskCollection'),
};

const COURSE_QUERY = gql`
  query CourseQuery(
    $slug: String!
    $courseSlug: String!
    $versionNumber: Int
    $specificVersion: String
  ) {
    course(slug: $courseSlug) {
      viewerIsAdmin
      categories {
        name
      }
      examActivity(slug: $slug) {
        ...ActivityEditorTitleFragment
        ...ActivityEditorSidebarFragment
        slug
        version(versionNumber: $versionNumber, specificVersion: $specificVersion) {
          versionNumber
          isDeprecated
          releasedAt
          item {
            info
            downloadable
            numberingFormat
            partNumberingFormat
            exam {
              attemptsCount
              duration
              selfEvaluable
              allowsPartialPoints
              usesGrades
              grades {
                name
                minScore
              }
              passingScore
            }
            tasksCount
            tasks {
              title
              position
              intro {
                text
              }
              parts {
                position
                title
                points
                type {
                  ...ClozeFragmentEditor
                  ...MultipleChoiceFragmentEditor
                  ...OpenQuestionFragmentEditor
                  ...TrueFalseFragmentEditor
                  ...PairingFragmentEditor
                }
              }
            }
            ...AttachmentsFragment
          }
        }

        author {
          id
        }

        viewerCanUpdate
      }
    }
  }

  ${CLOZE_FRAGMENT}
  ${MULTIPLE_CHOICE_FRAGMENT}
  ${OPEN_QUESTION_FRAGMENT}
  ${TRUE_FALSE_FRAGMENT}
  ${PAIRING_FRAGMENT}
  ${ATTACHMENTS_FRAGMENT}
  ${ACTIVITY_EDITOR_TITLE_FRAGMENT}
  ${ACTIVITY_EDITOR_SIDEBAR_FRAGMENT}
`;

const Container = createAsyncContainer(TaskCollection, (props) => {
  const {
    params: { slug, courseSlug },
    searchParams,
  } = props;

  const versionNumber = getVersion(searchParams.get('version'));
  const variables = {
    slug,
    courseSlug,
    versionNumber,
    specificVersion: versionNumber ? null : 'LATEST',
  };

  const loaders = {
    query: query(COURSE_QUERY, { variables }),
  };

  const selectors = {
    course: select(COURSE_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
