// @flow
import React from 'react';
import { ListGroup } from 'components/core';
import BundleCompact from './BundleCompact';

type Props = {
  onClose?: () => void,
};

function VerkaeuferBundles({ onClose }: Props): React$Node {
  return (
    <ListGroup>
      <BundleCompact
        to="/courses/L8y5cyW1PJ"
        title="Erstmal reinschnuppern"
        price="kostenlose Lernmaterialien und Community"
        onClose={onClose}
      />
      <BundleCompact
        to="/professions/verkaeuferin-verkaeufer"
        title="Lernpaket Verkäufer/-in"
        price="ab 24,95 EUR"
        paid
      />
    </ListGroup>
  );
}

export default VerkaeuferBundles;
