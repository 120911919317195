// @flow
import React from 'react';
import { View } from 'components/core';
import { StyleSheet, css, useMedia } from 'libs/ui';
import PieceMatching from './PieceMatching';
import SolutionDropdown from './SolutionDropdown';
import type { TaskExamControls } from '../../useExamControls';

type Props = {
  pairing: Entity<GQLPairing>,
  examAttemptId: Id,
  controls: TaskExamControls,
  viewerExamSolution: ?Entity<GQLPairingSolution>,
};

const styles = StyleSheet.create({
  dropdownAreaDesktop: css`
    min-width: 10rem;
    margin-left: 1rem;
  `,
  dropdownAreaMobile: css`
    justify-content: flex-end;
    align-items: flex-end;
  `,
  dividerMobile: css`
    border-bottom-width: 0.0625rem;
    border-bottom-color: $gray-200;
    margin-bottom: 0.5rem;
  `,
});

function SolutionFormMatching({
  pairing,
  examAttemptId,
  controls,
  viewerExamSolution,
}: Props): React$Node {
  const media = useMedia();
  return (
    <>
      {pairing.pieces
        .filter((piece) => piece.side === 'RIGHT')
        .map((piece, key) => (
          <View key={piece.id} styleName="flex-row ai-center flex-grow-1 flex-shrink-1 jc-between">
            <View styleName="flex-grow-1 flex-shrink-1 mr-2">
              <PieceMatching piece={piece} last={pairing.pieces.length - 1 === key} />
            </View>
            <View style={media.up('md') ? styles.dropdownAreaDesktop : styles.dropdownAreaMobile}>
              <SolutionDropdown
                pairing={pairing}
                piece={piece}
                examAttemptId={examAttemptId}
                controls={controls}
                viewerExamSolution={viewerExamSolution}
              />
            </View>
            {media.down('sm') && <View style={styles.dividerMobile} />}
          </View>
        ))}
    </>
  );
}

export default SolutionFormMatching;
