// @flow
import React from 'react';
import { Col } from 'bootstrap-rn';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  children: React$Node,
  style?: any,
};

const styles = StyleSheet.create({
  gridBox: css`
    @include media-breakpoint-down(xs) {
      padding: 0;
    }
  `,
});

const GridBox = React.forwardRef<Props, any>((props, ref) => {
  const { children, style, ...elementProps } = props;

  return (
    <Col {...elementProps} ref={ref} style={[styles.gridBox, style]}>
      {children}
    </Col>
  );
});

GridBox.displayName = 'GridBox';

export default (GridBox: React$AbstractComponent<Props, any>);
