// @flow
import React from 'react';
import { View, Button, Text, Badge, Heading } from 'components/core';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';

type Props = {
  to: string,
  title: string,
  price: string,
  onClose?: () => void,
  paid?: boolean,
  bestseller?: boolean,
  newYears?: boolean,
};

const styles = StyleSheet.create({
  boxDesktop: css`
    background-color: #ebfafa;
    border-radius: 0.1875rem;
    padding: 1rem;
    margin-bottom: 1rem;
  `,
  boxMobile: css`
    background-color: #ebfafa;
    padding: 1rem;
    flex-grow: 1;
    flex-shrink: 1;
    margin-bottom: 1rem;
  `,
  heading: css`
    text-align: left;
    margin-bottom: 0.5rem;
  `,
  badge: css`
    flex-direction: row;
    background-color: #ff6186;
    margin-bottom: 0.25rem;
  `,
});

const dict = createDict({
  view_plans: 'View plans',
  join_for_free: 'Join for free',
  new_years_special: 'NEW YEARS SPECIAL',
});

const defaultProps = {
  paid: false,
  bestseller: false,
  newYears: false,
};

function BundleCompact(props: Props): React$Node {
  const { to, title, price, onClose: handleClose, paid, bestseller, newYears } = props;
  const media = useMedia();

  return (
    <View style={[media.up('md') ? styles.boxDesktop : styles.boxMobile]}>
      <View styleName="flex-column">
        <View styleName="flex-column flex-grow-1 flex-shrink-1 jc-md-start">
          <Heading size={5} numberOfLines={2}>
            {title}
          </Heading>
        </View>
        <View styleName="flex-grow-1 flex-shrink-1 jc-start ai-start mt-1">
          <Text bold>{price}</Text>
        </View>
        <View styleName="flex-md-row flex-grow-1 flex-shrink-1 jc-between ai-md-end mt-2">
          <View styleName="flex-md-row flex-md-wrap ai-md-end">
            {newYears && (
              <View styleName="flex-grow-1 flex-shrink-1 jc-start ai-start mr-2">
                <Badge style={[styles.badge]}>
                  <Trans {...dict('new_years_special')} />
                </Badge>
              </View>
            )}
            {bestseller && (
              <View styleName="flex-grow-1 flex-shrink-1 jc-start ai-start">
                <Badge style={[styles.badge]}>
                  <Text>BESTSELLER</Text>
                </Badge>
              </View>
            )}
          </View>
          <View styleName="jc-start jc-md-end ai-start ai-md-end mt-2 mt-md-0">
            <Button
              to={to}
              onPress={() => {
                if (!handleClose) {
                  return;
                }

                handleClose();
              }}
              color={paid ? 'primary' : 'success'}
            >
              <Text>
                {paid && <Trans {...dict('view_plans')} />}
                {!paid && <Trans {...dict('join_for_free')} />}
              </Text>
            </Button>
          </View>
        </View>
      </View>
    </View>
  );
}

BundleCompact.defaultProps = defaultProps;

export default BundleCompact;
