// @flow
import React from 'react';
import { View, Image, Jumbotron, Button } from 'components/core';
import { BrandHeading } from 'components/pages';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { createDict, Trans, useIntl } from 'libs/intl';

const styles = StyleSheet.create({
  jumbotron: css`
    background-color: #ebf4f9;
    align-items: center;
    padding-bottom: 12rem;
    margin-bottom: -6rem;
  `,
  jumbotronDesktop: css`
    margin-top: -2rem;
  `,
  jumbotronMobile: css`
    margin-top: -4rem;
  `,
  content: css`
    flex-shrink: 1;
  `,
  contentDesktop: css`
    margin: 2rem;
  `,
  contentMobile: css`
    margin-horizontal: -1rem;
  `,
  heading: css`
    margin-bottom: 2rem;
  `,
  imageDesktop: css`
    width: 500px;
    height: 284px;
    margin-vertical: 4rem;
    margin-right: 2rem;
    margin-left: 4rem;
  `,
  imageMobile: css`
    width: 464px;
    height: 264px;
    margin-vertical: 2rem;
  `,
  badgeDesktop: css`
    width: 10rem;
    height: 10rem;
  `,
  badgeMobile: css`
    width: 10rem;
    height: 10rem;
  `,
  badgeButtonDesktop: css`
    width: 10rem;
    height: 10rem;
    margin-top: -9rem;
    padding: -9rem 0rem 0rem 0rem;
    border-radius: 6.5rem;
    z-index: 1;
  `,
  badgeButtonMobile: css`
    width: 10rem;
    height: 10rem;
    padding: -2rem 0rem -4rem 0rem;
    border-radius: 6.5rem;
    z-index: 1;
  `,
});

const dict = createDict({
  text: 'Master your apprenticeship',
  text_one_desktop: 'Master your',
  text_two_desktop: 'Apprenticeship',
  caption:
    'Get prepared for your tests in school and for your final exams - with thousands of tutorial videos, mock exams and flashcards. Give it a try and register for free now!',
  register_for_free: 'Register for free',
  view_all_courses: 'View all courses',
  your_profession: 'Your profession',
});

function HeroSection(): React$Node {
  const media = useMedia();
  const intl = useIntl();

  return (
    <>
      <Jumbotron
        style={[
          styles.jumbotron,
          media.up('md') ? styles.jumbotronDesktop : styles.jumbotronMobile,
        ]}
        // For xs, sm and md flex-column is better, for lg+ flex-row
        styleName="flex-lg-row"
      >
        <View
          style={[styles.content, media.up('lg') ? styles.contentDesktop : styles.contentMobile]}
        >
          {media.down('sm') && (
            <BrandHeading size={1} style={styles.heading}>
              <Trans {...dict('text')} />
            </BrandHeading>
          )}
          {media.up('md') && (
            <>
              <BrandHeading size={1}>
                <Trans {...dict('text_one_desktop')} />
              </BrandHeading>
              <BrandHeading size={1} style={styles.heading}>
                <Trans {...dict('text_two_desktop')} />
              </BrandHeading>
            </>
          )}
          <BrandHeading size={5} style={styles.heading}>
            <Trans {...dict('caption')} />
          </BrandHeading>
          <View styleName="ai-start">
            <Button color="success" to="/register" size="lg" styleName="mt-3 mt-md-0 mb-5 mb-md-0">
              <Trans {...dict('register_for_free')} />
            </Button>
          </View>
        </View>
        <View styleName="flex-column ai-md-end jc-md-end">
          {media.up('sm') && (
            <Image
              source="/img/home/apprentices.png"
              style={media.up('md') ? styles.imageDesktop : styles.imageMobile}
            />
          )}
          <View style={media.up('md') ? styles.badgeButtonDesktop : styles.badgeButtonMobile}>
            <Image
              source={
                intl.locale === 'de'
                  ? '/img/home/users_badge-ger.png'
                  : '/img/home/users_badge-eng.png'
              }
              style={media.up('md') ? styles.badgeDesktop : styles.badgeMobile}
            />
          </View>
        </View>
      </Jumbotron>
    </>
  );
}

export default HeroSection;
