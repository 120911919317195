// @flow
import React, { useMemo } from 'react';
import { unified } from 'unified';
import markdown from 'remark-parse';
import { StyleSheet, css } from 'libs/ui';
import emphasis from './plugins/emphasis';
import htmlBlock from './plugins/html-block';
import htmlInline from './plugins/html-inline';
import mathBlock from './plugins/math-block';
import mathInline from './plugins/math-inline';
import reference from './plugins/reference';
import table from './plugins/table';
import renderToReact from './renderToReact';
import Context from './Context';

type Props = {
  source: string,
  variant?: ?'article',
  renderText?: any,
  allowLinkedImages?: boolean,
  onTableScroll?: any,
  style?: ?Object,
  styleName?: ?string,
  textStyle?: any,
};

const styles = StyleSheet.create({
  articleText: css`
    line-height: 1.625rem;
  `,
});

function Markdown({
  source,
  variant,
  renderText,
  allowLinkedImages = true,
  onTableScroll,
  style,
  styleName,
  textStyle,
}: Props): React$Node {
  const element = useMemo(() => {
    const ast = unified()
      .use(markdown)
      .use(emphasis)
      .use(htmlBlock)
      .use(htmlInline)
      .use(mathBlock)
      .use(mathInline)
      .use(reference)
      .use(table)
      .parse(source);

    return renderToReact(ast);
  }, [source]);

  return (
    <Context.Provider
      value={{
        renderText,
        allowLinkedImages,
        handleTableScroll: onTableScroll,
        style,
        styleName,
        textStyle: [variant === 'article' && styles.articleText, textStyle],
      }}
    >
      {element}
    </Context.Provider>
  );
}

export default Markdown;
