// @flow
import { useRef, useEffect } from 'react';
import useScroll from './useScroll';

const SCROLL_OFFSET = 144;

type RefObject = {|
  current: any,
|};

export default function useAnchor(active: boolean): RefObject {
  const scroll = useScroll();
  const ref = useRef(null);

  useEffect(() => {
    if (!active) {
      return;
    }

    // Scroll to anchor async, because otherwise the pos and scrollOffset is
    // calculated wrongly on native.
    const timeout = setTimeout(() => {
      // $FlowFixMe[incompatible-use]
      ref.current.measure((...args) => {
        const [, , , , , pos] = args;

        const scrollOffset = scroll.getContentOffset();

        scroll.scrollTo(pos + scrollOffset - SCROLL_OFFSET);
      });
    }, 10);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return ref;
}
