// @flow
import React from 'react';
import { Text } from 'components/core';
import { useIntl } from 'intlized-components';

type Props = {
  value: number,
  options?: Object,
};

function Number({ value, options, ...textProps }: Props): React$Node {
  const intl = useIntl();

  return <Text {...textProps}>{value === 0 ? 0 : intl.number(value, options)}</Text>;
}

export default Number;
