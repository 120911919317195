// @flow
import React from 'react';
import { Markdown } from 'components/markdown';
import { TaskChoice } from 'components/tasks';
import { useDispatch } from 'libs/graphql';
import { useToggleState } from 'libs/misc';
import { formatNumber, getStatementValue } from 'utils/tasks';
import { updateOrCreateSolution } from './mutations';
import ChoiceCheckbox from './ChoiceCheckbox';
import type { TaskExamControls } from '../useExamControls';

type Props = {
  trueFalseId: Id,
  examAttemptId: Id,
  statement: Entity<GQLTrueFalseStatement>,
  last: boolean,
  viewerExamSolution: ?Entity<GQLTrueFalseSolution>,
  controls: TaskExamControls,
};

function Statement({
  trueFalseId,
  examAttemptId,
  statement,
  last,
  viewerExamSolution,
  controls,
}: Props): React$Node {
  const dispatch = useDispatch();
  const loading = useToggleState(false);

  const handleChange = (value) => {
    // block action if loading
    if (loading.on) {
      return;
    }

    loading.setOn();
    controls.block();

    dispatch(updateOrCreateSolution(trueFalseId, examAttemptId, statement.position, value)).finally(
      () => {
        loading.setOff();
        controls.unblock();
      },
    );
  };

  const value =
    viewerExamSolution && getStatementValue(statement, viewerExamSolution.statementValues);

  return (
    <TaskChoice last={last}>
      <TaskChoice.Label>
        <Markdown
          variant="article"
          source={`${formatNumber(statement.position, 'ALPHABETIC')}) ${statement.text}`}
        />
      </TaskChoice.Label>
      <TaskChoice.Solution>
        <TaskChoice.ButtonGroup>
          <ChoiceCheckbox
            variant="true"
            checked={value === true}
            onPress={() => {
              handleChange(value === true ? null : true);
            }}
            disabled={loading.on}
          />
          <ChoiceCheckbox
            variant="false"
            checked={value === false}
            onPress={() => {
              handleChange(value === false ? null : false);
            }}
            disabled={loading.on}
          />
        </TaskChoice.ButtonGroup>
      </TaskChoice.Solution>
    </TaskChoice>
  );
}

export default Statement;
