// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const CheckoutStripeWrapper = {
  name: 'CheckoutStripeWrapper',
  bundle: () => import('./CheckoutStripeWrapper'),
};

const PRODUCT_OFFER_PURCHASE_QUERY = gql`
  query ProductOfferPurchaseQuery($purchaseId: ClientID!) {
    productOfferPurchase(id: $purchaseId) {
      offer {
        product {
          name
          courses {
            slug
          }
        }
        duration
      }
      stripeClientSecret
      stripeStatus
      quantity
      price {
        amount
        currency {
          sign
          code
        }
      }
      voucher {
        code
        discount {
          amount
          currency {
            sign
            code
          }
        }
      }
      updatedAt
    }
  }
`;

const Container = createAsyncContainer(CheckoutStripeWrapper, (props) => {
  const {
    params: { purchaseId },
  } = props;

  const variables = { purchaseId };

  const loaders = {
    query: query(PRODUCT_OFFER_PURCHASE_QUERY, { variables }),
  };

  const selectors = {
    productOfferPurchase: select(PRODUCT_OFFER_PURCHASE_QUERY, { variables }),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
