// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import { ACTIVITY_REFERENCES_FRAGMENT } from '../ActivityReferences/fragments';
import { ACTIVITY_INFO_MODAL_FRAGMENT } from '../ActivityInfoModal/fragments';

export const ACTIVITY_DETAILS_SIDEBAR_FRAGMENT: DocumentNode = gql`
  fragment ActivityDetailsSidebarFragment on CourseActivity {
    viewerResult {
      versionNumber
    }
    version(versionNumber: $versionNumber, specificVersion: $specificVersion) {
      versionNumber
    }
    viewerCanAccess
    viewerCanUpdate
    ...ActivityReferencesFragment
    ...ActivityInfoModalFragment
  }

  ${ACTIVITY_REFERENCES_FRAGMENT}
  ${ACTIVITY_INFO_MODAL_FRAGMENT}
`;

export const ACTIVITY_DETAILS_SIDEBAR_EXAM_ATTEMPT_FRAGMENT: DocumentNode = gql`
  fragment ActivityDetailsSidebarExamAttemptFragment on ExamActivity {
    viewerResult {
      versionNumber
    }
    versionForAttempt(attemptId: $attemptId) {
      versionNumber
    }
    viewerCanUpdate
    ...ActivityReferencesFragment
    ...ActivityInfoModalFragment
  }

  ${ACTIVITY_REFERENCES_FRAGMENT}
  ${ACTIVITY_INFO_MODAL_FRAGMENT}
`;

export const ACTIVITY_DETAILS_SIDEBAR_QUIZ_RESULT_FRAGMENT: DocumentNode = gql`
  fragment ActivityDetailsSidebarQuizResultFragment on QuizActivity {
    viewerResult {
      versionNumber
    }
    versionForResult(resultId: $resultId) {
      versionNumber
    }
    viewerCanAccess
    viewerCanUpdate
    ...ActivityReferencesFragment
    ...ActivityInfoModalFragment
  }

  ${ACTIVITY_REFERENCES_FRAGMENT}
  ${ACTIVITY_INFO_MODAL_FRAGMENT}
`;
