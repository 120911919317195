// @flow
import React from 'react';
import { View, Image, Button } from 'components/core';
import { BrandHeading, BrandLargeText, HighlightStripe } from 'components/pages';
import { createDict, useIntl, Trans } from 'libs/intl';
import { StyleSheet, css, useMedia } from 'libs/ui';

const styles = StyleSheet.create({
  icon: css`
    color: #fff;
  `,
  imageDesktop: css`
    width: 260px;
    height: 185px;
    margin-left: 2rem;
    margin-right: 4rem;
  `,
  imageMobile: css`
    width: 141px;
    height: 100px;
    margin-bottom: 2rem;
  `,
  heading: css`
    margin-bottom: 2rem;
  `,
  content: css`
    text-align: left;
    margin-bottom: 2rem;
  `,
});

const dict = createDict({
  stuck_with_learning_problems: 'Stuck with learning problems?',
  register_for_free: 'Register for free',
});

function ApprenticesTutoringInfo(): React$Node {
  const media = useMedia();
  const intl = useIntl();

  return (
    <HighlightStripe styleName="flex-md-row py-md-4 pr-md-4 ai-center jc-center">
      <Image
        source="/img/pages/smile.png"
        style={media.up('md') ? styles.imageDesktop : styles.imageMobile}
      />
      <View styleName="flex-column flex-shrink-1">
        <BrandHeading style={styles.heading} size={2}>
          <Trans {...dict('stuck_with_learning_problems')} />
        </BrandHeading>
        <BrandLargeText style={styles.content}>
          {intl.locale === 'de'
            ? 'Du kommst beim Lernen einfach nicht weiter? In den öffentlichen Azubinet-Kursen helfen wir dir weiter. Stelle einfach deine Fragen und wir lösen dein Problem innerhalb von 24 Stunden, meist aber noch viel schneller!'
            : 'You are not able to move on with your learning stuff? We reach out to you in our public Azubinet courses. Just ask your questions and we solve your problems within 24 hours or much faster most of the time!'}
        </BrandLargeText>
        <View styleName="flex-md-row ai-center ai-md-start">
          <Button to="/register" size="lg" color="success">
            <Trans {...dict('register_for_free')} />
          </Button>
        </View>
      </View>
    </HighlightStripe>
  );
}

export default ApprenticesTutoringInfo;
