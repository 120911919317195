// @flow
import React from 'react';
import { Platform } from 'react-native';
import { Domain } from 'libs/misc';
import { Navigate } from 'libs/routing';
import Azubinet from './Azubinet';
import Examunity from './Examunity';
import VogelSPOT from './VogelSPOT';
import Verkehrserziehung from './Verkehrserziehung';
import LandingOthers from './LandingOthers';
import WBS from './WBS';
import Comcave from './Comcave';
import StBKNiedersachsen from './StBKNiedersachsen';
import AGA from './AGA';
import Bauvista from './Bauvista';

type Props = {
  viewer: ?Entity<GQLUser>,
};

function Home({ viewer }: Props): React$Node {
  // Redirect to dashboard or login on mobile
  if (Platform.OS !== 'web') {
    return <Navigate to={viewer ? '/dashboard' : '/login'} replace />;
  }

  return Domain.select({
    'www.azubinet.de': <Azubinet />,
    'www.examunity.com': <Examunity />,
    'www.vogelspot.de': <VogelSPOT />,
    'www.xn--fahrradprfung-4ob.de': <Verkehrserziehung />,
    'wbs.azubinet.de': <WBS />,
    'comcave.azubinet.de': <Comcave />,
    'stbk.azubinet.de': <StBKNiedersachsen />,
    'aga.azubinet.de': <AGA />,
    'bauvista.azubinet.de': <Bauvista />,
    '*': <LandingOthers />,
  });
}

export default Home;
