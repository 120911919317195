// @flow
import type { HtmlTag } from './types';

type Attribute = {
  key: string,
  number?: boolean,
  test?: (value: string) => boolean,
};

const alignments = ['left', 'right', 'center'];

const align = { key: 'align', test: (value) => alignments.includes(value) };
const width = { key: 'width', number: true, test: (value) => Number(value) > 0 };
const colspan = { key: 'colSpan', number: true, test: (value) => Number(value) > 0 };
const href = { key: 'url' };
const title = { key: 'title' };
const src = href;
const alt = { key: 'alt' };

const attributes = {
  div: { align },
  td: { width, colspan, align },
  th: { width, colspan, align },
  a: { href, title },
  img: { src, alt, title },
};

export default function getTagAttributes(tag: HtmlTag): Object {
  const props = {};

  if (!tag.attributes || !attributes[tag.name]) {
    return props;
  }

  // Tag is img tag, process attributes.
  Object.keys(attributes[tag.name]).forEach((key: string) => {
    // $FlowFixMe[incompatible-use]
    const value = tag.attributes[key];
    const attribute: Attribute = attributes[tag.name][key];

    if (attribute && (!attribute.test || attribute.test(value))) {
      if (attribute.number) {
        props[attribute.key] = Number(value);
      } else {
        props[attribute.key] = value;
      }
    }
  });

  return props;
}
