// @flow
import type { AstNode } from './types';

const getHeadRowsCount = (rows: Object[]) => {
  let count = 0;

  rows.every((row) => {
    if (row.children.every((cell) => cell.htmlTag && cell.htmlTag.name === 'th')) {
      count += 1;

      return true;
    }

    return false;
  });

  return count;
};

export default function wrapTable(rows: Object[], html: boolean = false): Object[] {
  // For non-html tables there is exactly one head row.
  const headRowsCount = html ? getHeadRowsCount(rows) : 1;
  const parts = [];

  // Insert thead if there are head rows.
  if (headRowsCount > 0) {
    const tableHead = {
      type: 'tableHead',
      children: rows.slice(0, headRowsCount),
      position: {
        start: rows[0].position.start,
        end: rows[headRowsCount - 1].position.end,
      },
      htmlTag: undefined,
    };

    // Add head attribute to head rows
    tableHead.children.forEach((row) => {
      // eslint-disable-next-line no-param-reassign
      row.head = true;

      row.children.forEach((cell) => {
        // eslint-disable-next-line no-param-reassign
        cell.head = true;
      });
    });

    if (html) {
      tableHead.htmlTag = {
        name: 'thead',
        source: ['<thead>', '</thead>'],
        selfClosing: false,
        attributes: null,
        validated: true,
      };
    }

    parts.push(tableHead);
  }

  // Insert tbody if not all rows are head rows.
  if (headRowsCount < rows.length) {
    const tableBody: AstNode = {
      type: 'tableBody',
      children: rows.slice(headRowsCount),
      position: {
        start: rows[headRowsCount].position.start,
        end: rows[rows.length - 1].position.end,
      },
    };

    if (html) {
      tableBody.htmlTag = {
        name: 'tbody',
        source: ['<tbody>', '</tbody>'],
        selfClosing: false,
        attributes: null,
        validated: true,
      };
    }

    parts.push(tableBody);
  }

  return parts;
}
