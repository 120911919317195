// @flow
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-transporter';

type MutationDispatch = (Object) => Promise<any>;

export type MutationState = {
  dispatch: MutationDispatch,
  hasError: (value: string) => boolean,
  ok: ?boolean,
  loading: boolean,
  executed: boolean,
  errors: ?(Object[]),
  networkError: ?Object,
};

const getNetworkError = (res) => {
  if (res.data && res.data.error) {
    return res.data.error;
  }

  return 'Unknown network error.';
};

export default function useMutation(): MutationState {
  const [state, setState] = useState({
    ok: null,
    loading: false,
    executed: false,
    errors: null,
    networkError: null,
  });

  const universalDispatch = useDispatch();

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const dispatch = (action) => {
    setState((prevState) => ({
      ok: null,
      loading: true,
      executed: prevState.executed,
      errors: null,
      networkError: null,
    }));

    const promise = universalDispatch(action);

    promise.then(
      () => {
        if (mounted.current) {
          setState({
            ok: true,
            loading: false,
            executed: true,
            errors: null,
            networkError: null,
          });
        }
      },
      (err) => {
        const errors = err.type === 'GraphQLError' ? err.data.errors : null;
        const networkError = err.type === 'HttpError' ? getNetworkError(err) : null;

        if (mounted.current) {
          setState({
            ok: false,
            loading: false,
            executed: true,
            errors,
            networkError,
          });
        }
      },
    );

    return promise;
  };

  const hasError = (value) => {
    if (!state.errors) {
      return false;
    }

    return state.errors.some(({ extensions: { code } }) => code === value);
  };

  return {
    dispatch,
    hasError,
    ...state,
  };
}
