// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql, selectByRoot } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import { getVersion } from 'utils/courses';
import { DOMAIN_QUERY } from 'libs/foundation/queries';
import { COMMENTS_FRAGMENT } from 'views/_shared/Comments';
import {
  QUIZ_CARD_TEXT_FRAGMENT,
  QUIZ_CARD_CHOICE_FRAGMENT,
  QUIZ_CARD_ORDERING_FRAGMENT,
  QUIZ_CARD_MATCHING_FRAGMENT,
} from '../_shared/QuizCard/fragments';
import { ACTIVITY_DETAILS_TITLE_FRAGMENT } from '../_shared/ActivityDetailsTitle/fragments';
import { ACTIVITY_VIEWER_RESULT_FRAGMENT } from '../_shared/ActivityViewerResult/fragments';
import { QUIZ_ACTIONS_FRAGMENT } from '../_shared/QuizActions/fragments';
import { ACTIVITY_DETAILS_SIDEBAR_FRAGMENT } from '../_shared/ActivityDetailsSidebar/fragments';

const Quiz = {
  name: 'Quiz',
  bundle: () => import('./Quiz'),
};

export const QUIZ_QUERY: DocumentNode = gql`
  query CourseQuiz(
    $slug: String!
    $courseSlug: String!
    $postId: ClientID
    $versionNumber: Int
    $specificVersion: String
  ) {
    course(slug: $courseSlug) {
      viewerIsAdmin
      viewerIsMember
      viewerIsObserver
      quizActivity(slug: $slug) {
        ...ActivityDetailsTitleFragment
        ...ActivityViewerResultFragment
        ...QuizActionsFragment
        ...ActivityDetailsSidebarFragment
        title
        slug
        published
        version(versionNumber: $versionNumber, specificVersion: $specificVersion) {
          versionNumber
          releasedAt
          current
          item {
            cardsCount
            downloadable
            totalBoxes
            resultsCount
            cards {
              ...QuizCardChoiceFragment
              ...QuizCardTextFragment
              ...QuizCardOrderingFragment
              ...QuizCardMatchingFragment
              ...CommentsFragment
            }
            updatedAt
            createdAt
          }
        }

        author {
          name
          avatar {
            file {
              name
              extension
              info {
                image {
                  height
                  width
                }
              }
            }
          }
        }
        viewerCanUpdate
      }
    }
  }

  ${QUIZ_CARD_TEXT_FRAGMENT}
  ${QUIZ_CARD_CHOICE_FRAGMENT}
  ${QUIZ_CARD_ORDERING_FRAGMENT}
  ${QUIZ_CARD_MATCHING_FRAGMENT}
  ${COMMENTS_FRAGMENT}
  ${ACTIVITY_DETAILS_TITLE_FRAGMENT}
  ${ACTIVITY_VIEWER_RESULT_FRAGMENT}
  ${QUIZ_ACTIONS_FRAGMENT}
  ${ACTIVITY_DETAILS_SIDEBAR_FRAGMENT}
`;

const Container = createAsyncContainer(Quiz, (props) => {
  const {
    params: { slug, courseSlug },
    searchParams,
  } = props;

  const versionNumber = getVersion(searchParams.get('version'));
  const variables = {
    slug,
    courseSlug,
    postId: searchParams.get('post_id'),
    versionNumber,
    specificVersion: versionNumber ? null : 'CURRENT',
  };

  const loaders = {
    query: query(QUIZ_QUERY, { variables }),
  };

  const selectors = {
    course: select(QUIZ_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
    domain: select(DOMAIN_QUERY, {}),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
