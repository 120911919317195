// @flow
import { createAsyncContainer } from 'libs/container';

const Cashback = {
  name: 'Cashback',
  bundle: () => import('./Cashback'),
};

const Container = createAsyncContainer(Cashback, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
