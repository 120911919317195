// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { Heading as BaseHeading } from 'bootstrap-rn';

type Props = {
  children: React$Node,
  style?: any,
};

const styles = StyleSheet.create({
  heading: css`
    font-family: Titillium Web;
  `,
});

const Heading = React.forwardRef<Props, any>((props, ref) => {
  const { children, style, ...elementProps } = props;

  return (
    <BaseHeading {...elementProps} ref={ref} style={[styles.heading, style]}>
      {children}
    </BaseHeading>
  );
});

Heading.displayName = 'Heading';

export default (Heading: React$AbstractComponent<Props, any>);
