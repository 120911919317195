// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { Button as BaseButton } from 'bootstrap-rn';

type Props = {
  children: React$Node,
  size?: 'sm' | 'lg',
  color?: string,
  outline?: boolean,
  style?: any,
  textStyle?: any,
  activeStyle?: any,
};

const styles = StyleSheet.create({
  text: css`
    letter-spacing: 0.5px;
    text-transform: uppercase;
    line-height: 1.375rem;
    font-weight: 500;
    // Workaround for native fonts
    @include platform(ios) {
      font-family: 'Roboto Medium';
    }
    @include platform(android) {
      font-family: 'Roboto Medium';
    }
  `,
  largeText: css`
    line-height: 1.625rem;
  `,
  darkText: css`
    color: $gray-900;
  `,
  linkText: css`
    &:hover {
      text-decoration-line: none;
    }
    &:focus-visible {
      text-decoration-line: none;
    }
  `,
  light: css`
    background-color: transparent;
    border-color: transparent;

    &:hover {
      background-color: $transparent-100;
      border-color: transparent;
    }
    &:focus-visible {
      background-color: $transparent-100;
      border-color: transparent;
    }
  `,
  lightActive: css`
    background-color: $transparent-200;
    border-color: transparent;

    &:hover {
      background-color: $transparent-200;
      border-color: transparent;
    }
    &:focus-visible {
      background-color: $transparent-200;
      border-color: transparent;
    }
  `,
  link: css`
    &:hover {
      background-color: $transparent-100;
    }
    &:focus-visible {
      background-color: $transparent-100;
    }
  `,
});

const Button = React.forwardRef<Props, any>((props, ref) => {
  const { children, size, color, outline, style, textStyle, activeStyle, ...elementProps } = props;

  return (
    <BaseButton
      {...elementProps}
      ref={ref}
      size={size}
      color={color}
      outline={outline}
      style={[color === 'light' && styles.light, color === 'link' && styles.link, style]}
      textStyle={[
        styles.text,
        (color === 'secondary' || color === 'warning' || color === 'info') && styles.darkText,
        color === 'link' && styles.linkText,
        size === 'lg' && styles.largeText,
        textStyle,
      ]}
      activeStyle={[color === 'light' && styles.lightActive, activeStyle]}
    >
      {children}
    </BaseButton>
  );
});

Button.displayName = 'Button';

export default (Button: React$AbstractComponent<Props, any>);
