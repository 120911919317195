// @flow
import React from 'react';
import { StyleSheet, css, colorContrast } from 'libs/ui';
import { NavbarLink } from 'components/navbar';
import { Icon } from 'components/common';
import { Dropdown } from 'components/core';
import SearchForm from './SearchForm';

const styles = StyleSheet.create({
  dropdownIcon: css`
    color: ${colorContrast((t) => t['brand-primary'])};
  `,
  menu: css`
    left: 0;
    right: 0;
    border-radius: 0;
    border-width: 0;
    border-top-width: 1px;
    background-color: $brand-primary;
    margin-top: -0.125rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  `,
});

function MobileSearchDropdown(): React$Node {
  return (
    <Dropdown>
      <NavbarLink toggle={Dropdown} caret={false}>
        <Icon glyph="search" style={styles.dropdownIcon} />
      </NavbarLink>
      <Dropdown.Menu style={styles.menu}>
        <SearchForm />
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default MobileSearchDropdown;
