// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query, selectByRoot } from 'libs/graphql';

const MonetizationLicenses = {
  name: 'MonetizationLicenses',
  bundle: () => import('./MonetizationLicenses'),
};

const ORGANIZATION_PRODUCT_LICENSES_QUERY = gql`
  query OrganizationProductDetailsQuery($slug: String!, $productSlug: String!) {
    organization(slug: $slug) {
      name
      slug
      product(productSlug: $productSlug) {
        slug
        name
        description
        offers {
          keysCount
          purchasesCount(completed: true)
        }
        licensesCount
        licenses {
          validUntil
          code
          organization {
            name
          }
          assignment {
            user {
              name
            }
          }
        }
      }
      viewerIsAdmin
      viewerIsTrainer
      viewerIsMember
    }
  }
`;

const Container = createAsyncContainer(MonetizationLicenses, (props) => {
  const {
    params: { slug, productSlug },
  } = props;

  const variables = { slug, productSlug };

  const loaders = {
    query: query(ORGANIZATION_PRODUCT_LICENSES_QUERY, { variables }),
  };

  const selectors = {
    organization: select(ORGANIZATION_PRODUCT_LICENSES_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
