// @flow
import { useFormikContext } from 'formik';
import { useIntl } from 'libs/intl';
import { formatError } from 'libs/validation';

export default function useFormField(
  name: string,
  niceName?: Intlized$Message | string | null,
  title?: Intlized$Message | string | null,
): Object {
  const form = useFormikContext();

  // $FlowFixMe[incompatible-call]
  const meta = form.getFieldMeta(name);
  const intl = useIntl();

  return {
    // values
    error: formatError(intl, name, niceName, title, meta.error),
    touched: meta.touched,
    value: meta.value,
    // functions
    setTouched() {
      form.setFieldTouched(name, true);
    },
    setValue(value, handleChange) {
      if (handleChange) {
        handleChange(value);
      }

      // $FlowFixMe[incompatible-call]
      form.setFieldError(name, null);

      form.setFieldValue(name, value);
    },
  };
}
