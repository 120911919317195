// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query, selectByRoot } from 'libs/graphql';

const InvitationsAccept = {
  name: 'InvitationsAccept',
  bundle: () => import('./InvitationsAccept'),
};

const ORGANIZATION_INVITAIONS_QUERY = gql`
  query OrganizationInvitationQuery($invitationId: ClientID!) {
    organizationInvitation(id: $invitationId) {
      email
      acceptedAt
      organization {
        slug
        name
      }
      user {
        id
      }
    }
  }
`;

const Container = createAsyncContainer(InvitationsAccept, (props) => {
  const {
    params: { invitationId },
  } = props;

  const variables = { invitationId };

  const loaders = {
    query: query(ORGANIZATION_INVITAIONS_QUERY, { variables }),
  };

  const selectors = {
    invitation: select(ORGANIZATION_INVITAIONS_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
