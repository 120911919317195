// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const QUIZ_ACTIVITY_CAPTION_FRAGMENT: DocumentNode = gql`
  fragment QuizActivityCaptionFragment on QuizActivity {
    version(specificVersion: "CURRENT") {
      item {
        cardsCount
      }
    }
  }
`;
