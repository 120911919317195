// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';
import { ACTIVITIES_FRAGMENT } from '../_shared/ActivityList/fragments';

const QuizList = {
  name: 'QuizList',
  bundle: () => import('./QuizList'),
};

const QUIZ_LIST_QUERY = gql`
  query CourseQuizList($courseSlug: String!, $activityType: CourseActivityType) {
    course(slug: $courseSlug) {
      quizActivitiesCount
      sections {
        quizzesDescription
      }
      restricted
      emailRestriction
      organizationRestriction
      isPrivate
      organization {
        id
      }
      ...ActivitiesFragment
      viewerCanCreateQuizzes
      viewerIsObserver
      viewerIsMember
      viewerIsAdmin
    }
  }

  ${ACTIVITIES_FRAGMENT}
`;

const Container = createAsyncContainer(QuizList, (props) => {
  const {
    params: { courseSlug },
    searchParams,
  } = props;

  const loaders = {
    query: query(QUIZ_LIST_QUERY, {
      variables: {
        courseSlug,
        activityType: 'Quiz',
        // Add activityId to reload query if an activity has been copied
        activityId: searchParams.get('activity_id'),
      },
    }),
  };

  const selectors = {
    course: select(QUIZ_LIST_QUERY, { variables: { courseSlug } }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
