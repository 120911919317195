// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql, selectByRoot } from 'libs/graphql';
import ProfileLoader from './ProfileLoader';
import Fallback from './Fallback';

const USER_QUERY = gql`
  query UserQuery($id: ClientID!) {
    user(id: $id) {
      name
      organizationMemberships {
        organization {
          name
          slug
        }
      }
      avatar {
        file {
          name
          extension
          info {
            image {
              height
              width
            }
          }
        }
      }
      createdAt
    }
  }
`;

const options = {
  async: {
    loading: Fallback,
    error: Fallback,
  },
};

const Container = createAsyncContainer(
  ProfileLoader,
  (props) => {
    const { userId } = props;

    const variables = { id: userId };

    const loaders = {
      query: query(USER_QUERY, { variables }),
    };

    const selectors = {
      user: select(USER_QUERY, { variables }),
      viewer: selectByRoot('viewer'),
    };

    return { loaders, selectors };
  },
  options,
);

export default (Container: React$AbstractComponent<any, mixed>);
