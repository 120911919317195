// @flow
import React from 'react';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { View } from 'components/core';

type Props = {
  children: React$Node,
};

const styles = StyleSheet.create({
  wrapper: css`
    background-color: white;
  `,
  wrapperDesktop: css`
    margin-bottom: -5rem;
  `,
  wrapperXs: css`
    padding-top: 0;
  `,
});

function Wrapper({ children }: Props): React$Node {
  const media = useMedia();

  return (
    <View
      style={[
        styles.wrapper,
        media.up('md') ? styles.wrapperDesktop : undefined,
        media.down('xs') && styles.wrapperXs,
      ]}
    >
      {children}
    </View>
  );
}

export default Wrapper;
