// @flow
import React from 'react';
import { View, Button } from 'components/core';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  to: string,
  children: React$Node,
};

const styles = StyleSheet.create({
  wrapper: css`
    background-color: #eaeff5;
    border-radius: 0.1875rem;
    flex-shrink: 1;
    margin-bottom: 1rem;
  `,
  button: css`
    flex-direction: row;
    flex-shrink: 1;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem;
  `,
});

function HighlightButton(props: Props): React$Node {
  // eslint-disable-next-line react/prop-types
  const { to, children } = props;

  return (
    <View style={styles.wrapper}>
      <Button to={to} color="light" style={styles.button}>
        {children}
      </Button>
    </View>
  );
}

export default HighlightButton;
