// @flow
import React from 'react';
import { useMedia } from 'libs/ui';
import { View, Heading } from 'components/core';
import { Icon } from 'components/common';
import { createDict, Trans } from 'libs/intl';
import CourseCard from './CourseCard';

const dict = createDict({
  popular_subjects: 'Popular subjects',
});

function PopularCourses(): React$Node {
  const media = useMedia();

  return (
    <>
      <View styleName="pt-5 pb-3">
        <Heading size={2}>
          {media.up('md') && <Icon glyph="star" styleName="mr-3" />}
          <Trans {...dict('popular_subjects')} />
        </Heading>
      </View>
      <View styleName="my-3">
        <View styleName="flex-row flex-wrap jc-md-start">
          <CourseCard
            courseName="Kundenbeziehungsprozesse (AP Teil 2)"
            professionName="Kaufleute für Büromanagement (IHK)"
            courseType="Kaufbarer Kurs"
            courseLink="rXiunh5qI8"
          />
          <CourseCard
            courseName="Wirtschafts- und Sozialkunde I"
            professionName="Kaufmännische Berufe (normales Anforderungsniveau)"
            courseType="Kaufbarer Kurs"
            courseLink="qLmkoQREwv"
          />
          <CourseCard
            courseName="Wirtschafts- und Sozialkunde II"
            professionName="Kaufmännische Berufe (erhöhtes Anforderungsniveau)"
            courseType="Kaufbarer Kurs"
            courseLink="NiWFktctzi"
          />
          <CourseCard
            courseName="Abschlussprüfung Teil 2"
            professionName="Kaufleute im E-Commerce"
            courseType="Kaufbarer Kurs"
            courseLink="ORuRpBqobA"
          />
          <CourseCard
            courseName="Geschäftsprozesse im Einzelhandel"
            professionName="Kaufleute im Einzelhandel"
            courseType="Kaufbarer Kurs"
            courseLink="onzwMcsx9Y"
          />
          <CourseCard
            courseName="Geschäftsprozesse"
            professionName="Industriekaufleute"
            courseType="Kaufbarer Kurs"
            courseLink="YXKJcFzGAG"
          />
        </View>
      </View>
    </>
  );
}

export default PopularCourses;
