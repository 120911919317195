// @flow
import React from 'react';
import { Text, View, Modal, ListGroup, Button, Dropdown, CloseButton } from 'components/core';
import { Scrollbars } from 'components/common';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { useValueState } from 'libs/misc';
import { createDict, Trans } from 'libs/intl';
import AutomobilkaufleuteBundles from './AzubinetBundles/AutomobilkaufleuteBundles';
import DrogistBundles from './AzubinetBundles/DrogistBundles';
import FachinformatikerBundles from './AzubinetBundles/FachinformatikerBundles';
import FachkraftFuerLagerlogistikBundles from './AzubinetBundles/FachkraftFuerLagerlogistikBundles';
import FachlageristBundles from './AzubinetBundles/FachlageristBundles';
import FloristBundles from './AzubinetBundles/FloristBundles';
import IndustriekaufleuteBundles from './AzubinetBundles/IndustriekaufleuteBundles';
import HotelfachmannBundles from './AzubinetBundles/HotelfachmannBundles';
import KaufleuteFuerBueromanagementBundles from './AzubinetBundles/KaufleuteFuerBueromanagementBundles';
import KaufleuteFuerGrossUndAussenhandelsmanagementBundles from './AzubinetBundles/KaufleuteFuerGrossUndAussenhandelsmanagementBundles';
import KaufleuteFuerSpeditionBundles from './AzubinetBundles/KaufleuteFuerSpeditionBundles';
import KaufleuteImECommerceBundles from './AzubinetBundles/KaufleuteImECommerceBundles';
import KaufleuteImEinzelhandelBundles from './AzubinetBundles/KaufleuteImEinzelhandelBundles';
import KaufleuteImGesundheitswesenBundles from './AzubinetBundles/KaufleuteImGesundheitswesenBundles';
import KaufleuteImGrossUndAussenhandelBundles from './AzubinetBundles/KaufleuteImGrossUndAussenhandelBundles';
import KochBundles from './AzubinetBundles/KochBundles';
import MFABundles from './AzubinetBundles/MFABundles';
import PersonaldienstleistungskaufmannBundles from './AzubinetBundles/PersonaldienstleistungskaufmannBundles';
import RestaurantfachmannBundles from './AzubinetBundles/RestaurantfachmannBundles';
import SteuerfachangestellteBundles from './AzubinetBundles/SteuerfachangestellteBundles';
import VerkaeuferBundles from './AzubinetBundles/VerkaeuferBundles';
import MobileDropdown from './AzubinetMobileDropdown';

type Props = {
  modal: ToggleState,
  onClose?: () => void,
};

const styles = StyleSheet.create({
  buttonText: css`
    font-size: 1rem;
    text-transform: none;
  `,
});

const dict = createDict({
  find_bundles: 'Find bundles',
});

function AzubinetBundlesModal({ modal, onClose }: Props): React$Node {
  const media = useMedia();
  const profession = useValueState('Beruf auswählen');

  const DesktopScrollbars = media.up('md') ? Scrollbars : React.Fragment;

  return (
    <Modal visible={modal.on} onToggle={modal.toggle} size="xl">
      <Modal.Header>
        <Modal.Title>
          <Trans {...dict('find_bundles')} />
        </Modal.Title>
        <CloseButton onPress={modal.toggle} />
      </Modal.Header>
      <Modal.Body styleName="flex-md-row flex-grow-1 flex-shrink-1">
        <View styleName="mb-3 mb-md-0">
          {media.down('sm') && (
            <MobileDropdown profession={profession}>
              <Button toggle={Dropdown} color="secondary" outline>
                <Text style={styles.buttonText}>
                  {profession.value === 'Beruf auswählen' ? 'Beruf auswählen' : profession.value}
                </Text>
              </Button>
            </MobileDropdown>
          )}
          {media.up('md') && (
            <ListGroup>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Automobilkaufleute');
                }}
                active={profession.value === 'Automobilkaufleute'}
              >
                <Text numberOfLines={1}>Automobilkaufleute</Text>
              </ListGroup.ItemAction>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Drogist/-innen');
                }}
                active={profession.value === 'Drogist/-innen'}
              >
                <Text numberOfLines={1}>Drogist/-innen</Text>
              </ListGroup.ItemAction>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Fachinformatiker/-innen und IT-Berufe');
                }}
                active={profession.value === 'Fachinformatiker/-innen und IT-Berufe'}
              >
                <Text numberOfLines={1}>Fachinformatiker/-innen und IT-Berufe</Text>
              </ListGroup.ItemAction>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Fachkräfte für Lagerlogistik');
                }}
                active={profession.value === 'Fachkräfte für Lagerlogistik'}
              >
                <Text numberOfLines={1}>Fachkräfte für Lagerlogistik</Text>
              </ListGroup.ItemAction>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Fachlagerist/-innen');
                }}
                active={profession.value === 'Fachlagerist/-innen'}
              >
                <Text>Fachlagerist/-innen</Text>
              </ListGroup.ItemAction>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Florist/-innen');
                }}
                active={profession.value === 'Florist/-innen'}
              >
                <Text numberOfLines={1}>Florist/-innen</Text>
              </ListGroup.ItemAction>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Hotelfachleute');
                }}
                active={profession.value === 'Hotelfachleute'}
              >
                <Text numberOfLines={1}>Hotelfachleute</Text>
              </ListGroup.ItemAction>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Industriekaufleute');
                }}
                active={profession.value === 'Industriekaufleute'}
              >
                <Text numberOfLines={1}>Industriekaufleute</Text>
              </ListGroup.ItemAction>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Kaufleute für Büromanagement');
                }}
                active={profession.value === 'Kaufleute für Büromanagement'}
              >
                <Text numberOfLines={1}>Kaufleute für Büromanagement</Text>
              </ListGroup.ItemAction>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Kaufleute für Groß- und Außenhandelsmanagement');
                }}
                active={profession.value === 'Kaufleute für Groß- und Außenhandelsmanagement'}
              >
                <Text numberOfLines={1}>Kaufleute für Groß- und Außenhandelsmanagement</Text>
              </ListGroup.ItemAction>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Kaufleute für Spedition und Logistikdienstleistung');
                }}
                active={profession.value === 'Kaufleute für Spedition und Logistikdienstleistung'}
              >
                <Text numberOfLines={1}>Kaufleute für Spedition und Logistikdienstleistung</Text>
              </ListGroup.ItemAction>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Kaufleute im E-Commerce');
                }}
                active={profession.value === 'Kaufleute im E-Commerce'}
              >
                <Text numberOfLines={1}>Kaufleute im E-Commerce</Text>
              </ListGroup.ItemAction>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Kaufleute im Einzelhandel');
                }}
                active={profession.value === 'Kaufleute im Einzelhandel'}
              >
                <Text numberOfLines={1}>Kaufleute im Einzelhandel</Text>
              </ListGroup.ItemAction>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Kaufleute im Gesundheitswesen');
                }}
                active={profession.value === 'Kaufleute im Gesundheitswesen'}
              >
                <Text numberOfLines={1}>Kaufleute im Gesundheitswesen</Text>
              </ListGroup.ItemAction>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Kaufleute im Groß- und Außenhandel');
                }}
                active={profession.value === 'Kaufleute im Groß- und Außenhandel'}
              >
                <Text numberOfLines={1}>Kaufleute im Groß- und Außenhandel</Text>
              </ListGroup.ItemAction>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Köche');
                }}
                active={profession.value === 'Köche'}
              >
                <Text numberOfLines={1}>Köche</Text>
              </ListGroup.ItemAction>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Medizinische Fachangestellte');
                }}
                active={profession.value === 'Medizinische Fachangestellte'}
              >
                <Text numberOfLines={1}>Medizinische Fachangestellte</Text>
              </ListGroup.ItemAction>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Personaldienstleistungskaufleute');
                }}
                active={profession.value === 'Personaldienstleistungskaufleute'}
              >
                <Text numberOfLines={1}>Personaldienstleistungskaufleute</Text>
              </ListGroup.ItemAction>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Restaurantfachleute');
                }}
                active={profession.value === 'Restaurantfachleute'}
              >
                <Text numberOfLines={1}>Restaurantfachleute</Text>
              </ListGroup.ItemAction>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Steuerfachangestellte');
                }}
                active={profession.value === 'Steuerfachangestellte'}
              >
                <Text numberOfLines={1}>Steuerfachangestellte</Text>
              </ListGroup.ItemAction>
              <ListGroup.ItemAction
                onPress={() => {
                  profession.set('Verkäufer/-innen');
                }}
                active={profession.value === 'Verkäufer/-innen'}
              >
                <Text numberOfLines={1}>Verkäufer/-innen</Text>
              </ListGroup.ItemAction>
            </ListGroup>
          )}
        </View>
        <View styleName="flex-grow-1 flex-shrink-1 ml-md-3">
          <DesktopScrollbars>
            {profession.value === 'Automobilkaufleute' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <AutomobilkaufleuteBundles onClose={onClose} />
              </View>
            )}
            {profession.value === 'Drogist/-innen' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <DrogistBundles onClose={onClose} />
              </View>
            )}
            {profession.value === 'Fachinformatiker/-innen und IT-Berufe' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <FachinformatikerBundles onClose={onClose} />
              </View>
            )}
            {profession.value === 'Fachkräfte für Lagerlogistik' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <FachkraftFuerLagerlogistikBundles onClose={onClose} />
              </View>
            )}
            {profession.value === 'Fachlagerist/-innen' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <FachlageristBundles onClose={onClose} />
              </View>
            )}
            {profession.value === 'Florist/-innen' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <FloristBundles onClose={onClose} />
              </View>
            )}
            {profession.value === 'Industriekaufleute' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <IndustriekaufleuteBundles onClose={onClose} />
              </View>
            )}
            {profession.value === 'Hotelfachleute' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <HotelfachmannBundles onClose={onClose} />
              </View>
            )}
            {profession.value === 'Kaufleute für Büromanagement' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <KaufleuteFuerBueromanagementBundles onClose={onClose} />
              </View>
            )}
            {profession.value === 'Kaufleute für Groß- und Außenhandelsmanagement' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <KaufleuteFuerGrossUndAussenhandelsmanagementBundles onClose={onClose} />
              </View>
            )}
            {profession.value === 'Kaufleute für Spedition und Logistikdienstleistung' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <KaufleuteFuerSpeditionBundles onClose={onClose} />
              </View>
            )}
            {profession.value === 'Kaufleute im E-Commerce' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <KaufleuteImECommerceBundles onClose={onClose} />
              </View>
            )}
            {profession.value === 'Kaufleute im Einzelhandel' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <KaufleuteImEinzelhandelBundles onClose={onClose} />
              </View>
            )}
            {profession.value === 'Kaufleute im Gesundheitswesen' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <KaufleuteImGesundheitswesenBundles onClose={onClose} />
              </View>
            )}
            {profession.value === 'Kaufleute im Groß- und Außenhandel' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <KaufleuteImGrossUndAussenhandelBundles onClose={onClose} />
              </View>
            )}
            {profession.value === 'Köche' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <KochBundles onClose={onClose} />
              </View>
            )}
            {profession.value === 'Medizinische Fachangestellte' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <MFABundles onClose={onClose} />
              </View>
            )}
            {profession.value === 'Personaldienstleistungskaufleute' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <PersonaldienstleistungskaufmannBundles onClose={onClose} />
              </View>
            )}
            {profession.value === 'Restaurantfachleute' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <RestaurantfachmannBundles onClose={onClose} />
              </View>
            )}
            {profession.value === 'Steuerfachangestellte' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <SteuerfachangestellteBundles onClose={onClose} />
              </View>
            )}
            {profession.value === 'Verkäufer/-innen' && (
              <View style={media.up('md') && styles.bundlesSectionDesktop}>
                <VerkaeuferBundles onClose={onClose} />
              </View>
            )}
          </DesktopScrollbars>
        </View>
      </Modal.Body>
    </Modal>
  );
}

export default AzubinetBundlesModal;
