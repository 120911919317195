// @flow
import React from 'react';
import { Icon } from 'components/common';
import { BrandHeading, BrandLargeText } from 'components/pages';
import { View, Grid } from 'components/core';
import { StyleSheet, css, useMedia } from 'libs/ui';

type Props = {
  title: string,
  content: string,
  icon: string,
  type: string,
};

const styles = StyleSheet.create({
  fontColorRed: css`
    color: #e30613;
  `,
  fontColorBlack: css`
    color: #000;
  `,
  GridBoxDesktop: css`
    padding: 2rem;
  `,
  BoxHeaderTitleDesktop: css`
    text-align: center;
    font-family: 'Titillium Web';
    font-weight: bold;
  `,
  BoxImageDesktop: css`
    padding-bottom: 0.25rem;
    justify-content: center;
    align-items: center;
  `,
  GridBoxMobile: css`
    padding: 1rem;
  `,
  BoxHeaderTitleMobile: css`
    text-align: center;
    font-family: 'Titillium Web';
    font-weight: bold;
  `,
  icon: css`
    font-size: 3rem;
    line-height: 3rem * $line-height-base;
  `,
});

function StBKFeaturesBox(props: Props): React$Node {
  const { title, content, icon, type } = props;
  const media = useMedia();

  let iconColor = styles.fontColorBlack;
  if (type === 'red') {
    iconColor = styles.fontColorRed;
  }

  return (
    <Grid.Box
      size={12}
      sizeMd={4}
      style={media.up('md') ? styles.GridBoxDesktop : styles.GridBoxMobile}
    >
      <View styleName="ai-center mb-2">
        <Icon glyph={icon} label="Feature" style={[styles.icon, iconColor]}>
          {type}
        </Icon>
        <BrandHeading
          size={4}
          style={media.up('md') ? styles.BoxHeaderTitleDesktop : styles.BoxHeaderTitleMobile}
        >
          {title}
        </BrandHeading>
      </View>
      <BrandLargeText align="center">{content}</BrandLargeText>
    </Grid.Box>
  );
}

export default StBKFeaturesBox;
