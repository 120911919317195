// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { View, Container } from 'components/core';
import { Outlet } from 'libs/routing';

const styles = StyleSheet.create({
  background: css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $brand-background;
    align-items: center;
    justify-content: center;

    @include platform(web) {
      position: fixed;
    }
  `,
  container: css`
    position: absolute;
    top: 0;
    bottom: 0;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  `,
});

function TheaterLayout(): React$Node {
  return (
    <View style={styles.background}>
      <Container style={styles.container}>
        <Outlet />
      </Container>
    </View>
  );
}

export default TheaterLayout;
