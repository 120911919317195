// @flow
import { createAsyncContainer } from 'libs/container';

const AcceptInvitation = {
  name: 'AcceptInvitation',
  bundle: () => import('./AcceptInvitation'),
};

const Container = createAsyncContainer(AcceptInvitation, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
