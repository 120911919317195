// @flow
import React from 'react';
import { View, Grid, Image } from 'components/core';
import { LargeText } from 'components/common';
import { StyleSheet, css, useMedia } from 'libs/ui';

type Props = {
  content: string,
  image: string,
  title: string,
  textPosition: string,
};

const styles = StyleSheet.create({
  sectionTextDesktop: css`
    padding-top: 2rem;
  `,
  sectionTextMobile: css`
    padding-top: 0.25rem;
    padding-bottom: 2rem;
    text-align: center;
  `,
});

function GridSectionBox(props: Props): React$Node {
  const { content, image, title, textPosition } = props;
  const media = useMedia();

  const textContent = (
    <Grid.Box size={12} sizeMd={6}>
      {media.up('md') && (
        <View>
          <LargeText style={styles.sectionTextDesktop}>{content}</LargeText>
        </View>
      )}
      {media.down('sm') && (
        <View styleName="p-3">
          <LargeText style={styles.sectionTextMobile}>{content}</LargeText>
        </View>
      )}
    </Grid.Box>
  );
  const imageContent = (
    <Grid.Box size={12} sizeMd={6} styleName="jc-center ai-center">
      {media.up('md') && <Image source={image} label={title} style={{ width: 318, height: 200 }} />}
      {media.down('sm') && (
        <Image source={image} label={title} style={{ width: 235, height: 150 }} />
      )}
    </Grid.Box>
  );

  if (textPosition === 'left') {
    return (
      <>
        {media.up('md') && (
          <Grid styleName="mt-5 mb-3">
            {textContent}
            {imageContent}
          </Grid>
        )}
        {media.down('sm') && (
          <Grid styleName="mt-3">
            {imageContent}
            {textContent}
          </Grid>
        )}
      </>
    );
  }
  return (
    <>
      {media.up('md') && (
        <Grid styleName="mt-5 mb-3">
          {imageContent}
          {textContent}
        </Grid>
      )}
      {media.down('sm') && (
        <Grid styleName="mt-3">
          {imageContent}
          {textContent}
        </Grid>
      )}
    </>
  );
}

export default GridSectionBox;
// export default extended(GridSectionBox, extensions);
