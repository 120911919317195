// @flow
import React from 'react';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { View } from 'components/core';

type Props = {
  position: number,
  positionXs: number,
  style?: any,
  styleName?: string,
};

const styles = StyleSheet.create({
  caret: css`
    position: absolute;
    bottom: 100%;
    height: 0;
    width: 0;
    border-color: transparent;
  `,
  caretOuter: css`
    border-bottom-color: $gray-300;
    border-width: 9px;
  `,
  caretInner: css`
    border-bottom-color: #fff;
    border-width: 7px;
  `,
});

function Caret({ position, positionXs, style, styleName }: Props): React$Node {
  const media = useMedia();

  const pos = media.down('xs') ? positionXs : position;

  return (
    <View style={style} styleName={styleName}>
      <View style={[styles.caret, styles.caretOuter, { right: pos }]} />
      <View style={[styles.caret, styles.caretInner, { right: pos + 2 }]} />
    </View>
  );
}

export default Caret;
