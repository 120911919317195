// @flow
export default function useProductsData(products: Array<Entity<GQLProduct>>): Object {
  const offerListOptions = {};
  const offerList = {};

  products.forEach((product) => {
    product.offers.forEach((offer) => {
      if (offer.businessPrices.length > 0) {
        offerList[offer.id] = offer;

        if (offerListOptions[product.id] === undefined) {
          offerListOptions[product.id] = [offer];
        } else {
          offerListOptions[product.id].push(offer);
        }
      }
    });
  });

  return { offerListOptions, offerList };
}
