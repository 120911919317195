// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query, selectByRoot } from 'libs/graphql';

const TeamsSettings = {
  name: 'TeamsSettings',
  bundle: () => import('./TeamsSettings'),
};

const ORGANIZATION_TEAMS_SETTINGS_QUERY = gql`
  query TeamsSettingsQuery($slug: String!, $teamSlug: String!) {
    organization(slug: $slug) {
      name
      slug
      team(teamSlug: $teamSlug) {
        name
        slug
        memberships {
          user {
            id
          }
        }
        viewerIsAdmin
        viewerCanDelete
      }
    }
  }
`;

const Container = createAsyncContainer(TeamsSettings, (props) => {
  const {
    params: { slug, teamSlug },
  } = props;

  const variables = { slug, teamSlug };

  const loaders = {
    query: query(ORGANIZATION_TEAMS_SETTINGS_QUERY, { variables }),
  };

  const selectors = {
    organization: select(ORGANIZATION_TEAMS_SETTINGS_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
