// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';
import { DOMAIN_QUERY } from 'libs/foundation/queries';

const RecordBook = {
  name: 'RecordBook',
  bundle: () => import('./RecordBook'),
};

const VIEWER_REPORTS_QUERY = gql`
  query RecordBookViewerQuery {
    viewer {
      name
    }
  }
`;

const Container = createAsyncContainer(RecordBook, () => {
  const loaders = {
    query: query(VIEWER_REPORTS_QUERY, {}),
  };

  const selectors = {
    viewer: select(VIEWER_REPORTS_QUERY, {}),
    domain: select(DOMAIN_QUERY, {}),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
