// @flow
import React from 'react';
import { View } from 'components/core';
import { Video } from 'components/common';
import { StyleSheet, css, useMedia } from 'libs/ui';

const styles = StyleSheet.create({
  box: css`
    border-radius: 0.125rem;
    border-width: 0.125rem;
    border-color: $brand-primary;
    align-self: center;
    background-color: white;
  `,
  boxDesktop: css`
    max-width: 1028px;
  `,
  boxMobile: css`
    margin-horizontal: 1rem;
  `,
});

function TeaserVideo(): React$Node {
  const media = useMedia();

  return (
    <View style={[styles.box, media.up('md') ? styles.boxDesktop : styles.boxMobile]}>
      <Video
        controls
        source={{ uri: '/video/2023-08-21_das_ist_azubinet.mp4' }}
        poster="/img/home/2023-08-21_das_ist_azubinet.jpg"
      />
    </View>
  );
}

export default TeaserVideo;
