// @flow
import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { CloseButton, ListGroup, Modal } from 'components/core';
import { useMenu } from 'libs/foundation';

type Props = {
  modal: ToggleState,
  domain: Entity<GQLDomain>,
};

const dict = createDict({
  create: 'Create',
  create_checklist: 'Create checklist',
  create_course: 'Create course',
});

function CreateModal({ modal, domain }: Props): React$Node {
  const menu = useMenu();

  const viewerCanCreateCourses =
    domain.permissions.usersCanCreateCourses || domain.ownerOrganization.viewerIsAdmin;

  return (
    <Modal visible={modal.on} onToggle={modal.toggle}>
      <Modal.Header styleName="flex-row">
        <Modal.Title>
          <Trans {...dict('create')} />
        </Modal.Title>
        <CloseButton onPress={modal.toggle} />
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          <ListGroup.ItemAction
            to="/checklists/create"
            onPress={() => {
              modal.setOff();
              menu.clear();
            }}
          >
            <Trans {...dict('create_checklist')} />
          </ListGroup.ItemAction>
          {viewerCanCreateCourses && (
            <ListGroup.ItemAction
              to="/courses/create"
              onPress={() => {
                modal.setOff();
                menu.clear();
              }}
            >
              <Trans {...dict('create_course')} />
            </ListGroup.ItemAction>
          )}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
}

export default CreateModal;
