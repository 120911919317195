// @flow
import { createContainer } from 'libs/container';
import { select } from 'libs/graphql';
import { MENU_CHECKLIST_TODO_ACTIVITY_FRAGMENT } from './fragments';
import ChecklistTodoCourseActivity from './ChecklistTodoCourseActivity';

const Container = createContainer(ChecklistTodoCourseActivity, (props) => {
  const selectors = {
    activity: select(MENU_CHECKLIST_TODO_ACTIVITY_FRAGMENT, {
      entry: [props.activityType, props.activityId],
    }),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
