// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';
import { getVersion } from 'utils/courses';
import { DOMAIN_QUERY } from 'libs/foundation/queries';
import { COMMENTS_FRAGMENT } from 'views/_shared/Comments';
import { VIDEO_FILE_FRAGMENT } from './VideoFile/fragments';
import { VIDEO_FILE_DOWNLOAD_FRAGMENT } from './VideoFileDownload/fragments';
import { VIDEO_LINK_FRAGMENT } from './VideoLink/fragments';
import { ACTIVITY_DETAILS_TITLE_FRAGMENT } from '../_shared/ActivityDetailsTitle/fragments';
import { ACTIVITY_VIEWER_RESULT_FRAGMENT } from '../_shared/ActivityViewerResult/fragments';
import { ACTIVITY_DETAILS_SIDEBAR_FRAGMENT } from '../_shared/ActivityDetailsSidebar/fragments';

const VideoDetails = {
  name: 'VideoDetails',
  bundle: () => import('./VideoDetails'),
};

const COURSE_VIDEO_QUERY = gql`
  query CourseVideoQuery(
    $slug: String!
    $courseSlug: String!
    $postId: ClientID
    $versionNumber: Int
    $specificVersion: String
  ) {
    course(slug: $courseSlug) {
      videoActivity(slug: $slug) {
        ...ActivityDetailsTitleFragment
        ...ActivityViewerResultFragment
        ...ActivityDetailsSidebarFragment
        slug
        published
        version(versionNumber: $versionNumber, specificVersion: $specificVersion) {
          versionNumber
          releasedAt
          item {
            description
            downloadable
            type {
              ...VideoFileFragment
              ...VideoFileDownloadFragment
              ...VideoLinkFragment
            }
            ...CommentsFragment
          }
        }
        author {
          name
        }
      }
      author {
        id
        name
      }
      viewerIsAdmin
      viewerIsMember
      viewerIsObserver
    }
  }

  ${COMMENTS_FRAGMENT}
  ${VIDEO_FILE_FRAGMENT}
  ${VIDEO_FILE_DOWNLOAD_FRAGMENT}
  ${VIDEO_LINK_FRAGMENT}
  ${ACTIVITY_DETAILS_TITLE_FRAGMENT}
  ${ACTIVITY_VIEWER_RESULT_FRAGMENT}
  ${ACTIVITY_DETAILS_SIDEBAR_FRAGMENT}
`;

const Container = createAsyncContainer(VideoDetails, (props) => {
  const {
    params: { courseSlug, slug },
    searchParams,
  } = props;

  const versionNumber = getVersion(searchParams.get('version'));
  const variables = {
    slug,
    courseSlug,
    postId: searchParams.get('post_id'),
    versionNumber,
    specificVersion: versionNumber ? null : 'CURRENT',
  };

  const loaders = {
    query: query(COURSE_VIDEO_QUERY, { variables }),
  };

  const selectors = {
    course: select(COURSE_VIDEO_QUERY, { variables }),
    domain: select(DOMAIN_QUERY, {}),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
