// @flow
import React, { useEffect } from 'react';
import { Button, Text, Modal, Link, View } from 'components/core';
import { useLocation } from 'libs/routing';
import { Form } from 'components/forms';
import { usePdfView } from 'utils/misc';
import { useDispatch } from 'libs/graphql';
import { createDict, Trans, useIntl } from 'libs/intl';
import { useToggleState, Cookies, Analytics } from 'libs/misc';
import { createCookieConsent } from './mutations';

const dict = createDict({
  we_use_cookies: 'We use Cookies',
  legal_note: 'Legal Note',
  privacy_policy: 'Privacy Policy',
  settings: 'Settings',
  accept: 'Accept',
  necessary: 'Necessary',
  necessary_cookies_text:
    'Necessary cookies are essential to provide our services. These cookies cannot be disabled.',
  statistics: 'Statistics',
  statistic_cookies_text:
    'Statistic cookies are used to collect information about the usage of our services and to analyze the traffic on our website. These cookies also include cookies of third party service Google Analytics.',
  back: 'Back',
  confirm_selection: 'Confirm selection',
});

const CONSENT_COOKIE = 'ckcs';

const ALWAYS_VIEWABLE_ROUTES = ['/terms-of-use', '/privacy-policy', '/legal-contact'];

function CookieConsent(): React$Node {
  const pdfView = usePdfView();

  const settings = useToggleState(false);
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();

  const cookie = Cookies.get(CONSENT_COOKIE);
  const modal = useToggleState(!cookie);

  const handleConsent = () => {
    Cookies.set(CONSENT_COOKIE, 'allow', 720);

    // Lazily activate google analytics and track pageview.
    Analytics.activate();
    Analytics.pageview({
      page_path: location.pathname + location.search,
    });

    dispatch(createCookieConsent(true));
  };

  const handleDissent = () => {
    Cookies.set(CONSENT_COOKIE, 'deny', 30);

    dispatch(createCookieConsent(false));
  };

  useEffect(() => {
    if (cookie !== 'allow') {
      return;
    }

    // Renew cookie consent cookie.
    Cookies.set(CONSENT_COOKIE, 'allow', 720);

    // Activate Google Analytics if cookie consent has already been accepted.
    Analytics.activate();
  }, []);

  // Return null if cookie consent was accepted earlier and for always viewable pages.
  if (__DEV__ || pdfView || !modal.on || ALWAYS_VIEWABLE_ROUTES.includes(location.pathname)) {
    return null;
  }

  return (
    <Modal visible={modal.on} onToggle={modal.toggle} backdrop="static" centered>
      <Form
        initialValues={{
          necessary: true,
          statistics: false,
        }}
        onSubmit={(values) => {
          if (values.statistics) {
            handleConsent();
          } else {
            handleDissent();
          }

          modal.setOff();
        }}
      >
        <Modal.Header styleName="ai-center">
          <Modal.Title>
            <Trans {...dict('we_use_cookies')} />
          </Modal.Title>
          <Link to="/legal-contact" external>
            <Trans {...dict('legal_note')} small />
          </Link>
        </Modal.Header>
        <Modal.Body>
          {intl.locale === 'en' && (
            <Text>
              This website is using cookies to enhance the learning experience and to analyze the
              usage of our services. More information can be found in our{' '}
              <Link to="/privacy-policy">privacy policy</Link>.
            </Text>
          )}
          {intl.locale === 'de' && (
            <Text>
              Diese Website nutzt Cookies, um das Lernerlebnis zu verbessern und Nutzungsdaten zu
              analysieren. Weitere Informationen können in unserer{' '}
              <Link to="/privacy-policy" external>
                Datenschutzerklärung
              </Link>{' '}
              gefunden werden.
            </Text>
          )}
          {settings.on && (
            <>
              <Form.Checkbox
                name="necessary"
                label={
                  <View>
                    <Trans {...dict('necessary')} />
                    <Trans {...dict('necessary_cookies_text')} small />
                  </View>
                }
                styleName="mt-3 mb-3"
                disabled
              />
              <Form.Checkbox
                name="statistics"
                label={
                  <View>
                    <Trans {...dict('statistics')} />
                    <Trans {...dict('statistic_cookies_text')} small />
                  </View>
                }
              />
            </>
          )}
        </Modal.Body>
        {!settings.on && (
          <Modal.Footer>
            <Button
              color="light"
              onPress={() => {
                settings.setOn();
              }}
              styleName="mr-3"
            >
              <Trans {...dict('settings')} />
            </Button>
            <Button
              color="primary"
              onPress={() => {
                handleConsent();

                modal.setOff();
              }}
            >
              <Trans {...dict('accept')} />
            </Button>
          </Modal.Footer>
        )}
        {settings.on && (
          <Modal.Footer styleName="jc-between">
            <Button
              color="light"
              onPress={() => {
                settings.setOff();
              }}
            >
              <Trans {...dict('back')} />
            </Button>
            <Form.Button type="submit" color="primary">
              <Trans {...dict('confirm_selection')} />
            </Form.Button>
          </Modal.Footer>
        )}
      </Form>
    </Modal>
  );
}

export default CookieConsent;
