// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const Account = {
  name: 'Account',
  bundle: () => import('./Account'),
};

const ACCOUNT_SETTINGS_QUERY = gql`
  query UserAccountQuery {
    viewer {
      name
      selfRegistered
      createdAt
      emails {
        email
        primary
        verifiedAt
      }
    }
  }
`;

const Container = createAsyncContainer(Account, () => {
  const loaders = {
    query: query(ACCOUNT_SETTINGS_QUERY),
  };

  const selectors = {
    viewer: select(ACCOUNT_SETTINGS_QUERY),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
