// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const ACTIVITY_VIEWER_RESULT_FRAGMENT: DocumentNode = gql`
  fragment ActivityViewerResultFragment on CourseActivity {
    viewerResult {
      versionNumber
    }
    # Use versions instead of version for now, because version is already used on query.
    versions {
      current
      versionNumber
    }
  }
`;
