// @flow
import { createContainer } from 'libs/container';
import { select, gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import Cloze from './Cloze';

export const CLOZE_EXAM_FRAGMENT: DocumentNode = gql`
  fragment ClozeExamFragment on Cloze {
    text
    gaps {
      key
    }
    viewerRunningExamSolution {
      gapValues {
        key
        value
      }
    }
  }
`;

const Container = createContainer(Cloze, (props) => {
  const selectors = {
    cloze: select(CLOZE_EXAM_FRAGMENT, {
      entry: ['Cloze', props.id],
    }),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
