// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { View } from 'components/core';
import { usePdfView } from 'utils/misc';

type Props = {
  children: React$Node,
  last?: boolean,
};

const styles = StyleSheet.create({
  container: css`
    border-bottom-width: 1px;
  `,
  containerNotPdf: css`
    @include media-breakpoint-up(lg) {
      border-color: $gray-300;
      padding-bottom: 2rem;
      margin-bottom: 3rem;
      margin-horizontal: -5rem;
      padding-horizontal: 5rem;
    }
    @include media-breakpoint-down(md) {
      border-color: $gray-300;
      margin-bottom: 1rem;
      margin-horizontal: -1rem;
      padding-horizontal: 1rem;
    }
  `,
  containerPdf: css`
    border-bottom-width: 0;
    margin-bottom: 2rem;
  `,
});

const TaskSection = React.forwardRef<Props, any>((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { children, last = false } = props;
  const viewPdf = usePdfView();

  return (
    <View
      style={[
        !last && styles.container,
        !last && !viewPdf && styles.containerNotPdf,
        viewPdf && styles.containerPdf,
      ]}
      ref={ref}
    >
      {children}
    </View>
  );
});

export default (TaskSection: React$AbstractComponent<Props, any>);
