// @flow
import React from 'react';
import { Platform } from 'react-native';
import FlexImage from './FlexImage';

type Props = {
  value: string,
};

function InlineMath({ value }: Props): React$Node {
  // SVG supported on web only
  const format = Platform.OS === 'web' ? 'svg' : 'png';

  return <FlexImage alt={value} url={`/math-api/${format}/${encodeURIComponent(value)}`} inline />;
}

export default InlineMath;
