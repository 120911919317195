// @flow
import React from 'react';
import { Domain } from 'libs/misc';
import NavigationAzubinet from './NavigationAzubinet';
import NavigationVerkehrserziehung from './NavigationVerkehrserziehung';
import NavigationDefault from './NavigationDefault';

type Props = {
  viewer: ?Entity<GQLUser>,
  domain: Entity<GQLDomain>,
  variant: ?'pages',
};

function Navigation({ viewer, domain, variant }: Props): React$Node {
  if (variant !== 'pages') {
    return <NavigationDefault viewer={viewer} domain={domain} />;
  }

  return Domain.select({
    'www.azubinet.de': <NavigationAzubinet viewer={viewer} domain={domain} />,
    'www.xn--fahrradprfung-4ob.de': <NavigationVerkehrserziehung viewer={viewer} domain={domain} />,
    '*': <NavigationDefault viewer={viewer} domain={domain} />,
  });
}

export default Navigation;
