// @flow
import React from 'react';
import { Text } from 'components/core';
import { Page } from 'components/layout';
import { useLocation } from 'libs/routing';
import ProductNav from './ProductNav';

type Props = {
  organization: ?Entity<GQLOrganization>,
};

const getTitle = (organization, state) => {
  if (organization) {
    return organization.product.name;
  }

  // Get product name from state if organization is not loaded yet.
  if (state?.productName) {
    return state?.productName;
  }

  return <Text>&nbsp;</Text>;
};

function ProductAside({ organization }: Props): React$Node {
  const { state } = useLocation();

  return (
    <>
      <Page.Header size={12}>
        <Page.Title>
          <Text bold>{getTitle(organization, state)}</Text>
        </Page.Title>
      </Page.Header>
      <Page.Aside size={3} styleName="mx-auto mb-3 mb-md-0">
        <ProductNav organization={organization} />
      </Page.Aside>
    </>
  );
}

export default ProductAside;
