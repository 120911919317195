// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query, selectByRoot } from 'libs/graphql';

const BlogList = {
  name: 'BlogList',
  bundle: () => import('./BlogList'),
};

const BLOG_LIST_QUERY = gql`
  query BlogListQuery {
    blogs {
      title
      slug
      createdAt
    }
  }
`;

const Container = createAsyncContainer(BlogList, () => {
  const loaders = {
    query: query(BLOG_LIST_QUERY),
  };

  const selectors = {
    blogs: select(BLOG_LIST_QUERY),
    viewer: selectByRoot('viewer'),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
