// @flow
import React from 'react';
import { Platform } from 'react-native';
import { createDict, Trans, useIntl } from 'libs/intl';
import { Form } from 'components/forms';
import { useNavigate } from 'libs/routing';
import { Alert, Card, Link, Button, View, Text } from 'components/core';
import { useDispatch } from 'libs/graphql';
import { Api, Domain, useDocumentTitle, useValueState, useToggleState } from 'libs/misc';
import { fetchViewer } from './queries';
import DomainPickerModal from './DomainPickerModal';
import AlternativeDomainsModal from './AlternativeDomainsModal';

type Props = {
  domain: Entity<GQLDomain>,
};

const dict = createDict({
  log_in: 'Log in',
  login_on_x: 'Login on {domain}',
  change_domain: 'Change domain',
  username_or_email: 'Username or Email',
  password: 'Password',
  forgot_password: 'Forgot password',
  dont_have_an_account: "Don't have an account?",
  register_now: 'Register now',
  error_invalid_credentials: 'The email address or the password is wrong.',
  error_user_inactive: 'The account is not active.',
  error_user_unverified:
    'The account is not verified. If you did not receive a verification email or if it expired, you can resend one.',
  error_login_failed: 'Login failed.',
  error_maybe_wrong_domain:
    'The password is wrong. Do you really want to login on this domain? Your email address is registered on other domains as well:',
  error_wrong_domain: 'The email address is not registered on this domain, but on other domains:',
  show_domains: 'Show domains',
  error_no_connection: 'Something went wrong. Do you have a working internet connection?',
  resend_verification_email: 'Resend verification email',
});

function Login({ domain }: Props): React$Node {
  useDocumentTitle(dict('log_in'));

  const domainPickerModal = useToggleState(false);
  const alternativeDomainsModal = useToggleState(false);
  const alternativeDomains = useValueState(null);
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const error = useValueState(null);

  return (
    <>
      {Platform.OS !== 'web' && (
        <Alert color="info" styleName="ai-center">
          <Trans {...dict('login_on_x', { domain: Domain.get() })} />
          <Link
            onPress={() => {
              domainPickerModal.setOn();
            }}
          >
            <Trans {...dict('change_domain')} />
          </Link>
        </Alert>
      )}
      {error.value && (
        <Alert color={error.value === 'error_user_unverified' ? 'warning' : 'danger'}>
          <Trans {...dict(error.value)} />
          {(error.value === 'error_wrong_domain' || error.value === 'error_maybe_wrong_domain') && (
            <Link
              onPress={() => {
                alternativeDomainsModal.setOn();
              }}
              styleName="as-center mt-3"
            >
              <Trans {...dict('show_domains')} />
            </Link>
          )}
          {error.value === 'error_user_unverified' && (
            <Button to="/resend-verification" color="warning" styleName="mt-3">
              <Trans {...dict('resend_verification_email')} />
            </Button>
          )}
        </Alert>
      )}
      <Card>
        <Card.Body>
          <Form
            initialValues={{ username: null, password: null }}
            onSubmit={(values, { setSubmitting }) => {
              const body = new FormData();

              body.append('username', values.username);
              body.append('password', values.password);

              Api.post('/api/auth/login', { body }).then(
                () => {
                  dispatch(fetchViewer()).then(() => {
                    navigate('/dashboard');
                  });
                },
                (err) => {
                  // Login found on other domain.
                  if (err.name === 'HttpError' && err.data.error.type === 'WRONG_DOMAIN') {
                    Api.post('/api/auth/find-domains', { body }).then(
                      (domains) => {
                        const altDomains = domains.filter((d) => d !== Domain.get());
                        alternativeDomains.set(altDomains);

                        if (altDomains.length === domains.length) {
                          error.set('error_wrong_domain');
                        } else {
                          error.set('error_maybe_wrong_domain');
                        }
                        setSubmitting(false);
                      },
                      () => {
                        // Mostlikely network error
                        error.set('error_no_connection');
                        setSubmitting(false);
                      },
                    );

                    return;
                  }

                  // Other errors.
                  if (err.name === 'HttpError') {
                    if (err.data.error.type === 'INVALID_CREDENTIALS') {
                      error.set('error_invalid_credentials');
                    } else if (err.data.error.type === 'USER_INACTIVE') {
                      error.set('error_user_inactive');
                    } else if (err.data.error.type === 'USER_UNVERIFIED') {
                      error.set('error_user_unverified');
                    } else {
                      error.set('error_login_failed');
                    }
                  } else {
                    // Mostlikely network error
                    error.set('error_no_connection');
                  }

                  setSubmitting(false);
                },
              );
            }}
          >
            <Form.Input
              name="username"
              title={dict('username_or_email')}
              placeholder="john.doe@example.com"
              inputMode="email"
              autoCorrect={false}
              autoCapitalize="none"
            />
            <Form.Input name="password" secureTextEntry title={{ ...dict('password') }} />
            <Link to="/forgot-password" styleName="mb-3 as-end">
              <Trans {...dict('forgot_password')} />
            </Link>
            <Form.Button type="submit" size="lg">
              <Trans {...dict('log_in')} />
            </Form.Button>
          </Form>
          <Text styleName="mt-3" small>
            {intl.locale === 'en' && (
              <>
                When logging in you accept the <Link to="/terms-of-use">Terms of Use</Link> and the{' '}
                <Link to="/privacy-policy">Privacy Policy</Link> regulations.
              </>
            )}
            {intl.locale === 'de' && (
              <>
                Mit der Anmeldung akzeptierst du die{' '}
                <Link to="/terms-of-use">Nutzungsbedingungen</Link> und die{' '}
                <Link to="/privacy-policy">Datenschutzbestimmungen</Link>.
              </>
            )}
          </Text>
        </Card.Body>
        {domain.permissions.usersCanSelfRegister && (
          <Card.Footer styleName="p-3">
            <View styleName="flex-md-row">
              <Trans {...dict('dont_have_an_account')} styleName="mr-2" />
              <Link to="/register">
                <Trans {...dict('register_now')} />
              </Link>
            </View>
          </Card.Footer>
        )}
      </Card>
      {domainPickerModal.on && <DomainPickerModal modal={domainPickerModal} />}
      {alternativeDomainsModal.on && (
        <AlternativeDomainsModal
          modal={alternativeDomainsModal}
          domains={alternativeDomains.value}
        />
      )}
    </>
  );
}

export default Login;
