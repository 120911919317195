// @flow
import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { Icon } from 'components/common';
import { ListGroup, Card, Button, Paragraph } from 'components/core';
import { useToggleState } from 'libs/misc';
import { getVisibleItems } from 'utils/misc';
import Announcement from './Announcement';
import AnnouncementForm from './AnnouncementForm';

type Props = {
  announceable: Entity<GQLOrganization> | Entity<GQLOrganizationTeam> | Entity<GQLCourse>,
  announceableId: Id,
  announceableType: string,
  viewer: Entity<GQLUser>,
  viewerCanCreateAnnouncement?: ?boolean,
  isDashboard?: ?boolean,
};

const defaultProps = {
  viewerCanCreateAnnouncement: false,
  isDashboard: false,
};

const dict = createDict({
  news: 'News',
  show_more: 'Show More',
  no_announcements_yet: 'No announcements yet.',
});

function Announcements({
  announceable,
  announceableId,
  announceableType,
  viewer,
  viewerCanCreateAnnouncement,
  isDashboard,
}: Props): React$Node {
  const expanded = useToggleState(false);

  const { announcements, announcement } = announceable;
  const visibleAnnouncements = expanded.on
    ? announcements
    : getVisibleItems(announcements, announcement, [], 3);

  return (
    <Card styleName="mb-3">
      <Card.Header styleName="flex-row ai-center">
        <Icon glyph="bell-alt" styleName="mr-2" />
        <Trans {...dict('news')} />
      </Card.Header>
      <Card.Body styleName="p-0">
        <ListGroup flush>
          {viewerCanCreateAnnouncement && (
            <ListGroup.Item styleName="p-3">
              <AnnouncementForm
                announceableId={announceableId}
                announceableType={announceableType}
              />
            </ListGroup.Item>
          )}
          {announcements.length === 0 && (
            <ListGroup.Item styleName="jc-center ai-center">
              <Paragraph styleName="m-5" align="center">
                <Trans color="muted" {...dict('no_announcements_yet')} />
              </Paragraph>
            </ListGroup.Item>
          )}
          {announcements.length > 0 &&
            visibleAnnouncements.map((visibleAnnouncement) => (
              <ListGroup.Item key={visibleAnnouncement.id} styleName="p-3">
                <Announcement
                  announcement={visibleAnnouncement}
                  announceableId={announceableId}
                  announceableType={announceableType}
                  viewer={viewer}
                  isDashboard={isDashboard}
                />
              </ListGroup.Item>
            ))}
          {!expanded.on && announcements.length > visibleAnnouncements.length && (
            <ListGroup.Item>
              <Button color="link" onPress={() => expanded.setOn()}>
                <Trans {...dict('show_more')} />
              </Button>
            </ListGroup.Item>
          )}
        </ListGroup>
      </Card.Body>
    </Card>
  );
}

Announcements.defaultProps = defaultProps;

export default Announcements;
