// @flow
import React, { useState } from 'react';
import { createDict, Trans } from 'libs/intl';
import { Api, useAlerts } from 'libs/misc';

type FileUploadFunction = (file: any) => Promise<any>;

type FileUploadState = {
  uploading: boolean,
  response: any,
  error: any,
};

const dict = createDict('filesystem', {
  file_upload_failed: 'File upload failed.',
});

export default function useUpload(): [FileUploadFunction, FileUploadState] {
  const [uploading, setUploading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const alerts = useAlerts();

  const handleUpload = (file) => {
    setError(null);
    setUploading(true);

    const body = new FormData();
    body.append('file', file);

    const promise = Api.post('/files/upload', { body }).then(({ data: { id: fileId } }) => fileId);

    promise.then(
      (res) => {
        setUploading(false);
        setResponse(res);
      },
      (err) => {
        alerts.add({
          color: 'danger',
          content: <Trans {...dict('file_upload_failed')} />,
        });
        setUploading(false);
        setError(err);
      },
    );

    return promise;
  };

  return [handleUpload, { uploading, response, error }];
}
