// @flow
import React from 'react';
import { StyleSheet, css, colorContrast } from 'libs/ui';
import { NavbarLink } from 'components/navbar';
import { Icon } from 'components/common';
import { View, Text, Dropdown } from 'components/core';
import NotificationList from './NotificationList';

type Props = {
  viewer: Entity<GQLUser>,
};

const styles = StyleSheet.create({
  count: css`
    margin-top: -0.125rem;
    margin-bottom: 0.875rem;
    margin-left: 0.25rem;
    background-color: $brand-secondary;
    padding: $badge-padding-y $badge-padding-x;
    border-radius: $badge-border-radius;
  `,
  countText: css`
    font-size: $badge-font-size;
    font-weight: $badge-font-weight;
    line-height: $badge-font-size * 1;
    color: ${colorContrast((t) => t['brand-secondary'])};
    text-align: center;
  `,
  dropdownIcon: css`
    color: ${colorContrast((t) => t['brand-primary'])};
  `,
});

function NotificationsDropdown({ viewer }: Props): React$Node {
  return (
    <Dropdown display="static">
      <NavbarLink toggle={Dropdown} caret={false}>
        <Icon glyph="bell-alt" style={styles.dropdownIcon} />
        <View style={styles.count}>
          <Text style={styles.countText}>{viewer.unreadNotificationsCount}</Text>
        </View>
      </NavbarLink>
      <NotificationList />
    </Dropdown>
  );
}

export default NotificationsDropdown;
