// @flow

export default function reference() {
  const { Parser } = this;
  const tokenizers = Parser.prototype.inlineTokenizers;

  // Disable reference tokenizer, because of some unwanted eatings, e.g.
  // "[test]" should not be a reference.
  function tokenizeReference() {
    return undefined;
  }

  tokenizeReference.locator = () => -1;

  tokenizers.reference = tokenizeReference;
}
