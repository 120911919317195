// @flow
import { useContext } from 'react';
import {
  useLocation,
  useResolvedPath,
  UNSAFE_NavigationContext as NavigationContext,
} from 'react-router';

const optional = (condition, value) => (condition ? value : undefined);

export default function useActive(props: Object): Object {
  const { to, external, end, caseSensitive, active, ...restProps } = props;

  if (!to || external) {
    return props;
  }

  const path = useResolvedPath(to, { relative: restProps.relative });
  const location = useLocation();
  const { navigator } = useContext(NavigationContext);

  let toPathname = navigator.encodeLocation
    ? navigator.encodeLocation(path).pathname
    : path.pathname;
  let locationPathname = location.pathname;

  if (!caseSensitive) {
    locationPathname = locationPathname.toLowerCase();
    toPathname = toPathname.toLowerCase();
  }

  const routeActive =
    locationPathname === toPathname ||
    (!end &&
      locationPathname.startsWith(toPathname) &&
      locationPathname.charAt(toPathname.length) === '/');

  const computedActive = active === undefined ? routeActive : active;

  return {
    ...restProps,
    to,
    external,
    active: computedActive,
    ...optional(active === undefined && routeActive, { 'aria-current': 'page' }),
  };
}
