// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';
import { DOMAIN_QUERY } from 'libs/foundation/queries';

const ViewerReports = {
  name: 'ViewerReports',
  bundle: () => import('./ViewerReports'),
};

const VIEVER_REPORTS_QUERY = gql`
  query ReportListQuery {
    viewer {
      organizationMemberships {
        organization {
          id
        }
      }
      domain {
        id
      }
      name
      avatar {
        file {
          name
          extension
          info {
            image {
              height
              width
            }
          }
        }
      }
      activeReportCollection {
        type
        profession
        startDate
        endDate
        reportsStartDate
        hoursPerWeek
        subject
        trainer
        address
        company
        chamber
        school
        reports {
          beginAt
          endAt
          completed
          approved
        }
      }
    }
  }
`;

const Container = createAsyncContainer(ViewerReports, () => {
  const loaders = {
    query: query(VIEVER_REPORTS_QUERY, {}),
  };

  const selectors = {
    viewer: select(VIEVER_REPORTS_QUERY, {}),
    domain: select(DOMAIN_QUERY, {}),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
