// @flow
import React from 'react';
import { View, Image } from 'components/core';
import { Icon } from 'components/common';
import { StyleSheet, css, useMedia } from 'libs/ui';
import BrandHeading from './BrandHeading';
import BrandText from './BrandText';
import HighlightButton from './HighlightButton';

type Props = {
  to: string,
  image: string,
  title: string,
  author: string,
  accessibility: string,
};

const styles = StyleSheet.create({
  heading: css`
    text-align: left;
    text-transform: none;
  `,
  text: css`
    line-height: 1.5rem;
    font-weight: normal;
    letter-spacing: 0;
    text-transform: none;
    font-size: 1rem;
    margin-top: 1rem;
    text-align: left;
  `,
  imageDesktop: css`
    height: 9rem;
    width: 9rem;
    margin: -1.0625rem;
    margin-right: 1.0625rem;
    border-radius: 0.1875rem 0 0 0.1875rem;
  `,
});

function FeaturedCourse(props: Props): React$Node {
  const { to, image, title, author, accessibility } = props;
  const media = useMedia();

  return (
    <HighlightButton to={to}>
      {media.up('md') && <Image source={image} style={styles.imageDesktop} />}
      <View styleName="flex-shrink-1">
        <BrandHeading size={4} style={styles.heading} numberOfLines={1}>
          {title}
        </BrandHeading>
        <BrandText style={styles.text} numberOfLines={1}>
          <Icon glyph="user" styleName="mr-2" />
          {author}
        </BrandText>
        <BrandText style={styles.text} numberOfLines={1}>
          <Icon glyph="lock-open-alt" styleName="mr-2" />
          {accessibility}
        </BrandText>
      </View>
    </HighlightButton>
  );
}

export default FeaturedCourse;
