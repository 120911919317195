// @flow
import { createAsyncContainer } from 'libs/container';

const RewardSystem = {
  name: 'RewardSystem',
  bundle: () => import('./RewardSystem'),
};

const Container = createAsyncContainer(RewardSystem, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
