// @flow
import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { View } from 'components/core';
import { Form } from 'components/forms';
import { useDispatch } from 'libs/graphql';
import { StyleSheet, css } from 'libs/ui';
import { useValueState, useToggleState } from 'libs/misc';
import { fetchCourseVoucher } from './queries';
import AccessAllowedVoucherModal from './AccessAllowedVoucherModal';
import getCoursesFromVoucherResult from './getCoursesFromVoucherResult';

type Props = {
  course: Entity<GQLCourse>,
  viewer: Entity<GQLUser>,
};

const dict = createDict({
  if_you_have_a_valid_code_please_enter_to_join:
    'If you have a valid access code, you can enter it below to join the course.',
  access_code: 'Access code',
  enter_access_code: 'Enter access code...',
  submit_access_code: 'Submit access code',
  code_is_invalid_or_being_used: 'This access code is either invalid or being used before.',
});

const styles = StyleSheet.create({
  font: css`
    color: $gray-900;
  `,
});

const getVoucherFromVoucherResult = (code: string, data: Object) => {
  const { link: courseVoucherLink } = data.roots[`courseVoucher({"code":"${code}"})`];
  return data.entities[courseVoucherLink[0]][courseVoucherLink[1]];
};

function AccessAllowedVoucher({ course, viewer }: Props): React$Node {
  const dispatch = useDispatch();
  const error = useToggleState(false);

  // Not the best solution to store the data of the fetchCourseVoucher query in
  // a local state. The next version of react-transporter should provide a more
  // elegant solution.
  const voucherResult = useValueState(null);

  return (
    <>
      <Trans
        {...dict('if_you_have_a_valid_code_please_enter_to_join')}
        styleName="mb-3"
        style={styles.font}
      />
      <Form
        initialValues={{
          code: null,
        }}
        onSubmit={(values, { setSubmitting }) => {
          error.setOff();

          dispatch(fetchCourseVoucher(values.code)).then(
            (data) => {
              const voucher = getVoucherFromVoucherResult(values.code, data);

              if (voucher.redeemed) {
                error.setOn();
              } else {
                const voucherCourses = getCoursesFromVoucherResult(values.code, data);

                if (voucherCourses.some((voucherCourse) => voucherCourse.id === course.id)) {
                  voucherResult.set({
                    code: values.code,
                    data,
                  });
                } else {
                  error.setOn();
                }
              }

              setSubmitting(false);
            },
            () => {
              error.setOn();
              setSubmitting(false);
            },
          );
        }}
        styleName="flex-md-row mb-n3"
      >
        <View styleName="flex-grow-1">
          <Form.Input
            name="code"
            niceName={dict('access_code')}
            placeholder={dict('enter_access_code')}
            autoComplete="off"
          />
        </View>
        <Form.Button type="submit" styleName="as-center mb-3 ml-md-3">
          <Trans {...dict('submit_access_code')} />
        </Form.Button>
      </Form>
      {error.on && (
        <Trans color="danger" {...dict('code_is_invalid_or_being_used')} styleName="mt-3" />
      )}
      <AccessAllowedVoucherModal course={course} viewer={viewer} voucherResult={voucherResult} />
    </>
  );
}

export default AccessAllowedVoucher;
