// @flow

import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { Icon } from 'components/common';
import { View, Button, Blockquote } from 'components/core';
import { useParams, useNavigate } from 'libs/routing';
import { StyleSheet, css } from 'libs/ui';
import { useMutation } from 'libs/graphql';
import { useAlerts } from 'libs/misc';
import { Markdown } from 'components/markdown';
import { QuizCardAnswer } from 'components/quizzes';
import { putQuizCardResult } from '../mutations';
import type { QuizState, CurrentQuizCardState } from '../useQuizState';

type Props = {
  choiceCard: Entity<GQLChoiceCard>,
  quiz: Entity<GQLQuiz>,
  answer: Entity<GQLChoiceCardAnswer>,
  quizState: QuizState,
  currentCardState: CurrentQuizCardState,
};

const dict = createDict({
  something_went_wrong_please_open_the_quiz_again:
    'Something went wrong. Please open the quiz again.',
});

const styles = StyleSheet.create({
  touchable: css`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-color: transparent;
    padding-vertical: 0.5rem;
    margin-vertical: -0.625rem;
    border-color: transparent;
    border-width: 0.125rem;

    &:active {
      background-color: $gray-200;
      border-color: transparent;
    }

    @include media-breakpoint-down(sm) {
      border-radius: 0;
      padding-horizontal: 0.875rem;
      margin-horizontal: -1rem;

      &:active {
        border-radius: 0;
      }
    }
    @include media-breakpoint-up(lg) {
      border-radius: 0.375rem;
      padding-horizontal: 0.75rem;
      margin-horizontal: -0.875rem;

      &:active {
        border-radius: 0.375rem;
      }
    }
  `,
  checkbox: css`
    margin-left: 0.75rem;
    width: 1.625rem;
    height: 1.625rem;
    justify-content: center;
    align-items: center;
    border-width: 0.125rem;
    border-color: $gray-500;
    border-radius: 0.125rem;
  `,
  checkboxCorrect: css`
    background-color: $success;
    border-color: $success;
  `,
  checkboxText: css`
    line-height: 1rem;
  `,
  checkboxTextSelected: css`
    color: $link-color;
  `,
  checkboxTextSuccess: css`
    color: $success;
  `,
  checkboxTextDanger: css`
    color: $danger;
  `,
  checkboxTextCorrect: css`
    color: $white;
  `,
  textReset: css`
    line-height: 1.5rem;
    text-transform: none;
    letter-spacing: 0;
    font-size: 1rem;
    font-weight: normal;
    // Workaround for native fonts
    @include platform(ios) {
      font-family: 'Roboto';
    }
    @include platform(android) {
      font-family: 'Roboto';
    }
    text-align: left;
  `,
  explanation: css`
    margin-top: 0.5rem;
    margin-bottom: 0;
    border-left-width: 0.125rem;
    padding-horizontal: 0.5rem;
  `,
  explanationText: css`
    font-size: $small-font-size;
    line-height: $small-font-size * $line-height-base;
  `,
});

function ChoiceCardSingleOption({
  choiceCard,
  quiz,
  answer,
  quizState,
  currentCardState,
}: Props): React$Node {
  const mutation = useMutation();
  const alerts = useAlerts();
  const params = useParams();
  const navigate = useNavigate();

  const userAnswer = currentCardState.answers.find((value) => value.answerId === answer.id);

  const handleMultipleChoicePress = () => {
    const correctAnswersCount = choiceCard.answers.filter((value) => value.correct).length;
    const correctUserAnswersCount = currentCardState.answers.filter(
      (value) => value.correct,
    ).length;

    let isAnswerCorrect = false;
    let areAnswersComplete = false;

    if (answer.correct) {
      if (!userAnswer) {
        if (
          !currentCardState.answers.some((value) => !value.correct) &&
          currentCardState.answers.length + 1 === correctAnswersCount
        ) {
          isAnswerCorrect = true;
        }
        areAnswersComplete = true;

        quizState.addAnswer({
          correct: true,
          answerId: answer.id,
        });
      } else {
        areAnswersComplete = currentCardState.answers.length - 1 !== 0;

        quizState.removeAnswer(answer.id);
      }
    } else if (!userAnswer) {
      areAnswersComplete = true;

      quizState.addAnswer({
        correct: false,
        answerId: answer.id,
      });
    } else {
      areAnswersComplete = currentCardState.answers.length - 1 !== 0;

      if (
        correctUserAnswersCount === correctAnswersCount &&
        currentCardState.answers.length - 1 === correctAnswersCount
      ) {
        isAnswerCorrect = true;
      }

      quizState.removeAnswer(answer.id);
    }

    quizState.setCurrentCardResult({
      correct: isAnswerCorrect,
      areAnswersComplete,
    });
  };

  const handleSingleChoicePress = () => {
    // block action if loading
    if (mutation.loading) {
      return;
    }

    quizState.setCurrentCardResult({
      correct: answer.correct,
      areAnswersComplete: true,
    });

    quizState.addAnswer({
      correct: answer.correct,
      answerId: answer.id,
    });
    mutation
      .dispatch(putQuizCardResult(quiz, choiceCard, answer.correct, quizState.repeatMode))
      .then(
        () => {
          quizState.flip();
        },
        (error) => {
          if (error.data.errors[0].extensions.code) {
            alerts.add({
              color: 'info',
              content: <Trans {...dict('something_went_wrong_please_open_the_quiz_again')} />,
            });

            navigate(`/courses/${params.courseSlug}/cards`);
          }
        },
      )
      .catch(() => {});
  };

  return (
    <>
      {!currentCardState.flipped ? (
        <Button
          onPress={choiceCard.single ? handleSingleChoicePress : handleMultipleChoicePress}
          color="light"
          style={styles.touchable}
        >
          <Markdown source={answer.text} allowLinkedImages={false} textStyle={styles.textReset} />
          <View style={styles.checkbox}>
            {!choiceCard.single && userAnswer && (
              <Icon glyph="ok" style={styles.checkboxTextSelected} />
            )}
          </View>
        </Button>
      ) : (
        <QuizCardAnswer
          isCorrect={userAnswer ? answer.correct && currentCardState.correct : undefined}
          styleName="flex-row flex-grow-1 flex-shrink-1 ai-center jc-between"
        >
          <View styleName="flex-grow-1 flex-shrink-1">
            <Markdown source={answer.text} allowLinkedImages={false} />
            {answer.explanation && (
              <Blockquote style={styles.explanation}>
                <Markdown source={answer.explanation} textStyle={styles.explanationText} />
              </Blockquote>
            )}
          </View>
          <View
            style={[
              styles.checkbox,
              answer.correct && currentCardState.correct && styles.checkboxCorrect,
            ]}
          >
            <Icon
              glyph={answer.correct ? 'ok' : 'cancel'}
              style={[
                styles.checkboxText,
                answer.correct ? styles.checkboxTextSuccess : styles.checkboxTextDanger,
                answer.correct && currentCardState.correct && styles.checkboxTextCorrect,
              ]}
            />
          </View>
        </QuizCardAnswer>
      )}
    </>
  );
}

export default ChoiceCardSingleOption;
