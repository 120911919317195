// @flow
import React from 'react';
import {
  View,
  Text,
  Container,
  Button,
  Paragraph,
  Image,
  Heading,
  ImageBackground,
  Dropdown,
  Link,
} from 'components/core';
import { Video } from 'components/common';
import { useDocumentTitle, useToggleState } from 'libs/misc';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { Section, BrandHeading, BrandLargeText } from 'components/pages';
import FeaturesBoxContent from './FeaturesBoxContent';
import TargetGroupsDesktop from './TargetGroupsDesktop';
import TargetGroupsMobile from './TargetGroupsMobile';
import VogelSpotInfoTrainersBox from './VogelSpotInfoTrainersBox';

type Props = {
  viewer: Entity<GQLUser>,
};

const styles = StyleSheet.create({
  backgroundWhite: css`
    background-color: #fff;
  `,
  backgroundGray: css`
    justify-content: center;
    align-items: center;
    background-color: #f3f5f6;
    margin-vertical: 1rem;
  `,
  vogelSpotLogo: css`
    width: 288px;
    height: 98px;
    margin-top: 3.5rem;
  `,
  backgroundGreen: css`
    justify-content: center;
    align-items: center;
    background-color: #66c430;
  `,
  imageBackgroundDesktop: css`
    width: 100%;
    height: 510px;
  `,
  imageBackgroundMobile: css`
    width: 100%;
    height: 211px;
  `,
  icon: css`
    color: $brand-primary;
    margin-right: 0.5rem;
  `,
  videoDesktop: css`
    justify-content: center;
    align-items: center;
    width: 820px;
    margin-top: 4rem;
  `,
  videoMobile: css`
    width: 320px;
    max-height: 211px;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  `,
  logoVogel: css`
    width: 120px;
    height: 43px;
  `,
  NeuBadgeDesktop: css`
    width: 4rem;
    height: 4rem;
    margin: -0.5rem 1rem -3.5rem 0rem;
  `,
  NeuBadgeMobile: css`
    width: 3rem;
    height: 3rem;
    margin: -1rem 1.5rem -1rem 0rem;
  `,
});

function VogelSPOT({ viewer }: Props): React$Node {
  useDocumentTitle('VogelSPOT');
  const bkf = useToggleState(false);
  const hazmat = useToggleState(false);
  const haulage = useToggleState(false);
  const taxi = useToggleState(false);
  const spedi = useToggleState(true);
  const media = useMedia();

  return (
    <>
      <View style={styles.backgroundWhite}>
        <Container styleName="jc-center ai-center">
          <Image
            source="/img/organizations/vogel/vogelspot_logo.png"
            label="vogelspot"
            style={styles.vogelSpotLogo}
          />
          <View styleName="my-4 px-3 ai-center jc-center">
            <BrandHeading size={3}>
              Willkommen bei der Online-Prüfungsvorbereitung für Berufe im Verkehrswesen.
            </BrandHeading>
          </View>
        </Container>
      </View>
      {bkf.on && (
        <ImageBackground
          source="/img/organizations/vogel/home-vogelspot.jpg"
          style={media.up('md') ? styles.imageBackgroundDesktop : styles.imageBackgroundMobile}
        />
      )}
      {hazmat.on && (
        <ImageBackground
          source="/img/organizations/vogel/home-vogelspot-hazmat.jpg"
          style={media.up('md') ? styles.imageBackgroundDesktop : styles.imageBackgroundMobile}
        />
      )}
      {haulage.on && (
        <ImageBackground
          source="/img/organizations/vogel/home-vogelspot-haulage.jpg"
          style={media.up('md') ? styles.imageBackgroundDesktop : styles.imageBackgroundMobile}
        />
      )}
      {taxi.on && (
        <ImageBackground
          source="/img/organizations/vogel/home-vogelspot-taxi.jpg"
          style={media.up('md') ? styles.imageBackgroundDesktop : styles.imageBackgroundMobile}
        />
      )}
      {spedi.on && (
        <ImageBackground
          source="/img/organizations/vogel/home-vogelspot-spedi.jpg"
          style={media.up('md') ? styles.imageBackgroundDesktop : styles.imageBackgroundMobile}
        />
      )}
      <View style={styles.backgroundGreen}>
        <Container>
          {media.up('md') && (
            <TargetGroupsDesktop
              bkf={bkf}
              hazmat={hazmat}
              haulage={haulage}
              taxi={taxi}
              spedi={spedi}
            />
          )}
          {media.down('sm') && (
            <TargetGroupsMobile
              bkf={bkf}
              hazmat={hazmat}
              haulage={haulage}
              taxi={taxi}
              spedi={spedi}
            />
          )}
        </Container>
      </View>
      <Container>
        <View styleName="flex-row flex-wrap p-md-3 py-3 jc-center ai-center">
          <Link
            onPress={() => {
              bkf.setOn();
              hazmat.setOff();
              haulage.setOff();
              taxi.setOff();
              spedi.setOff();
            }}
          >
            <Text bold={bkf.on}>BKF-Ausbildung</Text>
          </Link>
          <Text> &middot; </Text>
          <Link
            onPress={() => {
              bkf.setOff();
              hazmat.setOn();
              haulage.setOff();
              taxi.setOff();
              spedi.setOff();
            }}
          >
            <Text bold={hazmat.on}>Gefahrgut</Text>
          </Link>
          <Text> &middot; </Text>
          <Link
            onPress={() => {
              bkf.setOff();
              hazmat.setOff();
              haulage.setOn();
              taxi.setOff();
              spedi.setOff();
            }}
          >
            <Text bold={haulage.on}>Fachkunde Güterkraftverkehr</Text>
          </Link>
          <Text> &middot; </Text>
          <Link
            onPress={() => {
              bkf.setOff();
              hazmat.setOff();
              haulage.setOff();
              taxi.setOn();
              spedi.setOff();
            }}
          >
            <Text bold={taxi.on}>Fachkunde Taxi</Text>
          </Link>
          <Text> &middot; </Text>
          <Link
            onPress={() => {
              bkf.setOff();
              hazmat.setOff();
              haulage.setOff();
              taxi.setOff();
              spedi.setOn();
            }}
          >
            <Text bold={spedi.on}>Abschlussprüfung Speditionskaufleute</Text>
          </Link>
        </View>
      </Container>
      {bkf.on && (
        <>
          <Section color="white">
            <Container styleName="ai-end">
              <Text small color="muted" styleName="mt-n5 mb-3 px-2 px-md-0">
                Foto: © MarioGuti / iStock
              </Text>
            </Container>
            <Container styleName="mt-3">
              <View styleName="px-3 px-md-0">
                <View styleName="my-3 mb-md-4 flex-md-row flex-shrink-0 jc-between ai-center">
                  <Heading size={3} style={{ color: '#13573b' }}>
                    Das Prüfungstraining für Berufskraftfahrer
                  </Heading>
                </View>
                <Paragraph>
                  <BrandLargeText>
                    Mit VogelSPOT trainieren Sie{' '}
                    <b>online und mobil auf die IHK-Abschlussprüfung für Berufskraftfahrer!</b> Über
                    1000 Fragen erwarten den Prüfling - das sind deutlich mehr als im gedruckten
                    Prüfungstest. Wie in der echten IHK-Prüfung werden Single Choice- (“gebundene”),
                    offene (“ungebundene”) Fragen und Fallstudien durchlaufen. Somit wird eine
                    optimale Prüfungsvorbereitung gewährleistet!
                  </BrandLargeText>
                </Paragraph>
                <Paragraph>
                  <BrandLargeText>
                    Als Prüfungskandidat können Sie bei VogelSPOT <b>in verschiedenen Bereichen</b>{' '}
                    trainieren und Ihre Kenntnisse vertiefen:
                  </BrandLargeText>
                </Paragraph>
              </View>
            </Container>
          </Section>
          <Section color="gray">
            <Container styleName="p-0">
              <FeaturesBoxContent />
            </Container>
          </Section>
          <Section color="white">
            <Container>
              <View styleName="m-3 mx-md-0">
                <Paragraph>
                  <BrandLargeText>
                    Die Prüfungsfragen bei VogelSPOT wurden vom Fachautorenteam des Verlags Heinrich
                    Vogel entwickelt. Sie berücksichtigen aktuelle Prüfungsschwerpunkte und
                    Entwicklungen, sind aber nicht identisch mit den offiziellen Prüfungsfragen.
                  </BrandLargeText>
                </Paragraph>
                <Paragraph>
                  <BrandLargeText>
                    Praktisch! VogelSPOT kann in Kombination mit allen Lehrwerken des Verlags
                    verwendet werden!
                  </BrandLargeText>
                </Paragraph>
              </View>
              <View styleName="flex-md-row py-3 px-3 jc-center ai-center">
                {viewer && (
                  <Button to="/courses/nnAFoQPvQx" size="lg" styleName="ml-md-1 mb-2 mb-md-0">
                    {media.up('md') && <Text>Zum Prüfungstraining</Text>}
                    {media.down('sm') && <Text>Prüfungstraining</Text>}
                  </Button>
                )}
                {!viewer && (
                  <Button to="/login" size="lg" styleName="ml-md-1 mb-2 mb-md-0">
                    {media.up('md') && <Text>Zum Prüfungstraining</Text>}
                    {media.down('sm') && <Text>Prüfungstraining</Text>}
                  </Button>
                )}
                <Button
                  to="https://www.heinrich-vogel-shop.de/shop/aus-und-weiterbildung/berufskraftfahrer-ausbildung-fif/vogelspot-fuer-berufskraftfahrer-lkw-omnibus.html"
                  external
                  color="light"
                  size="lg"
                  styleName="mr-md-1"
                >
                  <Text>Zugang erwerben</Text>
                </Button>
              </View>
            </Container>
          </Section>
        </>
      )}
      {hazmat.on && (
        <>
          <Section color="white">
            <Container styleName="ai-end">
              <Text small color="muted" styleName="mt-n5 mb-3 px-2 px-md-0">
                Foto: © Bjoern Wylezich / stock.adobe.com
              </Text>
            </Container>
            <Container styleName="mt-3">
              <View styleName="px-3 px-md-0">
                <View styleName="my-3 mb-md-4 flex-md-row flex-shrink-0 jc-between ai-center">
                  <Heading size={3} style={{ color: '#13573b' }}>
                    Das Prüfungstraining für die ADR-Schulungsbescheinigung
                  </Heading>
                </View>
                <Paragraph>
                  <BrandLargeText>
                    Die GGVSEB und das ADR schreiben vor, dass grundsätzlich nur solche Personen
                    Fahrzeuge mit gefährlichen Gütern führen dürfen, die an einer Schulung
                    teilgenommen haben und eine Prüfung über die besonderen Anforderungen bei
                    Gefahrguttransporten bestanden haben. Mit VogelSPOT trainieren Sie{' '}
                    <b>
                      online und mobil auf die Erstschulung für Gefahrgutfahrer zum Erwerb der
                      notwendigen ADR-Schulungsbescheinigung!
                    </b>
                  </BrandLargeText>
                </Paragraph>
                <Paragraph>
                  <BrandLargeText>
                    Als Prüfungskandidat können Sie bei VogelSPOT <b>in verschiedenen Bereichen</b>{' '}
                    trainieren und Ihre Kenntnisse vertiefen:
                  </BrandLargeText>
                </Paragraph>
              </View>
            </Container>
          </Section>
          <Section color="gray">
            <Container styleName="p-0">
              <FeaturesBoxContent />
            </Container>
          </Section>
          <Section color="white">
            <Container>
              <View styleName="m-3 mx-md-0">
                <Paragraph>
                  <BrandLargeText>
                    Die Prüfungsfragen bei VogelSPOT wurden vom Fachautorenteam des Verlags Heinrich
                    Vogel entwickelt. Sie berücksichtigen aktuelle Prüfungsschwerpunkte und
                    Entwicklungen, sind aber nicht identisch mit den offiziellen Prüfungsfragen.
                  </BrandLargeText>
                </Paragraph>
                <Paragraph>
                  <BrandLargeText>
                    Praktisch! VogelSPOT kann in Kombination mit allen Lehrwerken des Verlags
                    verwendet werden!
                  </BrandLargeText>
                </Paragraph>
              </View>
              <View styleName="flex-md-row py-3 px-3 jc-center ai-center">
                {viewer && (
                  <Button to="/dashboard" size="lg" styleName="ml-md-1 mb-2 mb-md-0">
                    {media.up('md') && <Text>Zum Prüfungstraining</Text>}
                    {media.down('sm') && <Text>Prüfungstraining</Text>}
                  </Button>
                )}
                {!viewer && (
                  <Button to="/login" size="lg" styleName="ml-md-1 mb-2 mb-md-0">
                    {media.up('md') && <Text>Zum Prüfungstraining</Text>}
                    {media.down('sm') && <Text>Prüfungstraining</Text>}
                  </Button>
                )}
                <Dropdown>
                  <Button
                    toggle={Dropdown}
                    size="lg"
                    color="light"
                    styleName="mr-md-1 mb-2 mb-md-0"
                  >
                    <Text>Zugang erwerben</Text>
                  </Button>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      to="https://www.heinrich-vogel-shop.de/shop/aus-und-weiterbildung/gefahrgut-und-abfall/gefahrgut/vogelspot-erstschulung-basis-adr.html"
                      external
                    >
                      <Text>ADR-Schein Basis</Text>
                    </Dropdown.Item>
                    <Dropdown.Item
                      to="https://www.heinrich-vogel-shop.de/shop/aus-und-weiterbildung/gefahrgut-und-abfall/gefahrgut/vogelspot-auffrischungsschulung-adr.html"
                      external
                    >
                      <Text>ADR-Schein Auffrischung</Text>
                    </Dropdown.Item>
                    <Dropdown.Item
                      to="https://www.heinrich-vogel-shop.de/shop/aus-und-weiterbildung/gefahrgut-und-abfall/gefahrgut/vogelspot-aufbaukurs-tank-adr.html"
                      external
                    >
                      <Text>ADR-Schein Tank</Text>
                    </Dropdown.Item>
                    <Dropdown.Item
                      to="https://www.heinrich-vogel-shop.de/shop/aus-und-weiterbildung/gefahrgut-und-abfall/gefahrgut/vogelspot-erstschulung-basis-und-tank-adr.html"
                      external
                    >
                      <Text>ADR-Schein Basis &amp; Tank</Text>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </View>
            </Container>
          </Section>
        </>
      )}
      {haulage.on && (
        <>
          <Section color="white">
            <Container styleName="ai-end">
              <Text small color="muted" styleName="mt-n5 mb-3 px-2 px-md-0">
                Foto: © industrieblick / stock.adobe.com
              </Text>
            </Container>
            <Container styleName="mt-3">
              <View styleName="px-3 px-md-0">
                <View styleName="my-3 mb-md-4 flex-md-row flex-shrink-0 jc-between ai-center">
                  <Heading size={3} style={{ color: '#13573b' }}>
                    Das Prüfungstraining für künftige Güterkraftverkehrsunternehmer
                  </Heading>
                </View>
                <Paragraph>
                  <BrandLargeText>
                    Mit VogelSPOT trainieren Sie{' '}
                    <b>
                      online und mobil auf die IHK-Prüfung zur Erlangung der Fachkunde
                      Güterkraftverkehr!
                    </b>{' '}
                    Über 700 Fragen plus sechs umfangreiche Kalkulationsaufgaben auf Prüfungsniveau
                    ermöglichen Ihnen eine optimale Vorbereitung.
                  </BrandLargeText>
                </Paragraph>
                <Paragraph>
                  <BrandLargeText>
                    Als Prüfungskandidat können Sie bei VogelSPOT <b>in verschiedenen Bereichen</b>{' '}
                    trainieren und Ihre Kenntnisse vertiefen:
                  </BrandLargeText>
                </Paragraph>
              </View>
            </Container>
          </Section>
          <Section color="gray">
            <Container styleName="p-0">
              <FeaturesBoxContent />
            </Container>
          </Section>
          <Section color="white">
            <Container>
              <View styleName="m-3 mx-md-0">
                <Paragraph>
                  <BrandLargeText>
                    Die Prüfungsfragen bei VogelSPOT wurden vom Fachautorenteam des Verlags Heinrich
                    Vogel entwickelt. Sie berücksichtigen aktuelle Prüfungsschwerpunkte und
                    Entwicklungen, sind aber nicht identisch mit den offiziellen Prüfungsfragen.
                  </BrandLargeText>
                </Paragraph>
                <Paragraph>
                  <BrandLargeText>
                    Praktisch! VogelSPOT kann in Kombination mit allen Lehrwerken des Verlags
                    verwendet werden!
                  </BrandLargeText>
                </Paragraph>
              </View>
              <View styleName="flex-md-row py-3 px-3 jc-center ai-center">
                {viewer && (
                  <Button to="/courses/vWPwp2AnbH" size="lg" styleName="ml-md-1 mb-2 mb-md-0">
                    {media.up('md') && <Text>Zum Prüfungstraining</Text>}
                    {media.down('sm') && <Text>Prüfungstraining</Text>}
                  </Button>
                )}
                {!viewer && (
                  <Button to="/login" size="lg" styleName="ml-md-1 mb-2 mb-md-0">
                    {media.up('md') && <Text>Zum Prüfungstraining</Text>}
                    {media.down('sm') && <Text>Prüfungstraining</Text>}
                  </Button>
                )}
                <Button
                  to="https://www.heinrich-vogel-shop.de/shop/aus-und-weiterbildung/fachkunden/vogelspot-fachkunde-gueterkraftverkehr.html?suche=1&isSearch=true&templateName=articleList"
                  external
                  color="light"
                  size="lg"
                  styleName="mr-md-1"
                >
                  <Text>Zugang erwerben</Text>
                </Button>
              </View>
            </Container>
          </Section>
        </>
      )}
      {taxi.on && (
        <>
          <Section color="white">
            <Container styleName="ai-end">
              <Text small color="muted" styleName="mt-n5 mb-3 px-2 px-md-0">
                Foto: © Luftbildfotograf / stock.adobe.com
              </Text>
            </Container>
            <Container styleName="mt-3">
              <View styleName="px-3 px-md-0">
                <View styleName="my-3 mb-md-4 flex-md-row flex-shrink-0 jc-between ai-center">
                  <Heading size={3} style={{ color: '#13573b' }}>
                    Das Prüfungstraining für angehende Taxi- und Mietwagenunternehmer
                  </Heading>
                </View>
                <Paragraph>
                  <BrandLargeText>
                    Mit VogelSPOT trainieren Sie{' '}
                    <b>
                      online und mobil für die IHK-Fachkundeprüfung für den Taxi- und
                      Mietwagenunternehmer sowie den Unternehmer des gebündelten Bedarfsverkehrs!
                    </b>{' '}
                    Über 600 Fragen entlang der Sachgebiete gemäß Prüfungsordnung ermöglichen Ihnen
                    eine optimale Vorbereitung auf die IHK-Prüfung.
                  </BrandLargeText>
                </Paragraph>
                <Paragraph>
                  <BrandLargeText>
                    Als Prüfungskandidat können Sie bei VogelSPOT <b>in verschiedenen Bereichen</b>{' '}
                    trainieren und Ihre Kenntnisse vertiefen:
                  </BrandLargeText>
                </Paragraph>
              </View>
            </Container>
          </Section>
          <Section color="gray">
            <Container styleName="p-0">
              <FeaturesBoxContent />
            </Container>
          </Section>
          <Section color="white">
            <Container>
              <View styleName="m-3 mx-md-0">
                <Paragraph>
                  <BrandLargeText>
                    Die Prüfungsfragen bei VogelSPOT wurden vom Fachautorenteam des Verlags Heinrich
                    Vogel entwickelt. Sie berücksichtigen aktuelle Prüfungsschwerpunkte und
                    Entwicklungen, sind aber nicht identisch mit den offiziellen Prüfungsfragen.
                  </BrandLargeText>
                </Paragraph>
                <Paragraph>
                  <BrandLargeText>
                    Praktisch! VogelSPOT kann in Kombination mit allen Lehrwerken des Verlags
                    verwendet werden!
                  </BrandLargeText>
                </Paragraph>
              </View>
              <View styleName="flex-md-row py-3 px-3 jc-center ai-center">
                {viewer && (
                  <Button to="/courses/vWPwp2AnbH" size="lg" styleName="ml-md-1 mb-2 mb-md-0">
                    {media.up('md') && <Text>Zum Prüfungstraining</Text>}
                    {media.down('sm') && <Text>Prüfungstraining</Text>}
                  </Button>
                )}
                {!viewer && (
                  <Button to="/login" size="lg" styleName="ml-md-1 mb-2 mb-md-0">
                    {media.up('md') && <Text>Zum Prüfungstraining</Text>}
                    {media.down('sm') && <Text>Prüfungstraining</Text>}
                  </Button>
                )}
                <Button
                  to="https://www.heinrich-vogel-shop.de/shop/aus-und-weiterbildung/fachkunden/vogelspot-fachkunde-taxi.html?suche=1&isSearch=true&templateName=articleList"
                  external
                  color="light"
                  size="lg"
                  styleName="mr-md-1"
                >
                  <Text>Zugang erwerben</Text>
                </Button>
              </View>
            </Container>
          </Section>
        </>
      )}
      {spedi.on && (
        <>
          <Section color="white">
            <Container styleName="ai-end">
              <Text small color="muted" styleName="mt-n5 mb-3 px-2 px-md-0">
                Foto: ©lassedesignen/stock.adobe.com
              </Text>
            </Container>
            <View styleName="jc-end ai-end">
              <Image
                source="/img/organizations/vogel/vogelspot-neu.png"
                style={media.up('md') ? styles.NeuBadgeDesktop : styles.NeuBadgeMobile}
              />
            </View>
            <Container styleName="mt-3">
              <View styleName="px-3 px-md-0">
                <View styleName="my-3 mb-md-4 flex-md-row flex-shrink-0 jc-between ai-center">
                  <Heading size={3} style={{ color: '#13573b' }}>
                    Das Prüfungstraining für angehende Speditionskaufleute
                  </Heading>
                </View>
                <Paragraph>
                  <BrandLargeText>
                    Mit VogelSPOT trainieren Sie{' '}
                    <Text bold>
                      online und mobil auf die IHK-Abschlussprüfung für Speditionskaufleute!
                    </Text>{' '}
                    Über 600 Lernkarten ermöglichen Ihnen eine optimal Vorbereitung auf die
                    IHK-Prüfung. Zusätzlich profitieren Sie von insgesamt{' '}
                    <Text bold>14 Prüfungssimulationen.</Text>
                  </BrandLargeText>
                </Paragraph>
                <Paragraph>
                  <BrandLargeText>
                    Als Prüfungskandidat können Sie bei VogelSPOT{' '}
                    <Text bold>in verschiedenen Bereichen</Text> trainieren und Ihre Kenntnisse
                    vertiefen:
                  </BrandLargeText>
                </Paragraph>
              </View>
            </Container>
          </Section>
          <Section color="gray">
            <Container styleName="p-0">
              <FeaturesBoxContent />
            </Container>
          </Section>
          <Section color="white">
            <Container>
              <View styleName="m-3 mx-md-0">
                <Paragraph>
                  <BrandLargeText>
                    Die Prüfungsfragen bei VogelSPOT wurden vom Fachautorenteam des Verlags Heinrich
                    Vogel entwickelt. Sie berücksichtigen aktuelle Prüfungsschwerpunkte und
                    Entwicklungen, sind aber nicht identisch mit den offiziellen Prüfungsfragen.
                  </BrandLargeText>
                </Paragraph>
              </View>
              <View styleName="flex-md-row py-3 px-3 jc-center ai-center">
                {viewer && (
                  <Button to="/courses/o22UXQvS1M" size="lg" styleName="ml-md-1 mb-2 mb-md-0">
                    {media.up('md') && <Text>Zum Prüfungstraining</Text>}
                    {media.down('sm') && <Text>Prüfungstraining</Text>}
                  </Button>
                )}
                {!viewer && (
                  <Button to="/login" size="lg" styleName="ml-md-1 mb-2 mb-md-0">
                    {media.up('md') && <Text>Zum Prüfungstraining</Text>}
                    {media.down('sm') && <Text>Prüfungstraining</Text>}
                  </Button>
                )}
                <Button
                  to="https://www.heinrich-vogel-shop.de/shop/gueterverkehr/fachbuecher-software/vogelspot-fuer-speditionskaufleute.html"
                  external
                  color="light"
                  size="lg"
                  styleName="mr-md-1"
                >
                  <Text>Zugang erwerben</Text>
                </Button>
              </View>
            </Container>
          </Section>
        </>
      )}
      <View style={styles.backgroundWhite} styleName="mb-n5 p-md-3 py-3">
        <Container styleName="jc-center ai-center">
          <View styleName="m-3 mx-md-0 mb-5">
            <Paragraph>
              <BrandLargeText>
                Antworten auf häufig gestellte Fragen finden Sie{' '}
                <Link to="https://www.vogelspot.de/faq" external size="lg">
                  <Text bold>in unseren FAQ</Text>.
                </Link>
              </BrandLargeText>
            </Paragraph>
          </View>
          <VogelSpotInfoTrainersBox />
          <View style={[media.up('md') ? styles.videoDesktop : styles.videoMobile]}>
            <Video
              controls
              source={{ uri: '/img/organizations/vogel/VogelSPOT_Screencast.mp4' }}
              poster="/img/organizations/vogel/VogelSPOT-start.jpg"
            />
          </View>
          <Image
            source="/img/organizations/vogel/vogel-logo.png"
            label="vogel"
            styleName="mx-3 mx-md-0 my-3"
            style={styles.logoVogel}
          />
        </Container>
      </View>
    </>
  );
}

export default VogelSPOT;
