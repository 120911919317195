// @flow
import React from 'react';
import { Link } from 'components/core';
import ProfilePopover from 'views/_shared/ProfilePopover';

type Props = {
  children: React$Node,
  user: Entity<GQLUser>,
};

function ProfileLinkPopover({ user, children, ...elementProps }: Props): React$Node {
  return (
    <ProfilePopover {...elementProps} user={user} component={Link}>
      {children}
    </ProfilePopover>
  );
}

export default ProfileLinkPopover;
