// @flow

export default function isExpired(membership: Entity<GQLCourseMembership>): boolean {
  if (membership.role !== 'MEMBER') {
    return false;
  }

  if (!membership.licensed) {
    return false;
  }

  const { licensed } = membership;

  if (!licensed.validUntil) {
    return false;
  }

  const { validUntil } = licensed;

  return new Date(membership.createdAt) < new Date(validUntil) && new Date() > new Date(validUntil);
}
