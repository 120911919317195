// @flow
import { createContainer } from 'libs/container';
import { select } from 'libs/graphql';
import { DOMAIN_QUERY } from 'libs/foundation/queries';
import Login from './Login';

const Container = createContainer(Login, () => {
  const selectors = {
    domain: select(DOMAIN_QUERY, {}),
  };
  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
