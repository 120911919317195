// @flow
import React from 'react';
import { Grid, View, Text, Image, Button } from 'components/core';
import { BrandHeading, BrandLargeText } from 'components/pages';
import { StyleSheet, css, useMedia } from 'libs/ui';

const styles = StyleSheet.create({
  bodo: css`
    margin-right: 1rem;
    width: 300px;
    height: 300px;
  `,
  arbeitsheft: css`
    margin-top: 1rem;
    width: 200px;
    height: 264px;
  `,
  arrow: css`
    margin-top: 1rem;
    width: 200px;
    height: 33px;
  `,
  largeText: css`
    text-align: left;
  `,
});

function InfoSection(): React$Node {
  const media = useMedia();

  return (
    <View styleName="mx-3 mx-md-0">
      <View styleName="jc-center ai-center my-4">
        <BrandHeading size={2}>
          <Text>
            Lerne die Verkehrsregeln mit Bodo und meistere deine Fahrradprüfung erfolgreich!
          </Text>
        </BrandHeading>
      </View>
      <Grid>
        <Grid.Box size={12} sizeMd={4}>
          <View styleName="p-md-4 jc-center ai-center">
            <Image
              source="/img/organizations/verkehrserziehung/bodo.png"
              label="Grundschule Fahrradprüfung"
              style={styles.bodo}
            />
          </View>
        </Grid.Box>
        <Grid.Box size={12} sizeMd={4}>
          <View styleName="p-md-4 mt-3 mt-md-0">
            <View styleName="flex-grow-1 flex-shrink-1 my-3 mb-md-0 mt-md-5 jc-md-start ai-md-start">
              <BrandLargeText style={styles.largeText}>
                <Text>
                  Das Online-Prüfungstraining ist die ideale Ergänzung zum Arbeitsheft für die 4.
                  Klasse.
                </Text>
              </BrandLargeText>
              <Button
                color="secondary"
                styleName="mt-4"
                to="https://www.heinrich-vogel-shop.de/suche.html?searchphrase=fahrradpr%C3%BCfung.de+online"
                external
              >
                <Text>Zugangscode erwerben</Text>
              </Button>
            </View>
            {media.up('md') && (
              <View styleName="mr-n5 jc-end ai-end">
                <Image
                  source="/img/organizations/verkehrserziehung/arrow.png"
                  label="Radfahrprüfung Grundschule"
                  style={styles.arrow}
                />
              </View>
            )}
          </View>
        </Grid.Box>
        <Grid.Box size={12} sizeMd={4}>
          <View styleName="p-md-4 jc-center ai-center jc-md-start ai-md-start">
            <Image
              source="/img/organizations/verkehrserziehung/arbeitsheft.png"
              label="Fahrradprüfung üben"
              style={styles.arbeitsheft}
            />
          </View>
        </Grid.Box>
      </Grid>
    </View>
  );
}

export default InfoSection;
