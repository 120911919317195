// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';
import { useToggleState, Cookies } from 'libs/misc';
import { Button, View } from 'components/core';
import YoutubeVideoPlayer from './YoutubeVideoPlayer';

type Props = {
  videoId: string,
  start?: number,
  end?: number,
  maxWidth?: number,
};

const dict = createDict({
  accept_youtube_video_info:
    'This video will be loaded from YouTube (operated by Google). YouTube may place tracking cookies on your device. By clicking "Accept", you agree to load the video from YouTube and to the placement of the cookies.',
  accept: 'Accept',
});

const styles = StyleSheet.create({
  consentContainer: css`
    width: 100%;
    background-color: $gray-800;
    border-radius: 0.5rem;
    padding: 2rem;
    align-items: center;
    justify-content: center;

    // Use aspect ratio for sm+ only, because otherwise the message will be cropped in xs.
    @include media-breakpoint-up(sm) {
      aspect-ratio: 16/9;
    }
  `,
});

const YOUTUBE_CONSENT_COOKIE = 'ytcs';

function YoutubeVideo({ videoId, start, end, maxWidth }: Props): React$Node {
  const cookie = Cookies.get(YOUTUBE_CONSENT_COOKIE);
  const showYoutubeConsent = useToggleState(typeof cookie === 'undefined' || cookie === null);

  return (
    <>
      {showYoutubeConsent.on ? (
        <View style={[styles.consentContainer, { maxWidth }]}>
          <Trans
            {...dict('accept_youtube_video_info')}
            small
            styleName="text-white text-center mb-3"
          />
          <Button
            color="secondary"
            onPress={() => {
              Cookies.set(YOUTUBE_CONSENT_COOKIE, 'allow', 720);

              showYoutubeConsent.setOff();
            }}
          >
            <Trans {...dict('accept')} />
          </Button>
        </View>
      ) : (
        <YoutubeVideoPlayer videoId={videoId} start={start} end={end} maxWidth={maxWidth} />
      )}
    </>
  );
}

export default YoutubeVideo;
