// @flow
import { createContainer } from 'libs/container';
import { select, gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import LinkToCourse from './LinkToCourse';

export const COURSE_ANNOUNCEMENT_FRAGMENT: DocumentNode = gql`
  fragment CourseAnnouncementFragment on Course {
    name
    slug
  }
`;

const Container = createContainer(LinkToCourse, (props) => {
  const selectors = {
    course: select(COURSE_ANNOUNCEMENT_FRAGMENT, {
      entry: ['Course', props.id],
    }),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
