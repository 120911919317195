// @flow
import formatNumber from './formatNumber';

export default function getTaskNumber(
  taskCollection: Entity<GQLTaskCollection>,
  task: Entity<GQLTask>,
  taskPart?: Entity<GQLTaskPart>,
): string {
  const formattedTaskNumber = formatNumber(task.position, taskCollection.numberingFormat, false);

  if (!taskPart) {
    return formattedTaskNumber;
  }

  const formattedTaskPartNumber = formatNumber(
    taskPart.position,
    taskCollection.partNumberingFormat,
  );

  // Do not add dot if format is numeric-alphabetic
  if (
    taskCollection.numberingFormat === 'NUMERIC' &&
    taskCollection.partNumberingFormat === 'ALPHABETIC'
  ) {
    return `${formattedTaskNumber}${formattedTaskPartNumber}`;
  }

  return `${formattedTaskNumber}.${formattedTaskPartNumber}`;
}
