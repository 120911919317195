// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const ARTICLE_ACTIVITY_CAPTION_FRAGMENT: DocumentNode = gql`
  fragment ArticleActivityCaptionFragment on ArticleActivity {
    version(specificVersion: "CURRENT") {
      item {
        readingTime
      }
    }
  }
`;
