// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const SURVEY_QUESTION_SCALE_FRAGMENT: DocumentNode = gql`
  fragment SurveyQuestionScaleFragment on SurveyQuestionScale {
    text
    answers {
      rating
      resultId
      createdAt
    }
  }
`;
