// @flow
import React from 'react';
import { Link } from 'components/core';

type Props = {
  organization: Entity<GQLOrganization>,
  announcementId: Id,
};

function LinkToOrganization({ organization, announcementId }: Props): React$Node {
  return (
    <Link to={`/organizations/${organization.slug}?announcement_id=${announcementId}`}>
      {organization.name}
    </Link>
  );
}

export default LinkToOrganization;
