// @flow
import React from 'react';
import { createDict, Trans, useIntl } from 'libs/intl';
import { useAlerts } from 'libs/misc';
import { StyleSheet, css, colorContrast, shiftColor } from 'libs/ui';
import { useNavigate, useLocation } from 'libs/routing';
import { Icon } from 'components/common';
import { Form } from 'components/forms';

type Props = {
  variant?: 'navbar',
};

const styles = StyleSheet.create({
  form: css`
    flex-direction: row;
  `,
  formNavbar: css`
    width: 25rem;
    height: 2rem;
  `,
  input: css`
    background-color: #fff;
    border-width: 0;
    flex-grow: 1;
    min-height: 2rem;
    border-radius: 0.1875rem 0 0 0.1875rem;
    margin-bottom: 0;
  `,
  inputInner: css`
    background-color: #fff;
    border-width: 0;
    flex-grow: 1;
    border-radius: 0.1875rem 0 0 0.1875rem;
    margin-bottom: 0;
    &:focus {
      box-shadow: none;
    }
  `,
  button: css`
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    background-color: $brand-secondary;
    border-radius: 0 0.1875rem 0.1875rem 0;
    border-width: 0;

    &:hover {
      background-color: ${shiftColor(
        (t) => t['brand-secondary'],
        (t) => t['btn-hover-bg-shade-amount'],
      )};
    }

    &:focus-visible {
      background-color: ${shiftColor(
        (t) => t['brand-secondary'],
        (t) => t['btn-hover-bg-shade-amount'],
      )};
    }
  `,
  buttonNavbar: css`
    height: 2rem;
  `,
  iconColor: css`
    color: ${colorContrast((t) => t['brand-secondary'])};
  `,
});

const dict = createDict({
  search_placeholder: 'Search...',
  error_search_term_length: 'The search term must be at least 3 characters.',
});

function SearchForm({ variant }: Props): React$Node {
  const alerts = useAlerts();
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  return (
    <Form
      initialValues={{ term: params.get('q') }}
      onSubmit={(values, { setSubmitting }) => {
        const term = values.term ? values.term.trim() : '';

        if (term.length >= 3) {
          navigate(`/search/?q=${encodeURIComponent(term)}`);
        } else {
          alerts.add({
            color: 'danger',
            content: <Trans {...dict('error_search_term_length')} />,
          });
        }

        setSubmitting(false);
      }}
      style={[styles.form, variant === 'navbar' && styles.formNavbar]}
    >
      <Form.Input
        style={styles.input}
        inputStyle={styles.inputInner}
        size="sm"
        name="term"
        placeholder={intl.trans(dict('search_placeholder'))}
        autoComplete="off"
      />
      <Form.Button
        type="submit"
        style={[styles.button, variant === 'navbar' && styles.buttonNavbar]}
      >
        <Icon glyph="search" style={styles.iconColor} />
      </Form.Button>
    </Form>
  );
}

export default SearchForm;
