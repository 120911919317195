// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const CHECKLIST_TODO_ACTIVITY_FRAGMENT: DocumentNode = gql`
  fragment ChecklistTodoActivityFragment on CourseActivity {
    title
    slug
    course {
      slug
      name
    }
    viewerCanAccess
  }
`;
