// @flow
import React from 'react';
import { Grid } from 'components/core';
import FeaturesBox from './FeaturesBox';

function FeaturesBoxContent(): React$Node {
  return (
    <Grid styleName="mb-5 flex-grow-1 flex-shrink-1">
      <FeaturesBox
        title="Prüfungen"
        icon="award"
        content="Lernen Sie mit Probeklausuren, die sich an den IHK-Prüfungen orientieren"
        type="red"
      />
      <FeaturesBox
        title="Erklärvideos"
        icon="file-video"
        content="Vertiefen Sie Ihr Wissen kurzen Erklärvideos zu vielen Prüfungsthemen"
        type="red"
      />
      <FeaturesBox
        title="Lernkarten"
        icon="cards"
        content="Üben Sie mit nach Themen geordneten Lernkarten-Sets für die Prüfung"
        type="red"
      />
      <FeaturesBox
        title="Gemeinsames Lernen"
        icon="chat"
        content="Tauschen Sie sich in den Kursen mit anderen Lernenden aus"
        type="black"
      />
      <FeaturesBox
        title="Eigene Materialien"
        icon="users"
        content="Erstellen Sie Lernkarten oder laden Sie eigene Materialien hoch"
        type="black"
      />
      <FeaturesBox
        title="Mobil"
        icon="tablet"
        content="Lernen Sie, wann und wo Sie mögen - am Laptop, Tablet oder am Smartphone"
        type="black"
      />
    </Grid>
  );
}

export default FeaturesBoxContent;
