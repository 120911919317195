// @flow
import React, { useRef, useState, useCallback } from 'react';
import PortalContext from './PortalContext';

type Props = {
  children: React$Node,
};

function PortalProvider({ children }: Props): React$Node {
  const incrementalId = useRef(0);
  const [elements, setElements] = useState([]);

  const createPortal = useCallback(() => {
    const key = incrementalId.current.toString();
    incrementalId.current += 1;

    return {
      render(element) {
        const elementWithKey = React.cloneElement(element, { key });

        setElements(([...nextElements]) => {
          // $FlowFixMe[prop-missing]
          const index = nextElements.findIndex((el) => el.key === key);

          if (index === -1) {
            nextElements.push(elementWithKey);
          } else {
            // eslint-disable-next-line no-param-reassign
            nextElements[index] = elementWithKey;
          }

          return nextElements;
        });
      },
      destroy() {
        setElements(([...nextElements]) => {
          // $FlowFixMe[prop-missing]
          const index = nextElements.findIndex((el) => el.key === key);

          nextElements.splice(index, 1);
          return nextElements;
        });
      },
    };
  }, []);

  return (
    <PortalContext.Provider value={createPortal}>
      {children}
      {elements}
    </PortalContext.Provider>
  );
}

export default PortalProvider;
