// @flow
import React from 'react';
import { Text, View } from 'components/core';
import { createDict, Trans, TimeAgo } from 'libs/intl';
import Avatar from 'components/common/Avatar';

type Props = {
  user: Entity<GQLUser>,
  viewer: ?Entity<GQLUser>,
};

const dict = createDict({
  joined: 'Joined',
  this_information_is_visible_for_logged_in_users_only:
    'This information is only visible for logged in users only.',
});

function Profile({ user, viewer }: Props): React$Node {
  return (
    <>
      <View styleName="flex-row ai-center mb-3">
        <Avatar avatar={user.avatar} styleName="mr-2" />
        <Text>{user.name}</Text>
      </View>
      {viewer && (
        <>
          {user.organizationMemberships && (
            <View styleName="mb-2">
              {user.organizationMemberships.map(({ id, organization }) => (
                <Text key={id}>{organization.name}</Text>
              ))}
            </View>
          )}
          <View styleName="flex-row">
            <Text color="muted">
              <Trans {...dict('joined')} /> <TimeAgo value={user.createdAt} />
            </Text>
          </View>
        </>
      )}
      {!viewer && <Trans {...dict('this_information_is_visible_for_logged_in_users_only')} />}
    </>
  );
}

export default Profile;
