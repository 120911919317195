// @flow
import React from 'react';
import { View } from 'components/core';
import { StyleSheet, css, useMedia } from 'libs/ui';

type Props = {
  children: React$Node,
  style?: any,
};

const styles = StyleSheet.create({
  container: css`
    border-width: 0.125rem;
    border-radius: 0.5rem;
    border-color: $gray-200;
    flex-grow: 1;
    padding: 1rem;
  `,
  containerDesktop: css`
    border-radius: 0.5rem;
  `,
  containerMobile: css`
    margin-horizontal: 1rem;
  `,
});

const HighlightStripe = React.forwardRef<Props, any>((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { children, style, ...elementProps } = props;

  const media = useMedia();

  return (
    <View
      {...elementProps}
      ref={ref}
      style={[
        styles.container,
        media.up('md') ? styles.containerDesktop : styles.containerMobile,
        style,
      ]}
    >
      {children}
    </View>
  );
});

export default (HighlightStripe: React$AbstractComponent<Props, any>);
