// @flow
import React from 'react';
import { Text, Button, Image } from 'components/core';
import { HighlightStripe, BrandText } from 'components/pages';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';

const styles = StyleSheet.create({
  textDesktop: css`
    flex-grow: 1;
    margin-horizontal: 1rem;
  `,
  textMobile: css`
    text-align: center;
  `,
  buttonText: css`
    text-transform: none;
  `,
  image: css`
    width: 30px;
    height: 30px;
  `,
});

const dict = createDict({
  stbk_azubinet_teaser:
    'We are cooperating with StBK Niedersachsen. Visit the StBK.Azubinet learning platform now.',
});

function StBKLink(): React$Node {
  const media = useMedia();

  return (
    <HighlightStripe styleName="flex-md-row ai-center">
      <Image source="/img/logos/stbk-nds-icon.png" style={styles.image} />
      <BrandText
        style={media.up('md') ? styles.textDesktop : styles.textMobile}
        styleName="my-3 my-md-0"
      >
        <Trans {...dict('stbk_azubinet_teaser')} />
      </BrandText>
      <Button to="https://stbk.azubinet.de" external color="secondary" outline size="lg">
        <Text style={styles.buttonText}>Zu StBK.Azubinet</Text>
      </Button>
    </HighlightStripe>
  );
}

export default StBKLink;
