// @flow
import { useState } from 'react';

export default function useCounterState(initialCount: number): CounterState {
  const [count, setCount] = useState(initialCount);

  return {
    count,
    increment: () => {
      setCount((c) => c + 1);
    },
    decrement: () => {
      setCount((c) => c - 1);
    },
    incrementBy: (increment: number) => {
      setCount((c) => c + increment);
    },
    decrementBy: (decrement: number) => {
      setCount((c) => c - decrement);
    },
    set: (nextCount: number) => {
      setCount(nextCount);
    },
    reset: () => {
      setCount(initialCount);
    },
  };
}
