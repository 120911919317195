// @flow
import React from 'react';
import { StyleSheet, css, shiftColor } from 'libs/ui';
import { View } from 'components/core';

type Props = {
  children: React$Node,
  isCorrect?: boolean,
  style?: any,
};

const styles = StyleSheet.create({
  container: css`
    border-width: 0.125rem;
    border-color: transparent;
    padding-vertical: 0.5rem;
    margin-vertical: -0.625rem;

    @include media-breakpoint-down(sm) {
      padding-horizontal: 0.875rem;
      margin-horizontal: -1rem;
    }
    @include media-breakpoint-up(lg) {
      padding-horizontal: 0.75rem;
      margin-horizontal: -0.875rem;
      border-radius: 0.375rem;
    }
  `,
  correct: css`
    border-radius: 0.375rem;
    border-color: $success;
    background-color: ${shiftColor(
      (t) => t.success,
      (t) => t['alert-bg-scale'],
    )};
  `,
  wrong: css`
    background-color: $gray-200;
  `,
});

function QuizCardAnswer({ children, isCorrect, style, ...elementProps }: Props): React$Node {
  return (
    <View
      {...elementProps}
      style={[
        styles.container,
        isCorrect === true && styles.correct,
        isCorrect === false && styles.wrong,
        style,
      ]}
    >
      {children}
    </View>
  );
}

export default QuizCardAnswer;
