// @flow

class HttpError extends Error {
  response;

  data;

  constructor(response, data) {
    super(response.statusText);

    this.name = 'HttpError';
    this.response = response;
    this.data = data;
  }
}

const cache = {};

const headers = {
  Accept: 'application/json',
};

const handleRequest = (request, json) => {
  return (url, options) =>
    request(url, { ...options, headers: { ...headers, ...options.headers } }).then(async (res) => {
      if (!res.ok) {
        // We can assume that http errors are always json.
        throw new HttpError(res, await res.json());
      }

      if (!json) {
        return res;
      }

      // Status code 204 -> no content.
      if (res.status === 204) {
        return null;
      }

      return res.json();
    });
};

const Api = {
  init(request: any) {
    cache.request = request;
  },
  raw(url: string, options: Object): Promise<any> {
    const request = handleRequest(cache.request, false);
    return request(url, options);
  },
  get(url: string, options: Object): Promise<any> {
    const request = handleRequest(cache.request, true);
    return request(url, {
      ...options,
      method: 'get',
    });
  },
  post(url: string, options: Object): Promise<any> {
    const request = handleRequest(cache.request, true);
    return request(url, {
      ...options,
      method: 'post',
    });
  },
};

export default Api;
