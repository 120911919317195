// @flow
import { useEffect } from 'react';
import { Platform } from 'react-native';
import { useIntl } from 'libs/intl';

export default function useDocumentTitle(value: string | Intlized$Message) {
  const intl = useIntl();

  useEffect(() => {
    if (Platform.OS !== 'web') {
      return;
    }

    const name = document.title.split(' | ').pop();

    const title = typeof value === 'object' ? intl.trans(value) : value;

    document.title = `${title}${name ? ` | ${name}` : ''}`;
  }, [intl.locale]);
}
