// @flow
import React from 'react';
import { createDict, Trans, TimeAgo } from 'libs/intl';
import { StyleSheet, css } from 'libs/ui';
import { Avatar, Icon, OptionButton } from 'components/common';
import { Text, View, Nav, Badge } from 'components/core';
import { useMenu } from 'libs/foundation';
import { Domain } from 'libs/misc';

type Props = {
  membership: Entity<GQLOrganizationMembership>,
  expanded: ToggleState,
};

const styles = StyleSheet.create({
  indented: css`
    padding-horizontal: 0.75rem;
  `,
  avatar: css`
    margin-right: 0.75rem;
  `,
  dirIcon: css`
    width: 1rem;
    align-items: center;
  `,
});

const dict = createDict({
  attendees: 'Attendees',
  trainees: 'Trainees',
  active: 'active',
  not_active_yet: 'not active yet',
});

function MenuTrainees({ membership, expanded }: Props): React$Node {
  const menu = useMenu();

  const { organization } = membership;
  const { viewerTraineeMemberships } = organization;

  // Get trainees count.
  const viewerTraineesCount = membership.organization.viewerTraineeMemberships.length;

  if (viewerTraineesCount === 0) {
    return null;
  }

  return (
    <View styleName="mt-3">
      <OptionButton onPress={expanded.toggle} styleName="jc-start">
        <View style={styles.dirIcon}>
          {expanded.on ? <Icon glyph="down-dir" /> : <Icon glyph="right-dir" />}
        </View>
        <Trans
          {...dict(Domain.is('www.vogelspot.de') ? 'attendees' : 'trainees')}
          bold
          styleName="ml-2"
        />
        <Badge color="light" styleName="ml-1">
          <Text>{viewerTraineesCount}</Text>
        </Badge>
      </OptionButton>
      {expanded.on && (
        <Nav variant="pills" styleName="flex-column" key={viewerTraineeMemberships.length}>
          {viewerTraineeMemberships
            .sort((left, right) => {
              const userLeft = left.user.name.toLowerCase();
              const userRight = right.user.name.toLowerCase();
              if (userLeft < userRight) {
                return -1;
              }
              if (userLeft > userRight) {
                return 1;
              }
              return 0;
            })
            .map((traineeMembership) => (
              <Nav.Link
                to={`/organizations/${membership.organization.slug}/users/${traineeMembership.id}`}
                onPress={() => {
                  menu.clear();
                }}
                style={styles.indented}
                key={traineeMembership.id}
              >
                <Avatar avatar={traineeMembership.user.avatar} size="md" style={styles.avatar} />
                <View styleName="flex-grow-1 flex-shrink-1">
                  <Text numberOfLines={1}>{traineeMembership.user.name}</Text>
                  <Text color="muted" small numberOfLines={1}>
                    <Trans
                      {...dict(traineeMembership.user.lastSeenAt ? 'active' : 'not_active_yet')}
                    />
                    {traineeMembership.user.lastSeenAt && (
                      <Text>
                        {' '}
                        <TimeAgo value={traineeMembership.user.lastSeenAt} />
                      </Text>
                    )}
                  </Text>
                </View>
                {traineeMembership.user.checklistResults.length > 0 && (
                  <View styleName="flex-row ai-center ml-2">
                    <Icon glyph="checklist" styleName="mr-2" color="muted" />
                    <Text small>
                      {
                        traineeMembership.user.checklistResults.filter(
                          (result) => result.todoResultsCount === result.checklist.todosCount,
                        ).length
                      }
                      /{traineeMembership.user.checklistResults.length}
                    </Text>
                  </View>
                )}
              </Nav.Link>
            ))}
        </Nav>
      )}
    </View>
  );
}

export default MenuTrainees;
