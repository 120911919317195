// @flow

export default function getOptionValue(
  statement: Entity<GQLTrueFalseStatement>,
  statementValues: Array<Entity<GQLTrueFalseSolutionStatementValue>>,
): ?boolean {
  const item = statementValues.find((statementValue) => {
    return statementValue.position === statement.position;
  });

  if (!item) {
    return null;
  }

  return item.value;
}
