// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { BrandHeading, FeaturesGrid } from 'components/pages';
import { createDict, useIntl, Trans } from 'libs/intl';

const styles = StyleSheet.create({
  heading: css`
    text-align: center;
    padding-bottom: $spacer5;
    margin-horizontal: 1rem;
  `,
});

const dict = createDict({
  what_is_waiting_for_you: 'What is waiting for you?',
});

function FeaturesSection(): React$Node {
  const intl = useIntl();

  return (
    <>
      <BrandHeading size={1} style={styles.heading}>
        <Trans {...dict('what_is_waiting_for_you')} />
      </BrandHeading>
      <FeaturesGrid>
        <FeaturesGrid.Item
          icon="/img/home/feature-exam.png"
          feature={intl.locale === 'de' ? 'Probeklausuren' : 'Exams'}
          text={
            intl.locale === 'de'
              ? 'Bereite dich mit Probeklausuren wie von der IHK auf deine Prüfungen vor'
              : 'Prepare yourself with hundreds of mock exams'
          }
        />
        <FeaturesGrid.Item
          icon="/img/home/feature-flashcards.png"
          feature={intl.locale === 'de' ? 'Lernkarten' : 'Flashcards'}
          text={
            intl.locale === 'de'
              ? 'Teste dein Wissen mit tausenden Lernkarten im Leitner-System'
              : 'Use our flashcard system to check your knowledge'
          }
        />
        <FeaturesGrid.Item
          icon="/img/home/feature-wiki.png"
          feature={intl.locale === 'de' ? 'Videos & Artikel' : 'Videos & Articles'}
          text={
            intl.locale === 'de'
              ? 'Nutze hilfreiche Erklärvideos und Wissensartikel zu Prüfungsthemen'
              : 'Watch tutorial videos and read articles about the topics covered in the final exams'
          }
        />
        <FeaturesGrid.Item
          icon="/img/home/feature-laptop.png"
          feature={intl.locale === 'de' ? 'Austausch & Support' : 'Discussions & Support'}
          text={
            intl.locale === 'de'
              ? 'Das Azubinet-Team und andere Azubis helfen dir direkt wie in einer Lerngruppe weiter'
              : 'Our Azubinet team and other trainees help you out if you encounter problems'
          }
        />
        <FeaturesGrid.Item
          icon="/img/home/feature-docs.png"
          feature={intl.locale === 'de' ? 'Eigene Materialien' : 'Own Materials'}
          text={
            intl.locale === 'de'
              ? 'Lade Mitschriften hoch, erstelle Lernkarten und teile diese mit anderen'
              : 'Upload your own materials, create flashcards and share them with others'
          }
        />
        <FeaturesGrid.Item
          icon="/img/home/feature-stats.png"
          feature={intl.locale === 'de' ? 'Statistiken & mehr' : 'Stats & more'}
          text={
            intl.locale === 'de'
              ? 'Verfolge deinen Lernfortschritt, nutze das Berichtsheft mit oder ohne deinen Betrieb'
              : 'Track your learning & use the online records tool with our without your training company'
          }
        />
      </FeaturesGrid>
    </>
  );
}

export default FeaturesSection;
