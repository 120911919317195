// @flow
import React from 'react';
import { HighlightButton, BrandHeading } from 'components/pages';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  to: string,
  text: string,
};

const styles = StyleSheet.create({
  heading: css`
    text-align: left;
    text-transform: none;
  `,
});

function FeaturedProfession({ to, text }: Props): React$Node {
  return (
    <HighlightButton to={to}>
      <BrandHeading size={5} style={styles.heading} numberOfLines={1}>
        {text}
      </BrandHeading>
    </HighlightButton>
  );
}

export default FeaturedProfession;
