// @flow
import { createAsyncContainer } from 'libs/container';
import { select, selectByRoot } from 'libs/graphql';
import { DOMAIN_QUERY } from 'libs/foundation/queries';

const Dashboard = {
  name: 'Dashboard',
  bundle: () => import('./Dashboard'),
};

const Container = createAsyncContainer(Dashboard, () => {
  const selectors = {
    viewer: selectByRoot('viewer'),
    domain: select(DOMAIN_QUERY, {}),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
