// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';

const StatsMember = {
  name: 'StatsMember',
  bundle: () => import('./StatsMember'),
};

const COURSE_SETTINGS_QUERY = gql`
  query CourseQuery($courseSlug: String!, $userId: ClientID!) {
    course(slug: $courseSlug) {
      name
      caption
      description
      restricted
      viewerIsAdmin
      viewerIsMember
      viewerIsObserver
      membership(userId: $userId, withTeams: true) {
        quizCardResultsCount
        quizCardResultsCorrectCount
        quizCardResultsWrongCount
        averageQuizProgress
        averageExamAttemptResult
        examAttemptsCount
        user {
          name
        }
      }
      examActivities {
        slug
        title
        versions {
          item {
            exam {
              slug
              attempts(userId: $userId) {
                createdAt
                completedAt
                number
                result {
                  points
                  evaluatedPoints
                }
                user {
                  name
                }
              }
              points
              selfEvaluable
            }
          }
        }
      }
      quizActivities {
        title
        slug
        versions {
          item {
            totalBoxes
            results(userId: $userId) {
              createdAt
              correctCount
              wrongCount
              progress
              number
              user {
                name
              }
            }
          }
        }
      }
    }
  }
`;

const Container = createAsyncContainer(StatsMember, (props) => {
  const {
    params: { courseSlug, userId },
  } = props;

  const variables = { courseSlug, userId };

  const loaders = {
    query: query(COURSE_SETTINGS_QUERY, {
      variables,
    }),
  };

  const selectors = {
    course: select(COURSE_SETTINGS_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
