// @flow
import React from 'react';
import { Platform } from 'react-native';
import { createDict, Trans } from 'libs/intl';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { Icon, ScrollableNav } from 'components/common';
import {
  Alert,
  Container,
  View,
  Text,
  Badge,
  Heading,
  Button,
  Modal,
  CloseButton,
  Link,
} from 'components/core';
import { Domain, useToggleState } from 'libs/misc';
import { isExpired } from 'utils/courses';
import { Header } from 'components/layout';
import OrganizationLicensePurchaseModal from 'views/_shared/OrganizationLicensePurchaseModal';
import { CoursePropertiesText } from 'views/_shared/CoursePropertiesText';
import AccessAllowedDefault from './CourseAccessBox/AccessAllowedDefault';
import CourseAccessBox from './CourseAccessBox';
import CourseChecklistsModal from './CourseChecklistsModal';

type Props = {|
  course: Entity<GQLCourse>,
  viewer: ?Entity<GQLUser>,
|};

const dict = createDict({
  not_a_member: 'Not a member',
  member: 'Member',
  admin: 'Admin',
  observer: 'Observer',
  checklists: 'Checklists',
  course_is_copyable: 'The license of this course allows you to copy it including all its items.',
  start: 'Start',
  exam_training: 'Exam Training',
  flashcards: 'Flashcards',
  documents: 'Documents',
  articles: 'Articles',
  videos: 'Videos',
  surveys: 'Surveys',
  topics: 'Forum',
  buy_licenses: 'Buy licenses',
  you_are_an_observer: 'You are an observer',
  you_are_an_observer_of_this_course:
    'You are an observer of this course because one or some of your trainees are a member.',
  you_can_join_this_course_to_take_exams_and_quizzes_yourself:
    'You can join this course to take exams and quizzes yourself.',
  this_means_that_you_can:
    "This means that you can view all course materials and your trainee's results",
  successfully_joined_the_course: 'Successfully joined the course.',
  your_course_membership_has_expired: 'Your course membership has expired.',
});

const styles = StyleSheet.create({
  heading: css`
    min-height: 4rem;
  `,
  headingReset: css`
    font-size: 1rem;
    font-family: Roboto;
  `,
  iconWrapper: css`
    background-color: $text-muted;
    width: 4rem;
    height: 4rem;
    border-radius: 0.5rem;
  `,
  icon: css`
    text-align: center;
    color: #f5f5f5;
    flex-shrink: 0;
    width: 4rem;
    height: 4rem;
    font-size: 3rem;
    line-height: 4rem;
  `,
  subtitle: css`
    margin-top: 0.25rem;
    font-size: 1.125rem;
    line-height: 1.125rem * $line-height-base;
    color: $gray-700;
  `,
  privacyIconText: css`
    color: $gray-700;
  `,
  gradient: css`
    z-index: 2;
    opacity: 0.4;
    right: 0.5rem;
    background: #fff;
    position: absolute;
    height: 2.4375rem;
    width: 0.5rem;
    bottom: 0;
    pointer-events: none;
  `,
  nav: css`
    margin-bottom: 0;
    margin-right: 0.75rem;
    border-bottom-width: 0;
    flex-wrap: nowrap;
  `,
  divider: css`
    border-right-color: $gray-300;
    border-right-width: 1px;
    margin-horizontal: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  `,
  kiehlImage: css`
    width: 100%;
    aspect-ratio: 233/128;
    border-radius: 0.5rem;
  `,
  observerStatusText: css`
    text-transform: none;
    letter-spacing: 0;
    font-size: 0.875rem;
    font-weight: normal;
    text-align: left;
    color: $gray-700;
  `,
  access: css`
    margin-top: 2rem;
    margin-bottom: -1rem;
  `,
});

const getStatsPage = (course) => {
  if (course.viewerIsAdmin || course.viewerIsObserver) {
    return 'stats';
  }

  return 'my-stats';
};

function HeaderCourse({ course, viewer }: Props): React$Node {
  const media = useMedia();
  const buyCourseLicenseModal = useToggleState(false);
  const observerExplanationModal = useToggleState(false);
  const courseChecklistsModal = useToggleState(false);

  const {
    organization,
    features: { hasTasks, hasQuizzes, hasVideos, hasArticles, hasDocuments, hasSurveys, hasForum },
  } = course;

  const viewerIsOrganizationAdminOrTrainer = viewer && viewer.isOrganizationAdminOrTrainer;

  const viewerHasExpiredMembership =
    course.paid && course.viewerMembership && isExpired(course.viewerMembership);

  return (
    <>
      <Header>
        <View styleName="flex-column-reverse flex-md-row mt-md-4 mb-md-3 jc-md-between">
          <View style={styles.heading} styleName="flex-row flex-shrink-1 mb-3 my-md-2 mx-3">
            {media.up('lg') && (
              <View style={styles.iconWrapper} styleName="mr-3">
                {Domain.is('www.vogelspot.de') && (
                  <>
                    {(course.slug === 'nnAFoQPvQx' || course.slug === 'o22UXQvS1M') && (
                      <Icon glyph="truck" style={styles.icon} />
                    )}
                    {course.slug === 'vWPwp2AnbH' && (
                      <Icon glyph="building-filled" style={styles.icon} styleName="pl-1" />
                    )}
                    {course.slug === '3GF95CRNwn' && <Icon glyph="taxi" style={styles.icon} />}
                    {course.slug !== 'nnAFoQPvQx' &&
                      course.slug !== 'vWPwp2AnbH' &&
                      course.slug !== '3GF95CRNwn' &&
                      course.slug !== 'o22UXQvS1M' && <Icon glyph="fire-1" style={styles.icon} />}
                  </>
                )}
                {!Domain.is('www.vogelspot.de') && <Icon glyph="book-open" style={styles.icon} />}
              </View>
            )}
            <View styleName="flex-grow-1 flex-shrink-1 jc-center">
              <Heading size={4}>
                {course.name}
                <Text style={styles.headingReset}>
                  <CoursePropertiesText course={course} />
                </Text>
              </Heading>
              {(course.caption || organization) && (
                <Text style={styles.subtitle}>
                  {course.caption || (organization && organization.name)}
                </Text>
              )}
            </View>
          </View>
          <View>
            <View styleName="flex-row flex-grow-1 flex-md-grow-0 flex-shrink-1 ai-center jc-between jc-md-end mb-3 mt-3 mt-md-0">
              <View styleName="flex-row flex-md-row-reverse flex-shrink-1 flex-wrap ai-center mr-3">
                {(course.viewerIsMember || !course.viewerIsObserver) && (
                  <View styleName="flex-row ml-3">
                    <Icon style={styles.privacyIconText} glyph="user" small styleName="mr-2" />
                    {!course.viewerIsMember && (
                      <Trans
                        style={styles.privacyIconText}
                        {...dict('not_a_member')}
                        color="muted"
                        small
                      />
                    )}
                    {course.viewerIsAdmin && (
                      <Trans style={styles.privacyIconText} {...dict('admin')} small />
                    )}
                    {!course.viewerIsAdmin && course.viewerIsMember && (
                      <Trans style={styles.privacyIconText} {...dict('member')} small />
                    )}
                  </View>
                )}
                {!course.viewerIsMember && course.viewerIsObserver && (
                  <Button
                    styleName="ml-3"
                    color="secondary"
                    outline
                    onPress={observerExplanationModal.toggle}
                    size="sm"
                  >
                    <Icon style={styles.privacyIconText} styleName="mr-2" glyph="user" />
                    <Trans
                      style={[styles.privacyIconText, styles.observerStatusText]}
                      {...dict('observer')}
                    />
                  </Button>
                )}
                {course.relatedChecklists.length > 0 && (
                  <View styleName="flex-row ml-3">
                    <Icon color="muted" styleName="mr-2" glyph="checklist" />
                    <Link
                      onPress={() => {
                        courseChecklistsModal.setOn();
                      }}
                      small
                    >
                      <Trans {...dict('checklists')} /> (
                      {!viewerIsOrganizationAdminOrTrainer && (
                        <>
                          {
                            course.relatedChecklists.filter(
                              (checklist) =>
                                checklist.viewerResult &&
                                checklist.viewerResult.todoResultsCount === checklist.todosCount,
                            ).length
                          }
                          /
                        </>
                      )}
                      {course.relatedChecklists.length})
                    </Link>
                  </View>
                )}
              </View>
            </View>
            {!(Domain.is('www.vogelspot.de') || Domain.is('www.xn--fahrradprfung-4ob.de')) &&
              viewerIsOrganizationAdminOrTrainer &&
              course.paid &&
              Platform.OS === 'web' && (
                <Button
                  color="primary"
                  size="sm"
                  styleName="ml-3 mr-3 mb-3 mb-md-0 as-start as-md-end"
                  onPress={buyCourseLicenseModal.toggle}
                >
                  <Trans {...dict('buy_licenses')} />
                </Button>
              )}
          </View>
        </View>
        <View style={styles.gradient} />
        <ScrollableNav variant="tabs" style={styles.nav}>
          <ScrollableNav.Link to={`/courses/${course.slug}`} end>
            <Trans {...dict('start')} />
          </ScrollableNav.Link>
          {(hasTasks || hasQuizzes) && <View style={styles.divider} />}
          {hasTasks && (
            <ScrollableNav.Link to={`/courses/${course.slug}/tasks`}>
              <Trans {...dict('exam_training')} />
              <Badge color="light" styleName="ml-1">
                <Text>{course.examActivitiesCount}</Text>
              </Badge>
            </ScrollableNav.Link>
          )}
          {hasQuizzes && (
            <ScrollableNav.Link to={`/courses/${course.slug}/cards`}>
              <Trans {...dict('flashcards')} />
              <Badge color="light" styleName="ml-1">
                <Text>{course.quizActivitiesCount}</Text>
              </Badge>
            </ScrollableNav.Link>
          )}
          {(hasVideos || hasArticles) && <View style={styles.divider} />}
          {hasVideos && (
            <ScrollableNav.Link to={`/courses/${course.slug}/videos`}>
              <Trans {...dict('videos')} />
              <Badge color="light" styleName="ml-1">
                <Text>{course.videoActivitiesCount}</Text>
              </Badge>
            </ScrollableNav.Link>
          )}
          {hasArticles && (
            <ScrollableNav.Link to={`/courses/${course.slug}/articles`}>
              <Trans {...dict('articles')} />
              <Badge color="light" styleName="ml-1">
                <Text>{course.articleActivitiesCount}</Text>
              </Badge>
            </ScrollableNav.Link>
          )}
          {hasDocuments && (
            <>
              <View style={styles.divider} />
              <ScrollableNav.Link to={`/courses/${course.slug}/documents`}>
                <Trans {...dict('documents')} />
                <Badge color="light" styleName="ml-1">
                  <Text>{course.documentActivitiesCount}</Text>
                </Badge>
              </ScrollableNav.Link>
            </>
          )}
          {hasSurveys && (
            <>
              <View style={styles.divider} />
              <ScrollableNav.Link to={`/courses/${course.slug}/surveys`}>
                <Trans {...dict('surveys')} />
                <Badge color="light" styleName="ml-1">
                  <Text>{course.surveyActivitiesCount}</Text>
                </Badge>
              </ScrollableNav.Link>
            </>
          )}
          {hasForum && (
            <>
              <View style={styles.divider} />
              <ScrollableNav.Link to={`/courses/${course.slug}/topics`}>
                <Trans {...dict('topics')} />
                <Badge color="light" styleName="ml-1">
                  <Text>{course.topicsCount}</Text>
                </Badge>
              </ScrollableNav.Link>
            </>
          )}
          {(course.viewerIsAdmin || course.viewerIsMember || course.viewerIsObserver) && (
            <View style={styles.divider} />
          )}
          {course.viewerIsAdmin && (
            <ScrollableNav.Link to={`/courses/${course.slug}/settings`}>
              <Icon size="lg" glyph="cog" styleName="mx-2" />
            </ScrollableNav.Link>
          )}
          {(course.viewerIsMember || course.viewerIsObserver) && (
            <ScrollableNav.Link to={`/courses/${course.slug}/${getStatsPage(course)}`}>
              <Icon size="lg" glyph="chart-line" styleName="mx-2" />
            </ScrollableNav.Link>
          )}
        </ScrollableNav>
        {viewer && viewerIsOrganizationAdminOrTrainer && (
          <OrganizationLicensePurchaseModal
            products={course.products}
            organizations={viewer.organizationMemberships.map(
              (membership) => membership.organization,
            )}
            modal={buyCourseLicenseModal}
          />
        )}
      </Header>
      {!(course.viewerIsMember || course.viewerIsObserver) && (
        <Container style={styles.access}>
          {viewerHasExpiredMembership && (
            <Alert color="warning" styleName="mb-3">
              <Trans {...dict('your_course_membership_has_expired')} styleName="as-center" />
            </Alert>
          )}
          <CourseAccessBox course={course} />
        </Container>
      )}
      <CourseChecklistsModal modal={courseChecklistsModal} course={course} />
      {viewer && course.viewerIsObserver && observerExplanationModal.on && (
        <Modal visible={observerExplanationModal.on} onToggle={observerExplanationModal.toggle}>
          <Modal.Header styleName="flex-row">
            <Modal.Title>
              <Trans {...dict('you_are_an_observer')} />
            </Modal.Title>
            <CloseButton onPress={observerExplanationModal.toggle} />
          </Modal.Header>
          <Modal.Body>
            <Text styleName="mb-2">
              <Trans {...dict('you_are_an_observer_of_this_course')} />{' '}
              <Trans {...dict('this_means_that_you_can')} />
            </Text>
            {/* Show join button if course is not paid and not restricted */}
            {!course.paid && !course.restricted && course.viewerCanJoin && (
              <>
                <Trans
                  {...dict('you_can_join_this_course_to_take_exams_and_quizzes_yourself')}
                  styleName="mb-3"
                />
                <AccessAllowedDefault course={course} viewer={viewer} />
              </>
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default HeaderCourse;
