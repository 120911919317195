// @flow
import React from 'react';
import { Text, Modal, ListGroup, CloseButton, View, Button } from 'components/core';
import { Icon } from 'components/common';
import { StyleSheet, css } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';

type Props = {
  modal: ToggleState,
};

const dict = createDict({
  professions_on_bauvista_azubinet: 'Professions on Bauvista Azubinet',
  fourteen_day_trial_for_companies: '14-day trial for companies',
});

const styles = StyleSheet.create({
  iconView: css`
    width: 2rem;
    align-items: center;
    justify-content: center;
  `,

  icon: css`
    font-size: 1.375rem;
    margin-right: 0.5rem;
  `,
  text: css`
    font-size: 1rem;
  `,
  row: css`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 1;
  `,
});

function ProfessionsModal({ modal }: Props): React$Node {
  return (
    <Modal visible={modal.on} onToggle={modal.toggle}>
      <Modal.Header styleName="flex-row">
        <Modal.Title>
          <Trans {...dict('professions_on_bauvista_azubinet')} />
        </Modal.Title>
        <CloseButton onPress={modal.toggle} />
      </Modal.Header>
      <Modal.Body styleName="p-0">
        <ListGroup flush>
          <ListGroup.Item style={styles.row}>
            <View style={styles.iconView}>
              <Icon glyph="code" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Fachinformatiker/-innen
            </Text>
          </ListGroup.Item>
          <ListGroup.Item style={styles.row}>
            <View style={styles.iconView}>
              <Icon glyph="cubes" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Fachkräfte für Lagerlogistik
            </Text>
          </ListGroup.Item>
          <ListGroup.Item style={styles.row}>
            <View style={styles.iconView}>
              <Icon glyph="cubes" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Fachlagerist/-innen
            </Text>
          </ListGroup.Item>
          <ListGroup.Item style={styles.row}>
            <View style={styles.iconView}>
              <Icon glyph="briefcase" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Kaufleute für Büromanagement
            </Text>
          </ListGroup.Item>
          <ListGroup.Item style={styles.row}>
            <View style={styles.iconView}>
              <Icon glyph="ship" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Kaufleute für Groß- und Außenhandelsmanagement
            </Text>
          </ListGroup.Item>
          <ListGroup.Item style={styles.row}>
            <View style={styles.iconView}>
              <Icon glyph="at" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Kaufleute im E-Commerce
            </Text>
          </ListGroup.Item>
          <ListGroup.Item style={styles.row}>
            <View style={styles.iconView}>
              <Icon glyph="cart-plus" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Kaufleute im Einzelhandel
            </Text>
          </ListGroup.Item>
          <ListGroup.Item style={styles.row}>
            <View style={styles.iconView}>
              <Icon glyph="cart-plus" style={styles.icon} />
            </View>
            <Text numberOfLines={1} style={styles.text}>
              Verkäufer/-innen
            </Text>
          </ListGroup.Item>
        </ListGroup>
        <View styleName="m-3">
          <Button color="success" to="/request-access" size="lg">
            <Trans {...dict('fourteen_day_trial_for_companies')} />
          </Button>
        </View>
      </Modal.Body>
    </Modal>
  );
}

export default ProfessionsModal;
