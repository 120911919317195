// @flow
import { Platform } from 'react-native';

const cache = {};

const determineHost = (defaultHost: string, mockHost?: string): string => {
  if (__DEV__) {
    if (mockHost) {
      return mockHost;
    }

    return 'www.azubinet.de';
  }

  return defaultHost;
};

const checkHost = (host, cacheHost) => {
  const hostParts = host.split('.');
  const cacheHostParts = cacheHost.split('.');

  if (hostParts.length !== cacheHostParts.length) {
    return false;
  }

  return hostParts.every((part, key) => {
    return part === '*' || part === cacheHostParts[key];
  });
};

const Domain = {
  init(host: string, mockHost?: string) {
    cache.host = determineHost(host, mockHost);
    cache.originalHost = host;
  },
  get(): string {
    return cache.host;
  },
  url(path: string): string {
    if (Platform.OS === 'web') {
      return path;
    }

    const protocol = __DEV__ ? 'http' : 'https';

    return `${protocol}://${cache.originalHost}${path}`;
  },
  is(host: string): boolean {
    return checkHost(host, cache.host);
  },
  select(spec: Object): any {
    const selectedHost = Object.keys(spec).find((host) => checkHost(host, cache.host));

    if (selectedHost) {
      return spec[selectedHost];
    }

    if (spec['*'] !== undefined) {
      return spec['*'];
    }

    throw new Error(`Current hostname "${cache.host}" not specified. No fallback found.`);
  },
};

export default Domain;
