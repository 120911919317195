// @flow
import React from 'react';
import { useToggleState, useValueState } from 'libs/misc';
import { createDict, Trans } from 'libs/intl';
import { Dropdown, Text, Button } from 'components/core';
import { useDispatch } from 'libs/graphql';
import { getPairLeftKey } from 'utils/tasks';
import { updateOrCreateSolution } from '../mutations';
import type { TaskExamControls } from '../../useExamControls';

type Props = {
  piece: Entity<GQLPairingPiece>,
  pairing: Entity<GQLPairing>,
  examAttemptId: Id,
  controls: TaskExamControls,
  viewerExamSolution: ?Entity<GQLPairingSolution>,
};

const dict = createDict({
  in_order: 'in order',
  choose: 'Choose',
});

function SolutionDropdown({
  piece,
  pairing,
  examAttemptId,
  controls,
  viewerExamSolution,
}: Props): React$Node {
  const dispatch = useDispatch();
  const proposalDropdown = useToggleState(false);

  const value = viewerExamSolution && getPairLeftKey(piece, viewerExamSolution.pairs);
  const dropDownValue = useValueState(value);

  const handleChange = (pairValue) => {
    // block action if loading
    if (controls.loading) {
      return;
    }

    controls.block();
    dispatch(updateOrCreateSolution(pairing.id, examAttemptId, piece.key, pairValue)).finally(
      () => {
        controls.unblock();
        dropDownValue.set(pairValue);
        proposalDropdown.setOff();
      },
    );
  };

  // Generate an array [1, 2, 3, ..., n] of the length of the number of pieces.
  const positions = Array.from(Array(pairing.pieces.length), (_, i) => i + 1);

  return (
    <Dropdown
      visible={proposalDropdown.on}
      onToggle={proposalDropdown.toggle}
      key={piece.id}
      // last={pairing.pieces.length - 1 === key}
    >
      <Button
        toggle={Dropdown}
        color="secondary"
        outline
        styleName="mr-2 mr-md-0 mb-2 mb-md-0 mt-2"
      >
        {dropDownValue.value && (
          <Text>
            {dropDownValue.value}. <Trans {...dict('in_order')} />
          </Text>
        )}
        {!dropDownValue.value && <Trans {...dict('choose')} />}
      </Button>
      <Dropdown.Menu end>
        {positions.map((position) => (
          <Dropdown.Item
            key={position}
            onPress={(event) => {
              event.preventDefault();
              handleChange(position);
            }}
          >
            <Text>
              {position}. <Trans {...dict('in_order')} />
            </Text>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default SolutionDropdown;
