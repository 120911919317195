// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql, selectByRoot } from 'libs/graphql';
import { getVersion } from 'utils/courses';
import { ACTIVITY_EDITOR_SIDEBAR_FRAGMENT } from '../_shared/ActivityEditorSidebar/fragments';
import { DOCUMENT_FILE_FRAGMENT } from './DocumentFile/fragments';
import { ACTIVITY_EDITOR_TITLE_FRAGMENT } from '../_shared/ActivityEditorTitle/fragments';

const DocumentEditor = {
  name: 'DocumentEditor',
  bundle: () => import('./DocumentEditor'),
};

const COURSE_DOCUMENT_QUERY = gql`
  query CourseDocument(
    $slug: String!
    $courseSlug: String!
    $versionNumber: Int
    $specificVersion: String
  ) {
    course(slug: $courseSlug) {
      documentActivity(slug: $slug) {
        ...ActivityEditorTitleFragment
        ...ActivityEditorSidebarFragment
        slug
        version(versionNumber: $versionNumber, specificVersion: $specificVersion) {
          versionNumber
          isDeprecated
          releasedAt
          item {
            type {
              ... on DocumentArticle {
                text
              }
              ...DocumentFileFragmentEditor
              ... on DocumentLink {
                url
              }
            }
          }
        }

        author {
          name
        }
        viewerCanUpdate
      }
      viewerIsAdmin
      author {
        id
      }
    }
  }

  ${DOCUMENT_FILE_FRAGMENT}
  ${ACTIVITY_EDITOR_TITLE_FRAGMENT}
  ${ACTIVITY_EDITOR_SIDEBAR_FRAGMENT}
`;

const Container = createAsyncContainer(DocumentEditor, (props) => {
  const {
    params: { slug, courseSlug },
    searchParams,
  } = props;

  const versionNumber = getVersion(searchParams.get('version'));
  const variables = {
    slug,
    courseSlug,
    versionNumber,
    specificVersion: versionNumber ? null : 'LATEST',
  };

  const loaders = {
    query: query(COURSE_DOCUMENT_QUERY, { variables }),
  };

  const selectors = {
    course: select(COURSE_DOCUMENT_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
