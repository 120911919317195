// @flow
import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { View } from 'components/core';
import { Form } from 'components/forms';
import { useDispatch } from 'libs/graphql';
import { useToggleState, useAlerts } from 'libs/misc';
import { useNavigate, useLocation } from 'libs/routing';
import { StyleSheet, css } from 'libs/ui';
import { createCourseMembership } from './mutations';

type Props = {
  course: Entity<GQLCourse>,
  viewer: Entity<GQLUser>,
};

const dict = createDict({
  if_you_have_a_valid_password_please_enter_to_join:
    'If you have a valid course password, you can enter it below to join the course.',
  enter_password: 'Enter password...',
  submit_password: 'Submit password',
  password_is_invalid: 'This password is invalid.',
  you_are_now_a_member_of_this_course: 'You are now a member of this course.',
});

const styles = StyleSheet.create({
  font: css`
    color: $gray-900;
  `,
});

function AccessAllowedPassword({ course, viewer }: Props): React$Node {
  const dispatch = useDispatch();
  const error = useToggleState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const alerts = useAlerts();

  return (
    <>
      <Trans
        {...dict('if_you_have_a_valid_password_please_enter_to_join')}
        styleName="mb-3"
        style={styles.font}
      />
      <Form
        initialValues={{
          password: null,
        }}
        onSubmit={(values, { setSubmitting }) => {
          error.setOff();

          dispatch(createCourseMembership(course.id, viewer.id, values.password, 'PASSWORD')).then(
            () => {
              navigate('/courses/join', {
                state: { courseUrl: location.pathname + location.search },
              });

              alerts.add({
                color: 'success',
                content: <Trans {...dict('you_are_now_a_member_of_this_course')} />,
              });
            },
            () => {
              error.setOn();
              setSubmitting(false);
            },
          );
        }}
        styleName="flex-md-row mb-n3"
      >
        <View styleName="flex-grow-1">
          <Form.Input
            name="password"
            niceName={dict('enter_password')}
            placeholder={dict('enter_password')}
            secureTextEntry
          />
        </View>
        <Form.Button type="submit" styleName="as-center mb-3 ml-md-3">
          <Trans {...dict('submit_password')} />
        </Form.Button>
      </Form>
      {error.on && <Trans color="danger" {...dict('password_is_invalid')} styleName="mt-3" />}
    </>
  );
}

export default AccessAllowedPassword;
