// @flow
import React from 'react';
import { Image, Grid } from 'components/core';
import { StyleSheet, css, useMedia } from 'libs/ui';

const styles = StyleSheet.create({
  gridDesktop: css`
    min-height: 6.75rem;
    margin-bottom: 1rem;
  `,
  gridBoxDesktop: css`
    justify-content: center;
    align-items: center;
    margin-vertical: 0.75rem;
  `,
  gridBoxMobile: css`
    justify-content: center;
    align-items: center;
    padding: 1rem;
  `,
  logoHermesDesktop: css`
    height: 38px;
    width: 166px;
  `,
  logoHermesMobile: css`
    height: 29px;
    width: 125px;
  `,
  logoTUIDesktop: css`
    height: 46px;
    width: 104px;
  `,
  logoTUIMobile: css`
    height: 46px;
    width: 104px;
  `,
  logoNettoDesktop: css`
    height: 35px;
    width: 150px;
  `,
  logoNettoMobile: css`
    height: 28px;
    width: 120px;
  `,
  logoMerckDesktop: css`
    height: 24px;
    width: 150px;
  `,
  logoMerckMobile: css`
    height: 18px;
    width: 110px;
  `,
  logoOttoDesktop: css`
    height: 41px;
    width: 122px;
  `,
  logoOttoMobile: css`
    height: 34px;
    width: 100px;
  `,
  logoMedionDesktop: css`
    height: 24px;
    width: 144px;
  `,
  logoMedionMobile: css`
    height: 20px;
    width: 124px;
  `,
  buttonText: css`
    text-transform: none;
  `,
});

function TopReferences(): React$Node {
  const media = useMedia();

  return (
    <>
      <Grid
        styleName="flex-row flex-wrap flex-md-nowrap ai-center jc-center"
        style={media.up('md') && styles.gridDesktop}
      >
        <Grid.Box
          size={6}
          sizeMd={2}
          style={media.up('md') ? styles.gridBoxDesktop : styles.gridBoxMobile}
        >
          <Image
            source="/img/home/otto-gray.png"
            label="OTTO"
            style={media.up('md') ? styles.logoOttoDesktop : styles.logoOttoMobile}
          />
        </Grid.Box>
        <Grid.Box
          size={6}
          sizeMd={2}
          style={media.up('md') ? styles.gridBoxDesktop : styles.gridBoxMobile}
        >
          <Image
            source="/img/home/merck-gray.png"
            label="MERCK"
            style={media.up('md') ? styles.logoMerckDesktop : styles.logoMerckMobile}
          />
        </Grid.Box>
        <Grid.Box
          size={6}
          sizeMd={2}
          style={media.up('md') ? styles.gridBoxDesktop : styles.gridBoxMobile}
        >
          <Image
            source="/img/home/netto-gray.png"
            label="NETTO"
            style={media.up('md') ? styles.logoNettoDesktop : styles.logoNettoMobile}
          />
        </Grid.Box>
        <Grid.Box
          size={6}
          sizeMd={2}
          style={media.up('md') ? styles.gridBoxDesktop : styles.gridBoxMobile}
        >
          <Image
            source="/img/home/hermes-gray.png"
            label="Hermes Fulfilment"
            style={media.up('md') ? styles.logoHermesDesktop : styles.logoHermesMobile}
          />
        </Grid.Box>
        <Grid.Box
          size={6}
          sizeMd={2}
          style={media.up('md') ? styles.gridBoxDesktop : styles.gridBoxMobile}
        >
          <Image
            source="/img/home/tui-gray.png"
            label="TUI"
            style={media.up('md') ? styles.logoTUIDesktop : styles.logoTUIMobile}
          />
        </Grid.Box>
        <Grid.Box
          size={6}
          sizeMd={2}
          style={media.up('md') ? styles.gridBoxDesktop : styles.gridBoxMobile}
        >
          <Image
            source="/img/home/medion-gray.png"
            label="MEDION"
            style={media.up('md') ? styles.logoMedionDesktop : styles.logoMedionMobile}
            styleName="mx-md-5 m-3"
          />
        </Grid.Box>
      </Grid>
    </>
  );
}

export default TopReferences;
