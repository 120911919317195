// @flow
import React, { useMemo } from 'react';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { BrandHeading, BrandLargeText, BrandText } from 'components/pages';
import { View, Grid, Button } from 'components/core';
import { Icon } from 'components/common';
import { useCounterState } from 'libs/misc';
import { createDict, Trans, useIntl } from 'libs/intl';

const styles = StyleSheet.create({
  grid: css`
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;
    justify-content: center;
  `,
  testimonial: css`
    flex-grow: 1;
    border-radius: 0.5rem;
    border-width: 0.125rem;
    border-color: $gray-200;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    @include media-breakpoint-up(md) {
      height: 30rem;
    }
    @include media-breakpoint-up(lg) {
      height: 22rem;
    }
    @include media-breakpoint-up(xl) {
      height: 26rem;
    }
    @include media-breakpoint-down(sm) {
      min-height: 26rem;
    }
    @include media-breakpoint-down(xs) {
      min-height: 30rem;
    }
  `,
  button: css`
    width: 3rem;
    height: 3rem;
    align-items: center;
    justify-content: center;
  `,
  text: css`
    text-align: center;
    margin-bottom: 2rem;
  `,
});

const dict = createDict({
  apprentices_about_azubinet: 'Apprentices about Azubinet',
});

const testimonialsData = [
  {
    name: 'J.V., Kauffrau im E-Commerce',
    text: 'Nutzen! Super Probeklausuren, Lernaufgaben....',
  },
  {
    name: 'C.W., Kauffrau im E-Commerce',
    text: 'Azubinet fand ich gut, weil die Fragestellungen und Antwortmöglichkeiten so aufgebaut und formuliert sind, wie sie ähnlich auch in der Prüfung dran kamen. So konnte ich mich gut darauf einstellen. Auch die Erklärvideos zu den einzelnen Themen fand ich sehr gut.',
  },
  {
    name: 'F.Z., Kaufmann im Einzelhandel',
    text: 'Ich würde die Plattform als vorbereitung mehr als Empfehlen, es gibt keine vergleichbare Plattform die so gut ist wie Azubinet.',
  },
  {
    name: 'J.D., Verkäuferin',
    text: 'Sehr nützlich, ich habe mich mit Azubinet auf die schriftliche Abschlussprüfung vorbereitet und habe alle Prüfungsteile mit Note 1 bestanden. Kann die Plattform sehr empfehlen!',
  },
  {
    name: 'H.B., Kaufmann für Groß- und Außenhandelsmanagement',
    text: 'Ich würde es weiterempfehlen, da man hier auf der Plattform viele Möglichkeiten hat sich vorzubereiten. Es wird auch einem die Angst vor der Prüfung genommen, da man auch die Erfahrungen anderer Azubis mitbekommt.',
  },
  {
    name: 'E.W., Kauffrau im E-Commerce',
    text: 'Ich habe die Plattform bereits meinen Mitschülern empfohlen, die nicht verkürzt haben.',
  },
  {
    name: 'T.L., Fachkraft für Lagerlogistik',
    text: 'Ich würde sagen dass Azubinet gut für den weiteren Werdegang in der Ausbildung sehr hilft und das Programm sehr Empfehlenswert ist. Es gibt für jeden Themenbereich etwas und für sehr viele verschiedene Ausbildungen.',
  },
  {
    name: 'D.U., Kauffrau für Groß- und Außenhandelsmanagement',
    text: 'Azubinet ist eine Hilfreiche Plattform und würde sie jedem empfehlen.',
  },
];

const getTestimonialsCount = (media) => {
  if (media.up('xl')) {
    return 3;
  }
  if (media.up('md')) {
    return 2;
  }
  return 1;
};

function TestimonialsBoxTrainees(): React$Node {
  const media = useMedia();
  const controls = useCounterState(0);
  const intl = useIntl();

  const count = getTestimonialsCount(media);
  const start = controls.count;
  const end = start + count;

  const testimonials = useMemo(() => {
    return testimonialsData.slice(start, end);
  }, [start, count]);

  return (
    <>
      <View styleName="mx-3 mx-md-0">
        <View styleName="jc-center ai-center mb-3">
          <BrandHeading size={2} styleName="text-center">
            <Trans {...dict('apprentices_about_azubinet')} />
          </BrandHeading>
        </View>
        {intl.locale === 'de' && (
          <BrandLargeText bold style={styles.text}>
            Über 10.000 Azubis sind schon dabei
          </BrandLargeText>
        )}
        {intl.locale !== 'de' && (
          <BrandLargeText bold style={styles.text}>
            More than 10.000 apprentices are with us already
          </BrandLargeText>
        )}
        <View style={styles.grid}>
          <View styleName="ai-stretch jc-center mr-3">
            <Button
              color="light"
              disabled={start === 0}
              onPress={() => {
                controls.decrement();
              }}
              style={styles.button}
            >
              <Icon glyph="left-open" />
            </Button>
          </View>
          <Grid styleName="flex-shrink-1 flex-grow-1">
            {testimonials.map((item) => (
              <Grid.Box size={12} sizeMd={6} sizeXl={4} key={item.name}>
                <View style={styles.testimonial}>
                  <BrandLargeText styleName="text-center">&#8222;{item.text}&#8220;</BrandLargeText>
                  <BrandText styleName="text-center mt-3">
                    <Icon glyph="smile" /> {item.name}
                  </BrandText>
                </View>
              </Grid.Box>
            ))}
          </Grid>
          <View styleName="ai-stretch jc-center ml-3">
            <Button
              color="light"
              disabled={start >= testimonialsData.length - count}
              onPress={() => {
                controls.increment();
              }}
              style={styles.button}
            >
              <Icon glyph="right-open" />
            </Button>
          </View>
        </View>
      </View>
    </>
  );
}

export default TestimonialsBoxTrainees;
