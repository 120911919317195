// @flow
import React from 'react';
import { StyleSheet, css, injectTooltip } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';
import { Link, Image, View, Text, Button, ListGroup } from 'components/core';
import { Icon, OptionButton } from 'components/common';
import { useMutation } from 'libs/graphql';
import { useToggleState, useAlerts } from 'libs/misc';
import { deleteFileAttachment } from './mutations';

type Props = {
  attachable: Entity<GQLArticle | GQLDocument | GQLSurvey | GQLQuiz | GQLTaskCollection | GQLVideo>,
  file: Entity<GQLFile>,
  galleryModal: ToggleState,
  onSelect: (event: any) => void,
};

const dict = createDict({
  select: 'Select',
  copy_markdown_to_clipboard: 'Copy markdown to clipboard',
  markdown_copied_to_clipboard: 'Markdown copied to clipboard.',
  cancel: 'Cancel',
  delete: 'Delete',
  are_you_sure_you_want_to_delete_this_file: 'Are you sure you want to delete this file?',
});

const styles = StyleSheet.create({
  image: css`
    border-radius: 0.1875rem;
    width: 3rem;
    height: 3rem;
  `,
  icon: css`
    background-color: $gray-100;
    align-items: center;
    justify-content: center;
    border-radius: 0.1875rem;
    width: 3rem;
    height: 3rem;
  `,
  deleteLink: css`
    color: $danger;
    &:focus-visible {
      color: $danger;
    }
    &:hover {
      text-decoration-color: $danger;
      text-decoration-line: underline;
      color: $danger;
    }
  `,
  confirmContainer: css`
    background-color: $gray-100;
    border-radius: 0.25rem;
    padding: 1rem;
  `,
});

const TooltipOptionButton = injectTooltip(OptionButton);

function MediaGalleryItem({
  attachable,
  file,
  galleryModal,
  onSelect: handleSelect,
}: Props): React$Node {
  const fileUrl = `/files/${file.id}/${file.encodedName}`;

  const deleteMutation = useMutation();
  const deleteConfirmation = useToggleState(false);
  const alerts = useAlerts();

  return (
    <>
      <ListGroup.Item styleName="py-3">
        <View styleName="flex-row ai-center">
          {file.info.image ? (
            <Image source={fileUrl} style={styles.image} styleName="mr-3" />
          ) : (
            <View style={styles.icon} styleName="mr-3">
              <Icon glyph="doc" color="muted" />
            </View>
          )}
          <View styleName="flex-shrink-1 flex-grow-1">
            <Text>{file.name}</Text>
            {!deleteConfirmation.on ? (
              <Link
                small
                onPress={() => {
                  deleteConfirmation.setOn();
                }}
                style={styles.deleteLink}
                styleName="as-start"
              >
                <Trans {...dict('delete')} />
              </Link>
            ) : (
              <Text small>&nbsp;</Text>
            )}
          </View>
          <TooltipOptionButton
            tooltip={{
              title: <Trans {...dict(handleSelect ? 'select' : 'copy_markdown_to_clipboard')} />,
              trigger: 'hover',
              placement: 'top',
            }}
            onPress={() => {
              const attachmentText = `![Image](${fileUrl})`;
              if (handleSelect) {
                handleSelect(attachmentText);
                galleryModal.setOff();
              } else {
                navigator.clipboard
                  .writeText(attachmentText)
                  .then(() => {
                    alerts.add({
                      color: 'info',
                      content: <Trans {...dict('markdown_copied_to_clipboard')} />,
                    });

                    galleryModal.setOff();
                  })
                  .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.log(err);
                  });
              }
            }}
            styleName="ml-3"
          >
            <Icon glyph={handleSelect ? 'right-open' : 'docs'} />
          </TooltipOptionButton>
        </View>
        {deleteConfirmation.on && (
          <View style={styles.confirmContainer} styleName="flex-lg-row ai-center mt-3">
            <View styleName="flex-shrink-1 flex-grow-1">
              <Trans small {...dict('are_you_sure_you_want_to_delete_this_file')} />
            </View>
            <View styleName="flex-row mt-3 mt-lg-0">
              <Button
                size="sm"
                color="secondary"
                onPress={deleteConfirmation.toggle}
                styleName="ml-lg-3"
              >
                <Trans {...dict('cancel')} />
              </Button>
              <Button
                size="sm"
                color="danger"
                onPress={() => {
                  deleteMutation
                    .dispatch(deleteFileAttachment(file.id, attachable.id, attachable.__typename))
                    .then(() => {
                      deleteConfirmation.setOff();
                    })
                    .catch(() => {});
                }}
                styleName="ml-3"
              >
                <Trans {...dict('delete')} />
              </Button>
            </View>
          </View>
        )}
      </ListGroup.Item>
    </>
  );
}

export default MediaGalleryItem;
