// @flow
import React from 'react';
import { StyleSheet, css, colorContrast } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';
import { Domain } from 'libs/misc';
import { NavbarLink, NavbarMenu } from 'components/navbar';
import { Icon } from 'components/common';
import { Dropdown } from 'components/core';
import Caret from './Caret';

type Props = {
  domain: Entity<GQLDomain>,
};

const styles = StyleSheet.create({
  menu: css`
    width: 200px;
    height: auto;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  `,
  dropdownIcon: css`
    color: ${colorContrast((t) => t['brand-primary'])};
  `,
});

const dict = createDict({
  login: 'Log in',
  register: 'Register',
  for_companies: 'For companies',
});

function MobileLoginDropdown({ domain }: Props): React$Node {
  return (
    <Dropdown display="static">
      <NavbarLink toggle={Dropdown} caret={false}>
        <Icon glyph="menu" style={styles.dropdownIcon} />
      </NavbarLink>
      <NavbarMenu offset={0} offsetXs={0} style={styles.menu}>
        <Caret position={22} positionXs={14} styleName="mt-n2 mb-2" />
        {(Domain.is('www.azubinet.de') || Domain.is('stbk.azubinet.de')) && (
          <Dropdown.Item to="/business">
            <Trans {...dict('for_companies')} />
          </Dropdown.Item>
        )}
        <Dropdown.Item to="/login">
          <Trans {...dict('login')} />
        </Dropdown.Item>
        {domain.permissions.usersCanSelfRegister && (
          <Dropdown.Item to="/register">
            <Trans {...dict('register')} />
          </Dropdown.Item>
        )}
      </NavbarMenu>
    </Dropdown>
  );
}

export default MobileLoginDropdown;
