// @flow
import React, { useRef } from 'react';
import { createDict, useIntl, Trans } from 'libs/intl';
import { StyleSheet, css, injectTooltip } from 'libs/ui';
import { Input as BaseInput } from 'bootstrap-rn';
import { Link, View, Nav, Tab, Text } from 'components/core';
import { Icon, OptionButton } from 'components/common';
import { Domain, useToggleState } from 'libs/misc';
import useFormField from 'components/forms/useFormField';
import MediaGalleryModal from 'views/(app)/courses.[courseSlug]/_shared/MediaGalleryModal';
import Preview from './Preview';

type Props = {
  name: string,
  niceName?: any,
  title?: any,
  invalid?: boolean,
  onValueChange?: (any) => void,
  onFocus?: Function,
  onBlur?: Function,
  attachable?: Entity<
    GQLArticle | GQLDocument | GQLSurvey | GQLQuiz | GQLTaskCollection | GQLVideo | GQLBlog,
  >,
};

const dict = createDict({
  help_with_markdown: 'Help with Markdown',
  editor: 'Editor',
  add_file_from_media_gallery: 'Add file from media gallery...',
  preview: 'Preview',
});

const styles = StyleSheet.create({
  editor: css`
    border-radius: 1px;
    background-color: #fff;
    border-width: 1px;
    border-color: $gray-400;
    width: 100%;
    box-shadow: none;
  `,
  editorFocused: css`
    border-color: $input-focus-border-color;
    box-shadow: $form-select-focus-box-shadow;
  `,
  editorInvalid: css`
    border-color: $form-feedback-invalid-color;
  `,
  editorFocusedInvalid: css`
    box-shadow: 0 0 $input-btn-focus-blur $input-focus-width
      rgba($form-feedback-invalid-color, $input-btn-focus-color-opacity);
  `,
  controls: css`
    background-color: $body-bg;
    border-left-width: 0;
    border-bottom-width: 1px;
    border-bottom-color: $gray-300;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 2;
  `,
  input: css`
    border-width: 0;
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom: 0;
    min-height: 180px;
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  `,
  media: css`
    padding: 0.375rem 0.75rem;
    background-color: $white;
    border-left-width: 0;
    border-top-width: 1px;
    border-top-color: $gray-300;
    flex-direction: row;
    z-index: 2;
  `,
  filler: css`
    position: absolute;
    border: 1px solid $gray-400;
    border-bottom-width: 0;
    margin-top: -2.5625rem;
    height: 2.5625rem;
    border-radius: 1px;
    right: 0;
    left: 0;
  `,
  fillerFocused: css`
    border-color: $input-focus-border-color;
  `,
  tabs: css`
    margin-bottom: -1px;
    margin-left: 0.5rem;
  `,
});

const TooltipButton = injectTooltip(OptionButton);

const regex = /[\\s\\r\\n\\t]/;

const isChar = (value) => {
  return value && regex.test(value);
};

const Input = React.forwardRef<Props, any>((props, ref) => {
  const {
    name,
    niceName,
    title,
    invalid,
    onValueChange: handleValueChange,
    onFocus: handleFocus,
    onBlur: handleBlur,
    attachable,
    ...elementProps
  } = props;

  const preview = useToggleState(false);
  const focused = useToggleState(false);
  const intl = useIntl();

  const openMediaGalleryModal = useToggleState(false);

  const field = useFormField(name, niceName, title);

  const inputRef = useRef(null);

  const handleSelect = (textToInsert) => {
    if (inputRef.current) {
      const { selectionStart, selectionEnd } = inputRef.current;

      const beforeText = inputRef.current?.value.substring(0, selectionStart) || '';
      const before =
        beforeText && isChar(beforeText.at(beforeText.length - 1)) ? `${beforeText} ` : beforeText;

      const afterText = inputRef.current?.value.substring(selectionEnd) || '';
      const after = afterText && isChar(afterText.at(0)) ? ` ${afterText}` : afterText;

      const newTextInputValue = `${before}${textToInsert}${after}`;

      field.setValue(newTextInputValue, handleValueChange);
    }
  };

  return (
    <>
      <Tab.Provider defaultActiveTarget="editor">
        <View
          style={[
            styles.editor,
            invalid && styles.editorInvalid,
            focused.on && (invalid ? styles.editorFocusedInvalid : styles.editorFocused),
          ]}
        >
          <View style={styles.controls}>
            <Nav variant="tabs" style={styles.tabs}>
              <Nav.Link
                toggle={Tab}
                target="editor"
                onPress={() => {
                  preview.setOff();
                }}
              >
                <Trans {...dict('editor')} small />
              </Nav.Link>
              <Nav.Link
                toggle={Tab}
                target="preview"
                onPress={() => {
                  preview.setOn();
                }}
              >
                <Trans {...dict('preview')} small />
              </Nav.Link>
            </Nav>
            <TooltipButton
              tooltip={{
                title: intl.trans(dict('help_with_markdown')),
                trigger: 'hover',
                placement: 'top',
              }}
              size="sm"
              to={Domain.url('/mastering-markdown')}
              styleName="m-1"
              external
            >
              <Icon glyph="help" />
            </TooltipButton>
          </View>
          <Tab.Content>
            <Tab.Pane id="editor">
              <BaseInput
                {...elementProps}
                name={name}
                ref={(el) => {
                  // Apply inputRef
                  inputRef.current = el;

                  // Apply ref
                  if (typeof ref === 'function') {
                    ref(el);
                  } else if (ref) {
                    // eslint-disable-next-line no-param-reassign
                    ref.current = el;
                  }
                }}
                onFocus={(e) => {
                  if (handleFocus) {
                    handleFocus(e);
                  }

                  focused.setOn();
                }}
                onBlur={(e) => {
                  if (handleBlur) {
                    handleBlur(e);
                  }

                  focused.setOff();
                }}
                multiline
                invalid={invalid}
                style={styles.input}
              />
              <View style={[styles.filler, focused.on && styles.fillerFocused]} />
              {attachable && (
                <View style={styles.media}>
                  <Text small>
                    <Icon glyph="attach" color="muted" /> &nbsp;
                    <Link onPress={openMediaGalleryModal.toggle}>
                      <Trans {...dict('add_file_from_media_gallery')} />
                    </Link>
                  </Text>
                </View>
              )}
            </Tab.Pane>
            <Tab.Pane id="preview">
              <Preview name={name} preview={preview} />
            </Tab.Pane>
          </Tab.Content>
        </View>
      </Tab.Provider>
      {attachable && (
        <MediaGalleryModal
          modal={openMediaGalleryModal}
          attachableId={attachable.id}
          attachableType={attachable.__typename}
          onSelect={(text) => {
            handleSelect(text);
          }}
        />
      )}
    </>
  );
});

export default (Input: React$AbstractComponent<Props, any>);
