// @flow
import { createAsyncContainer } from 'libs/container';

const PasswordForgot = {
  name: 'PasswordForgot',
  bundle: () => import('./PasswordForgot'),
};

const Container = createAsyncContainer(PasswordForgot, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
