// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const ACTIVITY_REFERENCES_FRAGMENT: DocumentNode = gql`
  fragment ActivityReferencesFragment on CourseActivity {
    references {
      left {
        title
        slug
        viewerCanAccess
      }
      right {
        title
        slug
        viewerCanAccess
      }
    }
  }
`;
