// @flow
import React from 'react';
import { Markdown } from 'components/markdown';
import Option from './Option';
import type { TaskExamControls } from '../useExamControls';

type Props = {
  multipleChoice: Entity<GQLMultipleChoice>,
  examAttemptId: Id,
  controls: TaskExamControls,
  task: Entity<GQLTask>,
  taskPart: Entity<GQLTaskPart>,
  examAttemptNumber: number,
};

const shuffleOptions = (options, task, taskPart, examAttemptNumber) => {
  const newOptions = [];
  options.forEach((option, index) => {
    newOptions[(index + examAttemptNumber + task.position + taskPart.position) % options.length] =
      option;
  });
  return newOptions;
};

function MultipleChoice({
  multipleChoice,
  examAttemptId,
  controls,
  task,
  taskPart,
  examAttemptNumber,
}: Props): React$Node {
  const shuffledOptions = shuffleOptions(multipleChoice.options, task, taskPart, examAttemptNumber);

  return (
    <>
      <Markdown variant="article" source={multipleChoice.text} styleName="mb-3" />
      {shuffledOptions.map((option, numbering) => (
        <Option
          key={option.id}
          multipleChoiceId={multipleChoice.id}
          examAttemptId={examAttemptId}
          option={option}
          viewerExamSolution={multipleChoice.viewerRunningExamSolution}
          last={multipleChoice.options.length - 1 === numbering}
          controls={controls}
        />
      ))}
    </>
  );
}

export default MultipleChoice;
