// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, query, select } from 'libs/graphql';
import { DOMAIN_QUERY } from 'libs/foundation/queries';

const ProfessionSwitch = {
  name: 'ProfessionSwitch',
  bundle: () => import('./ProfessionSwitch'),
};

const STORE_PROFESSION_QUERY = gql`
  query StoreProfessionQuery($slug: String!) {
    profession(slug: $slug) {
      name
      products {
        id
        name
        createdAt
        offers {
          duration
          price {
            min
            price {
              amount
              currency {
                code
              }
            }
          }
          businessPrices {
            min
            price {
              amount
              currency {
                code
              }
            }
          }
        }
        courses {
          slug
          name
          caption
          examActivitiesCount
          quizActivitiesCount
          videoActivitiesCount
          articleActivitiesCount
          documentActivitiesCount
          surveyActivitiesCount
          topicsCount
          membershipsCount
          viewerIsMember
        }
        description
        organization {
          slug
        }
      }
    }
  }
`;

const Container = createAsyncContainer(ProfessionSwitch, (props) => {
  const {
    params: { slug },
  } = props;

  const variables = { slug };

  const loaders = {
    query: query(STORE_PROFESSION_QUERY, { variables }),
  };

  const selectors = {
    profession: select(STORE_PROFESSION_QUERY, { variables }),
    domain: select(DOMAIN_QUERY, {}),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
