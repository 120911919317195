// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const Notifications = {
  name: 'Notifications',
  bundle: () => import('./Notifications'),
};

const NOTIFICATIONS_QUERY = gql`
  query NotificationsQuery {
    viewer {
      unreadNotificationsCount
      notifications {
        type
        text
        url
        readAt
        updatedAt
        createdAt
      }
    }
  }
`;

const Container = createAsyncContainer(Notifications, () => {
  const loaders = {
    query: query(NOTIFICATIONS_QUERY),
  };

  const selectors = {
    viewer: select(NOTIFICATIONS_QUERY),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
