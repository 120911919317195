// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query, selectByRoot } from 'libs/graphql';

const TeamsStats = {
  name: 'TeamsStats',
  bundle: () => import('./TeamsStats'),
};

const ORGANIZATION_TEAMS_SETTINGS_QUERY = gql`
  query TeamsSettingsQuery($slug: String!, $teamSlug: String!) {
    organization(slug: $slug) {
      name
      slug
      team(teamSlug: $teamSlug) {
        name
        slug
        courses {
          slug
          name
        }
        viewerIsAdmin
      }
    }
  }
`;

const Container = createAsyncContainer(TeamsStats, (props) => {
  const {
    params: { slug, teamSlug },
  } = props;

  const variables = { slug, teamSlug };

  const loaders = {
    query: query(ORGANIZATION_TEAMS_SETTINGS_QUERY, { variables }),
  };

  const selectors = {
    organization: select(ORGANIZATION_TEAMS_SETTINGS_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
