// @flow
import React from 'react';
import { Modal, CloseButton, Nav, Text, Tab } from 'components/core';
import { Video } from 'components/common';

type Props = {
  modal: ToggleState,
};

function AppFirstStepsModal({ modal }: Props): React$Node {
  return (
    <Modal visible={modal.on} onToggle={modal.toggle} size="lg">
      <Modal.Header>
        <Modal.Title>
          <Text>Das ist StBK.Azubinet</Text>
        </Modal.Title>
        <CloseButton onPress={modal.toggle} />
      </Modal.Header>
      <Modal.Body>
        <Tab.Provider defaultActiveTarget="promo">
          <Nav variant="tabs" styleName="mb-3">
            <Nav.Link toggle={Tab} target="promo">
              <Text>Info-Video</Text>
            </Nav.Link>
            <Nav.Link toggle={Tab} target="example">
              <Text>Beispiel-Erklärvideo (Lernfeld 2)</Text>
            </Nav.Link>
          </Nav>
          <Tab.Content>
            <Tab.Pane id="promo">
              <Video
                controls
                source={{ uri: '/video/stbk/stbk-promo-video.mp4' }}
                poster="/video/stbk/stbk-promo-video.jpg"
              />
            </Tab.Pane>
            <Tab.Pane id="example">
              <Video
                controls
                source={{ uri: '/video/stbk/stbk-beispiel-video.mp4' }}
                poster="/video/stbk/stbk-beispiel-video.jpg"
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Provider>
      </Modal.Body>
    </Modal>
  );
}

export default AppFirstStepsModal;
