// @flow
import React, { useRef, useState, useId } from 'react';
import { Dropdown, Input } from 'bootstrap-rn';
import { useIntl } from 'libs/intl';
import { DayPicker } from 'react-day-picker';
import { StyleSheet, css } from 'libs/ui';
import de from 'date-fns/locale/de';

type Props = {
  selectedValue: any,
  onValueChange: (any) => void,
  style: any,
};

const calendarIcon =
  '%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 -256 1850 1850%27%3E%3Cpath d=%27M219.12 1425.864h288v-288h-288v288zm352 0h320v-288h-320v288zm-352-352h288v-320h-288v320zm352 0h320v-320h-320v320zm-352-384h288v-288h-288v288zm736 736h320v-288h-320v288zm-384-736h320v-288h-320v288zm768 736h288v-288h-288v288zm-384-352h320v-320h-320v320zm-352-864v-288q0-13-9.5-22.5t-22.5-9.5h-64q-13 0-22.5 9.5t-9.5 22.5v288q0 13 9.5 22.5t22.5 9.5h64q13 0 22.5-9.5t9.5-22.5zm736 864h288v-320h-288v320zm-384-384h320v-288h-320v288zm384 0h288v-288h-288v288zm32-480v-288q0-13-9.5-22.5t-22.5-9.5h-64q-13 0-22.5 9.5t-9.5 22.5v288q0 13 9.5 22.5t22.5 9.5h64q13 0 22.5-9.5t9.5-22.5zm384-64v1280q0 52-38 90t-90 38h-1408q-52 0-90-38t-38-90v-1280q0-52 38-90t90-38h128v-96q0-66 47-113t113-47h64q66 0 113 47t47 113v96h384v-96q0-66 47-113t113-47h64q66 0 113 47t47 113v96h128q52 0 90 38t38 90z%27 fill=%27%23666%27/%3E%3C/svg%3E';

const styles = StyleSheet.create({
  input: css`
    width: 288px;

    @include platform(web) {
      cursor: default;
    }
  `,
  inputActive: css`
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline-width: 0;
    box-shadow: $input-focus-box-shadow;
  `,
});

const concatRefs = (...refs) => {
  return (element) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(element);
      } else if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = element;
      }
    });
  };
};

const webCss = (strings, ...values) =>
  strings.reduce((result, current, i) => `${result}${current}${values[i]}`, '');

const DatePicker = React.forwardRef<Props, any>((props, ref) => {
  const { selectedValue, onValueChange: handleValueChange, style, ...inputProps } = props;

  const inputRef = useRef();
  const [visible, setVisible] = useState(false);
  const intl = useIntl();
  const id = useId();

  // Define background style as object, because it is not supported by StyleSheet.create.
  const backgroundStyle = {
    backgroundImage: `url("data:image/svg+xml;charset=utf8,${calendarIcon}")`,
    backgroundPosition: 'top 0.675rem right 0.75rem',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 1rem',
  };

  // Inject css web styles by using a <style> tag.
  const webStyles = (
    <style>{webCss`
      .rdp {
        --rdp-cell-size: 2.25rem;
        --rdp-accent-color: ${StyleSheet.value('gray-900')};
        --rdp-background-color: ${StyleSheet.value('gray-100')};
        --rdp-outline: 2px solid ${StyleSheet.value('gray-900')};
        --rdp-outline-selected: 0; /* Outline border for focused _and_ selected elements */

        font-family: ${StyleSheet.value('font-family-base')};
        font-size: 1rem;
        color: ${StyleSheet.value('body-color')};
        margin: calc(0.5rem + 1px) calc(1rem + 1px); /* max-width should be 288px for devices with a width of 320px */
      }

      /* Hide elements for devices that are not screen readers */
      .rdp-vhidden {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        background: transparent;
        border: 0;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        position: absolute !important;
        top: 0;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        overflow: hidden !important;
        clip: rect(1px, 1px, 1px, 1px) !important;
        border: 0 !important;
      }

      /* Buttons */
      .rdp-button_reset {
        appearance: none;
        position: relative;
        margin: 0;
        padding: 0;
        cursor: default;
        color: inherit;
        background: none;
        font: inherit;

        -moz-appearance: none;
        -webkit-appearance: none;
      }

      .rdp-button_reset:focus-visible {
        /* Make sure to reset outline only when :focus-visible is supported */
        outline: none;
      }

      .rdp-button {
        border: 2px solid transparent;
      }

      .rdp-button[disabled]:not(.rdp-day_selected) {
        opacity: 0.25;
      }

      .rdp-button:not([disabled]) {
        cursor: pointer;
      }

      .rdp-button:focus-visible:not([disabled]) {
        color: inherit;
        background-color: var(--rdp-background-color);
        border: var(--rdp-outline);
      }

      .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
        background-color: var(--rdp-background-color);
      }

      .rdp-months {
        display: flex;
      }

      .rdp-month {
        margin: 0 1em;
      }

      .rdp-month:first-child {
        margin-left: 0;
      }

      .rdp-month:last-child {
        margin-right: 0;
      }

      .rdp-table {
        margin: 0;
        max-width: calc(var(--rdp-cell-size) * 7);
        border-collapse: collapse;
      }

      .rdp-with_weeknumber .rdp-table {
        max-width: calc(var(--rdp-cell-size) * 8);
        border-collapse: collapse;
      }

      .rdp-caption {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        text-align: left;
      }

      .rdp-multiple_months .rdp-caption {
        position: relative;
        display: block;
        text-align: center;
      }

      .rdp-caption_dropdowns {
        position: relative;
        display: inline-flex;
      }

      .rdp-caption_label {
        position: relative;
        z-index: 1;
        display: inline-flex;
        align-items: center;
        margin: 0;
        padding: 0 0.25em;
        white-space: nowrap;
        color: currentColor;
        border: 0;
        border: 2px solid transparent;
        font-family: inherit;
        font-size: 1rem;
        font-weight: bold;
      }

      .rdp-nav {
        white-space: nowrap;
      }

      .rdp-multiple_months .rdp-caption_start .rdp-nav {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      .rdp-multiple_months .rdp-caption_end .rdp-nav {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }

      .rdp-nav_button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: var(--rdp-cell-size);
        height: var(--rdp-cell-size);
        padding: 0.25em;
        border-radius: 0.1875rem;
      }

      /* ---------- */
      /* Dropdowns  */
      /* ---------- */

      .rdp-dropdown_year,
      .rdp-dropdown_month {
        position: relative;
        display: inline-flex;
        align-items: center;
      }

      .rdp-dropdown {
        appearance: none;
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 0;
        cursor: inherit;
        opacity: 0;
        border: none;
        background-color: transparent;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
      }

      .rdp-dropdown[disabled] {
        opacity: unset;
        color: unset;
      }

      .rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
        background-color: var(--rdp-background-color);
        border: var(--rdp-outline);
        border-radius: 6px;
      }

      .rdp-dropdown_icon {
        margin: 0 0 0 5px;
      }

      .rdp-head {
        border: 0;
      }

      .rdp-head_row,
      .rdp-row {
        height: 100%;
      }

      .rdp-head_cell {
        vertical-align: middle;
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: 700;
        text-align: center;
        height: 100%;
        height: var(--rdp-cell-size);
        padding: 0;
      }

      .rdp-tbody {
        border: 0;
      }

      .rdp-tfoot {
        margin: 0.5em;
      }

      .rdp-cell {
        width: var(--rdp-cell-size);
        height: 100%;
        height: var(--rdp-cell-size);
        padding: 0;
        text-align: center;
      }

      .rdp-weeknumber {
        font-size: 0.875rem;
      }

      .rdp-weeknumber,
      .rdp-day {
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: var(--rdp-cell-size);
        max-width: var(--rdp-cell-size);
        height: var(--rdp-cell-size);
        margin: 0;
        border: 2px solid transparent;
        border-radius: 0.1875rem;
      }

      .rdp-day_today:not(.rdp-day_outside) {
        font-weight: bold;
      }

      .rdp-day_selected,
      .rdp-day_selected:focus-visible,
      .rdp-day_selected:hover {
        color: white;
        opacity: 1;
        background-color: var(--rdp-accent-color);
      }

      .rdp-day_selected:focus-visible {
        /* Since the background is the same use again the outline */
        outline: var(--rdp-outline);
        outline-offset: 2px;
        z-index: 1;
      }

      .rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .rdp[dir='rtl'] .rdp-day_range_start:not(.rdp-day_range_end) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .rdp[dir='rtl'] .rdp-day_range_end:not(.rdp-day_range_start) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .rdp-day_range_end.rdp-day_range_start {
        border-radius: 0.1875rem;
      }

      .rdp-day_range_middle {
        border-radius: 0;
      }
    `}</style>
  );

  const handleClose = () => {
    setVisible(false);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  // Ref: https://w3c.github.io/aria-practices/examples/combobox/combobox-datepicker.html
  return (
    <Dropdown
      visible={visible}
      onToggle={() => {
        handleClose();
      }}
    >
      <Dropdown.Toggle>
        {({ ref: toggleRef, onPress, ...toggleProps }) => (
          <Input
            {...inputProps}
            {...toggleProps}
            ref={concatRefs(ref, inputRef, toggleRef)}
            role="combobox"
            aria-autocomplete="none"
            aria-haspopup="dialog"
            aria-controls={id}
            onClick={() => {
              setVisible((value) => !value);
            }}
            onKeyPress={(event) => {
              // open dropdown on arrow down or space press
              if (event.keyCode === 40 || event.keyCode === 32) {
                setVisible(true);
                event.preventDefault();
              }
            }}
            value={selectedValue ? selectedValue.toLocaleDateString(intl.locale) : ''}
            onChangeText={undefined}
            readOnly
            selectTextOnFocus={false}
            style={[styles.input, visible && styles.inputActive, backgroundStyle, style]}
          />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu role="dialog" aria-modal="true" id={id}>
        {webStyles}
        <DayPicker
          firstDayOfWeek={1}
          selected={selectedValue}
          defaultMonth={selectedValue || undefined}
          onDayKeyDown={(nextValue, modifiers, event) => {
            // close dropdown on tab or esc press
            if (event.keyCode === 9 || event.keyCode === 27) {
              // close dropdown
              handleClose();
            }

            // set value on space or enter press
            if (event.keyCode === 32 || event.keyCode === 13) {
              event.preventDefault();

              // set value
              handleValueChange(nextValue);

              // close dropdown
              handleClose();
            }
          }}
          onDayClick={(nextValue) => {
            // set value
            handleValueChange(nextValue);

            // close dropdown
            handleClose();
          }}
          locale={intl.locale === 'de' ? de : undefined}
          initialFocus
        />
      </Dropdown.Menu>
    </Dropdown>
  );
});

export default (DatePicker: React$AbstractComponent<Props, any>);
