// @flow
import React from 'react';
import { Button, View, Heading, Text, Image, Modal } from 'components/core';
import { Icon, Video, Well } from 'components/common';
import { StyleSheet, css, useMedia } from 'libs/ui';

type Props = {
  modal: ToggleState,
  onClose?: () => void,
};

const styles = StyleSheet.create({
  heading: css`
    margin-bottom: 1rem;
  `,
  growShrink: css`
    flex-shrink: 1;
  `,
  buttonDesktop: css`
    width: 50%;
    height: 7rem;
  `,
  buttonMobile: css`
    height: 7rem;
  `,
  iconDesktop: css`
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  `,
  iconMobile: css`
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  `,
  kiehlImage: css`
    width: 4rem;
    height: 2rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  `,
});

function StbkCoursesModal({ modal, onClose: handleClose }: Props): React$Node {
  const media = useMedia();

  return (
    <Modal visible={modal.on} onToggle={modal.toggle} size="lg">
      <Modal.Body>
        <Heading size={5} style={styles.heading}>
          <Text>Kostenlose Kurse</Text>
        </Heading>
        <Well styleName="flex-md-row flex-md-wrap flex-shrink-1 jc-md-start ai-md-start mb-3">
          <Button
            color="light"
            to="/courses/x8V1bHNJ42"
            onPress={() => {
              if (!handleClose) {
                return;
              }

              handleClose();
            }}
            size="lg"
            style={[styles.growShrink, media.up('md') ? styles.buttonDesktop : styles.buttonMobile]}
          >
            <View styleName="flex-column flex-shrink-1">
              <View styleName="flex-row ai-center jc-center">
                <Icon
                  glyph="book-open"
                  style={[media.up('md') ? styles.iconDesktop : styles.iconMobile]}
                />
              </View>
              <Text>Zwischenprüfung</Text>
            </View>
          </Button>
          <Button
            color="light"
            to="/courses/hVC9J2S4yq"
            onPress={() => {
              if (!handleClose) {
                return;
              }

              handleClose();
            }}
            size="lg"
            style={[styles.growShrink, media.up('md') ? styles.buttonDesktop : styles.buttonMobile]}
          >
            <View styleName="flex-column">
              <Icon
                glyph="book-open"
                style={[styles.growShrink, media.up('md') ? styles.iconDesktop : styles.iconMobile]}
              />
              <Text>Abschlussprüfung: Steuerwesen</Text>
            </View>
          </Button>
          <Button
            color="light"
            to="/courses/8fHzA3nwn7"
            onPress={() => {
              if (!handleClose) {
                return;
              }

              handleClose();
            }}
            size="lg"
            style={[styles.growShrink, media.up('md') ? styles.buttonDesktop : styles.buttonMobile]}
          >
            <View styleName="flex-column">
              <Icon
                glyph="book-open"
                style={[styles.growShrink, media.up('md') ? styles.iconDesktop : styles.iconMobile]}
              />
              <Text>Abschlussprüfung: Rechnungswesen</Text>
            </View>
          </Button>
          <Button
            color="light"
            to="/courses/QruYd0f5u1"
            onPress={() => {
              if (!handleClose) {
                return;
              }

              handleClose();
            }}
            size="lg"
            style={[styles.growShrink, media.up('md') ? styles.buttonDesktop : styles.buttonMobile]}
          >
            <View styleName="flex-column flex-shrink-1">
              <View styleName="flex-row ai-center jc-center">
                <Icon
                  glyph="book-open"
                  style={[media.up('md') ? styles.iconDesktop : styles.iconMobile]}
                />
              </View>
              <Text>Abschlussprüfung: WiSo</Text>
            </View>
          </Button>
        </Well>
        <Heading size={5} style={styles.heading} styleName="mt-3">
          <Text>Premium-Kurse (kostenpflichtig)</Text>
        </Heading>
        <Well styleName="flex-md-row flex-md-wrap flex-shrink-1 jc-md-start ai-md-start mb-3">
          <Button
            color="light"
            to="/courses/0oWd4bZR1U"
            size="lg"
            style={[styles.growShrink, media.up('md') ? styles.buttonDesktop : styles.buttonMobile]}
          >
            <View styleName="flex-column flex-shrink-1">
              <View styleName="flex-row ai-center jc-center">
                <Icon
                  glyph="book-open"
                  style={[media.up('md') ? styles.iconDesktop : styles.iconMobile]}
                />
              </View>
              <Text>1. Lehrjahr</Text>
            </View>
          </Button>
          <Button
            color="light"
            to="/search/?q=kiehl"
            onPress={() => {
              if (!handleClose) {
                return;
              }

              handleClose();
            }}
            size="lg"
            style={[styles.growShrink, media.up('md') ? styles.buttonDesktop : styles.buttonMobile]}
          >
            <View styleName="flex-column flex-shrink-1">
              <View styleName="flex-row ai-center jc-center">
                <Image source="/img/home/logo-kiehl.png" style={styles.kiehlImage} />
              </View>
              <Text>Kurse von KIEHL</Text>
            </View>
          </Button>
        </Well>
        <Heading size={5} style={styles.heading} styleName="mt-3">
          <Text>Tutorial-Video zum Einstieg</Text>
        </Heading>
        <Video
          controls
          source={{ uri: '/video/2023-08-13_azubinet-stbk-dashboard-tutorial.mp4' }}
          poster="/video/2023-08-13_azubinet-stbk-dashboard-tutorial.jpg"
        />
      </Modal.Body>
    </Modal>
  );
}

export default StbkCoursesModal;
