// @flow
import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { StyleSheet, css } from 'libs/ui';
import { getVisibleItems } from 'utils/misc';
import { useToggleState, useValueState } from 'libs/misc';
import { Editor } from 'components/editor';
import { Form } from 'components/forms';
import { Avatar } from 'components/common';
import { View, Link } from 'components/core';
import { useDispatch } from 'libs/graphql';
import ProfilePopover from 'views/_shared/ProfilePopover';
import Post from './Post';
import { createPost } from './mutations';

type Props = {
  courseId: Id,
  itemType: 'TaskIntro' | 'TaskPart' | 'OpenQuestionSolution',
  itemId: Id,
  viewer: Entity<GQLUser>,
  thread: Entity<GQLThread>,
};

const dict = createDict({
  reply: 'Reply',
  show_replies: '{count} {count, plural, =1 {reply} other {replies}}',
});

const styles = StyleSheet.create({
  container: css`
    border-top-width: 1px;
    border-top-color: $gray-300;
    padding-top: 0.75rem;
  `,
  showMore: css`
    margin-top: -0.5rem;
    margin-bottom: 0.25rem;
    padding-top: 1rem;
    margin-left: 0.9375rem;
    border-left-width: 0.125rem;
    @include platform(web) {
      border-style: dotted;
    }
    border-color: #c8d1d3;
    padding-left: 1.6875rem;
    padding-bottom: 1rem;
  `,
  showMoreLast: css`
    padding-bottom: 0;
    margin-bottom: 1rem;
  `,
  input: css`
    flex-direction: row;
  `,
  avatar: css`
    flex-basis: 2.75rem;
  `,
  editor: css`
    flex-grow: 1;
    flex-shrink: 1;
    margin-bottom: 1rem;
  `,
});

function Thread({ courseId, itemType, itemId, viewer, thread }: Props): React$Node {
  const dispatch = useDispatch();
  const editor = useToggleState(false);
  const expanded = useToggleState(false);
  const added = useValueState([]);

  const visibleReplies = expanded.on
    ? thread.replies
    : getVisibleItems([...thread.replies].reverse(), thread.post, added.value, 1).reverse();

  return (
    <View style={[styles.container]}>
      <Post
        courseId={courseId}
        itemType={itemType}
        itemId={itemId}
        post={thread.opening}
        onReplyPress={() => editor.setOn()}
        last={!editor.on && thread.replies.length === 0}
        thread={thread}
        opening
      />
      {thread.replies.length > visibleReplies.length && (
        <View
          styleName="flex-row jc-start"
          style={[
            styles.showMore,
            visibleReplies.length === 0 && !editor.on && styles.showMoreLast,
          ]}
        >
          <Link onPress={() => expanded.setOn()}>
            <Trans
              {...dict('show_replies', { count: thread.replies.length - visibleReplies.length })}
              small
            />
          </Link>
        </View>
      )}
      {visibleReplies.map((post, key) => (
        <Post
          courseId={courseId}
          itemType={itemType}
          itemId={itemId}
          post={post}
          onReplyPress={() => editor.setOn()}
          key={post.id}
          last={!editor.on && key === visibleReplies.length - 1}
          thread={thread}
        />
      ))}
      <Form
        initialValues={{
          text: null,
        }}
        onSubmit={async (values, actions) => {
          try {
            const data = await dispatch(createPost(thread.id, values.text));

            added.set((value) => [...value, data.roots.threadPost_create.link[1]]);

            actions.resetForm();
          } catch (err) {
            actions.setFieldValue('text', values.text);
            editor.setOn();
            throw err;
          }

          editor.setOff();
        }}
      >
        {editor.on && (
          <View style={styles.input}>
            <ProfilePopover user={viewer} style={styles.avatar}>
              <Avatar avatar={viewer.avatar} />
            </ProfilePopover>
            <View style={styles.editor}>
              <Editor name="text" size="sm" autoFocus />
              <View styleName="flex-row jc-end mt-n2">
                <Form.Button type="submit" size="sm">
                  <Trans {...dict('reply')} />
                </Form.Button>
              </View>
            </View>
          </View>
        )}
      </Form>
    </View>
  );
}

export default Thread;
