// @flow

// alternative: const youtubeLinkRegExp = /^https?:\/\/(youtu.be\/|(www\.)?youtube.com\/(v\/|u\/\w\/|embed\/|watch\?v=|&v=))([^#&?]*)(\?|&|$)/;

const youtubeLinkRegExp =
  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

export default function getYoutubeId(link: string): ?string {
  const match = link.match(youtubeLinkRegExp);

  // alternative: match && match[4].length === 11 ? match[4] : null;

  if (match && match.length !== 0) {
    return match[1] !== null && match[1] !== undefined ? match[1] : null;
  }

  return null;
}
