// @flow
import React from 'react';
import { Text, Dropdown } from 'components/core';

type Props = {
  profession: ValueState,
  children: React$Node,
};

function MobileDropdown({ profession, children }: Props): React$Node {
  return (
    <Dropdown>
      {children}
      <Dropdown.Menu>
        <Dropdown.Item
          onPress={() => {
            profession.set('Automobilkaufleute');
          }}
          active={profession.value === 'Automobilkaufleute'}
        >
          <Text>Beruf auswählen</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            profession.set('Automobilkaufleute');
          }}
          active={profession.value === 'Automobilkaufleute'}
        >
          <Text>Automobilkaufleute</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            profession.set('Drogist/-innen');
          }}
          active={profession.value === 'Drogist/-innen'}
        >
          <Text>Drogist/-innen</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            profession.set('Fachinformatiker/-innen und IT-Berufe');
          }}
          active={profession.value === 'Fachinformatiker/-innen und IT-Berufe'}
        >
          <Text>Fachinformatiker/-innen und IT-Berufe</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            profession.set('Fachkräfte für Lagerlogistik');
          }}
          active={profession.value === 'Fachkräfte für Lagerlogistik'}
        >
          <Text>Fachkräfte für Lagerlogistik</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            profession.set('Fachlagerist/-innen');
          }}
          active={profession.value === 'Fachlagerist/-innen'}
        >
          <Text>Fachlagerist/-innen</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            profession.set('Florist/-innen');
          }}
          active={profession.value === 'Florist/-innen'}
        >
          <Text>Florist/-innen</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            profession.set('Industriekaufleute');
          }}
          active={profession.value === 'Industriekaufleute'}
        >
          <Text>Industriekaufleute</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            profession.set('Hotelfachleute');
          }}
          active={profession.value === 'Hotelfachleute'}
        >
          <Text>Hotelfachleute</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            profession.set('Kaufleute für Büromanagement');
          }}
          active={profession.value === 'Kaufleute für Büromanagement'}
        >
          <Text>Kaufleute für Büromanagement</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            profession.set('Kaufleute für Groß- und Außenhandelsmanagement');
          }}
          active={profession.value === 'Kaufleute für Groß- und Außenhandelsmanagement'}
        >
          <Text>Kaufleute für Groß- und Außenhandelsmanagement</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            profession.set('Kaufleute für Spedition und Logistikdienstleistung');
          }}
          active={profession.value === 'Kaufleute für Spedition und Logistikdienstleistung'}
        >
          <Text>Kaufleute für Spedition und Logistikdienstleistung</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            profession.set('Kaufleute im E-Commerce');
          }}
          active={profession.value === 'Kaufleute im E-Commerce'}
        >
          <Text>Kaufleute im E-Commerce</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            profession.set('Kaufleute im Einzelhandel');
          }}
          active={profession.value === 'Kaufleute im Einzelhandel'}
        >
          <Text>Kaufleute im Einzelhandel</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            profession.set('Kaufleute im Gesundheitswesen');
          }}
          active={profession.value === 'Kaufleute im Gesundheitswesen'}
        >
          <Text>Kaufleute im Gesundheitswesen</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            profession.set('Köche');
          }}
          active={profession.value === 'Köche'}
        >
          <Text>Köche</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            profession.set('Medizinische Fachangestellte');
          }}
          active={profession.value === 'Medizinische Fachangestellte'}
        >
          <Text>Medizinische Fachangestellte</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            profession.set('Personaldienstleistungskaufleute');
          }}
          active={profession.value === 'Personaldienstleistungskaufleute'}
        >
          <Text>Personaldienstleistungskaufleute</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            profession.set('Restaurantfachleute');
          }}
          active={profession.value === 'Restaurantfachleute'}
        >
          <Text>Restaurantfachleute</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            profession.set('Steuerfachangestellte');
          }}
          active={profession.value === 'Steuerfachangestellte'}
        >
          <Text>Steuerfachangestellte</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            profession.set('Verkäufer/-innen');
          }}
          active={profession.value === 'Verkäufer/-innen'}
        >
          <Text>Verkäufer/-innen</Text>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default MobileDropdown;
