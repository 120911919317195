// @flow
import { createContainer } from 'libs/container';
import { select } from 'libs/graphql';
import { DOMAIN_QUERY } from 'libs/foundation/queries';
import { VIEWER_QUERY } from 'views/_root/App/Container/queries';
import Navigation from './Navigation';

const Container = createContainer(Navigation, () => {
  const selectors = {
    viewer: select(VIEWER_QUERY, {}),
    domain: select(DOMAIN_QUERY, {}),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
