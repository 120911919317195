// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import { MENU_CHECKLIST_TODO_ACTIVITY_FRAGMENT } from './ChecklistTodoCourseActivity/fragments';
import { MENU_CHECKLIST_TODO_CUSTOM_FRAGMENT } from './ChecklistTodoCustom/fragments';

export const VIEWER_MENU_FRAGMENT: DocumentNode = gql`
  fragment ViewerMenuFragment on User {
    isOrganizationAdminOrTrainer
    checklistResults {
      checklist {
        slug
        name
        todosCount
        todos {
          position
          item {
            ...MenuChecklistTodoActivityFragment
            ...MenuChecklistTodoCustomFragment
          }
        }
      }
      doneUntil
      todoResultsCount
    }
    teamMemberships {
      team {
        name
        slug
        courses {
          slug
          name
          caption
        }
        organization {
          name
          slug
        }
      }
    }
    courseMemberships(withTeams: true) {
      role
      licensed {
        validUntil
      }
      createdAt
      course {
        slug
        name
        caption
      }
    }
    promotedCourses {
      slug
      name
      caption
    }
    organizationMemberships {
      role
      organization {
        name
        slug
        viewerTraineeMemberships {
          user {
            name
            lastSeenAt
            avatar {
              file {
                name
                extension
                info {
                  image {
                    height
                    width
                  }
                }
              }
            }
            checklistResults {
              checklist {
                slug
                name
                todosCount
              }
              doneUntil
              todoResultsCount
            }
          }
        }
        viewerIsAdmin
        viewerIsTrainer
      }
    }
  }

  ${MENU_CHECKLIST_TODO_ACTIVITY_FRAGMENT}
  ${MENU_CHECKLIST_TODO_CUSTOM_FRAGMENT}
`;
