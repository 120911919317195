// @flow

type Props = {
  modal: ToggleState,
};

// eslint-disable-next-line no-unused-vars
function DomainPickerModal({ modal }: Props): React$Node {
  return null;
}

export default DomainPickerModal;
