// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';
import { DOMAIN_QUERY } from 'libs/foundation/queries';

const CourseSettings = {
  name: 'CourseSettings',
  bundle: () => import('./CourseSettings'),
};

const COURSE_SETTINGS_QUERY = gql`
  query CourseSettingsQuery($courseSlug: String!) {
    course(slug: $courseSlug) {
      name
      paid
      organization {
        ownsDomain
        domain {
          isMain
        }
      }
      caption
      description
      restricted
      viewerIsAdmin
      features {
        hasTasks
        hasQuizzes
        hasVideos
        hasArticles
        hasDocuments
        hasForum
        hasSurveys
        hasTilesView
        hasSimplifiedView
      }
    }
  }
`;

const Container = createAsyncContainer(CourseSettings, (props) => {
  const {
    params: { courseSlug },
  } = props;

  const variables = { courseSlug };

  const loaders = {
    query: query(COURSE_SETTINGS_QUERY, {
      variables,
    }),
  };

  const selectors = {
    course: select(COURSE_SETTINGS_QUERY, { variables }),
    domain: select(DOMAIN_QUERY, {}),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
