// @flow
import React from 'react';
import { Grid, View, Text } from 'components/core';
import { Icon } from 'components/common';
import { BrandHeading, BrandLargeText } from 'components/pages';
import { StyleSheet, css } from 'libs/ui';

const styles = StyleSheet.create({
  feature: css`
    flex-direction: row;
    flex-shrink: 1;
    padding-top: 1rem;
  `,
  icon: css`
    margin-right: 1rem;
    color: #001f3f;
  `,
  largeText: css`
    text-align: left;
  `,
});

function FeaturesSection(): React$Node {
  return (
    <View styleName="mx-3 mx-md-0">
      <View styleName="jc-center ai-center mb-4">
        <BrandHeading size={2} styleName="mb-3">
          <Text>Üben für die Fahrradprüfung</Text>
        </BrandHeading>
      </View>
      <Grid>
        <Grid.Box size={12} sizeMd={6}>
          <View styleName="p-md-4">
            <BrandHeading size={3} styleName="mb-3">
              <Icon glyph="ok-circled" styleName="mr-3" />
              <Text>Prüfungstraining</Text>
            </BrandHeading>
            <View style={styles.feature} styleName="mb-3">
              <BrandLargeText style={styles.largeText}>
                <Text>
                  Hier kannst du testen, ob du schon gut vorbereitet bist. Das Prüfungstraining
                  sieht genau aus wie der echte Prüfungsbogen! Wenn du abgibst, erfährst du auch
                  gleich, ob du bestanden hättest.
                </Text>
              </BrandLargeText>
            </View>
          </View>
        </Grid.Box>
        <Grid.Box size={12} sizeMd={6}>
          <View styleName="p-md-4 mt-3 mt-md-0">
            <BrandHeading size={3} styleName="mb-3">
              <Icon glyph="cards" styleName="mr-3" />
              <Text>Lernkarten</Text>
            </BrandHeading>
            <View style={styles.feature} styleName="mb-3">
              <BrandLargeText style={styles.largeText}>
                <Text>
                  Übe mit abwechslungsreichen Fragen alle Themen der Fahrradprüfung. Nachdem du eine
                  Frage beantwortet hast, erfährst du nicht nur, welche Auswahlmöglichkeit richtig
                  ist. Der Drache Bode erklärt dir auch, warum diese Antwort zutrifft.
                </Text>
              </BrandLargeText>
            </View>
          </View>
        </Grid.Box>
      </Grid>
    </View>
  );
}

export default FeaturesSection;
