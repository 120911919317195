// @flow
import { gql, createMutation } from 'libs/graphql';

const REGISTER_USER = gql`
  mutation RegisterUser($input: AuthRegisterInput!) {
    auth_register(input: $input) {
      id
    }
  }
`;

export function registerUser(
  firstName: string,
  lastName: string,
  email: string,
  organization: string,
  password: string,
): MutationAction {
  return createMutation(REGISTER_USER, {
    variables: {
      input: {
        firstName,
        lastName,
        email,
        organization,
        password,
      },
    },
  });
}
