// @flow
import { LINE_FEED, SPACE, TAB } from './chars';

const fillers = [LINE_FEED, SPACE, TAB];

export default function countTrailingSpaces(value: string, reverse: boolean = false): number {
  const start = reverse ? value.length - 1 : 0;
  let count = 0;

  while (fillers.includes(value.charAt(start + count))) {
    if (reverse) {
      count -= 1;
    } else {
      count += 1;
    }
  }

  return Math.abs(count);
}
