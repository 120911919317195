// @flow
import React from 'react';
import { Route, Routes, Navigate } from 'react-router';

// 1) app layout
import AppLayout from './views/(app)/_layout';

// checklist routes
import ChecklistsDetails from './views/(app)/(checklists)/checklists.[slug]';
import ChecklistsEditor from './views/(app)/(checklists)/checklists.[slug].editor';
import ChecklistsCreate from './views/(app)/(checklists)/checklists.create';
import MyChecklists from './views/(app)/(checklists)/my-checklists';
// report routes
import MyReports from './views/(app)/(reports)/my-reports';
import MyReportsDetails from './views/(app)/(reports)/my-reports.[reportId]';
import MyReportsCreate from './views/(app)/(reports)/my-reports.create';
import ReportsCollection from './views/(app)/(reports)/reports.[collectionId]';
import Reports$Details from './views/(app)/(reports)/reports.[collectionId].[reportId]';
// checkout routes
import Checkout$Home from './views/(app)/checkout.[purchaseId]/$home';
import Checkout$ConfirmSofort from './views/(app)/checkout.[purchaseId]/confirm-sofort';
// course routes
import Courses$Layout from './views/(app)/courses.[courseSlug]/_layout';
import Courses$Home from './views/(app)/courses.[courseSlug]/$home';
import Courses$Articles from './views/(app)/courses.[courseSlug]/articles';
import Courses$ArticlesDetails from './views/(app)/courses.[courseSlug]/articles.[slug]';
import Courses$ArticlesCreate from './views/(app)/courses.[courseSlug]/articles.create';
import Courses$ArticlesEditor from './views/(app)/courses.[courseSlug]/articles.[slug].editor';
import Courses$Flashcards from './views/(app)/courses.[courseSlug]/cards';
import Courses$FlashcardsDiscussion from './views/(app)/courses.[courseSlug]/cards.[slug]';
import Courses$FlashcardsEditor from './views/(app)/courses.[courseSlug]/cards.[slug].editor';
import Courses$FlashcardsCreate from './views/(app)/courses.[courseSlug]/cards.create';
import Courses$FlashcardsQuizResult from './views/(app)/courses.[courseSlug]/cards.[slug].results.[resultId]';
import Courses$Documents from './views/(app)/courses.[courseSlug]/documents';
import Courses$DocumentsCreate from './views/(app)/courses.[courseSlug]/documents.create';
import Courses$DocumentsDetails from './views/(app)/courses.[courseSlug]/documents.[slug]';
import Courses$DocumentsDetailsEditor from './views/(app)/courses.[courseSlug]/documents.[slug].editor';
import Courses$MyStats from './views/(app)/courses.[courseSlug]/my-stats';
import Courses$SettingsLayout from './views/(app)/courses.[courseSlug]/settings/_layout';
import Courses$SettingsHome from './views/(app)/courses.[courseSlug]/settings/$home';
import Courses$SettingsAccess from './views/(app)/courses.[courseSlug]/settings/access';
import Courses$SettingsCategories from './views/(app)/courses.[courseSlug]/settings/categories';
import Courses$SettingsMembers from './views/(app)/courses.[courseSlug]/settings/members';
import Courses$SettingsPermissions from './views/(app)/courses.[courseSlug]/settings/permissions';
import Courses$Stats from './views/(app)/courses.[courseSlug]/stats';
import Courses$StatsDetails from './views/(app)/courses.[courseSlug]/stats.[userId]';
import Courses$Surveys from './views/(app)/courses.[courseSlug]/surveys';
import Courses$SurveysDetails from './views/(app)/courses.[courseSlug]/surveys.[slug]';
import Courses$SurveysEditor from './views/(app)/courses.[courseSlug]/surveys.[slug].editor';
import Courses$SurveysCreate from './views/(app)/courses.[courseSlug]/surveys.create';
import Courses$Tasks from './views/(app)/courses.[courseSlug]/tasks';
import Courses$TasksDiscussion from './views/(app)/courses.[courseSlug]/tasks.[slug]';
import Courses$TasksEditor from './views/(app)/courses.[courseSlug]/tasks.[slug].editor';
import Courses$TasksCreate from './views/(app)/courses.[courseSlug]/tasks.create';
import Courses$TasksExamResult from './views/(app)/courses.[courseSlug]/tasks.exams.[slug].[attemptId]';
import Courses$Topics from './views/(app)/courses.[courseSlug]/topics';
import Courses$TopicsDetails from './views/(app)/courses.[courseSlug]/topics.[slug]';
import Courses$TopicsCreate from './views/(app)/courses.[courseSlug]/topics.create';
import Courses$Videos from './views/(app)/courses.[courseSlug]/videos';
import Courses$VideosDetails from './views/(app)/courses.[courseSlug]/videos.[slug]';
import Courses$VideosDetailsEditor from './views/(app)/courses.[courseSlug]/videos.[slug].editor';
import Courses$VideosCreate from './views/(app)/courses.[courseSlug]/videos.create';
import CoursesCreate from './views/(app)/courses.create';
import CoursesJoin from './views/(app)/courses.join';
// dashboard route
import Dashboard from './views/(app)/dashboard';
// experimental routes
import ExperimentalElements from './views/(app)/experimental/elements';
import ExperimentalFormElements from './views/(app)/experimental/form-elements';
import ExperimentalLang from './views/(app)/experimental/lang';
// grades route
import Grades from './views/(app)/grades';
// notifications route
import Notifications from './views/(app)/notifications';
// organization routes
import Organizations$Layout from './views/(app)/organizations.[slug]/_layout';
import Organizations$Home from './views/(app)/organizations.[slug]/$home';
import Organizations$Checklists from './views/(app)/organizations.[slug]/checklists';
import Organizations$Courses from './views/(app)/organizations.[slug]/courses';
import Organizations$DomainLayout from './views/(app)/organizations.[slug]/domain/_layout';
import Organizations$DomainHome from './views/(app)/organizations.[slug]/domain/$home';
import Organizations$DomainUsers from './views/(app)/organizations.[slug]/domain/users';
import Organizations$DomainOrganizations from './views/(app)/organizations.[slug]/domain/organizations';
import Organizations$DomainPendingInquiries from './views/(app)/organizations.[slug]/domain/pending-inquiries';
import Organizations$DomainSubdomains from './views/(app)/organizations.[slug]/domain/subdomains';
import Organizations$DomainPermissions from './views/(app)/organizations.[slug]/domain/permissions';
import Organizations$DomainDesign from './views/(app)/organizations.[slug]/domain/design';
import Organizations$DomainSeo from './views/(app)/organizations.[slug]/domain/seo';
import Organizations$DomainPwa from './views/(app)/organizations.[slug]/domain/pwa';
import Organizations$Licenses from './views/(app)/organizations.[slug]/licenses';
import Organizations$Monetization from './views/(app)/organizations.[slug]/monetization';
import Organizations$Monetization$Layout from './views/(app)/organizations.[slug]/monetization.[productSlug]/_layout';
import Organizations$Monetization$Home from './views/(app)/organizations.[slug]/monetization.[productSlug]/$home';
import Organizations$Monetization$Keys from './views/(app)/organizations.[slug]/monetization.[productSlug]/keys';
import Organizations$Monetization$Licenses from './views/(app)/organizations.[slug]/monetization.[productSlug]/licenses';
import Organizations$Monetization$Purchases from './views/(app)/organizations.[slug]/monetization.[productSlug]/purchases';
import Organizations$Profile from './views/(app)/organizations.[slug]/profile';
import Organizations$Settings from './views/(app)/organizations.[slug]/settings';
import Organizations$Teams from './views/(app)/organizations.[slug]/teams';
import Organizations$TeamsCreate from './views/(app)/organizations.[slug]/teams.create';
import Organizations$Users from './views/(app)/organizations.[slug]/users';
import Organizations$UsersDetails from './views/(app)/organizations.[slug]/users.[membershipId]';
import Organizations$UsersCreate from './views/(app)/organizations.[slug]/users.create';
import Organizations$UsersInvitations from './views/(app)/organizations.[slug]/users.invitations';
// organization team routes
import Organizations$TeamsLayout from './views/(app)/organizations.[slug].teams.[teamSlug]/_layout';
import Organizations$TeamsHome from './views/(app)/organizations.[slug].teams.[teamSlug]/$home';
import Organizations$TeamsCourses from './views/(app)/organizations.[slug].teams.[teamSlug]/courses';
import Organizations$TeamsDocuments from './views/(app)/organizations.[slug].teams.[teamSlug]/documents';
import Organizations$TeamsMembers from './views/(app)/organizations.[slug].teams.[teamSlug]/members';
import Organizations$TeamsSettings from './views/(app)/organizations.[slug].teams.[teamSlug]/settings';
import Organizations$TeamsStats from './views/(app)/organizations.[slug].teams.[teamSlug]/stats';
// search routes
import Search from './views/(app)/search';
// settings routes
import SettingsLayout from './views/(app)/settings/_layout';
import SettingsAccount from './views/(app)/settings/account';
import SettingsLicenses from './views/(app)/settings/licenses';
import SettingsProfile from './views/(app)/settings/profile';

// 2) auth layout
import AuthLayout from './views/(auth)/_layout';

import AcceptInvitation from './views/(auth)/accept-invitation.[userId].[token]';
import ForgotPassword from './views/(auth)/forgot-password';
import Login from './views/(auth)/login';
import Organizations$Join from './views/(auth)/organizations.[slug].join.[invitationId]';
import Register from './views/(auth)/register';
import ResendVerification from './views/(auth)/resend-verification';
import ResetPassword from './views/(auth)/reset-password.[userId].[token]';
import VerifyAccount from './views/(auth)/verify-account.[userId].[token]';
import VerifyEmail from './views/(auth)/verify-email.[emailId].[token]';

// 3) pages layout
import PagesLayout from './views/(pages)/_layout';

import Home from './views/(pages)/$home';
import AboutUs from './views/(pages)/about-us';
import BlogHome from './views/(pages)/(blog)/blog';
import BlogDetails from './views/(pages)/(blog)/blog.[slug]';
import BlogCreate from './views/(pages)/(blog)/blog.create';
import Business from './views/(pages)/business';
import Career from './views/(pages)/career';
import Cashback from './views/(pages)/cashback';
import Community from './views/(pages)/community';
import Education from './views/(pages)/education';
import FAQ from './views/(pages)/faq';
import IHKPruefungsfit from './views/(pages)/ihk-pruefungsfit';
import LegalContact from './views/(pages)/legal-contact';
import MasteringMarkdown from './views/(pages)/mastering-markdown';
import Partners from './views/(pages)/partners';
import PrivacyPolicy from './views/(pages)/privacy-policy';
import PublicCourses from './views/(pages)/public-courses';
import PublishersAuthors from './views/(pages)/publishers-authors';
import RecordBook from './views/(pages)/record-book';
import RequestAccess from './views/(pages)/request-access';
import RewardSystem from './views/(pages)/reward-system';
import Special from './views/(pages)/special';
import ProfessionsHome from './views/(pages)/professions/$home';
import ProfessionsDetails from './views/(pages)/professions/[slug]';
import ProfessionsProductsDetails from './views/(pages)/professions/products.[productId]';
import TermsOfUse from './views/(pages)/terms-of-use';
import VerkehrserziehungTeachersParents from './views/(pages)/verkehrserziehung-teachers-parents';

// 4) theater layout
import TheaterLayout from './views/(theater)/_layout';

import Courses$FlashcardsQuizMode from './views/(theater)/courses.[courseSlug].cards.quiz-mode.[slug]';
import Courses$TasksExamMode from './views/(theater)/courses.[courseSlug].tasks.exam-mode';

// 5) pdf layout
import PdfLayout from './views/pdf/_layout';

import PdfCourses$ArticlesDetails from './views/pdf/courses.[courseSlug].articles.[slug]';
import PdfCourses$FlashcardsDiscussion from './views/pdf/courses.[courseSlug].cards.[slug]';
import PdfCourses$TasksDiscussion from './views/pdf/courses.[courseSlug].tasks.[slug]';
import PdfReportsCollection from './views/pdf/reports.[collectionId]';
import PdfReports$Details from './views/pdf/reports.[collectionId].[reportId]';

// error route
import { ErrorPage } from './components/common';

// Hint: We need to add "root" to each page root component in order to fire an
// analytics pageview in withAnalytics.

/* eslint-disable react/jsx-pascal-case */
const routes = (): React$Node => (
  <Routes>
    {/* app routes */}
    <Route element={<AppLayout />}>
      {/* checklists routes */}
      <Route path="checklists/:slug" element={<ChecklistsDetails root />} />
      <Route path="checklists/:slug/editor" element={<ChecklistsEditor root />} />
      <Route path="checklists/create" element={<ChecklistsCreate root />} />
      <Route path="my-checklists" element={<MyChecklists root />} />
      {/* reports routes */}
      <Route path="my-reports/" element={<MyReports root />} />
      <Route path="my-reports/:reportId" element={<MyReportsDetails root />} />
      <Route path="my-reports/create" element={<MyReportsCreate root />} />
      <Route path="reports/:collectionId" element={<ReportsCollection root />} />
      <Route path="reports/:collectionId/:reportId" element={<Reports$Details root />} />
      {/* checkout routes */}
      <Route path="checkout/:purchaseId" element={<Checkout$Home root />} />
      <Route path="checkout/:purchaseId/confirm-sofort" element={<Checkout$ConfirmSofort root />} />
      {/* courses routes */}
      <Route path="courses/:courseSlug" element={<Courses$Layout />}>
        <Route index element={<Courses$Home root />} />
        <Route path="articles" element={<Courses$Articles root />} />
        <Route path="articles/:slug" element={<Courses$ArticlesDetails root />} />
        <Route path="articles/create" element={<Courses$ArticlesCreate root />} />
        <Route path="articles/:slug/editor" element={<Courses$ArticlesEditor root />} />
        <Route path="cards" element={<Courses$Flashcards root />} />
        <Route path="cards/:slug/editor" element={<Courses$FlashcardsEditor root />} />
        <Route path="cards/:slug" element={<Courses$FlashcardsDiscussion root />} />
        <Route path="cards/create" element={<Courses$FlashcardsCreate root />} />
        <Route
          path="cards/:slug/results/:resultId"
          element={<Courses$FlashcardsQuizResult root />}
        />
        <Route path="documents" element={<Courses$Documents root />} />
        <Route path="documents/:slug" element={<Courses$DocumentsDetails root />} />
        <Route path="documents/create" element={<Courses$DocumentsCreate root />} />
        <Route path="documents/:slug/editor" element={<Courses$DocumentsDetailsEditor root />} />
        <Route path="my-stats" element={<Courses$MyStats root />} />
        <Route path="settings" element={<Courses$SettingsLayout />}>
          <Route index element={<Courses$SettingsHome root />} />
          <Route path="access" element={<Courses$SettingsAccess root />} />
          <Route path="categories" element={<Courses$SettingsCategories root />} />
          <Route path="members" element={<Courses$SettingsMembers root />} />
          <Route path="permissions" element={<Courses$SettingsPermissions root />} />
        </Route>
        <Route path="stats" element={<Courses$Stats root />} />
        <Route path="stats/:userId" element={<Courses$StatsDetails root />} />
        <Route path="surveys" element={<Courses$Surveys root />} />
        <Route path="surveys/:slug/editor" element={<Courses$SurveysEditor root />} />
        <Route path="surveys/:slug" element={<Courses$SurveysDetails root />} />
        <Route path="surveys/create" element={<Courses$SurveysCreate root />} />
        <Route path="tasks" element={<Courses$Tasks root />} />
        <Route path="tasks/:slug" element={<Courses$TasksDiscussion root />} />
        <Route path="tasks/:slug/editor" element={<Courses$TasksEditor root />} />
        <Route path="tasks/create" element={<Courses$TasksCreate root />} />
        <Route path="tasks/exams/:slug/:attemptId" element={<Courses$TasksExamResult root />} />
        <Route path="topics" element={<Courses$Topics root />} />
        <Route path="topics/:slug" element={<Courses$TopicsDetails root />} />
        <Route path="topics/create" element={<Courses$TopicsCreate root />} />
        <Route path="videos" element={<Courses$Videos root />} />
        <Route path="videos/:slug" element={<Courses$VideosDetails root />} />
        <Route path="videos/:slug/editor" element={<Courses$VideosDetailsEditor root />} />
        <Route path="videos/create" element={<Courses$VideosCreate root />} />
      </Route>
      <Route path="courses/create" element={<CoursesCreate root />} />
      <Route path="courses/join" element={<CoursesJoin />} />
      {/* dashboard route */}
      <Route path="dashboard" element={<Dashboard root />} />
      {/* experimental routes */}
      <Route path="experimental/elements/*" element={<ExperimentalElements root />} />
      <Route path="experimental/form-elements" element={<ExperimentalFormElements root />} />
      <Route path="experimental/lang" element={<ExperimentalLang root />} />
      {/* grades route */}
      <Route path="grades" element={<Grades root />} />
      {/* notifications route */}
      <Route path="notifications" element={<Notifications root />} />
      {/* organizations routes */}
      <Route path="organizations/:slug" element={<Organizations$Layout />}>
        <Route index element={<Organizations$Home root />} />
        <Route path="checklists" element={<Organizations$Checklists root />} />
        <Route path="courses" element={<Organizations$Courses root />} />
        <Route path="domain" element={<Organizations$DomainLayout />}>
          <Route index element={<Organizations$DomainHome root />} />
          <Route path="users" element={<Organizations$DomainUsers root />} />
          <Route path="organizations" element={<Organizations$DomainOrganizations root />} />
          <Route path="pending-inquiries" element={<Organizations$DomainPendingInquiries root />} />
          <Route path="subdomains" element={<Organizations$DomainSubdomains root />} />
          <Route path="design" element={<Organizations$DomainDesign root />} />
          <Route path="permissions" element={<Organizations$DomainPermissions root />} />
          <Route path="seo" element={<Organizations$DomainSeo root />} />
          <Route path="pwa" element={<Organizations$DomainPwa root />} />
        </Route>
        <Route path="licenses" element={<Organizations$Licenses root />} />
        <Route path="monetization" element={<Organizations$Monetization root />} />
        <Route path="monetization/:productSlug" element={<Organizations$Monetization$Layout />}>
          <Route index element={<Organizations$Monetization$Home root />} />
          <Route path="keys" element={<Organizations$Monetization$Keys root />} />
          <Route path="licenses" element={<Organizations$Monetization$Licenses root />} />
          <Route path="purchases" element={<Organizations$Monetization$Purchases root />} />
        </Route>
        <Route path="profile" element={<Organizations$Profile root />} />
        <Route path="settings" element={<Organizations$Settings root />} />
        <Route path="teams" element={<Organizations$Teams root />} />
        <Route path="teams/create" element={<Organizations$TeamsCreate root />} />
        <Route path="users" element={<Organizations$Users root />} />
        <Route path="users/:membershipId" element={<Organizations$UsersDetails root />} />
        <Route path="users/create" element={<Organizations$UsersCreate root />} />
        <Route path="users/invitations" element={<Organizations$UsersInvitations root />} />
      </Route>
      {/* organization teams routes */}
      <Route path="organizations/:slug/teams/:teamSlug" element={<Organizations$TeamsLayout />}>
        <Route index element={<Organizations$TeamsHome root />} />
        <Route path="courses" element={<Organizations$TeamsCourses root />} />
        <Route path="documents" element={<Organizations$TeamsDocuments root />} />
        <Route path="members" element={<Organizations$TeamsMembers root />} />
        <Route path="settings" element={<Organizations$TeamsSettings root />} />
        <Route path="stats" element={<Organizations$TeamsStats root />} />
      </Route>
      {/* search route */}
      <Route path="search" element={<Search root />} />
      {/* settings routes */}
      <Route path="settings" element={<SettingsLayout />}>
        <Route path="account" element={<SettingsAccount root />} />
        <Route path="licenses" element={<SettingsLicenses root />} />
        <Route path="profile" element={<SettingsProfile root />} />
      </Route>
    </Route>
    {/* auth routes */}
    <Route element={<AuthLayout />}>
      <Route path="accept-invitation/:userId/:token" element={<AcceptInvitation root />} />
      <Route path="forgot-password" element={<ForgotPassword root />} />
      <Route path="login" element={<Login root />} />
      <Route path="organizations/:slug/join/:invitationId" element={<Organizations$Join root />} />
      <Route path="register" element={<Register root />} />
      <Route path="resend-verification" element={<ResendVerification root />} />
      <Route path="reset-password/:userId/:token" element={<ResetPassword root />} />
      <Route path="verify-account/:userId/:token" element={<VerifyAccount root />} />
      <Route path="verify-email/:emailId/:token" element={<VerifyEmail root />} />
    </Route>
    {/* pages routes */}
    <Route element={<PagesLayout />}>
      <Route index element={<Home root />} />
      <Route path="about-us" element={<AboutUs root />} />
      <Route path="blog" element={<BlogHome root />} />
      <Route path="blog/:slug" element={<BlogDetails root />} />
      <Route path="blog/create" element={<BlogCreate root />} />
      <Route path="business" element={<Business root />} />
      <Route path="career" element={<Career root />} />
      <Route path="cashback" element={<Cashback root />} />
      <Route path="community" element={<Community root />} />
      <Route path="education" element={<Education root />} />
      <Route path="faq" element={<FAQ root />} />
      <Route path="ihk-pruefungsfit" element={<IHKPruefungsfit root />} />
      <Route path="legal-contact" element={<LegalContact root />} />
      <Route path="mastering-markdown" element={<MasteringMarkdown root />} />
      <Route path="partners" element={<Partners root />} />
      <Route path="privacy-policy" element={<PrivacyPolicy root />} />
      <Route path="public-courses" element={<PublicCourses root />} />
      <Route path="publishers-authors" element={<PublishersAuthors root />} />
      <Route path="record-book" element={<RecordBook root />} />
      <Route path="request-access" element={<RequestAccess root />} />
      <Route path="reward-system" element={<RewardSystem root />} />
      <Route path="special" element={<Special root />} />
      <Route path="professions" element={<ProfessionsHome root />} />
      <Route path="professions/products/:productId" element={<ProfessionsProductsDetails root />} />
      <Route path="professions/:slug" element={<ProfessionsDetails root />} />
      <Route path="terms-of-use" element={<TermsOfUse root />} />
      <Route path="teachers-parents" element={<VerkehrserziehungTeachersParents root />} />
    </Route>
    {/* theater routes */}
    <Route element={<TheaterLayout />}>
      <Route
        path="courses/:courseSlug/cards/quiz-mode/:slug"
        element={<Courses$FlashcardsQuizMode root />}
      />
      <Route path="courses/:courseSlug/tasks/exam-mode" element={<Courses$TasksExamMode root />} />
    </Route>
    {/* pdf routes */}
    <Route element={<PdfLayout />}>
      <Route
        path="pdf/courses/:courseSlug/articles/:slug"
        element={<PdfCourses$ArticlesDetails root />}
      />
      <Route
        path="pdf/courses/:courseSlug/cards/:slug"
        element={<PdfCourses$FlashcardsDiscussion root />}
      />
      <Route
        path="pdf/courses/:courseSlug/tasks/:slug"
        element={<PdfCourses$TasksDiscussion root />}
      />

      <Route path="pdf/reports/:collectionId/:reportId" element={<PdfReports$Details root />} />
      <Route path="pdf/reports/:collectionId" element={<PdfReportsCollection root />} />
    </Route>
    {/* redirects */}
    <Route
      path="/store/automobilkaufmann"
      element={<Navigate to="/professions/automobilkauffrau-automobilkaufmann" replace />}
    />
    <Route
      path="/store/kaufmann-fuer-bueromanagement"
      element={<Navigate to="/professions/kauffrau-kaufmann-fuer-bueromanagement" replace />}
    />
    <Route
      path="/store/drogist"
      element={<Navigate to="/professions/drogistin-drogist" replace />}
    />
    <Route
      path="/store/industriekaufmann"
      element={<Navigate to="/professions/industriekauffrau-industriekaufmann" replace />}
    />
    <Route
      path="/store/hotelfachmann"
      element={<Navigate to="/professions/hotelfachfrau-hotelfachmann" replace />}
    />
    <Route
      path="/store/kaufmann-im-einzelhandel"
      element={<Navigate to="/professions/kauffrau-kaufmann-im-einzelhandel" replace />}
    />
    <Route
      path="/store/kaufmann-im-e-commerce"
      element={<Navigate to="/professions/kauffrau-kaufmann-im-e-commerce" replace />}
    />
    <Route
      path="/store/kaufmann-fuer-spedition-und-logistikdienstleistung"
      element={
        <Navigate
          to="/professions/kauffrau-kaufmann-fuer-spedition-und-logistikdienstleistung"
          replace
        />
      }
    />
    <Route
      path="/professions/fachinformatiker"
      element={<Navigate to="/professions/fachinformatikerin-fachinformatiker" replace />}
    />
    <Route
      path="/professions/fachinformatiker-systemintegration"
      element={
        <Navigate to="/professions/fachinformatikerin-fachinformatiker-systemintegration" replace />
      }
    />
    <Route
      path="/store/fachkraft-fuer-lagerlogistik"
      element={<Navigate to="/professions/fachkraft-fuer-lagerlogistik" replace />}
    />
    <Route
      path="/store/fachlagerist"
      element={<Navigate to="/professions/fachlageristin-fachlagerist" replace />}
    />
    <Route
      path="/store/florist"
      element={<Navigate to="/professions/floristin-florist" replace />}
    />
    <Route
      path="/store/kaufmann-fuer-digitalisierungsmanagement"
      element={
        <Navigate to="/professions/kauffrau-kaufmann-fuer-digitalisierungsmanagement" replace />
      }
    />
    <Route
      path="/store/kaufmann-im-gesundheitswesen"
      element={<Navigate to="/professions/kauffrau-kaufmann-im-gesundheitswesen" replace />}
    />
    <Route
      path="/store/kaufmann-im-gross-und-aussenhandel"
      element={<Navigate to="/professions/kauffrau-kaufmann-im-gross-und-aussenhandel" replace />}
    />
    <Route path="/store/koch" element={<Navigate to="/professions/koechin-koch" replace />} />
    <Route
      path="/store/medizinische-fachangestellte"
      element={
        <Navigate
          to="/professions/medizinische-fachangestellte-medizinischer-fachangestellter"
          replace
        />
      }
    />
    <Route
      path="/store/personaldienstleistungskaufmann"
      element={
        <Navigate
          to="/professions/personaldienstleistungskauffrau-personaldienstleistungskaufmann"
          replace
        />
      }
    />
    <Route
      path="/store/restaurantfachmann"
      element={<Navigate to="/professions/restaurantfachfrau-restaurantfachmann" replace />}
    />
    <Route
      path="/store/steuerfachangestellter"
      element={<Navigate to="/professions/steuerfachangestellte-steuerfachangestellter" replace />}
    />
    <Route
      path="/stfa"
      element={<Navigate to="/professions/steuerfachangestellte-steuerfachangestellter" replace />}
    />
    <Route
      path="/store/verkaeufer"
      element={<Navigate to="/professions/verkaeuferin-verkaeufer" replace />}
    />
    <Route path="/store" element={<Navigate to="/professions" replace />} />
    {/* error route */}
    <Route path="*" element={<ErrorPage code="PAGE_NOT_FOUND" />} />
  </Routes>
);
/* eslint-enable */

export default routes;
