// @flow
import { createContainer } from 'libs/container';
import { selectByRoot, select } from 'libs/graphql';
import { DOMAIN_QUERY } from 'libs/foundation/queries';
import CourseAccessBox from './CourseAccessBox';

const Container = createContainer(CourseAccessBox, () => {
  const selectors = {
    viewer: selectByRoot('viewer', (viewer) =>
      viewer.join('organizationMemberships', (organizationMembership) =>
        organizationMembership.join('organization'),
      ),
    ),
    domain: select(DOMAIN_QUERY, {}),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
