// @flow

const parseAttributePart = (path, keys, values, handle) => {
  const [key, ...nextKeys] = keys;

  if (!values || typeof values !== 'object') {
    throw new Error(`Cannot apply ${key} on validation.`);
  }

  // If key is * call parseAttributePart for each value
  if (key === '*') {
    if (Array.isArray(values)) {
      values.map((value, i) => parseAttributePart([...path, i], nextKeys, value, handle));
    } else {
      Object.entries(values).map(([name, value]) =>
        parseAttributePart([...path, name], nextKeys, value, handle),
      );
    }

    return;
  }

  // If there are nextKeys call parseAttributePart for next keys
  if (nextKeys.length > 0) {
    parseAttributePart([...path, key], nextKeys, values[key], handle);
    return;
  }

  handle([...path, key], values[key]);
};

export default function parseAttributeName(
  attribute: string,
  values: Object,
  handle: (path: $ReadOnlyArray<string | number>, value: mixed) => mixed,
): void {
  const keys = attribute.split('.');

  parseAttributePart([], keys, values, handle);
}
