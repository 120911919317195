// @flow
import React from 'react';
import { View, Container } from 'components/core';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  children?: ?React$Node,
};

const styles = StyleSheet.create({
  container: css`
    background-color: #fff;
    border-bottom-width: 1px;
    border-bottom-color: $gray-300;
    min-height: 8.625rem;

    @include media-breakpoint-up(md) {
      min-height: 10.625rem;
    }
  `,
});

function Header({ children }: Props): React$Node {
  return <View style={styles.container}>{children && <Container>{children}</Container>}</View>;
}

export default Header;
