// @flow
import React from 'react';
import { Platform } from 'react-native';
import { StyleSheet, css } from 'libs/ui';
import { View } from 'components/core';
import Paragraph from './Paragraph';

type Props = {
  htmlTag: Object,
  children: React$Element<any>[],
  head?: boolean,
  align?: 'right' | 'left' | 'center',
  width?: number,
  colSpan?: number,
};

const styles = StyleSheet.create({
  tableCell: css`
    border: 1px solid $gray-900;
    padding: 0.25rem;
  `,
  tableHeadCellText: css`
    font-weight: 600;
  `,
});

const getRole = (headCell) => {
  if (Platform.OS !== 'web') {
    return null;
  }

  return headCell ? 'columnheader' : 'cell';
};

function TableCell(props: Props): React$Node {
  const { htmlTag, children, head, align, width, colSpan } = props;

  const headCell = head || (htmlTag && htmlTag.name === 'th');

  // 128px per column + 2px for border
  const cellWidth = width || (colSpan || 1) * 130;

  return (
    <View
      role={getRole(headCell)}
      aria-colspan={colSpan}
      style={[styles.tableCell, { width: cellWidth }]}
      textStyle={headCell && styles.tableHeadCellText}
    >
      <Paragraph align={align} spacing={false}>
        {children}
      </Paragraph>
    </View>
  );
}

export default TableCell;
