// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const CLOZE_RESULT_FRAGMENT: DocumentNode = gql`
  fragment ClozeResultFragment on Cloze {
    text
    sampleSolution {
      gapValues {
        key
        value
      }
      explanation
    }
    examAttemptSolution(slug: $slug, attemptId: $attemptId) {
      gapValues {
        key
        value
      }
    }
    gaps {
      key
    }
  }
`;
