// @flow
import React from 'react';
import { createDict, Trans, TimeAgo, useIntl } from 'libs/intl';
import { useDispatch } from 'libs/graphql';
import { useSearchParams } from 'libs/routing';
import { useToggleState, useAlerts } from 'libs/misc';
import { useAnchor } from 'libs/scroll';
import { Editor } from 'components/editor';
import { Form } from 'components/forms';
import { StyleSheet, css } from 'libs/ui';
import { Icon, Avatar, OptionButton } from 'components/common';
import { Modal, View, Text, Button, Dropdown, CloseButton } from 'components/core';
import { Markdown } from 'components/markdown';
import ProfilePopover from 'views/_shared/ProfilePopover';
import ProfileLinkPopover from 'views/_shared/ProfileLinkPopover';
import { updateAnnouncement, deleteAnnouncement } from './mutations';
import LinkToCourse from './LinkToCourse';
import LinkToOrganization from './LinkToOrganization';
import LinkToOrganizationTeam from './LinkToOrganizationTeam';

type Props = {
  announcement: Entity<GQLAnnouncement>,
  announceableId: Id,
  announceableType: string,
  viewer: ?Entity<GQLUser>,
  isDashboard?: ?boolean,
};

const dict = createDict({
  announcement: 'announcement',
  write_an_announcement: 'Write an announcement...',
  edit: 'Edit',
  cancel: 'Cancel',
  delete_announcement: 'Delete Announcement',
  do_you_really_want_to_delete_this_announcement: 'Do you really want to delete this announcement?',
  announcement_deleted_successfully: 'Announcement deleted successfully.',
  delete: 'Delete',
  in: 'in',
});

const styles = StyleSheet.create({
  avatarLink: css`
    height: 2rem;
  `,
});

const defaultProps = {
  isDashboard: false,
};

function Announcement({
  announcement,
  announceableId,
  announceableType,
  viewer,
  isDashboard,
}: Props): React$Node {
  const dispatch = useDispatch();
  const intl = useIntl();
  const alerts = useAlerts();

  const [searchParams] = useSearchParams();
  const selectedAnnouncementId = searchParams.get('announcement_id');

  const ref = useAnchor(selectedAnnouncementId === announcement.id);

  const { author, createdAt, text, id } = announcement;

  const editAnnouncement = useToggleState(false);
  const deleteAnnouncementModal = useToggleState(false);

  return (
    <>
      <View ref={ref}>
        <View styleName="flex-row ai-center jc-between flex-grow-1 flex-shrink-1 mb-3">
          <View styleName="flex-row flex-grow-1 flex-shrink-1 ai-center mr-3">
            <ProfilePopover user={announcement.author} style={styles.avatarLink}>
              <Avatar avatar={announcement.author.avatar} />
            </ProfilePopover>
            <View styleName="ml-3 flex-shrink-1 ai-start">
              <ProfileLinkPopover user={announcement.author} numberOfLines={1}>
                {author.name}
              </ProfileLinkPopover>
              <Text small color="muted" numberOfLines={1}>
                <TimeAgo value={createdAt} />
                {isDashboard && (
                  <>
                    <Text> &middot; </Text>
                    <Trans {...dict('in')} />{' '}
                    {announcement.announceable.__typename === 'Course' && (
                      <LinkToCourse
                        id={announcement.announceable.id}
                        announcementId={announcement.id}
                      />
                    )}
                    {announcement.announceable.__typename === 'Organization' && (
                      <LinkToOrganization
                        id={announcement.announceable.id}
                        announcementId={announcement.id}
                      />
                    )}
                    {announcement.announceable.__typename === 'OrganizationTeam' && (
                      <LinkToOrganizationTeam
                        id={announcement.announceable.id}
                        announcementId={announcement.id}
                      />
                    )}
                  </>
                )}
              </Text>
            </View>
          </View>
          <View styleName="flex-row ai-center jc-end flex-grow-1 flex-shrink-1">
            {!isDashboard && viewer && author.id === viewer.id && (
              <Dropdown>
                <OptionButton toggle={Dropdown} color="light">
                  <Icon glyph="ellipsis-vert" />
                </OptionButton>
                <Dropdown.Menu end>
                  <Dropdown.Item
                    onPress={() => {
                      editAnnouncement.setOn();
                    }}
                  >
                    <Trans {...dict('edit')} numberOfLines={1} />
                  </Dropdown.Item>
                  <Dropdown.Item
                    onPress={() => {
                      editAnnouncement.setOff();
                      deleteAnnouncementModal.setOn();
                    }}
                  >
                    <Trans {...dict('delete')} color="danger" numberOfLines={1} />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </View>
        </View>
        {!editAnnouncement.on && <Markdown source={text} />}
        {editAnnouncement.on && (
          <Form
            initialValues={{
              text,
            }}
            validate={{
              text: 'required',
            }}
            onSubmit={async (values) => {
              await dispatch(updateAnnouncement(id, values.text));

              editAnnouncement.setOff();
            }}
          >
            <Editor
              name="text"
              niceName={dict('announcement')}
              placeholder={dict('write_an_announcement')}
            />
            <View styleName="flex-row jc-end">
              <Button
                color="link"
                onPress={() => {
                  editAnnouncement.setOff();
                }}
                styleName="mr-2"
              >
                <Trans {...dict('cancel')} />
              </Button>
              <Form.Button type="submit">
                <Trans {...dict('edit')} />
              </Form.Button>
            </View>
          </Form>
        )}
      </View>

      <Modal visible={deleteAnnouncementModal.on} onToggle={deleteAnnouncementModal.toggle}>
        <Modal.Header styleName="flex-row">
          <Modal.Title>
            <Trans {...dict('delete_announcement')} />
          </Modal.Title>
          <CloseButton onPress={deleteAnnouncementModal.toggle} />
        </Modal.Header>
        <Modal.Body>
          <Trans {...dict('do_you_really_want_to_delete_this_announcement')} />
        </Modal.Body>
        <Modal.Footer>
          <Button color="light" onPress={deleteAnnouncementModal.toggle}>
            <Trans {...dict('cancel')} />
          </Button>
          <Button
            color="danger"
            styleName="ml-3"
            onPress={() => {
              dispatch(deleteAnnouncement(id, announceableId, announceableType))
                .then(() => {
                  alerts.add({
                    color: 'success',
                    content: intl.trans(dict('announcement_deleted_successfully')),
                  });
                })
                .catch(() => {});
            }}
          >
            <Trans {...dict('delete')} />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

Announcement.defaultProps = defaultProps;

export default Announcement;
