// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';

const StatsPage = {
  name: 'StatsPage',
  bundle: () => import('./StatsPage'),
};

const COURSE_SETTINGS_QUERY = gql`
  query CourseQuery($courseSlug: String!) {
    course(slug: $courseSlug) {
      viewerIsAdmin
      viewerIsObserver
      viewerManageableMemberships(withTeams: true) {
        quizCardResultsCount
        quizCardResultsCorrectCount
        quizCardResultsWrongCount
        averageQuizProgress
        averageExamAttemptResult
        examAttemptsCount
        user {
          name
          avatar {
            file {
              name
              extension
              info {
                image {
                  height
                  width
                }
              }
            }
          }
          firstName
          lastName
          createdAt
        }
      }

      bestExamActivities(first: 3) {
        slug
        title
      }

      bestQuizActivities(first: 3) {
        slug
        title
      }
      worstExamActivities(first: 3) {
        slug
        title
      }
      worstQuizActivities(first: 3) {
        slug
        title
      }
    }
  }
`;

const Container = createAsyncContainer(StatsPage, (props) => {
  const {
    params: { courseSlug },
  } = props;

  const variables = { courseSlug };

  const loaders = {
    query: query(COURSE_SETTINGS_QUERY, {
      variables,
    }),
  };

  const selectors = {
    course: select(COURSE_SETTINGS_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
