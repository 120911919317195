// @flow
import { useRef, useMemo, createRef } from 'react';
import getTaskKey from './getTaskKey';
import getTaskNumber from './getTaskNumber';

export default function useTaskSections(
  taskCollection: Entity<GQLTaskCollection>,
  deps: Array<mixed> | void | null,
): Object {
  const cache = useRef([]);

  return useMemo(() => {
    const items = [];

    taskCollection.tasks.forEach((task) => {
      const taskKey = getTaskKey(task);
      const taskNumber = getTaskNumber(taskCollection, task);
      const taskCache = cache.current.find((item) => item.id === taskKey);

      items.push({
        id: taskKey,
        label: taskNumber,
        ref: taskCache ? taskCache.ref : createRef(),
      });

      if (task.parts.length > 1) {
        task.parts.forEach((taskPart) => {
          const taskPartKey = getTaskKey(task, taskPart);
          const taskPartNumber = getTaskNumber(taskCollection, task, taskPart);
          const taskPartCache = cache.current.find((item) => item.id === taskPartKey);

          items.push({
            id: taskPartKey,
            label: taskPartNumber,
            ref: taskPartCache ? taskPartCache.ref : createRef(),
          });
        });
      }
    });

    cache.current = items;

    return items;
  }, deps);
}
