// @flow
import React from 'react';
import { ImageBackground } from 'components/core';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { Section, Spacing } from 'components/pages';
import { useDocumentTitle } from 'libs/misc';
import { Wrapper } from 'components/layout';
import HeroSection from './HeroSection';
import ContentSection from './ContentSection';
import FeaturesSection from './FeaturesSection';

const styles = StyleSheet.create({
  imageBackground: css`
    width: 100%;
    height: 350px;
    max-width: 1920px;

    @include media-breakpoint-up(md) {
      width: 100%;
      height: 750px;
    }
  `,
});

function Verkehrserziehung(): React$Node {
  useDocumentTitle(
    'FahrradPrüfung.de | Das Onlinetraining zur Vorbereitung auf die Radfahrprüfung',
  );
  const media = useMedia();

  return (
    <Wrapper>
      <ImageBackground
        source="/img/organizations/verkehrserziehung/imagebackground.jpg"
        styleName="as-center"
        style={styles.imageBackground}
        label="Fahrradprüfung online"
      />
      <Section color="white">
        <HeroSection />
        <ContentSection />
        {media.up('md') && <Spacing />}
      </Section>
      <Section color="gray">
        <FeaturesSection />
        {media.up('md') && <Spacing />}
      </Section>
    </Wrapper>
  );
}

export default Verkehrserziehung;
