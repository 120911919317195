// @flow
import { createContainer } from 'libs/container';
import { select, gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import OrderingCard from './OrderingCard';

export const QUIZ_ORDERING_CARD_FRAGMENT: DocumentNode = gql`
  fragment QuizOrderingCardFragment on OrderingCard {
    text
    hint
    answers {
      text
      position
    }
  }
`;

const Container = createContainer(OrderingCard, (props) => {
  const selectors = {
    orderingCard: select(QUIZ_ORDERING_CARD_FRAGMENT, {
      entry: ['OrderingCard', props.id],
    }),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
