// @flow
import React from 'react';
import { Text, View, Feedback, FormText, css, StyleSheet } from 'bootstrap-rn';
import { Trans } from 'libs/intl';

type Props = {
  children?: React$Node,
  title?: Intlized$Message | string | null,
  error: React$Node,
  touched?: boolean,
  info?: Intlized$Message | string | null,
  labelID: string,
  elementProps: any,
};

const styles = StyleSheet.create({
  formGroup: css`
    flex-shrink: 1;
    margin-bottom: 1rem;
  `,
});

function Field(props: Props): React$Node {
  const { children, title, error, touched = false, info, labelID, elementProps } = props;

  return (
    <View {...elementProps} style={[styles.formGroup, elementProps.style]}>
      {title && (
        <Text small styleName="fw-bold" labelID={labelID}>
          {typeof title === 'object' ? <Trans {...title} /> : title}
        </Text>
      )}
      {children}
      {touched && error && <Feedback type="invalid">{error}</Feedback>}
      {info && (
        <FormText styleName="text-muted">
          {typeof info === 'object' ? <Trans {...info} /> : info}
        </FormText>
      )}
    </View>
  );
}

export default Field;
