// @flow

export default function getCoursesFromVoucherResult(code: string, data: Object): Object[] {
  const { link: courseVoucherLink } = data.roots[`courseVoucher({"code":"${code}"})`];
  const courseVoucher = data.entities[courseVoucherLink[0]][courseVoucherLink[1]];

  return courseVoucher.courses.link.map((link) => {
    return {
      id: link[1],
      ...data.entities[link[0]][link[1]],
    };
  });
}
