// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';

const TopicList = {
  name: 'TopicList',
  bundle: () => import('./TopicList'),
};

const THREAD_LIST_QUERY = gql`
  query ThreadList($courseSlug: String!) {
    course(slug: $courseSlug) {
      paid
      topics {
        slug
        title
        author {
          id
          name
        }
        repliesCount
      }
      organization {
        name
        emailDomain
      }
      viewerIsAdmin
      viewerIsMember
      viewerIsObserver
      restricted
      isPrivate
      emailRestriction
      organizationRestriction
      sections {
        forumDescription
      }
    }
  }
`;

const Container = createAsyncContainer(TopicList, (props) => {
  const {
    params: { courseSlug },
  } = props;

  const variables = { courseSlug };

  const loaders = {
    query: query(THREAD_LIST_QUERY, { variables }),
  };

  const selectors = {
    course: select(THREAD_LIST_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
