// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { Button, Modal, Spinner, View, Text, CloseButton } from 'components/core';
import { createDict, Trans } from 'libs/intl';

type Props = {
  children: React$Node,
  title?: React$Node,
  button?: React$Node,
  color?: string,
  visible: boolean,
  loading?: boolean,
  onClose: () => void,
  onSubmit: (event: any) => void,
};

const defaultProps = {
  title: null,
  button: null,
  color: 'primary',
  loading: false,
};

const styles = StyleSheet.create({
  spinner: css`
    margin-right: 0.75rem;
  `,
});

const dict = createDict({
  confirmation: 'Confirmation',
  cancel: 'Cancel',
  confirm: 'Confirm',
});

function ConfirmationModal(props: Props): React$Node {
  const {
    children,
    title,
    button,
    color = 'primary',
    visible,
    loading = false,
    onClose: handleClose,
    onSubmit: handleSubmit,
  } = props;

  return (
    <Modal visible={visible} onToggle={handleClose}>
      <Modal.Header styleName="flex-row">
        <Modal.Title>{title || <Trans {...dict('confirmation')} />}</Modal.Title>
        <CloseButton onPress={handleClose} />
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <View styleName="flex-row jc-end flex-grow-1 flex-shrink-1">
          <Button color="light" styleName="mr-3" onPress={handleClose}>
            <Trans {...dict('cancel')} />
          </Button>
          <Button color={color} onPress={handleSubmit} disabled={loading} styleName="ai-center">
            {loading && <Spinner size="sm" color="light" style={styles.spinner} />}
            {button ? <Text>{button}</Text> : <Trans {...dict('confirm')} />}
          </Button>
        </View>
      </Modal.Footer>
    </Modal>
  );
}

ConfirmationModal.defaultProps = defaultProps;

export default ConfirmationModal;
