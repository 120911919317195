// @flow

const cache = {
  alphabetic: {},
  roman: {},
};

function convertToAlphabetic(input, lowercase) {
  let output = '';

  if (cache.alphabetic[input]) {
    output = cache.alphabetic[input];
  } else {
    const ordA = 'A'.charCodeAt(0);
    const ordZ = 'Z'.charCodeAt(0);
    // eslint-disable-next-line no-mixed-operators
    const len = ordZ - ordA + 1;

    let n = input - 1;
    while (n >= 0) {
      // eslint-disable-next-line no-mixed-operators
      output = String.fromCharCode((n % len) + ordA) + output;
      n = Math.floor(n / len) - 1;
    }

    cache.alphabetic[input] = output;
  }

  return lowercase ? output.toLowerCase() : output;
}

function convertToRoman(input, lowercase) {
  let output = '';

  if (cache.roman[input]) {
    output = cache.roman[input];
  } else {
    const romanLookup = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1,
    };

    let number = input;
    Object.keys(romanLookup).forEach((key) => {
      while (number >= romanLookup[key]) {
        output += key;
        number -= romanLookup[key];
      }
    });

    cache.roman[input] = output;
  }

  return lowercase ? output.toLowerCase() : output;
}

export default function formatNumber(
  input: number,
  convertTo: string,
  lowercase: boolean = true,
): string {
  if (convertTo === 'ALPHABETIC') {
    return convertToAlphabetic(input, lowercase);
  }

  if (convertTo === 'ROMAN') {
    return convertToRoman(input, lowercase);
  }

  // Output should be numeric, so we do not need to convert input.
  return input.toString();
}
