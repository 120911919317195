// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';
import { ANNOUNCEMENTS_FRAGMENT } from 'views/_shared/Announcements';

const TeamDiscussions = {
  name: 'TeamDiscussions',
  bundle: () => import('./TeamDiscussions'),
};

const ORGANIZATION_TEAM_MEMBERS_QUERY = gql`
  query OrganizationQuery($slug: String!, $teamSlug: String!, $announcementId: ClientID) {
    organization(slug: $slug) {
      name
      slug
      team(teamSlug: $teamSlug) {
        name
        slug
        viewerCanUpdate
        ...AnnouncementsFragment
      }
    }
  }

  ${ANNOUNCEMENTS_FRAGMENT}
`;

const Container = createAsyncContainer(TeamDiscussions, (props) => {
  const {
    params: { slug, teamSlug },
    searchParams,
  } = props;

  const variables = { slug, teamSlug, announcementId: searchParams.get('announcement_id') };

  const loaders = {
    query: query(ORGANIZATION_TEAM_MEMBERS_QUERY, { variables }),
  };

  const selectors = {
    organization: select(ORGANIZATION_TEAM_MEMBERS_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
