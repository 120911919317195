// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql, selectByRoot } from 'libs/graphql';
import { getVersion } from 'utils/courses';
import { ACTIVITY_EDITOR_SIDEBAR_FRAGMENT } from '../_shared/ActivityEditorSidebar/fragments';
import { SURVEY_QUESTION_TEXT_EDITOR_FRAGMENT } from './SurveyQuestionOpen/fragments';
import { SURVEY_QUESTION_RATE_EDITOR_FRAGMENT } from './SurveyQuestionScale/fragments';
import { ACTIVITY_EDITOR_TITLE_FRAGMENT } from '../_shared/ActivityEditorTitle/fragments';
import { ATTACHMENTS_FRAGMENT } from '../_shared/MediaGalleryModal';

const SurveyEditor = {
  name: 'SurveyEditor',
  bundle: () => import('./SurveyEditor'),
};

const COURSE_SURVEY_QUERY = gql`
  query CourseSurvey(
    $slug: String!
    $courseSlug: String!
    $versionNumber: Int
    $specificVersion: String
  ) {
    course(slug: $courseSlug) {
      categories {
        name
      }
      surveyActivity(slug: $slug) {
        ...ActivityEditorTitleFragment
        ...ActivityEditorSidebarFragment
        slug
        version(versionNumber: $versionNumber, specificVersion: $specificVersion) {
          versionNumber
          isDeprecated
          releasedAt
          item {
            questionsCount
            questions {
              type {
                ...SurveyQuestionOpenEditorFragment
                ...SurveyQuestionScaleEditorFragment
              }
            }
            files {
              name
              extension
              info {
                image {
                  height
                  width
                }
              }
            }
            endedAt
            ...AttachmentsFragment
          }
        }
        viewerCanUpdate
      }
      viewerIsAdmin
    }
  }

  ${SURVEY_QUESTION_TEXT_EDITOR_FRAGMENT}
  ${SURVEY_QUESTION_RATE_EDITOR_FRAGMENT}
  ${ATTACHMENTS_FRAGMENT}
  ${ACTIVITY_EDITOR_TITLE_FRAGMENT}
  ${ACTIVITY_EDITOR_SIDEBAR_FRAGMENT}
`;

const Container = createAsyncContainer(SurveyEditor, (props) => {
  const {
    params: { slug, courseSlug },
    searchParams,
  } = props;

  const versionNumber = getVersion(searchParams.get('version'));
  const variables = {
    slug,
    courseSlug,
    versionNumber,
    specificVersion: versionNumber ? null : 'LATEST',
  };

  const loaders = {
    query: query(COURSE_SURVEY_QUERY, { variables }),
  };

  const selectors = {
    course: select(COURSE_SURVEY_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
