// @flow
import React from 'react';
import { ListGroup } from 'components/core';
import BundleCompact from './BundleCompact';

type Props = {
  onClose?: () => void,
};

function KaufleuteImGesundheitswesenBundles({ onClose }: Props): React$Node {
  return (
    <ListGroup>
      <BundleCompact
        to="/courses/cuOsv0GDIt"
        title="Erstmal reinschnuppern"
        price="kostenlose Lernmaterialien und Community"
        onClose={onClose}
      />
      <BundleCompact
        to="/professions/kauffrau-kaufmann-im-gesundheitswesen"
        title="Lernpaket Gesundheitswesen"
        price="ab 24,95 EUR"
        paid
      />
    </ListGroup>
  );
}

export default KaufleuteImGesundheitswesenBundles;
