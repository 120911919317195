// @flow
import { DOLLAR_SIGN, BACKSLASH } from '../utils/chars';

const reDelimiter = /^(\$+)/;

export default function mathBlock() {
  const { Parser } = this;
  const { interruptParagraph, interruptList, interruptBlockquote } = Parser.prototype;
  const tokenizers = Parser.prototype.blockTokenizers;
  const methods = Parser.prototype.blockMethods;

  // Interrupt elements for math block.
  interruptParagraph.push(['mathBlock']);
  interruptList.push(['mathBlock']);
  interruptBlockquote.push(['mathBlock']);

  // Run it just after `blankLine` tokenizer.
  methods.splice(1, 0, 'mathBlock');

  function tokenizeMathBlock(eat, value, silent) {
    if (value.charAt(0) !== DOLLAR_SIGN || value.charAt(1) !== DOLLAR_SIGN) {
      return undefined;
    }

    const openings = value.match(reDelimiter)[0].length;

    let index = openings;

    while (index < value.length) {
      if (value.charAt(index) === DOLLAR_SIGN) {
        const closings = value.slice(index).match(reDelimiter)[0].length;
        index += closings;

        if (openings === closings) {
          const innerValue = value.slice(openings, index - closings).trim();

          if (!innerValue) {
            return undefined;
          }

          if (silent) {
            return true;
          }

          return eat(value.slice(0, index))({
            type: 'math',
            value: innerValue,
          });
        }
      }

      if (value.charAt(index) === BACKSLASH) {
        index += 1;
      }

      index += 1;
    }

    return undefined;
  }

  tokenizers.mathBlock = tokenizeMathBlock;
}
