// @flow
import { createAsyncContainer } from 'libs/container';
import { select, query, gql, selectByRoot } from 'libs/graphql';
import { DOMAIN_QUERY } from 'libs/foundation/queries';
import { ACTIVITY_DETAILS_TITLE_EXAM_ATTEMPT_FRAGMENT } from '../_shared/ActivityDetailsTitle/fragments';
import { ACTIVITY_VIEWER_RESULT_FRAGMENT } from '../_shared/ActivityViewerResult/fragments';
import { EXAM_ACTIONS_FRAGMENT } from '../_shared/ExamActions/fragments';
import { ACTIVITY_DETAILS_SIDEBAR_EXAM_ATTEMPT_FRAGMENT } from '../_shared/ActivityDetailsSidebar/fragments';
import { CLOZE_RESULT_FRAGMENT } from './Cloze/fragments';
import { MULTIPLE_CHOICE_RESULT_FRAGMENT } from './MultipleChoice/fragments';
import { OPEN_QUESTION_RESULT_FRAGMENT } from './OpenQuestion/fragments';
import { PAIRING_RESULT_FRAGMENT } from './Pairing/fragments';
import { TRUE_FALSE_RESULT_FRAGMENT } from './TrueFalse/fragments';

const TaskCollection = {
  name: 'TaskCollection',
  bundle: () => import('./TaskCollection'),
};

const TASKS_EXAM_RESULT_QUERY = gql`
  query CourseTasksExamResult($slug: String!, $courseSlug: String!, $attemptId: ClientID!) {
    course(slug: $courseSlug) {
      viewerIsMember
      viewerIsAdmin
      viewerIsObserver
      allowsAiEvaluations
      examActivity(slug: $slug) {
        ...ActivityDetailsTitleExamAttemptFragment
        ...ActivityViewerResultFragment
        ...ExamActionsFragment
        ...ActivityDetailsSidebarExamAttemptFragment
        versionForAttempt(attemptId: $attemptId) {
          item {
            info
            numberingFormat
            partNumberingFormat
            tasks {
              position
              title
              intro {
                text
                commentable
              }
              parts {
                position
                title
                type {
                  ...ClozeResultFragment
                  ...MultipleChoiceResultFragment
                  ...OpenQuestionResultFragment
                  ...PairingResultFragment
                  ...TrueFalseResultFragment
                }
              }
            }
            exam {
              selfEvaluable
              duration
              points
              usesGrades
              passingScore
              grades {
                name
                minScore
              }
              taskPoints {
                position
                points
                parts {
                  position
                  points
                }
              }
              attempt(attemptId: $attemptId) {
                user {
                  name
                }
                result {
                  points
                  evaluatedPoints
                  taskPoints {
                    position
                    points
                    parts {
                      position
                      points
                      reviewer {
                        firstName
                        lastName
                      }
                    }
                  }
                  openaiReviewedAt
                }
                completedAt
                number
                createdAt
              }
            }
          }
        }
        author {
          name
        }
        updatedAt
        createdAt
        viewerCanAccess
      }
    }
  }

  ${CLOZE_RESULT_FRAGMENT}
  ${MULTIPLE_CHOICE_RESULT_FRAGMENT}
  ${OPEN_QUESTION_RESULT_FRAGMENT}
  ${PAIRING_RESULT_FRAGMENT}
  ${TRUE_FALSE_RESULT_FRAGMENT}
  ${ACTIVITY_DETAILS_TITLE_EXAM_ATTEMPT_FRAGMENT}
  ${ACTIVITY_VIEWER_RESULT_FRAGMENT}
  ${EXAM_ACTIONS_FRAGMENT}
  ${ACTIVITY_DETAILS_SIDEBAR_EXAM_ATTEMPT_FRAGMENT}
`;

const Container = createAsyncContainer(TaskCollection, (props) => {
  const {
    params: { slug, courseSlug, attemptId },
  } = props;

  const variables = {
    slug,
    courseSlug,
    attemptId,
  };

  const loaders = {
    query: query(TASKS_EXAM_RESULT_QUERY, { variables }),
  };

  const selectors = {
    course: select(TASKS_EXAM_RESULT_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
    domain: select(DOMAIN_QUERY, {}),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
