// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query, selectByRoot } from 'libs/graphql';

const MonetizationPurchases = {
  name: 'MonetizationPurchases',
  bundle: () => import('./MonetizationPurchases'),
};

const ORGANIZATION_PRODUCT_DETAILS_PURCHASES_QUERY = gql`
  query OrganizationProductDetailsPurchasesQuery($slug: String!, $productSlug: String!) {
    organization(slug: $slug) {
      name
      slug
      product(productSlug: $productSlug) {
        slug
        name
        description
        licensesCount
        offers {
          keysCount
          purchasesCount(completed: true)
          duration
          reusable
          purchases(completed: true) {
            createdAt
            quantity
            stripeStatus
            user {
              name
            }
            organization {
              name
            }
            price {
              amount
              currency {
                code
              }
            }
          }
        }
      }
      viewerIsAdmin
      viewerIsTrainer
      viewerIsMember
    }
  }
`;

const Container = createAsyncContainer(MonetizationPurchases, (props) => {
  const {
    params: { slug, productSlug },
  } = props;

  const variables = { slug, productSlug };

  const loaders = {
    query: query(ORGANIZATION_PRODUCT_DETAILS_PURCHASES_QUERY, { variables }),
  };

  const selectors = {
    organization: select(ORGANIZATION_PRODUCT_DETAILS_PURCHASES_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
