// @flow
import { Link, createMutation, gql } from 'libs/graphql';

const UPDATE_OR_CREATE_SOLUTION = gql`
  mutation UpdateOrCreateSolution($input: TrueFalseExamSolutionUpdateOrCreateInput!) {
    trueFalseExamSolution_updateOrCreate(input: $input) {
      statementValues {
        position
        value
      }
    }
  }
`;

const updateViewerSolution = (link) => (record) => {
  record.set('viewerRunningExamSolution', link);
};

export function updateOrCreateSolution(
  trueFalseId: Id,
  examAttemptId: Id,
  position: number,
  value: ?boolean,
): MutationAction {
  const statementValues =
    value !== null
      ? [
          {
            position,
            value,
          },
        ]
      : [];
  const statementValuePositionsToRemove = value === null ? [position] : [];

  return createMutation(UPDATE_OR_CREATE_SOLUTION, {
    variables: {
      input: {
        trueFalseId,
        examAttemptId,
        statementValues,
        statementValuePositionsToRemove,
      },
    },
    updater: (store, data) => {
      // Update link
      const link = new Link(data.roots.trueFalseExamSolution_updateOrCreate);
      store.update('TrueFalse', trueFalseId, updateViewerSolution(link));
    },
  });
}
