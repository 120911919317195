// @flow
import React from 'react';
import { View, Text, Image } from 'components/core';
import { LargeText } from 'components/common';
import { StyleSheet, css, useMedia } from 'libs/ui';

const styles = StyleSheet.create({
  titleDesktop: css`
    font-family: 'Titillium Web';
    font-weight: bold;
    font-size: 2rem;
    line-height: 4rem;
    text-align: left;
    padding-bottom: 1.5rem;
  `,
  titleMobile: css`
    font-family: 'Titillium Web';
    font-weight: bold;
    font-size: 1.5rem;
    text-align: left;
    padding-bottom: 0.5rem;
  `,
  imageDesktop: css`
    width: 300px;
    height: 200px;
    border-radius: 0.125rem;
  `,
  imageMobile: css`
    width: 182px;
    height: 120px;
    border-radius: 0.125rem;
  `,
  text: css`
    text-align: left;
  `,
  containerDesktop: css`
    flex-direction: row;
    margin-vertical: 5rem;
  `,
  containerMobile: css`
    margin: 2rem 1rem 2rem 1rem;
  `,
});

function Content(): React$Node {
  const media = useMedia();

  return (
    <View styleName="my-5">
      <View style={media.up('md') ? styles.containerDesktop : styles.containerMobile}>
        <View styleName="pr-md-5 mb-3 mb-md-0 ai-center ai-md-start">
          <Image
            source="/img/home/template-landing-1.png"
            label="Lernplattform"
            style={[styles.imageMobile, media.up('md') && styles.imageDesktop]}
          />
        </View>
        <View styleName="flex-column jc-md-start ai-md-start flex-grow-1 flex-shrink-1">
          <Text style={media.up('md') ? styles.titleDesktop : styles.titleMobile}>
            Prüfungstraining
          </Text>
          <LargeText style={styles.text}>
            Bereite dich mit anderen Auszubildenden auf die Zwischen- und Abschlussprüfung vor,
            tausche Mitschriften und Fragen aus, erstelle und teile Karteikarten, nutze Karten-Sets
            von anderen und teste dein Wissen mit Prüfungssimulationen.
          </LargeText>
        </View>
      </View>
      <View style={media.up('md') ? styles.containerDesktop : styles.containerMobile}>
        <View styleName="pl-md-5 mb-3 mb-md-0 ai-center ai-md-start">
          <Image
            source="/img/home/template-landing-2.png"
            label="Lernplattform"
            style={[styles.imageMobile, media.up('md') && styles.imageDesktop]}
          />
        </View>
        <View styleName="jc-md-start ai-md-start flex-grow-1 flex-shrink-1">
          <Text style={media.up('md') ? styles.titleDesktop : styles.titleMobile}>
            Gemeinsames Lernen
          </Text>
          <LargeText style={styles.text}>
            Finde dich im Betrieb und in der Berufsschule in Kursen mit anderen Auszubildenden
            zusammen und erstelle deine eigenen Kurse, um gemeinsam mit Tests und Karteikarten zu
            lernen, sich auf die mündliche Prüfung vorzubereiten, Dokumente auszutauschen, zu
            chatten und sich gegenseitig zu helfen.
          </LargeText>
        </View>
      </View>
    </View>
  );
}

export default Content;
