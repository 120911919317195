// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql, selectByRoot } from 'libs/graphql';
import Header from 'components/layout/Header';
import CourseHeader from './CourseHeader';

const COURSE_QUERY = gql`
  query CourseQuery($courseSlug: String!) {
    course(slug: $courseSlug) {
      slug
      name
      caption
      organization {
        name
        viewerIsAdmin
        viewerIsTrainer
      }
      examActivitiesCount
      quizActivitiesCount
      videoActivitiesCount
      articleActivitiesCount
      documentActivitiesCount
      surveyActivitiesCount
      topicsCount
      restricted
      isPrivate
      organizationRestriction
      emailRestriction
      viewerIsMember
      viewerIsAdmin
      viewerIsObserver
      viewerCanJoin
      viewerIsOnTrialUntil
      viewerMembership {
        role
        licensed {
          validUntil
        }
        createdAt
      }
      allowsTrial
      paid
      products {
        name
        professions {
          slug
          name
        }
        offers {
          duration
          price {
            price {
              amount
              currency {
                code
              }
            }
          }
          businessPrices {
            min
            price {
              amount
              currency {
                code
              }
            }
          }
        }
        courses {
          slug
          name
        }
      }
      features {
        hasTasks
        hasQuizzes
        hasVideos
        hasArticles
        hasDocuments
        hasForum
        hasSurveys
      }
      relatedChecklists {
        slug
        name
        todosCount
        viewerResult {
          todoResultsCount
          doneUntil
        }
      }
      author {
        name
        createdAt
      }
    }
  }
`;

const options = {
  async: { loading: Header, error: () => null },
};

const Container = createAsyncContainer(
  CourseHeader,
  (props) => {
    const {
      params: { courseSlug },
    } = props;

    const variables = {
      courseSlug,
    };

    const loaders = {
      query: query(COURSE_QUERY, { variables }),
    };

    const selectors = {
      course: select(COURSE_QUERY, { variables }),
      viewer: selectByRoot('viewer', (viewer) =>
        viewer.join('organizationMemberships', (organizationMembership) =>
          organizationMembership.join('organization'),
        ),
      ),
    };

    return { loaders, selectors };
  },
  options,
);

export default (Container: React$AbstractComponent<any, mixed>);
