// @flow
import { Domain } from 'libs/misc';

export default function createHeaders(options: Object, customHeaders: Object = {}): any {
  return {
    // Domain header
    'X-App-Domain': Domain.get(),
    // Headers
    ...options.headers,
    // Custom headers
    ...customHeaders,
  };
}
