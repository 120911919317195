// @flow
import React from 'react';
import { Icon } from 'components/common';
import { View, Grid } from 'components/core';
import { BrandHeading, BrandLargeText } from 'components/pages';
import { StyleSheet, css, useMedia } from 'libs/ui';

type Props = {
  title: string,
  content: string,
  icon: string,
  type: string,
};

const styles = StyleSheet.create({
  fontColorBlue: css`
    color: #fe5000;
  `,
  fontColorDarkBlue: css`
    color: #0071b2;
  `,
  GridBoxDesktop: css`
    align-items: center;
    padding: 2rem 2.5rem 3rem 2.5rem;
  `,
  GridBoxMobile: css`
    margin-top: 1rem;
    margin-bottom: 2rem;
    flex-grow: 1;
    flex-shrink: 1;
  `,
  iconDesktop: css`
    font-size: 1.5rem;
    line-height: 3rem;
    margin-right: 1rem;
  `,
  iconMobile: css`
    font-size: 1.5rem;
    line-height: 3rem;
    margin-right: 0.5rem;
  `,
  textAlign: css`
    text-align: center;
  `,
});

function FeaturesBox(props: Props): React$Node {
  const { title, content, icon, type } = props;
  const media = useMedia();

  let iconColor = styles.fontColorBlue;
  if (type === 'red') {
    iconColor = styles.fontColorDarkBlue;
  }

  return (
    <Grid.Box
      size={12}
      sizeMd={4}
      style={[media.up('md') ? styles.GridBoxDesktop : styles.GridBoxMobile]}
    >
      <View styleName="m-3 flex-row flex-shrink-1 ai-center jc-center">
        <Icon
          glyph={icon}
          label="Feature"
          style={[media.up('md') ? styles.iconDesktop : styles.iconMobile, iconColor]}
        >
          {type}
        </Icon>
        <BrandHeading size={4}>{title}</BrandHeading>
      </View>
      <View styleName="flex-grow-1 flex-shrink-1">
        <BrandLargeText style={styles.textAlign}>{content}</BrandLargeText>
      </View>
    </Grid.Box>
  );
}

export default FeaturesBox;
