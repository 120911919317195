// @flow
import React from 'react';
import { Markdown } from 'components/markdown';
import { Icon } from 'components/common';
import { View, Button } from 'components/core';
import { TaskChoice } from 'components/tasks';
import { StyleSheet, css } from 'libs/ui';
import { useDispatch } from 'libs/graphql';
import { getOptionValue } from 'utils/tasks';
import { updateOrCreateSolution } from './mutations';
import type { TaskExamControls } from '../useExamControls';

type Props = {
  multipleChoiceId: Id,
  examAttemptId: Id,
  option: Entity<GQLMultipleChoiceOption>,
  last: boolean,
  viewerExamSolution: ?Entity<GQLMultipleChoiceSolution>,
  controls: TaskExamControls,
};

const styles = StyleSheet.create({
  touchable: css`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.25rem;
    border-width: 0;
    padding: 1rem;
    margin-horizontal: -1rem;
    @include media-breakpoint-up(md) {
      border-radius: 0.5rem;
    }
  `,
  textReset: css`
    line-height: 1.5rem;
    text-transform: none;
    letter-spacing: 0;
    font-size: 1rem;
    font-weight: normal;
    // Workaround for native fonts
    @include platform(ios) {
      font-family: 'Roboto';
    }
    @include platform(android) {
      font-family: 'Roboto';
    }
    text-align: left;
  `,
  checkbox: css`
    margin-left: 1rem;
    width: 1.625rem;
    height: 1.625rem;
    justify-content: center;
    align-items: center;
    border-width: 0.125rem;
    border-color: #bbb;
    border-radius: 0.125rem;
    box-shadow: 0 0 1px $transparent-200;
  `,
  checkboxAnswered: css`
    border-color: $gray-500;
  `,
  iconAnswered: css`
    color: $link-color;
    text-transform: none;
    letter-spacing: 0;
    font-size: 1rem;
    font-weight: normal;
  `,
});

function Option({
  multipleChoiceId,
  examAttemptId,
  option,
  last,
  viewerExamSolution,
  controls,
}: Props): React$Node {
  const dispatch = useDispatch();

  const handleChange = (value) => {
    // block action if loading
    if (controls.loading) {
      return;
    }

    controls.block();
    dispatch(
      updateOrCreateSolution(multipleChoiceId, examAttemptId, option.position, value),
    ).finally(() => {
      controls.unblock();
    });
  };

  const value = viewerExamSolution && getOptionValue(option, viewerExamSolution.optionValues);

  return (
    <TaskChoice last={last}>
      <View styleName="flex-grow-1 flex-shrink-1 my-n1">
        <Button
          onPress={() => {
            handleChange(value === true ? null : true);
          }}
          color="light"
          style={styles.touchable}
        >
          <Markdown source={`${option.text}`} textStyle={styles.textReset} />
          <View style={[styles.checkbox, value === true && styles.checkboxAnswered]}>
            {value === true && <Icon glyph="ok" style={styles.iconAnswered} />}
          </View>
        </Button>
      </View>
    </TaskChoice>
  );
}

export default Option;
