// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { View } from 'components/core';

type Props = {
  children: React$Node,
};

const styles = StyleSheet.create({
  container: css`
    flex-direction: row;
  `,
});

function TaskChoiceSolution({ children }: Props): React$Node {
  return (
    <View styleName="ml-md-4" style={styles.container}>
      {children}
    </View>
  );
}

export default TaskChoiceSolution;
