// @flow
import React from 'react';
import BundleCompact from './BundleCompact';

type Props = {
  onClose?: () => void,
};

function HotelfachmannBundles({ onClose }: Props): React$Node {
  return (
    <>
      <BundleCompact
        to="/courses/8OlswH7l34"
        title="Erstmal reinschnuppern"
        price="kostenlose Lernmaterialien und Community"
        onClose={onClose}
      />
      <BundleCompact
        to="/professions/hotelfachfrau-hotelfachmann"
        title="Abschlussprüfung"
        price="ab 10,95 EUR"
        paid
      />
    </>
  );
}

export default HotelfachmannBundles;
