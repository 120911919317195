// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const Licenses = {
  name: 'Licenses',
  bundle: () => import('./Licenses'),
};

const LICENSES_QUERY = gql`
  query LicensesQuery {
    viewer {
      name
      licenseAssignments {
        license {
          validUntil
          product {
            name
            courses {
              slug
              name
              caption
            }
          }
        }
      }
    }
  }
`;

const Container = createAsyncContainer(Licenses, () => {
  const loaders = {
    query: query(LICENSES_QUERY),
  };

  const selectors = {
    viewer: select(LICENSES_QUERY),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
