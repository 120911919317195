// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';
import { DOMAIN_QUERY } from 'libs/foundation/queries';

const OrganizationCourses = {
  name: 'OrganizationCourses',
  bundle: () => import('./OrganizationCourses'),
};

const ORGANIZATION_COURSES_QUERY = gql`
  query OrganizationCoursesQuery($slug: String!) {
    organization(slug: $slug) {
      name
      city
      description
      relatedCourses {
        organization {
          id
        }
        slug
        name
        caption
        restricted
        isPrivate
        emailRestriction
        organizationRestriction
      }
      viewerIsMember
    }
  }
`;

const Container = createAsyncContainer(OrganizationCourses, (props) => {
  const {
    params: { slug },
  } = props;

  const variables = { slug };

  const loaders = {
    query: query(ORGANIZATION_COURSES_QUERY, { variables }),
  };

  const selectors = {
    organization: select(ORGANIZATION_COURSES_QUERY, { variables }),
    domain: select(DOMAIN_QUERY, {}),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
