// @flow
import { createContainer } from 'libs/container';
import { select, gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import LinkToOrganization from './LinkToOrganization';

export const ORGANIZATION_ANNOUNCEMENT_FRAGMENT: DocumentNode = gql`
  fragment OrganizationAnnouncementFragment on Organization {
    name
    slug
  }
`;

const Container = createContainer(LinkToOrganization, (props) => {
  const selectors = {
    organization: select(ORGANIZATION_ANNOUNCEMENT_FRAGMENT, {
      entry: ['Organization', props.id],
    }),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
