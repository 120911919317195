// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, query, select } from 'libs/graphql';

const NewArticle = {
  name: 'NewArticle',
  bundle: () => import('./NewArticle'),
};

const COURSE_QUERY = gql`
  query ArticleList($courseSlug: String!) {
    course(slug: $courseSlug) {
      viewerCanCreateArticles
      categories {
        name
      }
    }
  }
`;

const Container = createAsyncContainer(NewArticle, (props) => {
  const {
    params: { courseSlug },
  } = props;

  const variables = { courseSlug };

  const loaders = {
    query: query(COURSE_QUERY, { variables }),
  };

  const selectors = {
    course: select(COURSE_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
