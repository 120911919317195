// @flow
import React from 'react';
import {
  ImageBackground,
  View,
  Text,
  Container,
  Card,
  ListGroup,
  Grid,
  Button,
} from 'components/core';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { BrandHeading, BrandText, Spacing } from 'components/pages';
import { Wrapper, Page } from 'components/layout';
import { Icon } from 'components/common';
import { useToggleState, useDocumentTitle } from 'libs/misc';
import { createDict, Trans } from 'libs/intl';
import ProfessionsFreeCoursesModal from 'views/_shared/ProfessionsFreeCoursesModal';
import FreeCoursesInfoBox from 'views/_shared/FreeCoursesInfoBox';
import ContentSection from '../_shared/ContentSection';

type Props = {
  viewer: Entity<GQLUser>,
};

const styles = StyleSheet.create({
  imageBackgroundDesktop: css`
    width: 100%;
    height: 37.5rem;
  `,
  teaserText: css`
    font-size: 1.375rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 1rem;
  `,
  cardMobile: css`
    padding-top: 2rem;
  `,
  cardDesktop: css`
    margin-top: -22rem;
    margin-bottom: 8rem;
    background-color: transparent;
  `,
  colorDesktop: css`
    color: #fff;
  `,
  icon: css`
    font-size: 3rem;
    line-height: 3rem * $line-height-base;
    color: #fff;
    margin-bottom: 0.25rem;
  `,
  itemAction: css`
    margin-bottom: 1rem;
    min-height: 11.875rem;
    justify-content: center;
    align-items: center;
  `,
  itemActionDesktop: css`
    height: 10rem;
  `,
  text: css`
    text-align: center;
    padding-top: 1rem;
    color: #fff;
  `,
  imageBackground: css`
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.8;
  `,
  itemActionPadding: css`
    padding: 1rem 0rem 1rem 0rem;
    justify-content: center;
    align-items: center;
  `,
  textAlign: css`
    text-align: center;
  `,
});

const dict = createDict({
  exams_are_coming_up_get_started: 'The final exams are coming up - start learning now!',
  unlock_bundle_text:
    'Unlock your bundle for the exams in April and May and get started - now in browser, mobile and on the Azubinet App!',
  professions_on_azubinet: 'Professions on Azubinet',
  find_free_courses: 'Find free courses',
  popular_bundles: 'Popular bundles',
});

function Store({ viewer }: Props): React$Node {
  useDocumentTitle('Azubinet-Store');
  const media = useMedia();
  const modal = useToggleState(false);

  const viewerIsOrganizationAdminOrTrainer = viewer && viewer.isOrganizationAdminOrTrainer;

  return (
    <>
      <Wrapper>
        {media.up('md') && (
          <ImageBackground
            source="/img/pages/store-06-23.jpg"
            style={[styles.imageBackgroundDesktop]}
          />
        )}
        <Container>
          <Card style={[media.up('md') ? styles.cardDesktop : styles.cardMobile]}>
            <Card.Body>
              <View styleName="flex-grow-1 flex-shrink-1 ai-md-center jc-md-center">
                <BrandHeading size={1} styleName="flex-wrap flex-shrink-1">
                  <Text style={media.up('md') && styles.colorDesktop}>
                    <Trans {...dict('exams_are_coming_up_get_started')} />
                  </Text>
                </BrandHeading>
                <BrandText style={[styles.teaserText, media.up('md') && styles.colorDesktop]} bold>
                  <Trans {...dict('unlock_bundle_text')} />
                </BrandText>
              </View>
            </Card.Body>
          </Card>
        </Container>
        <Page windowTitle="Azubinet-Store">
          <View styleName="flex-grow-1 flex-shrink-1 jc-md-center ai-md-center mt-5 mb-2 mx-3 mx-md-0">
            <BrandHeading size={1} styleName="flex-wrap">
              <Trans {...dict('professions_on_azubinet')} />
            </BrandHeading>
          </View>
          <Page.Main size={12}>
            <Spacing />
            <ListGroup>
              <Grid>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/automobilkauffrau-automobilkaufmann"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/automobilkaufleute.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="cab" style={styles.icon} />
                      <Text style={styles.text} numberOfLines={2}>
                        Automobilkaufleute
                      </Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/drogistin-drogist"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/drogisten.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="cart-plus" style={styles.icon} />
                      <Text style={styles.text} numberOfLines={2}>
                        Drogist/-innen
                      </Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/fachinformatikerin-fachinformatiker"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/fachinformatiker.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="code" style={styles.icon} />
                      <Text style={styles.text} numberOfLines={2}>
                        Fachinformatiker/-innen (alle Fachrichtungen)
                      </Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/fachkraft-fuer-lagerlogistik"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/fachlageristen.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="cubes" style={styles.icon} />
                      <Text style={styles.text} numberOfLines={2}>
                        Fachkräfte für Lagerlogistik
                      </Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/fachlageristin-fachlagerist"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/fachlageristen.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="cubes" style={styles.icon} />
                      <Text style={styles.text}>Fachlagerist/-innen</Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/floristin-florist"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/floristen.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="garden" style={styles.icon} />
                      <Text style={styles.text}>Florist/-innen</Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/hotelfachfrau-hotelfachmann"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/hotelfachmann.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="bed" style={styles.icon} />
                      <Text style={styles.text}>Hotelfachleute</Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/industriekauffrau-industriekaufmann"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/industriekaufleute.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="industry" style={styles.icon} />
                      <Text style={styles.text}>Industriekaufleute</Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/kauffrau-kaufmann-fuer-bueromanagement"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/buerokaufleute.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="briefcase" style={styles.icon} />
                      <Text style={styles.text} numberOfLines={2}>
                        Kaufleute für Büromanagement
                      </Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/kauffrau-kaufmann-fuer-digitalisierungsmanagement"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/fachinformatiker.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="code" style={styles.icon} />
                      <Text style={styles.text} numberOfLines={2}>
                        {media.up('md')
                          ? 'Kaufleute für Digitalisierungsmanagement'
                          : 'Kaufleute für Digitalisierungs-Management'}
                      </Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/kauffrau-kaufmann-fuer-gross-und-aussenhandelsmanagement"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/industriekaufleute.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="ship" style={styles.icon} />
                      <Text style={styles.text} numberOfLines={2}>
                        {media.up('md')
                          ? 'Kaufleute für Groß- und Außenhandelsmanagement (neue AO)'
                          : 'Kaufleute für Groß- und Außenhandels-Management (neue AO)'}
                      </Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/kauffrau-kaufmann-fuer-spedition-und-logistikdienstleistung"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/fachlageristen.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="truck" style={styles.icon} />
                      <Text style={styles.text} numberOfLines={2}>
                        Kaufleute für Spedition und Logistikdienstleistung
                      </Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/kauffrau-kaufmann-im-e-commerce"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/e-commerce.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="at" style={styles.icon} />
                      <Text style={styles.text} numberOfLines={2}>
                        Kaufleute im E-Commerce
                      </Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/kauffrau-kaufmann-im-einzelhandel"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/einzelhandel.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="cart-plus" style={styles.icon} />
                      <Text style={styles.text} numberOfLines={2}>
                        Kaufleute im Einzelhandel
                      </Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/kauffrau-kaufmann-im-gesundheitswesen"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/gesundheitswesen.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="hospital" style={styles.icon} />
                      <Text style={styles.text} numberOfLines={2}>
                        Kaufleute im Gesundheitswesen
                      </Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/kauffrau-kaufmann-im-gross-und-aussenhandel"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/industriekaufleute.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="ship" style={styles.icon} />
                      <Text style={styles.text} numberOfLines={2}>
                        Kaufleute im Groß- und Außenhandel (alte AO)
                      </Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/koechin-koch"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/koch.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="food" style={styles.icon} />
                      <Text style={styles.text}>Köche</Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/medizinische-fachangestellte-medizinischer-fachangestellter"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/gesundheitswesen.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="hospital" style={styles.icon} />
                      <Text style={styles.text} numberOfLines={2}>
                        Medizinische Fachangestellte
                      </Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/personaldienstleistungskauffrau-personaldienstleistungskaufmann"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/buerokaufleute.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="users" style={styles.icon} />
                      {media.up('md') && (
                        <Text style={styles.text} numberOfLines={1}>
                          Personaldienstleistungskaufleute
                        </Text>
                      )}
                      {media.down('sm') && (
                        <Text style={styles.text} numberOfLines={2}>
                          Personaldienst-leistungskaufleute
                        </Text>
                      )}
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/restaurantfachfrau-restaurantfachmann"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/restaurantfachmann.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="coffee" style={styles.icon} />
                      <Text style={styles.text} numberOfLines={1}>
                        Restaurantfachleute
                      </Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/steuerfachangestellte-steuerfachangestellter"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/buerokaufleute-4.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="book-open" style={styles.icon} />
                      <Text style={styles.text} numberOfLines={2}>
                        Steuerfachangestellte
                      </Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
                <Grid.Box size={6} sizeMd={3}>
                  <ListGroup.ItemAction
                    to="/professions/verkaeuferin-verkaeufer"
                    style={[styles.itemAction, media.up('md') && styles.itemActionDesktop]}
                  >
                    <ImageBackground
                      source="/img/pages/professions/einzelhandel.jpg"
                      style={styles.imageBackground}
                    />
                    <View style={styles.itemActionPadding}>
                      <Icon glyph="cart-plus" style={styles.icon} />
                      <Text style={styles.text}>Verkäufer/-in</Text>
                    </View>
                  </ListGroup.ItemAction>
                </Grid.Box>
              </Grid>
            </ListGroup>
            {!viewerIsOrganizationAdminOrTrainer && (
              <>
                <FreeCoursesInfoBox>
                  <Button onPress={modal.toggle} size="lg">
                    <Trans {...dict('find_free_courses')} />
                  </Button>
                </FreeCoursesInfoBox>
              </>
            )}
          </Page.Main>
        </Page>
        <ContentSection viewer={viewer} teaserSection />
      </Wrapper>
      <ProfessionsFreeCoursesModal modal={modal} />
    </>
  );
}

export default Store;
