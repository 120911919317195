// @flow
import React from 'react';
import { Page } from 'components/layout';
import { Outlet } from 'libs/routing';
import DomainNav from './DomainNav';

type Props = {
  domain: Entity<GQLDomain>,
};

function DomainLayout({ domain }: Props): React$Node {
  return (
    <Page>
      <Page.Aside size={3} styleName="mx-auto mb-3 mb-md-0">
        <DomainNav domain={domain} />
      </Page.Aside>
      <Page.Main size={9}>
        <Outlet />
      </Page.Main>
    </Page>
  );
}

export default DomainLayout;
