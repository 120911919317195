// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, selectByRoot, gql } from 'libs/graphql';

const Topic = {
  name: 'Topic',
  bundle: () => import('./Topic'),
};

const POST_FIELDS = gql`
  {
    text
    author {
      name
      avatar {
        file {
          name
          extension
          info {
            image {
              height
              width
            }
          }
        }
      }
      createdAt
    }
    reactionsThumbUpCount
    reactionsThumbDownCount
    viewerCanUpdate
    viewerCanDelete
    viewerReaction {
      value
    }
    createdAt
  }
`;

const THREAD_QUERY = gql`
  query CourseTopic($slug: String!, $courseSlug: String!, $postId: ClientID) {
    course(slug: $courseSlug) {
      topic(slug: $slug) {
        title
        opening ${POST_FIELDS}
        post(id: $postId) ${POST_FIELDS}
        replies ${POST_FIELDS}
      }
      restricted
      isPrivate
      emailRestriction
      organizationRestriction
      viewerIsMember
      viewerIsObserver
    }
  }
`;

const Container = createAsyncContainer(Topic, (props) => {
  const {
    params: { slug, courseSlug },
    searchParams,
  } = props;

  const variables = {
    slug,
    courseSlug,
    postId: searchParams.get('post_id'),
  };

  const loaders = {
    query: query(THREAD_QUERY, { variables }),
  };

  const selectors = {
    course: select(THREAD_QUERY, { variables }),
    viewer: selectByRoot('viewer', (viewer) =>
      viewer.join('avatar', (avatar) =>
        avatar.join('file', (file) => file.join('info', (info) => info.join('image'))),
      ),
    ),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
