// @flow
import React from 'react';
import { ListGroup, Modal, CloseButton, Text } from 'components/core';
import { createDict, Trans } from 'libs/intl';
import { Well } from 'components/common';
import MediaGalleryItem from './MediaGalleryItem';
import MediaGalleryUpload from './MediaGalleryUpload';

type Props = {
  attachable: Entity<GQLArticle | GQLDocument | GQLSurvey | GQLQuiz | GQLTaskCollection | GQLVideo>,
  modal: ToggleState,
  onSelect: (event: any) => void,
};

const dict = createDict({
  no_files_uploaded_yet: 'No files uploaded yet.',
  media_gallery: 'Media Gallery',
});

function MediaGalleryModal({ attachable, modal, onSelect }: Props): React$Node {
  const { files } = attachable;

  return (
    <Modal visible={modal.on} onToggle={modal.toggle} size="lg">
      <Modal.Header styleName="flex-row">
        <Modal.Title>
          <Text>
            <Trans {...dict('media_gallery')} /> ({files.length})
          </Text>
        </Modal.Title>
        <CloseButton onPress={modal.toggle} />
      </Modal.Header>
      <Modal.Body>
        <MediaGalleryUpload attachable={attachable} />
        {files.length === 0 && (
          <Well>
            <Trans {...dict('no_files_uploaded_yet')} />
          </Well>
        )}
        {files.length !== 0 && (
          <ListGroup>
            {files.reverse().map((file) => (
              <MediaGalleryItem
                key={file.id}
                file={file}
                galleryModal={modal}
                attachable={attachable}
                onSelect={onSelect}
              />
            ))}
          </ListGroup>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default MediaGalleryModal;
