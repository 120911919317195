// @flow

import convertActivityType from './convertActivityType';

export default function getActivityLink(course: Entity<GQLCourse>, activity: Object): string {
  const activities = convertActivityType(activity, {
    Article: 'articles',
    Document: 'documents',
    Exam: 'tasks',
    Quiz: 'cards',
    Survey: 'surveys',
    Video: 'videos',
  });

  const baseUrl = `/courses/${course.slug}/${activities}`;

  if (activity.viewerCanAccess) {
    return `${baseUrl}/${activity.slug}`;
  }

  return `${baseUrl}?activity_id=${activity.id}`;
}
