// @flow
import { useEffect } from 'react';

export default function usePdfReadyTrigger() {
  useEffect(() => {
    const event = new CustomEvent('pdfReady');

    // We add a timeout of 1000ms before we fire the event as a workaround to
    // ensure that the font files are loaded first.
    setTimeout(() => {
      if (document.body) {
        document.body.dispatchEvent(event);
      }
    }, 1000);
  }, []);
}
