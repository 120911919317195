// @flow
import { Api } from 'libs/misc';

const isClient = typeof window !== 'undefined';

const makeRequest = () => {
  return Api.get('/api/info').catch((err) => {
    if (err.name === 'HttpError' && err.response.status === 422) {
      throw new Error('DOMAIN_NOT_FOUND');
    }

    throw new Error('REQUEST_ERROR');
  });
};

export default function fetchDomainInfo(): Promise<any> {
  // eslint-disable-next-line no-underscore-dangle
  if (!isClient || !window.__DATA__) {
    return makeRequest();
  }

  // eslint-disable-next-line no-underscore-dangle
  const { error, domain } = window.__DATA__;

  if (error) {
    // Only load viewer domain query on client for debug purpose.
    if (error !== 'DOMAIN_NOT_FOUND') {
      makeRequest().catch(() => {});
    }

    return Promise.reject(new Error(error));
  }

  return Promise.resolve(domain);
}
