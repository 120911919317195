// @flow
import { useState, useEffect } from 'react';

const interval = 1000;

export default function useCountdown(expiresAt: string): number {
  const [countdown, setCountdown] = useState(() => {
    const diff = new Date(expiresAt) - new Date();
    return diff >= 0 ? diff : 0;
  });

  useEffect(() => {
    // $FlowFixMe[incompatible-type]
    let expected = Date.now() + interval;
    let timeout;

    const step = () => {
      const now = Date.now();
      const dt = now - expected;

      const diff = new Date(expiresAt) - now;

      // Update countdown value.
      setCountdown(diff >= 0 ? diff : 0);

      // Set next timeout step.
      if (diff > 0) {
        expected += interval;
        timeout = setTimeout(step, Math.max(0, interval - dt));
      }
    };

    timeout = setTimeout(step, interval);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return Math.round(countdown / 1000);
}
