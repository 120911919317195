// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { BrandHeading, BrandLargeText, BrandText } from 'components/pages';
import { View, Grid, Image } from 'components/core';
import { Icon } from 'components/common';
import { createDict, Trans } from 'libs/intl';

const styles = StyleSheet.create({
  testimonial: css`
    flex-grow: 1;
    border-radius: 0.5rem;
    border-width: 0.125rem;
    border-color: $gray-200;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  `,
  logoMerck: css`
    height: 16px;
    width: 100px;
    margin-top: 0.5rem;
  `,
  logoWuensche: css`
    height: 30px;
    width: 109px;
    margin-top: 0.5rem;
  `,
  logoGeorgC: css`
    height: 13px;
    width: 110px;
    margin-top: 0.5rem;
  `,
});

const dict = createDict({
  trainers_about_azubinet: 'Trainers about Azubinet',
});

function TestimonialsBoxTrainees(): React$Node {
  return (
    <>
      <View styleName="jc-center ai-center mb-4 mx-2 mx-md-0">
        <BrandHeading size={2} align="center">
          <Trans align="center" {...dict('trainers_about_azubinet')} />
        </BrandHeading>
      </View>
      <Grid>
        <Grid.Box size={12} sizeMd={4}>
          <View style={styles.testimonial} styleName="mb-3 mb-md-0">
            <BrandLargeText styleName="text-center">
              &#8222;Azubinet wird von unseren Azubis als sehr hilfreich empfunden. Gerade was die
              Vorbereitung auf eine verkürzte Ausbildung angeht, konnten Themenfelder, welche in der
              Berufsschule noch nicht behandelt wurden gezielt bearbeitet und aufgeholt werden.
              Alles in Allem sind wir sehr zufrieden. &#8220;
            </BrandLargeText>
            <BrandText styleName="text-center mt-3">
              <Icon glyph="smile" /> I. Lietz, Trainer Apprenticeship
            </BrandText>
            <Image source="/img/home/merck.png" label="MERCK" style={styles.logoMerck} />
          </View>
        </Grid.Box>
        <Grid.Box size={12} sizeMd={4}>
          <View style={styles.testimonial} styleName="mb-3 mb-md-0">
            <BrandLargeText styleName="text-center">
              &#8222;Azubinet war für unseren Auszubildenden sehr hilfreich und er hat Azubinet
              sogar fleißig in seiner Klasse weiterempfohlen, woraufhin sich auch viele seiner
              Klassenkameraden einen Zugang geholt haben.&#8220;
            </BrandLargeText>
            <BrandText styleName="text-center mt-3">
              <Icon glyph="smile" /> L. Koch, Human Resources
            </BrandText>
            <Image
              source="/img/home/wuensche.png"
              label="WÜNSCHE Group"
              style={styles.logoWuensche}
            />
          </View>
        </Grid.Box>
        <Grid.Box size={12} sizeMd={4}>
          <View style={styles.testimonial}>
            <BrandLargeText styleName="text-center">
              &#8222;Azubinet bietet eine gute Prüfungsvorbereitung. Unsere Azubis nutzen die
              Inhalte gerne während des Schulblockes zur Unterstützung. Azubinet ist eine große
              Erleichterung für uns Ausbilder bei unseren Produktschulungen und in der
              Nachbearbeitung. Außerdem haben wir eine gute Übersicht, welche Azubis fleißig und
              welche außer der Reihe sind.&#8220;
            </BrandLargeText>
            <BrandText styleName="text-center mt-3">
              <Icon glyph="smile" /> J. Clausen, Ausbilderin
            </BrandText>
            <Image
              source="/img/home/georg-c.png"
              label="Georg C. Hansen"
              style={styles.logoGeorgC}
            />
          </View>
        </Grid.Box>
      </Grid>
    </>
  );
}

export default TestimonialsBoxTrainees;
