// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const CheckoutConfirmSofort = {
  name: 'CheckoutConfirmSofort',
  bundle: () => import('./CheckoutConfirmSofort'),
};

const PRODUCT_OFFER_PURCHASE_QUERY = gql`
  query LicensePurchaseQuery($purchaseId: ClientID!) {
    productOfferPurchase(id: $purchaseId) {
      id
    }
  }
`;

const Container = createAsyncContainer(CheckoutConfirmSofort, (props) => {
  const {
    params: { purchaseId },
  } = props;

  const loaders = {
    query: query(PRODUCT_OFFER_PURCHASE_QUERY, { variables: { purchaseId } }),
  };

  const selectors = {
    productOfferPurchase: select(PRODUCT_OFFER_PURCHASE_QUERY, {
      variables: { purchaseId },
    }),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
