// @flow
import React from 'react';
import { View, Image } from 'components/core';
import { ScrollArea } from 'libs/scroll';
import { Domain } from 'libs/misc';
import { StyleSheet, css } from 'libs/ui';
import { Outlet } from 'libs/routing';
import usePdfReadyTrigger from './usePdfReadyTrigger';

const styles = StyleSheet.create({
  container: css`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    min-height: 100vh;
    width: 742px;
    border-color: #fff;
    border-left-width: 2rem;
    border-right-width: 2rem;
    border-bottom-width: 2rem;
    background: #fff;
  `,
  logoView: css`
    align-items: center;
    margin-bottom: 4rem;
  `,
  logoStBK: css`
    width: 144px;
    height: 41px;
  `,
  logoVogelSPOT: css`
    width: 144px;
    height: 49px;
  `,
  logoExamunity: css`
    width: 144px;
    height: 32px;
  `,
  logoAzubinet: css`
    width: 144px;
    height: 25px;
  `,
});

function PdfLayout(): React$Node {
  usePdfReadyTrigger();

  return (
    <ScrollArea>
      <View style={styles.container}>
        <View style={styles.logoView}>
          {Domain.is('stbk.azubinet.de') && (
            <Image source="/img/organizations/stbk-nds/stbk-logo.png" style={styles.logoStBK} />
          )}
          {Domain.is('www.vogelspot.de') && (
            <Image
              source="/img/organizations/vogel/vogelspot_logo.png"
              style={styles.logoVogelSPOT}
            />
          )}
          {Domain.is('www.examunity.com') && (
            <Image source="/img/logo.png" style={styles.logoExamunity} />
          )}
          {Domain.is('*.azubinet.de') && !Domain.is('stbk.azubinet.de') && (
            <Image source="/img/azubinet.png" style={styles.logoAzubinet} />
          )}
        </View>
        <Outlet />
      </View>
    </ScrollArea>
  );
}

export default PdfLayout;
