// @flow
import React from 'react';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { Text, View, Card, ListGroup } from 'components/core';
import { Icon, LargeText } from 'components/common';

type Props = {
  courseName: string,
  professionName: string,
  courseType: string,
  courseLink: string,
};

const styles = StyleSheet.create({
  containerDesktop: css`
    flex-direction: column;
    width: 23.5rem;
    min-height: 8rem;
    margin: 0rem 1rem 1rem 0rem;
  `,
  containerMobile: css`
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column;
    margin-horizontal: -1rem;
    margin-bottom: 1rem;
  `,
  heading: css`
    margin-bottom: 1rem;
    font-weight: bold;
  `,
  description: css`
    line-height: 1.25rem;
  `,
  caption: css`
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0.5rem 0rem 0.5rem 0rem;
    flex-direction: row;
    align-items: flex-end;
  `,
  courseInfos: css`
    flex-direction: column;
    flex-shrink: 1;
  `,
});

function CourseCard({ courseName, professionName, courseType, courseLink }: Props): React$Node {
  const media = useMedia();

  return (
    <Card style={media.up('md') ? styles.containerDesktop : styles.containerMobile}>
      <Card.Body styleName="p-0">
        <ListGroup flush styleName="flex-row flex-grow-1 flex-shrink-1">
          <ListGroup.ItemAction
            to={`/courses/${courseLink}/`}
            styleName="flex-column flex-grow-1 flex-shrink-1"
          >
            <View style={styles.courseInfos}>
              <LargeText style={styles.heading}>{courseName}</LargeText>
              <View styleName="flex-row flex-shrink-1 ai-center jc-start">
                <Icon glyph="user" styleName="mr-2" />
                <Text style={styles.description} numberOfLines={2}>
                  {professionName}
                </Text>
              </View>
            </View>
            <View style={styles.caption}>
              <Icon glyph="sliders" color="muted" styleName="mr-2" />
              <Text style={styles.courseType} color="muted">
                {courseType}
              </Text>
            </View>
          </ListGroup.ItemAction>
        </ListGroup>
      </Card.Body>
    </Card>
  );
}

export default CourseCard;
