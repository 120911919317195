// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql, selectByRoot } from 'libs/graphql';
import { DOMAIN_QUERY } from 'libs/foundation/queries';
import type { DocumentNode } from 'graphql';
import {
  QUIZ_CARD_TEXT_FRAGMENT,
  QUIZ_CARD_CHOICE_FRAGMENT,
  QUIZ_CARD_ORDERING_FRAGMENT,
  QUIZ_CARD_MATCHING_FRAGMENT,
} from '../_shared/QuizCard/fragments';
import { ACTIVITY_DETAILS_TITLE_QUIZ_RESULT_FRAGMENT } from '../_shared/ActivityDetailsTitle/fragments';
import { ACTIVITY_VIEWER_RESULT_FRAGMENT } from '../_shared/ActivityViewerResult/fragments';
import { QUIZ_ACTIONS_FRAGMENT } from '../_shared/QuizActions/fragments';
import { ACTIVITY_DETAILS_SIDEBAR_QUIZ_RESULT_FRAGMENT } from '../_shared/ActivityDetailsSidebar/fragments';

const QuizResult = {
  name: 'QuizResult',
  bundle: () => import('./QuizResult'),
};

export const QUIZ_RESULT_QUERY: DocumentNode = gql`
  query CourseQuizResult($slug: String!, $courseSlug: String!, $resultId: ClientID!) {
    course(slug: $courseSlug) {
      viewerIsAdmin
      viewerIsMember
      viewerIsObserver
      restricted
      isPrivate
      emailRestriction
      organizationRestriction
      quizActivity(slug: $slug) {
        ...ActivityDetailsTitleQuizResultFragment
        ...ActivityViewerResultFragment
        ...QuizActionsFragment
        ...ActivityDetailsSidebarQuizResultFragment
        versionForResult(resultId: $resultId) {
          item {
            cards {
              createdAt
              ...QuizCardChoiceFragment
              ...QuizCardTextFragment
              ...QuizCardOrderingFragment
              ...QuizCardMatchingFragment

              result(quizResultId: $resultId) {
                correctCount
                wrongCount
                box
              }
              hint
            }
            cardsCount

            totalBoxes
            result(resultId: $resultId) {
              correctCount
              wrongCount
              progress
              user {
                name
              }
              createdAt
              completedAt
            }
          }
        }
        author {
          name
        }
        updatedAt
        createdAt
      }
    }
  }

  ${QUIZ_CARD_TEXT_FRAGMENT}
  ${QUIZ_CARD_CHOICE_FRAGMENT}
  ${QUIZ_CARD_ORDERING_FRAGMENT}
  ${QUIZ_CARD_MATCHING_FRAGMENT}
  ${ACTIVITY_DETAILS_TITLE_QUIZ_RESULT_FRAGMENT}
  ${ACTIVITY_VIEWER_RESULT_FRAGMENT}
  ${QUIZ_ACTIONS_FRAGMENT}
  ${ACTIVITY_DETAILS_SIDEBAR_QUIZ_RESULT_FRAGMENT}
`;

const Container = createAsyncContainer(QuizResult, (props) => {
  const {
    params: { slug, courseSlug, resultId },
  } = props;

  const variables = {
    slug,
    courseSlug,
    resultId,
  };

  const loaders = {
    query: query(QUIZ_RESULT_QUERY, { variables }),
  };

  const selectors = {
    course: select(QUIZ_RESULT_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
    domain: select(DOMAIN_QUERY, {}),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
