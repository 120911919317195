// @flow
import React from 'react';
import { View, Jumbotron, Button, Text } from 'components/core';
import { BrandButton, BrandHeading, BrandLargeText } from 'components/pages';
import { StyleSheet, css } from 'libs/ui';

const styles = StyleSheet.create({
  jumbotron: css`
    background-color: #405767;
    align-items: center;
    margin-top: -5rem;

    @include media-breakpoint-up(md) {
      margin-top: -6rem;
      border-radius: 1rem;
    }
  `,
  content: css`
    flex-shrink: 1;
    justify-content: center;
    align-items: center;
    margin: 1rem;

    @include media-breakpoint-up(md) {
      margin: 2rem;
    }
  `,
  heading: css`
    color: #fff;
    margin-bottom: 2rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.75rem;
      line-height: 1.75rem * $line-height-base;
    }
  `,
  text: css`
    color: #fff;
    margin-bottom: 2rem;
  `,
});

function HeroSection(): React$Node {
  return (
    <Jumbotron style={styles.jumbotron}>
      <View styleName="jc-center ai-center flex-md-row">
        <View style={styles.content}>
          <BrandHeading size={1} style={styles.heading} styleName="text-center">
            <Text>Willkommen auf FahrradPrüfung.de!</Text>
          </BrandHeading>
          <BrandLargeText size={5} style={styles.text} bold styleName="text-center">
            Das Onlinetraining zur Vorbereitung auf die Radfahrprüfung
          </BrandLargeText>
          <View styleName="flex-md-row ai-md-center jc-md-center">
            <Button color="secondary" to="/register" size="lg" styleName="mr-md-2 mb-2 mb-md-0">
              <Text>Registrieren</Text>
            </Button>
            <BrandButton to="/login" size="lg" styleName="ml-md-2 mt-2 mt-md-0">
              <Text>Anmelden</Text>
            </BrandButton>
          </View>
        </View>
      </View>
    </Jumbotron>
  );
}

export default HeroSection;
