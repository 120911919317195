// @flow
import React from 'react';
import { View, Text, Container, Image, Button, Link } from 'components/core';
import { Video } from 'components/common';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { Section, BrandHeading, BrandLargeText, Spacing } from 'components/pages';
import { useDocumentTitle } from 'libs/misc';
import HeroSection from './HeroSection';
import Content from './Content';
import FeaturesBoxContent from './FeaturesBoxContent';
import FeaturedCourses from './FeaturedCourses';
import CompaniesInfo from './CompaniesInfo';
import StBKSlogan from '../../_shared/StBKSlogan';
import References from '../../_shared/References';

type Props = {
  viewer: Entity<GQLUser>,
};

const styles = StyleSheet.create({
  logoDesktop: css`
    width: 300px;
    height: 86px;
    margin-top: 1rem;
  `,
  logoMobile: css`
    width: 250px;
    height: 71px;
    margin-top: 1rem;
  `,
  videoDesktop: css`
    border-radius: 0.25rem;
    border-width: 0.25rem;
    border-color: $gray-800;
    width: 60rem;
  `,
  videoMobile: css`
    margin: -1rem 1rem 0rem 1rem;
  `,
  videoContainerDesktop: css`
    margin-top: -12rem;
    align-items: center;
    justify-content: center;
  `,
  videoContainerMobile: css`
    margin-top: -12rem;
    align-items: center;
    justify-content: center;
  `,
  textDesktop: css`
    text-align: center;
  `,
  appStoreBadge: css`
    width: 135px;
    height: 40px;
  `,
  action: css`
    flex-shrink: 1;
    align-items: flex-start;
  `,
  textAlign: css`
    text-align: center;
  `,
});

function StbkNiedersachsen({ viewer }: Props): React$Node {
  useDocumentTitle(
    'StBK.Azubinet: Lernplattform für Steuerfachangestellte, ausbildende Kanzleien und Lehrkräfte in Niedersachsen',
  );

  const media = useMedia();
  return (
    <>
      <Section color="white">
        <HeroSection />
      </Section>
      <Section color="white">
        <View style={media.up('md') ? styles.videoContainerDesktop : styles.videoContainerMobile}>
          <View style={media.up('md') ? styles.videoDesktop : styles.videoMobile}>
            <Video
              controls
              source={{ uri: '/video/stbk/stbk-azubinet-landing.mp4' }}
              poster="/video/stbk/stbk_azubinet_landing.jpg"
            />
          </View>
        </View>
      </Section>
      <Section color="white" styleName="px-md-5 py-md-3 mt-md-5">
        <View styleName="jc-center ai-center mt-n4 mt-md-4">
          <BrandHeading align="center" size={1}>
            Für Auszubildende
          </BrandHeading>
        </View>
        <View styleName="my-md-5 mt-5">
          <Content />
        </View>
        <Spacing />
        <View styleName="ai-md-center jc-md-center mt-3 mb-5">
          <CompaniesInfo />
        </View>
        <View styleName="jc-center ai-center my-5 mx-3 mx-md-0">
          <BrandHeading size={2}>
            <Text>Lernen wann und wo du willst, am Schreibtisch und mobil.</Text>
          </BrandHeading>
          <View style={styles.action}>
            <View styleName="flex-row flex-wrap mt-4">
              <Button
                to="https://play.google.com/store/apps/details?id=de.azubinet.client"
                external
                styleName="p-0 mr-2"
                color="light"
              >
                <Image
                  source="/img/home/google_app.png"
                  label="Google App"
                  style={styles.appStoreBadge}
                />
              </Button>
              <Button
                to="https://apps.apple.com/us/app/azubinet/id6443448057"
                external
                styleName="p-0 ml-2"
                color="light"
              >
                <Image
                  source="/img/home/apple_app.png"
                  label="Apple App"
                  style={styles.appStoreBadge}
                />
              </Button>
            </View>
          </View>
        </View>
      </Section>
      <Section color="gray" styleName="px-md-5 py-md-3 mt-md-5">
        <View styleName="jc-center ai-center mx-md-5 p-md-3 px-3">
          <BrandHeading align="center" size={1}>
            Für Lehrkräfte
          </BrandHeading>
        </View>
        <View styleName="m-3 py-4 jc-md-center ai-md-center">
          <BrandLargeText style={media.up('md') && styles.textDesktop} styleName="pb-3">
            Die Nutzung der Lernplattform ist für Lehrkräfte der Berufsschulen und für Auszubildende
            zum/zur Steuerfachangestellten in Niedersachsen kostenlos.
          </BrandLargeText>
          <BrandLargeText style={media.up('md') && styles.textDesktop} styleName="pb-3">
            Für Lehrkräfte an berufsbildenden Schulen in Niedersachsen, die Steuerfachangestellte
            ausbilden und für Auszubildende, die im Berufsregister der Steuerberaterkammer
            Niedersachsen eingetragen sind. Ihre Zugangsdaten erhalten Sie von Ihrer Lehrkraft bzw.
            von Ihrer Steuerberaterkammer.
          </BrandLargeText>
          <Container>
            <View styleName="ai-center mb-4">
              <Link
                external
                to="https://www.stbk-niedersachsen.de/de/ihre_kammer/aktuelle_meldungen/pr%C3%BCfungsvorbereitung_mit_der_lernplattform_stbk_azubinet/"
              >
                <Image
                  source="/img/home/stbk-nds.png"
                  label="stbk-niedersachsen"
                  style={media.up('md') ? styles.logoDesktop : styles.logoMobile}
                />
              </Link>
            </View>
          </Container>
          <View styleName="ai-md-start mb-5 mb-md-0 flex-md-row">
            <Button to="/login" size="lg" styleName="mt-3 mt-md-0" color="dark">
              <Text>Zur Anmeldung</Text>
            </Button>
            <Button
              to="https://www.stbk-niedersachsen.de/de/ihre_kammer/aktuelle_meldungen/pr%C3%BCfungsvorbereitung_mit_der_lernplattform_stbk_azubinet/"
              external
              size="lg"
              styleName="mt-3 mt-md-0 ml-md-3"
              color="secondary"
            >
              <Text>Zugang anfordern</Text>
            </Button>
          </View>
        </View>
      </Section>
      <Section color="white">
        <FeaturedCourses viewer={viewer} />
      </Section>
      <Section color="gray" styleName="px-md-5 py-md-3 mt-md-5">
        <View styleName="jc-center ai-center mx-md-5 p-md-3 px-3">
          <BrandHeading align="center" size={2}>
            StBK.Azubinet auf einen Blick
          </BrandHeading>
        </View>
        <FeaturesBoxContent />
      </Section>
      <Section color="white">
        <View styleName="jc-center ai-center mb-5">
          <BrandLargeText style={styles.textAlign}>
            StBK.Azubinet ist ein gemeinsames Angebot der Steuerberaterkammer Niedersachsen und
            Azubinet.
          </BrandLargeText>
        </View>
        <References />
      </Section>
      <Section color="gray">
        <StBKSlogan />
      </Section>
    </>
  );
}

export default StbkNiedersachsen;
