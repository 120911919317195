// @flow
import { gql, createMutation } from 'libs/graphql';

const BUY_LICENSES = gql`
  mutation BuyLicenses($id: ClientID!, $input: OrganizationBuyLicensesInput!) {
    organization_buyLicenses(id: $id, input: $input) {
      licenses {
        validUntil
        reusable
        assignment {
          user {
            name
            avatar {
              file {
                name
                extension
                info {
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
        }
        product {
          name
          professions {
            name
          }
        }
      }
      licensesCount
    }
  }
`;

export function buyLicenses(id: Id, pricingId: Id, quantity: number): MutationAction {
  return createMutation(BUY_LICENSES, {
    variables: {
      id,
      input: {
        pricingId,
        quantity,
      },
    },
  });
}
