// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const DomainPwa = {
  name: 'DomainPwa',
  bundle: () => import('./DomainPwa'),
};

const DOMAIN_PWA_QUERY = gql`
  query DomainPwaQuery($slug: String!) {
    organization(slug: $slug) {
      ownsDomain
      domain {
        pwa {
          name
          description
          originalIcon {
            id
          }
          smallIcon {
            name
            extension
            info {
              image {
                height
                width
              }
            }
          }
        }
      }
      viewerIsAdmin
    }
  }
`;

const Container = createAsyncContainer(DomainPwa, (props) => {
  const {
    params: { slug },
  } = props;

  const variables = { slug };

  const loaders = {
    query: query(DOMAIN_PWA_QUERY, { variables }),
  };

  const selectors = {
    organization: select(DOMAIN_PWA_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
