// @flow
import { createContainer } from 'libs/container';
import { select, gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import TextCard from './TextCard';

export const QUIZ_TEXT_CARD_FRAGMENT: DocumentNode = gql`
  fragment QuizTextCardFragment on TextCard {
    text
    hint
    answer {
      text
    }
  }
`;

const Container = createContainer(TextCard, (props) => {
  const selectors = {
    textCard: select(QUIZ_TEXT_CARD_FRAGMENT, {
      entry: ['TextCard', props.id],
    }),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
