// @flow
import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { Badge, Text, View, Link, Button } from 'components/core';
import { Well, Icon } from 'components/common';
import { StyleSheet, css } from 'libs/ui';
import { useToggleState } from 'libs/misc';
import { getVisibleItems } from 'utils/misc';

type Props = {
  item: { +id: Id, +proposedSolutions: any, +proposedSolution: any },
  modal: ToggleState,
  renderSolution: (any) => React$Node,
};

const styles = StyleSheet.create({
  solutionOptions: css`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 0.5rem;
  `,
  solutionOptionsText: css`
    color: $text-muted;
  `,
  moreSolutions: css`
    border-top-width: 1px;
    border-color: $gray-200;
    padding-top: 1rem;
  `,
});

const dict = createDict({
  solution_proposal: 'Solution Proposal',
  solution_proposals: 'Solution Proposals',
  show_more_solution_proposals:
    '{count} more solution {count, plural, =1 {proposal} other {proposals}}',
  propose_solution: 'Propose solution',
  no_solution_proposal_yet: 'No solution proposal yet',
});

function SolutionProposals({ item, modal, renderSolution }: Props): React$Node {
  const expanded = useToggleState(false);

  const visibleProposedSolutions = expanded.on
    ? item.proposedSolutions
    : getVisibleItems(item.proposedSolutions, item.proposedSolution);

  return (
    <>
      {item.proposedSolutions.length === 0 && (
        <Well styleName="mb-3">
          <Trans {...dict('no_solution_proposal_yet')} color="muted" styleName="mb-3" />
          <Button color="secondary" outline onPress={() => modal.setOn()}>
            <Trans {...dict('propose_solution')} />
          </Button>
        </Well>
      )}
      {item.proposedSolutions.length > 0 && (
        <>
          <View style={styles.solutionOptions}>
            <View styleName="flex-row mb-3 ai-center">
              <Icon glyph="lightbulb" style={styles.solutionOptionsText} styleName="mr-2" />
              <Trans {...dict('solution_proposals')} style={styles.solutionOptionsText} />
              <Badge color="light" styleName="ml-2">
                <Text>{item.proposedSolutions.length}</Text>
              </Badge>
            </View>
            <Button
              color="secondary"
              outline
              onPress={() => {
                modal.setOn();
              }}
              styleName="mb-3"
            >
              <Trans {...dict('propose_solution')} />
            </Button>
          </View>
          {visibleProposedSolutions.map((proposedSolution) => (
            <React.Fragment key={proposedSolution.id}>
              {renderSolution(proposedSolution)}
            </React.Fragment>
          ))}
          {!expanded.on && item.proposedSolutions.length > visibleProposedSolutions.length && (
            <View styleName="mb-3" style={styles.moreSolutions}>
              <Link onPress={() => expanded.setOn()}>
                <Icon glyph="down-open" styleName="mr-2" />
                <Trans
                  {...dict('show_more_solution_proposals', {
                    count: item.proposedSolutions.length - visibleProposedSolutions.length,
                  })}
                />
              </Link>
            </View>
          )}
        </>
      )}
    </>
  );
}

export default SolutionProposals;
