// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const VIDEO_FILE_DOWNLOAD_FRAGMENT: DocumentNode = gql`
  fragment VideoFileDownloadFragment on VideoFile {
    file {
      encodedName
      info {
        size
      }
    }
  }
`;
