// @flow
import React from 'react';
import { View, Text, Modal, Button, Heading, CloseButton } from 'components/core';
import { Domain } from 'libs/misc';

type Props = {
  modal: ToggleState,
};

function RequestAccountModal({ modal }: Props): React$Node {
  return (
    <>
      <Modal visible={modal.on} onToggle={modal.toggle}>
        <Modal.Header styleName="flex-row">
          <Modal.Title>
            <Text>Zugang anfordern</Text>
          </Modal.Title>
          <CloseButton onPress={modal.toggle} />
        </Modal.Header>
        <Modal.Body>
          <Heading size={5}>Willkommen auf Azubinet</Heading>
          <Text styleName="py-3">
            Bitte sende uns eine kurze Nachricht, damit wir deinen Account einrichten können. Wir
            benötigen die folgenden Informationen:
          </Text>
          <Text styleName="pb-2">- Deinen Vor- und Nachnamen</Text>
          <Text styleName="pb-2">- Deinen Ausbildungsberuf</Text>
          <Text styleName="pb-3">
            - Die Prüfung, die du schreibst (z.B. Teil 1 oder Teil 2 der Abschlussprüfung)
          </Text>
          <Text styleName="pb-2">
            Bitte sende uns deine Nachricht mit der E-Mail-Adresse, mit der du deinen Zugang
            registrieren lassen möchtest. Du erhältst dann schnellstmöglich eine E-Mail an deine
            Adresse. Wenn du auf den Link in der E-Mail tippst, aktivierst du deinen Account und
            kannst dir ein Passwort vergeben. Deine passenden Lerninhalte und To-Do-Listen sind dann
            bereits zugeordnet.
          </Text>
          {Domain.is('reisser.azubinet.de') && (
            <Text styleName="pb-2">
              Pro Auszubildender kann jeweils für die Zwischenprüfung/Abschlussprüfung Teil 1 sowie
              Abschlussprüfung/Abschlussprüfung Teil 2 ein kostenloser Zugang beantragt werden.
            </Text>
          )}
          <View styleName="pb-3">
            <Text bold>Wichtig:</Text>
            <Text>Bitte nutze zur Anmeldung immer diese Adresse </Text>
            {Domain.is('netto.azubinet.de') && (
              <Text bold align="center" styleName="py-2">
                netto.azubinet.de
              </Text>
            )}
            {Domain.is('reisser.azubinet.de') && (
              <Text bold align="center" styleName="py-2">
                reisser.azubinet.de
              </Text>
            )}
            {Domain.is('budni.azubinet.de') && (
              <Text bold align="center" styleName="py-2">
                budni.azubinet.de
              </Text>
            )}
            <Text>
              und <Text bold>nicht</Text> die Anmeldung auf www.azubinet.de. Viel Erfolg beim Lernen
              und für die Prüfungen!
            </Text>
          </View>
          <Button
            onPress={() => {
              window.location = 'mailto:post@azubinet.de';
            }}
          >
            <Text>Zugang anfordern</Text>
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RequestAccountModal;
