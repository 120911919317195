// @flow
import React, { useContext } from 'react';
import { Link, View, Text } from 'components/core';
import { StyleSheet, css } from 'libs/ui';
import type { AstNode } from './utils/types';
import Paragraph from './elements/Paragraph';
import Heading from './elements/Heading';
import Blockquote from './elements/Blockquote';
import ThematicBreak from './elements/ThematicBreak';
import Definition from './elements/Definition';
import Image from './elements/Image';
import List from './elements/List';
import ListItem from './elements/ListItem';
import Table from './elements/Table';
import TableBody from './elements/TableBody';
import TableCell from './elements/TableCell';
import TableHead from './elements/TableHead';
import TableRow from './elements/TableRow';
import Code from './elements/Code';
import InlineCode from './elements/InlineCode';
import Math from './elements/Math';
import InlineMath from './elements/InlineMath';
import Html from './elements/Html';
import Context from './Context';

/* eslint-disable react/require-default-props */
type Props = {
  ...AstNode,
  // eslint-disable-next-line react/no-unused-prop-types
  children: React$Node,
};
/* eslint-enable */

type TextProps = {
  ...Props,
  value: string,
};

type LinkProps = {
  ...Props,
  url: string,
  // eslint-disable-next-line react/require-default-props
  title?: string,
};
/* eslint-enable */

const styles = StyleSheet.create({
  root: css`
    flex-shrink: 1;
  `,
  strong: css`
    font-weight: $font-weight-bolder;
    // Workaround for native fonts
    font-family: 'Roboto Medium';
    @include platform(web) {
      font-family: inherit;
    }
  `,
  emphasis: css`
    font-style: italic;
    // Workaround for native fonts
    font-family: 'Roboto Italic';
    @include platform(web) {
      font-family: inherit;
    }
  `,
  delete: css`
    text-decoration: line-through;
  `,
});

const absoluteURLRegExp = new RegExp('^(?:[a-z+]+:)?//', 'i');

const elements = {
  Root({ children }: Props) {
    const { style, styleName, textStyle } = useContext(Context);

    return (
      <View style={[style, styles.root]} styleName={styleName} textStyle={textStyle}>
        {children}
      </View>
    );
  },

  // layout
  Paragraph,
  Heading,
  Blockquote,
  ThematicBreak,
  Definition,

  // text
  Text({ value }: TextProps) {
    const { renderText } = useContext(Context);

    return <Text>{renderText ? renderText(value) : value}</Text>;
  },
  Strong({ children }: Props) {
    return <Text style={styles.strong}>{children}</Text>;
  },
  Emphasis({ children }: Props) {
    return <Text style={styles.emphasis}>{children}</Text>;
  },
  Delete({ children }: Props) {
    return <Text style={styles.delete}>{children}</Text>;
  },
  Link({ url, title, children }: LinkProps) {
    if (!url) {
      return children ? <Text color="muted">{children}</Text> : null;
    }

    return (
      <Link to={url} title={title} external={absoluteURLRegExp.test(url)}>
        {children}
      </Link>
    );
  },
  LinkReference() {
    return null; // TODO: Enable link reference tokenizer and add component.
  },
  ImageReference() {
    return null; // TODO: Enable image reference tokenizer and add component.
  },
  Break() {
    return '\n';
  },

  // image
  Image,

  // lists
  List,
  ListItem,

  // tables
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,

  // code
  Code,
  InlineCode,

  // math
  Math,
  InlineMath,

  // html
  Html,
};

export default function renderToReact(
  node: AstNode,
  parent: ?AstNode = null,
  index: number = 0,
): React$Node {
  const { htmlTag, children, position, type } = node;
  const pos = position.start;

  const elementProps = {
    ...node,
    key: [type, pos.line, pos.column, index].join('-'),
    // We do not need spacing within lists.
    // $FlowFixMe[incompatible-use]
    spacing: !!parent && parent.type !== 'listItem' && index !== parent.children.length - 1,
  };

  const elementType =
    !htmlTag || htmlTag.validated
      ? elements[type.charAt(0).toUpperCase() + type.slice(1)]
      : elements.Html;

  const elementChildren = children
    ? children.map((child, i) => renderToReact(child, node, i))
    : null;

  return React.createElement(elementType, elementProps, elementChildren);
}
