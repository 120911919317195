// @flow
import React from 'react';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';
import { Icon, FooterLink } from 'components/common';
import { View, Container, Grid, Text, Image } from 'components/core';
import LocaleSwitcher from './LocaleSwitcher';
import ViewSwitcher from './ViewSwitcher';

const styles = StyleSheet.create({
  footer: css`
    background-color: $gray-800;
    width: 100%;
    margin-top: 5rem;
    padding-top: 0;
    flex-shrink: 0;
  `,
  footerDesktop: css`
    min-height: 10rem;
  `,
  footerMobile: css`
    min-height: 16rem;
  `,
  container: css`
    padding: 1rem;
  `,
  containerMobile: css`
    max-width: 560px;
  `,
  infoMobile: css`
    border-bottom-width: 1px;
    border-bottom-color: rgba(255, 255, 255, 0.05);
    margin-bottom: 1rem;
    align-items: center;
  `,
  copyright: css`
    padding-top: 1rem;
    text-align: center;
  `,
  text: css`
    color: $gray-400;
  `,
  logo: css`
    border-radius: 0.25rem;
    margin-horizontal: 0.25rem;
  `,
  visaLogo: css`
    height: 30px;
    width: 47px;
  `,
  mastercardLogo: css`
    height: 30px;
    width: 47px;
  `,
  sepaLogo: css`
    height: 30px;
    width: 75px;
  `,
  klarnaLogo: css`
    height: 30px;
    width: 74px;
  `,
});

const dict = createDict({
  discover_azubinet: 'Discover Azubinet',
  apprentices: 'Apprentices',
  business: 'Business',
  store: 'Store',
  teachers_and_schools: 'Teachers & Schools',
  publishers_authors: 'Publishers & Authors',
  about_azubinet: 'About Azubinet',
  home: 'Home',
  about_us: 'Team',
  legal_contact: 'Legal Note & Contact',
  blog: 'Blog',
  faq: 'FAQ',
  privacy: 'Privacy',
  terms_of_use: 'Terms of Use',
  open_educational_resources: 'Open Educational Resources',
  payment_partners: 'Payment Partners',
  career: 'Career',
});

const currentYear = new Date().getFullYear();

function FooterAzubinet(): React$Node {
  const media = useMedia();

  return (
    <>
      <View
        role="contentinfo"
        style={[styles.footer, media.up('md') ? styles.footerDesktop : styles.footerMobile]}
      >
        <Container style={[styles.container, media.down('sm') && styles.containerMobile]}>
          <Grid>
            <Grid.Box size={12} sizeMd={6} style={media.down('sm') && styles.infoMobile}>
              {/* language */}
              <LocaleSwitcher />
              {/* desktop/mobile toggle */}
              <ViewSwitcher />
              <View styleName="mb-3 flex-row ai-center ai-md-start">
                <Text style={styles.text} small>
                  <Icon glyph="instagram" /> &nbsp;
                  <FooterLink to="https://www.instagram.com/azubinet" external>
                    Instagram
                  </FooterLink>
                </Text>
                <Text style={styles.text} small>
                  <Text> · </Text>
                  <Icon glyph="youtube" /> &nbsp;
                  <FooterLink to="https://www.youtube.com/@Azubinet" external>
                    YouTube
                  </FooterLink>
                </Text>
                <Text style={styles.text} small>
                  <Text> · </Text>
                  <Icon glyph="linkedin" /> &nbsp;
                  <FooterLink to="https://www.linkedin.com/showcase/azubinet" external>
                    LinkedIn
                  </FooterLink>
                </Text>
              </View>
              <Text style={styles.text} small>
                <Trans {...dict('payment_partners')} />
              </Text>
              <View styleName="flex-row flex-wrap mt-2">
                <Image source="/img/pages/visaLogo.png" style={[styles.logo, styles.visaLogo]} />
                <Image
                  source="/img/pages/mastercardLogo.png"
                  style={[styles.logo, styles.mastercardLogo]}
                />
                <Image source="/img/pages/sepaLogo.png" style={[styles.logo, styles.sepaLogo]} />
                <Image source="/img/pages/klarna.png" style={[styles.logo, styles.klarnaLogo]} />
              </View>
            </Grid.Box>
            <Grid.Box size={12} sizeMd={3}>
              {/* links for target courses */}
              <View styleName="ai-center ai-md-start jc-md-end">
                <Text style={styles.text} small>
                  <Trans {...dict('discover_azubinet')} />
                </Text>
                <Text style={styles.text} small>
                  <FooterLink to="/">
                    <Trans {...dict('apprentices')} />
                  </FooterLink>
                </Text>
                <Text style={styles.text} small>
                  <FooterLink to="/business">
                    <Trans {...dict('business')} />
                  </FooterLink>
                </Text>
                <Text style={styles.text} small>
                  <FooterLink to="/professions">
                    <Trans {...dict('store')} />
                  </FooterLink>
                </Text>
                <Text style={styles.text} small>
                  <FooterLink to="/education">
                    <Trans {...dict('teachers_and_schools')} />
                  </FooterLink>
                </Text>
                <Text style={styles.text} small>
                  <FooterLink to="/publishers-authors">
                    <Trans {...dict('publishers_authors')} />
                  </FooterLink>
                </Text>
              </View>
            </Grid.Box>
            <Grid.Box size={12} sizeMd={3}>
              {/* links */}
              <View styleName="mb-4 ai-center ai-md-start jc-md-end mt-3 mt-md-0">
                <Text style={styles.text} small>
                  <Trans {...dict('about_azubinet')} />
                </Text>
                <Text style={styles.text} small>
                  <FooterLink to="/">
                    <Trans {...dict('home')} />
                  </FooterLink>
                </Text>
                <Text style={styles.text} small>
                  <FooterLink to="/about-us">
                    <Trans {...dict('about_us')} />
                  </FooterLink>
                </Text>
                <Text style={styles.text} small>
                  <FooterLink to="/career">
                    <Trans {...dict('career')} />
                  </FooterLink>
                </Text>
                <Text style={styles.text} small>
                  <FooterLink to="/legal-contact">
                    <Trans {...dict('legal_contact')} />
                  </FooterLink>
                </Text>
                <Text style={styles.text} small>
                  <FooterLink to="/blog">
                    <Trans {...dict('blog')} />
                  </FooterLink>
                </Text>
                <Text style={styles.text} small>
                  <FooterLink to="/faq">
                    <Trans {...dict('faq')} />
                  </FooterLink>
                </Text>
                <Text style={styles.text} small>
                  <FooterLink to="/privacy-policy">
                    <Trans {...dict('privacy')} />
                  </FooterLink>
                </Text>
                <Text style={styles.text} small>
                  <FooterLink to="/terms-of-use">
                    <Trans {...dict('terms_of_use')} />
                  </FooterLink>
                </Text>
              </View>
              {/* external links */}
            </Grid.Box>
          </Grid>
          <Text small style={[styles.copyright, styles.text]}>
            © Examunity {currentYear}
          </Text>
        </Container>
      </View>
    </>
  );
}

export default FooterAzubinet;
