// @flow
import { useState } from 'react';

export default function useValueState(initialValue: any): ValueState {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    set: (nextValue: any) => {
      setValue(nextValue);
    },
    reset: () => {
      setValue(initialValue);
    },
  };
}
