// @flow
import React from 'react';
import { ImageBackground as BaseImageBackground } from 'bootstrap-rn';
import getSource from './getSource';

type Props = {
  source: string | Object,
  label?: string,
};

const ImageBackground = React.forwardRef<Props, any>((props, ref) => {
  const { source, label, ...elementProps } = props;

  return (
    <BaseImageBackground
      {...elementProps}
      ref={ref}
      source={getSource(source)}
      aria-label={label}
    />
  );
});

ImageBackground.displayName = 'ImageBackground';

export default (ImageBackground: React$AbstractComponent<Props, any>);
