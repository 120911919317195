// @flow
import { useEffect, useRef, useCallback } from 'react';
import { useToggleState, Cookies } from 'libs/misc';

type PdfDownload = {
  generating: boolean,
  start: (name: string, url: string, variables: Object) => Promise<any>,
};

export default function usePdfDownload(): PdfDownload {
  const generating = useToggleState(false);

  const hash = useRef(null);

  const start = useCallback((name, url, variables) => {
    if (generating.on) {
      Promise.reject(new Error('Download generation has already been started.'));
    }

    // Generate hash.
    hash.current = Math.random().toString(16).substr(2, 8);

    generating.setOn();

    const params = new URLSearchParams({
      ...variables,
      name,
      hash: hash.current,
    });

    window.location = `${url}?${params.toString()}`;

    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        // If the hash value is null the component has unmounted.
        if (hash.current === null) {
          clearInterval(interval);
          reject(new Error('Component did unmount before download generation was finished.'));
          return;
        }

        const generated = Cookies.get(`download_${hash.current}`) === 'true';

        if (generated) {
          generating.setOff();
          clearInterval(interval);
          resolve();
        }
      }, 1000);
    });
  }, []);

  // Reset hash on unmount.
  useEffect(() => {
    return () => {
      hash.current = null;
    };
  }, []);

  return {
    generating: generating.on,
    start,
  };
}
