// @flow
import { gql, createQuery } from 'libs/graphql';

const FETCH_COURSE_VOUCHER = gql`
  query CreateCourseMembership($code: String!) {
    courseVoucher(code: $code) {
      redeemed
      courses {
        name
      }
    }
  }
`;

export function fetchCourseVoucher(code: string): QueryAction {
  return createQuery(FETCH_COURSE_VOUCHER, {
    variables: {
      code,
    },
  });
}
