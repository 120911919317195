// @flow
import React from 'react';
import { ListGroup } from 'components/core';
import BundleCompact from './BundleCompact';

type Props = {
  onClose?: () => void,
};

function MFABundles({ onClose }: Props): React$Node {
  return (
    <ListGroup>
      <BundleCompact
        to="/courses/40Gg6xgwFD"
        title="Abschlussprüfung"
        price="kostenlos"
        onClose={onClose}
      />
    </ListGroup>
  );
}

export default MFABundles;
