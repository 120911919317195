// @flow
import React from 'react';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { View, Image, Jumbotron } from 'components/core';
import { BrandHeading, BrandLargeText, BrandText } from 'components/pages';
import { Icon } from 'components/common';
import { createDict, Trans } from 'libs/intl';

const styles = StyleSheet.create({
  containerDesktop: css`
    background-color: #ebf4f9;
    padding: 3rem;
  `,
  heading: css`
    padding-bottom: 1rem;
  `,
  photo: css`
    width: 10rem;
    height: 10rem;
    align-items: center;
    justify-content: center;
  `,
});

const dict = createDict({
  questions: 'Questions?',
  contact_us: 'Contact us',
  monday_friday_times: 'Mo. - Fr., 09:00 - 16:00',
  training_companies_support: 'Training companies support',
});

function Content(): React$Node {
  const media = useMedia();
  return (
    <>
      <Jumbotron style={media.up('md') && styles.containerDesktop}>
        <View styleName="p-3">
          <BrandHeading size={2} style={styles.heading} styleName="as-center">
            <Trans {...dict('questions')} />
          </BrandHeading>
          <View styleName="flex-md-row ai-center jc-center my-3">
            <View styleName="flex-column jc-md-start ai-md-start ai-center jc-center mr-md-5">
              <BrandHeading size={2} style={styles.heading}>
                <Trans {...dict('contact_us')} />
              </BrandHeading>
              <BrandLargeText>Fabian Pohl</BrandLargeText>
              <BrandText>
                <Trans align="center" {...dict('training_companies_support')} />
              </BrandText>
            </View>
            <View styleName="ml-md-3 my-3 my-md-0 jc-center ai-center">
              <Image source="/img/member/Fabian.png" label="Contact" style={styles.photo} />
            </View>
          </View>
          <View styleName="flex-md-row ai-center jc-center mt-5">
            <BrandLargeText styleName="mb-3">
              <BrandText styleName="pr-2">@</BrandText>
              <BrandText>post[at]azubinet.de</BrandText>
            </BrandLargeText>
            <BrandLargeText styleName="mb-md-3 ml-md-5">
              <Icon glyph="phone" styleName="mr-2" />
              <BrandText>0391 50548702</BrandText>
            </BrandLargeText>
            <BrandLargeText styleName="mb-3 pl-3">
              <Trans {...dict('monday_friday_times')} />
            </BrandLargeText>
          </View>
        </View>
      </Jumbotron>
    </>
  );
}

export default Content;
