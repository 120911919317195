// @flow
import React from 'react';
import { useIntl, createDict } from 'libs/intl';
import { Text } from 'components/core';
import { convertTaskPartType } from 'utils/tasks';

type Props = {
  taskPartNumber: string,
  taskPart: Entity<GQLTaskPart>,
};

const dict = createDict({
  cloze: 'Cloze',
  open_question: 'Open Question',
  multiple_choice: 'Multiple Choice',
  single_choice: 'Single Choice',
  true_false: 'True/False',
  arrange_in_order: 'Arrange In Order',
  matching: 'Matching',
  accounting_record: 'Accounting Record',
});

function TaskPartTitle({ taskPartNumber, taskPart }: Props): React$Node {
  const intl = useIntl();

  if (taskPart.title) {
    return `${taskPartNumber} ${taskPart.title}`;
  }

  const key = convertTaskPartType(taskPart, {
    'Cloze.*': 'cloze',
    'MultipleChoice.MULTIPLE': 'multiple_choice',
    'MultipleChoice.SINGLE': 'single_choice',
    'OpenQuestion.*': 'open_question',
    'TrueFalse.*': 'true_false',
    'Pairing.ORDERING': 'arrange_in_order',
    'Pairing.MATCHING': 'matching',
    'Pairing.ACCOUNTING': 'accounting_record',
  });

  return (
    <Text>
      {taskPartNumber} {intl.trans(dict(key))}
    </Text>
  );
}

export default TaskPartTitle;
