// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const SEARCH_RESULT_CHECKLIST_FRAGMENT: DocumentNode = gql`
  fragment SearchResultChecklistFragment on Checklist {
    slug
    name
    isPrivate
    todosCount
    author {
      name
    }
    organization {
      name
    }
    viewerResult {
      id
    }
  }
`;
