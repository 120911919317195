// @flow

type ActivityTypeConversion = {|
  Article?: any,
  Document?: any,
  Exam?: any,
  Quiz?: any,
  Survey?: any,
  Video?: any,
  '*'?: any,
|};

export default function convertActivityType(
  activity: CourseActivityType | Object,
  conversion: ActivityTypeConversion,
): any {
  const type =
    typeof activity === 'string' ? activity : activity.__typename.replace('Activity', '');

  if (conversion[type] !== undefined) {
    return conversion[type];
  }

  if (conversion['*'] !== undefined) {
    return conversion['*'];
  }

  throw new Error(`Unknown activity type. [${type}]`);
}
