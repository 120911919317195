// @flow
import { useCallback } from 'react';

function useDomainChange(value: string): any {
  return useCallback(() => {
    window.location = `https://${value}`;
  }, [value]);
}

export default useDomainChange;
