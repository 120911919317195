// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';
import { getVersion } from 'utils/courses';
import { ACTIVITY_EDITOR_SIDEBAR_FRAGMENT } from '../_shared/ActivityEditorSidebar/fragments';
import { VIDEO_FILE_EDITOR_FRAGMENT } from './VideoFile/fragments';
import { VIDEO_LINK_EDITOR_FRAGMENT } from './VideoLink/fragments';
import { ACTIVITY_EDITOR_TITLE_FRAGMENT } from '../_shared/ActivityEditorTitle/fragments';
import { ATTACHMENTS_FRAGMENT } from '../_shared/MediaGalleryModal';

const VideoEditor = {
  name: 'VideoEditor',
  bundle: () => import('./VideoEditor'),
};

const COURSE_VIDEO_QUERY = gql`
  query CourseVideoQuery(
    $slug: String!
    $courseSlug: String!
    $versionNumber: Int
    $specificVersion: String
  ) {
    course(slug: $courseSlug) {
      videoActivity(slug: $slug) {
        ...ActivityEditorTitleFragment
        ...ActivityEditorSidebarFragment
        slug
        version(versionNumber: $versionNumber, specificVersion: $specificVersion) {
          versionNumber
          isDeprecated
          releasedAt
          item {
            description
            downloadable
            type {
              ...VideoFileEditorFragment
              ...VideoLinkEditorFragment
            }
            ...AttachmentsFragment
          }
        }

        author {
          name
        }
        viewerCanUpdate
      }
      author {
        id
        name
      }
      viewerIsAdmin
      viewerIsMember
      viewerIsObserver
    }
  }

  ${VIDEO_FILE_EDITOR_FRAGMENT}
  ${VIDEO_LINK_EDITOR_FRAGMENT}
  ${ATTACHMENTS_FRAGMENT}
  ${ACTIVITY_EDITOR_TITLE_FRAGMENT}
  ${ACTIVITY_EDITOR_SIDEBAR_FRAGMENT}
`;

const Container = createAsyncContainer(VideoEditor, (props) => {
  const {
    params: { slug, courseSlug },
    searchParams,
  } = props;

  const versionNumber = getVersion(searchParams.get('version'));
  const variables = {
    slug,
    courseSlug,
    versionNumber,
    specificVersion: versionNumber ? null : 'LATEST',
  };

  const loaders = {
    query: query(COURSE_VIDEO_QUERY, { variables }),
  };

  const selectors = {
    course: select(COURSE_VIDEO_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
