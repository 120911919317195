// @flow
import React from 'react';
import { Modal, Button, View, Text, CloseButton } from 'components/core';
import { Icon } from 'components/common';
import { StyleSheet, css, useMedia } from 'libs/ui';

type Props = {
  modal: ToggleState,
};

const styles = StyleSheet.create({
  heading: css`
    font-family: Titillium Web;
    margin-bottom: 1rem;
  `,
  growShrink: css`
    flex-shrink: 1;
  `,
  vogelSpotButtonDesktop: css`
    width: 50%;
    height: 10rem;
  `,
  vogelSpotButtonMobile: css`
    height: 9rem;
  `,
  iconDesktop: css`
    font-size: 3rem;
    line-height: 2.5rem * $line-height-base;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  `,
  iconBusDesktop: css`
    font-size: 2.5rem;
    line-height: 2.25rem * $line-height-base;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  `,
  iconMobile: css`
    font-size: 2.25rem;
    line-height: 2rem * $line-height-base;
    margin-bottom: 1rem;
  `,
  iconBusMobile: css`
    font-size: 1.875rem;
    line-height: 1.875rem * $line-height-base;
    margin-bottom: 1rem;
  `,
  whiteBackground: css`
    background-color: #fff;
  `,
});

function VogelspotCoursesModal({ modal }: Props): React$Node {
  const media = useMedia();

  return (
    <Modal visible={modal.on} onToggle={modal.toggle}>
      <Modal.Header>
        <Modal.Title>
          <Text>Kurse auf VogelSPOT</Text>
        </Modal.Title>
        <CloseButton onPress={modal.toggle} />
      </Modal.Header>
      <Modal.Body styleName="p-0">
        <View styleName="flex-md-row flex-md-wrap flex-shrink-1 jc-md-start ai-md-start">
          <Button
            color="light"
            to="/courses/nnAFoQPvQx"
            size="lg"
            style={[
              styles.growShrink,
              media.up('md') ? styles.vogelSpotButtonDesktop : styles.vogelSpotButtonMobile,
            ]}
          >
            <View styleName="flex-column flex-shrink-1">
              <View styleName="flex-row ai-center jc-center">
                <Icon
                  glyph="truck"
                  style={[media.up('md') ? styles.iconDesktop : styles.iconMobile]}
                  styleName="mr-1"
                />
                <Icon
                  glyph="bus"
                  style={[media.up('md') ? styles.iconBusDesktop : styles.iconBusMobile]}
                  styleName="ml-1"
                />
              </View>
              <Text>Prüfungstraining Berufskraftfahrer</Text>
            </View>
          </Button>
          <Button
            color="light"
            to="/courses/vWPwp2AnbH"
            size="lg"
            style={[
              styles.growShrink,
              media.up('md') ? styles.vogelSpotButtonDesktop : styles.vogelSpotButtonMobile,
            ]}
          >
            <View styleName="flex-column flex-shrink-1">
              <View styleName="flex-row ai-center jc-center">
                <Icon
                  glyph="building-filled"
                  style={[media.up('md') ? styles.iconDesktop : styles.iconMobile]}
                  styleName="mr-1"
                />
                <Icon
                  glyph="truck"
                  style={[media.up('md') ? styles.iconDesktop : styles.iconMobile]}
                  styleName="ml-1"
                />
              </View>
              <Text>Fachkunde Güterkraftverkehr</Text>
            </View>
          </Button>
          <Button
            color="light"
            to="/courses/cWhuGE5AUg"
            size="lg"
            style={[
              styles.growShrink,
              media.up('md') ? styles.vogelSpotButtonDesktop : styles.vogelSpotButtonMobile,
            ]}
          >
            <View styleName="flex-column">
              <Icon
                glyph="fire-1"
                style={[styles.growShrink, media.up('md') ? styles.iconDesktop : styles.iconMobile]}
              />
              <Text>Erstschulung Basiskurs</Text>
            </View>
          </Button>
          <Button
            color="light"
            to="/courses/MChhrcYu8O"
            size="lg"
            style={[
              styles.growShrink,
              media.up('md') ? styles.vogelSpotButtonDesktop : styles.vogelSpotButtonMobile,
            ]}
          >
            <View styleName="flex-column">
              <Icon
                glyph="fire-1"
                style={[styles.growShrink, media.up('md') ? styles.iconDesktop : styles.iconMobile]}
              />
              <Text>Auffrischungsschulung</Text>
            </View>
          </Button>
          <Button
            color="light"
            to="/courses/sRDEM1zCd3"
            size="lg"
            style={[
              styles.growShrink,
              media.up('md') ? styles.vogelSpotButtonDesktop : styles.vogelSpotButtonMobile,
            ]}
          >
            <View styleName="flex-column">
              <Icon
                glyph="fire-1"
                style={[styles.icon, media.up('md') ? styles.iconDesktop : styles.iconMobile]}
              />
              <Text>Aufbaukurs Tank</Text>
            </View>
          </Button>
          <Button
            color="light"
            to="/courses/3GF95CRNwn"
            size="lg"
            style={[
              styles.growShrink,
              media.up('md') ? styles.vogelSpotButtonDesktop : styles.vogelSpotButtonMobile,
            ]}
          >
            <View styleName="flex-column flex-shrink-1">
              <View styleName="flex-row ai-center jc-center">
                <Icon
                  glyph="person"
                  style={[media.up('md') ? styles.iconDesktop : styles.iconMobile]}
                  styleName="mr-1"
                />
                <Icon
                  glyph="taxi"
                  style={[media.up('md') ? styles.iconDesktop : styles.iconMobile]}
                  styleName="ml-1"
                />
              </View>
              <Text>Fachkunde Taxi</Text>
            </View>
          </Button>
          <Button
            color="light"
            to="/courses/o22UXQvS1M"
            size="lg"
            style={[
              styles.growShrink,
              media.up('md') ? styles.vogelSpotButtonDesktop : styles.vogelSpotButtonMobile,
            ]}
          >
            <View styleName="flex-column flex-shrink-1">
              <View styleName="flex-row ai-center jc-center">
                <Icon
                  glyph="ship"
                  style={[media.up('md') ? styles.iconDesktop : styles.iconMobile]}
                  styleName="mr-1"
                />
                <Icon
                  glyph="truck"
                  style={[media.up('md') ? styles.iconBusDesktop : styles.iconBusMobile]}
                  styleName="ml-1"
                />
              </View>
              <Text>Abschlussprüfung Speditionskaufleute</Text>
            </View>
          </Button>
        </View>
      </Modal.Body>
    </Modal>
  );
}

export default VogelspotCoursesModal;
