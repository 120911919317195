// @flow
import React from 'react';
import { View, Image, Text, Button } from 'components/core';
import { useIntl } from 'libs/intl';
import { Domain } from 'libs/misc';
import { StyleSheet, css, useMedia } from 'libs/ui';
import BrandHeading from './BrandHeading';

type Props = {
  noAction?: boolean,
};

const styles = StyleSheet.create({
  containerDesktop: css`
    flex-direction: row;
    align-items: center;
    padding: 2rem;
  `,
  containerMobile: css`
    margin-horizontal: 1rem;
    align-items: center;
  `,
  action: css`
    flex-shrink: 1;
    align-items: flex-start;
  `,
  button: css`
    margin-top: 2rem;
  `,
  appStoreBadge: css`
    width: 135px;
    height: 40px;
  `,
  imageDesktop: css`
    width: 626px;
    height: 370px;
    margin-right: 4rem;
  `,
  imageMobile: css`
    width: 256px;
    height: 151px;
    margin-bottom: 2rem;
  `,
});

const defaultProps = {
  noAction: false,
};

function DevicesStripe({ noAction }: Props): React$Node {
  const intl = useIntl();
  const media = useMedia();

  const image = Domain.is('www.examunity.com')
    ? '/img/home/img-cta.png'
    : '/img/home/img-cta-azubinet.png';

  return (
    <View style={media.up('md') ? styles.containerDesktop : styles.containerMobile}>
      <Image
        source={image}
        label="Background"
        style={media.up('md') ? styles.imageDesktop : styles.imageMobile}
      />
      <View style={styles.action}>
        <BrandHeading size={2}>
          {intl.locale === 'en'
            ? 'On any device, in browser and app.'
            : 'Lernen wann und wo du willst, am Schreibtisch und mobil.'}
        </BrandHeading>
        <View styleName="flex-row flex-wrap mt-3">
          <Button
            to="https://play.google.com/store/apps/details?id=de.azubinet.client"
            external
            styleName="p-0 mr-2"
            color="light"
          >
            <Image
              source="/img/home/google_app.png"
              label="Google App"
              style={styles.appStoreBadge}
            />
          </Button>
          <Button
            to="https://apps.apple.com/us/app/azubinet/id6443448057"
            external
            styleName="p-0 ml-2"
            color="light"
          >
            <Image
              source="/img/home/apple_app.png"
              label="Apple App"
              style={styles.appStoreBadge}
            />
          </Button>
        </View>
        {!noAction && (
          <Button size="lg" to="/register" style={styles.button} color="success">
            <Text>{intl.locale === 'en' ? 'Register for free' : 'Kostenlos registrieren'}</Text>
          </Button>
        )}
      </View>
    </View>
  );
}

DevicesStripe.defaultProps = defaultProps;

export default DevicesStripe;
