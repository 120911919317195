// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { View } from 'components/core';

type Props = {
  children: React$Node,
};

const styles = StyleSheet.create({
  container: css`
    flex-grow: 1;
    @include platform(web) {
      min-height: 100vh;
    }
  `,
});

function Content({ children }: Props): React$Node {
  return <View style={styles.container}>{children}</View>;
}

export default Content;
