// @flow
import React from 'react';
import { View } from 'components/core';
import CourseCard from './CourseCard';

type Props = {
  value: ValueState,
  showDefault: ToggleState,
};

function CoursesList({ value, showDefault }: Props): React$Node {
  return (
    <View styleName="flex-row flex-wrap jc-md-start">
      {value.value === 'Drogist/-innen' && (
        <CourseCard
          courseName="Abschlussprüfung"
          professionName="Drogist/-innen"
          courseType="Kaufbarer Kurs"
          courseLink="o4ACHylIzZ"
        />
      )}
      {value.value === 'Florist/-innen' && (
        <CourseCard
          courseName="Abschlussprüfung"
          professionName="Florist/-innen"
          courseType="Kaufbarer Kurs"
          courseLink="CpDNuW3L0n"
        />
      )}
      {value.value === 'Kaufleute für Büromanagement' && (
        <>
          <CourseCard
            courseName="1. Ausbildungsjahr"
            professionName="Kaufleute für Büromanagement"
            courseType="Kaufbarer Kurs"
            courseLink="lS1XyiKX1Q"
          />
          <CourseCard
            courseName="2. Ausbildungsjahr"
            professionName="Kaufleute für Büromanagement"
            courseType="Kaufbarer Kurs"
            courseLink="CV3hytrHAL"
          />
        </>
      )}
      {(showDefault.on ||
        value.value === 'Kaufleute im Einzelhandel' ||
        value.value === 'Verkäufer') && (
        <>
          <CourseCard
            courseName="1. Ausbildungsjahr"
            professionName="Kaufleute im Einzelhandel | Verkäufer/-innen"
            courseType="Kaufbarer Kurs"
            courseLink="JanG56JLiL"
          />
          <CourseCard
            courseName="2. Ausbildungsjahr"
            professionName="Kaufleute im Einzelhandel | Verkäufer/-innen"
            courseType="Kaufbarer Kurs"
            courseLink="CcnDN11VMS"
          />
        </>
      )}
      {value.value === 'Kaufleute im Einzelhandel' && (
        <CourseCard
          courseName="3. Ausbildungsjahr"
          professionName="Kaufleute im Einzelhandel"
          courseType="Kaufbarer Kurs"
          courseLink="L3xImii3Vl"
        />
      )}
      {value.value === 'Automobilkaufleute' && (
        <CourseCard
          courseName="Abschlussprüfung Teil 1"
          professionName="Automobilkaufleute"
          courseType="Kaufbarer Kurs"
          courseLink="L3xImii3Vl"
        />
      )}
      {(showDefault.on || value.value === 'Kaufleute im E-Commerce') && (
        <CourseCard
          courseName="Abschlussprüfung Teil 1"
          professionName="Kaufleute im E-Commerce"
          courseType="Kaufbarer Kurs"
          courseLink="5ivYDJQekv"
        />
      )}
      {value.value === 'Kaufleute im E-Commerce' && (
        <CourseCard
          courseName="Abschlussprüfung Teil 2"
          professionName="Kaufleute im E-Commerce"
          courseType="Kaufbarer Kurs"
          courseLink="ORuRpBqobA"
        />
      )}
      {value.value === 'Personaldienstleistungskaufleute' && (
        <CourseCard
          courseName="Auftragsgewinnung, -bearbeitung und -steuerung"
          professionName="Personaldienstleistungskaufleute"
          courseType="Kaufbarer Kurs"
          courseLink="o15NEsjVzw"
        />
      )}
      {value.value === 'Steuerfachangestellte' && (
        <CourseCard
          courseName="1. Lehrjahr"
          professionName="Steuerfachangestellte"
          courseType="Kaufbarer Kurs"
          courseLink="0oWd4bZR1U"
        />
      )}
      {value.value === 'Kaufleute im Groß- und Außenhandel' && (
        <CourseCard
          courseName="Außenhandelsgeschäfte"
          professionName="Kaufleute im Groß- und Außenhandel (FR Außenhandel)"
          courseType="Kaufbarer Kurs"
          courseLink="ZVvzc6Mpzt"
        />
      )}
      {value.value === 'Bankkaufleute' && (
        <CourseCard
          courseName="Bankwirtschaft"
          professionName="Bankkaufleute"
          courseType="Kostenloser Kurs"
          courseLink="QesqBB2cIP"
        />
      )}
      {value.value === 'Medizinische Fachangestellte' && (
        <>
          <CourseCard
            courseName="Abschlussprüfung"
            professionName="Medizinische/-r Fachangestellte/-r"
            courseType="Kaufbarer Kurs"
            courseLink="40Gg6xgwFD"
          />
        </>
      )}
      {value.value === 'Fachinformatiker/-innen' && (
        <>
          <CourseCard
            courseName="Ganzheitliche Aufgaben I: Fachqualifikationen (AE)"
            professionName="Fachinformatiker/-innen Anwendungsentwicklung"
            courseType="Kostenloser Kurs"
            courseLink="lSa2yvGLCC"
          />
          <CourseCard
            courseName="Ganzheitliche Aufgaben II: Kernqualifikationen"
            professionName="Fachinformatiker/-innen Anwendungsentwicklung / Systemintegration"
            courseType="Kostenloser Kurs"
            courseLink="inNKcAaW3h"
          />
        </>
      )}
      {value.value === 'Hotelfachleute' && (
        <CourseCard
          courseName="Gästeempfang und Beratung"
          professionName="Hotelfachleute"
          courseType="Kaufbarer Kurs"
          courseLink="nKsSPngZ22"
        />
      )}
      {value.value === 'Industriekaufleute' && (
        <CourseCard
          courseName="Geschäftsprozesse"
          professionName="Industriekaufleute"
          courseType="Kaufbarer Kurs"
          courseLink="YXKJcFzGAG"
        />
      )}
      {value.value === 'Kaufleute für Büromanagement' && (
        <CourseCard
          courseName="Informationstechnisches Büromanagement (AP Teil 1)"
          professionName="Kaufleute für Büromanagement (IHK)"
          courseType="Kaufbarer Kurs"
          courseLink="rV9NlcPnfX"
        />
      )}
      {value.value === 'Kaufleute für Büromanagement' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Kaufleute für Büromanagement (IHK)"
          courseType="Kaufbarer Kurs"
          courseLink="eVPLGN6SKt"
        />
      )}
      {value.value === 'Kaufleute im Gesundheitswesen' && (
        <CourseCard
          courseName="Geschäfts- und Leistungsprozesse in Einrichtungen des Gesundheitswesens"
          professionName="Kaufleute im Gesundheitswesen"
          courseType="Kaufbarer Kurs"
          courseLink="jvH6DKc2HD"
        />
      )}
      {value.value === 'Kaufleute im Einzelhandel' && (
        <CourseCard
          courseName="Geschäftsprozesse im Einzelhandel"
          professionName="Kaufleute im Einzelhandel"
          courseType="Kaufbarer Kurs"
          courseLink="onzwMcsx9Y"
        />
      )}
      {value.value === 'Kaufleute im Gesundheitswesen' && (
        <CourseCard
          courseName="Gesundheitswesen"
          professionName="Kaufleute im Gesundheitswesen"
          courseType="Kaufbarer Kurs"
          courseLink="EnY2kEL2PD"
        />
      )}
      {value.value === 'Kaufleute im Groß- und Außenhandel' && (
        <CourseCard
          courseName="Großhandelsgeschäfte"
          professionName="Kaufleute im Groß- und Außenhandel (FR Großhandel)"
          courseType="Kaufbarer Kurs"
          courseLink="XxDq4VeflS"
        />
      )}
      {value.value === 'Fachlagerist/-innen' && (
        <CourseCard
          courseName="Güterbewegung"
          professionName="Fachlagerist/-innen"
          courseType="Kaufbarer Kurs"
          courseLink="b93G7kaFHk"
        />
      )}
      {value.value === 'Kaufleute im Groß- und Außenhandel' && (
        <CourseCard
          courseName="Kaufmännische Steuerung und Kontrolle, Organisation"
          professionName="Kaufleute im Groß- und Außenhandel"
          courseType="Kaufbarer Kurs"
          courseLink="5G0NPyaYcX"
        />
      )}
      {value.value === 'Industriekaufleute' && (
        <CourseCard
          courseName="Kaufmännische Steuerung und Kontrolle"
          professionName="Industriekaufleute"
          courseType="Kaufbarer Kurs"
          courseLink="PuMj4gTz1b"
        />
      )}
      {value.value === 'Kaufleute für Spedition und Logistikdienstleistung' && (
        <CourseCard
          courseName="Kaufmännische Steuerung und Kontrolle"
          professionName="Kaufleute für Spedition und Logistikdienstleistung"
          courseType="Kaufbarer Kurs"
          courseLink="12WqapnwjR"
        />
      )}
      {value.value === 'Kaufleute für Groß- und Außenhandelsmanagement' && (
        <CourseCard
          courseName="Prozessorientierte Organisation von Großhandelsgeschäften"
          professionName="Kaufleute für Groß- und Außenhandelsmanagement (FR Großhandel)"
          courseType="Kaufbarer Kurs"
          courseLink="CRlbWcJV99"
        />
      )}
      {value.value === 'Kaufleute für Groß- und Außenhandelsmanagement' && (
        <CourseCard
          courseName="Kaufmännische Steuerung von Geschäftsprozessen"
          professionName="Kaufleute für Groß- und Außenhandelsmanagement"
          courseType="Kaufbarer Kurs"
          courseLink="QxixLUc9Sp"
        />
      )}
      {value.value === 'Kaufleute für Büromanagement' && (
        <CourseCard
          courseName="Kundenbeziehungsprozesse (AP Teil 2)"
          professionName="Kaufleute für Büromanagement (IHK)"
          courseType="Kaufbarer Kurs"
          courseLink="rXiunh5qI8"
        />
      )}
      {value.value === 'Fachlagerist/-innen' && (
        <CourseCard
          courseName="Lagerprozesse"
          professionName="Fachlagerist/-innen"
          courseType="Kaufbarer Kurs"
          courseLink="x7PDxClHif"
        />
      )}
      {value.value === 'Kaufleute für Spedition und Logistikdienstleistung' && (
        <CourseCard
          courseName="Leistungserstellung in Spedition und Logistik"
          professionName="Kaufleute für Spedition und Logistikdienstleistung"
          courseType="Kaufbarer Kurs"
          courseLink="bYbOmeSyXZ"
        />
      )}
      {value.value === 'Hotelfachleute' && (
        <CourseCard
          courseName="Marketing und Arbeitsorganisation"
          professionName="Hotelfachleute"
          courseType="Kaufbarer Kurs"
          courseLink="3Hax4yo8l9"
        />
      )}
      {value.value === 'Mathe-Basics' && (
        <CourseCard
          courseName="Mathe-Basics"
          professionName="Alle Ausbildungsberufe"
          courseType="Kostenloser Kurs"
          courseLink="MTi3QFl2sH"
        />
      )}
      {value.value === 'Personaldienstleistungskaufleute' && (
        <CourseCard
          courseName="Personalwirtschaftliche Prozesse"
          professionName="Personaldienstleistungskaufleute"
          courseType="Kaufbarer Kurs"
          courseLink="lmoinSFYiS"
        />
      )}
      {value.value === 'Fachkräfte im Gastgewerbe' && (
        <CourseCard
          courseName="Produkte und gastorientierte Dienstleistungen"
          professionName="Fachkräfte im Gastgewerbe"
          courseType="Kostenloser Kurs"
          courseLink="jRdlVrfLT0"
        />
      )}
      {value.value === 'Fachkräfte für Lagerlogistik' && (
        <CourseCard
          courseName="Prozesse der Fachkräfte für Lagerlogistik"
          professionName="Fachkräfte für Lagerlogistik"
          courseType="Kaufbarer Kurs"
          courseLink="cAc5GXBXP9"
        />
      )}
      {value.value === 'Fachkräfte für Lagerlogistik' && (
        <CourseCard
          courseName="Rationeller und qualitätssichernder Güterumschlag"
          professionName="Fachkräfte für Lagerlogistik"
          courseType="Kaufbarer Kurs"
          courseLink="UjEaITb6Nx"
        />
      )}
      {value.value === 'Rechnungswesen-Basics' && (
        <CourseCard
          courseName="Rechnungswesen-Basics"
          professionName="Alle Ausbildungsberufe"
          courseType="Kostenloser Kurs"
          courseLink="3Khhacxgh5"
        />
      )}
      {value.value === 'Bankkaufleute' && (
        <CourseCard
          courseName="Rechnungswesen und Steuerung"
          professionName="Bankkaufleute"
          courseType="Kostenloser Kurs"
          courseLink="wqnrV9Oqzn"
        />
      )}
      {value.value === 'Restaurantfachleute' && (
        <>
          <CourseCard
            courseName="Restaurantorganisation"
            professionName="Restaurantfachleute"
            courseType="Kaufbarer Kurs"
            courseLink="XZVtsI3Wp3"
          />
          <CourseCard
            courseName="Service"
            professionName="Restaurantfachleute"
            courseType="Kaufbarer Kurs"
            courseLink="7cwNiTrorT"
          />
        </>
      )}
      {value.value === 'Fachkräfte für Systemgastronomie' && (
        <>
          <CourseCard
            courseName="Steuerung und Kontrolle, Personalwesen"
            professionName="Fachkräfte für Systemgastronomie"
            courseType="Kostenloser Kurs"
            courseLink="Uo7eChoWnT"
          />
          <CourseCard
            courseName="Systemorganisation"
            professionName="Fachkräfte für Systemgastronomie"
            courseType="Kostenloser Kurs"
            courseLink="q78crsMleY"
          />
        </>
      )}
      {value.value === 'Veranstaltungskaufleute' && (
        <>
          <CourseCard
            courseName="Veranstaltungsorganisation"
            professionName="Veranstaltungskaufleute"
            courseType="Kostenloser Kurs"
            courseLink="7WcZf3oETl"
          />
          <CourseCard
            courseName="Veranstaltungswirtschaft"
            professionName="Veranstaltungskaufleute"
            courseType="Kostenloser Kurs"
            courseLink="ScepbxZaIW"
          />
        </>
      )}
      {(showDefault.on ||
        value.value === 'Kaufleute im Einzelhandel' ||
        value.value === 'Verkäufer/-innen') && (
        <CourseCard
          courseName="Verkauf und Werbemaßnahmen"
          professionName="Kaufleute im Einzelhandel | Verkäufer/-innen"
          courseType="Kaufbarer Kurs"
          courseLink="sKby2ltafz"
        />
      )}
      {value.value === 'Fachkräfte im Gastgewerbe' && (
        <CourseCard
          courseName="Warenwirtschaft"
          professionName="Fachkräfte im Gastgewerbe"
          courseType="Kostenloser Kurs"
          courseLink="b1oJjcUO9b"
        />
      )}
      {value.value === 'Köche' && (
        <CourseCard
          courseName="Abschlussprüfung"
          professionName="Köche"
          courseType="Kaufbarer Kurs"
          courseLink="ZE8zgtnjhh"
        />
      )}
      {(value.value === 'Kaufleute im Einzelhandel' || value.value === 'Verkäufer/-innen') && (
        <CourseCard
          courseName="Warenwirtschaft und Kalkulation"
          professionName="Kaufleute im Einzelhandel | Verkäufer/-innen"
          courseType="Kaufbarer Kurs"
          courseLink="hZVxKWkGf2"
        />
      )}
      {(value.value === 'WiSo I' ||
        value.value === 'Drogist/-innen' ||
        value.value === 'Fachkräfte für Lagerlogistik' ||
        value.value === 'Fachkräfte für Systemgastronomie' ||
        value.value === 'Fachkräfte im Gastgewerbe' ||
        value.value === 'Fachlagerist/-innen' ||
        value.value === 'Florist/-innen' ||
        value.value === 'Hotelfachleute' ||
        value.value === 'Kaufleute im Einzelhandel' ||
        value.value === 'Kaufleute im Gesundheitswesen' ||
        value.value === 'Köche' ||
        value.value === 'Restaurantfachleute' ||
        value.value === 'Veranstaltungskaufleute' ||
        value.value === 'Verkäufer/-innen') && (
        <CourseCard
          courseName="Wirtschafts- und Sozialkunde I"
          professionName="Kaufmännische Berufe (normales Anforderungsniveau)"
          courseType="Kaufbarer Kurs"
          courseLink="qLmkoQREwv"
        />
      )}
      {(value.value === 'WiSo II' ||
        value.value === 'Automobilkaufleute' ||
        value.value === 'Bankkaufleute' ||
        value.value === 'Fachinformatiker/-innen' ||
        value.value === 'Immobilienkaufleute' ||
        value.value === 'Industriekaufleute' ||
        value.value === 'Kaufleute für Büromanagement' ||
        value.value === 'Kaufleute im E-Commerce' ||
        value.value === 'Kaufleute im Groß- und Außenhandel' ||
        value.value === 'Kaufleute für Spedition und Logistikdienstleistung') && (
        <CourseCard
          courseName="Wirtschafts- und Sozialkunde II"
          professionName="Kaufmännische Berufe (erhöhtes Anforderungsniveau)"
          courseType="Kaufbarer Kurs"
          courseLink="NiWFktctzi"
        />
      )}
      {(value.value === 'WiSo gewerblich' || value.value === 'Kfz-Mechatroniker/-innen') && (
        <CourseCard
          courseName="Wirtschafts- und Sozialkunde"
          professionName="Gewerblich-technische Berufe"
          courseType="Kaufbarer Kurs"
          courseLink="jkxxY27mf1"
        />
      )}
      {value.value === 'Bankkaufleute' && (
        <CourseCard
          courseName="Zwischenprüfung / AP Teil 1"
          professionName="Bankkaufleute"
          courseType="Kostenloser Kurs"
          courseLink="x7QITaX8EI"
        />
      )}
      {value.value === 'Fachinformatiker/-innen' && (
        <CourseCard
          courseName="Zwischenprüfung / AP Teil 1"
          professionName="Fachinformatiker/-innen Anwendungsentwicklung / Systemintegration"
          courseType="Kaufbarer Kurs"
          courseLink="X16i147fJJ"
        />
      )}
      {(showDefault.on || value.value === 'Fachkräfte für Lagerlogistik') && (
        <CourseCard
          courseName="Zwischenprüfung"
          professionName="Fachkräfte für Lagerlogistik"
          courseType="Kaufbarer Kurs"
          courseLink="8V4qLot3G5"
        />
      )}
      {(showDefault.on || value.value === 'Industriekaufleute') && (
        <CourseCard
          courseName="Zwischenprüfung"
          professionName="Industriekaufleute"
          courseType="Kaufbarer Kurs"
          courseLink="JYtiWB4oxp"
        />
      )}
      {value.value === 'Kaufleute im Gesundheitswesen' && (
        <CourseCard
          courseName="Zwischenprüfung"
          professionName="Kaufleute im Gesundheitswesen"
          courseType="Kaufbarer Kurs"
          courseLink="ofGT023B44"
        />
      )}
      {value.value === 'Kaufleute im Groß- und Außenhandel' && (
        <CourseCard
          courseName="Zwischenprüfung"
          professionName="Kaufleute im Groß- und Außenhandel"
          courseType="Kaufbarer Kurs"
          courseLink="FOTRrsPzfn"
        />
      )}
      {value.value === 'Kaufleute für Spedition und Logistikdienstleistung' && (
        <CourseCard
          courseName="Zwischenprüfung"
          professionName="Kaufleute für Spedition und Logistikdienstleistung"
          courseType="Kaufbarer Kurs"
          courseLink="aYbOolnJjg"
        />
      )}
      {value.value === 'Personaldienstleistungskaufleute' && (
        <CourseCard
          courseName="Zwischenprüfung"
          professionName="Personaldienstleistungskauleute"
          courseType="Kaufbarer Kurs"
          courseLink="xy7l37INiG"
        />
      )}
      {value.value === 'Veranstaltungskaufleute' && (
        <CourseCard
          courseName="Zwischenprüfung"
          professionName="Veranstaltungskaufleute"
          courseType="Kostenloser Kurs"
          courseLink="lLELI9sEIt"
        />
      )}
      {value.value === 'Verkäufer/-innen' && (
        <CourseCard
          courseName="Zwischenprüfung"
          professionName="Verkäufer/-innen"
          courseType="Kaufbarer Kurs"
          courseLink="jgfhvx1knN"
        />
      )}
      {value.value === 'Automobilkaufleute' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Automobilkaufleute"
          courseType="Kostenloser Kurs"
          courseLink="5Hb1wZWF7Q"
        />
      )}
      {value.value === 'Bankkaufleute' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Bankkaufleute"
          courseType="Kostenloser Kurs"
          courseLink="KYlbuP96A5"
        />
      )}
      {value.value === 'Fachinformatiker/-innen' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Fachinformatiker/-innen"
          courseType="Kostenloser Kurs"
          courseLink="p2LNBzMtRS"
        />
      )}
      {value.value === 'Fachkräfte für Lagerlogistik' ||
        (value.value === 'Fachlagerist/-innen' && (
          <CourseCard
            courseName="Kostenlose Lernmaterialien und Community"
            professionName="Fachkräfte für Lagerlogistik | Fachlagerist/-innen"
            courseType="Kostenloser Kurs"
            courseLink="BWUrPiP8hB"
          />
        ))}
      {value.value === 'Fachkräfte für Systemgastronomie' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Fachkräfte für Systemgastronomie"
          courseType="Kostenloser Kurs"
          courseLink="UbAtxDZYrU"
        />
      )}
      {value.value === 'Fachkräfte im Gastgewerbe' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Fachkräfte im Gastgewerbe"
          courseType="Kostenloser Kurs"
          courseLink="ItUVcraWjg"
        />
      )}
      {value.value === 'Hotelfachleute' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Hotelfachleute"
          courseType="Kostenloser Kurs"
          courseLink="8OlswH7l34"
        />
      )}
      {value.value === 'Industriekaufleute' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Industriekaufleute"
          courseType="Kostenloser Kurs"
          courseLink="E7KIeSeujm"
        />
      )}
      {value.value === 'Kaufleute für Büromanagement' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Kaufleute für Büromanagement"
          courseType="Kostenloser Kurs"
          courseLink="eVPLGN6SKt"
        />
      )}
      {value.value === 'Kaufleute im E-Commerce' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Kaufleute im E-Commerce"
          courseType="Kostenloser Kurs"
          courseLink="pmkYpxvEuK"
        />
      )}
      {value.value === 'Kaufleute im Einzelhandel' ||
        (value.value === 'Verkäufer/-innen' && (
          <CourseCard
            courseName="Kostenlose Lernmaterialien und Community"
            professionName="Kaufleute im Einzelhandel | Verkäufer/-innen"
            courseType="Kostenloser Kurs"
            courseLink="L8y5cyW1PJ"
          />
        ))}
      {value.value === 'Kaufleute im Gesundheitswesen' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Kaufleute im Gesundheitswesen"
          courseType="Kostenloser Kurs"
          courseLink="cuOsv0GDIt"
        />
      )}
      {value.value === 'Kaufleute im Groß- und Außenhandel' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Kaufleute im Groß- und Außenhandel"
          courseType="Kostenloser Kurs"
          courseLink="wnHFB8q97X"
        />
      )}
      {value.value === 'Kaufleute für Spedition und Logistikdienstleistung' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Kaufleute für Spedition und Logistikdienstleistung"
          courseType="Kostenloser Kurs"
          courseLink="shUe1FJmsj"
        />
      )}
      {value.value === 'Kaufleute f+r Groß- und Außenhandelsmanagement' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Kaufleute für Groß- und Außenhandelsmanagement"
          courseType="Kostenloser Kurs"
          courseLink="wnHFB8q97X"
        />
      )}
      {value.value === 'Köche' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Köche"
          courseType="Kostenloser Kurs"
          courseLink="tTgcqHF3ff"
        />
      )}
      {value.value === 'Personaldienstleistungskaufleute' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Personaldienstleistungskaufleute"
          courseType="Kostenloser Kurs"
          courseLink="pALvuIP1lL"
        />
      )}
      {value.value === 'Restaurantfachleute' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Restaurantfachleute"
          courseType="Kostenloser Kurs"
          courseLink="cneSzc9b5W"
        />
      )}
      {value.value === 'Steuerfachangestellte' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Steuerfachangestellte"
          courseType="Kostenloser Kurs"
          courseLink="sRWDDIfSot"
        />
      )}
      {value.value === 'Veranstaltungskaufleute' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Veranstaltungskaufleute"
          courseType="Kostenloser Kurs"
          courseLink="e1fBPncZUu"
        />
      )}
      {value.value === 'Mediengestalter/-innen Bild und Ton' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Mediengestalter/-innen Bild und Ton"
          courseType="Kostenloser Kurs"
          courseLink="5StDGwZLzd"
        />
      )}
      {value.value === 'Drogist/-innen' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Drogist/-innen"
          courseType="Kostenloser Kurs"
          courseLink="bQQcC1bvpB"
        />
      )}
      {value.value === 'Florist/-innen' && (
        <CourseCard
          courseName="Kostenlose Lernmaterialien und Community"
          professionName="Florist/-innen"
          courseType="Kostenloser Kurs"
          courseLink="uqIlfNitHJ"
        />
      )}
    </View>
  );
}

export default CoursesList;
