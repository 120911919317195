// @flow

const cache = {};

const getHeadElement = () => document.head || document.getElementsByTagName('head')[0];

const Analytics = {
  init(id: string) {
    cache.id = id;
  },
  activate() {
    if (!cache.id || Analytics.active()) {
      return;
    }

    // Create gtag script tag.
    const gtagScript = document.createElement('script');
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${cache.id}`;
    gtagScript.async = true;

    // Create script tag for tracking code.
    const trackScript = document.createElement('script');
    trackScript.appendChild(
      document.createTextNode(`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      `),
    );

    // Load gtag script
    const head = getHeadElement();
    head.appendChild(gtagScript);
    head.appendChild(trackScript);

    cache.scripts = { gtagScript, trackScript };
  },
  deactivate() {
    if (!cache.id || !Analytics.active()) {
      return;
    }

    const {
      script: { gtagScript, trackScript },
    } = cache;

    const head = getHeadElement();
    head.removeChild(gtagScript);
    head.removeChild(trackScript);

    delete cache.scripts;
  },
  pageview(parameters: Object) {
    if (!cache.id || !Analytics.active()) {
      return;
    }

    // Set cookie domain for subdomains and anonymize IP
    //
    // Ref:
    // https://developers.google.com/analytics/devguides/collection/gtagjs/cookies-user-id
    // https://developers.google.com/analytics/devguides/collection/gtagjs/ip-anonymization
    window.gtag('config', cache.id, {
      ...parameters,
      cookie_domain: window.location.hostname === 'localhost' ? 'none' : window.location.hostname,
      anonymize_ip: true,
    });
  },
  active(): boolean {
    return !!(cache.id && cache.scripts);
  },
};

export default Analytics;
