// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { Text } from 'components/core';

type Props = {
  children: React$Node,
  style?: any,
};

const styles = StyleSheet.create({
  textStyle: css`
    font-size: 1.25rem;
    line-height: 1.25rem * $line-height-base;
  `,
});

const LargeText = React.forwardRef<Props, any>((props, ref) => {
  const { children, style, ...elementProps } = props;

  return (
    <Text {...elementProps} ref={ref} style={[styles.textStyle, style]}>
      {children}
    </Text>
  );
});

export default (LargeText: React$AbstractComponent<Props, any>);
