// @flow
import { createContainer } from 'libs/container';
import { select, gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import ChoiceCard from './ChoiceCard';

export const QUIZ_CHOICE_CARD_FRAGMENT: DocumentNode = gql`
  fragment QuizChoiceCardFragment on ChoiceCard {
    text
    hint
    single
    answers {
      text
      explanation
      correct
    }
  }
`;

const Container = createContainer(ChoiceCard, (props) => {
  const selectors = {
    choiceCard: select(QUIZ_CHOICE_CARD_FRAGMENT, {
      entry: ['ChoiceCard', props.id],
    }),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
