// @flow
import React from 'react';
import BundleCompact from './BundleCompact';

type Props = {
  onClose?: () => void,
};

function KaufleuteImEinzelhandelBundles({ onClose }: Props): React$Node {
  return (
    <>
      <BundleCompact
        to="/courses/L8y5cyW1PJ"
        title="Erstmal reinschnuppern"
        price="kostenlose Lernmaterialien und Community"
        onClose={onClose}
      />
      <BundleCompact
        to="/professions/kauffrau-kaufmann-im-einzelhandel"
        title="Lernpaket Kaufleute im Einzelhandel"
        price="ab 26,95 EUR"
        paid
      />
    </>
  );
}

export default KaufleuteImEinzelhandelBundles;
