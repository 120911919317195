// @flow
import React, { useMemo } from 'react';
import { useMedia } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';
import { QuizCard } from 'components/quizzes';
import { Scrollbars } from 'components/common';
import { Text, View, Badge, Blockquote } from 'components/core';
import { Markdown } from 'components/markdown';
import MatchingCardPair from './MatchingCardPair';
import type { QuizState, CurrentQuizCardState } from '../useQuizState';

type Props = {
  matchingCard: Entity<GQLMatchingCard>,
  quizState: QuizState,
  currentCardState: CurrentQuizCardState,
};

const dict = createDict({
  question: 'Question',
  matching: 'Matching',
  solution: 'Solution',
  choose_the_matching_answers: 'Choose the matching answers...',
  hint: 'Hint',
});

const shuffleArray = (arr) => {
  return [...arr].sort(() => Math.random() - 0.5);
};

function MatchingCard({ matchingCard, quizState, currentCardState }: Props): React$Node {
  const media = useMedia();
  const { hint, answers } = matchingCard;

  const DesktopScrollbars = media.up('lg') ? Scrollbars : React.Fragment;

  const matchingAnswers = useMemo(() => {
    return shuffleArray(matchingCard.answers);
  }, [matchingCard.id]);

  return (
    <QuizCard>
      <QuizCard.Front styleName="p-0">
        <DesktopScrollbars>
          <View styleName="p-3 p-lg-4">
            <View styleName="flex-row mb-3 jc-between ai-center">
              <Text color="muted" styleName="pr-3">
                <Trans {...dict('question')} /> #{currentCardState.number}
              </Text>
              <Badge color="secondary">
                <Trans {...dict('matching')} />
              </Badge>
            </View>
            <Markdown source={matchingCard.text} />
          </View>
        </DesktopScrollbars>
      </QuizCard.Front>
      <QuizCard.Back styleName="p-0">
        <DesktopScrollbars
          // Scroll to top of card back (by recreating element) on flip (desktop)
          key={currentCardState.flipped ? 1 : 0}
        >
          <View styleName="p-3 p-lg-4">
            <Text styleName="pb-3">
              {currentCardState.flipped ? (
                <Trans {...dict('solution')} color="muted" />
              ) : (
                <Trans {...dict('choose_the_matching_answers')} color="primary" />
              )}
            </Text>
            {answers.map((answer, key) => (
              <View styleName={key < answers.length - 1 ? 'mb-3' : undefined} key={answer.id}>
                <MatchingCardPair
                  answer={answer}
                  matchingAnswers={matchingAnswers}
                  quizState={quizState}
                  currentCardState={currentCardState}
                />
              </View>
            ))}
            {hint && currentCardState.flipped && (
              <Blockquote styleName="mt-3">
                <Trans {...dict('hint')} color="muted" small styleName="mb-2" />
                <Markdown source={hint} />
              </Blockquote>
            )}
          </View>
        </DesktopScrollbars>
      </QuizCard.Back>
    </QuizCard>
  );
}

export default MatchingCard;
