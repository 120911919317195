// @flow
import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { Text, Button, ListGroup } from 'components/core';
import useChangeDomain from './useChangeDomain';

type Props = {
  domain: string,
};

const dict = createDict({
  open: 'Open',
});

function AlternativeDomain({ domain }: Props): React$Node {
  const changeDomain = useChangeDomain(domain);

  return (
    <ListGroup.Item styleName="flex-row jc-between ai-center pr-2">
      <Text>{domain}</Text>
      <Button onPress={changeDomain}>
        <Trans {...dict('open')} />
      </Button>
    </ListGroup.Item>
  );
}

export default AlternativeDomain;
