// @flow
import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { StyleSheet, css } from 'libs/ui';
import { View } from 'components/core';
import { Markdown } from 'components/markdown';
import { useField } from 'formik';

type Props = {
  name: string,
  preview: ToggleState,
};

const dict = createDict({
  nothing_to_preview: 'Nothing to preview',
});

const styles = StyleSheet.create({
  preview: css`
    margin-horizontal: 0.75rem;
    margin-vertical: 0.375rem;
  `,
});

function Preview({ name, preview }: Props): React$Node {
  const [, meta] = useField(name);

  const isEmpty = !meta.value;

  // Only render preview if preview is on.
  if (!preview.on) {
    return null;
  }

  return (
    <View style={styles.preview}>
      {isEmpty && <Trans color="muted" styleName="mx-auto my-3" {...dict('nothing_to_preview')} />}
      {!isEmpty && <Markdown source={meta.value} />}
    </View>
  );
}

export default Preview;
