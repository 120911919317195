// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import { CHECKLIST_TODO_ACTIVITY_FRAGMENT } from './ChecklistTodoCourseActivity/fragments';
import { CHECKLIST_TODO_CUSTOM_FRAGMENT } from './ChecklistTodoCustom/fragments';

export const CHECKLIST_TODO_FRAGMENT: DocumentNode = gql`
  fragment ChecklistTodoFragment on ChecklistTodo {
    position
    item {
      ...ChecklistTodoActivityFragment
      ...ChecklistTodoCustomFragment
      viewerResult {
        createdAt
      }
    }
  }

  ${CHECKLIST_TODO_ACTIVITY_FRAGMENT}
  ${CHECKLIST_TODO_CUSTOM_FRAGMENT}
`;
