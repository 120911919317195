// @flow
import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { Modal, Button, CloseButton, ListGroup } from 'components/core';
import AlternativeDomain from './AlternativeDomain';

type Props = {
  modal: ToggleState,
  domains: string[],
};

const dict = createDict({
  alternative_domains: 'Alternative domains',
  your_email_address_is_registered_on_the_following_domains:
    'Your email address is registered on the following domains:',
  cancel: 'Cancel',
});

function AlternativeDomainsModal({ modal, domains }: Props): React$Node {
  return (
    <Modal visible={modal.on} onToggle={modal.toggle}>
      <Modal.Header>
        <Modal.Title>
          <Trans {...dict('alternative_domains')} />
        </Modal.Title>
        <CloseButton onPress={modal.toggle} />
      </Modal.Header>
      <Modal.Body>
        <Trans
          {...dict('your_email_address_is_registered_on_the_following_domains')}
          styleName="mb-3"
        />
        <ListGroup>
          {domains.map((domain) => (
            <AlternativeDomain domain={domain} key={domain} />
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="light"
          onPress={() => {
            modal.setOff();
          }}
        >
          <Trans {...dict('cancel')} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AlternativeDomainsModal;
