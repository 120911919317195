// @flow
import React from 'react';
import { Form } from 'components/forms';
import { useMutation } from 'libs/graphql';
import { createDict, Trans, useIntl } from 'libs/intl';
import { StyleSheet, css } from 'libs/ui';
import { useSearchParams } from 'libs/routing';
import {
  Section,
  HighlightStripe,
  DevicesStripe,
  Spacing,
  BrandHeading,
  BrandLargeText,
  BrandText,
} from 'components/pages';
import { Link, Heading, View, Grid, Alert, Jumbotron } from 'components/core';
import { useDocumentTitle, Domain } from 'libs/misc';
import { useScroll } from 'libs/scroll';
import ContactSection from '../_shared/ContactSection';
import References from '../_shared/References';
import { createInquiry } from './mutations';

type Props = {
  domain: Entity<GQLDomain>,
};

const styles = StyleSheet.create({
  jumbotron: css`
    max-width: 24rem;
    border-radius: 0.5rem;
  `,
  form: css`
    flex-shrink: 1;
    flex-grow: 1;
  `,
  success: css`
    margin-bottom: 10rem;
  `,
});

const dict = createDict({
  on_this_domain_you_cannot_register_for_a_demo: 'On this domain you cannot register for a demo.',
  access_for_schools: 'Access for schools',
  fourteen_day_trial_for_companies_and_teachers: '14-day trial for companies and teachers',
  access_requested: 'Access requested',
  first_name: 'First Name',
  last_name: 'Last Name',
  position: 'Position',
  trainer: 'Trainer',
  teacher: 'Teacher',
  phone: 'Phone',
  business_email_address: 'Business email address',
  name_of_company_school: 'Name of company / school',
  request_access: 'Request access',
  access_requested_message:
    'Thank you for your request! Please give us some time to set up your access (usually not more than three hours). We will reach out to you as soon as possible.',
  try_for_two_weeks_without_charge: 'Try Azubinet for two weeks without any charges!',
  access_without_charge: 'Try Azubinet now without any charges!',
  you_are_right_here: 'You are right here!',
  form_info:
    'By submitting this form we will set up your company access you can use to manage your apprentices.',
  trial_info:
    'Choose a license that fits your needs after the 14 days trial period. Your access will NOT be changed to a paid license automatically after the trial period. You can terminate the access at any time.',
  access_for_teachers:
    'As a teacher you can use Examunity without any charges. We will get back to you as soon as possible to send you all the information to get started. Please note that we might request a verification from you to proof that you are a teacher.',
  your_access_is_and_will_be_free:
    'Your access is and will be free. Additional features may be part of the pro licenses.',
  are_you_a_trainer_or_hr_representative: 'Are you a trainer or HR representative?',
  free_access_for_teachers_and_trainers: 'Free access for teachers and trainers',
  you_will_receive_your_access_data_after_we_verified_your_account:
    'You will receive your access data after we verified your account.',
  form_info_vogelspot:
    'After submitting this form we will set up your trainer access you can use to manage your learners.',
  access_info_vogelspot:
    'As a trainer or teacher you can use VogelSPOT without any charges. We will get back to you as soon as possible to send you all the information to get started. Please note that we might request a verification from you to proof that you are a trainer or teacher.',
  access_for_trainers_and_teachers: 'Access for trainers and teachers',
});

function RequestAccess({ domain }: Props): React$Node {
  const intl = useIntl();
  const mutation = useMutation();
  const scroll = useScroll();
  const [searchParams] = useSearchParams();
  const paramRef = searchParams.get('ref');

  const titleKey = Domain.is('www.vogelspot.de')
    ? 'access_for_trainers_and_teachers'
    : 'fourteen_day_trial_for_companies_and_teachers';

  useDocumentTitle(dict(titleKey));

  return (
    <>
      {!domain.permissions.usersCanCreateInquiries && (
        <Section color="white">
          <HighlightStripe styleName="p-md-4">
            <Trans {...dict('on_this_domain_you_cannot_register_for_a_demo')} />
          </HighlightStripe>
        </Section>
      )}
      {domain.permissions.usersCanCreateInquiries && (
        <>
          {!mutation.ok && (
            <>
              <Section color="white">
                <HighlightStripe styleName="p-md-4">
                  <View styleName="mb-4">
                    <BrandHeading size={2} styleName="mb-4">
                      {!Domain.is('www.vogelspot.de') && <Trans {...dict(titleKey)} />}
                      {Domain.is('www.vogelspot.de') && (
                        <Trans {...dict('free_access_for_teachers_and_trainers')} />
                      )}
                    </BrandHeading>
                    <BrandLargeText styleName="mb-3" bold>
                      {!Domain.is('www.vogelspot.de') && (
                        <Trans {...dict('try_for_two_weeks_without_charge')} />
                      )}
                      {Domain.is('www.vogelspot.de') && (
                        <Trans
                          {...dict(
                            'you_will_receive_your_access_data_after_we_verified_your_account',
                          )}
                        />
                      )}
                    </BrandLargeText>
                  </View>
                  <View styleName="flex-md-row">
                    <Form
                      initialValues={{
                        lastName: null,
                        firstName: null,
                        position: null,
                        email: null,
                        phone: null,
                        organization: null,
                      }}
                      validate={{
                        lastName: 'required|max:255',
                        firstName: 'required|max:255',
                        position: 'required|max:255',
                        // If registration is not for subdomain, we need to check if email is already in use.
                        email: `required|max:255|email${
                          domain.allowsSubdomains ? '' : '|unique:email'
                        }`,
                        phone: 'required|max:255',
                        organization: 'required|max:255',
                      }}
                      onSubmit={async (values) => {
                        const ref = parseInt(paramRef, 10) || 0;

                        await mutation.dispatch(
                          createInquiry(
                            values.firstName,
                            values.lastName,
                            values.position,
                            values.email,
                            values.phone,
                            values.organization,
                            ref,
                          ),
                        );

                        scroll.scrollTo(0);
                      }}
                      style={styles.form}
                    >
                      <Grid>
                        <Grid.Box size={12} sizeMd={6}>
                          <Form.Input
                            name="firstName"
                            title={dict('first_name')}
                            placeholder="Jane/John"
                          />
                        </Grid.Box>
                        <Grid.Box size={12} sizeMd={6}>
                          <Form.Input name="lastName" title={dict('last_name')} placeholder="Doe" />
                        </Grid.Box>
                      </Grid>
                      <Form.Input
                        name="position"
                        title={dict('position')}
                        placeholder={dict(Domain.is('www.examunity.com') ? 'teacher' : 'trainer')}
                      />
                      <Form.Input
                        name="email"
                        title={dict('business_email_address')}
                        placeholder="doe@example.com"
                        inputMode="email"
                        autoCorrect={false}
                        autoCapitalize="none"
                      />
                      <Form.Input
                        inputMode="tel"
                        name="phone"
                        title={dict('phone')}
                        placeholder="+49 0123 456789"
                      />
                      <Form.Input
                        name="organization"
                        title={dict('name_of_company_school')}
                        placeholder="Example GmbH"
                      />
                      <BrandText styleName="mt-2 mb-1">
                        {intl.locale === 'en' && (
                          <>
                            When requesting an access you accept the{' '}
                            <Link to="/terms-of-use">Terms of Use</Link> and the{' '}
                            <Link to="/privacy-policy">Privacy Policy</Link> regulations.
                          </>
                        )}
                        {intl.locale === 'de' && (
                          <>
                            Mit Absenden der Anfrage akzeptieren Sie unsere{' '}
                            <Link to="/terms-of-use">Nutzungsbedingungen</Link> und die{' '}
                            <Link to="/privacy-policy">Datenschutzbestimmungen</Link>.
                          </>
                        )}
                      </BrandText>
                      <Form.Button
                        color="success"
                        size="lg"
                        type="submit"
                        styleName="mt-3 as-center"
                      >
                        <Trans {...dict('request_access')} />
                      </Form.Button>
                    </Form>
                    <Jumbotron
                      style={styles.jumbotron}
                      styleName="ml-md-4 mt-5 mt-md-3 as-center as-md-start"
                    >
                      {!Domain.is('www.vogelspot.de') && (
                        <>
                          <BrandLargeText styleName="mb-3">
                            <Trans {...dict('are_you_a_trainer_or_hr_representative')} />
                          </BrandLargeText>
                          <BrandText styleName="mb-3">
                            <Trans {...dict('you_are_right_here')} />
                          </BrandText>
                          <BrandText styleName="mb-3">
                            <Trans {...dict('form_info')} />
                          </BrandText>
                          <BrandText>
                            <Trans {...dict('trial_info')} />
                          </BrandText>
                        </>
                      )}
                      {Domain.is('www.vogelspot.de') && (
                        <>
                          <BrandText styleName="mb-3">
                            <Trans {...dict('form_info_vogelspot')} />
                          </BrandText>
                          <BrandText>
                            <Trans {...dict('access_info_vogelspot')} />
                          </BrandText>
                        </>
                      )}
                      {Domain.is('www.examunity.com') && (
                        <>
                          <BrandText styleName="mb-3">
                            <Trans {...dict('access_for_teachers')} />
                          </BrandText>
                          <BrandText>
                            <Trans {...dict('your_access_is_and_will_be_free')} />
                          </BrandText>
                        </>
                      )}
                    </Jumbotron>
                  </View>
                </HighlightStripe>
                <Spacing />
                {!Domain.is('www.vogelspot.de') && (
                  <>
                    <ContactSection />
                    <Spacing />
                    <References />
                  </>
                )}
              </Section>
              {!Domain.is('www.vogelspot.de') && (
                <Section color="gray">
                  <DevicesStripe noAction />
                </Section>
              )}
            </>
          )}
          {mutation.ok && (
            <Section color="gray">
              <Alert color="success" style={styles.success} styleName="mt-3">
                <Heading size={4} styleName="mb-3">
                  <Trans {...dict('access_requested')} />
                </Heading>
                <Trans {...dict('access_requested_message')} />
              </Alert>
            </Section>
          )}
        </>
      )}
    </>
  );
}

export default RequestAccess;
