// @flow
import React from 'react';
import { View, Button, Heading, Card } from 'components/core';
import { Icon } from 'components/common';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';

const styles = StyleSheet.create({
  card: css`
    box-shadow: 0 1px 5px 2px $transparent-200;
  `,
  title: css`
    text-align: center;
    margin-horizontal: 1rem;
  `,
  text: css`
    line-height: 1.75rem;
  `,
  blueBackground: css`
    background-color: rgb(0, 132, 166);
    border-radius: 0.125rem;
  `,
  azubinetButton: css`
    text-transform: none;
  `,
});

const dict = createDict({
  learn_together: 'learn together',
  master_your_exams: 'Master Your Exams',
  teaser_text:
    'Learn with thousands of exercises and flashcards and prepare on your exams together with your classmates and colleagues - without any charges, at your desk and on the go. Now for students and pupils.',
  for_teachers: 'For Teachers',
  teacher_text:
    'Old LMS are giving you headaches? Try Examunity as the perfect add-on for your classes, use thousands of study materials and add your own. Now free for all teachers!',
  you_are_a_trainee_or_trainer: 'You are a trainee or trainer?',
  trainee_text:
    'You are looking for learning content or you want to add your own materials? Or you are a trainer who wants to support their trainees with high-quality online tools?',
  register: 'Register',
  try_it_for_free: 'Try it for free',
  visit_azubinet_de: 'Visit azubinet.de',
});

function TeaserSection(): React$Node {
  const media = useMedia();

  return (
    <Card style={styles.card} styleName="mx-3 mx-md-0">
      <Card.Body styleName="ai-center">
        <View style={styles.blueBackground} styleName="py-3 px-3 px-md-5 ai-center">
          <Heading size={2} styleName="mb-3" style={styles.title}>
            {media.up('md') && <Icon glyph="graduation-cap" styleName="mr-3" color="light" />}
            <Trans color="light" {...dict('master_your_exams')} />
          </Heading>
          <Trans align="center" {...dict('teaser_text')} style={styles.text} color="light" />
          <View styleName="mt-3 mb-md-3 ai-center">
            <Button color="success" to="/register" size="lg">
              <Trans {...dict('register')} />
            </Button>
          </View>
        </View>
        <View styleName="flex-md-row jc-between flex-grow-1 flex-shrink-1 mt-3">
          <View styleName="flex-column flex-grow-1 flex-shrink-1 m-md-3">
            <View styleName="ai-center">
              <Heading size={2} styleName="mb-3" style={styles.title}>
                <Icon glyph="book-open" styleName="mr-3" />
                <Trans {...dict('for_teachers')} />
              </Heading>
              <Trans align="center" {...dict('teacher_text')} style={styles.text} />
            </View>
            <View styleName="mt-3 ai-center">
              <Button color="success" to="/request-access" size="lg">
                <Trans {...dict('try_it_for_free')} />
              </Button>
            </View>
          </View>
          <View styleName="flex-column flex-grow-1 flex-shrink-1 mt-4 mt-md-0 m-md-3">
            <View styleName="ai-center">
              <Heading size={2} styleName="mb-3" style={styles.title}>
                <Icon glyph="smile" styleName="mr-3" />
                <Trans {...dict('you_are_a_trainee_or_trainer')} />
              </Heading>
              <Trans align="center" {...dict('trainee_text')} style={styles.text} />
            </View>
            <View styleName="mt-3 ai-center">
              <Button size="lg" color="secondary" to="https://www.azubinet.de" external>
                <Trans {...dict('visit_azubinet_de')} style={styles.azubinetButton} />
              </Button>
            </View>
          </View>
        </View>
      </Card.Body>
    </Card>
  );
}

export default TeaserSection;
