// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const TRUE_FALSE_RESULT_FRAGMENT: DocumentNode = gql`
  fragment TrueFalseResultFragment on TrueFalse {
    text
    sampleSolution {
      statementValues {
        position
        value
      }
      explanation
    }
    examAttemptSolution(slug: $slug, attemptId: $attemptId) {
      statementValues {
        position
        value
      }
    }
    statements {
      position
      text
    }
  }
`;
