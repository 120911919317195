// @flow
import { gql, createMutation } from 'libs/graphql';

const READ_NOTIFICATION = gql`
  mutation ReadNotification($id: ClientID!) {
    notification_read(id: $id) {
      readAt
    }
  }
`;

export function readNotification(id: Id, userId: Id): MutationAction {
  return createMutation(READ_NOTIFICATION, {
    variables: {
      id,
    },
    updater: (store) => {
      store.update('User', userId, (record) => {
        record.set('unreadNotificationsCount', (value) => value - 1);
      });
    },
  });
}

const MARK_ALL_USER_UNREAD_NOTIFICATION_AS_READ = gql`
  mutation MarkAllUsersUnreadNotificationsAsRead($id: ClientID!) {
    user_readAllNotifications(id: $id) {
      notifications {
        type
        text
        url
        readAt
        updatedAt
        createdAt
      }
    }
  }
`;

export function markAllUsersUnreadNotificationsAsRead(userId: Id): MutationAction {
  return createMutation(MARK_ALL_USER_UNREAD_NOTIFICATION_AS_READ, {
    variables: {
      id: userId,
    },
    updater: (store) => {
      store.update('User', userId, (record) => {
        record.set('unreadNotificationsCount', 0);
      });
    },
  });
}
