// @flow
import { useCallback } from 'react';

export default function useFileOpener(file: Entity<GQLFile>): [any, Object] {
  const loading = false;

  const viewFile = useCallback(() => {
    window.open(`/files/${file.id}/${file.encodedName}`, '_blank');
  }, []);

  return [viewFile, { loading }];
}
