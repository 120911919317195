// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const COURSES_DROPDOWN_FRAGMENT: DocumentNode = gql`
  fragment CoursesDropdownFragment on Course {
    paid
    slug
    name
    caption
    createdAt
    viewerCanCreateArticles
    viewerCanCreateDocuments
    viewerCanCreateQuizzes
    viewerCanCreateTasks
    viewerCanCreateVideos
    viewerCanCreateSurveys
  }
`;
