// @flow
import React from 'react';
import { Markdown } from 'components/markdown';
import { View } from 'components/core';
import { Icon } from 'components/common';
import { createDict, Trans, useIntl } from 'libs/intl';
import { injectTooltip } from 'libs/ui';
import PieceMatching from './Matching/PieceMatching';
import PieceAccounting from './Accounting/PieceAccounting';
import SolutionFormOrdering from './Ordering/SolutionFormOrdering';
import SolutionFormMatching from './Matching/SolutionFormMatching';
import SolutionFormAccounting from './Accounting/SolutionFormAccounting';
import type { TaskExamControls } from '../useExamControls';

type Props = {
  pairing: Entity<GQLPairing>,
  examAttemptId: Id,
  controls: TaskExamControls,
};

const dict = createDict({
  statements: 'Statements',
  options_to_match: 'Options to match',
  match_each_of_these_options_with_a_statement: 'Match each of these options with a statement.',
});

const IconTooltip = injectTooltip(View);

function Pairing({ pairing, examAttemptId, controls }: Props): React$Node {
  const intl = useIntl();

  return (
    <>
      {pairing.type === 'ORDERING' && (
        <>
          <Markdown variant="article" source={pairing.text} styleName="mb-3" />
          <SolutionFormOrdering
            pairing={pairing}
            examAttemptId={examAttemptId}
            controls={controls}
            viewerExamSolution={pairing.viewerRunningExamSolution}
          />
        </>
      )}
      {pairing.type === 'MATCHING' && (
        <>
          <Markdown variant="article" source={pairing.text} styleName="mb-3" />
          <View styleName="jc-start flex-row mt-4 mb-2">
            <Icon glyph="list-numbered" color="muted" styleName="pr-2" />
            <Trans {...dict('statements')} color="muted" />
          </View>
          <View styleName="flex-column flex-shrink-1">
            {pairing.pieces
              .filter((piece) => piece.side === 'LEFT')
              .map((piece, key) => (
                <PieceMatching key={piece.id} piece={piece} last={pairing.pieces.length === key} />
              ))}
          </View>
          <View styleName="ai-start jc-start flex-row mt-4 mb-2">
            <Icon glyph="sliders" color="muted" styleName="pr-2" />
            <Trans {...dict('options_to_match')} color="muted" />
            <IconTooltip
              tooltip={{
                title: intl.trans(dict('match_each_of_these_options_with_a_statement')),
                trigger: 'hover',
                placement: 'top',
              }}
            >
              <Icon glyph="info-circled-alt" color="muted" styleName="pl-2" />
            </IconTooltip>
          </View>
          <SolutionFormMatching
            pairing={pairing}
            examAttemptId={examAttemptId}
            controls={controls}
            viewerExamSolution={pairing.viewerRunningExamSolution}
          />
        </>
      )}
      {pairing.type === 'ACCOUNTING' && (
        <>
          <Markdown variant="article" source={pairing.text} styleName="mb-3" />
          {pairing.pieces.map((piece, key) => (
            <PieceAccounting
              key={piece.id}
              piece={piece}
              last={pairing.pieces.length - 1 === key}
            />
          ))}
          <SolutionFormAccounting
            pairing={pairing}
            examAttemptId={examAttemptId}
            controls={controls}
            viewerExamSolution={pairing.viewerRunningExamSolution}
          />
        </>
      )}
    </>
  );
}

export default Pairing;
