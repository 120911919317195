// @flow
import React from 'react';
import BundleCompact from './BundleCompact';

type Props = {
  onClose?: () => void,
};

function SteuerfachangestellteBundles({ onClose }: Props): React$Node {
  return (
    <>
      <BundleCompact
        to="/courses/sRWDDIfSot"
        title="Erstmal reinschnuppern"
        price="kostenlose Lernmaterialien und Community"
        onClose={onClose}
      />
      <BundleCompact
        to="/professions/steuerfachangestellte-steuerfachangestellter"
        title="1. Lehrjahr"
        price="ab 39,95 EUR"
        paid
      />
      <BundleCompact
        to="/professions/steuerfachangestellte-steuerfachangestellter"
        title="Steuerlehre | von KIEHL"
        price="14,90 EUR"
        paid
      />
      <BundleCompact
        to="/professions/steuerfachangestellte-steuerfachangestellter"
        title="Rechnungswesen | von KIEHL"
        price="14,90 EUR"
        paid
      />
      <BundleCompact
        to="/professions/steuerfachangestellte-steuerfachangestellter"
        title="Wirtschafts- und Sozialkunde | von KIEHL"
        price="14,90 EUR"
        paid
      />
      <BundleCompact
        to="/professions/steuerfachangestellte-steuerfachangestellter"
        title="Komplettpaket Abschlussprüfung | von KIEHL"
        price="39,90 EUR"
        paid
      />
    </>
  );
}

export default SteuerfachangestellteBundles;
