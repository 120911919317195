// @flow
import React from 'react';
import { Platform } from 'react-native';
import { Grid, View } from 'components/core';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  children: React$Node,
  size?: number,
};

const styles = StyleSheet.create({
  divider: css`
    width: 100%;
  `,
});

function PageHeader({ children, size = 12, ...elementProps }: Props): React$Node {
  const role = Platform.OS === 'web' ? 'banner' : null;

  return (
    <>
      <Grid.Box {...elementProps} role={role} size={12} sizeMd={size}>
        {children}
      </Grid.Box>
      <View style={styles.divider} />
    </>
  );
}

export default PageHeader;
