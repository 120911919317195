// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const ReportCollection = {
  name: 'ReportCollection',
  bundle: () => import('./ReportCollection'),
};

const REPORT_COLLECTION_QUERY = gql`
  query ReportCollectionQuery($collectionId: ClientID!) {
    reportCollection(collectionId: $collectionId) {
      trainee {
        name
      }
      type
      profession
      startDate
      endDate
      reportsStartDate
      hoursPerWeek
      subject
      trainer
      address
      company
      chamber
      school
      reports {
        beginAt
        endAt
        completed
        approved
        userNote
        trainerNote
        createdAt
        entries {
          date
          category
          status
          activities {
            text
            duration
          }
        }
      }
    }
  }
`;

const Container = createAsyncContainer(ReportCollection, (props) => {
  const {
    params: { collectionId },
  } = props;
  const loaders = {
    query: query(REPORT_COLLECTION_QUERY, { variables: { collectionId } }),
  };

  const selectors = {
    reportCollection: select(REPORT_COLLECTION_QUERY, { variables: { collectionId } }),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
