// @flow
import React from 'react';
import { Heading, Alert } from 'components/core';
import { createDict, Trans } from 'libs/intl';
import { StyleSheet, css } from 'libs/ui';
import AccessAllowedDefault from './AccessAllowedDefault';
import AccessAllowedVoucher from './AccessAllowedVoucher';
import AccessAllowedPassword from './AccessAllowedPassword';

type Props = {
  course: Entity<GQLCourse>,
  viewer: Entity<GQLUser>,
};

const dict = createDict({
  voucher_required: 'Access code required',
  password_required: 'Password required',
  you_are_not_a_member_of_this_course_only_members_can_learn_with_this_content:
    'You are not a member of this course. Only members can learn with this content.',
  confirm_no_abusive_content:
    "When joining the course, you confirm that you don't post or share any violent, vulgar or abusive content.",
});

const styles = StyleSheet.create({
  font: css`
    color: $gray-900;
  `,
});

function AccessAllowed({ course, viewer }: Props): React$Node {
  return (
    <Alert color="info" styleName="mb-3">
      {course.restricted === 'VOUCHER' && (
        <Heading size={5} styleName="mb-3">
          <Trans {...dict('voucher_required')} />
        </Heading>
      )}
      {course.restricted === 'PASSWORD' && (
        <Heading size={5} styleName="mb-3">
          <Trans {...dict('password_required')} />
        </Heading>
      )}
      <Trans
        {...dict('you_are_not_a_member_of_this_course_only_members_can_learn_with_this_content')}
        styleName="mb-3"
        style={styles.font}
      />
      <Trans {...dict('confirm_no_abusive_content')} styleName="mb-3" style={styles.font} />
      {!course.restricted && <AccessAllowedDefault course={course} viewer={viewer} />}
      {course.restricted === 'VOUCHER' && <AccessAllowedVoucher course={course} viewer={viewer} />}
      {course.restricted === 'PASSWORD' && (
        <AccessAllowedPassword course={course} viewer={viewer} />
      )}
    </Alert>
  );
}

export default AccessAllowed;
