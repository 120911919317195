// @flow
import React from 'react';
import { Icon } from 'components/common';
import { Text, Grid } from 'components/core';
import { BrandHeading, BrandLargeText } from 'components/pages';
import { StyleSheet, css, useMedia } from 'libs/ui';

type Props = {
  title: string,
  content: string,
  icon: string,
  type: string,
};

const styles = StyleSheet.create({
  fontColorGrey: css`
    color: #13573b;
  `,
  fontColorGreen: css`
    color: #76b82a;
  `,
  GridBoxDesktop: css`
    align-items: center;
    padding: 0rem 1rem 0rem 1rem;
  `,
  BoxHeaderTitle: css`
    color: #13573b;
  `,
  BoxContentDesktop: css`
    text-align: center;
    padding-top: 0.5rem;
  `,
  GridBoxMobile: css`
    align-items: center;
    padding: 1rem;
  `,
  BoxContentMobile: css`
    text-align: center;
  `,
});

function FeaturesBox(props: Props): React$Node {
  const { title, content, icon, type } = props;
  const media = useMedia();

  let iconColor = styles.fontColorGreen;
  if (type === 'grey') {
    iconColor = styles.fontColorGrey;
  }

  return (
    <Grid.Box
      size={12}
      sizeMd={4}
      style={[media.up('md') ? styles.GridBoxDesktop : styles.GridBoxMobile]}
    >
      <BrandHeading size={4} styleName="mb-2">
        <Icon glyph={icon} label="Feature" style={[iconColor]} styleName="mr-3">
          {type}
        </Icon>
        <Text style={[styles.BoxHeaderTitle]}>{title}</Text>
      </BrandHeading>
      <BrandLargeText style={media.up('md') ? styles.BoxContentDesktop : styles.BoxContentMobile}>
        {content}
      </BrandLargeText>
    </Grid.Box>
  );
}

export default FeaturesBox;
