// @flow
import React, { useEffect, useRef } from 'react';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { useToggleState } from 'libs/misc';
import { createDict, Trans } from 'libs/intl';
import { View, Offcanvas, CloseButton } from 'components/core';
import { useMenu } from 'libs/foundation';
import { Platform } from 'react-native';
import { Scrollbars } from 'components/common';

type Props = {
  children: React$Node,
  selectedMembershipId: ValueState,
  activeChecklistResultId: ValueState,
};

const styles = StyleSheet.create({
  content: css`
    min-height: 100%;
    padding: 1rem;
  `,
  container: css`
    flex-grow: 1;
  `,
  drawer: css`
    width: 305px;
    border-right-width: 1px;
    border-color: $gray-300;
  `,
  header: css`
    border-bottom-width: 1px;
    border-color: $gray-200;
  `,
  body: css`
    position: absolute;
    top: 0;
    bottom: 0;

    @include platform(web) {
      top: 3.5rem;
      position: fixed;
      z-index: 1030;
      box-shadow: 1px 0 2px 1px $transparent-100;
    }
  `,
  bodyBackground: css`
    background-color: $white;
  `,
});

const dict = createDict({
  menu: 'Menu',
});

function MenuWrapper({
  children,
  selectedMembershipId,
  activeChecklistResultId,
}: Props): React$Node {
  const media = useMedia();
  const menu = useMenu();
  const expanded = useToggleState(menu.expanded);

  const ref = useRef();
  const scrollPos = useRef(0);

  useEffect(() => {
    const unsubscribe = menu.subscribe((value) => {
      expanded.set(value);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    // Restore scroll position on reopen
    const scrollTo = () => {
      ref.current.scrollTo({ y: scrollPos.current, animated: false });
    };

    // Workaround for https://github.com/facebook/react-native/issues/24531
    if (Platform.OS === 'ios') {
      setTimeout(scrollTo, 1);
    } else {
      scrollTo();
    }
  }, [expanded.on]);

  const keyForScrollReset = `${selectedMembershipId.value || ''}.${
    activeChecklistResultId.value || ''
  }`;

  return media.up('xxl') ? (
    <>
      {expanded.on && (
        <View key={keyForScrollReset}>
          <View style={styles.drawer} />
          <View style={[styles.drawer, styles.body, styles.bodyBackground]}>
            {Platform.OS === 'web' ? (
              <Scrollbars>
                <View style={styles.content}>{children}</View>
              </Scrollbars>
            ) : (
              <Scrollbars contentContainerStyle={styles.container}>
                <View style={styles.content}>{children}</View>
              </Scrollbars>
            )}
          </View>
        </View>
      )}
    </>
  ) : (
    <Offcanvas
      placement="start"
      visible={expanded.on}
      onToggle={() => {
        menu.setExpanded((value) => !value);
      }}
      dialogStyle={styles.container}
    >
      <Offcanvas.Header style={styles.header}>
        <Offcanvas.Title>
          <Trans {...dict('menu')} />
        </Offcanvas.Title>
        <CloseButton
          onPress={() => {
            menu.setExpanded(false);
          }}
        />
      </Offcanvas.Header>
      <Offcanvas.Body
        // $FlowFixMe[incompatible-type]
        ref={ref}
        contentContainerStyle={styles.container}
        bounces={false}
        onScroll={(event) => {
          scrollPos.current = event.nativeEvent.contentOffset.y;
        }}
        scrollEventThrottle={100}
        key={keyForScrollReset}
      >
        {children}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default MenuWrapper;
