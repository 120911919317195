// @flow
import React from 'react';
import { Platform } from 'react-native';
import { StyleSheet, css } from 'libs/ui';
import { ScrollView, Text } from 'components/core';

type Props = {
  value: React$Node,
  spacing: boolean,
};

const styles = StyleSheet.create({
  code: css`
    background-color: $gray-100;
  `,
  codeText: css`
    font-family: $font-family-code;
    font-size: $code-font-size;
    color: $pre-color;
  `,
  spacing: css`
    margin-bottom: 1rem;
  `,
});

function Code({ value, spacing }: Props): React$Node {
  const role = Platform.OS === 'web' ? 'code' : null;

  return (
    <ScrollView bounces={false} horizontal style={[styles.code, spacing && styles.spacing]}>
      <Text role={role} style={styles.codeText}>
        {value}
      </Text>
    </ScrollView>
  );
}

export default Code;
