// @flow
import React from 'react';
import { Image } from 'components/core';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  avatar: ?Entity<GQLUserAvatar>,
  size?: 'sm' | 'md' | 'lg',
  style?: ?Object,
};

const styles = StyleSheet.create({
  avatar: css`
    background-color: $transparent-200;
  `,
  avatarDefault: css`
    border-radius: 16px;
    width: 32px;
    height: 32px;
  `,
  avatarMd: css`
    border-radius: 12px;
    width: 24px;
    height: 24px;
  `,
  avatarSm: css`
    border-radius: 8px;
    width: 16px;
    height: 16px;
  `,
  avatarLg: css`
    border-radius: 20px;
    width: 40px;
    height: 40px;
  `,
});

function Avatar({ avatar, size, style, ...elementProps }: Props): React$Node {
  const url =
    avatar && avatar.file && avatar.file.info.image
      ? `/files/${avatar.file.id}/${avatar.file.name}`
      : '/img/avatars/default.png';

  return (
    <Image
      {...elementProps}
      source={url}
      resizeMode="cover"
      label="Avatar"
      style={[
        styles.avatar,
        !size && styles.avatarDefault,
        size === 'md' && styles.avatarMd,
        size === 'sm' && styles.avatarSm,
        size === 'lg' && styles.avatarLg,
        style,
      ]}
    />
  );
}

export default Avatar;
