// @flow
import React from 'react';
import { View } from 'bootstrap-rn';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  style?: any,
};

const styles = StyleSheet.create({
  header: css`
    flex-direction: row;
    padding: 0.25rem 0.5rem;
    border-radius: 0.125rem;
    border-bottom-width: 1px;
    border-bottom-color: $gray-300;
    justify-content: space-between;
  `,
});

const NavbarHeader = React.forwardRef<Props, any>(({ style, ...props }, ref) => {
  return <View {...props} ref={ref} style={[styles.header, style]} />;
});

export default (NavbarHeader: React$AbstractComponent<Props, any>);
