// @flow
import React from 'react';
import { View } from 'components/core';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  children: React$Node,
  style?: any,
};

const styles = StyleSheet.create({
  wrapper: css`
    @include media-breakpoint-up(lg) {
      flex-grow: 1;
      width: 50%;
    }
  `,
  card: css`
    border-color: $gray-300;
    padding: 1rem;

    @include media-breakpoint-down(md) {
      border-bottom-width: 1px;
    }

    @include media-breakpoint-up(lg) {
      flex-grow: 1;
      border-right-width: 1px;
    }
  `,
});

function QuizCardFront({ children, style, ...elementProps }: Props): React$Node {
  return (
    <View style={styles.wrapper}>
      <View {...elementProps} style={[styles.card, style]}>
        {children}
      </View>
    </View>
  );
}

export default QuizCardFront;
