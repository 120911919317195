// @flow
import React from 'react';
import { View, Image, Text, Grid, Button } from 'components/core';
import { BrandHeading } from 'components/pages';
import { StyleSheet, css, useMedia } from 'libs/ui';

const styles = StyleSheet.create({
  background: css`
    padding-bottom: 4rem;
    margin-bottom: -5rem;
  `,
  appStoreBadge: css`
    width: 135px;
    height: 40px;
  `,
  imageDesktop: css`
    margin-top: 3rem;
    height: 224px;
    width: 500px;
  `,
  imageMobile: css`
    height: 125px;
    width: 280px;
    margin-bottom: 2rem;
  `,
  action: css`
    flex-shrink: 1;
    align-items: flex-start;
  `,
});

function StBKSlogan(): React$Node {
  const media = useMedia();

  return (
    <>
      <View style={styles.background}>
        <View styleName="m-md-3 mx-3">
          <Grid styleName="jc-center ai-center">
            <Grid.Box size={12} sizeMd={6} styleName="ai-md-end jc-md-end ai-center jc-center">
              <Image
                source="/img/home/devices-stbk.png"
                label="background"
                style={media.up('md') ? styles.imageDesktop : styles.imageMobile}
              />
            </Grid.Box>
            <Grid.Box size={12} sizeMd={6} styleName="ai-center jc-center ai-md-start jc-md-start">
              <BrandHeading size={2}>
                <Text>Lernen wann und wo du willst, am Schreibtisch und mobil.</Text>
              </BrandHeading>
              <View style={styles.action}>
                <View styleName="flex-row flex-wrap mt-4">
                  <Button
                    to="https://play.google.com/store/apps/details?id=de.azubinet.client"
                    external
                    styleName="p-0 mr-2"
                    color="light"
                  >
                    <Image
                      source="/img/home/google_app.png"
                      label="Google App"
                      style={styles.appStoreBadge}
                    />
                  </Button>
                  <Button
                    to="https://apps.apple.com/us/app/azubinet/id6443448057"
                    external
                    styleName="p-0 ml-2"
                    color="light"
                  >
                    <Image
                      source="/img/home/apple_app.png"
                      label="Apple App"
                      style={styles.appStoreBadge}
                    />
                  </Button>
                </View>
              </View>
            </Grid.Box>
          </Grid>
        </View>
      </View>
    </>
  );
}

export default StBKSlogan;
