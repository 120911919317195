// @flow
import React, { useMemo, useState } from 'react';
import { useDispatch, resetStore } from 'react-transporter';
import { DOMAIN_QUERY } from 'libs/foundation/queries';
import AppContext from './AppContext';

type Props = {
  client: Object,
  children: React$Node,
};

const requestBody = DOMAIN_QUERY.loc && DOMAIN_QUERY.loc.source.body;

const fetchDomain = (network) => {
  return network.fetch(requestBody).then(
    (res) => {
      return res.json().then(
        (data) => {
          if (!res.ok && data.error && data.error.message === 'Domain not valid.') {
            throw new Error('DOMAIN_NOT_FOUND');
          }

          if (!res.ok || data.errors) {
            // eslint-disable-next-line no-console
            console.error(data);

            throw new Error('REQUEST_ERROR');
          }

          return data;
        },
        () => {
          throw new Error('REQUEST_ERROR');
        },
      );
    },
    (err) => {
      // eslint-disable-next-line no-console
      console.error(err);

      throw new Error('NETWORK_ERROR');
    },
  );
};

function OptionsProvider({ client, children }: Props): React$Node {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const value = useMemo(
    () => ({
      loading,
      reset: (handle) => {
        const { network } = client.getConfig();

        setLoading(true);
        return fetchDomain(network).then(
          (domain) => {
            handle();

            dispatch(
              resetStore({
                roots: {
                  viewer: {
                    link: null,
                  },
                  viewerRunningTaskExam: {
                    link: null,
                  },
                  domain: domain.data.roots.domain,
                },
                entities: domain.data.entities,
              }),
            );

            setLoading(false);
          },
          () => {
            setLoading(false);
          },
        );
      },
    }),
    [loading],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export default OptionsProvider;
