// @flow
import React from 'react';
import { View, Image, Heading } from 'components/core';
import { LargeText } from 'components/common';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';

const styles = StyleSheet.create({
  backgroundDesktop: css`
    width: 100%;
    height: 52rem;
  `,
  backgroundMobile: css`
    width: 100%;
    height: 15rem;
  `,
  searchContainerDesktop: css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15rem;
  `,
  searchContainerMobile: css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: flex-start;
    align-items: center;
    margin-top: 3rem;
  `,
  searchViewDesktop: css`
    background-color: rgba(255, 255, 255, 0.92);
    box-shadow: 0 1px 5px 2px $transparent-200;
    padding: 2rem;
    border-radius: 0.125rem;
    width: 40rem;
    justify-content: center;
    align-items: center;
  `,
  searchViewMobile: css`
    background-color: rgba(255, 255, 255, 0.92);
    margin-horizontal: 1rem;
    border-radius: 0.125rem;
    justify-content: center;
    align-items: center;
  `,
  title: css`
    color: $gray-700;
    font-family: 'Titillium Web';
    text-align: center;
    padding-top: 0.5rem;
  `,
  textDesktop: css`
    text-align: center;
    align-items: center;
    color: $gray-700;
    padding-top: 1rem;
  `,
  image: css`
    width: 18.75rem;
    height: 4.1875rem;
  `,
});

const dict = createDict({
  title: 'learn together',
  subtitle:
    'Together we learn more successfully - your learning management system for all exams, classes und courses.',
});

function SplashScreenBox(): React$Node {
  const media = useMedia();

  return (
    <>
      {media.up('md') && (
        <Image
          source="/img/pages/bg-splash-students.jpg"
          style={styles.backgroundDesktop}
          label="Examunity"
        />
      )}
      {media.down('sm') && <View style={styles.backgroundMobile} />}
      <View style={media.up('md') ? styles.searchContainerDesktop : styles.searchContainerMobile}>
        <View style={media.up('md') ? styles.searchViewDesktop : styles.searchViewMobile}>
          <View styleName="ai-center">
            <Image source="/img/logo.png" style={styles.image} label="logo" />
          </View>
          <>
            <Heading size={4} style={styles.title}>
              <Trans {...dict('title')} />
            </Heading>
            {media.up('md') && (
              <LargeText>
                <Trans {...dict('subtitle')} style={styles.textDesktop} />
              </LargeText>
            )}
          </>
        </View>
      </View>
    </>
  );
}

export default SplashScreenBox;
