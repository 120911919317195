// @flow
import React from 'react';
import { Link } from 'components/core';

type Props = {
  course: Entity<GQLCourse>,
  announcementId: Id,
};

function LinkToCourse({ course, announcementId }: Props): React$Node {
  return (
    <Link to={`/courses/${course.slug}?announcement_id=${announcementId}`}>{course.name}</Link>
  );
}

export default LinkToCourse;
