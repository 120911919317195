// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const SEARCH_RESULT_COURSE_ACTIVITY_FRAGMENT: DocumentNode = gql`
  fragment SearchResultCourseActivityFragment on CourseActivity {
    slug
    title
    published
    version(specificVersion: "CURRENT") {
      versionNumber
      releasedAt
    }
    course {
      slug
      name
      caption
      restricted
      emailRestriction
      organizationRestriction
      isPrivate
    }
    viewerCanAccess
  }
`;
