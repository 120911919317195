// @flow
import { createAsyncContainer } from 'libs/container';

const Elements = {
  name: 'Elements',
  bundle: () => import('./Elements'),
};

const Container = createAsyncContainer(Elements, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
