// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query, selectByRoot } from 'libs/graphql';

const OrganizationLanding = {
  name: 'OrganizationLanding',
  bundle: () => import('./OrganizationLanding'),
};

const ORGANIZATION_QUERY = gql`
  query OrganizationQuery($slug: String!) {
    organization(slug: $slug) {
      name
      city
      description
      sector
      size
      website
      contactPerson
      phoneNumber
      contactEmail
      createdAt
      type
      courses {
        slug
        name
        caption
        viewerIsAdmin
        viewerIsMember
        restricted
        isPrivate
        emailRestriction
        organizationRestriction
      }
      viewerIsTrainer
      viewerIsAdmin
    }
  }
`;

const Container = createAsyncContainer(OrganizationLanding, (props) => {
  const {
    params: { slug },
  } = props;

  const variables = { slug };

  const loaders = {
    query: query(ORGANIZATION_QUERY, {
      variables,
    }),
  };

  const selectors = {
    organization: select(ORGANIZATION_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
