// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { Card as BaseCard } from 'bootstrap-rn';

type Props = {
  children: React$Node,
  style?: any,
};

const styles = StyleSheet.create({
  footer: css`
    @include media-breakpoint-down(xs) {
      border-radius: 0;
    }
  `,
});

const CardFooter = React.forwardRef<Props, any>((props, ref) => {
  const { children, style, ...elementProps } = props;

  return (
    <BaseCard.Footer {...elementProps} ref={ref} style={[styles.footer, style]}>
      {children}
    </BaseCard.Footer>
  );
});

CardFooter.displayName = 'CardFooter';

export default (CardFooter: React$AbstractComponent<Props, any>);
