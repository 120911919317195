// @flow
import React, { useEffect } from 'react';
import { Analytics } from 'libs/misc';
import { useLocation } from 'libs/routing';
import { usePdfView } from 'utils/misc';

type Props = {
  root?: boolean,
};

const getDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

function withAnalytics(
  Component: React$AbstractComponent<Props, mixed>,
): React$AbstractComponent<Props, mixed> {
  function WithAnalytics({ root, ...props }: Props) {
    const viewPdf = usePdfView();
    const location = useLocation();

    useEffect(() => {
      // Only track pageview if component is child of Route component. For this
      // we assume that only these child components have a root prop that
      // is defined in Routes.js.
      if (viewPdf || !root) {
        return;
      }

      const path = location.pathname + location.search;

      Analytics.pageview({
        page_path: path,
      });
    }, []);

    return <Component {...props} />;
  }

  WithAnalytics.displayName = `WithAnalytics(${getDisplayName(Component)})`;

  return WithAnalytics;
}

export default withAnalytics;
