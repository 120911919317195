// @flow
import React from 'react';
import { Modal, Text, Image, View, CloseButton } from 'components/core';
import { StyleSheet, css, useMedia } from 'libs/ui';

type Props = {
  modal: ToggleState,
};

const styles = StyleSheet.create({
  imageDesktop: css`
    height: 800px;
    width: 566px;
  `,
  imageMobile: css`
    height: 400px;
    width: 283px;
  `,
});

function EFREModal({ modal }: Props): React$Node {
  const media = useMedia();

  return (
    <Modal visible={modal.on} onToggle={modal.toggle} size="lg">
      <Modal.Header styleName="flex-row">
        <Modal.Title>
          <Text>EFRE Digital Creativity</Text>
        </Modal.Title>
        <CloseButton onPress={modal.toggle} />
      </Modal.Header>
      <Modal.Body>
        <Text>
          Das Vorhaben der Examunity UG (haftungsbeschränkt) &bdquo;Entwicklung einer nativen App,
          einer Marktplatz-Lösung, von Organisations-Tools und Skalierung der Plattform&rdquo; wird
          im Rahmen des Programms &bdquo;Digital Creativity&rdquo; finanziell durch die Europäische
          Union und das Land Sachsen-Anhalt unterstützt.
        </Text>
        <View styleName="ai-center jc-center mt-3">
          <Image
            source="/img/home/EFRE-Digital-Creativity.png"
            label="Messebeteiligung"
            style={media.up('md') ? styles.imageDesktop : styles.imageMobile}
          />
        </View>
      </Modal.Body>
    </Modal>
  );
}

export default EFREModal;
