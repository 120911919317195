// @flow
import { createAsyncContainer } from 'libs/container';

const Special = {
  name: 'Special',
  bundle: () => import('./Special'),
};

const Container = createAsyncContainer(Special, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
