// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { View } from 'bootstrap-rn';

type Props = {
  children: React$Node,
  fluid?: boolean,
  style?: any,
};

const styles = StyleSheet.create({
  jumbotron: css`
    $jumbotron-padding: 2rem;
    $jumbotron-color: null;
    $jumbotron-bg: $gray-200;

    padding-horizontal: $jumbotron-padding;
    padding-vertical: $jumbotron-padding * 0.5;
    margin-bottom: $jumbotron-padding;
    color: $jumbotron-color;
    background-color: $jumbotron-bg;
    border-radius: $border-radius-lg;

    @include media-breakpoint-up(sm) {
      padding-horizontal: $jumbotron-padding * 2;
      padding-vertical: $jumbotron-padding;
    }
    @include media-breakpoint-down(sm) {
      margin-horizontal: 1rem;
    }
  `,
  jumbotronFluid: css`
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
  `,
});

const Jumbotron = React.forwardRef<Props, any>((props, ref) => {
  const { children, fluid = false, style, ...elementProps } = props;

  return (
    <View
      {...elementProps}
      ref={ref}
      style={[styles.jumbotron, fluid && styles.jumbotronFluid, style]}
    >
      {children}
    </View>
  );
});

Jumbotron.displayName = 'Jumbotron';

export default (Jumbotron: React$AbstractComponent<Props, any>);
