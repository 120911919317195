// @flow
import { createAsyncContainer } from 'libs/container';

const MasteringMarkdown = {
  name: 'MasteringMarkdown',
  bundle: () => import('./MasteringMarkdown'),
};

const Container = createAsyncContainer(MasteringMarkdown, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
