// @flow
import Domain from 'libs/misc/Domain';

export default function getSource(source: string | Object): Object {
  if (typeof source !== 'string') {
    return {
      ...source,
      uri: Domain.url(source.uri),
    };
  }

  return { uri: Domain.url(source) };
}
