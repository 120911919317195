// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';
import { ANNOUNCEMENTS_FRAGMENT } from 'views/_shared/Announcements';

const Start = {
  name: 'Start',
  bundle: () => import('./Start'),
};

const ORGANIZATION_ANNOUNCEMENTS_QUERY = gql`
  query OrganizationAnnouncementsQuery($slug: String!, $announcementId: ClientID) {
    organization(slug: $slug) {
      name
      slug
      ...AnnouncementsFragment
      viewerIsAdmin
      viewerIsTrainer
      viewerIsMember
    }
  }

  ${ANNOUNCEMENTS_FRAGMENT}
`;

const Container = createAsyncContainer(Start, (props) => {
  const {
    params: { slug },
    searchParams,
  } = props;

  const variables = { slug, announcementId: searchParams.get('announcement_id') };

  const loaders = {
    query: query(ORGANIZATION_ANNOUNCEMENTS_QUERY, { variables }),
  };

  const selectors = {
    organization: select(ORGANIZATION_ANNOUNCEMENTS_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
