// @flow
import React from 'react';
import { Outlet } from 'libs/routing';
import OrganizationHeader from './OrganizationHeader';

function OrganizationLayout(): React$Node {
  return (
    <>
      <OrganizationHeader />
      <Outlet />
    </>
  );
}

export default OrganizationLayout;
