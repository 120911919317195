// @flow
import React from 'react';
import { Grid } from 'components/core';
import { StyleSheet, css } from 'libs/ui';
import FeaturesGridItem from './FeaturesGridItem';

type Props = {
  children: React$Node,
};

const styles = StyleSheet.create({
  container: css`
    margin-top: -2rem;
    margin-bottom: 2rem;
    margin-horizontal: 0;
  `,
});

function FeaturesGrid({ children }: Props): React$Node {
  return <Grid style={styles.container}>{children}</Grid>;
}

FeaturesGrid.Item = FeaturesGridItem;

export default FeaturesGrid;
