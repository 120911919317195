// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { View, Text } from 'components/core';
import { Icon } from 'components/common';

type Props = {
  variant:
    | 'true'
    | 'false'
    | 'white'
    | 'user'
    | 'correct'
    | 'wrong'
    | 'true-muted'
    | 'false-muted'
    | 'correct-muted'
    | 'wrong-muted',
  checked?: boolean,
  correct?: boolean,
  wrong?: boolean,
  last?: boolean,
  blank?: boolean,
  review?: boolean,
};

const styles = StyleSheet.create({
  container: css`
    width: 5rem;
    height: 3rem;
    justify-content: center;
    align-items: center;
  `,
  containerLast: css`
    margin-left: 1px;
  `,
  text: css`
    font-size: 1.125rem;
  `,
  textHidden: css`
    color: $gray-400;
  `,
  buttonLabel: css`
    width: 5rem;
  `,
  iconLarge: css`
    font-size: 1.425rem;
  `,
});

const defaultProps = {
  checked: false,
  last: false,
  blank: false,
  wrong: false,
  correct: false,
  review: false,
};

const getTextColor = (checked, variant, blank, correct, wrong, review) => {
  if (variant === 'true' && checked) {
    return 'success';
  }

  if (variant === 'false' && checked) {
    return 'danger';
  }

  if (variant === 'false' && blank) {
    return 'light';
  }

  if (variant === 'user' && correct) {
    return 'light';
  }

  if (variant === 'user' && wrong) {
    return 'danger';
  }

  if (variant === 'user' && review) {
    return 'primary';
  }

  if (variant === 'correct' && checked) {
    return 'success';
  }
  if (variant === 'wrong' && checked) {
    return 'danger';
  }

  return null;
};

function TaskChoiceItem({
  checked,
  last,
  variant,
  blank,
  correct,
  wrong,
  review,
}: Props): React$Node {
  const textColor = getTextColor(checked, variant, blank, correct, wrong, review);

  return (
    <View style={[styles.container, last && styles.containerLast]}>
      <Text color={textColor} style={[styles.text, !textColor && styles.textHidden]}>
        {/* Option is Correct or Wrong in Sample Solution */}

        {variant === 'true' && <Icon glyph="ok-circled2" style={styles.iconLarge} />}
        {variant === 'false' && <Icon glyph="cancel-circled2" style={styles.iconLarge} />}

        {/* Option is True/False in Sample Solution */}

        {variant === 'true-muted' && <Icon glyph="ok" />}
        {variant === 'false-muted' && <Icon glyph="cancel" />}

        {/* Blank Placeholder in Sample Solution */}

        {variant === 'white' && <Icon glyph="ok" style={{ opacity: 0 }} />}
        {variant === 'user' && <Icon glyph="user" />}

        {/* User has chosen the correct/wrong answer/option */}

        {variant === 'correct' && (
          <>
            <Icon glyph="ok-circled2" style={styles.iconLarge} />
            <Icon glyph="user" styleName="ml-2" />
          </>
        )}
        {variant === 'wrong' && (
          <>
            <Icon glyph="cancel-circled2" style={styles.iconLarge} />
            <Icon glyph="user" styleName="ml-2" />
          </>
        )}

        {/* User has chosen a wrong answer/option */}

        {variant === 'correct-muted' && (
          <>
            <Icon glyph="ok" />
            <Icon glyph="user" styleName="ml-2" />
          </>
        )}
        {variant === 'wrong-muted' && (
          <>
            <Icon glyph="cancel" />
            <Icon glyph="user" styleName="ml-2" />
          </>
        )}
      </Text>
    </View>
  );
}

TaskChoiceItem.defaultProps = defaultProps;

export default TaskChoiceItem;
