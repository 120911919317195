// @flow
import React from 'react';
import { Nav, Badge, Text } from 'components/core';
import { createDict, Trans } from 'libs/intl';
import { useParams } from 'libs/routing';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  organization: ?Entity<GQLOrganization>,
};

const styles = StyleSheet.create({
  nav: css`
    flex-direction: column;
    margin-bottom: 1rem;
  `,
});

const dict = createDict({
  info: 'Info',
  licenses: 'Licenses',
  keys: 'Keys',
  purchases: 'Purchases',
});

function ProductNav({ organization }: Props): React$Node {
  const { slug, productSlug } = useParams();

  return (
    <Nav variant="pills" style={styles.nav}>
      <Nav.Link to={`/organizations/${slug}/monetization/${productSlug}`} end>
        <Trans {...dict('info')} />
      </Nav.Link>
      <Nav.Link
        to={`/organizations/${slug}/monetization/${productSlug}/keys`}
        styleName="jc-between"
      >
        <Trans {...dict('keys')} />
        <Badge color="light">
          {organization ? (
            organization.product.offers.reduce((result, offer) => result + offer.keysCount, 0)
          ) : (
            <Text>&nbsp;</Text>
          )}
        </Badge>
      </Nav.Link>
      <Nav.Link
        to={`/organizations/${slug}/monetization/${productSlug}/purchases`}
        styleName="jc-between"
      >
        <Trans {...dict('purchases')} />
        <Badge color="light">
          {organization ? (
            organization.product.offers.reduce((result, offer) => result + offer.purchasesCount, 0)
          ) : (
            <Text>&nbsp;</Text>
          )}
        </Badge>
      </Nav.Link>
      <Nav.Link
        to={`/organizations/${slug}/monetization/${productSlug}/licenses`}
        styleName="jc-between"
      >
        <Trans {...dict('licenses')} />
        <Badge color="light">
          {organization ? organization.product.licensesCount : <Text>&nbsp;</Text>}
        </Badge>
      </Nav.Link>
    </Nav>
  );
}

export default ProductNav;
