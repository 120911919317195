// @flow
import { LESS_THAN, SLASH } from './chars';
import type { HtmlTag } from './types';

const attributeName = '[a-zA-Z_:][a-zA-Z0-9:._-]*';
const attributeValue = `(?:'([^']*)'|"([^"]*)")`;
const attribute = `\\s+(${attributeName})(?:\\s*=\\s*${attributeValue})?`;

const reAttribute = new RegExp(attribute);
const reAttributes = new RegExp(attribute, 'g');
const reOpeningTag = new RegExp(`^<([A-Za-z][A-Za-z0-9\\-]*)(${attribute})*\\s*\\/?>`);

const selfClosingTags = ['img', 'br', 'hr'];

const getAttributes = (value) => {
  const match = value.match(reAttributes);

  if (!match) {
    return null;
  }

  const attributes = {};

  // $FlowFixMe[incompatible-call]
  match.forEach((item) => {
    const result = item.match(reAttribute);

    // Key 1 is the attribute name, key 2 or 3 is the value (depends on double
    // or single quote).
    attributes[result[1]] = result[2] || result[3];
  });

  return attributes;
};

export default function matchOpeningTag(value: string, index: number): ?HtmlTag {
  if (value.charAt(index) !== LESS_THAN || value.charAt(index + 1) === SLASH) {
    return null;
  }

  const match = value.slice(index).match(reOpeningTag);

  if (!match) {
    return null;
  }

  const name = match[1];
  const hasClosingSlash = value.charAt(index + match[0].length - 2) === SLASH;
  const selfClosing = selfClosingTags.includes(name);

  // Tag is self closing, but should not be self closing.
  if (hasClosingSlash && !selfClosing) {
    return null;
  }

  const opening = match[0];
  const closing = selfClosing ? undefined : `</${name}>`;
  const source = [opening, closing];
  const attributes = getAttributes(match[0]);

  return { name, source, selfClosing, attributes };
}
