// @flow
import React from 'react';
import { Grid } from 'components/core';
import { StyleSheet, css } from 'libs/ui';
import { BrandHeading } from 'components/pages';
import { createDict, Trans } from 'libs/intl';
import FeaturedProfession from './FeaturedProfession';

const styles = StyleSheet.create({
  box: css`
    margin-bottom: $spacer5;
  `,
  heading: css`
    text-align: center;
    padding-top: $spacer5;
    padding-bottom: $spacer5;
  `,
  genderHintText: css`
    text-align: center;
  `,
  button: css`
    height: 3.625rem;
  `,
});

const dict = createDict({
  popular_professions: 'Popular professions',
  popular_courses: 'Popular courses',
  all_courses: 'All courses',
});

function FavoritesSection(): React$Node {
  return (
    <Grid styleName="px-3 px-md-0" style={styles.box}>
      <Grid.Box size={12} sizeMd={12}>
        <BrandHeading size={1} style={styles.heading}>
          <Trans {...dict('popular_professions')} />
        </BrandHeading>
      </Grid.Box>
      <Grid.Box size={12} sizeMd={6}>
        <FeaturedProfession
          to="/professions/automobilkauffrau-automobilkaufmann"
          text="Automobilkaufleute"
        />
        <FeaturedProfession to="/professions/drogistin-drogist" text="Drogist/-innen" />
        <FeaturedProfession
          to="/professions/fachinformatikerin-fachinformatiker"
          text="Fachinformatiker/-innen"
        />
        <FeaturedProfession
          to="/professions/fachkraft-fuer-lagerlogistik"
          text="Fachkräfte für Lagerlogistik"
        />
        <FeaturedProfession
          to="/professions/fachlageristin-fachlagerist"
          text="Fachlagerist/-innen"
        />
        <FeaturedProfession to="/professions/floristin-florist" text="Florist/-innen" />
        <FeaturedProfession to="/professions/hotelfachfrau-hotelfachmann" text="Hotelfachleute" />
        <FeaturedProfession
          to="/professions/industriekauffrau-industriekaufmann"
          text="Industriekaufleute"
        />
        <FeaturedProfession
          to="/professions/kauffrau-kaufmann-fuer-bueromanagement"
          text="Kaufleute für Büromanagement"
        />
        <FeaturedProfession
          to="/professions/kauffrau-kaufmann-fuer-digitalisierungsmanagement"
          text="Kaufleute für Digitalisierungsmanagement"
        />
        <FeaturedProfession
          to="/professions/kauffrau-kaufmann-fuer-gross-und-aussenhandelsmanagement"
          text="Kaufleute für Groß- und Außenhandelsmanagement"
        />
      </Grid.Box>
      <Grid.Box size={12} sizeMd={6}>
        <FeaturedProfession
          to="/professions/kauffrau-kaufmann-fuer-spedition-und-logistikdienstleistung"
          text="Kaufleute für Spedition und Logistikdienstleistung"
        />
        <FeaturedProfession
          to="/professions/kauffrau-kaufmann-im-e-commerce"
          text="Kaufleute im E-Commerce"
        />
        <FeaturedProfession
          to="/professions/kauffrau-kaufmann-im-einzelhandel"
          text="Kaufleute im Einzelhandel"
        />
        <FeaturedProfession
          to="/professions/kauffrau-kaufmann-im-gesundheitswesen"
          text="Kaufleute im Gesundheitswesen"
        />
        <FeaturedProfession to="/professions/koechin-koch" text="Köche" />
        <FeaturedProfession
          to="/professions/medizinische-fachangestellte-medizinischer-fachangestellter"
          text="Medizinische Fachangestellte"
        />
        <FeaturedProfession
          to="/professions/personaldienstleistungskauffrau-personaldienstleistungskaufmann"
          text="Personaldienstleistungskaufleute"
        />
        <FeaturedProfession
          to="/professions/restaurantfachfrau-restaurantfachmann"
          text="Restaurantfachleute"
        />
        <FeaturedProfession
          to="/professions/steuerfachangestellte-steuerfachangestellter"
          text="Steuerfachangestellte"
        />
        <FeaturedProfession to="/professions/verkaeuferin-verkaeufer" text="Verkäufer/-innen" />
        <FeaturedProfession to="/professions" text="Alle Berufe" />
      </Grid.Box>
    </Grid>
  );
}

export default FavoritesSection;
