// @flow
import React from 'react';
import { Platform } from 'react-native';
import { Dropdown } from 'bootstrap-rn';
import { StyleSheet, css, useMedia } from 'libs/ui';
import NavbarMenuHeader from './NavbarMenuHeader';
import NavbarMenuContent from './NavbarMenuContent';
import NavbarMenuFooter from './NavbarMenuFooter';

type Props = {
  style?: any,
  offset: number,
  offsetXs: number,
};

const styles = StyleSheet.create({
  menu: css`
    margin-top: -0.125rem;
    padding: 0;
    width: 368px;
    height: 354px;

    @include platform(web) {
      margin-top: 0;
      top: 48px;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    }

    @include media-breakpoint-down(xs) {
      width: 304px;
      left: auto;
      right: 0.5rem;
    }
  `,
});

const NavbarMenu = React.forwardRef<Props, any>(({ style, offset, offsetXs, ...props }, ref) => {
  const display = Platform.OS === 'web' ? 'static' : 'dynamic';
  const media = useMedia();

  return (
    <Dropdown.Menu
      {...props}
      ref={ref}
      style={[
        styles.menu,
        display === 'static' && media.up('sm') && { marginRight: -offset },
        display === 'static' && media.down('xs') && { marginRight: -offsetXs },
        display === 'dynamic' && media.up('sm') && { marginLeft: offset },
        style,
      ]}
      end
    />
  );
});

// $FlowFixMe[prop-missing]
NavbarMenu.Header = NavbarMenuHeader;
// $FlowFixMe[prop-missing]
NavbarMenu.Content = NavbarMenuContent;
// $FlowFixMe[prop-missing]
NavbarMenu.Footer = NavbarMenuFooter;

export default (NavbarMenu: any);
