// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const OrganizationSettings = {
  name: 'OrganizationSettings',
  bundle: () => import('./OrganizationSettings'),
};

const ORGANIZATION_SETTINGS_QUERY = gql`
  query OrganizationSettingsQuery($slug: String!) {
    organization(slug: $slug) {
      name
      city
      description
      emailDomain
      sector
      size
      website
      contactPerson
      phoneNumber
      contactEmail
      type
      memberships {
        user {
          name
          avatar {
            file {
              name
              extension
              info {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
      viewerIsAdmin
      viewerIsTrainer
    }
  }
`;

const Container = createAsyncContainer(OrganizationSettings, (props) => {
  const {
    params: { slug },
  } = props;

  const variables = { slug };

  const loaders = {
    query: query(ORGANIZATION_SETTINGS_QUERY, { variables }),
  };

  const selectors = {
    organization: select(ORGANIZATION_SETTINGS_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
