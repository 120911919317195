// @flow
import React from 'react';
import { Domain } from 'libs/misc';
import StbkCoursesModal from './StbkCoursesModal';
import AzubinetBundlesModal from './AzubinetBundlesModal';
import VogelspotCoursesModal from './VogelspotCoursesModal';

type Props = {
  modal: ToggleState,
  viewer: Entity<GQLUser>,
  onClose?: () => void,
};

function FindCoursesModal({ modal, viewer, onClose: handleClose }: Props): React$Node {
  const CoursesModal = Domain.select({
    'stbk.azubinet.de': StbkCoursesModal,
    'www.azubinet.de': viewer.isOrganizationAdminOrTrainer ? null : AzubinetBundlesModal,
    '*.azubinet.de': null,
    'www.vogelspot.de': VogelspotCoursesModal,
    '*': null,
  });

  if (!CoursesModal) {
    return null;
  }

  return <CoursesModal modal={modal} viewer={viewer} onClose={handleClose} />;
}

export default FindCoursesModal;
