// @flow
import React from 'react';
import { useStack } from 'react-stacks';
import { Toast } from 'components/stacks';

type ToastProps = {
  title?: ?string,
  content: React$Node,
  autoDismiss?: number,
};

export default function useToasts(): Object {
  const stack = useStack('toasts');

  return {
    add: ({ autoDismiss, ...toastProps }: ToastProps) => {
      function ToastWrapper(props) {
        return <Toast {...toastProps} {...props} />;
      }

      stack.push(ToastWrapper, { autoDismiss });
    },
  };
}
