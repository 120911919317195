// @flow
import React, { useId, useState } from 'react';
// $FlowFixMe[missing-export]
import { Platform, unstable_createElement as createElement } from 'react-native';
import * as DocumentPicker from 'expo-document-picker';
import { Input } from 'bootstrap-rn';
import { Pressable, View } from 'components/core';
import { StyleSheet, css } from 'libs/ui';
import useFormField from './useFormField';
import Field from './Field';

type Props = {
  name: string,
  niceName?: Intlized$Message | string | null,
  title?: Intlized$Message | string | null,
  info?: Intlized$Message | string | null,
  disabled?: boolean,
  onValueChange?: (any) => void,
  label: Intlized$Message | string,
  multiple?: boolean,
  accept?: string | string[],
};

const styles = StyleSheet.create({
  container: css`
    pointer-events: none;
  `,
});

const FormFileInput = React.forwardRef<Props, any>((props, ref) => {
  const {
    name,
    niceName,
    title,
    info,
    // disabled = false,
    onValueChange,
    label,
    // multiple = false,
    accept,
    ...elementProps
  } = props;

  const [loading, setLoading] = useState(false);
  const field = useFormField(name, niceName, title);
  const id = useId();

  return (
    <Field
      title={title}
      error={field.error}
      touched={field.touched}
      info={info}
      labelID={id}
      elementProps={elementProps}
    >
      {Platform.OS === 'web' &&
        createElement('input', {
          type: 'file',
          accept: Array.isArray(accept) ? accept.join(',') : accept,
          onChange(event) {
            const nextValue = event.target.files?.[0] || null;
            field.setValue(nextValue, onValueChange);
          },
          onBlur() {
            field.setTouched();
          },
          style: {
            fontSize: '1rem',
            borderRadius: 1,
            borderColor: '#dee2e6',
            borderWidth: 1,
            borderStyle: 'solid',
            backgroundColor: '#f8f9fa',
            padding: '1rem',
            lineHeight: '1.5rem',
          },
        })}
      {Platform.OS !== 'web' && (
        <Pressable
          onPress={() => {
            setLoading(true);

            DocumentPicker.getDocumentAsync({ type: accept }).then((result) => {
              if (result.type === 'cancel') {
                setLoading(false);
              } else if (result.type === 'success') {
                fetch(result.uri)
                  // $FlowFixMe[prop-missing]
                  .then((res) => res.blob())
                  .then((blob) => {
                    setLoading(false);

                    const nextValue = new File([blob], result.name);
                    field.setValue(nextValue, onValueChange);
                  });
              } else {
                setLoading(false);
              }
            });
          }}
          disabled={loading}
        >
          <View style={styles.container}>
            <Input
              {...elementProps}
              ref={ref}
              value={field.value ? field.value[0].name : ''}
              onChangeText={undefined}
              onBlur={() => {
                field.setTouched();
              }}
              readOnly
              selectTextOnFocus={false}
            />
          </View>
        </Pressable>
      )}
    </Field>
  );
});

export default (FormFileInput: React$AbstractComponent<Props, any>);
