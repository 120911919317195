// @flow
import React, { useMemo } from 'react';
import { Pressable } from 'components/core';
import { injectPopover } from 'libs/ui';
import ProfileLoader from './ProfileLoader';
import Profile from './Profile';

type Props = {
  children: React$Node,
  user: Entity<GQLUser>,
  viewer: ?Entity<GQLUser>,
  component?: React$AbstractComponent<any, any>,
};

function ProfilePopover({ user, viewer, children, component, ...elementProps }: Props): React$Node {
  const Component = useMemo(() => {
    return injectPopover(component || Pressable);
  }, []);

  // Cache profile data to prevent loading profile data twice.
  const profile = useMemo(() => ({ data: null }), []);

  return (
    <Component
      {...elementProps}
      popover={{
        content: (
          <>
            {profile.data && <Profile viewer={viewer} user={profile.data} />}
            {!profile.data && (
              <ProfileLoader userId={user.id} viewer={viewer}>
                {(data) => {
                  profile.data = data;

                  return <Profile viewer={viewer} user={data} />;
                }}
              </ProfileLoader>
            )}
          </>
        ),
        placement: 'top',
        fallbackPlacement: 'flip',
      }}
    >
      {children}
    </Component>
  );
}

export default ProfilePopover;
