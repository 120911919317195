// @flow
import React from 'react';
import { Grid } from 'components/core';
import StBKFeaturesBox from '../../_shared/StBKFeaturesBox';

function StBKFeaturesBoxContent(): React$Node {
  return (
    <Grid>
      <StBKFeaturesBox
        title="Online-Kurse"
        icon="users"
        content="In den Online-Kursen findest du dich mit anderen Auszubildenden im Betrieb, in der Berufsschule und aus ganz Niedersachsen zusammen"
        type="red"
      />
      <StBKFeaturesBox
        title="Prüfungssimulationen"
        icon="award"
        content="Die Steuerberaterkammer stellt Originalprüfungen der letzten Jahre und Musterklausuren zur Verfügung"
        type="red"
      />
      <StBKFeaturesBox
        title="Lernbox"
        icon="cards"
        content="Nutze zahlreiche Lernkarten, teilen deine eigenen Sets und lerne mit Lernkarten von anderen Auszubildenden"
        type="red"
      />
      <StBKFeaturesBox
        title="Premium-Inhalte"
        icon="videocam"
        content="Erklärvideos, hunderte nach Lernfeldern geordnete Fragen für das erste Lehrjahr und vieles mehr"
        type="red"
      />
      <StBKFeaturesBox
        title="Austausch"
        icon="chat"
        content="Erarbeite und vergleiche Lösungen, tausche Materialien aus und lade Inhalte herunter"
        type="red"
      />
      <StBKFeaturesBox
        title="Für Lehrkräfte"
        icon="graduation-cap"
        content="Verwenden Sie Materialien für Ihren Unterricht, tauschen Sie Inhalte mit anderen Lehrkräften aus und nutzen Sie die Plattform unterrichtsbegleitend"
        type="red"
      />
    </Grid>
  );
}

export default StBKFeaturesBoxContent;
