// @flow
import { createAsyncContainer } from 'libs/container';

const IHKPruefungsfit = {
  name: 'IHKPruefungsfit',
  bundle: () => import('./IHKPruefungsfit'),
};

const Container = createAsyncContainer(IHKPruefungsfit, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
