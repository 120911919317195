// @flow
import React from 'react';
import { Platform } from 'react-native';
import { Grid } from 'components/core';

type Props = {
  children: React$Node,
  size?: number,
};

function PageAside({ children, size = 4, ...elementProps }: Props): React$Node {
  const role = Platform.OS === 'web' ? 'complementary' : null;

  return (
    <Grid.Box {...elementProps} role={role} size={12} sizeMd={size}>
      {children}
    </Grid.Box>
  );
}

export default PageAside;
