// @flow
import React from 'react';
import { Nav } from 'bootstrap-rn';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  style?: any,
};

const styles = StyleSheet.create({
  link: css`
    margin-vertical: -0.5rem;
    min-width: 4rem;
    height: 3.5rem;
    align-items: center;
    justify-content: center;
    padding: 0;

    &:hover {
      background-color: $transparent-200;
    }
    &:focus {
      background-color: $transparent-200;
    }
  `,
});

const NavbarLink = React.forwardRef<Props, any>(({ style, ...props }, ref) => {
  return <Nav.Link {...props} ref={ref} style={[styles.link, style]} />;
});

export default (NavbarLink: React$AbstractComponent<Props, any>);
