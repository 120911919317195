// @flow
import React from 'react';
import { Alert } from 'components/core';
import { Icon } from 'components/common';
import { Markdown } from 'components/markdown';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  explanation: ?string,
};

const styles = StyleSheet.create({
  explanationText: css`
    color: $body-color;
    font-size: $small-font-size;
  `,
});

function SolutionExplanation({ explanation }: Props): React$Node {
  if (!explanation) {
    return null;
  }

  return (
    <Alert color="warning" styleName="flex-row flex-shrink-1 flex-grow-1">
      <Icon glyph="lightbulb" small styleName="mr-3" />
      <Markdown textStyle={styles.explanationText} source={explanation} />
    </Alert>
  );
}

export default SolutionExplanation;
