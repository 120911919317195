// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const DomainSEO = {
  name: 'DomainSEO',
  bundle: () => import('./DomainSEO'),
};

const DOMAIN_SEO_QUERY = gql`
  query DomainSeoQuery($slug: String!) {
    organization(slug: $slug) {
      ownsDomain
      domain {
        googleAnalyticsId
        seo {
          title
          description
        }
      }
      viewerIsAdmin
    }
  }
`;

const Container = createAsyncContainer(DomainSEO, (props) => {
  const {
    params: { slug },
  } = props;

  const variables = { slug };

  const loaders = {
    query: query(DOMAIN_SEO_QUERY, { variables }),
  };

  const selectors = {
    organization: select(DOMAIN_SEO_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
