// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { Heading as BaseHeading } from 'components/core';

type Props = {
  depth: number,
  children: React$Node,
  spacing: boolean,
};

const styles = StyleSheet.create({
  heading: css`
    font-family: Roboto;
    font-weight: 600;
  `,
  h1: css`
    font-size: 1.375rem;
  `,
  h2: css`
    font-size: 1.25rem;
  `,
  h3: css`
    font-size: 1.125rem;
  `,
  h4: css`
    font-size: 1rem;
  `,
  h5: css`
    font-size: 0.875rem;
  `,
  h6: css`
    font-size: 0.875rem;
    color: $text-muted;
  `,
  spacing: css`
    margin-bottom: 1rem;
  `,
});

function Heading({ depth, children, spacing }: Props): React$Node {
  return (
    <BaseHeading
      size={depth}
      style={[styles.heading, styles[`h${depth}`], spacing && styles.spacing]}
    >
      {children}
    </BaseHeading>
  );
}

export default Heading;
