// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { View } from 'components/core';

type Props = {
  style?: any,
};

const styles = StyleSheet.create({
  divider: css`
    margin-top: 1rem;
    height: 1px;
    background-color: $gray-300;
    margin-bottom: 1rem;
  `,
});

const Divider = React.forwardRef<Props, any>((props, ref) => {
  const { style, ...elementProps } = props;

  return <View {...elementProps} ref={ref} style={[styles.divider, style]} />;
});

export default (Divider: React$AbstractComponent<Props, any>);
