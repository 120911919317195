// @flow
import { createAsyncContainer } from 'libs/container';
import { select, query, gql } from 'libs/graphql';

const TaskExam = {
  name: 'RunningTaskExam',
  bundle: () => import('./RunningTaskExam'),
};

const VIEWER_RUNNING_TASK_EXAM_QUERY = gql`
  query ViewerRunningExamQuery {
    viewerRunningTaskExam {
      course {
        slug
      }
      viewerRunningAttempt {
        expiredAt
      }
    }
  }
`;

const options = {
  async: {
    loading: () => null,
    error: () => null,
  },
};

const Container = createAsyncContainer(
  TaskExam,
  () => {
    const loaders = {
      query: query(VIEWER_RUNNING_TASK_EXAM_QUERY),
    };

    const selectors = {
      viewerRunningTaskExam: select(VIEWER_RUNNING_TASK_EXAM_QUERY),
    };

    return {
      loaders,
      selectors,
    };
  },
  options,
);

export default (Container: React$AbstractComponent<any, mixed>);
