// @flow
import React from 'react';
import { Text } from 'components/core';
import { useIntl } from 'intlized-components';

type Props = {
  id: string,
  defaultMessage?: string,
  variables?: ?Object | Function,
};

function Trans({ id, defaultMessage, variables, ...textProps }: Props): React$Node {
  const intl = useIntl();

  return <Text {...textProps}>{intl.trans({ id, defaultMessage, variables })}</Text>;
}

export default Trans;
