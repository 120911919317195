// @flow
import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { StyleSheet, css } from 'libs/ui';
import { Icon, OptionButton } from 'components/common';
import { Text, View, Nav, Badge } from 'components/core';
import { useMenu } from 'libs/foundation';

type Props = {
  viewer: Entity<GQLUser>,
  expanded: ToggleState,
};

const styles = StyleSheet.create({
  indented: css`
    padding-horizontal: 0.75rem;
  `,
  dirIcon: css`
    width: 1rem;
    align-items: center;
  `,
});

const dict = createDict({
  teams: 'Teams',
});

function MenuTeams({ viewer, expanded }: Props): React$Node {
  const { teamMemberships } = viewer;

  const menu = useMenu();

  if (teamMemberships.length === 0) {
    return null;
  }

  return (
    <>
      <OptionButton onPress={expanded.toggle} styleName="jc-start mt-3">
        <View style={styles.dirIcon}>
          {expanded.on ? <Icon glyph="down-dir" /> : <Icon glyph="right-dir" />}
        </View>
        <Trans {...dict('teams')} bold styleName="ml-2" />
        <Badge color="light" styleName="ml-1">
          <Text>{teamMemberships.length}</Text>
        </Badge>
      </OptionButton>
      {expanded.on && (
        <Nav variant="pills" styleName="flex-column mb-3">
          {teamMemberships
            .sort((left, right) => {
              const titleLeft = left.team.name.toLowerCase();
              const titleRight = right.team.name.toLowerCase();
              if (titleLeft < titleRight) {
                return -1;
              }
              if (titleLeft > titleRight) {
                return 1;
              }
              return 0;
            })
            .map(({ team }) => (
              <Nav.Link
                to={`/organizations/${team.organization.slug}/teams/${team.slug}`}
                onPress={() => {
                  menu.clear();
                }}
                style={styles.indented}
                key={team.id}
              >
                <View styleName="flex-grow-1 flex-shrink-1">
                  <Text numberOfLines={1}>{team.name}</Text>
                  <Text color="muted" small numberOfLines={1}>
                    {team.organization.name}
                  </Text>
                </View>
              </Nav.Link>
            ))}
        </Nav>
      )}
    </>
  );
}

export default MenuTeams;
