// @flow
import React from 'react';
import { Image, Pressable } from 'components/core';

type Props = {
  domain: Entity<GQLDomain>,
};

function BrandLogo({ domain }: Props): React$Node {
  return (
    <Pressable to="/">
      {domain.logo && domain.logo.info.image && (
        <Image
          source={`/files/${domain.logo.id}/${domain.logo.name}`}
          resizeMode="contain"
          style={{
            height: domain.logo.info.image.height,
            width: domain.logo.info.image.width,
          }}
        />
      )}
      {!domain.logo && <Image source="/img/azubinet.png" style={{ height: 43, width: 253 }} />}
    </Pressable>
  );
}

export default BrandLogo;
