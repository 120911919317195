// @flow
import React, { useState } from 'react';
import { Input } from 'bootstrap-rn';
import { createDict, Trans, useIntl } from 'libs/intl';
import { View, Text, Button } from 'components/core';
import { Well } from 'components/common';
import FormChoice from './FormChoice';
import useFormField from './useFormField';

type SearchableOption = {
  value: any,
  label: any,
  searchTerm: string,
  disabled?: boolean,
};

type Props = {
  name: string,
  niceName?: Intlized$Message | string | null,
  title?: Intlized$Message | string | null,
  disabled?: boolean,
  placeholder?: ?(Intlized$Message | string),
  options: Array<SearchableOption>,
  controls?: boolean,
};

const dict = createDict({
  show_all: 'Show all',
  show_selected: 'Show selected',
  no_results_found: 'No results found',
});

const coerceOptions = (options) => options.map(({ searchTerm, ...option }) => option);

const filterOptions = (options, searchTerm) => {
  if (!searchTerm) {
    return coerceOptions(options);
  }

  const regex = new RegExp(searchTerm, 'i');

  return coerceOptions(
    options.filter((option) => {
      return option.searchTerm.search(regex) >= 0;
    }),
  );
};

const FormSearchableList = React.forwardRef<Props, any>((props, ref) => {
  const { name, niceName, title, placeholder, disabled, options, controls, ...elementProps } =
    props;
  const [activeOptions, setActiveOptions] = useState(() => coerceOptions(options));

  const field = useFormField(name, niceName, title);
  const intl = useIntl();

  return (
    <View {...elementProps} ref={ref}>
      {title && (
        <Text small styleName="fw-bold">
          {typeof title === 'object' ? <Trans {...title} /> : title}
        </Text>
      )}
      <Input
        placeholder={
          placeholder && typeof placeholder === 'object' ? intl.trans(placeholder) : placeholder
        }
        onChangeText={(searchTerm) => {
          setActiveOptions(filterOptions(options, searchTerm));
        }}
        autoComplete="off"
        disabled={disabled}
        styleName="mb-2"
      />
      {controls && (
        <View styleName="flex-row jc-end ai-center">
          <Button
            onPress={() => {
              setActiveOptions(filterOptions(options));
            }}
            color="link"
            styleName="mr-2"
          >
            <Trans {...dict('show_all')} />
          </Button>
          <Button
            onPress={() => {
              const selectedOptionsOnly = options.filter((option) => {
                return field.value?.indexOf(option.value) >= 0;
              });

              setActiveOptions(filterOptions(selectedOptionsOnly));
            }}
            color="link"
          >
            <Text>
              <Trans {...dict('show_selected')} /> ({field.value?.length || 0})
            </Text>
          </Button>
        </View>
      )}
      <FormChoice
        {...elementProps}
        name={name}
        niceName={niceName}
        options={activeOptions}
        disabled={disabled}
        multiple
      />
      {activeOptions.length === 0 && (
        <Well styleName="mt-n3 mb-3">
          <Trans color="muted" {...dict('no_results_found')} />
        </Well>
      )}
    </View>
  );
});

export default (FormSearchableList: React$AbstractComponent<Props, any>);
