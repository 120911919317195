// @flow
import React from 'react';
import { NavbarMenu } from 'components/navbar';
import { Loading } from 'components/common';
import Caret from '../Caret';

function NotificationListLoading(): React$Node {
  return (
    <NavbarMenu offset={16} offsetXs={64}>
      <Caret position={46} positionXs={86} />
      <NavbarMenu.Content>
        <Loading />
      </NavbarMenu.Content>
    </NavbarMenu>
  );
}

export default NotificationListLoading;
