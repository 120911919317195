// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const OrganizationChecklists = {
  name: 'OrganizationChecklists',
  bundle: () => import('./OrganizationChecklists'),
};

const ORGANIZATION_QUERY = gql`
  query OrganizationQuery($slug: String!) {
    organization(slug: $slug) {
      name
      slug
      viewerIsMember
      relatedChecklists {
        organization {
          id
        }
        name
        slug
        isPrivate
        todosCount
      }
    }
  }
`;

const Container = createAsyncContainer(OrganizationChecklists, (props) => {
  const {
    params: { slug },
  } = props;

  const variables = { slug };

  const loaders = {
    query: query(ORGANIZATION_QUERY, { variables }),
  };

  const selectors = {
    organization: select(ORGANIZATION_QUERY, { variables }),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
