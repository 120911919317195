// @flow
import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { Page } from 'components/layout';
import { View, Heading, Link, Button } from 'components/core';
import { Domain } from 'libs/misc';

type Props = {
  code?: ?ErrorType,
  viewer?: ?Entity<GQLUser>,
};

const dict = createDict({
  domain_not_found: 'Domain not found',
  domain_not_found_text: 'We are sorry, but this domain does not exist.',
  page_not_found: 'Page not found',
  page_not_found_text: 'We are sorry, but this page does not exist.',
  request_error: 'Request Error',
  request_error_text: 'We are sorry, but an unexpected error occured.',
  network_error: 'Network Error',
  network_error_text:
    'We were not able to load the page. Do you have a working internet connection?',
  unauthenticated: 'Unauthenticated',
  unauthenticated_text: 'You need to log in in order to view this page.',
  forbidden: 'Forbidden',
  forbidden_text: 'You do not have access to this page.',
  whooops_something_went_wrong: 'Whooops. Something went wrong.',
  whooops_something_went_wrong_text: 'We are sorry, but an unexpected error occured.',
  discover_examunity: 'Discover Examunity',
  discover_azubinet: 'Discover Azubinet',
  login: 'Login',
  this_page_might_be_accessible_to_users_who_are_logged_in:
    'This page might be accessible to users who are logged in.',
});

const getDictTitleKey = (code, viewer) => {
  switch (code) {
    case 'DOMAIN_NOT_FOUND':
      return 'domain_not_found';
    case 'PAGE_NOT_FOUND':
      return 'page_not_found';
    case 'REQUEST_ERROR':
      return 'request_error';
    case 'NETWORK_ERROR':
      return 'network_error';
    case 'UNAUTHENTICATED':
      return 'unauthenticated';
    // Transform forbidden error in unauthenticated error if there is no viewer.
    case 'FORBIDDEN':
      return viewer ? 'forbidden' : 'unauthenticated';
    default:
      return 'whooops_something_went_wrong';
  }
};

const getDictTextKey = (code, viewer) => {
  switch (code) {
    case 'DOMAIN_NOT_FOUND':
      return 'domain_not_found_text';
    case 'PAGE_NOT_FOUND':
      return 'page_not_found_text';
    case 'REQUEST_ERROR':
      return 'request_error_text';
    case 'NETWORK_ERROR':
      return 'network_error_text';
    case 'UNAUTHENTICATED':
      return 'unauthenticated_text';
    // Transform forbidden error in unauthenticated error if there is no viewer.
    case 'FORBIDDEN':
      return viewer ? 'forbidden_text' : 'unauthenticated_text';
    default:
      return 'whooops_something_went_wrong_text';
  }
};

function ErrorPage({ code, viewer }: Props): React$Node {
  const dictTitleKey = getDictTitleKey(code, viewer);
  const dictTextKey = getDictTextKey(code, viewer);

  const isGuest = viewer === null;

  return (
    <Page windowTitle={dict(dictTitleKey)}>
      <Page.Main size={8} styleName="mx-auto mb-5">
        <View styleName="mx-3 my-5 ai-center">
          <Heading size={4} styleName="mb-4">
            <Trans {...dict(dictTitleKey)} />
          </Heading>
          <Trans {...dict(dictTextKey)} />
          {code === 'DOMAIN_NOT_FOUND' && Domain.get().endsWith('.examunity.com') && (
            <Link to="https://www.examunity.com" external={{ target: 'self' }} styleName="mt-4">
              <Trans {...dict('discover_examunity')} />
            </Link>
          )}
          {code === 'DOMAIN_NOT_FOUND' && Domain.get().endsWith('.azubinet.de') && (
            <Link to="https://www.azubinet.de" external={{ target: 'self' }} styleName="mt-4">
              <Trans {...dict('discover_azubinet')} />
            </Link>
          )}
          {code === 'UNAUTHENTICATED' && (
            <Button to="/login" styleName="mt-4" size="lg">
              <Trans {...dict('login')} />
            </Button>
          )}
          {code === 'PAGE_NOT_FOUND' && isGuest && (
            <View styleName="ai-center mt-3">
              <Trans {...dict('this_page_might_be_accessible_to_users_who_are_logged_in')} />
              <Button to="/login" styleName="mt-4" size="lg">
                <Trans {...dict('login')} />
              </Button>
            </View>
          )}
        </View>
      </Page.Main>
    </Page>
  );
}

export default ErrorPage;
