// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { View } from 'components/core';
import TaskChoiceButton from './TaskChoiceButton';
import TaskChoiceButtonGroup from './TaskChoiceButtonGroup';
import TaskChoiceLabel from './TaskChoiceLabel';
import TaskChoiceSolution from './TaskChoiceSolution';
import TaskChoiceItem from './TaskChoiceItem';
import TaskChoiceItemMatch from './TaskChoiceItemMatch';
import TaskChoiceItemOrder from './TaskChoiceItemOrder';

type Props = {
  children: React$Node,
  last?: boolean,
};

const styles = StyleSheet.create({
  container: css`
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-vertical: 0.25rem;
    border-bottom-width: 1px;
    border-bottom-color: $gray-300;
    align-items: center;
  `,
  containerLast: css`
    border-bottom-width: 0;
    margin-bottom: 1rem;
  `,
});

const defaultProps = {
  last: false,
};

function TaskChoice({ children, last }: Props): React$Node {
  return (
    <View
      styleName="flex-wrap flex-md-nowrap"
      style={[styles.container, last && styles.containerLast]}
    >
      {children}
    </View>
  );
}

TaskChoice.defaultProps = defaultProps;

TaskChoice.Button = TaskChoiceButton;
TaskChoice.ButtonGroup = TaskChoiceButtonGroup;
TaskChoice.Label = TaskChoiceLabel;
TaskChoice.Solution = TaskChoiceSolution;
TaskChoice.Item = TaskChoiceItem;
TaskChoice.ItemMatch = TaskChoiceItemMatch;
TaskChoice.ItemOrder = TaskChoiceItemOrder;

export default TaskChoice;
