// @flow
import React from 'react';
import { Text, Button, Modal, View, CloseButton } from 'components/core';
import { createDict, Trans } from 'libs/intl';
import { useDispatch } from 'libs/graphql';
import { useAlerts } from 'libs/misc';
import { useNavigate, useLocation } from 'libs/routing';
import { createCourseMembership } from './mutations';
import getCoursesFromVoucherResult from './getCoursesFromVoucherResult';

type Props = {
  course: Entity<GQLCourse>,
  viewer: Entity<GQLUser>,
  voucherResult: ValueState,
};

const dict = createDict({
  access_code_confirmation: 'Access Code Confirmation',
  confirmation_text:
    'The submitted access code is for the listed courses. Please confirm that you want to redeem the code and join these courses:',
  cancel: 'Cancel',
  confirm: 'Confirm',
  you_are_now_a_member_of_this_course: 'You are now a member of this course.',
});

function AccessAllowedVoucherModal({ course, viewer, voucherResult }: Props): React$Node {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const alerts = useAlerts();

  const visible = !!voucherResult.value;
  const closeModal = () => {
    voucherResult.set(null);
  };

  const courses =
    visible && getCoursesFromVoucherResult(voucherResult.value.code, voucherResult.value.data);

  return (
    <Modal visible={visible} onToggle={closeModal}>
      <Modal.Header styleName="flex-row">
        <Modal.Title>
          <Trans {...dict('access_code_confirmation')} />
        </Modal.Title>
        <CloseButton onPress={closeModal} />
      </Modal.Header>
      <Modal.Body>
        <Trans {...dict('confirmation_text')} styleName="mb-3" />
        {courses &&
          courses.map((joinedCourse) => (
            <View key={joinedCourse.id} styleName="flex-row">
              <Text styleName="mr-2">{'\u25CF'}</Text>
              <Text>{joinedCourse.name}</Text>
            </View>
          ))}
      </Modal.Body>
      <Modal.Footer styleName="flex-row jc-end ai-end">
        <Button color="light" onPress={closeModal}>
          <Trans {...dict('cancel')} />
        </Button>
        <Button
          styleName="ml-3"
          onPress={() => {
            dispatch(
              createCourseMembership(course.id, viewer.id, voucherResult.value.code, 'VOUCHER'),
            ).then(() => {
              navigate('/courses/join', {
                state: { courseUrl: location.pathname + location.search },
              });

              alerts.add({
                color: 'success',
                content: <Trans {...dict('you_are_now_a_member_of_this_course')} />,
              });
            });
          }}
        >
          <Trans {...dict('confirm')} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AccessAllowedVoucherModal;
