// @flow
import React from 'react';
import { Text } from 'components/core';
import { useIntl } from 'intlized-components';

type Props = {
  value: string | Date,
  options?: Object,
};

function DateTime({ value, options, ...textProps }: Props): React$Node {
  const intl = useIntl();

  return <Text {...textProps}>{intl.dateTime(value, options)}</Text>;
}

export default DateTime;
