// @flow
import { createAsyncContainer } from 'libs/container';

const AboutUs = {
  name: 'AboutUs',
  bundle: () => import('./AboutUs'),
};

const Container = createAsyncContainer(AboutUs, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
