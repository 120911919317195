// @flow
import React from 'react';
import { Text, Button, Dropdown } from 'components/core';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  bkf: ToggleState,
  hazmat: ToggleState,
  haulage: ToggleState,
  taxi: ToggleState,
  spedi: ToggleState,
};

const styles = StyleSheet.create({
  background: css`
    background-color: #66c430;
  `,
  menu: css`
    width: 95%;
    margin-top: 0rem;
    margin-left: -0.1875rem;
  `,
  darken: css`
    background-color: #55a428;
  `,
  gridBox: css`
    flex-grow: 1;
    flex-shrink: 1;
  `,
  text: css`
    color: #fff;
    font-size: 1rem;
    line-height: 1.375rem;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
  `,
});

function TargetGroupsMobile({ bkf, hazmat, haulage, taxi, spedi }: Props): React$Node {
  return (
    <Dropdown style={styles.background}>
      <Button toggle={Dropdown} color="light" styleName="py-2">
        {bkf.on && <Text style={styles.text}>BKF-Ausbildung</Text>}
        {hazmat.on && <Text style={styles.text}>Gefahrgut</Text>}
        {haulage.on && <Text style={styles.text}>Fachkunde Güterkraftverkehr</Text>}
        {taxi.on && <Text style={styles.text}>Fachkunde Taxi</Text>}
        {spedi.on && <Text style={styles.text}>Ausbildung Speditionskaufleute</Text>}
      </Button>
      <Dropdown.Menu style={[styles.background, styles.menu]}>
        <Dropdown.Item
          onPress={() => {
            bkf.setOn();
            hazmat.setOff();
            haulage.setOff();
            taxi.setOff();
            spedi.setOff();
          }}
        >
          <Text style={styles.text}>BKF-Ausbildung</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            bkf.setOff();
            hazmat.setOn();
            haulage.setOff();
            taxi.setOff();
            spedi.setOff();
          }}
        >
          <Text style={styles.text}>Gefahrgut</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            bkf.setOff();
            hazmat.setOff();
            haulage.setOn();
            taxi.setOff();
            spedi.setOff();
          }}
        >
          <Text style={styles.text}>Fachkunde Güterkraftverkehr</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            bkf.setOff();
            hazmat.setOff();
            haulage.setOff();
            taxi.setOn();
            spedi.setOff();
          }}
        >
          <Text style={styles.text}>Fachkunde Taxi</Text>
        </Dropdown.Item>
        <Dropdown.Item
          onPress={() => {
            bkf.setOff();
            hazmat.setOff();
            haulage.setOff();
            taxi.setOff();
            spedi.setOn();
          }}
        >
          <Text style={styles.text}>Abschlussprüfung Speditionskaufleute</Text>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default TargetGroupsMobile;
