// @flow
import { createAsyncContainer } from 'libs/container';
import { query } from 'libs/graphql';
import { DOMAIN_QUERY } from 'libs/foundation/queries';
import Routes from './Routes';
import { VIEWER_QUERY } from './queries';

const options = {
  async: {
    error: Routes,
  },
};

const Container = createAsyncContainer(
  Routes,
  () => {
    const loaders = {
      domainQuery: query(DOMAIN_QUERY),
      viewerQuery: query(VIEWER_QUERY),
    };

    return {
      loaders,
    };
  },
  options,
);

export default (Container: React$AbstractComponent<any, mixed>);
