// @flow
import tokenizeBaseTable from 'remark-parse/lib/tokenize/table';
import wrapTable from '../utils/wrapTable';

export default function table() {
  const { Parser } = this;
  const tokenizers = Parser.prototype.blockTokenizers;

  function tokenizeTable(eat, value, silent) {
    const self = this;

    const node = tokenizeBaseTable.apply(self, [eat, value, silent]);

    if (silent || node === undefined) {
      return node;
    }

    // Pass down alignments.
    node.children.forEach((row) => {
      row.children.forEach((cell, i) => {
        if (node.align[i]) {
          // eslint-disable-next-line no-param-reassign
          cell.align = node.align[i];
        }
      });
    });
    delete node.align;

    // Add tableHead and tableBody nodes to table.
    node.children = wrapTable(node.children);

    return node;
  }

  tokenizers.table = tokenizeTable;
}
