// @flow
import { Link, createMutation, gql } from 'libs/graphql';

const UPDATE_OR_CREATE_SOLUTION = gql`
  mutation UpdateOrCreateSolution($input: PairingExamSolutionUpdateOrCreateInput!) {
    pairingExamSolution_updateOrCreate(input: $input) {
      pairs {
        left
        right
      }
    }
  }
`;

export function updateOrCreateSolution(
  pairingId: Id,
  examAttemptId: Id,
  right: number,
  left: ?number,
): MutationAction {
  const pairs =
    left !== null
      ? [
          {
            right,
            left,
          },
        ]
      : [];
  const pairKeysToRemove = left === null ? [right] : [];

  return createMutation(UPDATE_OR_CREATE_SOLUTION, {
    variables: {
      input: {
        pairingId,
        examAttemptId,
        pairs,
        pairKeysToRemove,
      },
    },
    updater: (store, data) => {
      // Update link
      const link = new Link(data.roots.pairingExamSolution_updateOrCreate);
      store.update('Pairing', pairingId, (record) => {
        record.set('viewerRunningExamSolution', link);
      });
    },
  });
}
