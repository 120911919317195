// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const Products = {
  name: 'Products',
  bundle: () => import('./Products'),
};

const ORGANIZATION_PRODUCTS_QUERY = gql`
  query OrganizatioQuery($slug: String!) {
    organization(slug: $slug) {
      products {
        name
        slug
        promoted
        offers {
          keysCount
          purchasesCount(completed: true)
        }
        licensesCount
        courses {
          name
        }
        professions {
          name
        }
      }
      courses {
        name
        caption
        isPrivate
      }
      name
      slug
      viewerIsAdmin
      viewerIsTrainer
    }
  }
`;

const CURRENCIES_QUERY = gql`
  query CurrenciesQuery {
    currencies {
      code
      sign
    }
  }
`;

const Container = createAsyncContainer(Products, (props) => {
  const {
    params: { slug },
  } = props;

  const variables = { slug };

  const loaders = {
    organizationQuery: query(ORGANIZATION_PRODUCTS_QUERY, { variables }),
    currenciesQuery: query(CURRENCIES_QUERY),
  };

  const selectors = {
    organization: select(ORGANIZATION_PRODUCTS_QUERY, { variables }),
    currencies: select(CURRENCIES_QUERY),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
