// @flow
import React, { useEffect } from 'react';
import { StyleSheet, css } from 'libs/ui';
import { createDict, Trans, TimeAgo } from 'libs/intl';
import { NavbarMenu } from 'components/navbar';
import { Icon, Scrollbars } from 'components/common';
import { View, Text, Dropdown, Link } from 'components/core';
import { useDispatch } from 'libs/graphql';
import { readNotification, markAllUsersUnreadNotificationsAsRead } from './mutations';
import Caret from '../Caret';

type Props = {
  query: any,
  viewer: Entity<GQLUser>,
};

const styles = StyleSheet.create({
  icon: css`
    color: $text-muted;
    margin-right: 0.25rem;
    font-size: 1.125rem;
    flex-basis: 3rem;
    flex-shrink: 0;
    text-align: center;
  `,
  subText: css`
    font-size: 0.8125rem;
  `,
  divider: css`
    border-bottom-width: 1px;
    border-bottom-color: $gray-200;
    border-style: solid;
  `,
});

const dict = createDict({
  notifications: 'Notifications',
  all_notifications: 'All notifications',
  mark_all_as_read: 'Mark all as read',
});

function NotificationList({ query, viewer }: Props): React$Node {
  const dispatch = useDispatch();

  useEffect(() => {
    // Refresh notifications every 5 minutes.
    query.startPolling(1000 * 60 * 5);

    return () => {
      query.endPolling();
    };
  }, []);

  return (
    <NavbarMenu offset={16} offsetXs={64}>
      <Caret position={46} positionXs={86} />
      <NavbarMenu.Header>
        <Trans
          {...dict('notifications')}
          color="muted"
          styleName="flex-shrink-1"
          numberOfLines={1}
        />
        {viewer.unreadNotificationsCount > 0 && (
          <View>
            <Link
              onPress={() => {
                dispatch(markAllUsersUnreadNotificationsAsRead(viewer.id));
              }}
              dismiss={Dropdown}
              styleName="ml-3"
            >
              <Trans {...dict('mark_all_as_read')} />
            </Link>
          </View>
        )}
      </NavbarMenu.Header>
      <NavbarMenu.Content>
        <Scrollbars>
          {viewer.notifications.map((notification, key) => (
            <Dropdown.Item
              to={notification.url}
              onPress={() => {
                if (notification.readAt) {
                  return;
                }

                dispatch(readNotification(notification.id, viewer.id));
              }}
              styleName="flex-row ai-center jc-center px-0 py-1"
              style={key !== viewer.notifications.length - 1 && styles.divider}
              key={notification.id}
            >
              <Icon
                glyph="bell-alt"
                style={styles.icon}
                color={notification.readAt ? null : 'primary'}
              />
              <View styleName="flex-grow-1 flex-shrink-1">
                <Text small>{notification.text}</Text>
                <TimeAgo value={notification.createdAt} style={styles.subText} color="muted" />
              </View>
            </Dropdown.Item>
          ))}
        </Scrollbars>
      </NavbarMenu.Content>
      <NavbarMenu.Footer>
        <Link to="/notifications" dismiss={Dropdown}>
          <Trans {...dict('all_notifications')} />
        </Link>
      </NavbarMenu.Footer>
    </NavbarMenu>
  );
}

export default NotificationList;
