// @flow
import React from 'react';
import { View } from 'bootstrap-rn';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  style?: any,
};

const styles = StyleSheet.create({
  footer: css`
    flex-direction: row;
    padding: 0.25rem;
    border-radius: 0.125rem;
    border-top-width: 1px;
    border-top-color: $gray-300;
    justify-content: center;
  `,
});

const NavbarFooter = React.forwardRef<Props, any>(({ style, ...props }, ref) => {
  return <View {...props} ref={ref} style={[styles.footer, style]} />;
});

export default (NavbarFooter: React$AbstractComponent<Props, any>);
