// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const EXAM_ACTIVITY_CAPTION_FRAGMENT: DocumentNode = gql`
  fragment ExamActivityCaptionFragment on ExamActivity {
    version(specificVersion: "CURRENT") {
      item {
        tasksCount
        exam {
          duration
        }
      }
    }
  }
`;
