// @flow
import React, { useEffect, useMemo, useRef } from 'react';
import { useMedia } from 'libs/ui';
import MenuContext from './MenuContext';

type Props = {
  children: React$Node,
};

function MenuProvider({ children }: Props): React$Node {
  const media = useMedia();

  const isXXLScreen = media.up('xxl');

  const menu = useRef({
    expanded: isXXLScreen,
    listeners: [],
  });

  const setExpanded = (value) => {
    menu.current.expanded = typeof value === 'function' ? value(menu.current.expanded) : value;

    menu.current.listeners.forEach((handleUpdate) => {
      handleUpdate(menu.current.expanded);
    });
  };

  useEffect(() => {
    if (isXXLScreen && !menu.current.expanded) {
      setExpanded(true);
    }

    if (!isXXLScreen && menu.current.expanded) {
      setExpanded(false);
    }
  }, [isXXLScreen]);

  const value = useMemo(
    () => ({
      get expanded() {
        return menu.current.expanded;
      },
      subscribe(listener: (key: boolean) => void) {
        menu.current.listeners.push(listener);

        const unsubscribe = () => {
          const index = menu.current.listeners.indexOf(listener);

          menu.current.listeners.splice(index, 1);
        };

        return unsubscribe;
      },
      clear() {
        if (isXXLScreen) {
          return;
        }

        setExpanded(false);
      },
      setExpanded(expanded) {
        setExpanded(expanded);
      },
    }),
    [isXXLScreen],
  );

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
}

export default MenuProvider;
