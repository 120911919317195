// @flow
import React from 'react';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';
import { Markdown } from 'components/markdown';
import { QuizCard } from 'components/quizzes';
import { Icon, Scrollbars } from 'components/common';
import { Text, View, Button, Badge, Blockquote } from 'components/core';
import type { QuizState, CurrentQuizCardState } from '../useQuizState';

type Props = {
  textCard: Entity<GQLTextCard>,
  quizState: QuizState,
  currentCardState: CurrentQuizCardState,
};

const dict = createDict({
  question: 'Question',
  solution: 'Solution',
  show_solution: 'Show solution',
  open_question: 'Open Question',
  hint: 'Hint',
});

const styles = StyleSheet.create({
  hiddenBack: css`
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    @include media-breakpoint-down(md) {
      border-radius: 0 0 0.5rem 0.5rem;
    }

    @include media-breakpoint-up(lg) {
      border-radius: 0 0.5rem 0.5rem 0;
    }
  `,
  hiddenBackIcon: css`
    font-size: 1.5rem;
    line-height: 2.25rem;
  `,
});

function TextCard({ textCard, currentCardState, quizState }: Props): React$Node {
  const media = useMedia();
  const { answer, hint } = textCard;

  const DesktopScrollbars = media.up('lg') ? Scrollbars : React.Fragment;

  return (
    <QuizCard>
      <QuizCard.Front styleName="p-0">
        <DesktopScrollbars>
          <View styleName="p-3 p-lg-4">
            <View styleName="flex-row mb-3 jc-between ai-center">
              <Text color="muted" styleName="pr-3">
                <Trans {...dict('question')} /> #{currentCardState.number}
              </Text>
              <Badge color="secondary">
                <Trans {...dict('open_question')} />
              </Badge>
            </View>
            <Markdown source={textCard.text} />
          </View>
        </DesktopScrollbars>
      </QuizCard.Front>
      <QuizCard.Back styleName="p-0">
        {!currentCardState.flipped ? (
          <Button
            onPress={() => {
              quizState.flip();
            }}
            color="light"
            style={styles.hiddenBack}
          >
            <Icon glyph="lightbulb" style={styles.hiddenBackIcon} />
            <Trans {...dict('show_solution')} styleName="mt-2" />
          </Button>
        ) : (
          <DesktopScrollbars>
            <View styleName="p-3 p-lg-4">
              <Trans {...dict('solution')} color="muted" styleName="pb-3" />
              <Markdown source={answer.text} />
              {hint && currentCardState.flipped && (
                <Blockquote styleName="mt-3">
                  <Trans {...dict('hint')} color="muted" small styleName="mb-2" />
                  <Markdown source={hint} />
                </Blockquote>
              )}
            </View>
          </DesktopScrollbars>
        )}
      </QuizCard.Back>
    </QuizCard>
  );
}

export default TextCard;
