// @flow
import React, { useId } from 'react';
import { Checkbox, Radio, View, Text, FormCheck } from 'bootstrap-rn';
import useFormField from './useFormField';
import Field from './Field';

type Props = {
  name: string,
  niceName?: Intlized$Message | string | null,
  title?: Intlized$Message | string | null,
  info?: Intlized$Message | string | null,
  disabled?: boolean,
  onValueChange?: (any) => void,
  options: Array<{
    value: any,
    label: any,
    disabled?: boolean,
  }>,
  multiple?: boolean,
};

const FormChoice = React.forwardRef<Props, any>((props, ref) => {
  const {
    name,
    niceName,
    title,
    options,
    info,
    multiple = false,
    disabled = false,
    onValueChange,
    ...elementProps
  } = props;

  const field = useFormField(name, niceName, title);
  const id = useId();

  return (
    <Field
      title={title}
      error={field.error}
      touched={field.touched}
      info={info}
      labelID={id}
      elementProps={elementProps}
    >
      <View styleName="mt-2" aria-labelledby={title && id}>
        {!multiple && (
          <Radio.Group
            selectedValue={field.value}
            onValueChange={(nextValue) => {
              field.setValue(nextValue, onValueChange);
            }}
          >
            {options.map((option, key) => (
              <FormCheck
                invalid={field.touched && !!field.error}
                disabled={disabled}
                key={option.value}
              >
                <Radio
                  ref={ref}
                  name={name}
                  value={option.value}
                  onBlur={() => {
                    field.setTouched();
                  }}
                  aria-labelledby={`${id}-${key}`}
                  id={`${id}-action-${key}`}
                />
                <FormCheck.Label htmlFor={`${id}-action-${key}`} id={`${id}-${key}`}>
                  {typeof option.label === 'string' ? <Text>{option.label}</Text> : option.label}
                </FormCheck.Label>
              </FormCheck>
            ))}
          </Radio.Group>
        )}
        {multiple && (
          <View>
            {options.map((option, key) => (
              <FormCheck
                invalid={field.touched && !!field.error}
                disabled={disabled || option.disabled}
                key={option.value}
              >
                <Checkbox
                  ref={ref}
                  name={`${name}[${key}]`}
                  value={field.value.indexOf(option.value) !== -1}
                  onValueChange={(checked) => {
                    const nextValue = [...field.value];

                    if (checked) {
                      nextValue.push(option.value);
                    } else {
                      nextValue.splice(nextValue.indexOf(option.value), 1);
                    }

                    field.setValue(nextValue, onValueChange);
                  }}
                  onBlur={() => {
                    field.setTouched();
                  }}
                  aria-labelledby={`${id}-${key}`}
                  id={`${id}-action-${key}`}
                />
                <FormCheck.Label htmlFor={`${id}-action-${key}`} id={`${id}-${key}`}>
                  {typeof option.label === 'string' ? <Text>{option.label}</Text> : option.label}
                </FormCheck.Label>
              </FormCheck>
            ))}
          </View>
        )}
      </View>
    </Field>
  );
});

export default (FormChoice: React$AbstractComponent<Props, any>);
