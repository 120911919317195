// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const EXAM_ACTIONS_FRAGMENT: DocumentNode = gql`
  fragment ExamActionsFragment on ExamActivity {
    slug
    versions {
      item {
        taskPartsCount
        exam {
          slug
          duration
          attemptsCount
          points
        }
      }
    }
  }
`;
