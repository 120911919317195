// @flow
import React from 'react';
import { FeatureStripe } from 'components/pages';
import { useIntl } from 'libs/intl';

function Content(): React$Node {
  const intl = useIntl();

  return (
    <>
      {intl.locale === 'en' && (
        <>
          <FeatureStripe
            title="Compact learning formats"
            content="Tutorial videos explain difficult topics within a few minuts. Flashcard sets cover all the relevant stuff needed for the exams."
            image="/img/home/presentation-section-2.png"
            textPosition="left"
          />
          <FeatureStripe
            title="Real-Life Mock Exams"
            content="Your trainees work with hundreds of mock exams just like from the chamber of industry and commerce / Industrie- und Handelskammer (IHK)."
            image="/img/pages/business-2.png"
            textPosition="right"
          />

          <FeatureStripe
            title="Full support for your trainees"
            content="You accompany your trainees, viewing their individual learning statistics. The Azubinet team supports your trainees if they experience problems."
            image="/img/pages/invite.png"
            textPosition="left"
          />
          <FeatureStripe
            title="Your own content"
            content="You, your colleagues and your trainees can create your very own materials such as flashcards, videos, surveys or even checklists."
            image="/img/pages/business-3.png"
            textPosition="right"
          />
        </>
      )}
      {intl.locale === 'de' && (
        <>
          <FeatureStripe
            title="Prüfungswissen in kompakter Form"
            content="Mit unseren Lernkarten und Erklärvideos, die sich nach den offiziellen Prüfungskatalogen und den Rahmenlehrplänen richten, üben Ihre Azubis kurzweilig und auf den Punkt zielgerichtet."
            image="/img/home/presentation-section-2.png"
            textPosition="left"
          />
          <FeatureStripe
            title="Probeklausuren wie von der IHK"
            content="Ihre Auszubildenden lernen mit Probeklausuren auf IHK-Niveau - als Prüfungssimulation, im gemeinsamen Austausch als PDF-Download zum Lernen am Schreibtisch."
            image="/img/pages/business-2.png"
            textPosition="right"
          />

          <FeatureStripe
            title="Volle Unterstützung für Ihre Azubis"
            content="Sie begleiten den Lernfortschritt Ihrer Azubis, erkennen frühzeitig Probleme und können eigene Materialien beisteuern. Ihre Azubis tauschen sich mit anderen aus und das Azubinet-Team unterstützt sie fachlich."
            image="/img/pages/business-3.png"
            textPosition="left"
          />
          <FeatureStripe
            title="Maßgeschneiderter Content"
            content="Zusätzlich zu den Inhalten zur Prüfungsvorbereitung und für die einzelnen Lehrjahre lernen Ihre Azubis bei Bedarf Grundlagen zu Mathe und Rechnungswesen oder mit Themen wie Deutsch für die Ausbildung."
            image="/img/pages/business-4.png"
            textPosition="right"
          />
        </>
      )}
    </>
  );
}

export default Content;
