// @flow
import { useCallback } from 'react';

export default function useDownload(file: Entity<GQLFile>): [any, Object] {
  const loading = false;

  const handleDownload = useCallback(() => {
    window.location = `/files/${file.id}/${file.encodedName}?download=true`;
  }, []);

  return [handleDownload, { loading }];
}
