// @flow
import React from 'react';
import { Heading } from 'components/core';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  children: React$Node,
  style?: any,
};

const styles = StyleSheet.create({
  azubinetColor: css`
    color: #001f3f;
  `,
});

const BrandHeading = React.forwardRef<Props, any>((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { children, style, ...elementProps } = props;

  return (
    <Heading {...elementProps} ref={ref} style={[styles.azubinetColor, style]}>
      {children}
    </Heading>
  );
});

export default (BrandHeading: React$AbstractComponent<Props, any>);
