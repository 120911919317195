// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { Code } from 'components/core';

type Props = {
  value: React$Node,
};

const styles = StyleSheet.create({
  code: css`
    background-color: $gray-100;
  `,
});

function InlineCode({ value }: Props): React$Node {
  return <Code style={styles.inlineCode}>{value}</Code>;
}

export default InlineCode;
