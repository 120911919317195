// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query, selectByRoot } from 'libs/graphql';

const LicenseList = {
  name: 'LicenseList',
  bundle: () => import('./LicenseList'),
};

const ORGANIZATION_LICENSES_QUERY = gql`
  query OrganizationQuery($slug: String!) {
    organization(slug: $slug) {
      name
      slug
      licenses {
        validUntil
        reusable
        assignment {
          user {
            name
            avatar {
              file {
                name
                extension
                info {
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
        }
        product {
          name
          professions {
            name
          }
        }
      }
      memberships {
        role
        user {
          name
          avatar {
            file {
              name
              extension
              info {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
      licensesCount
      viewerIsAdmin
      viewerIsTrainer
      trialUntil
    }
  }
`;

const Container = createAsyncContainer(LicenseList, (props) => {
  const {
    params: { slug },
  } = props;

  const variables = { slug };

  const loaders = {
    query: query(ORGANIZATION_LICENSES_QUERY, { variables }),
  };

  const selectors = {
    organization: select(ORGANIZATION_LICENSES_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
