// @flow
import React from 'react';
import { Markdown } from 'components/markdown';
import { View } from 'components/core';
import { useMedia } from 'libs/ui';
import { TaskChoice } from 'components/tasks';

type Props = {
  piece: Entity<GQLPairingPiece>,
  last: boolean,
};

function PieceOrdering({ piece, last }: Props): React$Node {
  const media = useMedia();

  return (
    <>
      {media.up('md') && (
        <TaskChoice last={last}>
          <TaskChoice.Label>
            <View styleName="flex-row flex-grow-1 flex-shrink-1 ai-start">
              <Markdown variant="article" source={piece.text} />
            </View>
          </TaskChoice.Label>
        </TaskChoice>
      )}
      {media.down('sm') && (
        <View styleName="flex-row flex-grow-1 flex-shrink-1 ai-start">
          <Markdown variant="article" source={piece.text} />
        </View>
      )}
    </>
  );
}

export default PieceOrdering;
