// @flow
import React from 'react';
import { View, Text, Grid } from 'components/core';
import { StyleSheet, css } from 'libs/ui';
import Feature from './Feature';

const styles = StyleSheet.create({
  heading: css`
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
  `,
  headingText: css`
    font-family: 'Titillium Web';
    font-weight: bold;
    font-size: 2rem;
    padding-bottom: 0.5rem;
    text-align: center;
  `,
});

function FeaturesContent(): React$Node {
  return (
    <>
      <View style={styles.heading}>
        <Text style={styles.headingText}>Funktionen der Lernplattform</Text>
      </View>
      <Grid>
        <Feature
          icon="award"
          feature="Prüfungsmodus"
          text="Teste dich mit Prüfungen und Klausuren"
        />
        <Feature
          icon="cards"
          feature="Quizmodus"
          text="Lerne mit tausenden Fragen im Karteikarten-System"
        />
        <Feature
          icon="users"
          feature="Diskussionsmodus"
          text="Tausche dich mit anderen Azubis aus"
        />
        <Feature
          icon="videocam"
          feature="Artikel und Videos"
          text="Wissen zum Nachschlagen und ständig neue Videos"
        />
        <Feature
          icon="chart-line"
          feature="Statistiken"
          text="Behalte deinen Lernfortschritt im Auge"
        />
        <Feature
          icon="folder-open"
          feature="Eigene Materialien"
          text="Zum Online-Lernen &amp; Teilen mit anderen"
        />
      </Grid>
    </>
  );
}

export default FeaturesContent;
