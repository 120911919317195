// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const TRUE_FALSE_FRAGMENT: DocumentNode = gql`
  fragment TrueFalseFragment on TrueFalse {
    text
    viewerProposedSolution {
      statementValues {
        position
        value
      }
    }
    sampleSolution {
      statementValues {
        position
        value
      }
      explanation
    }
    statements {
      position
      text
      proposedSolutionsTrueCount
      proposedSolutionsFalseCount
    }
  }
`;
