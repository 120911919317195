// @flow
import React from 'react';
import { Avatar } from 'components/common';
import { Navbar, Image, Nav, Container, View } from 'components/core';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';
import { useToggleState } from 'libs/misc';
import ProfessionsModal from 'views/_shared/ProfessionsModal';

type Props = {
  viewer?: ?Entity<GQLUser>,
};

const dict = createDict({
  professions: 'Professions',
  login: 'Log in',
  register: 'Register',
  for_companies: 'For companies',
  logged_in_as_user: 'Logged in as {name}',
});

const styles = StyleSheet.create({
  logo: css`
    width: 200px;
    height: 34px;
  `,
  collapseMobile: css`
    margin-top: 1rem;
    border-radius: 0.125rem;
    background-color: #f5f5f5;
    padding: 0.5rem 1rem;
    align-items: flex-start;
  `,
  navbarLink: css`
    color: #001f3f;
    &:focus-visible {
      color: #001f3f;
    }
    &:hover {
      text-decoration-color: #001f3f;
      text-decoration-line: underline;
      color: #001f3f;
    }
  `,
  highlightButtonDesktop: css`
    border-color: #001f3f;
    border-style: solid;
    border-radius: 0.1875rem;
    border-width: 1px;
  `,
});

const defaultProps = {
  viewer: null,
};

function NavigationAzubinet({ viewer }: Props): React$Node {
  const media = useMedia();
  const modal = useToggleState(false);

  return (
    <>
      <View styleName="bg-white">
        <Container styleName="bg-white">
          <Navbar expand="md" styleName="bg-white px-3 py-3 py-md-4 flex-grow-1">
            <Navbar.Brand to="/">
              <Image resizeMode="contain" source="/img/azubinet.png" style={styles.logo} />
            </Navbar.Brand>
            <Navbar.Toggler />
            <Navbar.Collapse
              styleName="flex-grow-1 jc-start jc-md-end"
              style={media.down('sm') && styles.collapseMobile}
            >
              <Nav>
                <Nav.Link
                  to="/business"
                  textStyle={styles.navbarLink}
                  style={media.up('md') && styles.highlightButtonDesktop}
                >
                  <Trans {...dict('for_companies')} bold={media.up('md')} />
                </Nav.Link>
                <Nav.Link onPress={modal.toggle} textStyle={styles.navbarLink} styleName="ml-md-3">
                  <Trans {...dict('professions')} />
                </Nav.Link>
                {!viewer && (
                  <>
                    <Nav.Link to="/login" textStyle={styles.navbarLink} styleName="ml-md-3">
                      <Trans {...dict('login')} />
                    </Nav.Link>
                    <Nav.Link to="/register" textStyle={styles.navbarLink} styleName="ml-md-3">
                      <Trans {...dict('register')} />
                    </Nav.Link>
                  </>
                )}
                {viewer && (
                  <Nav.Link
                    to="/dashboard"
                    textStyle={styles.navbarLink}
                    styleName="pr-md-0 mr-md-0 mt-3 mt-md-0 ml-md-3"
                  >
                    {media.down('sm') && (
                      <Avatar avatar={viewer.avatar} size="sm" styleName="mr-2" />
                    )}
                    <Trans {...dict('logged_in_as_user', { name: viewer.firstName })} />
                    {media.up('md') && <Avatar avatar={viewer.avatar} styleName="ml-2" />}
                  </Nav.Link>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </View>
      <ProfessionsModal modal={modal} navigation />
    </>
  );
}

NavigationAzubinet.defaultProps = defaultProps;

export default NavigationAzubinet;
