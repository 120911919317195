// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { Heading } from 'components/core';

type Props = {
  children: React$Node,
  style?: any,
};

const styles = StyleSheet.create({
  title: css`
    margin-horizontal: 1rem;
    margin-bottom: 2rem;
    font-size: $h3-font-size;
    line-height: $h3-font-size * $headings-line-height;
  `,
});

function PageTitle({ children, style }: Props): React$Node {
  return (
    <Heading size={1} style={[styles.title, style]}>
      {children}
    </Heading>
  );
}

export default PageTitle;
