// @flow
import { Link, createMutation, gql } from 'libs/graphql';

const UPDATE_OR_CREATE_SOLUTION = gql`
  mutation UpdateOrCreateSolution($input: ClozeExamSolutionUpdateOrCreateInput!) {
    clozeExamSolution_updateOrCreate(input: $input) {
      gapValues {
        key
        value
      }
    }
  }
`;

type GapValueInput = { key: string, value: string };

export function updateOrCreateSolution(
  clozeId: Id,
  examAttemptId: Id,
  gapValues: GapValueInput[],
  gapValueKeysToRemove: string[],
): MutationAction {
  return createMutation(UPDATE_OR_CREATE_SOLUTION, {
    variables: {
      input: {
        clozeId,
        examAttemptId,
        gapValues,
        gapValueKeysToRemove,
      },
    },
    updater: (store, data) => {
      const link = new Link(data.roots.clozeExamSolution_updateOrCreate);

      store.update('Cloze', clozeId, (record) => {
        record.set('viewerRunningExamSolution', link);
      });
    },
  });
}
