// @flow

export default function getTaskPartPoints(
  task: Entity<GQLTask>,
  taskPart: Entity<GQLTaskPart>,
  taskPoints: Array<Entity<GQLTaskExamPoints>>,
): ?number {
  const taskItem = taskPoints.find((item) => {
    return item.position === task.position;
  });

  const taskPartItem =
    taskItem &&
    taskItem.parts.find((item) => {
      return item.position === taskPart.position;
    });

  if (!taskPartItem) {
    return null;
  }

  return taskPartItem.points;
}
