// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { View } from 'components/core';

type Props = {
  children: React$Node,
  style?: ?Object,
};

const styles = StyleSheet.create({
  container: css`
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-radius: 0.125rem;
    border-color: $gray-300;
    border-width: 1px;
    box-shadow: inset 0 0 0.25rem 0 $transparent-200;
    background-color: #f5f5f5;
  `,
});

const defaultProps = {
  style: null,
};

function Well({ style, children, ...elementProps }: Props): React$Node {
  return (
    <View {...elementProps} style={[styles.container, style]}>
      {children}
    </View>
  );
}

Well.defaultProps = defaultProps;

export default Well;
