// @flow
import React from 'react';
import { Nav } from 'components/core';
import { createDict, Trans } from 'libs/intl';
import { StyleSheet, css } from 'libs/ui';

const styles = StyleSheet.create({
  nav: css`
    margin: 0;
    flex-shrink: 1;
    flex-direction: column;
  `,
  heading: css`
    margin-top: 1.25rem;
    margin-bottom: 0.75rem;
  `,
});

const dict = createDict({
  personal_settings: 'Personal Settings',
  profile: 'Profile',
  account: 'Account',
  licenses: 'Licenses',
  organization_settings: 'Organization Settings',
  public_information: 'Public Information',
  users: 'Users',
  courses: 'Courses',
  landing: 'Landing',
});

function UserSettingsLayout(): React$Node {
  return (
    <Nav variant="pills" style={styles.nav} styleName="mb-3 mb-md-0">
      <Nav.Link to="/settings/profile">
        <Trans {...dict('profile')} />
      </Nav.Link>
      <Nav.Link to="/settings/account">
        <Trans {...dict('account')} />
      </Nav.Link>
      <Nav.Link to="/settings/licenses">
        <Trans {...dict('licenses')} />
      </Nav.Link>
    </Nav>
  );
}

export default UserSettingsLayout;
