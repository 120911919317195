// @flow
import { createContainer } from 'libs/container';
import { select, gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import LinkToOrganizationTeam from './LinkToOrganizationTeam';

export const ORGANIZATION_TEAM_ANNOUNCEMENT_FRAGMENT: DocumentNode = gql`
  fragment OrganizationTeamAnnouncementFragment on OrganizationTeam {
    name
    slug
    organization {
      slug
    }
  }
`;

const Container = createContainer(LinkToOrganizationTeam, (props) => {
  const selectors = {
    team: select(ORGANIZATION_TEAM_ANNOUNCEMENT_FRAGMENT, {
      entry: ['OrganizationTeam', props.id],
    }),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
