// @flow
import React from 'react';
import { Grid, View, Button } from 'components/core';
import { Icon } from 'components/common';
import { BrandHeading, BrandLargeText, HighlightStripe } from 'components/pages';
import { createDict, Trans } from 'libs/intl';
import { StyleSheet, css } from 'libs/ui';

const styles = StyleSheet.create({
  feature: css`
    flex-direction: row;
    flex-shrink: 1;
    padding-top: 1rem;
  `,
  icon: css`
    margin-right: 1rem;
    color: #001f3f;
  `,
  largeText: css`
    text-align: left;
  `,
});

const dict = createDict({
  exam_preparation: 'Exam Preparation',
  continuous_learning: 'Continuous Learning',
  exam_preparation_explained:
    'The right option for an intensive preparation on the midterm and final exams.',
  continuous_learning_explained:
    'Your choice if you want to support your trainees from the first day of their apprenticeship onwards.',
  one_time_licenses_for_three_or_six_months: 'One-time licenses for three or six months',
  no_subscription_no_hidden_fees_or_setup_costs: 'No subscription, no hidden fees or setup costs',
  onboarding_all_functions_full_support_included:
    'Onboarding, all functions and full support included',
  licenses_for_one_year_discounts_for_longer_durations:
    'Licenses for one year, discounts for longer durations',
  your_own_materials_tests_checklists_cloud_storage:
    'Your own materials: tests, checklists, cloud storage',
  consistent_support_by_azubinet_team: 'Consistent support by Azubinet team',
  request_free_trial: 'Request free trial',
});

function PlansContent(): React$Node {
  return (
    <>
      <Grid>
        <Grid.Box size={12} sizeMd={6}>
          <HighlightStripe styleName="p-md-4">
            <BrandHeading size={3} styleName="mb-3">
              <Icon glyph="list-numbered" styleName="mr-3" />
              <Trans {...dict('exam_preparation')} />
            </BrandHeading>
            <View style={styles.feature} styleName="mb-3">
              <BrandLargeText style={styles.largeText}>
                <Trans {...dict('exam_preparation_explained')} />
              </BrandLargeText>
            </View>
            <View style={styles.feature}>
              <Icon glyph="ok" style={styles.icon} />
              <BrandLargeText style={styles.largeText}>
                <Trans {...dict('one_time_licenses_for_three_or_six_months')} />
              </BrandLargeText>
            </View>
            <View style={styles.feature}>
              <Icon glyph="ok" style={styles.icon} />
              <BrandLargeText style={styles.largeText}>
                <Trans {...dict('no_subscription_no_hidden_fees_or_setup_costs')} />
              </BrandLargeText>
            </View>
            <View style={styles.feature}>
              <Icon glyph="ok" style={styles.icon} />
              <BrandLargeText style={styles.largeText}>
                <Trans {...dict('onboarding_all_functions_full_support_included')} />
              </BrandLargeText>
            </View>
            <View styleName="mt-4 ai-md-center">
              <Button size="lg" to="/request-access" color="success">
                <Trans {...dict('request_free_trial')} />
              </Button>
            </View>
          </HighlightStripe>
        </Grid.Box>
        <Grid.Box size={12} sizeMd={6}>
          <HighlightStripe styleName="p-md-4 mt-3 mt-md-0">
            <BrandHeading size={3} styleName="mb-3">
              <Icon glyph="chart-line" styleName="mr-3" />
              <Trans {...dict('continuous_learning')} />
            </BrandHeading>
            <View style={styles.feature} styleName="mb-3">
              <BrandLargeText style={styles.largeText}>
                <Trans {...dict('continuous_learning_explained')} />
              </BrandLargeText>
            </View>
            <View style={styles.feature}>
              <Icon glyph="ok" style={styles.icon} />
              <BrandLargeText style={styles.largeText}>
                <Trans {...dict('licenses_for_one_year_discounts_for_longer_durations')} />
              </BrandLargeText>
            </View>
            <View style={styles.feature}>
              <Icon glyph="ok" style={styles.icon} />
              <BrandLargeText style={styles.largeText}>
                <Trans {...dict('your_own_materials_tests_checklists_cloud_storage')} />
              </BrandLargeText>
            </View>
            <View style={styles.feature}>
              <Icon glyph="ok" style={styles.icon} />
              <BrandLargeText style={styles.largeText}>
                <Trans {...dict('consistent_support_by_azubinet_team')} />
              </BrandLargeText>
            </View>
            <View styleName="mt-4 ai-md-center">
              <Button size="lg" to="/request-access" color="success">
                <Trans {...dict('request_free_trial')} />
              </Button>
            </View>
          </HighlightStripe>
        </Grid.Box>
      </Grid>
    </>
  );
}

export default PlansContent;
