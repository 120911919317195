// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { Icon } from 'components/common';
import { Button } from 'components/core';

type Props = {
  variant: 'true' | 'false',
  checked: boolean,
  onPress: (SyntheticEvent<*>) => void,
  disabled?: boolean,
};

const styles = StyleSheet.create({
  label: css`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
  `,
  labelDefault: css`
    font-size: 1.125rem;
    color: $transparent-200;

    &:hover {
      color: $transparent-200;
    }
  `,
  labelActive: css`
    font-size: 1.125rem;
    color: $link-color;

    &:hover {
      color: $link-color;
    }
  `,
});

function ChoiceCheckbox(props: Props): React$Node {
  const { variant, checked, onPress: handlePress, disabled } = props;

  return (
    <Button
      color="secondary"
      outline
      onPress={handlePress}
      active={checked}
      disabled={disabled}
      style={styles.label}
      textStyle={checked ? styles.labelActive : styles.labelDefault}
    >
      {variant === 'true' && <Icon glyph="ok" />}
      {variant === 'false' && <Icon glyph="cancel" />}
    </Button>
  );
}

export default ChoiceCheckbox;
