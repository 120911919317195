// @flow

import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { Modal, CloseButton, ListGroup } from 'components/core';
import { Markdown } from 'components/markdown';
import type { QuizState, CurrentQuizCardState } from '../useQuizState';

type Props = {
  modal: ToggleState,
  answer: Entity<GQLMatchingCardAnswer>,
  matchingAnswers: Array<Entity<GQLMatchingCardAnswer>>,
  quizState: QuizState,
  currentCardState: CurrentQuizCardState,
};

const dict = createDict({
  select_the_matching_answer: 'Select the matching answer',
});

function SelectMatchingAnswerModal({
  modal,
  answer,
  matchingAnswers,
  quizState,
  currentCardState,
}: Props): React$Node {
  return (
    <Modal visible={modal.on} onToggle={modal.toggle}>
      <Modal.Header styleName="flex-row">
        <Modal.Title>
          <Trans {...dict('select_the_matching_answer')} />
        </Modal.Title>
        <CloseButton onPress={modal.toggle} />
      </Modal.Header>
      <Modal.Body>
        <Markdown source={answer.text} allowLinkedImages={false} styleName="mb-3" />
        <ListGroup>
          {matchingAnswers.map((answerItem) => (
            <ListGroup.ItemAction
              key={answerItem.id}
              onPress={() => {
                const hasAnswer = currentCardState.answers.some(
                  (value) => value.answerId === answer.id,
                );

                let isAnswerCorrect = false;
                let areAnswersComplete = false;

                if (answer.matchingText === answerItem.matchingText) {
                  if (!hasAnswer) {
                    if (
                      !currentCardState.answers.some((value) => !value.correct) &&
                      currentCardState.answers.length + 1 === matchingAnswers.length
                    ) {
                      isAnswerCorrect = true;
                    }
                    areAnswersComplete =
                      currentCardState.answers.length + 1 === matchingAnswers.length;
                  } else {
                    const correctAnswers = currentCardState.answers.filter(
                      (value) => value.correct,
                    );
                    const wrongAnswers = currentCardState.answers.filter((value) => !value.correct);
                    if (correctAnswers.length + wrongAnswers.length === matchingAnswers.length) {
                      isAnswerCorrect = true;
                    }
                    areAnswersComplete = currentCardState.answers.length === matchingAnswers.length;
                  }

                  quizState.addAnswer({
                    correct: true,
                    answerId: answer.id,
                    matchingText: answerItem.matchingText,
                  });
                } else {
                  areAnswersComplete =
                    currentCardState.answers.length + (hasAnswer ? 0 : 1) ===
                    matchingAnswers.length;

                  quizState.addAnswer({
                    correct: false,
                    answerId: answer.id,
                    matchingText: answerItem.matchingText,
                  });
                }

                quizState.setCurrentCardResult({
                  correct: isAnswerCorrect,
                  areAnswersComplete,
                });

                modal.setOff();
              }}
            >
              <Markdown source={answerItem.matchingText} allowLinkedImages={false} />
            </ListGroup.ItemAction>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
}

export default SelectMatchingAnswerModal;
