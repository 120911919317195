// @flow
import React from 'react';
import { Markdown } from 'components/markdown';
import { Form } from 'components/forms';
import { useDispatch } from 'libs/graphql';
import { Editor } from 'components/editor';
import { updateOrCreateSolution, deleteSolution } from './mutations';
import AutoSubmit from './AutoSubmit';
import type { TaskExamControls } from '../useExamControls';

type Props = {
  openQuestion: Entity<GQLOpenQuestion>,
  examAttemptId: Id,
  controls: TaskExamControls,
};

function OpenQuestion({ openQuestion, examAttemptId, controls }: Props): React$Node {
  const dispatch = useDispatch();

  const initialText =
    openQuestion.viewerRunningExamSolution && openQuestion.viewerRunningExamSolution.text;

  return (
    <>
      <Markdown variant="article" source={openQuestion.text} styleName="mb-3" />
      <Form
        initialValues={{
          text: initialText,
        }}
        onSubmit={(values) => {
          // Don't fire mutation if there are no changes.
          if (values.text === initialText) {
            return null;
          }

          if (!values.text) {
            if (!openQuestion.viewerRunningExamSolution) {
              return null;
            }
            return dispatch(
              deleteSolution(openQuestion.id, openQuestion.viewerRunningExamSolution.id),
            );
          }

          return dispatch(updateOrCreateSolution(openQuestion.id, examAttemptId, values.text));
        }}
      >
        <Editor name="text" />
        <AutoSubmit controls={controls} />
      </Form>
    </>
  );
}

export default OpenQuestion;
