// @flow
import React from 'react';
import { ScrollArea } from 'libs/scroll';
import { StyleSheet, css } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';
import { Outlet } from 'libs/routing';
import { Text, View, Link } from 'components/core';
import BrandLogo from './BrandLogo';

type Props = {
  domain: Entity<GQLDomain>,
};

const dict = createDict({
  privacy: 'Privacy',
  terms: 'Terms',
  legal_note: 'Legal Note',
});

const styles = StyleSheet.create({
  background: css`
    border-top-width: 0.25rem;
    border-top-color: $brand-primary;
  `,
  container: css`
    width: 100%;
    margin-horizontal: auto;
    max-width: 384px;
  `,
  logo: css`
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 2rem;
  `,
  footer: css`
    align-self: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
  `,
});

function AuthLayout({ domain }: Props): React$Node {
  return (
    <ScrollArea>
      <View style={styles.background}>
        <View style={styles.container}>
          <View style={styles.logo}>
            <BrandLogo domain={domain} />
          </View>
          <Outlet />
          <Text small color="muted" style={styles.footer}>
            <Link to="/privacy-policy">
              <Trans {...dict('privacy')} />
            </Link>{' '}
            &middot;{' '}
            <Link to="/terms-of-use">
              <Trans {...dict('terms')} />
            </Link>{' '}
            &middot;{' '}
            <Link to="/legal-contact">
              <Trans {...dict('legal_note')} />
            </Link>
          </Text>
        </View>
      </View>
    </ScrollArea>
  );
}

export default AuthLayout;
