// @flow
import React from 'react';
import BundleCompact from './BundleCompact';

type Props = {
  onClose?: () => void,
};

function PersonaldienstleistungskaufmannBundles({ onClose }: Props): React$Node {
  return (
    <>
      <BundleCompact
        to="/courses/pALvuIP1lL"
        title="Erstmal reinschnuppern"
        price="kostenlose Lernmaterialien und Community"
        onClose={onClose}
      />
      <BundleCompact
        to="/professions/personaldienstleistungskauffrau-personaldienstleistungskaufmann"
        title="Lernpaket Personaldienstleistungskaufleute"
        price="24,95 EUR"
        paid
      />
    </>
  );
}

export default PersonaldienstleistungskaufmannBundles;
