// @flow
import { LESS_THAN, SLASH } from './chars';
import type { HtmlTag } from './types';

export default function isClosingTag(value: string, index: number, tag: HtmlTag): boolean {
  if (value.charAt(index) !== LESS_THAN || value.charAt(index + 1) !== SLASH) {
    return false;
  }

  const [, closingTag] = tag.source;

  if (!closingTag) {
    throw new Error('No closing tag found.');
  }

  return value.slice(index, index + closingTag.length) === closingTag;
}
