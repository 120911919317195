// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const MyChecklists = {
  name: 'MyChecklists',
  bundle: () => import('./MyChecklists'),
};

const VIEWER_CHECKLISTS_QUERY = gql`
  query ChecklistQuery {
    viewer {
      checklists {
        slug
        name
        todosCount
      }
      checklistResults {
        assigner {
          id
        }
        slug
        checklist {
          slug
          name
          todosCount
        }
        todoResultsCount
        doneUntil
      }
    }
  }
`;

const Container = createAsyncContainer(MyChecklists, () => {
  const loaders = {
    query: query(VIEWER_CHECKLISTS_QUERY, {}),
  };

  const selectors = {
    viewer: select(VIEWER_CHECKLISTS_QUERY, {}),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
