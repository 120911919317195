// @flow
import { createContainer } from 'react-transporter';
import { selectByRoot } from 'libs/graphql';
import Home from './Home';

const Container = createContainer(Home, () => {
  const selectors = {
    viewer: selectByRoot('viewer'),
  };

  return {
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
