// @flow
import React, { useRef, useEffect } from 'react';
import { findNodeHandle } from 'react-native';
import { StyleSheet, css } from 'libs/ui';
import { View } from 'components/core';

type Props = {
  videoId: string,
};

const styles = StyleSheet.create({
  container: css`
    width: 100%;
    aspect-ratio: 16/9;
  `,
});

function StudyflixVideo({ videoId }: Props): React$Node {
  const ref = useRef();

  useEffect(() => {
    const element = ref.current && findNodeHandle(ref.current);

    if (!element) {
      return;
    }

    const script = document.createElement('script');

    script.src = 'https://studyflix.de/embed';
    script.setAttribute('data-id', videoId);
    script.setAttribute('data-partner', 's_MXzP7q');

    element.appendChild(script);
  }, []);

  // $FlowFixMe[incompatible-type]
  return <View ref={ref} style={styles.container} />;
}

export default StudyflixVideo;
