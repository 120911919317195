// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, query, select } from 'libs/graphql';

const NewMembership = {
  name: 'NewMembership',
  bundle: () => import('./NewMembership'),
};

const ORGANIZATION_QUERY = gql`
  query ThreadList($slug: String!) {
    organization(slug: $slug) {
      viewerIsAdmin
      viewerIsTrainer
    }
  }
`;

const Container = createAsyncContainer(NewMembership, (props) => {
  const {
    params: { slug },
  } = props;

  const variables = { slug };

  const loaders = {
    query: query(ORGANIZATION_QUERY, { variables }),
  };

  const selectors = {
    organization: select(ORGANIZATION_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
