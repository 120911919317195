// @flow
import React from 'react';
import { View, Text, Modal, Button, Grid, Heading, CloseButton } from 'components/core';
import { Icon } from 'components/common';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { useToggleState } from 'libs/misc';

type Props = {
  modal: ToggleState,
};

const styles = StyleSheet.create({
  darken: css`
    background-color: $gray-200;
  `,
  gridBox: css`
    flex-grow: 1;
    flex-shrink: 1;
  `,
  gridBoxDesktop: css`
    height: 4rem;
  `,
  gridBoxMobile: css`
    height: 3rem;
  `,
  buttonDesktop: css`
    height: 4rem;
    width: 100%;
  `,
  buttonMobile: css`
    height: 3rem;
    width: 100%;
  `,
  iconDesktop: css`
    font-size: 2rem;
    margin: 0.5rem;
    color: $gray-900;
  `,
  iconMobile: css`
    font-size: 1.5rem;
    margin: 0.5rem;
    color: $gray-900;
  `,
  text: css`
    font-size: 1rem;
    color: $gray-900;
  `,
});

function WhatsNewModal({ modal }: Props): React$Node {
  const media = useMedia();
  const user = useToggleState(true);
  const trainer = useToggleState(false);

  return (
    <>
      <Modal visible={modal.on} onToggle={modal.toggle} size="lg">
        <Modal.Header styleName="flex-row">
          <Modal.Title>
            <Text>Was ist neu?</Text>
          </Modal.Title>
          <CloseButton onPress={modal.toggle} />
        </Modal.Header>
        <Modal.Body styleName="p-0">
          <Grid styleName="p-0 m-0">
            <Grid.Box
              size={12}
              sizeMd={6}
              style={[
                styles.gridBox,
                media.up('md') ? styles.gridBoxDesktop : styles.gridBoxMobile,
                user.on && styles.darken,
              ]}
              styleName="p-0 m-0"
            >
              <Button
                color="light"
                onPress={() => {
                  user.setOn();
                  trainer.setOff();
                }}
                style={[
                  styles.gridBox,
                  media.up('md') ? styles.buttonDesktop : styles.buttonMobile,
                ]}
              >
                <View styleName="flex-row ai-center jc-center">
                  <Icon
                    glyph="smile"
                    style={[
                      styles.gridBox,
                      media.up('md') ? styles.iconDesktop : styles.iconMobile,
                    ]}
                  />
                  <Text style={styles.text}>Für Nutzer/-innen</Text>
                </View>
              </Button>
            </Grid.Box>
            <Grid.Box
              size={12}
              sizeMd={6}
              style={[
                styles.GridBox,
                media.up('md') ? styles.GridBoxDesktop : styles.GridBoxMobile,
                trainer.on && styles.darken,
              ]}
              styleName="p-0 m-0"
            >
              <Button
                color="light"
                onPress={() => {
                  user.setOff();
                  trainer.setOn();
                }}
                style={[
                  styles.gridBox,
                  media.up('md') ? styles.buttonDesktop : styles.buttonMobile,
                ]}
              >
                <View styleName="flex-row ai-center jc-center">
                  <Icon
                    glyph="graduation-cap"
                    style={[
                      styles.gridBox,
                      media.up('md') ? styles.iconDesktop : styles.iconMobile,
                    ]}
                  />
                  <Text style={styles.text}>Für Trainer/-innen</Text>
                </View>
              </Button>
            </Grid.Box>
          </Grid>
          <View styleName="p-3">
            {user.on && (
              <>
                <Heading size={6}>Lernlisten</Heading>
                <Text small color="muted">
                  26.09.2021
                </Text>
                <Text styleName="pt-2 pb-4">
                  Eure Ausbilder/-innen können euch jetzt Lernlisten zuordnen. Diese Lernlisten
                  bestehen aus To-dos - das können Prüfungen, Lernkarten-Sets, Videos und Artikel
                  sein. Eure Ausbilder/-innen können Deadlines zur Bearbeitung setzen. Ihr habt
                  durch die Lernlisten einen durchstrukturierten Plan zum Lernen. Viel Erfolg!
                </Text>
                <Heading size={6}>Umfragen / Erfahrungsberichte</Heading>
                <Text small color="muted">
                  26.08.2021
                </Text>
                <Text styleName="pt-2 pb-4">
                  Während der vergangenen Sommerprüfungen haben wir erstmals Umfragen gestellt. In
                  diesen Umfragen konntet ihr eure Erfahrungen zu euren Prüfungen teilen. Eure
                  Nachfolger haben den Vorteil, dass sie eure Erfahrungen für ihre eigene
                  Prüfungsvorbereitung nutzen können. Zur kommenden Prüfung werden wir das Ganze
                  wiederholen und auch wieder Gutscheine unter allen verlosen, die teilnehmen!
                </Text>
                <Heading size={6}>Video- und Artikel-Tab</Heading>
                <Text small color="muted">
                  03.05.2021
                </Text>
                <Text styleName="pt-2 pb-4">
                  Wir haben die Kurse nun noch übersichtlicher gestaltet. Wenn dein Kurs Videos
                  enthält, findest du diese in einem eigenen Tab. Außerdem haben wir die Wikis
                  aufgeräumt und zu Artikeln gemacht. Du kannst jetzt einzelne Artikel direkt von
                  der Übersicht aus abrufen.
                </Text>
                <Heading size={6}>Lernvideos</Heading>
                <Text small color="muted">
                  23.04.2021
                </Text>
                <Text styleName="pt-2 pb-4">
                  Vielen von euch ist es bestimmt schon aufgefallen: Es gibt mehr und mehr
                  Lernvideos auf Azubinet. In den kommenden Wochen und Monaten werden wir weitere
                  Lernvideos mit dazugehörigen Aufgabenblättern für viele Prüfungsthemen und
                  Ausbildungsberufe veröffentlichen.
                </Text>
                <Heading size={6}>Lösungshinweise</Heading>
                <Text small color="muted">
                  13.01.2021
                </Text>
                <Text styleName="pt-2 pb-4">
                  Bei Prüfungsaufgaben und Lernkarten gibt es jetzt die Option
                  &bdquo;Lösungshinweise&ldquo;. Wenn du eigene Materialien erstellst, kannst du
                  ebenfalls Lösungshinweise hinzufügen.
                </Text>
                <Heading size={6}>Lernvideos</Heading>
                <Text small color="muted">
                  11.12.2020
                </Text>
                <Text styleName="pt-2 pb-4">
                  Jetzt lassen sich Lernvideos abspielen. Im Mathe-Basics-Kurs gibt es bereits die
                  ersten kostenlosen Lernvideos. In den kommenden Monaten folgen viele weitere
                  Videos zu verschiedenen Themen!
                </Text>
                <Heading size={6}>Lernstatistiken</Heading>
                <Text small color="muted">
                  20.11.2020
                </Text>
                <Text styleName="pt-2 pb-4">
                  Im Kurs gibt es jetzt einen neuen Bereich Lern-Statistiken. Du erreichst ihn über
                  das Icon rechts neben dem Forum. Dort hast du alle Prüfungen und
                  Lernkarten-Quizzes, die du gemacht hast, im Blick.
                </Text>
                <Heading size={6}>Deine Info-Box</Heading>
                <Text styleName="py-2">
                  Hier informieren wir dich über neue Lerninhalte, Funktionen und Möglichkeiten auf
                  der Lernplattform.
                </Text>
              </>
            )}
            {trainer.on && (
              <>
                <Heading size={6}>Lernlisten</Heading>
                <Text small color="muted">
                  26.09.2021
                </Text>
                <Text styleName="pt-2 pb-4">
                  Ordnen Sie Ihren Auszubildenden Lernlisten zu! So haben Ihre Azubis die
                  Möglichkeit, strukturierter und übersichtlicher zu lernen. Lernlisten bestehen aus
                  To-dos wie Prüfungen, Lernkarten-Sets oder Videos. Sie können Ihren Azubis
                  Deadlines zur Bearbeitung setzen und den Lernfortschritt verfolgen.
                </Text>
                <Heading size={6}>Umfragen / Erfahrungsberichte</Heading>
                <Text small color="muted">
                  26.08.2021
                </Text>
                <Text styleName="pt-2 pb-4">
                  Sie können in Ihren eigenen Kursen nun Umfragen erstellen. Außerdem haben Ihre
                  Auszubildenden die Möglichkeit, an den öffentlichen Umfragen von Azubinet
                  teilzunehmen, wenn Ihre individuellen Zugangseinstellungen den Beitritt zu den
                  öffentlichen Azubinet-Kursen zulassen.
                </Text>
                <Heading size={6}>Kurs-Einstellungen überarbeitet</Heading>
                <Text small color="muted">
                  03.05.2021
                </Text>
                <Text styleName="pt-2 pb-4">
                  Wir haben die Kurs-Einstellungen übersichtlicher gestaltet. Jetzt haben Sie die
                  Möglichkeit, die Tabs Videos, Artikel und Dokumente ein- und auzuschalten - je
                  nachdem, was Sie in Ihren Kursen benötigen.
                </Text>
                <Heading size={6}>Überarbeitete Nutzerverwaltung</Heading>
                <Text small color="muted">
                  11.02.2021
                </Text>
                <Text styleName="pt-2 pb-4">
                  Sie können nun in Ihrer Nutzerverwaltung zusätzliche Einstellungen vornehmen. Wenn
                  Sie Nutzer per E-Mail eingeladen haben, sehen Sie den Status der Aktivierung und
                  können ggf. eine erneute Einladung per E-Mail an den Nutzer zur Verifizierung
                  versenden. Wenn Sie auf den Namen des Nutzers tippen, kommen Sie zu dessen
                  Profilseite. Dort sehen Sie alle Infos auf einen Blick. Zeitnah können Sie an
                  dieser Stelle auch die Lernstatistiken des Nutzers einsehen, dessen Account
                  deaktivieren bzw. löschen und bei Nutzern, die über keine eigene E-Mail-Adresse
                  verfügen, das Passwort ändern.
                </Text>
                <Heading size={6}>Lösungshinweise &amp; Kurskategorien</Heading>
                <Text small color="muted">
                  13.01.2021
                </Text>
                <Text styleName="pt-2 pb-4">
                  Bei Prüfungsaufgaben und Lernkarten gibt es jetzt die Option
                  &bdquo;Lösungshinweise&ldquo;. Wenn Sie eigene Materialien erstellen, können Sie
                  diesen ebenfalls Lösungshinweise hinzufügen. Außerdem können Sie nun über die
                  Kurseinstellungen &bdquo;Kurskategorien&ldquo; hinzufügen. Aufgaben, Karteikarten,
                  Dokumente und Wikis lassen sich dann diesen Kurskategorien zuordnen, um eine
                  bessere Übersicht zu schaffen.
                </Text>
                <Heading size={6}>Lernvideos</Heading>
                <Text small color="muted">
                  11.12.2020
                </Text>
                <Text styleName="pt-2 pb-4">
                  Jetzt lassen sich Lernvideos abspielen. Im Mathe-Basics-Kurs gibt es bereits die
                  ersten kostenlosen Lernvideos. In den kommenden Monaten folgen viele weitere
                  Videos zu verschiedenen Themen!
                </Text>
                <Heading size={6}>Domain-Einstellungen</Heading>
                <Text small color="muted">
                  10.12.2020
                </Text>
                <Text styleName="pt-2 pb-4">
                  Wenn Sie eine eigene Subdomain wie abc.azubinet.de haben, können Sie jetzt über
                  die Verwaltung bzw. Administration verschiedene Einstellungen vornehmen. So können
                  Sie z.B. das Logo ändern, das Online-Berichtsheft ein- und ausschalten und
                  auswählen, ob Ihre Auszubildenden eigene Kurse erstellen dürfen.
                </Text>
                <Heading size={6}>Lernstatistiken</Heading>
                <Text small color="muted">
                  20.11.2020
                </Text>
                <Text styleName="pt-2 pb-4">
                  Sie können nun die Lernstatistiken Ihrer Kurs-Mitglieder einsehen. Diese erreichen
                  Sie über das Statistik-Icon neben den Kurs-Einstellungen. Dort haben Sie Einblick
                  in alle Prüfungsdurchläufe und Lernkarten-Quizzes Ihrer Kurs-Mitglieder. Diese
                  Option gilt nur für Kurse, die zu Ihrer Organisation gehören - für Kurse, die
                  keiner Organisation gehören, kann nur jedes Kurs-Mitglied für sich selbst diese
                  Statistiken einsehen. Dies gilt z.B. auch für von Auszubildenden erstellte private
                  Kurse.
                </Text>
                <Heading size={6}>Ihre Info-Box</Heading>
                <Text styleName="py-2">
                  Hier informieren wir Sie über neue Funktionen und Möglichkeiten für Trainer/-innen
                  und Ausbilder/-innen - etwa bei neuen Funktionen als Kurs-Administrator/-in oder
                  bei der Verwaltung von Nutzern und Kursen.
                </Text>
              </>
            )}
          </View>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default WhatsNewModal;
