// @flow
import React from 'react';
import { Text, Nav } from 'components/core';
import { ActivityIcon, Icon } from 'components/common';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  item: Entity<GQLChecklistTodoCustom>,
};

const styles = StyleSheet.create({
  indented: css`
    padding-horizontal: 0.75rem;
  `,
  todoIcon: css`
    margin-right: 0.75rem;
  `,
  checked: css`
    color: $success;
  `,
  unchecked: css`
    color: transparent;
  `,
});

function ChecklistTodoCustom({ item }: Props): React$Node {
  return (
    <Nav.Link style={styles.indented} styleName="flex-row ai-center" disabled>
      <ActivityIcon glyph="circle" size="sm" style={styles.todoIcon} />
      <Text small styleName="flex-grow-1 flex-shrink-1">
        {item.title}
      </Text>
      <Icon
        glyph="ok-squared"
        style={item.viewerResult ? styles.checked : styles.unchecked}
        styleName="ml-2"
      />
    </Nav.Link>
  );
}

export default ChecklistTodoCustom;
