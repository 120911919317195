// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query, selectByRoot } from 'libs/graphql';

const MembershipDetails = {
  name: 'MembershipDetails',
  bundle: () => import('./MembershipDetails'),
};

const ORGANIZATION_MEMBERSHIP_DETAILS_QUERY = gql`
  query OrganizationMembershipDetailsQuery($slug: String!, $membershipId: ClientID!) {
    organization(slug: $slug) {
      name
      city
      description
      membership(membershipId: $membershipId) {
        role
        ownsUser
        user {
          name
          emails {
            email
          }
          username
          firstName
          lastName
          phone
          state
          selfRegistered
          avatar {
            file {
              name
              extension
              info {
                image {
                  height
                  width
                }
              }
            }
            largeFile {
              name
              extension
              info {
                image {
                  height
                  width
                }
              }
            }
          }
          teamMemberships {
            team {
              slug
              name
            }
          }
          courseMemberships(withTeams: true) {
            quizCardResultsCount
            quizCardResultsCorrectCount
            quizCardResultsWrongCount
            averageQuizProgress
            averageExamAttemptResult
            examAttemptsCount
            course {
              slug
              name
              caption
            }
          }
          licenseAssignments {
            license {
              validUntil
              product {
                name
                courses {
                  slug
                  name
                  caption
                }
              }
            }
          }
          checklistResults {
            slug
            checklist {
              name
              slug
              todosCount
            }
            todoResultsCount
            doneUntil
          }
          lastSeenAt
          createdAt
        }
        trainers {
          user {
            name
            avatar {
              file {
                name
                extension
                info {
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
        }
        trainees {
          user {
            name
            avatar {
              file {
                name
                extension
                info {
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }
      viewerIsAdmin
      viewerIsMember
    }
  }
`;

const Container = createAsyncContainer(MembershipDetails, (props) => {
  const {
    params: { slug, membershipId },
  } = props;

  const variables = { slug, membershipId };

  const loaders = {
    query: query(ORGANIZATION_MEMBERSHIP_DETAILS_QUERY, { variables }),
  };

  const selectors = {
    organization: select(ORGANIZATION_MEMBERSHIP_DETAILS_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
