// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const DOCUMENT_FILE_FRAGMENT: DocumentNode = gql`
  fragment DocumentFileFragment on DocumentFile {
    file {
      name
      encodedName
      extension
      info {
        size
        mimeType
        image {
          height
          width
        }
      }
    }
  }
`;
