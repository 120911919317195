// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const InvitationList = {
  name: 'InvitationList',
  bundle: () => import('./InvitationList'),
};

const ORGANIZATION_MEMBERSHIPS_QUERY = gql`
  query OrganizationMembershipsQuery($slug: String!) {
    organization(slug: $slug) {
      slug
      invitations {
        email
        acceptedAt
        role
        user {
          name
        }
        createdAt
      }
      viewerIsAdmin
      viewerIsTrainer
    }
  }
`;

const Container = createAsyncContainer(InvitationList, (props) => {
  const {
    params: { slug },
  } = props;

  const variables = { slug };

  const loaders = {
    query: query(ORGANIZATION_MEMBERSHIPS_QUERY, { variables }),
  };

  const selectors = {
    organization: select(ORGANIZATION_MEMBERSHIPS_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
