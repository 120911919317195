// @flow
import React from 'react';
import { Link } from 'components/core';

type Props = {
  team: Entity<GQLOrganizationTeam>,
  announcementId: Id,
};

function LinkToOrganizationTeam({ team, announcementId }: Props): React$Node {
  return (
    <Link
      to={`/organizations/${team.organization.slug}/teams/${team.slug}?announcement_id=${announcementId}`}
    >
      {team.name}
    </Link>
  );
}

export default LinkToOrganizationTeam;
