// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';
import { DOMAIN_QUERY } from 'libs/foundation/queries';

const Profile = {
  name: 'Profile',
  bundle: () => import('./Profile'),
};

const MYPROFILE_QUERY = gql`
  query MyProfileQuery {
    viewer {
      firstName
      lastName
      organization
      organizationMemberships {
        organization {
          name
          slug
        }
      }
      avatar {
        largeFile {
          name
          extension
          info {
            image {
              height
              width
            }
          }
        }
      }
      createdAt
    }
  }
`;

const Container = createAsyncContainer(Profile, () => {
  const loaders = {
    query: query(MYPROFILE_QUERY),
  };

  const selectors = {
    viewer: select(MYPROFILE_QUERY),
    domain: select(DOMAIN_QUERY, {}),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
