// @flow
import { useAndroidBackButton } from 'libs/routing';
import routes from '../../../../routes';

function Routes(): React$Node {
  useAndroidBackButton();

  return routes();
}

export default Routes;
