// @flow
import React from 'react';
import { ButtonGroup } from 'components/core';

type Props = {
  children: React$Node,
};

function TaskChoiceButtonGroup({ children }: Props): React$Node {
  return <ButtonGroup styleName="flex-row my-2">{children}</ButtonGroup>;
}

export default TaskChoiceButtonGroup;
