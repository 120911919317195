// @flow
import React from 'react';
import { Row } from 'bootstrap-rn';
import { StyleSheet, css } from 'libs/ui';
import GridBox from './GridBox';

type Props = {
  children: React$Node,
  style?: any,
};

const styles = StyleSheet.create({
  grid: css`
    @include media-breakpoint-down(xs) {
      margin: 0;
    }
  `,
});

const Grid = React.forwardRef<Props, any>((props, ref) => {
  const { children, style, ...elementProps } = props;

  return (
    <Row {...elementProps} ref={ref} style={[styles.grid, style]}>
      {children}
    </Row>
  );
});

Grid.displayName = 'Grid';

// $FlowFixMe[prop-missing]
Grid.Box = GridBox;

export default (Grid: any);
