// @flow
import React from 'react';
import { createDict, DateTime, Trans } from 'libs/intl';
import { ActivityIcon, Divider, Icon, OptionButton } from 'components/common';
import { View, Nav, Button, Text, Progress, Badge } from 'components/core';
import { useMenu } from 'libs/foundation';
import { StyleSheet, css } from 'libs/ui';
import ChecklistTodoCustom from './ChecklistTodoCustom';
import ChecklistTodoCourseActivity from './ChecklistTodoCourseActivity';

type Props = {
  checklistResult: Entity<GQLChecklistResult>,
  expanded: ToggleState,
  onClose: () => void,
};

const styles = StyleSheet.create({
  checklistIconWrapper: css`
    background-color: $transparent-200;
    width: 2rem;
    height: 2rem;
    border-radius: 0.25rem;
    margin-right: 0.75rem;
  `,
  checklistIcon: css`
    text-align: center;
    color: $gray-700;
    flex-shrink: 0;
    width: 2rem;
    height: 2rem;
    font-size: 1.25rem;
    line-height: 2rem;
  `,
  todoIcon: css`
    margin-right: 0.75rem;
  `,
  indented: css`
    padding-horizontal: 0.75rem;
  `,
  progress: css`
    height: 0.5rem;
    background-color: $transparent-100;
  `,
  dirIcon: css`
    width: 1rem;
    align-items: center;
  `,
});

const dict = createDict({
  back: 'Back',
  x_todos: '{count} {count, plural, =1 {todo} other {todos}}',
  deadline: 'Deadline',
  no_deadline: 'No deadline',
  todos: 'Todos',
  inaccessible_course_item: 'Inaccessible course item',
});

const isOverDue = (checklist, checklistResult) => {
  if (!checklistResult) {
    return null;
  }

  const now = new Date();
  const notComplete = checklistResult.todoResultsCount < checklist.todosCount;
  return checklistResult.doneUntil && notComplete
    ? new Date(checklistResult.doneUntil) <= now
    : false;
};

function MenuChecklistDetails({
  checklistResult,
  expanded,
  onClose: handleClose,
}: Props): React$Node {
  const menu = useMenu();

  const { checklist } = checklistResult;

  return (
    <View>
      <Button color="light" onPress={handleClose} styleName="jc-start">
        <Icon glyph="left-circled" styleName="mr-2" />
        <Trans {...dict('back')} />
      </Button>
      <Divider />
      <Nav variant="pills" styleName="flex-column mb-3">
        <Nav.Link
          to={`/checklists/${checklistResult.checklist.slug}?result_id=${checklistResult.id}`}
          onPress={() => {
            menu.clear();
          }}
          style={styles.indented}
          styleName="flex-column ai-stretch"
        >
          <View styleName="flex-row ai-center">
            <View style={styles.checklistIconWrapper}>
              <Icon glyph="checklist" style={styles.checklistIcon} />
            </View>
            <View styleName="flex-shrink-1">
              <Text>{checklistResult.checklist.name}</Text>
              <Trans
                {...dict('x_todos', {
                  count: checklist.todosCount,
                })}
                color="muted"
                small
              />
            </View>
          </View>
          <View styleName="flex-grow-1 my-1">
            <View styleName="flex-row jc-between my-1">
              <View styleName="flex-grow-1 flex-shrink-1">
                <Text
                  color={isOverDue(checklistResult.checklist, checklistResult) ? 'danger' : 'muted'}
                  small
                >
                  {checklistResult.doneUntil ? (
                    <DateTime value={checklistResult.doneUntil} />
                  ) : (
                    <Trans {...dict('no_deadline')} />
                  )}
                </Text>
              </View>
              <View styleName="flex-row ai-center ml-2">
                <Text small>
                  {checklistResult.todoResultsCount}/{checklist.todosCount}
                </Text>
              </View>
            </View>
            <Progress style={styles.progress}>
              <Progress.Bar
                value={(checklistResult.todoResultsCount / checklist.todosCount) * 100}
                styleName={
                  checklistResult.todoResultsCount === checklist.todosCount
                    ? 'bg-success'
                    : 'bg-primary'
                }
              />
            </Progress>
          </View>
        </Nav.Link>
      </Nav>
      <OptionButton onPress={expanded.toggle} styleName="jc-start">
        <View style={styles.dirIcon}>
          {expanded.on ? <Icon glyph="down-dir" /> : <Icon glyph="right-dir" />}
        </View>
        <Trans {...dict('todos')} bold styleName="ml-2" />
        <Badge color="light" styleName="ml-1">
          <Text>{checklist.todosCount}</Text>
        </Badge>
      </OptionButton>
      {expanded.on && (
        <Nav variant="pills" styleName="flex-column">
          {checklistResult.checklist.todos.map((todo) => (
            <React.Fragment key={todo.id}>
              {todo.item && todo.item.__typename === 'ChecklistTodoCustom' && (
                <ChecklistTodoCustom id={todo.item.id} todo={todo} />
              )}
              {todo.item && todo.item.__typename !== 'ChecklistTodoCustom' && (
                <ChecklistTodoCourseActivity
                  activityId={todo.item.id}
                  activityType={todo.item.__typename}
                  todo={todo}
                />
              )}
              {!todo.item && (
                <Nav.Link style={styles.indented} styleName="flex-row ai-center" disabled>
                  <ActivityIcon size="sm" style={styles.todoIcon} />
                  <Trans {...dict('inaccessible_course_item')} color="muted" italic small />
                </Nav.Link>
              )}
            </React.Fragment>
          ))}
        </Nav>
      )}
    </View>
  );
}

export default MenuChecklistDetails;
