// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';

const NewCourse = {
  name: 'NewCourse',
  bundle: () => import('./NewCourse'),
};

const COURSE_CREATE_QUERY = gql`
  query CourseCreateQuery {
    viewer {
      organizationMemberships {
        organization {
          name
          emailDomain
          viewerIsAdmin
          viewerIsTrainer
        }
      }
    }
  }
`;

const Container = createAsyncContainer(NewCourse, () => {
  const selectors = {
    viewer: select(COURSE_CREATE_QUERY, {}),
  };

  const loaders = {
    query: query(COURSE_CREATE_QUERY, {}),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
