// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { Badge } from 'components/core';

type Props = {
  children: React$Node,
  style?: any,
  textStyle?: any,
};

const styles = StyleSheet.create({
  badge: css`
    padding: 0;
    min-width: 2rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
  `,
  badgeText: css`
    font-size: 1rem;
    line-height: 1.5rem;
  `,
});

const LargeBadge = React.forwardRef<Props, any>((props, ref) => {
  const { children, style, textStyle, ...elementProps } = props;

  return (
    <Badge
      {...elementProps}
      ref={ref}
      style={[styles.badge, style]}
      textStyle={[styles.badgeText, textStyle]}
    >
      {children}
    </Badge>
  );
});

export default (LargeBadge: React$AbstractComponent<Props, any>);
