// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';

const Report = {
  name: 'Report',
  bundle: () => import('./Report'),
};

const REPORT_QUERY = gql`
  query ViewerReportQuery($reportId: ClientID!) {
    viewer {
      activeReportCollection {
        type
        report(reportId: $reportId) {
          beginAt
          endAt
          completed
          approved
          userNote
          trainerNote
          createdAt
          entries {
            date
            category
            status
            activities {
              text
              duration
            }
          }
        }
      }
    }
  }
`;

const Container = createAsyncContainer(Report, (props) => {
  const {
    params: { reportId },
  } = props;

  const loaders = {
    query: query(REPORT_QUERY, { variables: { reportId } }),
  };

  const selectors = {
    viewer: select(REPORT_QUERY, { variables: { reportId } }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
