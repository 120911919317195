// @flow
import { gql, createMutation } from 'libs/graphql';

const CREATE_ANNOUNCEMENT = gql`
  mutation CreateAnnouncement($input: AnnouncementCreateInput!) {
    announcement_create(input: $input) {
      text
      createdAt
      announceable {
        ... on Course {
          name
          slug
        }
        ... on Organization {
          name
          slug
        }
        ... on OrganizationTeam {
          name
          slug
          organization {
            slug
          }
        }
      }
      author {
        name
        avatar {
          file {
            name
            extension
            info {
              image {
                height
                width
              }
            }
          }
        }
        createdAt
      }
    }
  }
`;

export function createAnnouncement(
  announceableId: Id,
  announceableType: string,
  text: string,
): MutationAction {
  return createMutation(CREATE_ANNOUNCEMENT, {
    variables: {
      input: {
        announceableId,
        announceableType,
        text,
      },
    },
    updater: (store, data) => {
      // $FlowFixMe[incompatible-call]
      store.update(announceableType, announceableId, (record) => {
        record.set('announcements', (links) => links.prepend(data.roots.announcement_create));
      });
    },
  });
}

const UPDATE_ANNOUNCEMENT = gql`
  mutation UpdateAnnouncement($id: ClientID!, $input: AnnouncementUpdateInput!) {
    announcement_update(id: $id, input: $input) {
      text
    }
  }
`;

export function updateAnnouncement(id: Id, text: string): MutationAction {
  return createMutation(UPDATE_ANNOUNCEMENT, {
    variables: {
      id,
      input: {
        text,
      },
    },
  });
}

const DELETE_ANNOUNCEMENT = gql`
  mutation DeleteAnnouncement($id: ClientID!) {
    announcement_delete(id: $id)
  }
`;

export function deleteAnnouncement(
  id: Id,
  announceableId: Id,
  announceableType: string,
): MutationAction {
  return createMutation(DELETE_ANNOUNCEMENT, {
    variables: {
      id,
    },
    updater: (store) => {
      // $FlowFixMe[incompatible-call]
      store.update(announceableType, announceableId, (record) => {
        record.set('announcements', (links) => links.detach('Announcement', id));
      });
    },
  });
}
