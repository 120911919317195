// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const SURVEY_ACTIVITY_CAPTION_FRAGMENT: DocumentNode = gql`
  fragment SurveyActivityCaptionFragment on SurveyActivity {
    version(specificVersion: "CURRENT") {
      item {
        viewerIsDone
        questionsCount
        createdAt
        endedAt
      }
    }
  }
`;
