// @flow
import { gql, generateId, Link, createMutation } from 'libs/graphql';

type Commentable = 'Course' | 'TaskIntro' | 'TaskPart' | 'OpenQuestionSolution';

const THREAD_POST_FRAGMENT = gql`
  fragment ThreadPostFragment on ThreadPost {
    text
    viewerReaction {
      value
    }
    reactionsThumbUpCount
    reactionsThumbDownCount
    author {
      name
    }
    viewerCanUpdate
    viewerCanDelete
    updatedAt
    createdAt
  }
`;

// Thread mutations

const CREATE_THREAD = gql`
  mutation CreateThread($input: ThreadCreateInput!, $postId: ClientID) {
    thread_create(input: $input) {
      slug
      opening {
        ...ThreadPostFragment
      }
      post(id: $postId) {
        ...ThreadPostFragment
      }
      replies {
        ...ThreadPostFragment
      }
      author {
        name
      }
      updatedAt
      createdAt
    }
  }

  ${THREAD_POST_FRAGMENT}
`;

export function createThread(
  courseId: Id,
  itemType: ?Commentable,
  itemId: ?Id,
  title: ?string,
  text: string,
  selectedPostId: ?Id,
): MutationAction {
  return createMutation(CREATE_THREAD, {
    variables: {
      input: {
        courseId,
        itemType,
        itemId,
        title,
        text,
      },
      postId: selectedPostId,
    },
    updater: (store, data) => {
      // The thread is a topic in the forum, update the topicsCount field.
      if (!itemType && !itemId) {
        store.update('Course', courseId, (record) => {
          record.set('topics', (links) => links.detach(data.roots.thread_create));
          record.set('topicsCount', (value) => value + 1);
        });
      }

      // The thread is a comment, so update the related item.
      if (itemType && itemId) {
        // $FlowFixMe[speculation-ambiguous]
        store.update(itemType, itemId, (record) => {
          record.set('comments', (links) => links.prepend(data.roots.thread_create));
        });
      }
    },
  });
}

const DELETE_THREAD = gql`
  mutation DeleteThread($id: ClientID!) {
    thread_delete(id: $id)
  }
`;

export function deleteThread(
  id: Id,
  courseId: Id,
  itemType: ?Commentable,
  itemId: ?Id,
): MutationAction {
  return createMutation(DELETE_THREAD, {
    variables: {
      id,
    },
    updater: (store) => {
      // The thread is a topic in the forum, update the topicsCount field.
      if (!itemType && !itemId) {
        store.update('Course', courseId, (record) => {
          record.set('topics', (links) => links.detach('Thread', id));
          record.set('topicsCount', (value) => value - 1);
        });
      }

      // The thread is a comment, so update the related item.
      if (itemType && itemId) {
        // $FlowFixMe[speculation-ambiguous]
        store.update(itemType, itemId, (record) => {
          record.set('comments', (links) => links.detach('Thread', id));
        });
      }
    },
  });
}

// Thread post mutations

const CREATE_POST = gql`
  mutation CreateThreadPost($input: ThreadPostCreateInput!) {
    threadPost_create(input: $input) {
      ...ThreadPostFragment
    }
  }

  ${THREAD_POST_FRAGMENT}
`;

export function createPost(threadId: Id, text: string): MutationAction {
  return createMutation(CREATE_POST, {
    variables: {
      input: {
        threadId,
        text,
      },
    },
    updater: (store, data) => {
      store.update('Thread', threadId, (record) => {
        record.set('replies', (links) => links.append(data.roots.threadPost_create));
      });
    },
  });
}

const DELETE_POST = gql`
  mutation DeleteThreadPost($id: ClientID!) {
    threadPost_delete(id: $id)
  }
`;

export function deletePost(threadId: Id, postId: Id): MutationAction {
  return createMutation(DELETE_POST, {
    variables: {
      id: postId,
    },
    updater: (store) => {
      store.update('Thread', threadId, (record) => {
        record.set('replies', (links) => links.detach('ThreadPost', postId));
      });
    },
  });
}

const UPDATE_POST = gql`
  mutation UpdatePost($id: ClientID!, $input: ThreadPostUpdateInput!) {
    threadPost_update(id: $id, input: $input) {
      text
    }
  }
`;

export function updatePost(postId: Id, text: string): MutationAction {
  return createMutation(UPDATE_POST, {
    variables: {
      id: postId,
      input: {
        text,
      },
    },
  });
}

// Thread post reaction mutations

const PUT_REACTION = gql`
  mutation PutReaction($input: ThreadPostReactionPutInput!) {
    threadPostReaction_put(input: $input) {
      value
    }
  }
`;

const setValue = (value) => (record) => {
  record.set('value', value);
};

const updateCounters = (currentValue, value) => (record) => {
  if (currentValue != null) {
    record.fill({
      reactionsThumbUpCount: (count) => count - (currentValue === 'THUMB_UP' ? 1 : 0),
      reactionsThumbDownCount: (count) => count - (currentValue === 'THUMB_DOWN' ? 1 : 0),
    });
  }
  if (value != null) {
    record.fill({
      reactionsThumbUpCount: (count) => count + (value === 'THUMB_UP' ? 1 : 0),
      reactionsThumbDownCount: (count) => count + (value === 'THUMB_DOWN' ? 1 : 0),
    });
  }
};

const updateViewerReaction = (link) => (record) => {
  record.set('viewerReaction', link);
};

export function putReaction(
  postId: Id,
  reaction: ?Entity<GQLThreadPostReaction>,
  value: ?('THUMB_UP' | 'THUMB_DOWN'),
): MutationAction {
  const currentValue = reaction ? reaction.value : null;

  return createMutation(PUT_REACTION, {
    variables: {
      input: {
        postId,
        value,
      },
    },
    optimisticUpdater: (store) => {
      // Create new reaction and link it.
      if (reaction == null && value != null) {
        const reactionId = generateId();
        store.insert('ThreadPostReaction', reactionId, setValue(value));

        const link = new Link('ThreadPostReaction', reactionId);
        store.update('ThreadPost', postId, updateViewerReaction(link));
      }

      // Update existing reaction.
      else if (reaction != null && value != null) {
        store.update('ThreadPostReaction', reaction.id, setValue(value));
      }

      // Unlink reaction if reaction exists, but new value is null.
      else if (reaction != null && value == null) {
        store.update('ThreadPost', postId, updateViewerReaction(new Link()));
      }

      // Update counters.
      store.update('ThreadPost', postId, updateCounters(currentValue, value));
    },
    updater: (store, data) => {
      // Update link.
      const link = new Link(data.roots.threadPostReaction_put);
      store.update('ThreadPost', postId, updateViewerReaction(link));

      // Update counters.
      store.update('ThreadPost', postId, updateCounters(currentValue, value));
    },
  });
}
