// @flow
import getValidationRule from './getValidationRule';
import handleAttributeValue from './handleAttributeValue';

const parseSchema = (rules) => {
  const schema = {};

  Object.keys(rules).forEach((attribute) => {
    const attributeRules = Array.isArray(rules[attribute])
      ? rules[attribute]
      : rules[attribute].split('|');

    schema[attribute] = attributeRules.map((rule) => {
      if (typeof rule === 'function') {
        return rule;
      }

      const [name, parametersAsString] = rule.split(':');

      if (!getValidationRule(name)) {
        throw new Error(`Validation rule ${name} does not exist.`);
      }

      const parameters = parametersAsString ? parametersAsString.split(',') : [];

      return { name, parameters };
    });
  });

  return schema;
};

export default function validateSchema(
  input: Object,
): (values: Object) => Object | Promise<Object> {
  const schema = parseSchema(input);

  return (values) => {
    const errors = {};
    const promises = [];

    const setError = (path, err) => {
      return path.reduce((acc, cur, i) => {
        if (i === path.length - 1) {
          acc[cur] = err;
        } else if (!acc[cur]) {
          acc[cur] = {};
        }
        return acc[cur];
      }, errors);
    };

    Object.entries(schema).forEach(([attribute, rules]) => {
      const handle = (path, value) => {
        // $FlowFixMe[incompatible-use]
        rules.forEach((rule) => {
          if (typeof rule === 'function') {
            const fail = (message) => {
              throw message;
            };

            try {
              rule(attribute, value, fail);
            } catch (err) {
              if (err instanceof Error) {
                throw err;
              }

              setError(path, err);
            }

            return;
          }

          const validate = getValidationRule(rule.name);
          const result = validate(value, rule.parameters, attribute, values);

          if (result === false) {
            setError(path, rule);
          }

          if (typeof result !== 'boolean') {
            promises.push(result);

            result.then((response) => {
              if (response.valid === false) {
                setError(path, rule);
              }
            });
          }
        });
      };

      handleAttributeValue(attribute, values, handle);
    });

    if (promises.length === 0) {
      return errors;
    }

    return Promise.all(promises).then(() => {
      return errors;
    });
  };
}
