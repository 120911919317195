// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const PAIRING_RESULT_FRAGMENT: DocumentNode = gql`
  fragment PairingResultFragment on Pairing {
    text
    type
    pieces {
      text
      key
      side
    }
    sampleSolution {
      pairs {
        left
        right
      }
      explanation
    }
    examAttemptSolution(slug: $slug, attemptId: $attemptId) {
      pairs {
        left
        right
      }
    }
  }
`;
