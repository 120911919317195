// @flow
import React from 'react';
import { View, Spinner } from 'components/core';
import { StyleSheet, css } from 'libs/ui';

const styles = StyleSheet.create({
  container: css`
    padding-top: 8rem;
    padding-bottom: 8rem;
    align-items: center;
    flex-grow: 1;
  `,
  spinner: css`
    color: $gray-800;
  `,
});

function Loading(): React$Node {
  return (
    <View style={styles.container}>
      <Spinner style={styles.spinner} />
    </View>
  );
}

export default Loading;
