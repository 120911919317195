// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import NotificationListLoading from './NotificationListLoading';
import NotificationList from './NotificationList';

export const VIEWER_NOTIFICATIONS_QUERY: DocumentNode = gql`
  query ViewerNotificationsQuery {
    viewer {
      unreadNotificationsCount
      notifications(first: 10) {
        type
        text
        url
        readAt
        updatedAt
        createdAt
      }
    }
  }
`;

const options = {
  async: {
    loading: NotificationListLoading,
  },
};

const Container = createAsyncContainer(
  NotificationList,
  () => {
    const loaders = {
      query: query(VIEWER_NOTIFICATIONS_QUERY),
    };

    const selectors = {
      viewer: select(VIEWER_NOTIFICATIONS_QUERY, {}),
    };

    return {
      loaders,
      selectors,
    };
  },
  options,
);

export default (Container: React$AbstractComponent<any, mixed>);
