// @flow
import React from 'react';
import { Section, Spacing, DevicesStripe } from 'components/pages';
import { Button } from 'components/core';
import { useDocumentTitle, useToggleState } from 'libs/misc';
import { createDict, Trans } from 'libs/intl';
import FreeCoursesInfoBox from 'views/_shared/FreeCoursesInfoBox';
import ProfessionsModal from 'views/_shared/ProfessionsModal';
import HeroSection from './HeroSection';
import TeaserVideo from '../../_shared/AzubinetTeaserVideo';
import PresentationSection from './PresentationSection';
import ApprenticesTutoringInfo from './ApprenticesTutoringInfo';
import FeaturesSection from './FeaturesSection';
import FavoritesSection from './FavoritesSection';
import CompaniesInfo from '../../_shared/AzubinetCompaniesInfo';
import StBKLink from '../../_shared/AzubinetStBKLink';
import SocialMedia from './SocialMedia';
import TestimonialsBoxTrainees from '../../_shared/AzubinetTestimonialsBoxTrainees';
import TopReferences from '../../_shared/TopReferences';

const dict = createDict({
  homepage:
    'Azubinet: Exam preparation for apprentices, e-learning for training companies, tutorial videos, flashcards, mock exams, ...',
  your_profession: 'Your profession',
});

function Azubinet(): React$Node {
  useDocumentTitle(dict('homepage'));
  const modal = useToggleState(false);

  return (
    <>
      <Section color="white">
        <HeroSection />
        <TeaserVideo />
        <Spacing />
        <TestimonialsBoxTrainees />
        <Spacing />
        <TopReferences />
        <Spacing />
        <Spacing />
        <FreeCoursesInfoBox>
          <Button onPress={modal.toggle} size="lg">
            <Trans {...dict('your_profession')} />
          </Button>
        </FreeCoursesInfoBox>
        <Spacing />
        <PresentationSection />
        <Spacing />
      </Section>
      <Section color="gray">
        <FeaturesSection />
      </Section>
      <Section color="white">
        <ApprenticesTutoringInfo />
        <Spacing />
        <FavoritesSection />
        <Spacing />
        <CompaniesInfo />
      </Section>
      <Section color="white">
        <StBKLink />
      </Section>
      <Section color="white">
        <SocialMedia />
      </Section>
      <Section color="gray">
        <DevicesStripe />
      </Section>
      <ProfessionsModal modal={modal} />
    </>
  );
}

export default Azubinet;
