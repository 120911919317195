// @flow
import { createAsyncContainer } from 'libs/container';

const Education = {
  name: 'Education',
  bundle: () => import('./Education'),
};

const Container = createAsyncContainer(Education, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
