// @flow
import React from 'react';
import { Container, Text, Image, View, Heading, Button, Link } from 'components/core';
import { Video } from 'components/common';
import { createDict, useIntl, Trans } from 'libs/intl';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { useDocumentTitle } from 'libs/misc';
import { Section, DevicesStripe, Spacing } from 'components/pages';
import SplashScreenBox from './SplashScreenBox';
import TeaserSection from './TeaserSection';
import FeaturesBoxContent from './FeaturesBoxContent';
import GridSectionBox from './GridSectionBox';

const styles = StyleSheet.create({
  fontColorRed: css`
    color: #e06450;
  `,
  fontColorBlue: css`
    color: #0084a6;
  `,
  backgroundDesktop: css`
    position: absolute;
    background-color: transparent;
    width: 100%;
    height: 41.3125rem;
    top: -0.25rem;
  `,
  backgroundMobile: css`
    position: absolute;
    background-color: transparent;
    top: 1.75rem;
    width: 100%;
    height: 18.125rem;
  `,
  whiteBackground: css`
    background-color: #fff;
  `,
  stretchedBackgroundDesktop: css`
    margin-top: -15rem;
    z-index: 99;
  `,
  stretchedBackgroundMobile: css`
    z-index: 99;
  `,
  whatIsExamunity: css`
    text-align: center;
  `,
  heading: css`
    text-align: center;
    margin-horizontal: 1rem;
  `,
  caption: css`
    color: $gray-700;
    margin: 1rem;
    text-align: center;
  `,
  apprenticeshipBox: css`
    justify-content: center;
    align-items: center;
    padding-bottom: 3rem;
  `,
  borderBox: css`
    border-radius: 0.125rem;
    border-width: 0.125rem;
    border-color: #0084a6;
  `,
  borderBoxDesktop: css`
    margin-horizontal: 0;
  `,
  borderBoxMobile: css`
    margin-horizontal: 1rem;
  `,
  azubinetLogo: css`
    width: 280px;
    height: 48px;
  `,
});

const dict = createDict({
  homepage:
    'E-Learning for Students, Apprentices, Companies, Teachers, Marketplace for Publishers & Authors',
  together_we_learn_more_successfully: 'Together We Learn More Successfully',
  your_social_media_lms: 'Your Social Media LMS',
  register: 'Register',
  our_new_platform_for_the_vocational_education: 'Our new platform for the vocational education',
  visit_now: 'Visit now',
  learn_more: 'Learn more',
});

function Examunity(): React$Node {
  useDocumentTitle(dict('homepage'));

  const media = useMedia();
  const intl = useIntl();

  return (
    <>
      <View style={styles.whiteBackground}>
        <SplashScreenBox />
        <View
          style={
            media.up('md') ? styles.stretchedBackgroundDesktop : styles.stretchedBackgroundMobile
          }
        >
          {media.up('md') && (
            <Image
              source="/img/bg-target-2.png"
              style={[styles.backgroundDesktop, { resizeMode: 'stretch' }]}
            />
          )}
          {media.down('sm') && (
            <Image
              source="/img/bg-target-3.png"
              style={[styles.backgroundMobile, { resizeMode: 'stretch' }]}
            />
          )}
          <Container>
            <TeaserSection />
            {intl.locale === 'en' && (
              <View>
                <Heading styleName="pt-5" size={2} style={styles.whatIsExamunity}>
                  <Text style={styles.fontColorRed}>
                    What is<Text style={styles.fontColorBlue}> Examunity?</Text>
                  </Text>
                </Heading>
                <GridSectionBox
                  content="Find online study courses for your courses or easily add your own and invite your classmates to study together"
                  image="/img/home/img-feat1.png"
                  title="Featured 1"
                  textPosition="left"
                />
                <GridSectionBox
                  content="Share and compare your results, vote on solutions and discuss on problems"
                  image="/img/home/img-feat2.png"
                  title="Featured 2"
                  textPosition="right"
                />
                <GridSectionBox
                  content="Use thousands of materials such as tests and flashcards, use the knowledge from other users shared and add your own experiences"
                  image="/img/home/img-feat3.png"
                  title="Featured 3"
                  textPosition="left"
                />
              </View>
            )}
            {intl.locale === 'de' && (
              <View>
                <Heading styleName="pt-5" size={2} style={styles.whatIsExamunity}>
                  <Text style={styles.fontColorRed}>
                    Was ist<Text style={styles.fontColorBlue}> Examunity?</Text>
                  </Text>
                </Heading>
                <GridSectionBox
                  content="Finde Kurse oder erstelle einfach deine eigenen und lerne zusammen mit deinen Kollegen"
                  image="/img/home/img-feat1.png"
                  title="Featured 1"
                  textPosition="left"
                />
                <GridSectionBox
                  content="Bereite dich zusammen mit deinen Klassenkameraden auf die nächste Klausur vor"
                  image="/img/home/img-feat2.png"
                  title="Featured 2"
                  textPosition="right"
                />
                <GridSectionBox
                  content="Lerne mit tausenden Aufgaben, Lösungen, Tests und Karteikarten, teile dein Wissen und nutze das Wissen deiner Vorgänger"
                  image="/img/home/img-feat3.png"
                  title="Featured 3"
                  textPosition="left"
                />
              </View>
            )}
          </Container>
          <Spacing />
          <Container styleName="mt-3 jc-center ai-center">
            <View
              style={[
                styles.borderBox,
                media.up('md') ? styles.borderBoxDesktop : styles.borderBoxMobile,
              ]}
            >
              <Video
                source={{ uri: '/video/screencast-examunity.mp4' }}
                poster="/video/screencast-bg-examunity.png"
                controls
              />
            </View>
            <View styleName="my-5 ai-md-center jc-md-between">
              <Button color="success" to="/register" size="lg">
                <Trans {...dict('register')} />
              </Button>
            </View>
          </Container>
        </View>
        <Section color="gray">
          <View styleName="ai-center jc-center">
            <Heading size={2} style={styles.heading}>
              <Trans {...dict('together_we_learn_more_successfully')} />
            </Heading>
            <Heading size={5} style={styles.caption}>
              <Trans {...dict('your_social_media_lms')} />
            </Heading>
          </View>
          <FeaturesBoxContent />
          <Spacing />
        </Section>
        <Spacing />
        <View style={styles.apprenticeshipBox}>
          <Heading size={2} style={styles.heading}>
            <Trans {...dict('our_new_platform_for_the_vocational_education')} />
          </Heading>
          <View styleName="my-3">
            <Link to="https://www.azubinet.de" external>
              <Image
                source="/img/azubinet.png"
                style={styles.azubinetLogo}
                label="Azubinet Logo"
                styleName="my-3"
              />
            </Link>
          </View>
          <Button to="https://www.azubinet.de" external size="lg" color="secondary">
            <Trans {...dict('visit_now')} />
          </Button>
        </View>
        <Spacing />
        {/* support section */}
        <Section color="gray">
          <DevicesStripe noAction />
        </Section>
      </View>
    </>
  );
}

export default Examunity;
