// @flow
import React from 'react';
import { PortalProvider as BSPortalProvider } from 'bootstrap-rn';
import { Provider as StacksProvider } from 'react-stacks';
import AlertsStack from './AlertsStack';
import ToastsStack from './ToastsStack';
import PortalProvider from './PortalProvider';

type Props = {
  children: React$Node,
};

const stacks = {
  alerts: AlertsStack,
  toasts: ToastsStack,
};

function OverlayProvider({ children }: Props): React$Node {
  return (
    <StacksProvider stacks={stacks} autoDismiss={3000}>
      <PortalProvider>
        <BSPortalProvider>{children}</BSPortalProvider>
      </PortalProvider>
    </StacksProvider>
  );
}

export default OverlayProvider;
