// @flow

import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { Button, View } from 'components/core';
import { Divider } from 'components/common';
import { StyleSheet, css } from 'libs/ui';
import { useToggleState } from 'libs/misc';
import { QuizCardMatchingAnswer } from 'components/quizzes';
import { Markdown } from 'components/markdown';
import type { QuizState, CurrentQuizCardState } from '../useQuizState';
import SelectMatchingAnswerModal from './SelectMatchingAnswerModal';

type Props = {
  answer: Entity<GQLMatchingCardAnswer>,
  matchingAnswers: Array<Entity<GQLMatchingCardAnswer>>,
  quizState: QuizState,
  currentCardState: CurrentQuizCardState,
};

const dict = createDict({
  select_answer: 'Select answer...',
});

const styles = StyleSheet.create({
  selection: css`
    background-color: $white;

    &:hover {
      background-color: $gray-100;
    }
    &:focus-visible {
      background-color: $gray-100;
    }
  `,
  textReset: css`
    line-height: 1.5rem;
    text-transform: none;
    letter-spacing: 0;
    font-size: 1rem;
    font-weight: normal;
    // Workaround for native fonts
    @include platform(ios) {
      font-family: 'Roboto';
    }
    @include platform(android) {
      font-family: 'Roboto';
    }
    text-align: left;
  `,
});

function MatchingCardOption({
  answer,
  matchingAnswers,
  quizState,
  currentCardState,
}: Props): React$Node {
  const selectMatchingTextModal = useToggleState(false);

  const userAnswer = currentCardState.answers.find((value) => value.answerId === answer.id);

  return (
    <>
      {!currentCardState.flipped ? (
        <QuizCardMatchingAnswer>
          <Markdown source={answer.text} allowLinkedImages={false} />
          <Divider styleName="my-2" />
          <Button
            onPress={selectMatchingTextModal.toggle}
            color="secondary"
            style={styles.selection}
            textStyle={styles.textReset}
            outline
            caret
          >
            {userAnswer?.matchingText ? (
              <Markdown
                source={userAnswer.matchingText}
                allowLinkedImages={false}
                styleName="flex-grow-1 flex-shrink-1"
              />
            ) : (
              <View styleName="flex-grow-1 flex-shrink-1">
                <Trans {...dict('select_answer')} color="muted" />
              </View>
            )}
          </Button>
        </QuizCardMatchingAnswer>
      ) : (
        <QuizCardMatchingAnswer>
          <Markdown source={answer.text} allowLinkedImages={false} />
          <Divider styleName="my-2" />
          <Markdown source={answer.matchingText} allowLinkedImages={false} />
        </QuizCardMatchingAnswer>
      )}
      <SelectMatchingAnswerModal
        modal={selectMatchingTextModal}
        answer={answer}
        matchingAnswers={matchingAnswers}
        quizState={quizState}
        currentCardState={currentCardState}
      />
    </>
  );
}

export default MatchingCardOption;
