// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql, selectByRoot } from 'libs/graphql';
import { DOMAIN_QUERY } from 'libs/foundation/queries';
import { CHECKLIST_TODO_FRAGMENT } from '../_shared/ChecklistTodo/fragments';

const ChecklistDetails = {
  name: 'ChecklistDetails',
  bundle: () => import('./ChecklistDetails'),
};

const CHECKLIST_QUERY = gql`
  query ChecklistQuery($slug: String!) {
    checklist(slug: $slug) {
      slug
      name
      isPrivate
      todosCount
      todos {
        ...ChecklistTodoFragment
        item {
          results {
            user {
              id
            }
            approver {
              id
            }
            createdAt
          }
        }
      }
      results {
        todoResultsCount
        user {
          name
        }
        doneUntil
        viewerCanUpdate
        viewerCanDelete
      }
      viewerCanUpdate
    }
  }

  ${CHECKLIST_TODO_FRAGMENT}
`;

const Container = createAsyncContainer(ChecklistDetails, (props) => {
  const {
    params: { slug },
  } = props;

  const loaders = {
    query: query(CHECKLIST_QUERY, { variables: { slug } }),
  };

  const selectors = {
    checklist: select(CHECKLIST_QUERY, { variables: { slug } }),
    viewer: selectByRoot('viewer'),
    domain: select(DOMAIN_QUERY, {}),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
