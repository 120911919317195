// @flow
import React, { useMemo } from 'react';
import { Well, LargeText } from 'components/common';
import { Modal, Button, View, Text, Paragraph, Jumbotron, CloseButton } from 'components/core';
import { useMutation } from 'libs/graphql';
import { StyleSheet, css } from 'libs/ui';
import { Form } from 'components/forms';
import { useIntl, createDict, Trans, CurrencyNumber } from 'libs/intl';
import { useNavigate } from 'libs/routing';
import { useValueState, useAlerts } from 'libs/misc';
import { isPurchasableProduct } from 'utils/products';
import useProductsData from './useProductsData';
import { buyLicenses } from './mutations';

type Props = {
  products: Array<Entity<GQLProduct>>,
  organizations: Array<Entity<GQLOrganization>>,
  modal: ToggleState,
};

const styles = StyleSheet.create({
  box: css`
    padding: 1rem;
    border-width: 1px;
    border-color: $gray-200;
    border-radius: 0.25rem;
  `,
});

const dict = createDict({
  buy_licenses: 'Buy Licenses',
  quantity: 'Quantity',
  product: 'Product',
  no_products_available_yet: 'No products available yet.',
  close: 'Close',
  licenses_bought_successfully: 'Licenses bought successfully.',
  unlimited_for: 'Unlimited for',
  cancel: 'Cancel',
  no_pricing_found: 'No pricing found.',
  organization: 'Organization',
  netto: 'Netto',
  vat: 'VAT',
  total: 'Total',
  suitable_for: 'Suitable for',
  courses_included: 'Courses included',
  buy_x_licenses: 'Buy {amount} {amount, plural, =1 {license} other {licenses}}',
  x_months_for: '{duration} {duration, plural, =1 {month} other {months}} for',
  offers: 'Offers',
  license_assignment_explanation:
    'You can assign the licenses to your trainees afterwards. We will send you an invoice of your purchase.',
});

const vatRate = 0.19;

function OrganizationLicensePurchaseModal({
  products: unfilteredProducts,
  organizations,
  modal,
}: Props): React$Node {
  const mutation = useMutation();
  const alerts = useAlerts();
  const intl = useIntl();
  const navigate = useNavigate();

  const products = useMemo(() => {
    return unfilteredProducts
      .filter((product) => product.offers.some((offer) => offer.businessPrices.length > 0))
      .filter(isPurchasableProduct);
  }, []);

  const getApplicablePricing = (quantity, offer) => {
    let newOfferPricing = offer.businessPrices[0];
    offer.businessPrices.forEach((pricing) => {
      if (pricing.min <= quantity) {
        newOfferPricing = pricing;
      }
    });

    return newOfferPricing;
  };

  const { offerListOptions, offerList } = useProductsData(products);

  const organizationOptions = useMemo(
    () =>
      organizations.map((organization) => {
        return { value: organization.id, label: organization.name };
      }),
    [],
  );

  const productOptions = useMemo(
    () =>
      products.map((product) => {
        return { value: product.id, label: product.name };
      }),
    [],
  );

  const firstOfferId = products.length > 0 ? products[0].id : 0;

  const getOfferOptionLabel = (offer, pricing) => {
    const { price } = pricing;

    // reduce by 19% VAT in germany for organizations
    const amount = price.amount / (1 + vatRate);

    return (
      <Text>
        {offer.duration ? (
          <Trans {...dict('x_months_for', { duration: offer.duration })} />
        ) : (
          <Trans {...dict('unlimited_for')} />
        )}{' '}
        <CurrencyNumber value={amount} code={price.currency.code} />
      </Text>
    );
  };

  const getOfferListFinalOptions = (quantity, productId) => {
    const newOptions = [];
    const pricingQuantity = !quantity || typeof quantity === 'undefined' ? 0 : quantity;

    offerListOptions[productId].forEach((offer) => {
      const pricing = getApplicablePricing(pricingQuantity, offer);
      newOptions.push({
        value: offer.id,
        label: getOfferOptionLabel(offer, pricing),
      });
    });

    return newOptions;
  };

  const totalAmount = useValueState(0);
  const offerOptions = useValueState(
    offerListOptions && offerListOptions[firstOfferId]
      ? getOfferListFinalOptions(0, firstOfferId)
      : [],
  );

  const getTotalAmount = (quantity, pricing) => {
    if (quantity && quantity !== 0 && typeof pricing !== 'undefined' && pricing !== null) {
      return quantity * pricing.price.amount;
    }
    return 0;
  };

  const canBuyLicense = productOptions.length > 0;

  return (
    <Modal visible={modal.on} onToggle={modal.toggle}>
      <Modal.Header styleName="flex-row">
        <Modal.Title>
          <Trans {...dict('buy_licenses')} />
        </Modal.Title>
        <CloseButton onPress={modal.toggle} />
      </Modal.Header>
      {!canBuyLicense && (
        <Modal.Body>
          <Well styleName="py-5">
            <Paragraph styleName="mb-4" align="center">
              <Trans color="muted" {...dict('no_products_available_yet')} />
            </Paragraph>
            <Button onPress={modal.toggle} color="success" size="lg">
              <Trans {...dict('close')} />
            </Button>
          </Well>
        </Modal.Body>
      )}
      {canBuyLicense && (
        <Form
          initialValues={{
            product: firstOfferId,
            quantity: null,
            offer: null,
            organization: organizations.length > 0 ? organizations[0].id : null,
          }}
          validate={{
            product: 'required',
            quantity: 'required|numeric',
            offer: 'required',
            organization: 'required',
          }}
          onSubmit={async (values) => {
            await mutation.dispatch(
              buyLicenses(
                values.organization,
                getApplicablePricing(values.quantity, offerList[values.offer]).id,
                parseInt(values.quantity, 10),
              ),
            );

            modal.setOff();

            const selectedOrganization = organizations.find(
              (organization) => organization.id === values.organization,
            );

            alerts.add({
              color: 'success',
              content: intl.trans(dict('licenses_bought_successfully')),
            });

            if (selectedOrganization) {
              navigate(`/organizations/${selectedOrganization.slug}/licenses`);
            }
          }}
        >
          {(form) => {
            const product = products.find((p) => p.id === form.values.product);

            if (!product) {
              throw new Error('Product not found.');
            }

            return (
              <>
                <Modal.Body>
                  {organizations.length > 1 && (
                    <Form.Picker
                      title={dict('organization')}
                      name="organization"
                      options={organizationOptions}
                    />
                  )}
                  <Form.Picker
                    title={dict('product')}
                    name="product"
                    options={productOptions}
                    onValueChange={(value) => {
                      totalAmount.set(0);
                      form.setFieldValue('offer', null, false);
                      offerOptions.set(getOfferListFinalOptions(0, value));
                    }}
                  />
                  {(product.courses.length > 0 || product.professions.length > 0) && (
                    <View style={styles.box} styleName="mb-3">
                      {product.professions.length > 0 && (
                        <View styleName="mb-3">
                          <Trans bold small {...dict('suitable_for')} />
                          {product.professions.map((profession) => (
                            <Text key={profession.id} small>
                              {profession.name}
                            </Text>
                          ))}
                        </View>
                      )}
                      {product.courses.length > 0 && (
                        <View>
                          <Trans small bold {...dict('courses_included')} />
                          {product.courses.map((productCourse) => (
                            <Text key={productCourse.id} small>
                              {productCourse.name}
                            </Text>
                          ))}
                        </View>
                      )}
                    </View>
                  )}
                  <View style={styles.box} styleName="mb-3">
                    <Form.Input
                      title={dict('quantity')}
                      name="quantity"
                      inputMode="numeric"
                      placeholder={dict('quantity')}
                      onValueChange={(value) => {
                        const newOptions = getOfferListFinalOptions(value, form.values.product);
                        offerOptions.set(newOptions);
                        if (value === '' || !value) {
                          totalAmount.set(0);
                          return;
                        }

                        if (
                          form.values.offer &&
                          newOptions.filter((offer) => offer.value === form.values.offer).length ===
                            0
                        ) {
                          form.setFieldValue('offer', null, false);
                          return;
                        }
                        totalAmount.set(
                          form.values.offer
                            ? getTotalAmount(
                                value,
                                getApplicablePricing(value, offerList[form.values.offer]),
                              )
                            : 0,
                        );
                      }}
                      styleName="as-start"
                    />
                    {offerOptions.value.length > 0 && (
                      <Form.Choice
                        title={dict('offers')}
                        name="offer"
                        options={offerOptions.value}
                        onValueChange={(value) => {
                          totalAmount.set(
                            getTotalAmount(
                              form.values.quantity,
                              getApplicablePricing(form.values.quantity, offerList[value]),
                            ),
                          );
                        }}
                        styleName="mb-0"
                      />
                    )}
                    {offerOptions.value.length === 0 && <Trans {...dict('no_pricing_found')} />}
                  </View>
                  <Jumbotron styleName="mb-0">
                    <View styleName="flex-row jc-between">
                      <Trans {...dict('netto')} />
                      {form.values.offer && form.values.quantity ? (
                        <CurrencyNumber
                          value={totalAmount.value / (1 + vatRate)}
                          code={
                            getApplicablePricing(form.values.quantity, offerList[form.values.offer])
                              .price.currency.code
                          }
                        />
                      ) : (
                        <Text>-</Text>
                      )}
                    </View>
                    <View styleName="flex-row jc-between">
                      <Text>
                        <Trans {...dict('vat')} /> 19%
                      </Text>
                      {form.values.offer && form.values.quantity ? (
                        <CurrencyNumber
                          value={totalAmount.value - totalAmount.value / (1 + vatRate)}
                          code={
                            getApplicablePricing(form.values.quantity, offerList[form.values.offer])
                              .price.currency.code
                          }
                        />
                      ) : (
                        <Text>-</Text>
                      )}
                    </View>
                    <View styleName="flex-row jc-between mt-3">
                      <LargeText>
                        <Trans {...dict('total')} />
                      </LargeText>
                      {form.values.offer && form.values.quantity ? (
                        <LargeText bold>
                          <CurrencyNumber
                            value={totalAmount.value}
                            code={
                              getApplicablePricing(
                                form.values.quantity,
                                offerList[form.values.offer],
                              ).price.currency.code
                            }
                          />
                        </LargeText>
                      ) : (
                        <Text>-</Text>
                      )}
                    </View>
                  </Jumbotron>
                  <Trans {...dict('license_assignment_explanation')} styleName="mt-3" small />
                </Modal.Body>
                <Modal.Footer styleName="flex-row jc-end">
                  <Button color="light" onPress={modal.toggle} styleName="mr-3">
                    <Trans {...dict('cancel')} />
                  </Button>
                  <Form.Button
                    color="success"
                    type="submit"
                    disabled={!Number(form.values.quantity) || !form.values.offer}
                  >
                    <Trans
                      {...dict('buy_x_licenses', { amount: Number(form.values.quantity) || 0 })}
                    />
                  </Form.Button>
                </Modal.Footer>
              </>
            );
          }}
        </Form>
      )}
    </Modal>
  );
}

export default OrganizationLicensePurchaseModal;
