// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';

const CourseSettingsCategories = {
  name: 'CourseSettingsCategories',
  bundle: () => import('./CourseSettingsCategories'),
};

const COURSE_SETTINGS_CATEGORIES_QUERY = gql`
  query CourseSettingsCategoriesQuery($courseSlug: String!) {
    course(slug: $courseSlug) {
      name
      organization {
        ownsDomain
        domain {
          isMain
        }
      }
      categories {
        name
        expanded
        position
        articleActivitiesCount
        documentActivitiesCount
        examActivitiesCount
        quizActivitiesCount
        surveyActivitiesCount
        videoActivitiesCount
      }
    }
  }
`;

const Container = createAsyncContainer(CourseSettingsCategories, (props) => {
  const {
    params: { courseSlug },
  } = props;

  const variables = { courseSlug };

  const loaders = {
    query: query(COURSE_SETTINGS_CATEGORIES_QUERY, {
      variables,
    }),
  };

  const selectors = {
    course: select(COURSE_SETTINGS_CATEGORIES_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
