// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const MENU_CHECKLIST_TODO_CUSTOM_FRAGMENT: DocumentNode = gql`
  fragment MenuChecklistTodoCustomFragment on ChecklistTodoCustom {
    title
    viewerResult {
      createdAt
    }
  }
`;
