// @flow
import React from 'react';
import { Domain } from 'libs/misc';
import FooterAzubinet from './FooterAzubinet';
import FooterExamunity from './FooterExamunity';
import FooterVogel from './FooterVogel';
import FooterVerkehrserziehung from './FooterVerkehrserziehung';
import FooterWBS from './FooterWBS';
import FooterStBK from './FooterStBK';
import FooterOthers from './FooterOthers';

type Props = {
  domain: Entity<GQLDomain>,
};

function Footer({ domain }: Props): React$Node {
  return Domain.select({
    'www.azubinet.de': <FooterAzubinet />,
    'www.examunity.com': <FooterExamunity />,
    'www.vogelspot.de': <FooterVogel />,
    'www.xn--fahrradprfung-4ob.de': <FooterVerkehrserziehung />,
    'wbs.azubinet.de': <FooterWBS />,
    'stbk.azubinet.de': <FooterStBK />,
    '*': <FooterOthers domain={domain} />,
  });
}

export default Footer;
