// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query, selectByRoot } from 'libs/graphql';

const MonetizationDetails = {
  name: 'MonetizationDetails',
  bundle: () => import('./MonetizationDetails'),
};

const ORGANIZATION_PRODUCT_DETAILS_QUERY = gql`
  query OrganizationOfferDetailsQuery($slug: String!, $productSlug: String!) {
    organization(slug: $slug) {
      name
      slug
      product(productSlug: $productSlug) {
        name
        slug
        description
        promoted
        licensesCount
        licenses {
          validUntil
          code
          organization {
            name
          }
          assignment {
            user {
              name
            }
          }
        }
        courses {
          name
        }
        professions {
          name
        }
        offers {
          keysCount
          purchasesCount(completed: true)
          duration
          reusable
          price {
            price {
              amount
              currency {
                code
              }
            }
          }
          businessPrices {
            min
            price {
              amount
              currency {
                code
              }
            }
          }
        }
      }
      courses {
        name
        caption
        isPrivate
      }
      domain {
        professions {
          name
        }
      }
      viewerIsAdmin
      viewerIsTrainer
      viewerIsMember
    }
  }
`;

const CURRENCIES_QUERY = gql`
  query CurrenciesQuery {
    currencies {
      code
      sign
    }
  }
`;

const Container = createAsyncContainer(MonetizationDetails, (props) => {
  const {
    params: { slug, productSlug },
  } = props;

  const variables = { slug, productSlug };

  const loaders = {
    query: query(ORGANIZATION_PRODUCT_DETAILS_QUERY, { variables }),
    currenciesQuery: query(CURRENCIES_QUERY),
  };

  const selectors = {
    organization: select(ORGANIZATION_PRODUCT_DETAILS_QUERY, { variables }),
    currencies: select(CURRENCIES_QUERY),
    viewer: selectByRoot('viewer'),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
