// @flow
import React, { useId } from 'react';
import { useIntl } from 'libs/intl';
import useFormField from './useFormField';
import Field from './Field';
import DatePicker from './DatePicker';

type Props = {
  name: string,
  niceName?: Intlized$Message | string | null,
  title?: Intlized$Message | string | null,
  info?: Intlized$Message | string | null,
  disabled?: boolean,
  onValueChange?: (any) => void,
  placeholder?: Intlized$Message | string | null,
  size?: 'sm' | 'lg',
  autoFocus?: boolean,
  inputStyle?: any,
};

const FormDatePicker = React.forwardRef<Props, any>((props, ref) => {
  const {
    name,
    niceName,
    title,
    info,
    disabled = false,
    onValueChange,
    placeholder = '',
    size,
    autoFocus = false,
    inputStyle,
    ...elementProps
  } = props;

  const field = useFormField(name, niceName, title);
  const id = useId();
  const intl = useIntl();

  return (
    <Field
      title={title}
      error={field.error}
      touched={field.touched}
      info={info}
      labelID={id}
      elementProps={elementProps}
    >
      <DatePicker
        ref={ref}
        aria-labelledby={title && id}
        selectedValue={field.value || undefined}
        onValueChange={(nextValue) => {
          field.setValue(nextValue, onValueChange);
        }}
        onBlur={() => {
          field.setTouched();
        }}
        placeholder={
          placeholder && typeof placeholder === 'object' ? intl.trans(placeholder) : placeholder
        }
        autoFocus={autoFocus}
        size={size}
        invalid={field.touched && !!field.error}
        disabled={disabled}
        style={inputStyle}
      />
    </Field>
  );
});

export default (FormDatePicker: React$AbstractComponent<Props, any>);
