// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const ACTIVITY_INFO_MODAL_FRAGMENT: DocumentNode = gql`
  fragment ActivityInfoModalFragment on CourseActivity {
    author {
      name
    }
    versions {
      versionNumber
      current
      releaseNote
      releasedAt
      createdAt
    }
    updatedAt
  }
`;
