// @flow
import React, { useContext, useRef, useEffect } from 'react';
import PortalContext from './PortalContext';
import FixedView from './FixedView';

type Props = {};

function Overlay({ ...elementProps }: Props): null {
  const createPortal = useContext(PortalContext);

  const portal = useRef(null);

  useEffect(() => {
    portal.current = createPortal();

    return () => {
      if (!portal.current) {
        return;
      }

      portal.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (!portal.current) {
      return;
    }

    portal.current.render(<FixedView {...elementProps} />);
  });

  return null;
}

export default Overlay;
