// @flow
import React from 'react';
import { View } from 'components/core';
import { Svg, Circle } from 'react-native-svg';
import { StyleSheet } from 'libs/ui';

type ProgressWheelBar = {
  value: number,
  color: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'light' | 'dark',
};

type Props = {
  children?: React$Node,
  size: number,
  min?: number,
  max?: number,
  values: ProgressWheelBar[],
  style?: any,
};

const ROUND_PRECISION = 1000;

const getPercentage = (value, min, max) => {
  const percentage = ((value - min) / (max - min)) * 100;
  return Math.round(percentage * ROUND_PRECISION) / ROUND_PRECISION;
};

const ProgressWheel = React.forwardRef<Props, any>((props, ref) => {
  const { children, size, min = 0, max = 100, values, style, ...elementProps } = props;

  const strokeWidth = size / 10;
  const r = size / 2 - strokeWidth / 2;
  const circleLength = 2 * Math.PI * r;

  let rotate = 0;

  return (
    <View
      {...elementProps}
      ref={ref}
      style={[
        {
          width: size,
          height: size,
        },
        style,
      ]}
    >
      <View styleName="position-absolute">
        <Svg width={size} height={size}>
          <Circle
            stroke="rgba(0,0,0,0.1)"
            fill="none"
            cx={size / 2}
            cy={size / 2}
            r={r}
            strokeWidth={strokeWidth}
            shapeRendering="geometricPrecision"
          />
        </Svg>
      </View>
      {values.map(({ value, color }, key) => {
        const percentage = getPercentage(value, min, max);

        const element = (
          <View
            styleName="position-absolute"
            // eslint-disable-next-line react/no-array-index-key
            key={`${key}.${value}.${color}`}
          >
            <View
              role="progressbar"
              aria-valuenow={value}
              aria-valuemin={min}
              aria-valuemax={max}
              style={{
                transform: [{ rotate: `${(rotate / 100) * 360 - 90}deg` }],
              }}
            >
              <Svg width={size} height={size}>
                <Circle
                  stroke={StyleSheet.value(color)}
                  fill="none"
                  cx={size / 2}
                  cy={size / 2}
                  r={r}
                  strokeWidth={strokeWidth}
                  strokeDasharray={circleLength}
                  strokeDashoffset={(circleLength * (100 - percentage)) / 100}
                  shapeRendering="geometricPrecision"
                />
              </Svg>
            </View>
          </View>
        );

        rotate += percentage;

        return element;
      })}
      {children && (
        <View
          styleName="ai-center jc-center"
          style={{
            width: size,
            height: size,
          }}
        >
          {children}
        </View>
      )}
    </View>
  );
});

export default (ProgressWheel: React$AbstractComponent<Props, any>);
