// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { View } from 'components/core';

type Props = {
  children: React$Node,
  style?: any,
};

const styles = StyleSheet.create({
  container: css`
    padding: 0.5rem;
    background-color: $gray-200;
    border-left-width: 0.375rem;
    border-radius: 0.25rem;
    border-color: $gray-300;
  `,
});

function QuizCardMatchingAnswer({ children, style, ...elementProps }: Props): React$Node {
  return (
    <View {...elementProps} style={[styles.container, style]}>
      {children}
    </View>
  );
}

export default QuizCardMatchingAnswer;
