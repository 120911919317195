// @flow
import { Link, createMutation, gql } from 'libs/graphql';

const UPDATE_OR_CREATE_SOLUTION = gql`
  mutation UpdateOrCreateSolution($input: OpenQuestionExamSolutionUpdateOrCreateInput!) {
    openQuestionExamSolution_updateOrCreate(input: $input) {
      text
    }
  }
`;

export function updateOrCreateSolution(
  openQuestionId: Id,
  examAttemptId: Id,
  text: string,
): MutationAction {
  return createMutation(UPDATE_OR_CREATE_SOLUTION, {
    variables: {
      input: {
        openQuestionId,
        examAttemptId,
        text,
      },
    },
    updater: (store, data) => {
      const link = new Link(data.roots.openQuestionExamSolution_updateOrCreate);

      store.update('OpenQuestion', openQuestionId, (record) => {
        record.set('viewerRunningExamSolution', link);
      });
    },
  });
}

const DELETE_SOLUTION = gql`
  mutation DeleteSolution($id: ClientID!) {
    openQuestionExamSolution_delete(id: $id)
  }
`;

export function deleteSolution(openQuestionId: Id, id: Id): MutationAction {
  return createMutation(DELETE_SOLUTION, {
    variables: {
      id,
    },
    updater: (store) => {
      store.update('OpenQuestion', openQuestionId, (record) => {
        record.set('viewerRunningExamSolution', new Link());
      });
    },
  });
}
