// @flow
import React from 'react';
import BundleCompact from './BundleCompact';

type Props = {
  onClose?: () => void,
};

function FachlageristBundles({ onClose }: Props): React$Node {
  return (
    <>
      <BundleCompact
        to="/courses/BWUrPiP8hB"
        title="Erstmal reinschnuppern"
        price="kostenlose Lernmaterialien und Community"
        onClose={onClose}
      />
      <BundleCompact
        to="/professions/fachlageristin-fachlagerist"
        title="Lernpaket Fachlagerist/-in"
        price="ab 24,95 EUR"
        paid
      />
    </>
  );
}

export default FachlageristBundles;
