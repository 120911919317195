// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const DomainDesign = {
  name: 'DomainDesign',
  bundle: () => import('./DomainDesign'),
};

const DOMAIN_DESIGN_QUERY = gql`
  query DomainDesignQuery($slug: String!) {
    organization(slug: $slug) {
      ownsDomain
      domain {
        logo {
          name
          extension
          info {
            image {
              height
              width
            }
          }
        }
        navbarLogo {
          name
          extension
          info {
            image {
              height
              width
            }
          }
        }
        favicon {
          name
          extension
          info {
            image {
              height
              width
            }
          }
        }
        theme {
          primaryColor
          secondaryColor
          tertiaryColor
          backgroundColor
          buttonColor
        }
      }
      viewerIsAdmin
    }
  }
`;

const Container = createAsyncContainer(DomainDesign, (props) => {
  const {
    params: { slug },
  } = props;

  const variables = { slug };

  const loaders = {
    query: query(DOMAIN_DESIGN_QUERY, { variables }),
  };

  const selectors = {
    organization: select(DOMAIN_DESIGN_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
