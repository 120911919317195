// @flow
import React from 'react';
import { Nav, Image, Navbar, Pressable, View } from 'components/core';
import { Icon } from 'components/common';
import { StyleSheet, css, useMedia, colorContrast } from 'libs/ui';
import { Domain } from 'libs/misc';
import { createDict, Trans } from 'libs/intl';
import { NavbarLink } from 'components/navbar';
import { useMenu } from 'libs/foundation';
import SearchForm from './SearchForm';
import NotificationsDropdown from './NotificationsDropdown';
import UserDropdown from './UserDropdown';
import MobileSearchDropdown from './MobileSearchDropdown';
import MobileLoginDropdown from './MobileLoginDropdown';

type Props = {
  viewer?: ?Entity<GQLUser>,
  domain: Entity<GQLDomain>,
};

const styles = StyleSheet.create({
  brand: css`
    padding: 0;
    margin-left: 1rem;
  `,
  navbar: css`
    background-color: $brand-primary;

    @include media-breakpoint-up(sm) {
      padding-horizontal: 1rem;
    }

    @include platform(web) {
      position: sticky;
      top: 0;
      z-index: 1030;
    }
  `,
  menuLink: css`
    margin-vertical: -0.5rem;
    min-width: 3.5rem;
    height: 3.5rem;
    padding: 0 1rem;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
    &:focus-visible {
      background-color: rgba(0, 0, 0, 0.2);
    }
  `,
  menuIcon: css`
    font-size: 1.5rem;
    color: ${colorContrast((t) => t['brand-primary'])};
  `,
  placeholder: css`
    height: 3.5rem;
  `,
  searchForm: css`
    height: 2rem;
    width: 25rem;
  `,
  defaultLogo: css`
    width: 38px;
    height: 38px;
  `,
  navMobileWithSearch: css`
    max-width: 48px;
  `,
  navMobileWithoutSearch: css`
    max-width: 112px;
  `,
  navText: css`
    color: ${colorContrast((t) => t['brand-primary'])};
    margin-horizontal: 1rem;
  `,
});

const dict = createDict({
  login: 'Log in',
  register: 'Register',
  for_companies: 'For Companies',
});

const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
};

function NavigationDefault({ viewer, domain }: Props): React$Node {
  const media = useMedia();
  const menu = useMenu();

  const navbarLogoWidth =
    domain.navbarLogo && domain.navbarLogo.info.image ? domain.navbarLogo.info.image.width : 100;
  const navbarLogoHeight =
    domain.navbarLogo && domain.navbarLogo.info.image ? domain.navbarLogo.info.image.height : 38;

  const navbarLogoSize = calculateAspectRatioFit(navbarLogoWidth, navbarLogoHeight, 150, 38);

  return (
    <>
      <Navbar style={styles.navbar} expand>
        {viewer && (viewer.isOrganizationAdminOrTrainer || !domain.primaryCourse) && (
          <Pressable
            onPress={() => {
              menu.setExpanded((value) => !value);
            }}
            style={styles.menuLink}
          >
            <Icon glyph="menu" style={styles.menuIcon} />
          </Pressable>
        )}
        <Navbar.Brand to={viewer ? '/dashboard' : '/'} style={styles.brand}>
          {domain.navbarLogo && (
            <Image
              source={`/files/${domain.navbarLogo.id}/${domain.navbarLogo.name}`}
              resizeMode="contain"
              style={[
                {
                  height: navbarLogoSize.height,
                  width: navbarLogoSize.width,
                },
                media.down('sm') &&
                  (domain.searchType !== 'NONE'
                    ? styles.navMobileWithSearch
                    : styles.navMobileWithoutSearch),
              ]}
            />
          )}
          {!domain.navbarLogo && (
            <Image
              resizeMode="contain"
              source="/img/logos/azubinet-icon-white.png"
              style={styles.defaultLogo}
            />
          )}
        </Navbar.Brand>
        {domain.searchType !== 'NONE' && media.up('md') && (
          <View styleName="flex-grow-1 ai-center">
            <SearchForm variant="navbar" />
          </View>
        )}
        <Nav styleName="ml-auto ai-end">
          {media.down('sm') && domain.searchType !== 'NONE' && <MobileSearchDropdown />}
          {!viewer && (
            <>
              {media.up('md') && (
                <>
                  {Domain.is('stbk.azubinet.de') && (
                    <NavbarLink to="/business">
                      <Trans {...dict('for_companies')} style={styles.navText} />
                    </NavbarLink>
                  )}
                  <NavbarLink to="/login">
                    <Trans {...dict('login')} style={styles.navText} />
                  </NavbarLink>
                  {domain.permissions.usersCanSelfRegister && (
                    <NavbarLink to="/register">
                      <Trans {...dict('register')} style={styles.navText} />
                    </NavbarLink>
                  )}
                </>
              )}
              {media.down('sm') && <MobileLoginDropdown domain={domain} />}
            </>
          )}
          {viewer && (
            <>
              <NotificationsDropdown viewer={viewer} />
              <UserDropdown viewer={viewer} domain={domain} />
            </>
          )}
        </Nav>
      </Navbar>
    </>
  );
}

export default NavigationDefault;
