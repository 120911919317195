// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';
import { QUIZ_TEXT_CARD_FRAGMENT } from './TextCard';
import { QUIZ_CHOICE_CARD_FRAGMENT } from './ChoiceCard';
import { QUIZ_ORDERING_CARD_FRAGMENT } from './OrderingCard';
import { QUIZ_MATCHING_CARD_FRAGMENT } from './MatchingCard';
import QuizLoading from './QuizLoading';

const QuizLoader = {
  name: 'QuizLoader',
  bundle: () => import('./QuizLoader'),
};

const COUSE_QUIZ_QUERY = gql`
  query CourseQuiz($slug: String!, $courseSlug: String!) {
    course(slug: $courseSlug) {
      viewerIsMember
      quizActivity(slug: $slug) {
        title
        slug
        versionForActiveResult {
          versionNumber
          item {
            cards {
              ...QuizTextCardFragment
              ...QuizChoiceCardFragment
              ...QuizOrderingCardFragment
              ...QuizMatchingCardFragment
              hint
              viewerActiveResult {
                correctCount
                wrongCount
                box
                loopAnswer
              }
            }

            totalBoxes
            viewerActiveResult {
              correctCount
              wrongCount
              progress
              completedAt
              repeatMode
            }
          }
        }
      }
    }
  }

  ${QUIZ_TEXT_CARD_FRAGMENT}
  ${QUIZ_CHOICE_CARD_FRAGMENT}
  ${QUIZ_ORDERING_CARD_FRAGMENT}
  ${QUIZ_MATCHING_CARD_FRAGMENT}
`;

const options = {
  async: {
    loading: QuizLoading,
  },
};

const Container = createAsyncContainer(
  QuizLoader,
  (props) => {
    const {
      params: { slug, courseSlug },
    } = props;

    const variables = {
      slug,
      courseSlug,
    };

    const loaders = {
      query: query(COUSE_QUIZ_QUERY, { variables }),
    };

    const selectors = {
      course: select(COUSE_QUIZ_QUERY, { variables }),
    };

    return {
      loaders,
      selectors,
    };
  },
  options,
);

export default (Container: React$AbstractComponent<any, mixed>);
