// @flow
import React from 'react';
import Markdown from '../markdown/Markdown';

type Props = {
  cloze: Entity<GQLCloze>,
  gaps: (gap: Entity<GQLClozeGap>) => React$Node,
  onTableScroll?: any,
  textStyle?: any,
};

const defaultProps = {
  textStyle: null,
};

const makeRenderText = (cloze, makeGap) => (text) => {
  const parts = [];

  text.split(/{([a-zA-Z0-9]+)}/g).forEach((part, i) => {
    // part is string
    if (i % 2 === 0) {
      parts.push(part);
    }

    // part is gap
    if (i % 2 === 1) {
      const gap = cloze.gaps.find((item) => item.key === part);

      if (!gap) {
        // eslint-disable-next-line no-console
        console.error(`Gap for key ${part} not found.`);

        return;
      }

      parts.push(makeGap(gap));
    }
  });

  return parts;
};

function ClozeTemplate({ cloze, gaps: makeGap, onTableScroll, textStyle }: Props): React$Node {
  return (
    <Markdown
      variant="article"
      source={cloze.text}
      renderText={makeRenderText(cloze, makeGap)}
      onTableScroll={onTableScroll}
      textStyle={textStyle}
      styleName="mb-3"
    />
  );
}

ClozeTemplate.defaultProps = defaultProps;

export default ClozeTemplate;
