// @flow
import React, { useEffect, useId, useRef } from 'react';
import { Platform } from 'react-native';
import { Input } from 'bootstrap-rn';
import { StyleSheet } from 'libs/ui';
import { useIntl } from 'libs/intl';
import useFormField from './useFormField';
import Field from './Field';

type Props = {
  element?: React$Element<*>,
  name: string,
  niceName?: Intlized$Message | string | null,
  title?: Intlized$Message | string | null,
  info?: Intlized$Message | string | null,
  disabled?: boolean,
  onValueChange?: (any) => void,
  placeholder?: Intlized$Message | string | null,
  size?: 'sm' | 'lg' | null,
  multiline?: boolean,
  rows?: number,
  autoComplete?: string,
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters',
  autoCorrect?: boolean,
  autoFocus?: boolean,
  autoExpand?: boolean,
  trimValue?: boolean,
  convertEmptyValueToNull?: boolean,
  inputMode?: string,
  secureTextEntry?: boolean,
  returnKeyType?: string,
  onSubmitEditing?: ({ nativeEvent: Object }) => void,
  inputStyle?: any,
};

const FormInput = React.forwardRef<Props, any>((props, ref) => {
  const {
    element,
    name,
    niceName,
    title,
    info,
    disabled = false,
    onValueChange,
    placeholder = '',
    size,
    multiline = false,
    rows,
    autoComplete,
    autoCapitalize,
    autoCorrect = false,
    autoFocus = false,
    autoExpand = false,
    trimValue = false,
    convertEmptyValueToNull = false,
    inputMode,
    secureTextEntry = false,
    returnKeyType,
    onSubmitEditing,
    inputStyle,
    ...elementProps
  } = props;

  const field = useFormField(name, niceName, title);
  const id = useId();
  const intl = useIntl();

  const inputRef = useRef(null);

  if (Platform.OS === 'web') {
    useEffect(() => {
      if (!inputRef.current || !inputRef.current.style || !autoExpand) {
        return;
      }

      // Hide overflow, so that scrollbar does not influence height.
      inputRef.current.style.overflow = 'hidden';
      inputRef.current.style.height = 0;
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }, []);
  }

  const sanitizeValue = (rawValue) => {
    // Trim value if type is not password
    const trimmedValue = trimValue && secureTextEntry ? rawValue.trim() : rawValue;

    // Handle empty string as null and return
    return convertEmptyValueToNull && trimmedValue === '' ? null : trimmedValue;
  };

  const inputProps = {
    ref: (el) => {
      // Apply inputRef
      inputRef.current = el;

      // Apply ref
      if (typeof ref === 'function') {
        ref(el);
      } else if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = el;
      }
    },
    'aria-labelledby': title && id,
    name,
    value: field.value || '',
    onChangeText: (nextValue) => {
      field.setValue(sanitizeValue(nextValue), onValueChange);
    },
    onChange:
      Platform.OS === 'web' && autoExpand
        ? (e) => {
            e.target.style.height = 0;
            e.target.style.height = `${e.target.scrollHeight}px`;
          }
        : undefined,
    onBlur: () => {
      field.setTouched();
    },
    placeholder:
      placeholder && typeof placeholder === 'object' ? intl.trans(placeholder) : placeholder,
    size,
    multiline,
    rows: rows || (multiline ? 7 : null),
    autoComplete,
    autoCapitalize,
    autoCorrect,
    autoFocus,
    invalid: field.touched && !!field.error,
    disabled,
    cursorColor: StyleSheet.value('primary'),
    inputMode,
    secureTextEntry,
    returnKeyType,
    onSubmitEditing,
    style: inputStyle,
  };

  return (
    <Field
      title={title}
      error={field.error}
      touched={field.touched}
      info={info}
      labelID={id}
      elementProps={elementProps}
    >
      {element ? React.cloneElement(element, inputProps) : <Input {...inputProps} />}
    </Field>
  );
});

export default (FormInput: React$AbstractComponent<Props, any>);
