// @flow
import locateTag from 'remark-parse/lib/locate/tag';
import matchOpeningTag from '../utils/matchOpeningTag';
import isClosingTag from '../utils/isClosingTag';
import getTagAttributes from '../utils/getTagAttributes';
import validateTag from '../utils/validateTag';
import countTrailingSpaces from '../utils/countTrailingSpaces';

export const allowedTags = {
  b: { type: 'strong' },
  strong: { type: 'strong' },
  i: { type: 'emphasis' },
  em: { type: 'emphasis' },
  del: { type: 'delete' },
  a: { type: 'link' },
  img: { type: 'image' },
  br: { type: 'break' },
};

export default function htmlInline() {
  const { Parser } = this;
  const tokenizers = Parser.prototype.inlineTokenizers;

  function tokenizeHtmlInline(eat, value, silent) {
    const self = this;
    const tag = matchOpeningTag(value, 0);

    if (!tag) {
      return undefined;
    }

    const def = allowedTags[tag.name];

    if (!def) {
      return undefined;
    }

    const openingLength = tag.source[0].length;

    // Tag is self-closing tag.
    if (tag.selfClosing) {
      if (silent) {
        return true;
      }

      return eat(value.slice(0, openingLength))({
        type: def.type,
        htmlTag: validateTag(tag),
        ...getTagAttributes(tag),
      });
    }

    // $FlowFixMe[incompatible-use]
    const closingLength = tag.source[1].length;
    let index = openingLength;
    let openings = 1;

    while (index < value.length) {
      // Potential tag found.
      const innerTag = matchOpeningTag(value, index);

      if (innerTag && innerTag.name === tag.name) {
        // Marker is another opening marker.
        index += innerTag.source[0].length;
        openings += 1;
      } else if (isClosingTag(value, index, tag)) {
        // Update openings count.
        index += closingLength;
        openings -= 1;

        // Marker is closing marker, eat value.
        if (openings === 0) {
          if (silent) {
            return true;
          }

          const now = eat.now();

          const innerValue = value.slice(openingLength, index - closingLength);
          const offset = countTrailingSpaces(innerValue);

          now.column += openingLength + offset;
          now.offset += openingLength + offset;

          const trimmedInnerValue = innerValue.slice(
            offset,
            innerValue.length - countTrailingSpaces(innerValue, true),
          );

          let children;

          if (tag.name === 'a') {
            // If we are inside of a link, we should mark this before we tokenize the inner.
            const exit = self.enterLink();
            children = self.tokenizeInline(trimmedInnerValue, now);
            exit();
          } else {
            children = self.tokenizeInline(trimmedInnerValue, now);
          }

          return eat(value.slice(0, index))({
            type: def.type,
            htmlTag: validateTag(tag, children),
            ...getTagAttributes(tag),
            children,
          });
        }
      }

      index += 1;
    }

    return undefined;
  }

  tokenizeHtmlInline.locator = locateTag;

  tokenizers.html = tokenizeHtmlInline;
}
