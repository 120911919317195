// @flow
import React from 'react';
import { useFormikContext } from 'formik';
import Button from '../core/Button';

type Props = {
  children: React$Node,
  type: 'submit' | 'reset',
  onPress?: Function,
  disabled?: boolean,
};

const FormButton = React.forwardRef<Props, any>((props, ref) => {
  const { type, disabled = false, onPress: handlePress, ...elementProps } = props;

  const form = useFormikContext();

  const buttonDisabled = disabled || form.isSubmitting;

  return (
    <Button
      {...elementProps}
      ref={ref}
      onPress={(event) => {
        if (type === 'submit') {
          form.submitForm();
        }

        if (type === 'reset') {
          form.resetForm();
        }

        if (handlePress) handlePress(event);
      }}
      disabled={buttonDisabled}
    />
  );
});

FormButton.displayName = 'FormButton';

export default (FormButton: React$AbstractComponent<Props, any>);
