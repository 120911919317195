// @flow
import React from 'react';
import { View, Image, Button, Text, Grid, Link } from 'components/core';
import { HighlightStripe, BrandHeading } from 'components/pages';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';
import { useToggleState } from 'libs/misc';
import EFREModal from './EFREModal';
import MesseModal from './MesseModal';

const styles = StyleSheet.create({
  heading: css`
    text-align: center;
    margin-bottom: 2rem;
  `,
  gridDesktop: css`
    min-height: 6.75rem;
    margin-bottom: 1rem;
  `,
  gridBox: css`
    justify-content: center;
    align-items: center;
    margin-vertical: 0.5rem;
  `,
  logoVogelDesktop: css`
    height: 56px;
    width: 143px;
  `,
  logoVogelMobile: css`
    height: 42px;
    width: 107px;
  `,
  logoStBKDesktop: css`
    height: 45px;
    width: 158px;
  `,
  logoStBKMobile: css`
    height: 31px;
    width: 110px;
  `,
  logoWBSDesktop: css`
    height: 56px;
    width: 155px;
  `,
  logoWBSMobile: css`
    height: 42px;
    width: 116px;
  `,
  logoHermesDesktop: css`
    height: 60px;
    width: 166px;
  `,
  logoHermesMobile: css`
    height: 45px;
    width: 125px;
  `,
  logoGeorgCDesktop: css`
    height: 17px;
    width: 150px;
  `,
  logoGeorgCMobile: css`
    height: 15px;
    width: 130px;
  `,
  logoWalzDesktop: css`
    height: 26px;
    width: 144px;
  `,
  logoWalzMobile: css`
    height: 18px;
    width: 100px;
  `,
  logoNWBDesktop: css`
    height: 56px;
    width: 104px;
  `,
  logoNWBMobile: css`
    height: 42px;
    width: 78px;
  `,
  logoMZPDesktop: css`
    height: 56px;
    width: 125px;
  `,
  logoMZPMobile: css`
    height: 42px;
    width: 94px;
  `,
  logoVRDesktop: css`
    height: 48px;
    width: 70px;
  `,
  logoVRMobile: css`
    height: 48px;
    width: 70px;
  `,
  logoTUIDesktop: css`
    height: 46px;
    width: 104px;
  `,
  logoTUIMobile: css`
    height: 46px;
    width: 104px;
  `,
  logoBonprixDesktop: css`
    height: 38px;
    width: 120px;
  `,
  logoBonprixMobile: css`
    height: 38px;
    width: 120px;
  `,
  logoVRRDesktop: css`
    height: 50px;
    width: 46px;
  `,
  logoVRRMobile: css`
    height: 50px;
    width: 46px;
  `,
  logoHagoDesktop: css`
    height: 30px;
    width: 112px;
  `,
  logoHagoMobile: css`
    height: 30px;
    width: 112px;
  `,
  logoNettoDesktop: css`
    height: 50px;
    width: 150px;
  `,
  logoNettoMobile: css`
    height: 37px;
    width: 110px;
  `,
  logoBudniDesktop: css`
    height: 65px;
    width: 65px;
  `,
  logoBudniMobile: css`
    height: 50px;
    width: 50px;
  `,
  logoMerckDesktop: css`
    height: 24px;
    width: 150px;
  `,
  logoMerckMobile: css`
    height: 18px;
    width: 110px;
  `,
  logoUniteDesktop: css`
    height: 35px;
    width: 144px;
  `,
  logoUniteMobile: css`
    height: 35px;
    width: 144px;
  `,
  logoCrownDesktop: css`
    height: 32px;
    width: 137px;
  `,
  logoCrownMobile: css`
    height: 25px;
    width: 105px;
  `,
  logoReisserDesktop: css`
    height: 35px;
    width: 145px;
  `,
  logoReisserMobile: css`
    height: 26px;
    width: 110px;
  `,
  logoOttoDesktop: css`
    height: 41px;
    width: 122px;
  `,
  logoOttoMobile: css`
    height: 34px;
    width: 100px;
  `,
  logoAllianceDesktop: css`
    height: 37px;
    width: 140px;
  `,
  logoAllianceMobile: css`
    height: 37px;
    width: 140px;
  `,
  logoMedionDesktop: css`
    height: 24px;
    width: 144px;
  `,
  logoMedionMobile: css`
    height: 24px;
    width: 144px;
  `,
  logoMatecoDesktop: css`
    height: 36px;
    width: 144px;
  `,
  logoMatecoMobile: css`
    height: 36px;
    width: 144px;
  `,
  logoReberDesktop: css`
    height: 32px;
    width: 144px;
  `,
  logoReberMobile: css`
    height: 32px;
    width: 144px;
  `,
  buttonText: css`
    text-transform: none;
  `,
});

const dict = createDict({
  partners_and_references: 'Partners and References',
  supported_project: 'Supported Project',
});

function References(): React$Node {
  const InfoModal = useToggleState(false);
  const messeModal = useToggleState(false);
  const media = useMedia();

  return (
    <>
      <BrandHeading size={1} style={styles.heading}>
        <Trans {...dict('partners_and_references')} />
      </BrandHeading>
      <Grid
        styleName="flex-row flex-wrap flex-md-nowrap ai-center jc-center"
        style={media.up('md') && styles.gridDesktop}
      >
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/budni.png"
            label="BUDNI"
            style={media.up('md') ? styles.logoBudniDesktop : styles.logoBudniMobile}
          />
        </Grid.Box>
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/merck.png"
            label="MERCK"
            style={media.up('md') ? styles.logoMerckDesktop : styles.logoMerckMobile}
          />
        </Grid.Box>
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/netto.jpg"
            label="NETTO"
            style={media.up('md') ? styles.logoNettoDesktop : styles.logoNettoMobile}
          />
        </Grid.Box>
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/hermes-reference.jpg"
            label="Hermes Fulfilment"
            style={media.up('md') ? styles.logoHermesDesktop : styles.logoHermesMobile}
          />
        </Grid.Box>
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/tui.png"
            label="TUI"
            style={media.up('md') ? styles.logoTUIDesktop : styles.logoTUIMobile}
          />
        </Grid.Box>
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/stbk-nds.png"
            label="Steuerberaterkammer Niedersachsen"
            style={media.up('md') ? styles.logoStBKDesktop : styles.logoStBKMobile}
          />
        </Grid.Box>
      </Grid>
      <Grid
        styleName="flex-row flex-wrap flex-md-nowrap ai-center jc-center"
        style={media.up('md') && styles.gridDesktop}
      >
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/georg-c.png"
            label="GEORG C."
            style={media.up('md') ? styles.logoGeorgCDesktop : styles.logoGeorgCMobile}
            styleName="mx-md-5 m-3"
          />
        </Grid.Box>
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/walz.png"
            label="Versandhaus Walz"
            style={media.up('md') ? styles.logoWalzDesktop : styles.logoWalzMobile}
            styleName="mx-md-5 m-3"
          />
        </Grid.Box>
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/moebelzentrum-pforzheim.png"
            label="Möbelzentrum Pforzheim"
            style={media.up('md') ? styles.logoMZPDesktop : styles.logoMZPMobile}
            styleName="mx-md-5 m-3"
          />
        </Grid.Box>
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/vr-bank.png"
            label="Volksbank im Harz eG"
            style={media.up('md') ? styles.logoVRDesktop : styles.logoVRMobile}
            styleName="mx-md-5 m-3"
          />
        </Grid.Box>
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image source="/img/home/logo-nwb.png" label="NWB Verlag" style={styles.logoNWBMobile} />
        </Grid.Box>
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/verlag-heinrich-vogel-reference.png"
            label="Verlag Heinrich Vogel"
            style={media.up('md') ? styles.logoVogelDesktop : styles.logoVogelMobile}
          />
        </Grid.Box>
      </Grid>
      <Grid
        styleName="flex-row flex-wrap flex-md-nowrap ai-center jc-center"
        style={media.up('md') && styles.gridDesktop}
      >
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/hago.png"
            label="hago"
            style={media.up('md') ? styles.logoHagoDesktop : styles.logoHagoMobile}
            styleName="mx-md-5 m-3"
          />
        </Grid.Box>
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/vrr.jpg"
            label="VRR"
            style={media.up('md') ? styles.logoVRRDesktop : styles.logoVRRMobile}
            styleName="mx-md-5 m-3"
          />
        </Grid.Box>
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/bonprix.png"
            label="bonprix"
            style={media.up('md') ? styles.logoBonprixDesktop : styles.logoBonprixMobile}
            styleName="mx-md-5 m-3"
          />
        </Grid.Box>
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/reisser.png"
            label="REISSER"
            style={media.up('md') ? styles.logoReisserDesktop : styles.logoReisserMobile}
            styleName="mx-md-5 m-3"
          />
        </Grid.Box>
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/unite.png"
            label="Unite"
            style={media.up('md') ? styles.logoUniteDesktop : styles.logoUniteMobile}
            styleName="mx-md-5 m-3"
          />
        </Grid.Box>
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Link external to="https://www.crown.com/de-de">
            <Image
              source="/img/home/crown.png"
              label="CROWN"
              style={media.up('md') ? styles.logoCrownDesktop : styles.logoCrownMobile}
              styleName="mx-md-5 m-3"
            />
          </Link>
        </Grid.Box>
      </Grid>
      <Grid
        style={media.up('md') && styles.gridDesktop}
        styleName="flex-row flex-wrap flex-md-nowrap ai-center jc-center mb-3 mb-md-4"
      >
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/otto.png"
            label="OTTO"
            style={media.up('md') ? styles.logoOttoDesktop : styles.logoOttoMobile}
            styleName="mx-md-5 m-3"
          />
        </Grid.Box>
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/alliance.jpg"
            label="Alliance Automotive"
            style={media.up('md') ? styles.logoAllianceDesktop : styles.logoAllianceMobile}
            styleName="mx-md-5 m-3"
          />
        </Grid.Box>
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/wbs-reference.png"
            label="WBS Training"
            style={media.up('md') ? styles.logoWBSDesktop : styles.logoWBSMobile}
            styleName="mx-md-5 m-3"
          />
        </Grid.Box>
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/medion.png"
            label="MEDION"
            style={media.up('md') ? styles.logoMedionDesktop : styles.logoMedionMobile}
            styleName="mx-md-5 m-3"
          />
        </Grid.Box>
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/mateco.png"
            label="mateco"
            style={media.up('md') ? styles.logoMatecoDesktop : styles.logoMatecoMobile}
            styleName="mx-md-5 m-3"
          />
        </Grid.Box>
        <Grid.Box size={6} sizeMd={2} style={styles.gridBox}>
          <Image
            source="/img/home/reber.png"
            label="REBER"
            style={media.up('md') ? styles.logoReberDesktop : styles.logoReberMobile}
            styleName="mx-md-5 m-3"
          />
        </Grid.Box>
      </Grid>
      <HighlightStripe styleName="p-4">
        <BrandHeading size={4} styleName="mb-3 as-center">
          <Trans {...dict('supported_project')} />
        </BrandHeading>
        <View styleName="flex-md-row ai-center jc-center">
          <Image
            source="/img/home/sachsen-anhalt.png"
            label="S-A"
            style={{ height: 70, width: 105 }}
            styleName="mx-md-5 m-3"
          />
          <Image
            source="/img/home/efre-fonds.png"
            label="EFRE"
            style={{ height: 70, width: 160 }}
            styleName="mx-md-5 m-3"
          />
          <Image
            source="/img/home/efre-fonds-slogan.png"
            label="EFRE"
            style={{ height: 67, width: 210 }}
            styleName="mx-md-5 m-3"
          />
          <Image
            source="/img/home/investforum.png"
            label="Investforum"
            style={{ height: 70, width: 140 }}
            styleName="mx-md-5 m-3"
          />
        </View>
        <View styleName="mt-3 flex-md-row jc-center ai-center">
          <Button
            color="secondary"
            outline
            onPress={InfoModal.toggle}
            size="lg"
            styleName="mr-md-2 mb-4 mb-md-0"
          >
            <Text style={styles.buttonText}>EFRE Digital Creativity</Text>
          </Button>
          <Button
            color="secondary"
            outline
            onPress={messeModal.toggle}
            size="lg"
            styleName="ml-md-2"
          >
            <Text style={styles.buttonText}>EFRE Messebeteiligung</Text>
          </Button>
        </View>
      </HighlightStripe>
      <EFREModal modal={InfoModal} />
      <MesseModal modal={messeModal} />
    </>
  );
}

export default References;
