// @flow
import React from 'react';
import { View, Text } from 'components/core';
import { LargeText } from 'components/common';
import { useToggleState } from 'libs/misc';
import { StyleSheet, css, useMedia } from 'libs/ui';
import WhatsNewModal from './WhatsNewModal';

const styles = StyleSheet.create({
  containerDesktop: css`
    align-items: center;
    justify-content: center;
    max-width: 1008px;
    height: 15rem;
    margin-vertical: 22.5rem;
    border-radius: 0.5rem;
    flex-grow: 1;
    flex-shrink: 1;
    background-color: #000;
    opacity: 0.8;
  `,
  cardDesktop: css`
    padding: 1rem;
    border-radius: 0.5rem;
    margin: 2rem;
    flex-shrink: 1;
  `,
  cardMobile: css`
    margin-bottom: 1rem;
    padding: 1rem;
  `,
  headingDesktop: css`
    font-family: 'Titillium Web';
    font-weight: bold;
    font-size: 1.75rem;
    line-height: 3.5rem;
    opacity: 1;
    text-align: center;
    color: white;
  `,
  headingMobile: css`
    font-family: 'Titillium Web';
    font-weight: bold;
    font-size: 1.75rem;
    line-height: 3.5rem;
    opacity: 1;
    text-align: center;
    color: dark;
  `,
  textDesktop: css`
    opacity: 1;
    text-align: center;
    color: white;
  `,
  textMobile: css`
    opacity: 1;
    text-align: left;
    color: dark;
  `,
});

function HeroBox(): React$Node {
  const media = useMedia();
  const modal = useToggleState(false);

  return (
    <>
      <View style={media.up('md') ? styles.containerDesktop : styles.containerMobile}>
        <View style={media.up('md') ? styles.cardDesktop : styles.cardMobile}>
          <View styleName="ai-md-center jc-md-center mb-3">
            <Text
              style={[media.up('md') ? styles.headingDesktop : styles.headingMobile]}
              numberOfLines={media.up('md') ? 1 : 2}
            >
              Willkommen auf deiner Azubi-Lernplattform
            </Text>
          </View>
          <LargeText style={[media.up('md') ? styles.textDesktop : styles.textMobile]}>
            Bereite dich auf die anstehenden Prüfungen vor, lerne gemeinsam mit anderen
            Auszubildenden, nutze hunderte Lerninhalte, erstelle und teile deine eigene Materialien
            und teste deinen Wissensstand.
          </LargeText>
        </View>
      </View>
      <WhatsNewModal modal={modal} />
    </>
  );
}

export default HeroBox;
