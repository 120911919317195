// @flow
import React from 'react';
import { createAsyncContainer as createBaseAsyncContainer } from 'react-transporter';
import { useParams, useSearchParams } from 'libs/routing';
import { Loading } from 'components/common';
import ErrorSelector from './ErrorSelector';
import withAnalytics from './withAnalytics';

type LoadableComponent = {
  name: string,
  bundle: () => Promise<any>,
};

const wrapComponentWithAnalytics = (component) => {
  // $FlowFixMe[prop-missing]
  const hasCodeSplit = component.name && component.bundle;

  if (!hasCodeSplit) {
    // $FlowFixMe[incompatible-call]
    return withAnalytics(component);
  }

  return {
    name: component.name,
    bundle: () =>
      // $FlowFixMe[incompatible-use]
      component.bundle().then((result) => ({
        ...result,
        default: withAnalytics(result.default),
      })),
  };
};

export default function createAsyncContainer(
  component: React$ComponentType<any> | LoadableComponent,
  container: Object,
  options: Object,
): React$AbstractComponent<any, mixed> {
  const asyncOptions = options && options.async;

  const componentWithAnalytics = wrapComponentWithAnalytics(component);

  const AsyncContainer = createBaseAsyncContainer(componentWithAnalytics, container, {
    ...options,
    async: {
      loading: (asyncOptions && asyncOptions.loading) || Loading,
      error: (asyncOptions && asyncOptions.error) || ErrorSelector,
    },
  });

  return (props) => {
    const params = useParams();
    const [searchParams] = useSearchParams();

    return <AsyncContainer {...props} params={params} searchParams={searchParams} />;
  };
}
