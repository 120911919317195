// @flow
import React from 'react';
import { Platform } from 'react-native';
import { StyleSheet, css } from 'libs/ui';
import { ScrollView, Image } from 'components/core';
import { useImageDimensions } from 'libs/image';

type Props = {
  value: string,
  spacing: boolean,
};

const styles = StyleSheet.create({
  spacing: css`
    margin-bottom: 1rem;
  `,
});

function Math({ value, spacing }: Props): React$Node {
  // SVG supported on web only
  const format = Platform.OS === 'web' ? 'svg' : 'png';

  const url = `/math-api/${format}/${encodeURIComponent(value)}`;

  const dimensions = useImageDimensions(url);

  if (!dimensions) {
    return null;
  }

  return (
    <ScrollView bounces={false} horizontal style={spacing && styles.spacing}>
      <Image
        source={url}
        aria-label={value}
        style={{ width: dimensions.width, height: dimensions.height }}
        styleName="my-2"
      />
    </ScrollView>
  );
}

export default Math;
