// @flow
import React from 'react';
import { View, Button, Image, Jumbotron } from 'components/core';
import { BrandHeading } from 'components/pages';
import { LargeText } from 'components/common';
import { createDict, useIntl, Trans } from 'libs/intl';
import { StyleSheet, css, useMedia } from 'libs/ui';

const styles = StyleSheet.create({
  jumbotron: css`
    background-color: $brand-primary;
    align-self: center;
    align-items: center;
  `,
  icon: css`
    color: #fff;
  `,
  imageDesktop: css`
    width: 260px;
    height: 185px;
    margin-left: 2rem;
    margin-right: 4rem;
  `,
  imageMobile: css`
    width: 141px;
    height: 100px;
    margin-bottom: 2rem;
  `,
  heading: css`
    color: white;
    margin-bottom: 2rem;
  `,
  content: css`
    text-align: left;
    color: white;
    margin-bottom: 2rem;
  `,
  highlightText: css`
    font-weight: bold;
    text-align: left;
    color: white;
    margin-bottom: 1rem;
  `,
});

const dict = createDict({
  azubinet_for_your_business: 'Azubinet for your business',
  learn_more: 'Learn more',
  test_as_company: 'Test as company',
});

function CompaniesInfo(): React$Node {
  const media = useMedia();
  const intl = useIntl();

  return (
    <Jumbotron style={styles.jumbotron} styleName="flex-md-row mb-0 px-3 px-md-4">
      <Image
        source="/img/pages/Learning.png"
        style={media.up('md') ? styles.imageDesktop : styles.imageMobile}
      />
      <View styleName="flex-column flex-shrink-1">
        <BrandHeading style={styles.heading} size={2}>
          <Trans {...dict('azubinet_for_your_business')} />
        </BrandHeading>
        <LargeText style={styles.content}>
          {intl.locale === 'de'
            ? 'Jetzt mit exklusiven Funktionen für Ausbildungsbetriebe! Kaufen Sie ganz einfach Lernpakete zu speziellen Konditionen für Ihre Azubis, lassen Sie Ihre Azubis Teil der Community sein und erstellen Sie selbst Lerninhalte. Jetzt mit vielfältigen Möglichkeiten zur Kommunikation und Organisation des Ausbildungsalltags.'
            : 'Now with exclusive features for training companies! Simply purchase bundles for your trainees, let them be part of our community and create your own content. Save time and effort using the online record book on Azubinet.'}
        </LargeText>
        <LargeText style={styles.highlightText}>
          {intl.locale === 'de'
            ? 'Testen Sie Azubinet für Ausbildungsbetriebe jetzt 14 Tage kostenlos!'
            : 'Test Azubinet for companies 14 days for free now!'}
        </LargeText>
        <View styleName="flex-md-row ai-start">
          <Button to="/business" size="lg" color="secondary" styleName="mr-md-3 mb-3 mb-md-0">
            <Trans {...dict('learn_more')} />
          </Button>
          <Button to="/request-access" size="lg">
            <Trans {...dict('test_as_company')} />
          </Button>
        </View>
      </View>
    </Jumbotron>
  );
}

export default CompaniesInfo;
