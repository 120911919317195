// @flow
import React from 'react';
import { View, Image, Button, Text } from 'components/core';
import { BrandHeading, HighlightStripe, BrandText } from 'components/pages';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { useToggleState } from 'libs/misc';
import FirstYearModal from './FirstYearModal';

type Props = {
  viewer: Entity<GQLUser>,
};

const styles = StyleSheet.create({
  container: css`
    flex-direction: column;
    border-top-width: 1px;
    border-top-color: $gray-200;
  `,
  textBold: css`
    font-size: 1.125rem;
    text-align: left;
    line-height: 1.5rem;
    font-weight: normal;
    letter-spacing: 0;
    text-transform: none;
    color: $gray-900;
    font-weight: bold;
  `,
  description: css`
    text-align: left;
    line-height: 1.25rem;
    font-weight: normal;
    letter-spacing: 0;
    text-transform: none;
    color: $gray-900;
    margin-bottom: 0.5rem;
  `,
  textSmall: css`
    text-align: left;
    line-height: 1.5rem;
    font-weight: normal;
    letter-spacing: 0;
    text-transform: none;
    padding-left: 0.5rem;
  `,
  icon: css`
    font-size: 1.5rem;
    margin-right: 1rem;
  `,
  kiehlImage: css`
    width: 2rem;
    height: 1rem;
    border-radius: 0.125rem;
  `,
  stbkImage: css`
    width: 1rem;
    height: 1rem;
    border-radius: 0.125rem;
  `,
  heading: css`
    text-align: center;
    margin-bottom: 1rem;
  `,
  cardDesktop: css`
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 18rem;
    max-width: 20rem;
    min-height: 15rem;
    padding: 0rem;
  `,
  cardMobile: css`
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 17.5rem;
  `,
});

function References({ viewer }: Props): React$Node {
  const media = useMedia();
  const modal = useToggleState(false);

  return (
    <>
      <HighlightStripe styleName="py-3 px-0 p-md-4">
        <BrandHeading size={1} style={styles.heading}>
          <Text>Top-Kurse</Text>
        </BrandHeading>
        <View styleName="flex-md-row ai-start">
          <View style={media.up('md') ? styles.cardDesktop : styles.cardMobile}>
            <Button
              color="light"
              to={viewer ? `/courses/x8V1bHNJ42` : `/`}
              styleName="flex-row ai-start jc-start flex-grow-1 flex-shrink-1 my-3 py-3"
            >
              <View styleName="flex-column flex-grow-1 flex-shrink-1">
                <View styleName="flex-row flex-grow-1 flex-shrink-1 jc-between">
                  <Text style={styles.textBold} numberOfLines={2}>
                    Zwischenprüfung
                  </Text>
                  <View styleName="flex-row flex-grow-1 jc-end">
                    <Text style={styles.textBold} color="success">
                      kostenlos
                    </Text>
                  </View>
                </View>
                <Text style={styles.description}>StFA-Azubis Niedersachsen</Text>
                <Text style={styles.description} numberOfLines={3}>
                  Der öffentliche Kurs für die Zwischenprüfung. Viel Erfolg beim Lernen!
                </Text>
                <View styleName="flex-row flex-grow-1 flex-shrink-1 jc-start ai-end">
                  <Image
                    source="/img/logos/stbk-nds-icon.png"
                    style={styles.stbkImage}
                    styleName="mb-1"
                  />
                  <Text color="muted" style={styles.textSmall}>
                    Azubinet
                  </Text>
                </View>
              </View>
            </Button>
          </View>
          <View style={media.up('md') ? styles.cardDesktop : styles.cardMobile}>
            <Button
              color="light"
              to={viewer ? `/courses/hVC9J2S4yq` : `/`}
              styleName="flex-row ai-start jc-start flex-grow-1 flex-shrink-1 my-3 py-3"
            >
              <View styleName="flex-column flex-grow-1 flex-shrink-1">
                <View styleName="flex-row flex-grow-1 flex-shrink-1 jc-between">
                  <Text style={styles.textBold} numberOfLines={2}>
                    Steuerwesen
                  </Text>
                  <View styleName="flex-row flex-grow-1 jc-end">
                    <Text style={styles.textBold} color="success">
                      kostenlos
                    </Text>
                  </View>
                </View>
                <Text style={styles.description}>StFA-Azubis Niedersachsen</Text>
                <Text style={styles.description} numberOfLines={3}>
                  Der öffentliche Kurs für das Fach Steuerwesen in der Abschlussprüfung.
                </Text>
                <View styleName="flex-row flex-grow-1 flex-shrink-1 jc-start ai-end">
                  <Image
                    source="/img/logos/stbk-nds-icon.png"
                    style={styles.stbkImage}
                    styleName="mb-1"
                  />
                  <Text color="muted" style={styles.textSmall}>
                    Azubinet
                  </Text>
                </View>
              </View>
            </Button>
          </View>
          <View style={media.up('md') ? styles.cardDesktop : styles.cardMobile}>
            <Button
              color="light"
              to={viewer ? `/courses/0oWd4bZR1U` : `/`}
              styleName="flex-row ai-start jc-start flex-grow-1 flex-shrink-1 my-3 py-3"
            >
              <View styleName="flex-column flex-grow-1 flex-shrink-1">
                <View styleName="flex-row flex-grow-1 flex-shrink-1 jc-between">
                  <Text style={styles.textBold} numberOfLines={2}>
                    1. Lehrjahr
                  </Text>
                  <View styleName="flex-row flex-grow-1 jc-end">
                    <Text style={styles.textBold} color="success">
                      ab 29,95 €
                    </Text>
                  </View>
                </View>
                <Text style={styles.description}>Steuerfachangestellte</Text>
                <Text style={styles.description} numberOfLines={3}>
                  Exklusiv zur neuen Ausbildungsverordnung: Zahlreiche Erklärvideos, hunderte
                  Lernkarten und Übungstests zum perfekten Einstieg für das erste Lehrjahr!
                  Vergünstigte Konditionen für Mitglieder und Auszubildenden im Bezirk der
                  Steuerberaterkammer Niedersachsen.
                </Text>
                <View styleName="flex-row flex-grow-1 flex-shrink-1 jc-start ai-end">
                  <Image
                    source="/img/logos/stbk-nds-icon.png"
                    style={styles.stbkImage}
                    styleName="mb-1"
                  />
                  <Text color="muted" style={styles.textSmall}>
                    Azubinet
                  </Text>
                </View>
              </View>
            </Button>
            <Button onPress={modal.toggle} styleName="mx-2 mb-3">
              <Text bold>Mehr erfahren</Text>
            </Button>
          </View>
          <View style={media.up('md') ? styles.cardDesktop : styles.cardMobile}>
            <Button
              color="light"
              to={viewer ? `/courses/Dvr7Cpsgnm` : `/`}
              styleName="flex-row ai-start jc-start flex-grow-1 flex-shrink-1 my-3 py-3"
            >
              <View styleName="flex-column flex-grow-1 flex-shrink-1">
                <View styleName="flex-row flex-grow-1 flex-shrink-1 jc-between">
                  <Text style={styles.textBold} numberOfLines={2}>
                    Wirtschafts- und Sozialkunde
                  </Text>
                  <View styleName="flex-row flex-grow-1 jc-end">
                    <Text style={styles.textBold} color="success">
                      19,90 €
                    </Text>
                  </View>
                </View>
                <Text style={styles.description}>Steuerfachangestellte</Text>
                <Text style={styles.description} numberOfLines={3}>
                  Lernkarten Wirtschafts- und Sozialkunde für Steuerfachangestellte Das gesamte
                  Prüfungswissen für Wirtschafts- und Sozialkunde: Schuldrecht Sachenrecht
                  Kaufmannseigenschaft Firma und Handelsregister Prokura und Handlungsvollmacht
                  Unternehmensforme Finanzierung Kreditsicherheiten Arbeitsrecht
                  Lohnabrechnung/Sozialversicherung Die über 350 Lernkarten basieren auf dem
                  bewährten Karteikartensystem, das nachweislich besonders effektiv ist. So legen
                  Sie effizient und zeitsparend die Grundlage für eine erfolgreiche Prüfung. Mit den
                  ausgewählten Aufgaben zu einigen Themenkomplexen können Sie Ihr gelerntes Wissen
                  auf die Probe stellen. Rechtsstand 2020
                </Text>
                <View styleName="flex-row flex-grow-1 flex-shrink-1 jc-between ai-end">
                  <Image
                    source="/img/home/logo-kiehl.png"
                    style={styles.kiehlImage}
                    styleName="mb-1"
                  />
                </View>
              </View>
            </Button>
          </View>
        </View>
        <View styleName="jc-center ai-center px-3 px-md-0">
          <BrandText small>Diese Kurse sind nur für angemeldete Nutzer einsehbar.</BrandText>
        </View>
      </HighlightStripe>
      <FirstYearModal modal={modal} />
    </>
  );
}

export default References;
