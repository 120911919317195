// @flow
import React from 'react';
import { Markdown } from 'components/markdown';
import Statement from './Statement';
import type { TaskExamControls } from '../useExamControls';

type Props = {
  trueFalse: Entity<GQLTrueFalse>,
  examAttemptId: Id,
  controls: TaskExamControls,
};

function TrueFalse({ trueFalse, examAttemptId, controls }: Props): React$Node {
  return (
    <>
      <Markdown variant="article" source={trueFalse.text} styleName="mb-3" />
      {trueFalse.statements.map((statement, key) => (
        <Statement
          key={statement.id}
          trueFalseId={trueFalse.id}
          examAttemptId={examAttemptId}
          statement={statement}
          viewerExamSolution={trueFalse.viewerRunningExamSolution}
          last={trueFalse.statements.length - 1 === key}
          controls={controls}
        />
      ))}
    </>
  );
}

export default TrueFalse;
