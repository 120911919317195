// @flow
import { makeTheme, css } from 'bootstrap-rn';

export default function createTheme(theme: Object): Object {
  return makeTheme(css`
    // Color system

    $brand-primary: ${theme ? theme.primaryColor : '#00586d'};
    $brand-secondary: ${theme ? theme.secondaryColor : '#d33e33'};
    $brand-tertiary: ${theme ? theme.tertiaryColor : '#007f9e'};
    $brand-background: ${theme ? theme.backgroundColor : '#00586d'};

    $transparent-100: rgba(0, 0, 0, 0.05);
    $transparent-200: rgba(0, 0, 0, 0.1);

    // Ref: https://github.com/ColorlibHQ/AdminLTE
    // Ref: https://accessiblepalette.com/?lightness=98,98,94,90,85,74,48,34,25,16&FF6186=0,0&dc4135=0,0&fd7e14=0,0&ffc107=0,0&198719=0,0&20c997=0,0&39CCCC=0,0&00ABBD=0,0&001F3F=0,0&0d6efd=0,0&6f42c1=0,0&6610f2=0,0&adb5bd=0,0
    $white: #fff;
    $gray-100: #f8f9fa; // h: 98
    $gray-200: #e9ecef; // h: 94
    $gray-300: #dee2e6; // h: 90
    $gray-400: #ced4da; // h: 85
    $gray-500: #adb5bd; // h: 74
    $gray-600: #6c757d; // h: 48
    $gray-700: #495057; // h: 34
    $gray-800: #343a40; // h: 25
    $gray-900: #212529; // h: 16
    $black: #000;

    $blue: #0c6af3; // 600
    $indigo: #8a49f5; // 600
    $purple: #835cca; // 600
    $pink: #ff98b0; // 500
    $red: #cf3d32; // 600
    $orange: #fea052; // 500
    $yellow: #e5ad06; // 500
    $green: #188418; // 600
    $teal: #30cd9e; // 500
    $cyan: #38c9c9; // 500

    $primary: ${theme ? theme.buttonColor : '#007C9A'};
    $secondary: $gray-400;
    $success: $green;
    $info: $cyan;
    $warning: $yellow;
    $danger: $red;
    $light: $gray-100;
    $dark: $gray-800;

    // Grid containers
    //
    // Define the maximum width of ".container" for different screen sizes.

    $container-max-widths: ${() => ({
      sm: '1240px',
      md: '1240px',
      lg: '1240px',
      xl: '1240px',
      xxl: '1240px',
    })};

    // Grid columns
    //
    // Set the number of columns and specify the width of the gutters.

    $grid-gutter-width: 1rem;

    // Container padding

    $container-padding-x: $grid-gutter-width;

    // Typography
    //
    // Font, line-height, and color for body text, headings, and more.

    $font-family-sans-serif: Roboto;

    $blockquote-font-size: 1rem;

    // Spacing
    //
    // Control the default styling of most Bootstrap elements by modifying these
    // variables. Mostly focused on spacing.
    // You can add more entries to the $spacers map, should you need more variation.

    $spacer3: 1rem;
    $spacer4: 2rem;
    $spacer5: 4rem;
    $spacer: 1rem;
    $spacers: ${(t) => ({
      '0': '0px',
      '1': `${t.spacer} * 0.25`,
      '2': `${t.spacer} * 0.5`,
      '3': t.spacer,
      '4': `${t.spacer} * 2`,
      '5': `${t.spacer} * 4`,
    })};

    // Body
    //
    // Settings for the "<body>" element.

    $body-color: #101112;
    $body-bg: #fafafa;

    // Links
    //
    // Style anchor elements.

    $link-color: #007c9a; // 600
    $link-decoration: null;
    $link-shade-percentage: 0%;
    $link-hover-decoration: underline;

    // Typography
    //
    // Font, line-height, and color for body text, headings, and more.

    $fontSizeSm: 0.875rem;

    $headings-margin-bottom: 0;
    $headings-font-family: 'Titillium Web';
    $headings-font-weight: 700;

    $text-muted: #6e7378; // 600

    // Buttons + Forms
    //
    // Shared variables that are reassigned to "$input-" and "$btn-" specific variables.

    $input-btn-focus-width: 1px;
    $input-btn-focus-color-opacity: 1;
    $input-btn-focus-color: #5c6d71;

    $input-disabled-bg: #eee;

    // Buttons
    //
    // For each of Bootstrap's buttons, define text, background, and border color.

    $btn-font-size: 0.875rem;

    $btn-font-size-sm: 0.8125rem;

    $btn-font-size-lg: 1rem;

    // Forms

    $input-font-family: Roboto;

    $input-bg: $white;

    $input-focus-border-color: #5c6d71;

    $input-border-radius: 1px;
    $input-border-radius-sm: 1px;
    $input-border-radius-lg: 1px;

    $form-check-input-width: 1.375rem;
    $form-check-padding-start: $form-check-input-width + 0.75rem; // 0.5em;
    $form-check-margin-bottom: 0.5rem;

    $form-switch-width: 2.375rem;

    // Navs

    $nav-link-color: $gray-900;
    $nav-link-hover-color: $gray-900;

    $nav-tabs-link-hover-border-color: $gray-200;
    $nav-tabs-link-active-color: $gray-900;
    $nav-tabs-link-active-bg: null;
    $nav-tabs-link-active-border-color: $gray-300 $gray-300 $danger;

    $nav-pills-border-radius: 0.1875rem;
    $nav-pills-link-active-color: $gray-900;
    $nav-pills-link-active-bg: $transparent-100;

    // Dropdowns
    //
    // Dropdown menu container and contents.

    $dropdown-padding-y: 0.5rem;

    $dropdown-link-hover-bg: $transparent-100;

    $dropdown-item-padding-y: 0.375rem;

    // Cards

    $card-spacer-y: $spacer;
    $card-border-color: $gray-300;
    $card-cap-padding-y: $card-spacer-y * 0.5;
    $card-cap-bg: $white;

    // Badges

    $badge-font-weight: 500;

    // Progress bars

    $progress-border-radius: 0.5rem;

    // List group

    $list-group-bg: transparent;
    $list-group-border-color: $gray-300;

    $list-group-active-color: $gray-900;
    $list-group-active-bg: $gray-200;
    $list-group-active-border-color: $list-group-border-color;

    $list-group-action-color: $gray-900;
    $list-group-action-hover-color: $list-group-action-color;

    $list-group-action-active-color: $list-group-active-color;
    $list-group-action-active-bg: $list-group-active-bg;

    // Offcanvas

    $offcanvas-horizontal-width: 304px;
    $offcanvas-vertical-height: 288px; // for DatePicker, because iOS menu has a height of 216px + 37.5px button + spacing.
  `);
}
