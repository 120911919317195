// @flow
import React from 'react';
import { Text, View, Button } from 'components/core';
import { Icon } from 'components/common';
import { BrandHeading, BrandLargeText, HighlightStripe } from 'components/pages';
import { StyleSheet, css, useMedia } from 'libs/ui';

const styles = StyleSheet.create({
  icon: css`
    color: #fff;
  `,
  iconDesktop: css`
    font-size: 4.5rem;
    margin-left: 3rem;
    margin-right: 3rem;
    color: #13573b;
  `,
  heading: css`
    margin-bottom: 2rem;
    color: #13573b;
  `,
});

function TrainerAccessInfoBox(): React$Node {
  const media = useMedia();

  return (
    <HighlightStripe styleName="flex-md-row py-md-4 pr-md-4 ai-center jc-center">
      {media.up('md') && <Icon glyph="users" style={styles.iconDesktop} />}
      <View styleName="flex-column flex-shrink-1">
        <BrandHeading style={styles.heading} size={3}>
          <Text>Kostenloser Zugang für Trainer*innen und Lehrkräfte</Text>
        </BrandHeading>
        <BrandLargeText>
          Begleiten Sie als Trainer*in oder Lehrkraft Ihre Teilnehmer*innen, Schüler*innen bzw.
          Auszubildenden, indem Sie hilfreiche Lernstatistiken und -Ergebnisse nutzen und so
          frühzeitig auf mögliche Probleme beim Lernen reagieren. Die Administrationsfunktionen sind
          für Sie als Trainer*in oder Lehrkraft vollkommen kostenlos!
        </BrandLargeText>
        <View styleName="ai-md-start mt-4">
          <Button to="/request-access" size="lg">
            <Text>Zugang anfordern</Text>
          </Button>
        </View>
      </View>
    </HighlightStripe>
  );
}

export default TrainerAccessInfoBox;
