// @flow

let handler;

const Cookies = {
  init(cookies: AppCookies) {
    handler = cookies;
  },
  get(name: string): string {
    return handler.get(name);
  },
  set(name: string, value: string, days: ?number) {
    const options = {
      path: '/',
      expires: undefined,
    };

    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

      options.expires = date;
    }

    handler.set(name, value, options);
  },
  setRaw(name: string, value: string, options: Object) {
    handler.set(name, value, options);
  },
  all(): Object {
    return handler.all();
  },
};

export default Cookies;
