// @flow
import React from 'react';
import { View, Container } from 'components/core';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  children: React$Node,
  color?: 'white' | 'gray',
};

const styles = StyleSheet.create({
  container: css`
    padding-vertical: $spacer5;
  `,
  containerWhite: css`
    background-color: white;
  `,
  containerGray: css`
    background-color: #f3f5f6;
  `,
});

const defaultProps = {
  color: 'white',
};

function Section({ children, color }: Props): React$Node {
  return (
    <View
      style={[color === 'white' && styles.containerWhite, color === 'gray' && styles.containerGray]}
    >
      <Container
        style={[
          styles.container,
          color === 'white' && styles.containerWhite,
          color === 'gray' && styles.containerGray,
        ]}
      >
        {children}
      </Container>
    </View>
  );
}

Section.defaultProps = defaultProps;

export default Section;
