// @flow
import React, { useMemo } from 'react';
import { useMedia } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';
import { QuizCard } from 'components/quizzes';
import { Scrollbars } from 'components/common';
import { Text, View, Badge, Blockquote } from 'components/core';
import { Markdown } from 'components/markdown';
import ChoiceCardOption from './ChoiceCardOption';
import type { QuizState, CurrentQuizCardState } from '../useQuizState';

type Props = {
  choiceCard: Entity<GQLChoiceCard>,
  quiz: Entity<GQLQuiz>,
  quizState: QuizState,
  currentCardState: CurrentQuizCardState,
};

const dict = createDict({
  question: 'Question',
  single_choice: 'Single Choice',
  multiple_choice: 'Multiple Choice',
  choose_an_answer: 'Choose an answer...',
  choose_one_or_multiple_answers: 'Choose one or multiple answers...',
  solution: 'Solution',
  hint: 'Hint',
});

const shuffleArray = (arr) => {
  return [...arr].sort(() => Math.random() - 0.5);
};

function ChoiceCard({ choiceCard, quiz, quizState, currentCardState }: Props): React$Node {
  const media = useMedia();
  const { hint } = choiceCard;

  const DesktopScrollbars = media.up('lg') ? Scrollbars : View;

  const answers = useMemo(() => {
    return shuffleArray(choiceCard.answers);
  }, [choiceCard.id]);

  return (
    <QuizCard>
      <QuizCard.Front styleName="p-0">
        <DesktopScrollbars>
          <View styleName="p-3 p-lg-4">
            <View styleName="flex-row mb-3 jc-between ai-center">
              <Text color="muted" styleName="pr-3">
                <Trans {...dict('question')} /> #{currentCardState.number}
              </Text>
              <Badge color="secondary">
                <Trans {...dict(choiceCard.single ? 'single_choice' : 'multiple_choice')} />
              </Badge>
            </View>
            <Markdown source={choiceCard.text} />
          </View>
        </DesktopScrollbars>
      </QuizCard.Front>
      <QuizCard.Back styleName="p-0">
        <DesktopScrollbars
          // Scroll to top of card back (by recreating element) on flip (desktop)
          key={currentCardState.flipped ? 1 : 0}
        >
          <View styleName="p-3 p-lg-4">
            <Text styleName="pb-3">
              {currentCardState.flipped ? (
                <Trans {...dict('solution')} color="muted" />
              ) : (
                <Trans
                  {...dict(
                    choiceCard.single ? 'choose_an_answer' : 'choose_one_or_multiple_answers',
                  )}
                  color="primary"
                />
              )}
            </Text>
            {answers.map((answer, key) => (
              <View styleName={key < answers.length - 1 ? 'mb-4' : undefined} key={answer.id}>
                <ChoiceCardOption
                  choiceCard={choiceCard}
                  quiz={quiz}
                  answer={answer}
                  quizState={quizState}
                  currentCardState={currentCardState}
                />
              </View>
            ))}
            {hint && currentCardState.flipped && (
              <Blockquote styleName="mt-3">
                <Trans {...dict('hint')} color="muted" small styleName="mb-2" />
                <Markdown source={hint} />
              </Blockquote>
            )}
          </View>
        </DesktopScrollbars>
      </QuizCard.Back>
    </QuizCard>
  );
}

export default ChoiceCard;
