// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query, selectByRoot } from 'libs/graphql';

const TeamMemberList = {
  name: 'TeamMemberList',
  bundle: () => import('./TeamMemberList'),
};

const ORGANIZATION_TEAM_MEMBERS_QUERY = gql`
  query OrganizationQuery($slug: String!, $teamSlug: String!) {
    organization(slug: $slug) {
      name
      slug
      team(teamSlug: $teamSlug) {
        name
        slug
        memberships {
          isAdmin
          user {
            name
            avatar {
              file {
                name
                extension
                info {
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
        }
        viewerCanUpdate
      }
    }
  }
`;

const Container = createAsyncContainer(TeamMemberList, (props) => {
  const {
    params: { slug, teamSlug },
  } = props;

  const variables = { slug, teamSlug };

  const loaders = {
    query: query(ORGANIZATION_TEAM_MEMBERS_QUERY, { variables }),
  };

  const selectors = {
    organization: select(ORGANIZATION_TEAM_MEMBERS_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
