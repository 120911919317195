// @flow
import React from 'react';
import BundleCompact from './BundleCompact';

type Props = {
  onClose?: () => void,
};

function KochBundles({ onClose }: Props): React$Node {
  return (
    <>
      <BundleCompact
        to="/courses/tTgcqHF3ff"
        title="Erstmal reinschnuppern"
        price="kostenlose Lernmaterialien und Community"
        onClose={onClose}
      />
      <BundleCompact
        to="/professions/koechin-koch"
        title="Abschlussprüfung"
        price="ab 24,95 EUR"
        paid
      />
    </>
  );
}

export default KochBundles;
