// @flow
import React from 'react';
import { StyleSheet, css, useMedia } from 'libs/ui';
import BrandText from './BrandText';

type Props = {
  course: string,
};

const styles = StyleSheet.create({
  textDesktop: css`
    line-height: 1.25rem;
    font-weight: normal;
    letter-spacing: 0;
    text-transform: none;
    font-size: 1rem;
    margin: 0.25rem 0rem 0.25rem 0rem;
    text-align: left;
  `,
  textMobile: css`
    line-height: 1.25rem;
    font-weight: normal;
    letter-spacing: 0;
    text-transform: none;
    font-size: 1rem;
    margin: 0.25rem 0rem 0.25rem 0rem;
    text-align: left;
  `,
});

function FeaturedCourse(props: Props): React$Node {
  const { course } = props;
  const media = useMedia();

  return (
    <BrandText style={media.up('md') ? styles.textDesktop : styles.textMobile} numberOfLines={2}>
      {course}
    </BrandText>
  );
}

export default FeaturedCourse;
