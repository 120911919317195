// @flow
import React from 'react';
import { Grid } from 'components/core';
import FeaturesBox from './FeaturesBox';

function FeaturesBoxContent(): React$Node {
  return (
    <>
      <Grid styleName="mx-0">
        <FeaturesBox
          title="Übersicht"
          icon="book-open"
          content="Neuigkeiten in VogelSPOT und rund um das Thema IHK-Prüfung auf einen Blick."
          type="grey"
        />
        <FeaturesBox
          title="Prüfungssimulationen"
          icon="award"
          content="Die Zeit läuft unter möglichst realen Bedingungen mit anschließender Auswertung der Antworten."
          type="grey"
        />
        <FeaturesBox
          title="Lernbox"
          icon="cards"
          content="Nach Themen gegliederte Fragen werden so lange gestellt, bis sie zweimal richtig beantwortet wurden."
          type="grey"
        />
      </Grid>
      <Grid styleName="mt-md-5 mx-0">
        <FeaturesBox
          title="Dokumente"
          icon="doc"
          content="Zusätzliches Info-Material für eine erfolgreiche Prüfung."
          type="green"
        />
        <FeaturesBox
          title="Artikel"
          icon="article"
          content="Viele Info-Beiträge zum besseren Verständnis der Prüfungsthemen."
          type="green"
        />
        <FeaturesBox
          title="Forum"
          icon="chat"
          content="Tauschen Sie sich im Kurs mit anderen Lernenden aus!"
          type="green"
        />
      </Grid>
    </>
  );
}

export default FeaturesBoxContent;
