// @flow
import React from 'react';
import { Text, Nav } from 'components/core';
import { ActivityIcon, Icon } from 'components/common';
import { getActivityLink } from 'utils/courses';
import { StyleSheet, css } from 'libs/ui';
import { useMenu } from 'libs/foundation';

type Props = {
  activity: Entity<GQLCourseActivity>,
};

const styles = StyleSheet.create({
  indented: css`
    padding-horizontal: 0.75rem;
  `,
  todoIcon: css`
    margin-right: 0.75rem;
  `,
  checked: css`
    color: $success;
  `,
  unchecked: css`
    color: transparent;
  `,
});

function ChecklistTodoCourseActivity({ activity }: Props): React$Node {
  const { course } = activity;
  const menu = useMenu();

  return (
    <Nav.Link
      to={getActivityLink(course, activity)}
      onPress={() => {
        menu.clear();
      }}
      style={styles.indented}
      styleName="flex-row ai-center"
    >
      <ActivityIcon activity={activity} size="sm" style={styles.todoIcon} />
      <Text small styleName="flex-grow-1 flex-shrink-1">
        {activity.title}
      </Text>
      <Icon
        glyph="ok-squared"
        style={activity.viewerResult ? styles.checked : styles.unchecked}
        styleName="ml-2"
      />
    </Nav.Link>
  );
}

export default ChecklistTodoCourseActivity;
