// @flow
import React from 'react';
import { ClozeInput } from 'components/tasks';
import { Form } from 'components/forms';
import { useDispatch } from 'libs/graphql';
import { updateOrCreateSolution } from './mutations';
import AutoSubmit from './AutoSubmit';
import type { TaskExamControls } from '../useExamControls';

type Props = {
  cloze: Entity<GQLCloze>,
  examAttemptId: Id,
  controls: TaskExamControls,
};

const getInitialValues = (solution) => {
  if (!solution) {
    return {};
  }

  const values = {};

  solution.gapValues.forEach((value) => {
    values[value.key] = value.value;
  });

  return values;
};

const hasChangedValues = (cloze, initialValues, values) => {
  return cloze.gaps.some((gap) => {
    return initialValues[gap.key] !== values[gap.key];
  });
};

function Cloze({ cloze, examAttemptId, controls }: Props): React$Node {
  const dispatch = useDispatch();

  const initialGaps = getInitialValues(cloze.viewerRunningExamSolution);

  return (
    <Form
      initialValues={{
        gaps: initialGaps,
      }}
      onSubmit={(values) => {
        // Don't fire mutation if there are no changes.
        if (!hasChangedValues(cloze, initialGaps, values.gaps)) {
          return null;
        }

        const gapValues = cloze.gaps
          .filter((gap) => values.gaps[gap.key])
          .map((gap) => ({
            key: gap.key,
            value: values.gaps[gap.key],
          }));

        const gapValueKeysToRemove = cloze.gaps
          .filter((gap) => !values.gaps[gap.key])
          .map((gap) => gap.key);

        return dispatch(
          updateOrCreateSolution(cloze.id, examAttemptId, gapValues, gapValueKeysToRemove),
        );
      }}
    >
      <ClozeInput name="gaps" cloze={cloze} />
      <AutoSubmit controls={controls} />
    </Form>
  );
}

export default Cloze;
