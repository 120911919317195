// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import { DOCUMENT_ACTIVITY_VERSION_ICON_FRAGMENT } from '../DocumentActivityVersionIcon/fragments';

export const ACTIVITY_DETAILS_TITLE_FRAGMENT: DocumentNode = gql`
  fragment ActivityDetailsTitleFragment on CourseActivity {
    title
    published
    version(versionNumber: $versionNumber, specificVersion: $specificVersion) {
      current
      ...DocumentActivityVersionIconFragment
    }
  }

  ${DOCUMENT_ACTIVITY_VERSION_ICON_FRAGMENT}
`;

export const ACTIVITY_DETAILS_TITLE_EXAM_ATTEMPT_FRAGMENT: DocumentNode = gql`
  fragment ActivityDetailsTitleExamAttemptFragment on ExamActivity {
    title
    published
    versionForAttempt(attemptId: $attemptId) {
      current
    }
  }
`;

export const ACTIVITY_DETAILS_TITLE_QUIZ_RESULT_FRAGMENT: DocumentNode = gql`
  fragment ActivityDetailsTitleQuizResultFragment on QuizActivity {
    title
    published
    versionForResult(resultId: $resultId) {
      current
    }
  }
`;
