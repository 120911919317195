// @flow
import { gql, createMutation } from 'libs/graphql';

const CREATE_FILE_ATTACHMENT = gql`
  mutation CreateFileAttachment($input: FileAttachmentCreateInput!) {
    file_attach(input: $input) {
      name
      encodedName
      extension
      info {
        image {
          height
          width
        }
      }
    }
  }
`;

export function createFileAttachment(
  fileId: Id,
  attachableId: Id,
  attachableType: Type,
): MutationAction {
  return createMutation(CREATE_FILE_ATTACHMENT, {
    variables: {
      input: {
        fileId,
        attachableId,
        attachableType,
      },
    },

    updater: (store, data) => {
      // $FlowFixMe[incompatible-call]
      store.update(attachableType, attachableId, (record) => {
        record.set('files', (links) => links.append(data.roots.file_attach));
      });
    },
  });
}

const DELETE_FILE_ATTACHMENT = gql`
  mutation DeleteFileAttachment($id: ClientID!) {
    file_delete(id: $id)
  }
`;

export function deleteFileAttachment(
  id: Id,
  attachableId: Id,
  attachableType: Type,
): MutationAction {
  return createMutation(DELETE_FILE_ATTACHMENT, {
    variables: {
      id,
    },
    updater: (store) => {
      // $FlowFixMe[incompatible-call]
      store.update(attachableType, attachableId, (record) => {
        record.set('files', (links) => links.detach('File', id));
      });
    },
  });
}
