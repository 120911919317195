// @flow
import React from 'react';
import { createContainer as createBaseContainer } from 'react-transporter';
import { useParams, useSearchParams } from 'libs/routing';
import withAnalytics from './withAnalytics';

export default function createContainer(
  component: React$ComponentType<any>,
  container: Object,
  options: Object,
): React$AbstractComponent<any, mixed> {
  const componentWithAnalytics = withAnalytics(component);

  const Container = createBaseContainer(componentWithAnalytics, container, options);

  return (props) => {
    const params = useParams();
    const [searchParams] = useSearchParams();

    return <Container {...props} params={params} searchParams={searchParams} />;
  };
}
