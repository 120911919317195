// @flow
import React from 'react';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';
import { Icon, FooterLink } from 'components/common';
import { View, Container, Grid, Text } from 'components/core';
import LocaleSwitcher from './LocaleSwitcher';
import ViewSwitcher from './ViewSwitcher';

const styles = StyleSheet.create({
  footer: css`
    background-color: $gray-800;
    width: 100%;
    margin-top: 5rem;
    padding-top: 0;
    flex-shrink: 0;
  `,
  footerDesktop: css`
    min-height: 10rem;
  `,
  footerMobile: css`
    min-height: 16rem;
  `,
  container: css`
    padding: 1rem;
  `,
  containerMobile: css`
    max-width: 560px;
  `,
  infoMobile: css`
    border-bottom-width: 1px;
    border-bottom-color: rgba(255, 255, 255, 0.05);
    margin-bottom: 1rem;
    align-items: center;
  `,
  copyright: css`
    padding-top: 1rem;
    text-align: center;
  `,
  text: css`
    color: $gray-400;
  `,
});

const dict = createDict({
  home: 'Home',
  about_us: 'Team',
  legal_contact: 'Legal Note & Contact',
  blog: 'Blog',
  faq: 'FAQ',
  privacy: 'Privacy',
  terms_of_use: 'Terms of Use',
  about_examunity: 'About Examunity',
});

const currentYear = new Date().getFullYear();

function FooterExamunity(): React$Node {
  const media = useMedia();

  return (
    <>
      <View
        role="contentinfo"
        style={[styles.footer, media.up('md') ? styles.footerDesktop : styles.footerMobile]}
      >
        <Container style={[styles.container, media.down('sm') && styles.containerMobile]}>
          <Grid>
            <Grid.Box size={12} sizeMd={6} style={media.down('sm') && styles.infoMobile}>
              {/* language */}
              <LocaleSwitcher />

              {/* desktop/mobile toggle */}
              <ViewSwitcher />
              <View styleName="mb-3 flex-row ai-center ai-md-start">
                <Text style={styles.text} small>
                  <Icon glyph="facebook" /> &nbsp;
                  <FooterLink to="https://www.facebook.com/examunity" external>
                    Facebook
                  </FooterLink>
                </Text>
                <Text style={styles.text} small>
                  <Text> · </Text>
                  <Icon glyph="instagram" /> &nbsp;
                  <FooterLink to="https://www.instagram.com/examunityofficial" external>
                    Instagram
                  </FooterLink>
                </Text>
              </View>
            </Grid.Box>
            <Grid.Box size={12} sizeMd={6}>
              {/* links */}
              <View styleName="mb-4 ai-center ai-md-end">
                <View styleName="ai-center ai-md-start mr-md-5">
                  <Text style={styles.text} small>
                    <Trans {...dict('about_examunity')} />
                  </Text>
                  <Text style={styles.text} small>
                    <FooterLink to="/">
                      <Trans {...dict('home')} />
                    </FooterLink>
                  </Text>
                  <Text style={styles.text} small>
                    <FooterLink to="/about-us">
                      <Trans {...dict('about_us')} />
                    </FooterLink>
                  </Text>
                  <Text style={styles.text} small>
                    <FooterLink to="/faq">
                      <Trans {...dict('faq')} />
                    </FooterLink>
                  </Text>
                  {/*
              <Text style={styles.text} small>
                <FooterLink to="/blog">
                  <Trans {...dict('blog')} />
                </FooterLink></Text> */}
                  <Text style={styles.text} small>
                    <FooterLink to="/legal-contact">
                      <Trans {...dict('legal_contact')} />
                    </FooterLink>
                  </Text>
                  <Text style={styles.text} small>
                    <FooterLink to="/privacy-policy">
                      <Trans {...dict('privacy')} />
                    </FooterLink>
                  </Text>
                  <Text style={styles.text} small>
                    <FooterLink to="/terms-of-use">
                      <Trans {...dict('terms_of_use')} />
                    </FooterLink>
                  </Text>
                </View>
              </View>
            </Grid.Box>
          </Grid>
          <Text small style={[styles.copyright, styles.text]}>
            © Examunity {currentYear}
          </Text>
        </Container>
      </View>
    </>
  );
}

export default FooterExamunity;
