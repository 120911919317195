// @flow
import React from 'react';
import { View, Text } from 'components/core';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  children: React$Node,
  variant: ?('true' | 'false'),
};

const getColor = (variant) => {
  if (variant === 'true') {
    return 'counterGreen';
  }
  if (variant === 'false') {
    return 'counterRed';
  }

  return 'counterDefault';
};

const styles = StyleSheet.create({
  container: css`
    width: 2.5rem;
    border-left-width: 1px;
    border-left-color: $gray-300;
  `,
  counter: css`
    text-align: center;
    font-family: 'Titillium Web';
    font-size: 1rem;
  `,
  counterDefault: css`
    color: $gray-900;
  `,
  counterGreen: css`
    color: green;
  `,
  counterRed: css`
    color: red;
  `,
});

function TaskChoiceCounter({ children, variant }: Props): React$Node {
  return (
    <View style={styles.container}>
      <Text style={[styles.counter, styles[getColor(variant)]]}>{children}</Text>
    </View>
  );
}

export default TaskChoiceCounter;
