// @flow
import React from 'react';
import { Icon, LargeText } from 'components/common';
import { View, Text, Grid, Heading } from 'components/core';
import { StyleSheet, css, useMedia } from 'libs/ui';

type Props = {
  title: string,
  children: React$Node,
  type: string,
  icon: string,
};

const styles = StyleSheet.create({
  fontColorDark: css`
    color: #001f3f;
  `,
  fontColorBlue: css`
    color: #0084a6;
  `,
  fontColorOrange: css`
    color: #e06450;
  `,
  gridBoxDesktop: css`
    align-items: center;
    padding: 0rem 1rem 2rem 1rem;
  `,
  boxViewDesktop: css`
    justify-content: center;
    align-items: center;
  `,
  boxImageDesktop: css`
    flex-direction: row;
    margin: 1rem 0rem 0.5rem 0rem;
    justify-content: center;
    align-items: center;
  `,
  title: css`
    line-height: 1.75rem;
    letter-spacing: 0;
    font-size: 1.125rem;
    text-align: center;
  `,
  gridBoxMobile: css`
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  `,
  boxViewMobile: css`
    margin-horizontal: 1.5rem;
    justify-content: center;
    align-items: center;
  `,
  boxImageMobile: css`
    align-items: center;
    justify-content: center;
  `,
  iconDesktop: css`
    color: $gray-700;
    font-size: 1.375rem;
    padding-right: 0.5rem;
  `,
  iconMobile: css`
    color: $gray-700;
    padding-bottom: 0.5rem;
  `,
});

function FeaturesBox(props: Props): React$Node {
  const { title, children, type, icon } = props;
  const media = useMedia();

  let titleColor = styles.fontColorOrange;
  if (type === 'blue') {
    titleColor = styles.fontColorBlue;
  }
  if (type === 'dark') {
    titleColor = styles.fontColorDark;
  }
  return (
    <>
      {media.up('md') && (
        <Grid.Box size={12} sizeMd={4} style={[styles.gridBoxDesktop]}>
          <View style={styles.boxViewDesktop}>
            <View style={styles.boxImageDesktop}>
              <Icon glyph={icon} style={[styles.iconDesktop, titleColor]} />
              <Heading size={5} style={[styles.title, titleColor]}>
                {title}
              </Heading>
            </View>
            <Text align="center">{children}</Text>
          </View>
        </Grid.Box>
      )}
      {media.down('sm') && (
        <Grid.Box size={12} sizeMd={4} style={[styles.gridBoxMobile]}>
          <View style={styles.boxViewMobile}>
            <View style={styles.boxImageMobile}>
              <LargeText style={styles.iconMobile}>
                <Icon glyph={icon} />
              </LargeText>
            </View>
            <Heading size={5}>
              <Text style={[styles.title, titleColor]}>{title}</Text>
            </Heading>
            <Text align="center">{children}</Text>
          </View>
        </Grid.Box>
      )}
    </>
  );
}

export default FeaturesBox;
