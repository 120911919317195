// @flow
import locateEmphasis from 'remark-parse/lib/locate/emphasis';
import { ASTERISK, UNDERSCORE, BACKSLASH } from '../utils/chars';

const reWhitespace = /\s/;
const reDelimiter = /^(\*+|_+)/;

const isWhitespace = (value, index) => {
  return reWhitespace.test(value.charAt(index));
};

const isLeftFlanking = (value, index, length) => {
  return !isWhitespace(value, index - length - 1);
};

const isRightFlanking = (value, index) => {
  return !isWhitespace(value, index);
};

const getType = (openings, closings) => {
  if (openings % 3 === 1 || closings % 3 === 1) {
    return 1;
  }

  if (openings % 3 === 2 || closings % 3 === 2) {
    return 2;
  }

  return 1;
};

// Reference: https://github.github.com/gfm/#emphasis-and-strong-emphasis
export default function emphasis() {
  const { Parser } = this;
  const tokenizers = Parser.prototype.inlineTokenizers;

  // TODO: Implement full definition of a delimiter run (so far only (1) is
  // implemented). Implement rule 17 of gfm spec.
  function tokenizeEmphasis(eat, value, silent) {
    const self = this;
    const marker = value.charAt(0);

    if (marker !== ASTERISK && marker !== UNDERSCORE) {
      return undefined;
    }

    const initialOpenings = value.match(reDelimiter)[0].length;

    if (!isRightFlanking(value, initialOpenings)) {
      return undefined;
    }

    let index = initialOpenings;
    let openings = initialOpenings;

    while (index < value.length) {
      // Delimiter found.
      if (value.charAt(index) === marker) {
        const { length } = value.slice(index).match(reDelimiter)[0];
        index += length;

        if (isRightFlanking(value, index) && !isLeftFlanking(value, index, length)) {
          // Marker is another opening marker.
          openings += length;
        } else if (isLeftFlanking(value, index, length)) {
          // Update openings count.
          openings -= length;

          // Marker is closing marker, eat value.
          if (openings <= 0) {
            if (silent) {
              return true;
            }

            const now = eat.now();
            const type = getType(initialOpenings, length + openings);

            now.column += type;
            now.offset += type;

            return eat(value.slice(0, index + openings))({
              type: type === 1 ? 'emphasis' : 'strong',
              children: self.tokenizeInline(value.slice(type, index - type + openings), now),
            });
          }
        }
      }

      // Backslash found.
      if (value.charAt(index) === BACKSLASH) {
        index += 1;
      }

      index += 1;
    }

    return undefined;
  }

  tokenizeEmphasis.locator = locateEmphasis;

  tokenizers.emphasis = tokenizeEmphasis;

  // Disable strong tokenizer, because strong emphasis is also handled by this
  // tokenizer.
  function tokenizeStrong() {
    return undefined;
  }

  tokenizeStrong.locator = () => -1;

  tokenizers.strong = tokenizeStrong;
}
