// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql, selectByRoot } from 'libs/graphql';
import { ACTIVITIES_FRAGMENT } from '../_shared/ActivityList/fragments';

const TaskCollectionList = {
  name: 'TaskCollectionList',
  bundle: () => import('./TaskCollectionList'),
};

const TASK_COLLECTION_LIST_QUERY = gql`
  query CourseTaskCollectionList($courseSlug: String!, $activityType: CourseActivityType) {
    course(slug: $courseSlug) {
      examActivitiesCount
      sections {
        tasksDescription
      }
      restricted
      emailRestriction
      organizationRestriction
      isPrivate
      ...ActivitiesFragment
      viewerCanCreateTasks
      viewerIsObserver
      viewerIsMember
      viewerIsAdmin
    }
  }

  ${ACTIVITIES_FRAGMENT}
`;

const VIEWER_RUNNING_TASK_EXAM_QUERY = gql`
  query ViewerRunningExamQuery {
    viewerRunningTaskExam {
      course {
        slug
      }
      viewerRunningAttempt {
        expiredAt
      }
    }
  }
`;

const Container = createAsyncContainer(TaskCollectionList, (props) => {
  const {
    params: { courseSlug },
    searchParams,
  } = props;

  const loaders = {
    taskCollectionListQuery: query(TASK_COLLECTION_LIST_QUERY, {
      variables: {
        courseSlug,
        activityType: 'Exam',
        // Add activityId to reload query if an activity has been copied
        activityId: searchParams.get('activity_id'),
      },
    }),
    viewerRunningTaskExamQuery: query(VIEWER_RUNNING_TASK_EXAM_QUERY),
  };

  const selectors = {
    course: select(TASK_COLLECTION_LIST_QUERY, { variables: { courseSlug } }),
    viewerRunningTaskExam: select(VIEWER_RUNNING_TASK_EXAM_QUERY),
    viewer: selectByRoot('viewer', (viewer) =>
      viewer
        .join(['courseMemberships', { withTeams: true }], (courseMembership) =>
          courseMembership.join('course'),
        )
        .join('organizationMemberships'),
    ),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
