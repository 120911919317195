// @flow
import React from 'react';
import { ListGroup } from 'components/core';
import BundleCompact from './BundleCompact';

type Props = {
  onClose?: () => void,
};

function FachkraftFuerLagerlogistikBundles({ onClose }: Props): React$Node {
  return (
    <ListGroup>
      <BundleCompact
        to="/courses/BWUrPiP8hB"
        title="Erstmal reinschnuppern"
        price="kostenlose Lernmaterialien und Community"
        onClose={onClose}
      />
      <BundleCompact
        to="/professions/fachkraft-fuer-lagerlogistik"
        title="Lernpaket Lagerlogistik"
        price="ab 26,95 EUR"
        paid
      />
    </ListGroup>
  );
}

export default FachkraftFuerLagerlogistikBundles;
