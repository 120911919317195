// @flow
import { gql, createMutation } from 'libs/graphql';
import { COURSES_DROPDOWN_FRAGMENT } from 'views/_root/App/Container/fragments';

const CREATE_COURSE_MEMBERSHIP = gql`
  mutation CreateCourseMembership($input: CourseMembershipCreateInput!) {
    courseMembership_create(input: $input) {
      role
      licensed {
        validUntil
      }
      createdAt
      vouchers {
        memberships {
          role
          licensed {
            validUntil
          }
          createdAt
          course {
            ...CoursesDropdownFragment
          }
        }
      }
      course {
        ...CoursesDropdownFragment
      }
    }
  }

  ${COURSES_DROPDOWN_FRAGMENT}
`;

const getEntity = (link, data) => {
  return data.entities[link[0]][link[1]];
};

export function createCourseMembership(
  courseId: Id,
  viewerId: Id,
  password: ?string,
  type?: 'VOUCHER' | 'PASSWORD',
): MutationAction {
  return createMutation(CREATE_COURSE_MEMBERSHIP, {
    variables: {
      input: {
        courseId,
        userId: viewerId,
        password,
      },
    },
    updater: (store, data) => {
      // Add new course memberships to viewer course memberships:
      const membership = getEntity(data.roots.courseMembership_create.link, data);

      if (type !== 'VOUCHER') {
        store.update('User', viewerId, (record) => {
          // $FlowFixMe[invalid-computed-prop]
          record.set(['courseMemberships', { withTeams: true }], (links) =>
            links.prepend(data.roots.courseMembership_create),
          );

          record.set('promotedCourses', (links) => links.detach(membership.course));
        });
      } else {
        // We will assume that the voucher that was added last is the voucher
        // that is used for this mutation.
        const latestVoucherLink = membership.vouchers.link[membership.vouchers.link.length - 1];
        const voucher = getEntity(latestVoucherLink, data);

        const joinedMembershipLinks = voucher.memberships.link;
        const joinedCourseLinks = joinedMembershipLinks.map((link) => {
          const joinedCourseMembership = getEntity(link, data);

          return joinedCourseMembership.course.link;
        });

        store.update('User', viewerId, (record) => {
          // Use syncPrepend here, because the user might already be a member
          // of some courses.
          // $FlowFixMe[invalid-computed-prop]
          record.set(['courseMemberships', { withTeams: true }], (links) =>
            links.syncPrepend(joinedMembershipLinks),
          );

          record.set('promotedCourses', (links) => links.detach(joinedCourseLinks));
        });
      }
    },
  });
}

const UPDATE_OR_CREATE_PRODUCT_OFFER_PURCHASE = gql`
  mutation UpdateOrCreateProductOfferPurchase($input: ProductOfferPurchaseUpdateOrCreateInput!) {
    productOfferPurchase_updateOrCreate(input: $input) {
      id
    }
  }
`;

export function updateOrCreateProductOfferPurchase(pricingId: Id): MutationAction {
  return createMutation(UPDATE_OR_CREATE_PRODUCT_OFFER_PURCHASE, {
    variables: {
      input: {
        pricingId,
      },
    },
  });
}
