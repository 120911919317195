// @flow
import React from 'react';
import { ScrollArea } from 'libs/scroll';
import { View } from 'components/core';
import { Outlet } from 'libs/routing';
import { Domain } from 'libs/misc';
import RunningTaskExam from './RunningTaskExam';
import Navigation from './Navigation';
import Content from './Content';
import Footer from './Footer';
import Menu from './Menu';
import InstallPwaPrompt from './InstallPwaPrompt';

type Props = {
  viewer: ?Entity<GQLUser>,
  domain: Entity<GQLDomain>,
  variant?: ?'pages',
};

function AppLayout({ viewer, domain, variant }: Props): React$Node {
  const hasAlternativeNavigation =
    (Domain.is('www.azubinet.de') || Domain.is('www.xn--fahrradprfung-4ob.de')) &&
    variant === 'pages';

  return (
    <>
      <Navigation variant={variant} />
      <View styleName="flex-row flex-grow-1 flex-shrink-1">
        {viewer &&
          (viewer.isOrganizationAdminOrTrainer || !domain.primaryCourse) &&
          !hasAlternativeNavigation && <Menu viewerId={viewer.id} />}
        <ScrollArea>
          <RunningTaskExam />
          <Content>
            <Outlet />
          </Content>
          <Footer />
        </ScrollArea>
      </View>
      {viewer && domain.pwa && <InstallPwaPrompt />}
    </>
  );
}

export default AppLayout;
