// @flow
import React from 'react';
import { Platform } from 'react-native';
import { StyleSheet, css } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';
import { useApp } from 'libs/foundation';
import { Api, Domain } from 'libs/misc';
import { NavbarLink, NavbarMenu } from 'components/navbar';
import { Avatar } from 'components/common';
import { Dropdown, Text } from 'components/core';
import { useNavigate } from 'libs/routing';
import Caret from './Caret';

type Props = {
  viewer: Entity<GQLUser>,
  domain: Entity<GQLDomain>,
};

const styles = StyleSheet.create({
  menu: css`
    width: 200px;
    height: auto;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  `,
});

const dict = createDict({
  go_to_dashboard: 'Go to dashboard',
  reports: 'Reports',
  faq: 'FAQ',
  info: 'Info',
  settings: 'Settings',
  log_out: 'Log out',
  record_book: 'Record book',
  checklists: 'Checklists',
});

function UserDropdown({ viewer, domain }: Props): React$Node {
  const navigate = useNavigate();
  const { reset } = useApp();

  const handleLogoutClick = () => {
    Api.post('/api/auth/logout').then(() => {
      reset(() => {
        navigate(Platform.OS === 'web' ? '/' : '/login');
      });
    });
  };

  return (
    <Dropdown display="static">
      <NavbarLink toggle={Dropdown} caret={false}>
        <Avatar avatar={viewer.avatar} />
      </NavbarLink>
      <NavbarMenu offset={0} offsetXs={0} style={styles.menu}>
        <Caret position={22} positionXs={14} styleName="mt-n2 mb-2" />
        <Dropdown.Item to="/dashboard">
          <Text>{viewer.name}</Text>
          <Trans {...dict('go_to_dashboard')} color="muted" small />
        </Dropdown.Item>
        <Dropdown.Item to="/settings/profile">
          <Trans {...dict('settings')} />
        </Dropdown.Item>
        {!(Domain.is('www.vogelspot.de') || Domain.is('www.xn--fahrradprfung-4ob.de')) && (
          <>
            <Dropdown.Divider />
            <Dropdown.Item to="/my-checklists" styleName="flex-row jc-between ai-center">
              <Trans {...dict('checklists')} />
            </Dropdown.Item>
            {domain.hasReportCollections && (
              <Dropdown.Item to="/my-reports">
                <Trans {...dict('record_book')} />
              </Dropdown.Item>
            )}
          </>
        )}
        <Dropdown.Divider />
        {Platform.OS !== 'web' && (
          <>
            <Dropdown.Item to="/faq">
              <Trans {...dict('faq')} />
            </Dropdown.Item>
            <Dropdown.Item to="/legal-contact">
              <Trans {...dict('info')} />
            </Dropdown.Item>
          </>
        )}
        <Dropdown.Item onPress={handleLogoutClick}>
          <Trans {...dict('log_out')} />
        </Dropdown.Item>
      </NavbarMenu>
    </Dropdown>
  );
}

export default UserDropdown;
