// @flow
import { createContainer } from 'libs/container';
import { select, gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import MatchingCard from './MatchingCard';

export const QUIZ_MATCHING_CARD_FRAGMENT: DocumentNode = gql`
  fragment QuizMatchingCardFragment on MatchingCard {
    text
    hint
    answers {
      text
      matchingText
    }
  }
`;

const Container = createContainer(MatchingCard, (props) => {
  const selectors = {
    matchingCard: select(QUIZ_MATCHING_CARD_FRAGMENT, {
      entry: ['MatchingCard', props.id],
    }),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
