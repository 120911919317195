// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const VIDEO_LINK_EDITOR_FRAGMENT: DocumentNode = gql`
  fragment VideoLinkEditorFragment on VideoLink {
    provider
    videoId
  }
`;
