// @flow
import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { Domain } from 'libs/misc';
import { Icon, ScrollableNav } from 'components/common';
import { View, Text, Badge, Heading } from 'components/core';
import { Header } from 'components/layout';

type Props = {|
  organization: Entity<GQLOrganization>,
|};

const dict = createDict({
  administration: 'Administration',
  courses: 'Courses',
  start: 'Start',
  users: 'Users',
  settings: 'Settings',
  reports: 'Reports',
  domain: 'Domain',
  teams: 'Teams',
  licenses: 'Licenses',
  checklists: 'Checklists',
  monetization: 'Monetization',
});

const styles = StyleSheet.create({
  heading: css`
    flex-direction: row;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-horizontal: 1rem;
    min-height: 4rem;

    @include media-breakpoint-up(md) {
      margin-top: 2.5rem;
      margin-bottom: 1.5rem;
    }
  `,
  iconWrapper: css`
    background-color: $dark;
    width: 4rem;
    height: 4rem;
    border-radius: 0.5rem;
  `,
  icon: css`
    text-align: center;
    color: #f5f5f5;
    flex-shrink: 0;
    width: 4rem;
    height: 4rem;
    font-size: 2.5rem;
    line-height: 4rem;
  `,
  subtitle: css`
    margin-top: 0.25rem;
    font-size: 1.125rem;
    line-height: 1.125rem * $line-height-base;
    color: $gray-700;
  `,
  nav: css`
    margin-bottom: 0;
    margin-right: 0.75rem;
    border-bottom-width: 0;
    flex-wrap: nowrap;
  `,
  divider: css`
    border-right-color: $gray-300;
    border-right-width: 1px;
    margin-horizontal: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  `,
});

function OrganizationHeader({ organization }: Props): React$Node {
  const { viewerIsAdmin, viewerIsTrainer } = organization;
  const viewerCanAdminister = viewerIsAdmin || viewerIsTrainer;

  const media = useMedia();

  return (
    <Header>
      <View style={styles.heading}>
        {media.up('lg') && (
          <View style={styles.iconWrapper} styleName="mr-3">
            <Icon glyph="bank" style={styles.icon} />
          </View>
        )}
        <View styleName="flex-grow-1 flex-shrink-1 jc-center">
          <Heading size={4} style={styles.title}>
            {organization.name}
          </Heading>
          <Text style={styles.subtitle}>
            <Trans {...dict('administration')} />
          </Text>
        </View>
      </View>
      <ScrollableNav variant="tabs" style={styles.nav}>
        <ScrollableNav.Link to={`/organizations/${organization.slug}`} end>
          <Trans {...dict('start')} />
        </ScrollableNav.Link>
        {viewerCanAdminister && (
          <>
            <View style={styles.divider} />
            <ScrollableNav.Link to={`/organizations/${organization.slug}/users`}>
              <Trans {...dict('users')} />
              <Badge color="light" styleName="ml-1">
                <Text>{organization.membershipsCount}</Text>
              </Badge>
            </ScrollableNav.Link>
            <View style={styles.divider} />
            <ScrollableNav.Link to={`/organizations/${organization.slug}/courses`}>
              <Trans {...dict('courses')} />
              <Badge color="light" styleName="ml-1">
                <Text>{organization.relatedCoursesCount}</Text>
              </Badge>
            </ScrollableNav.Link>
            {!(Domain.is('www.vogelspot.de') || Domain.is('www.xn--fahrradprfung-4ob.de')) && (
              <>
                <View style={styles.divider} />
                <ScrollableNav.Link to={`/organizations/${organization.slug}/checklists`}>
                  <Trans {...dict('checklists')} />
                  <Badge color="light" styleName="ml-1">
                    <Text>{organization.relatedChecklistsCount}</Text>
                  </Badge>
                </ScrollableNav.Link>
              </>
            )}
            <View style={styles.divider} />
            <ScrollableNav.Link to={`/organizations/${organization.slug}/teams`}>
              <Trans {...dict('teams')} />
              <Badge color="light" styleName="ml-1">
                <Text>{organization.teamsCount}</Text>
              </Badge>
            </ScrollableNav.Link>
            {(organization.viewerIsAdmin || organization.viewerIsTrainer) && (
              <>
                <View style={styles.divider} />
                <ScrollableNav.Link to={`/organizations/${organization.slug}/licenses`}>
                  <Trans {...dict('licenses')} />
                  <Badge color="light" styleName="ml-1">
                    <Text>{organization.licensesCount}</Text>
                  </Badge>
                </ScrollableNav.Link>
              </>
            )}
            {organization.isPublisher && (
              <>
                <View style={styles.divider} />
                <ScrollableNav.Link to={`/organizations/${organization.slug}/monetization`}>
                  <Trans {...dict('monetization')} />
                  <Badge color="light" styleName="ml-1">
                    <Text>{organization.productsCount}</Text>
                  </Badge>
                </ScrollableNav.Link>
              </>
            )}
            {organization.ownsDomain && organization.viewerIsAdmin && (
              <>
                <View style={styles.divider} />
                <ScrollableNav.Link to={`/organizations/${organization.slug}/domain`}>
                  <Trans {...dict('domain')} />
                </ScrollableNav.Link>
              </>
            )}
            {organization.viewerIsAdmin && (
              <>
                <View style={styles.divider} />
                <ScrollableNav.Link to={`/organizations/${organization.slug}/settings`}>
                  <Trans {...dict('settings')} />
                </ScrollableNav.Link>
              </>
            )}
          </>
        )}
      </ScrollableNav>
    </Header>
  );
}

export default OrganizationHeader;
