// @flow

// emphasis
export const ASTERISK = '*';
export const UNDERSCORE = '_';

// html
export const LESS_THAN = '<';
export const SLASH = '/';

// math
export const DOLLAR_SIGN = '$';

// misc
export const BACKSLASH = '\\';
export const SPACE = ' ';
export const LINE_FEED = '\n';
export const TAB = '\t';
