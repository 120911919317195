// @flow
import React from 'react';
import BundleCompact from './BundleCompact';

type Props = {
  onClose?: () => void,
};

function KaufleuteFuerBueroManagementBundles({ onClose }: Props): React$Node {
  return (
    <>
      <BundleCompact
        to="/courses/eVPLGN6SKt"
        title="Erstmal reinschnuppern"
        price="kostenlose Lernmaterialien und Community"
        onClose={onClose}
      />
      <BundleCompact
        to="/professions/kauffrau-kaufmann-fuer-bueromanagement"
        title="Lernpaket Büromanagement"
        price="ab 26,95 EUR"
        paid
      />
      <BundleCompact
        to="/professions/kauffrau-kaufmann-fuer-bueromanagement"
        title="1. Lehrjahr Büromanagement"
        price="ab 19,95 EUR"
        paid
      />
    </>
  );
}

export default KaufleuteFuerBueroManagementBundles;
