// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql, selectByRoot } from 'libs/graphql';
import { DOMAIN_QUERY } from 'libs/foundation/queries';
import { SEARCH_RESULT_COURSE_FRAGMENT } from './ResultCourse/fragments';
import { SEARCH_RESULT_CHECKLIST_FRAGMENT } from './ResultChecklist/fragments';
import { SEARCH_RESULT_COURSE_ACTIVITY_FRAGMENT } from './ResultCourseActivity/fragments';

const Search = {
  name: 'Search',
  bundle: () => import('./Search'),
};

const SEARCH_QUERY = gql`
  query SearchQuery($term: String!) {
    search(term: $term) {
      ...SearchResultCourseFragment
      ...SearchResultChecklistFragment
      ...SearchResultCourseActivityFragment
    }
  }

  ${SEARCH_RESULT_COURSE_FRAGMENT}
  ${SEARCH_RESULT_CHECKLIST_FRAGMENT}
  ${SEARCH_RESULT_COURSE_ACTIVITY_FRAGMENT}
`;

const Container = createAsyncContainer(Search, ({ searchParams }) => {
  const variables = {
    term: searchParams.get('q'),
  };

  const loaders = {
    query: query(SEARCH_QUERY, { variables }),
  };

  const selectors = {
    search: select(SEARCH_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
    domain: select(DOMAIN_QUERY, {}),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
