// @flow
import React, { useMemo, useRef, useEffect } from 'react';
import { Platform } from 'react-native';
import { ScrollView, View } from 'components/core';
import { useLocation } from 'libs/routing';
import createScrollContext from './createScrollContext';
import ScrollContext from './ScrollContext';

type Props = {
  children: React$Node,
};

function ScrollArea({ children }: Props): React$Node {
  const ref = useRef();
  const location = useLocation();

  const context = useMemo(() => {
    return createScrollContext(ref);
  }, []);

  const { handleScroll } = context;

  useEffect(() => {
    if (!context) {
      return;
    }

    context.scrollTo(0);
  }, [location.pathname]);

  return (
    <ScrollContext.Provider value={context}>
      {Platform.OS === 'web' ? (
        <View styleName="flex-grow-1 flex-shrink-1">{children}</View>
      ) : (
        <ScrollView
          ref={ref}
          bounces={false}
          onScroll={handleScroll}
          scrollEventThrottle={16}
          keyboardShouldPersistTaps="handled"
          automaticallyAdjustKeyboardInsets
        >
          {children}
        </ScrollView>
      )}
    </ScrollContext.Provider>
  );
}

export default ScrollArea;
