// @flow
import { createContainer } from 'react-transporter';
import { selectByRoot } from 'libs/graphql';
import ErrorSelector from './ErrorSelector';

const Container = createContainer(ErrorSelector, () => {
  const selectors = {
    viewer: selectByRoot('viewer'),
  };

  return {
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
