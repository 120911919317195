// @flow

const normalizeScrollEvent = (e) => ({
  nativeEvent: {
    contentOffset: {
      get x() {
        return e.target.documentElement.scrollLeft;
      },
      get y() {
        return e.target.documentElement.scrollTop;
      },
    },
    contentSize: {
      get height() {
        return e.target.documentElement.scrollHeight;
      },
      get width() {
        return e.target.documentElement.scrollWidth;
      },
    },
    layoutMeasurement: {
      get height() {
        return e.target.documentElement.offsetHeight;
      },
      get width() {
        return e.target.documentElement.offsetWidth;
      },
    },
  },
  timeStamp: Date.now(),
});

/* eslint-disable no-underscore-dangle */
export default function createScrollContext(): Object {
  const context = {
    _callbacks: [],
    _normalizedCallbacks: [],
    scrollTo(top: number) {
      window.scrollTo({ top });
    },
    getContentOffset() {
      if (!document || !document.documentElement) {
        throw new Error('Document not defined.');
      }

      return document.documentElement.scrollTop;
    },
    addEventListener(cb: Function) {
      context._callbacks.push(cb);

      const normalizedCallback = (e) => {
        cb(normalizeScrollEvent(e));
      };
      context._normalizedCallbacks.push(normalizedCallback);

      window.addEventListener('scroll', normalizedCallback);
    },
    removeEventListener(cb: Function) {
      const index = context._callbacks.findIndex((value) => value === cb);
      window.removeEventListener('scroll', context._normalizedCallbacks[index]);

      context._callbacks.splice(index, 1);
      context._normalizedCallbacks.splice(index, 1);
    },
  };

  return context;
}
/* eslint-enable */
