// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';
import { DOMAIN_QUERY } from 'libs/foundation/queries';
import { ANNOUNCEMENTS_FRAGMENT } from 'views/_shared/Announcements';
import { ACTIVITIES_FRAGMENT } from '../_shared/ActivityList/fragments';

const Start = {
  name: 'Start',
  bundle: () => import('./Start'),
};

const COURSE_QUERY = gql`
  query CourseQuery(
    $courseSlug: String!
    $announcementId: ClientID
    $activityType: CourseActivityType
  ) {
    course(slug: $courseSlug) {
      slug
      name
      description
      paid
      membershipsCount(withTeams: true)
      organization {
        name
        emailDomain
      }
      restricted
      isPrivate
      emailRestriction
      organizationRestriction
      viewerIsAdmin
      viewerIsMember
      viewerIsObserver
      viewerCanCreateQuizzes
      viewerCanCreateTasks
      viewerCanCreateVideos
      viewerCanCreateArticles
      viewerCanCreateDocuments
      viewerCanCreateSurveys
      features {
        hasTasks
        hasQuizzes
        hasVideos
        hasArticles
        hasDocuments
        hasSurveys
        hasForum
        hasSimplifiedView
      }
      examActivities(highlighted: true) {
        slug
        title
      }
      quizActivities(highlighted: true) {
        slug
        title
      }
      articleActivities(highlighted: true) {
        slug
        title
      }
      videoActivities(highlighted: true) {
        slug
        title
      }
      documentActivities(highlighted: true) {
        slug
        title
      }
      ...AnnouncementsFragment
      topics {
        slug
        title
      }
      relatedChecklists {
        slug
        name
        todosCount
        viewerResult {
          todoResultsCount
          doneUntil
        }
      }
      author {
        name
        createdAt
      }
      ...ActivitiesFragment
    }
  }

  ${ANNOUNCEMENTS_FRAGMENT}
  ${ACTIVITIES_FRAGMENT}
`;

const Container = createAsyncContainer(Start, (props) => {
  const {
    params: { courseSlug },
    searchParams,
  } = props;
  const variables = {
    courseSlug,
    announcementId: searchParams.get('announcement_id'),
    activityType: null,
  };

  const loaders = {
    query: query(COURSE_QUERY, {
      variables,
    }),
  };

  const selectors = {
    course: select(COURSE_QUERY, { variables }),
    domain: select(DOMAIN_QUERY, {}),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
