// @flow
import React from 'react';
import { Dropdown, Text, Button } from 'components/core';
import { useToggleState, useValueState } from 'libs/misc';
import { createDict, Trans } from 'libs/intl';
import { useDispatch } from 'libs/graphql';
import { getPairLeftKey } from 'utils/tasks';
import { updateOrCreateSolution } from '../mutations';
import type { TaskExamControls } from '../../useExamControls';

type Props = {
  pairing: Entity<GQLPairing>,
  piece: Entity<GQLPairingPiece>,
  examAttemptId: Id,
  controls: TaskExamControls,
  viewerExamSolution: ?Entity<GQLPairingSolution>,
};

const dict = createDict({
  statement: 'Statement',
  choose: 'Choose',
});

function SolutionDropdown({
  pairing,
  piece,
  examAttemptId,
  controls,
  viewerExamSolution,
}: Props): React$Node {
  const dispatch = useDispatch();
  const proposalDropdown = useToggleState(false);

  const value = viewerExamSolution && getPairLeftKey(piece, viewerExamSolution.pairs);
  const dropDownValue = useValueState(value);

  const handleChange = (pairValue) => {
    // block action if loading
    if (controls.loading) {
      return;
    }

    controls.block();
    dispatch(updateOrCreateSolution(pairing.id, examAttemptId, piece.key, pairValue)).finally(
      () => {
        controls.unblock();
        dropDownValue.set(pairValue);
        proposalDropdown.setOff();
      },
    );
  };

  return (
    <Dropdown visible={proposalDropdown.on} onToggle={proposalDropdown.toggle} key={piece.id}>
      <Button toggle={Dropdown} color="secondary" outline styleName="mr-md-2 mb-2 mb-md-0">
        {dropDownValue.value && (
          <Text>
            <Trans {...dict('statement')} /> {dropDownValue.value}
          </Text>
        )}
        {!dropDownValue.value && <Trans {...dict('choose')} />}
      </Button>
      <Dropdown.Menu end>
        {pairing.pieces
          .filter((dropdownPiece) => dropdownPiece.side === 'LEFT')
          .map((dropdownPiece, index) => (
            <Dropdown.Item
              key={dropdownPiece.id}
              onPress={(event) => {
                event.preventDefault();
                handleChange(dropdownPiece.key);
              }}
            >
              <Text>
                <Trans {...dict('statement')} /> {index + 1}
              </Text>
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default SolutionDropdown;
