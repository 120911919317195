// @flow
import React from 'react';
import { Body } from 'bootstrap-rn';
import UpdateAvailable from './UpdateAvailable';
import StatusBar from './StatusBar';

type Props = {
  children: React$Node,
};

function Foundation({ children }: Props): React$Node {
  return (
    <>
      <StatusBar />
      <Body>
        <UpdateAvailable />
        {children}
      </Body>
    </>
  );
}

export default Foundation;
