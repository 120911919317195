// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';
import { getVersion } from 'utils/courses';
import { ACTIVITY_EDITOR_SIDEBAR_FRAGMENT } from '../_shared/ActivityEditorSidebar/fragments';
import { ACTIVITY_EDITOR_TITLE_FRAGMENT } from '../_shared/ActivityEditorTitle/fragments';
import { ATTACHMENTS_FRAGMENT } from '../_shared/MediaGalleryModal';

const ArticleEditor = {
  name: 'ArticleEditor',
  bundle: () => import('./ArticleEditor'),
};

const COURSE_ARTICLE_QUERY = gql`
  query CourseArticleEntry(
    $slug: String!
    $courseSlug: String!
    $versionNumber: Int
    $specificVersion: String
  ) {
    course(slug: $courseSlug) {
      viewerIsAdmin
      viewerIsMember
      viewerIsObserver
      restricted
      isPrivate
      emailRestriction
      organizationRestriction
      categories {
        name
      }
      articleActivity(slug: $slug) {
        ...ActivityEditorTitleFragment
        ...ActivityEditorSidebarFragment
        slug
        version(versionNumber: $versionNumber, specificVersion: $specificVersion) {
          versionNumber
          isDeprecated
          releasedAt
          item {
            text
            downloadable
            files {
              name
              extension
              info {
                image {
                  height
                  width
                }
              }
            }
            createdAt
            updatedAt
            ...AttachmentsFragment
          }
        }
        author {
          name
          avatar {
            file {
              name
              extension
              info {
                image {
                  height
                  width
                }
              }
            }
          }
          createdAt
        }
        viewerCanUpdate
      }
    }
  }

  ${ATTACHMENTS_FRAGMENT}
  ${ACTIVITY_EDITOR_TITLE_FRAGMENT}
  ${ACTIVITY_EDITOR_SIDEBAR_FRAGMENT}
`;

const Container = createAsyncContainer(ArticleEditor, (props) => {
  const {
    params: { slug, courseSlug },
    searchParams,
  } = props;

  const versionNumber = getVersion(searchParams.get('version'));
  const variables = {
    slug,
    courseSlug,
    versionNumber,
    specificVersion: versionNumber ? null : 'LATEST',
  };

  const loaders = {
    query: query(COURSE_ARTICLE_QUERY, { variables }),
  };

  const selectors = {
    course: select(COURSE_ARTICLE_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
