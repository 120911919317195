// @flow
import React from 'react';
import BundleCompact from './BundleCompact';

type Props = {
  onClose?: () => void,
};

function KaufleuteImECommerceBundles({ onClose }: Props): React$Node {
  return (
    <>
      <BundleCompact
        to="/courses/pmkYpxvEuK"
        title="Erstmal reinschnuppern"
        price="kostenlose Lernmaterialien und Community"
        onClose={onClose}
      />
      <BundleCompact
        to="/professions/kauffrau-kaufmann-im-e-commcerce"
        title="Lernpaket E-Commerce"
        price="ab 26,95 EUR"
        paid
      />
    </>
  );
}

export default KaufleuteImECommerceBundles;
