// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

const CLOZE_PROPOSED_SOLUTION_FIELDS = gql`
  {
    gapValues {
      key
      value
    }
    explanation
    author {
      name
      avatar {
        file {
          name
          extension
          info {
            image {
              height
              width
            }
          }
        }
      }
      createdAt
    }
    votesTrueCount
    votesFalseCount
    viewerVote {
      value
    }
    createdAt
    ...CommentsFragment
  }
`;

export const CLOZE_FRAGMENT: DocumentNode = gql`
  fragment ClozeFragment on Cloze {
    text
    gaps {
      key
    }
    sampleSolution {
      explanation
      gapValues {
        key
        value
      }
      ...CommentsFragment
    }
    proposedSolution(id: $solutionId, postId: $postId) ${CLOZE_PROPOSED_SOLUTION_FIELDS}
    proposedSolutions ${CLOZE_PROPOSED_SOLUTION_FIELDS}
  }
`;
