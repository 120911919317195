// @flow
import React from 'react';
import { Text, View, Image } from 'components/core';
import { HighlightStripe, BrandHeading } from 'components/pages';
import { useIntl } from 'libs/intl';
import { StyleSheet, css, useMedia } from 'libs/ui';

type Props = {
  children: React$Node,
};

const styles = StyleSheet.create({
  heading: css`
    margin-bottom: 2rem;
    text-align: center;
  `,
  lightBlueText: css`
    color: #00abbd;
  `,
  badgeDesktop: css`
    width: 195px;
    height: 139px;
    margin-right: 1.5rem;
    margin-vertical: -0.5rem;
  `,
});

function FreeCoursesInfoBox({ children }: Props): React$Node {
  const intl = useIntl();
  const media = useMedia();

  return (
    <>
      {intl.locale === 'en' && (
        <HighlightStripe styleName="py-md-4 pr-md-4 ai-center jc-center">
          <View styleName="ai-md-center jc-md-center p-3">
            <View styleName="flex-md-row flex-grow-1 flex-shrink-1">
              {media.up('md') && (
                <View styleName="flex-grow-1 flex-shrink-1 ai-md-start ai-end mt-n5 mb-n2 mr-n3 my-md-0 mr-md-0">
                  <Image
                    source="/img/home/book-open-free-courses.png"
                    style={styles.badgeDesktop}
                  />
                </View>
              )}
              <View styleName="flex-column ai-md-center">
                <BrandHeading style={styles.heading} size={3}>
                  Catch a glimpse first? Check our{' '}
                  <Text style={styles.lightBlueText}>free materials </Text> for your profession.
                </BrandHeading>
              </View>
            </View>
            <View styleName="mt-md-n5">{children}</View>
          </View>
        </HighlightStripe>
      )}
      {intl.locale === 'de' && (
        <HighlightStripe styleName="py-md-4 pr-md-4 ai-center jc-center">
          <View styleName="ai-md-center jc-md-center p-3">
            <View styleName="flex-md-row flex-grow-1 flex-shrink-1">
              {media.up('md') && (
                <View styleName="flex-grow-1 flex-shrink-1 ai-start mb-3">
                  <Image
                    source="/img/home/book-open-free-courses.png"
                    style={styles.badgeDesktop}
                  />
                </View>
              )}
              <View styleName="flex-column flex-wrap ai-md-center flex-shrink-1">
                <BrandHeading style={styles.heading} size={3}>
                  Erstmal reinschnuppern?{' '}
                  <Text style={styles.lightBlueText}>Kostenlose Lernmaterialien</Text> für deinen
                  Beruf!
                </BrandHeading>
              </View>
            </View>
            <View styleName="mt-md-n5 ai-center">{children}</View>
          </View>
        </HighlightStripe>
      )}
    </>
  );
}

export default FreeCoursesInfoBox;
