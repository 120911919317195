// @flow
import React from 'react';
import { View, Text, Image, ImageBackground, Button } from 'components/core';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { BrandHeading, BrandLargeText, Section } from 'components/pages';
import { useDocumentTitle } from 'libs/misc';
import { Wrapper } from 'components/layout';
import Content from './Content';
import FeaturesBoxContent from './FeaturesBoxContent';

const styles = StyleSheet.create({
  logoDesktop: css`
    width: 242px;
    height: 70px;
    margin-top: 4rem;
  `,
  logoMobile: css`
    width: 242px;
    height: 70px;
    margin-top: 3rem;
  `,
  textAlign: css`
    text-align: center;
  `,
});

function WBS(): React$Node {
  useDocumentTitle('WBS Training');
  const media = useMedia();

  return (
    <Wrapper>
      <Section color="white">
        <View styleName="ai-center">
          <Image
            source="/img/organizations/wbs/wbs_training.png"
            label="WBS Training"
            style={media.up('md') ? styles.logoDesktop : styles.logoMobile}
          />
        </View>
        <View styleName="pt-4 px-3 jc-center ai-center">
          <BrandHeading size={5} align="center">
            Willkommen auf Ihrer WBS-Lernplattform
          </BrandHeading>
        </View>
        <View styleName="mt-4 ai-center">
          <Button to="/login" size="lg" color="dark">
            <Text>Anmelden</Text>
          </Button>
        </View>
      </Section>
      <ImageBackground
        source="/img/organizations/wbs/wbs-background.jpg"
        style={
          media.up('md') ? { width: '100%', height: '300px' } : { width: '100%', height: '140px' }
        }
      />
      <Section color="white">
        <BrandLargeText bold style={styles.textAlign} styleName="pb-5 px-3 px-md-0">
          Bereiten Sie sich auf die anstehenden Prüfungen vor, vertiefen Sie Ihr Wissen mithilfe von
          Erklärvideos und Lernkarten und tauschen Sie sich mit anderen Teilnehmenden aus.
        </BrandLargeText>
        <Content />
      </Section>
      <Section color="gray">
        <View styleName="flex-grow-1 flex-shrink-1 ai-center jc-center mx-3 mx-md-0">
          <BrandHeading size={3}>Die Funktionen der Lernplattform</BrandHeading>
          <FeaturesBoxContent />
        </View>
      </Section>
    </Wrapper>
  );
}

export default WBS;
