// @flow
import React from 'react';
import { Outlet } from 'libs/routing';
import CourseHeader from './CourseHeader';

function CourseLayout(): React$Node {
  return (
    <>
      <CourseHeader />
      <Outlet />
    </>
  );
}

export default CourseLayout;
