// @flow
import React from 'react';
import { View, Button, Alert, Text } from 'components/core';
import { createDict, Trans } from 'libs/intl';

type Props = {
  course: Entity<GQLCourse>,
  viewer: ?Entity<GQLUser>,
};

const dict = createDict({
  you_are_not_a_member_of_this_course_only_members_can_learn_with_this_content:
    'You are not a member of this course. Only members can learn with this content.',
  please_register_or_log_in_to_join_courses: 'Please register or log in to join courses.',
  register: 'Register',
  login: 'Login',
  or: 'or',
  only_allowed_domain_email_can_join: 'Only allowed email domain can join this course.',
  this_course_is_private_message:
    'This course is private. Only admins of this course can add you to the course.',
});

function AccessDenied({ course, viewer }: Props): React$Node {
  return (
    <>
      {course.isPrivate && (
        <Alert color="warning" styleName="ai-center mb-3">
          <Trans {...dict('this_course_is_private_message')} />
        </Alert>
      )}
      {!course.isPrivate && (
        <Alert color="info" styleName="ai-center mb-3">
          {!viewer && (
            <View styleName="ai-center my-3">
              <Trans
                {...dict('please_register_or_log_in_to_join_courses')}
                styleName="flex-grow-1"
              />
              <View styleName="mt-3 flex-md-row flex-grow-1 flex-shrink-1 ai-center jc-center">
                <Button to="/register" color="success" size="lg">
                  <Trans {...dict('register')} />
                </Button>
                <Text styleName="mx-2 my-2 my-md-0">
                  {' '}
                  <Trans {...dict('or')} />{' '}
                </Text>
                <Button to="/login" color="secondary" size="lg">
                  <Trans {...dict('login')} />
                </Button>
              </View>
            </View>
          )}
          {viewer && (
            <>
              <Trans
                {...dict(
                  'you_are_not_a_member_of_this_course_only_members_can_learn_with_this_content',
                )}
              />
              {course.emailRestriction && (
                <Trans {...dict('only_allowed_domain_email_can_join')} styleName="mt-3" />
              )}
            </>
          )}
        </Alert>
      )}
    </>
  );
}

export default AccessDenied;
