// @flow
import React from 'react';
import { createDict, Trans, TimeAgo } from 'libs/intl';
import { StyleSheet, css } from 'libs/ui';
import { isExpired } from 'utils/courses';
import { Domain } from 'libs/misc';
import { Icon, OptionButton, Well } from 'components/common';
import { Text, View, Nav, Badge, Button } from 'components/core';
import { useMenu } from 'libs/foundation';

type Props = {
  viewer: Entity<GQLUser>,
  expanded: ToggleState,
  bundlesModal: ToggleState,
};

const styles = StyleSheet.create({
  indented: css`
    padding-horizontal: 0.75rem;
  `,
  dirIcon: css`
    width: 1rem;
    align-items: center;
  `,
});

const dict = createDict({
  courses: 'Courses',
  no_memberships_yet: 'No memberships yet.',
  find_courses: 'Find courses',
  redeem_code: 'Redeem code',
  recommendations_for_you: 'Recommendations for you',
});

function MenuCourses({ viewer, expanded, bundlesModal }: Props): React$Node {
  const { courseMemberships, promotedCourses } = viewer;

  const menu = useMenu();

  return (
    <>
      <OptionButton onPress={expanded.toggle} styleName="jc-start">
        <View style={styles.dirIcon}>
          {expanded.on ? <Icon glyph="down-dir" /> : <Icon glyph="right-dir" />}
        </View>
        <Trans {...dict('courses')} bold styleName="ml-2" />
        <Badge color="light" styleName="ml-1">
          <Text>{courseMemberships.length}</Text>
        </Badge>
      </OptionButton>
      {courseMemberships.length === 0 && expanded.on && (
        <Well styleName="mt-2">
          <Trans {...dict('no_memberships_yet')} color="muted" small />
          {((Domain.is('www.azubinet.de') && !viewer.isOrganizationAdminOrTrainer) ||
            Domain.is('stbk.azubinet.de') ||
            Domain.is('www.vogelspot.de')) && (
            <Button onPress={bundlesModal.toggle} styleName="mt-3">
              <Trans {...dict('find_courses')} />
            </Button>
          )}
        </Well>
      )}
      {courseMemberships.length > 0 && expanded.on && (
        <>
          <Nav variant="pills" styleName="flex-column">
            {courseMemberships
              .sort((left, right) => {
                const titleLeft = left.course.name.toLowerCase();
                const titleRight = right.course.name.toLowerCase();
                if (titleLeft < titleRight) {
                  return -1;
                }
                if (titleLeft > titleRight) {
                  return 1;
                }
                return 0;
              })
              .map((membership) => (
                <Nav.Link
                  to={`/courses/${membership.course.slug}`}
                  onPress={() => {
                    menu.clear();
                  }}
                  style={styles.indented}
                  key={membership.id}
                >
                  <View styleName="flex-grow-1 flex-shrink-1">
                    <View styleName="flex-row">
                      <Text numberOfLines={1}>{membership.course.name}</Text>
                      {membership.course.paid && isExpired(membership) && (
                        <Icon glyph="attention" color="warning" styleName="ml-2" />
                      )}
                    </View>
                    <Text color="muted" small numberOfLines={1}>
                      {membership.course.caption ? (
                        <Text>{membership.course.caption}</Text>
                      ) : (
                        <TimeAgo value={membership.course.createdAt} />
                      )}
                    </Text>
                  </View>
                </Nav.Link>
              ))}
          </Nav>
        </>
      )}
      {promotedCourses.length > 0 && expanded.on && (
        <>
          <Trans
            {...dict('recommendations_for_you')}
            small
            bold
            style={styles.indented}
            styleName="mt-3"
          />
          <Nav variant="pills" styleName="flex-column">
            {promotedCourses.map((course) => (
              <Nav.Link
                to={`/courses/${course.slug}`}
                onPress={() => {
                  menu.clear();
                }}
                style={styles.indented}
                key={course.id}
              >
                <View styleName="flex-grow-1 flex-shrink-1">
                  <Text numberOfLines={1}>{course.name}</Text>
                  <Text color="muted" small numberOfLines={1}>
                    {course.caption ? (
                      <Text>{course.caption}</Text>
                    ) : (
                      <TimeAgo value={course.createdAt} />
                    )}
                  </Text>
                </View>
              </Nav.Link>
            ))}
          </Nav>
        </>
      )}
    </>
  );
}

export default MenuCourses;
