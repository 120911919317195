// @flow
import { createContainer } from 'libs/container';
import { selectByRoot, select, gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import Comments from './Comments';

const POST_FIELDS = gql`
  {
    text
    author {
      name
      avatar {
        file {
          name
          extension
          info {
            image {
              height
              width
            }
          }
        }
      }
      createdAt
    }
    reactionsThumbUpCount
    reactionsThumbDownCount
    viewerReaction {
      value
    }
    viewerCanUpdate
    viewerCanDelete
    createdAt
  }
`;

const COMMENT_FIELDS = gql`
  {
    opening ${POST_FIELDS}
    post(id: $postId) ${POST_FIELDS}
    replies ${POST_FIELDS}
  }
`;

export const COMMENTS_FRAGMENT: DocumentNode = gql`
  fragment CommentsFragment on Commentable {
    comment(postId: $postId) ${COMMENT_FIELDS}
    comments ${COMMENT_FIELDS}
  }
`;

const Container = createContainer(Comments, (props) => {
  const { itemType, itemId, searchParams } = props;

  const variables = {
    postId: searchParams.get('post_id'),
  };

  const selectors = {
    viewer: selectByRoot('viewer', (viewer) =>
      viewer.join('avatar', (avatar) =>
        avatar.join('file', (file) => file.join('info', (info) => info.join('image'))),
      ),
    ),
    item: select(COMMENTS_FRAGMENT, {
      entry: [itemType, itemId],
      variables,
    }),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
