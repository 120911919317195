// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, selectByRoot, query } from 'libs/graphql';

const DomainUsers = {
  name: 'DomainUsers',
  bundle: () => import('./DomainUsers'),
};

const DOMAIN_USERS_QUERY = gql`
  query DomainUsersQuery($slug: String!) {
    organization(slug: $slug) {
      name
      ownsDomain
      domain {
        usersCount
      }
      viewerIsAdmin
    }
  }
`;

const Container = createAsyncContainer(DomainUsers, (props) => {
  const {
    params: { slug },
  } = props;

  const variables = { slug };

  const loaders = {
    query: query(DOMAIN_USERS_QUERY, { variables }),
  };

  const selectors = {
    organization: select(DOMAIN_USERS_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
