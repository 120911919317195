// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query, selectByRoot } from 'libs/graphql';
import ProductAside from './ProductAside';

const ORGANIZATION_PRODUCT_DETAILS_NAV_QUERY = gql`
  query OrganizationProductDetailsNavQuery($slug: String!, $productSlug: String!) {
    organization(slug: $slug) {
      slug
      product(productSlug: $productSlug) {
        name
        slug
        licensesCount
        offers {
          keysCount
          purchasesCount(completed: true)
        }
      }
    }
  }
`;

const CURRENCIES_QUERY = gql`
  query CurrenciesQuery {
    currencies {
      code
      sign
    }
  }
`;

const options = {
  async: {
    loading: ProductAside,
    error: ProductAside,
  },
};

const Container = createAsyncContainer(
  ProductAside,
  (props) => {
    const {
      params: { slug, productSlug },
    } = props;

    const variables = { slug, productSlug };

    const loaders = {
      query: query(ORGANIZATION_PRODUCT_DETAILS_NAV_QUERY, { variables }),
      currenciesQuery: query(CURRENCIES_QUERY),
    };

    const selectors = {
      organization: select(ORGANIZATION_PRODUCT_DETAILS_NAV_QUERY, { variables }),
      currencies: select(CURRENCIES_QUERY),
      viewer: selectByRoot('viewer'),
    };

    return { loaders, selectors };
  },
  options,
);

export default (Container: React$AbstractComponent<any, mixed>);
