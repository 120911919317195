// @flow
import { createAsyncContainer } from 'libs/container';

const Business = {
  name: 'Business',
  bundle: () => import('./Business'),
};

const Container = createAsyncContainer(Business, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
