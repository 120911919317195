// @flow
import { DOLLAR_SIGN, BACKSLASH } from '../utils/chars';

const reWhitespace = /\s/;
const reDelimiter = /^(\$+)/;

const isWhitespace = (value, index) => {
  return reWhitespace.test(value.charAt(index));
};

const isLeftFlanking = (value, index, length) => {
  return !isWhitespace(value, index - length - 1);
};

const isRightFlanking = (value, index) => {
  return !isWhitespace(value, index);
};

export default function mathInline() {
  const { Parser } = this;
  const tokenizers = Parser.prototype.inlineTokenizers;
  const methods = Parser.prototype.inlineMethods;

  // Run it just after `escape` tokenizer.
  methods.splice(1, 0, 'mathInline');

  function locateMathInline(value, fromIndex) {
    return value.indexOf(DOLLAR_SIGN, fromIndex);
  }

  function tokenizeMathInline(eat, value, silent) {
    if (value.charAt(0) !== DOLLAR_SIGN) {
      return undefined;
    }

    const openings = value.match(reDelimiter)[0].length;

    if (!isRightFlanking(value, openings)) {
      return undefined;
    }

    let index = openings;

    while (index < value.length) {
      // A potential closing delimiter is not allowed to be surrounded by other
      // delimiter chars.
      if (value.charAt(index) === DOLLAR_SIGN) {
        const closings = value.slice(index).match(reDelimiter)[0].length;
        index += closings;

        // We found another opening. Thus only the most inner delimiters should
        // be tokenized to a formula, we return undefined and will capture the
        // next opening in the next run.
        if (isRightFlanking(value, index) && !isLeftFlanking(value, index, closings)) {
          return undefined;
        }

        // If there is not a whitespace before, we found the closing delimiter.
        if (openings === closings && isLeftFlanking(value, index, closings)) {
          const innerValue = value.slice(openings, index - closings).trim();

          if (!innerValue) {
            return undefined;
          }

          if (silent) {
            return true;
          }

          return eat(value.slice(0, index))({
            type: 'inlineMath',
            value: innerValue,
          });
        }
      }

      if (value.charAt(index) === BACKSLASH) {
        index += 1;
      }

      index += 1;
    }

    return undefined;
  }

  tokenizeMathInline.locator = locateMathInline;

  tokenizers.mathInline = tokenizeMathInline;
}
