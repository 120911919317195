// @flow

import React from 'react';
import { View, Text } from 'components/core';
import { Form } from 'components/forms';
import { Markdown } from 'components/markdown';
import { QuizBadge, QuizCardAnswer } from 'components/quizzes';
import type { QuizState, CurrentQuizCardState } from '../useQuizState';

type Props = {
  orderingCard: Entity<GQLOrderingCard>,
  answer: Entity<GQLOrderingCardAnswer>,
  pickerOptions: Array<{
    label: string,
    value: string,
  }>,
  quizState: QuizState,
  currentCardState: CurrentQuizCardState,
};

function OrderingCardOption({
  orderingCard,
  answer,
  pickerOptions,
  currentCardState,
  quizState,
}: Props): React$Node {
  return (
    <>
      {!currentCardState.flipped ? (
        <QuizCardAnswer>
          <Form initialValues={{ order: null }} onSubmit={() => {}} styleName="flex-row ai-center">
            <Form.Picker
              name="order"
              options={pickerOptions}
              onValueChange={(nextValue) => {
                const position = parseInt(nextValue, 10);
                const hasAnswer = currentCardState.answers.some(
                  (value) => value.answerId === answer.id,
                );

                let isAnswerCorrect = false;
                let areAnswersComplete = false;

                if (answer.position === position) {
                  if (!hasAnswer) {
                    if (
                      !currentCardState.answers.some((value) => !value.correct) &&
                      currentCardState.answers.length + 1 === orderingCard.answers.length
                    ) {
                      isAnswerCorrect = true;
                    }
                    areAnswersComplete =
                      currentCardState.answers.length + 1 === orderingCard.answers.length;
                  } else {
                    const correctAnswers = currentCardState.answers.filter(
                      (value) => value.correct,
                    );
                    if (correctAnswers.length + 1 === orderingCard.answers.length) {
                      isAnswerCorrect = true;
                    }
                    areAnswersComplete =
                      currentCardState.answers.length === orderingCard.answers.length;
                  }

                  quizState.addAnswer({
                    correct: true,
                    answerId: answer.id,
                    position,
                  });
                } else {
                  areAnswersComplete =
                    currentCardState.answers.length + (hasAnswer ? 0 : 1) ===
                    orderingCard.answers.length;

                  quizState.addAnswer({
                    correct: false,
                    answerId: answer.id,
                    position,
                  });
                }

                quizState.setCurrentCardResult({
                  correct: isAnswerCorrect,
                  areAnswersComplete,
                });
              }}
              styleName="flex-shrink-0 mb-0 mr-3"
            />
            <Markdown source={answer.text} allowLinkedImages={false} />
          </Form>
        </QuizCardAnswer>
      ) : (
        <QuizCardAnswer>
          <View styleName="flex-row ai-center">
            <QuizBadge styleName="mr-3">
              <Text bold>{answer.position}</Text>
            </QuizBadge>
            <View styleName="flex-shrink-1 flex-grow-1">
              <Markdown source={answer.text} allowLinkedImages={false} />
            </View>
          </View>
        </QuizCardAnswer>
      )}
    </>
  );
}

export default OrderingCardOption;
