// @flow
import React from 'react';
import { Text, ListGroup, Modal, CloseButton } from 'components/core';
import { createDict, Trans } from 'libs/intl';

type Props = {
  modal: ToggleState,
};

const dict = createDict({
  find_your_courses: 'Find your courses',
});

function ProfessionsFreeCoursesModal({ modal }: Props): React$Node {
  return (
    <Modal visible={modal.on} onToggle={modal.toggle}>
      <Modal.Header styleName="flex-row">
        <Modal.Title>
          <Trans {...dict('find_your_courses')} />
        </Modal.Title>
        <CloseButton onPress={modal.toggle} />
      </Modal.Header>
      <Modal.Body styleName="p-0">
        <ListGroup flush>
          <ListGroup.ItemAction to="/courses/5Hb1wZWF7Q">
            <Text>Automobilkaufleute</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/bQQcC1bvpB">
            <Text>Drogist/-innen</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/p2LNBzMtRS">
            <Text>Fachinformatiker/-innen</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/BWUrPiP8hB">
            <Text>Fachkräfte für Lagerlogistik</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/UbAtxDZYrU">
            <Text>Fachkräfte für Systemgastronomie</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/ItUVcraWjg">
            <Text>Fachkräfte im Gastgewerbe</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/BWUrPiP8hB">
            <Text>Fachlagerist/-innen</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/uqIlfNitHJ">
            <Text>Florist/-innen</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/8OlswH7l34">
            <Text>Hotelfachleute</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/E7KIeSeujm">
            <Text>Industriekaufleute</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/eVPLGN6SKt">
            <Text>Kaufleute für Büromanagement</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/pmkYpxvEuK">
            <Text>Kaufleute im E-Commerce</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/L8y5cyW1PJ">
            <Text>Kaufleute im Einzelhandel</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/cuOsv0GDIt">
            <Text>Kaufleute im Gesundheitswesen</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/wnHFB8q97X">
            <Text>Kaufleute für Groß- und Außenhandelsmanagement</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/shUe1FJmsj">
            <Text>Kaufleute für Spedition und Logistikdienstleistung</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/tTgcqHF3ff">
            <Text>Köche</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/pALvuIP1lL">
            <Text>Personaldienstleistungskaufleute</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/cneSzc9b5W">
            <Text>Restaurantfachleute</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/sRWDDIfSot">
            <Text>Steuerfachangestellte</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/e1fBPncZUu">
            <Text>Veranstaltungskaufleute</Text>
          </ListGroup.ItemAction>
          <ListGroup.ItemAction to="/courses/L8y5cyW1PJ">
            <Text>Verkäufer/-innen</Text>
          </ListGroup.ItemAction>
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
}

export default ProfessionsFreeCoursesModal;
