// @flow
import { createContainer } from 'libs/container';
import { select } from 'libs/graphql';
import { DOMAIN_QUERY } from 'libs/foundation/queries';
import { VIEWER_MENU_FRAGMENT } from './fragments';
import Menu from './Menu';

const Container = createContainer(Menu, (props) => {
  const selectors = {
    viewer: select(VIEWER_MENU_FRAGMENT, {
      entry: ['User', props.viewerId],
    }),
    domain: select(DOMAIN_QUERY, {}),
  };

  return { selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
