// @flow
import React from 'react';
import { StyleSheet, css, shiftColor } from 'libs/ui';
import { Alert as BaseAlert, Text, View, Heading, CloseButton } from 'components/core';
import Icon from 'components/common/Icon';
import LargeText from 'components/common/LargeText';

type Props = {
  color: 'success' | 'danger' | 'warning' | 'info',
  title?: ?string,
  content: React$Node,
  dismiss: () => void,
  pauseTimeout: () => void,
  resumeTimeout: () => void,
  dismissible?: boolean,
};

const styles = StyleSheet.create({
  alert: css`
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.2);
    align-items: center;
    flex-direction: row;
  `,
  close: css`
    flex-shrink: 0;
    position: relative;
  `,
  icon: css`
    margin-right: 1rem;
    line-height: 2.125rem;
    height: 2.5rem;
    width: 2.5rem;
    flex-shrink: 0;
    text-align: center;
    border-radius: 1.625rem;
    border-width: 0.1875rem;
    border-style: solid;
  `,
  iconSuccess: css`
    border-color: ${shiftColor(
      (t) => t.success,
      (t) => t['alert-color-scale'],
    )};
  `,
  iconDanger: css`
    border-color: ${shiftColor(
      (t) => t.danger,
      (t) => t['alert-color-scale'],
    )};
  `,
  iconWarning: css`
    border-color: ${shiftColor(
      (t) => t.warning,
      (t) => t['alert-color-scale'],
    )};
  `,
  iconInfo: css`
    border-color: ${shiftColor(
      (t) => t.info,
      (t) => t['alert-color-scale'],
    )};
  `,
  title: css`
    font-family: 'Titillium Web';
  `,
  container: css`
    flex-grow: 1;
    flex-shrink: 1;
  `,
});

const defaultProps = {
  title: null,
  dismissible: true,
};

const getIcon = (color) => {
  switch (color) {
    case 'success':
      return 'ok';
    case 'danger':
      return 'cancel';
    case 'warning':
      return 'attention';
    case 'info':
      return 'info';
    default:
      throw new Error('Unknown color for icon.');
  }
};

function Alert({
  dismiss,
  pauseTimeout,
  resumeTimeout,
  title,
  content,
  color,
  dismissible,
}: Props): React$Node {
  return (
    <BaseAlert
      color={color}
      style={styles.alert}
      onMouseEnter={pauseTimeout}
      onMouseLeave={resumeTimeout}
    >
      <LargeText
        style={[
          styles.icon,
          color === 'success' && styles.iconSuccess,
          color === 'danger' && styles.iconDanger,
          color === 'warning' && styles.iconWarning,
          color === 'info' && styles.iconInfo,
        ]}
      >
        <Icon glyph={getIcon(color)} />
      </LargeText>
      <View style={styles.container}>
        {title && (
          <Heading size={5} style={styles.title}>
            {title}
          </Heading>
        )}
        <Text>{content}</Text>
      </View>
      {dismissible && <CloseButton onPress={dismiss} style={styles.close} />}
    </BaseAlert>
  );
}

Alert.defaultProps = defaultProps;

export default Alert;
