// @flow
import React from 'react';
import { View, Grid, Text, Image, Button } from 'components/core';
import { BrandHeading, BrandLargeText } from 'components/pages';
import { StyleSheet, css, useMedia } from 'libs/ui';

const styles = StyleSheet.create({
  imageDesktop: css`
    width: 310px;
    height: 195px;
  `,
  imageMobile: css`
    width: 250px;
    height: 140px;
    border-radius: 0.03125rem;
  `,
  buttonareaDesktop: css`
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: 1rem;
  `,
  buttonareaMobile: css`
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  `,
});

function Content(): React$Node {
  const media = useMedia();

  return (
    <>
      <Grid styleName="my-4">
        <Grid.Box size={12} sizeMd={4} styleName="jc-center ai-center">
          <Image
            source="/img/home/img-feat2.png"
            label="Lernplattform"
            style={[styles.imageMobile, media.up('md') && styles.imageDesktop]}
          />
        </Grid.Box>
        <Grid.Box size={12} sizeMd={8} styleName="p-3 p-md-0 pl-md-3 jc-start ai-start">
          <View>
            <BrandHeading size={4} styleName="mb-3">
              <Text>Prüfungstraining</Text>
            </BrandHeading>
            <BrandLargeText>
              Bereiten Sie sich mit Probeklausuren, Lernkarten und Erklärvideos auf die Zwischen-
              und Abschlussprüfung vor, tauschen Sie sich mit anderen Lernenden aus und erstellen
              und teilen Sie Materialien. Am Schreibtisch, unterwegs und mobil.
            </BrandLargeText>
            <View style={[styles.buttonareaMobile, media.up('md') && styles.buttonareaDesktop]}>
              <Button to="/login" color="dark" size="lg">
                <Text>Anmelden</Text>
              </Button>
            </View>
          </View>
        </Grid.Box>
      </Grid>
      <Grid styleName="my-4 flex-md-row-reverse">
        <Grid.Box size={12} sizeMd={4} styleName="p-3 p-md-0 pl-md-3 ai-center jc-center">
          <Image
            source="/img/home/img-feat3.png"
            label="Lernplattform"
            style={[styles.imageMobile, media.up('md') && styles.imageDesktop]}
          />
        </Grid.Box>
        <Grid.Box size={12} sizeMd={8} styleName="p-3 jc-start ai-start">
          <View>
            <BrandHeading size={4} styleName="mb-3">
              <Text>Wissen vertiefen</Text>
            </BrandHeading>
            <BrandLargeText>
              Nutzen Sie hunderte Lerninhalte, um Ihr Wissen zu vertiefen oder aufzufrischen. Lernen
              Sie in Ihrem eigenen Tempo, übersichtlich und strukturiert Sie mit vorgefertigten
              To-Do-Listen oder erstellen Sie Ihre ganz eigenen To-Do-Listen!
            </BrandLargeText>
            <View style={[styles.buttonareaMobile, media.up('md') && styles.buttonareaDesktop]}>
              <Button to="/login" color="dark" size="lg">
                <Text>Anmelden</Text>
              </Button>
            </View>
          </View>
        </Grid.Box>
      </Grid>
    </>
  );
}

export default Content;
