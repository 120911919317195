// @flow
import React, { useMemo } from 'react';
import { useMedia } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';
import { QuizCard } from 'components/quizzes';
import { Scrollbars } from 'components/common';
import { Text, View, Badge, Blockquote } from 'components/core';
import { Markdown } from 'components/markdown';
import OrderingCardOption from './OrderingCardOption';
import type { QuizState, CurrentQuizCardState } from '../useQuizState';

type Props = {
  orderingCard: Entity<GQLOrderingCard>,
  quizState: QuizState,
  currentCardState: CurrentQuizCardState,
};

const dict = createDict({
  question: 'Question',
  ordering: 'Ordering',
  solution: 'Solution',
  choose_the_correct_order: 'Choose the correct order...',
  hint: 'Hint',
});

function OrderingCard({ orderingCard, currentCardState, quizState }: Props): React$Node {
  const media = useMedia();
  const { hint } = orderingCard;

  const DesktopScrollbars = media.up('lg') ? Scrollbars : React.Fragment;

  const answers = useMemo(() => {
    return currentCardState.flipped
      ? [...orderingCard.answers].sort((a, b) => a.position - b.position)
      : [...orderingCard.answers].sort(() => Math.random() - 0.5);
  }, [orderingCard.id, currentCardState.flipped]);

  const pickerOptions = useMemo(() => {
    return Array.from({ length: answers.length }, (_, i) => ({
      label: (i + 1).toString(),
      value: (i + 1).toString(),
    }));
  }, [answers.length]);

  return (
    <>
      <QuizCard>
        <QuizCard.Front styleName="p-0">
          <DesktopScrollbars>
            <View styleName="p-3 p-lg-4">
              <View styleName="flex-row mb-3 jc-between ai-center">
                <Text color="muted" styleName="pr-3">
                  <Trans {...dict('question')} /> #{currentCardState.number}
                </Text>
                <Badge color="secondary">
                  <Trans {...dict('ordering')} />
                </Badge>
              </View>
              <Markdown source={orderingCard.text} />
            </View>
          </DesktopScrollbars>
        </QuizCard.Front>
        <QuizCard.Back styleName="p-0">
          <DesktopScrollbars
            // Scroll to top of card back (by recreating element) on flip (desktop)
            key={currentCardState.flipped ? 1 : 0}
          >
            <View styleName="p-3 p-lg-4">
              <Text styleName="pb-3">
                {currentCardState.flipped ? (
                  <Trans {...dict('solution')} color="muted" />
                ) : (
                  <Trans {...dict('choose_the_correct_order')} color="primary" />
                )}
              </Text>
              {answers.map((answer, key) => (
                <View styleName={key < answers.length - 1 ? 'mb-3' : undefined} key={answer.id}>
                  <OrderingCardOption
                    orderingCard={orderingCard}
                    answer={answer}
                    pickerOptions={pickerOptions}
                    currentCardState={currentCardState}
                    quizState={quizState}
                  />
                </View>
              ))}
              {hint && currentCardState.flipped && (
                <Blockquote styleName="mt-3">
                  <Trans {...dict('hint')} color="muted" small styleName="mb-2" />
                  <Markdown source={hint} />
                </Blockquote>
              )}
            </View>
          </DesktopScrollbars>
        </QuizCard.Back>
      </QuizCard>
    </>
  );
}

export default OrderingCard;
