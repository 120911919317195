// @flow
import React from 'react';
import { View } from 'components/core';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  style?: any,
};

const styles = StyleSheet.create({
  overlay: css`
    position: absolute;

    @include platform(web) {
      position: fixed;
    }
  `,
});

function FixedView({ style, ...elementProps }: Props): React$Node {
  return <View {...elementProps} style={[styles.overlay, style]} />;
}

export default FixedView;
