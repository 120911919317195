// @flow
import React from 'react';
import { Button } from 'components/core';
import { StyleSheet, css, shiftColor, colorContrast } from 'libs/ui';

type Props = {
  children: React$Node,
  style?: any,
  textStyle?: any,
};

const styles = StyleSheet.create({
  button: css`
    background-color: $brand-primary;
    border-color: $brand-primary;

    &:hover {
      background-color: ${shiftColor(
        (t) => t['brand-primary'],
        (t) => t['btn-hover-bg-shade-amount'],
      )};
      border-color: ${shiftColor(
        (t) => t['brand-primary'],
        (t) => t['btn-hover-border-shade-amount'],
      )};
    }

    &:focus-visible {
      background-color: ${shiftColor(
        (t) => t['brand-primary'],
        (t) => t['btn-hover-bg-shade-amount'],
      )};
      border-color: ${shiftColor(
        (t) => t['brand-primary'],
        (t) => t['btn-hover-border-shade-amount'],
      )};
    }
  `,
  buttonText: css`
    color: ${colorContrast((t) => t['brand-primary'])};

    &:hover {
      color: ${colorContrast((t) => t['brand-primary'])};
    }

    &:focus-visible {
      color: ${colorContrast((t) => t['brand-primary'])};
    }
  `,
});

const BrandHeading = React.forwardRef<Props, any>((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { children, style, textStyle, ...elementProps } = props;

  return (
    <Button
      {...elementProps}
      ref={ref}
      style={[styles.button, style]}
      textStyle={[styles.buttonText, textStyle]}
    >
      {children}
    </Button>
  );
});

export default (BrandHeading: React$AbstractComponent<Props, any>);
