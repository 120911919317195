// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { Button } from 'components/core';

type Props = {
  children: React$Node,
  color?: 'light' | 'link',
  style?: any,
};

const styles = StyleSheet.create({
  button: css`
    align-items: center;
    min-width: 2.5rem;
    min-height: 2rem;
    border-width: 0;
    padding-top: 0;
    padding-bottom: 0;
  `,
});

const OptionButton = React.forwardRef<Props, any>((props, ref) => {
  const { children, color = 'light', style, ...elementProps } = props;

  return (
    <Button {...elementProps} ref={ref} style={[styles.button, style]} color={color} caret={false}>
      {children}
    </Button>
  );
});

export default (OptionButton: React$AbstractComponent<Props, any>);
