// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const OPEN_QUESTION_RESULT_FRAGMENT: DocumentNode = gql`
  fragment OpenQuestionResultFragment on OpenQuestion {
    text
    sampleSolution {
      text
      explanation
    }
    examAttemptSolution(slug: $slug, attemptId: $attemptId) {
      text
    }
  }
`;
