// @flow
import React from 'react';
import { useStack } from 'react-stacks';
import { Alert } from 'components/stacks';

type AlertProps = {
  color: 'success' | 'danger' | 'warning' | 'info',
  title?: ?string,
  content: React$Node,
  autoDismiss?: number,
};

export default function useAlerts(): Object {
  const stack = useStack('alerts');

  return {
    add({ autoDismiss, ...alertProps }: AlertProps) {
      function AlertWrapper(props) {
        return <Alert {...alertProps} {...props} />;
      }

      stack.push(AlertWrapper, { autoDismiss });
    },
  };
}
