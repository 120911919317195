// @flow
import React from 'react';
import { StyleSheet, css, colorContrast } from 'libs/ui';
import { Badge as BaseBadge } from 'bootstrap-rn';

type Props = {
  children: React$Node,
  color?: string,
  style?: any,
  textStyle?: any,
};

const styles = StyleSheet.create({
  primary: css`
    background-color: $primary;
  `,
  secondary: css`
    background-color: $secondary;
  `,
  success: css`
    background-color: $success;
  `,
  danger: css`
    background-color: $danger;
  `,
  warning: css`
    background-color: $warning;
  `,
  info: css`
    background-color: $info;
  `,
  light: css`
    background-color: $transparent-100;
  `,
  dark: css`
    background-color: $dark;
  `,
  text: css`
    // Workaround for native fonts
    @include platform(ios) {
      font-family: 'Roboto Medium';
    }
    @include platform(android) {
      font-family: 'Roboto Medium';
    }
  `,
  primaryText: css`
    color: ${colorContrast((t) => t.primary)};
  `,
  secondaryText: css`
    color: ${colorContrast((t) => t.secondary)};
  `,
  successText: css`
    color: ${colorContrast((t) => t.success)};
  `,
  dangerText: css`
    color: ${colorContrast((t) => t.danger)};
  `,
  warningText: css`
    color: ${colorContrast((t) => t.warning)};
  `,
  infoText: css`
    color: ${colorContrast((t) => t.info)};
  `,
  lightText: css`
    color: ${colorContrast((t) => t.light)};
  `,
  darkText: css`
    color: ${colorContrast((t) => t.dark)};
  `,
});

const Badge = React.forwardRef<Props, any>((props, ref) => {
  const { children, color = 'primary', style, textStyle, ...elementProps } = props;

  return (
    <BaseBadge
      {...elementProps}
      ref={ref}
      style={[styles[color], style]}
      textStyle={[styles.text, styles[`${color}Text`], textStyle]}
    >
      {children}
    </BaseBadge>
  );
});

Badge.displayName = 'Badge';

export default (Badge: React$AbstractComponent<Props, any>);
