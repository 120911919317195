// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query, selectByRoot } from 'libs/graphql';

const MonetizationKeys = {
  name: 'MonetizationKeys',
  bundle: () => import('./MonetizationKeys'),
};

const ORGANIZATION_PRODUCT_DETAILS_KEYS_QUERY = gql`
  query OrganizationProductDetailsKeysQuery($slug: String!, $productSlug: String!) {
    organization(slug: $slug) {
      name
      slug
      product(productSlug: $productSlug) {
        slug
        name
        description
        licensesCount
        offers {
          keysCount
          purchasesCount(completed: true)
          duration
          reusable
          keys {
            key
            used
            createdAt
          }
          price {
            price {
              amount
              currency {
                code
              }
            }
          }
        }
      }
      viewerIsAdmin
      viewerIsTrainer
      viewerIsMember
    }
  }
`;

const Container = createAsyncContainer(MonetizationKeys, (props) => {
  const {
    params: { slug, productSlug },
  } = props;

  const variables = { slug, productSlug };

  const loaders = {
    query: query(ORGANIZATION_PRODUCT_DETAILS_KEYS_QUERY, { variables }),
  };

  const selectors = {
    organization: select(ORGANIZATION_PRODUCT_DETAILS_KEYS_QUERY, { variables }),
    viewer: selectByRoot('viewer'),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
