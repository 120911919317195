// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql, selectByRoot } from 'libs/graphql';
import { getVersion } from 'utils/courses';
import { SURVEY_QUESTION_OPEN_FRAGMENT } from './SurveyQuestionOpen/fragments';
import { SURVEY_QUESTION_SCALE_FRAGMENT } from './SurveyQuestionScale/fragments';
import { ACTIVITY_DETAILS_SIDEBAR_FRAGMENT } from '../_shared/ActivityDetailsSidebar/fragments';
import { ACTIVITY_DETAILS_TITLE_FRAGMENT } from '../_shared/ActivityDetailsTitle/fragments';

const SurveyDetails = {
  name: 'SurveyDetails',
  bundle: () => import('./SurveyDetails'),
};

const SURVEY_QUERY = gql`
  query CourseSurvey(
    $slug: String!
    $courseSlug: String!
    $versionNumber: Int
    $specificVersion: String
  ) {
    course(slug: $courseSlug) {
      categories {
        name
      }
      surveyActivity(slug: $slug) {
        ...ActivityDetailsTitleFragment
        ...ActivityDetailsSidebarFragment
        slug
        published
        version(versionNumber: $versionNumber, specificVersion: $specificVersion) {
          versionNumber
          releasedAt
          item {
            questionsCount
            questions {
              type {
                ...SurveyQuestionOpenFragment
                ...SurveyQuestionScaleFragment
              }
            }
            results {
              user {
                name
              }
            }
            endedAt
            viewerCanTakeSurvey
            viewerIsDone
          }
        }
        viewerCanUpdate
      }
      viewerIsAdmin
    }
  }

  ${SURVEY_QUESTION_OPEN_FRAGMENT}
  ${SURVEY_QUESTION_SCALE_FRAGMENT}
  ${ACTIVITY_DETAILS_TITLE_FRAGMENT}
  ${ACTIVITY_DETAILS_SIDEBAR_FRAGMENT}
`;

const Container = createAsyncContainer(SurveyDetails, (props) => {
  const {
    params: { slug, courseSlug },
    searchParams,
  } = props;

  const versionNumber = getVersion(searchParams.get('version'));
  const variables = {
    slug,
    courseSlug,
    versionNumber,
    specificVersion: versionNumber ? null : 'CURRENT',
  };

  const loaders = {
    query: query(SURVEY_QUERY, {
      variables,
    }),
  };

  const selectors = {
    course: select(SURVEY_QUERY, { variables }),
    viewer: selectByRoot('viewer', (viewer) => viewer.join('organizationMemberships')),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
