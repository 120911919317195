// @flow
import React, { useState } from 'react';
import { Platform } from 'react-native';
import { StyleSheet, css } from 'libs/ui';
import { Text } from 'components/core';
import ParagraphContext from './ParagraphContext';

type Props = {
  children: React$Element<any>[],
  align?: 'right' | 'left' | 'center',
  spacing: boolean,
};

const styles = StyleSheet.create({
  paragraph: css`
    width: 100%;
  `,
  spacing: css`
    margin-bottom: 1rem;
  `,
});

const isNative = Platform.OS !== 'web';

function Paragraph(props: Props): React$Node {
  const { children, align, spacing } = props;

  const [width, setWidth] = useState(null);

  // 1) Line height issue on Android & iOS
  // We set the line height to null on Android and iOS, because otherwise
  // images that have a greater height than the line height will be cropped.
  // Reference: https://github.com/facebook/react-native/issues/33903
  //
  // 2) Overflowing issue on Android
  // We determine the paragraph width in order to set these as a max width of
  // images, because "max-width: 100%" does not work correctly with an Image or
  // View component inside a Text component on Android.
  //
  // 3) White space issue in iOS
  // We determine the paragraph width in order to set these as a max width of
  // images, because otherwise it might happen that a white space is added to
  // the bottom of the image on iOS.
  const hasInlineImage = children.some(
    (child) => child.props.type === 'inlineMath' || child.props.type === 'image',
  );
  const deferRendering = isNative && hasInlineImage;

  const lineHeightStyle = deferRendering && { lineHeight: null };

  return (
    <Text
      style={[
        styles.paragraph,
        spacing && styles.spacing,
        lineHeightStyle,
        align && { textAlign: align },
      ]}
      onLayout={(event) => {
        if (!deferRendering) {
          return;
        }

        setWidth(event.nativeEvent.layout.width);
      }}
    >
      {deferRendering && width === null ? null : (
        <ParagraphContext.Provider value={{ width }}>{children}</ParagraphContext.Provider>
      )}
    </Text>
  );
}

export default Paragraph;
