// @flow

type Props = {
  children: (Entity<GQLUser>) => React$Node,
  user: Entity<GQLUser>,
};

function ProfileLoader({ children, user }: Props): React$Node {
  return children(user);
}

export default ProfileLoader;
