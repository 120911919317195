// @flow
import React from 'react';
import { createDict, Trans } from 'libs/intl';
import { View } from 'components/core';
import { Icon } from 'components/common';
import { StyleSheet, css, useMedia } from 'libs/ui';
import PieceOrdering from './PieceOrdering';
import SolutionDropdown from './SolutionDropdown';
import type { TaskExamControls } from '../../useExamControls';

type Props = {
  pairing: Entity<GQLPairing>,
  examAttemptId: Id,
  controls: TaskExamControls,
  viewerExamSolution: ?Entity<GQLPairingSolution>,
};

const dict = createDict({
  arrange_the_order: 'Arrange the order',
});

const styles = StyleSheet.create({
  dropdownAreaDesktop: css`
    min-width: 10rem;
    margin-left: 1rem;
  `,
  dropdownAreaMobile: css`
    justify-content: flex-end;
    align-items: flex-end;
  `,
  dividerMobile: css`
    border-bottom-width: 0.0625rem;
    border-bottom-color: $gray-200;
    margin-bottom: 0.5rem;
  `,
});

function SolutionFormOrdering({
  pairing,
  examAttemptId,
  controls,
  viewerExamSolution,
}: Props): React$Node {
  const media = useMedia();
  return (
    <>
      <View styleName="flex-column">
        <View styleName="flex-row my-md-3 my-2">
          <Icon glyph="list-numbered" color="muted" styleName="pr-2" />
          <Trans {...dict('arrange_the_order')} color="muted" />
        </View>
        {pairing.pieces.map((piece, key) => (
          <View key={piece.id} styleName="flex-md-row flex-shrink-1 mb-2 ai-md-start">
            <View styleName="flex-grow-1 flex-shrink-1">
              <PieceOrdering piece={piece} last={pairing.pieces.length - 1 === key} />
            </View>
            <View style={media.up('md') ? styles.dropdownAreaDesktop : styles.dropdownAreaMobile}>
              <SolutionDropdown
                piece={piece}
                pairing={pairing}
                examAttemptId={examAttemptId}
                controls={controls}
                viewerExamSolution={viewerExamSolution}
              />
            </View>
            {media.down('sm') && <View style={styles.dividerMobile} />}
          </View>
        ))}
      </View>
    </>
  );
}

export default SolutionFormOrdering;
