// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';
import DomainNav from './DomainNav';

const DOMAIN_SETTINGS_NAV_QUERY = gql`
  query OrganizationSettingsNavQuery($slug: String!) {
    organization(slug: $slug) {
      slug
      domain {
        allowsTrials
        allowsSubdomains
        inquiriesCount(pending: true)
        subDomainsCount
        usersCount
        organizationsCount(excludeDomainOwner: true)
        hasReportCollections
      }
    }
  }
`;

const options = {
  async: {
    loading: DomainNav,
    error: DomainNav,
  },
};

const Container = createAsyncContainer(
  DomainNav,
  (props) => {
    const {
      params: { slug },
    } = props;

    const variables = { slug };

    const loaders = {
      query: query(DOMAIN_SETTINGS_NAV_QUERY, { variables }),
    };

    const selectors = {
      organization: select(DOMAIN_SETTINGS_NAV_QUERY, { variables }),
    };

    return { loaders, selectors };
  },
  options,
);

export default (Container: React$AbstractComponent<any, mixed>);
