// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import { DOCUMENT_ACTIVITY_CAPTION_FILE_FRAGMENT } from './DocumentFile/fragments';

export const DOCUMENT_ACTIVITY_CAPTION_FRAGMENT: DocumentNode = gql`
  fragment DocumentActivityCaptionFragment on DocumentActivity {
    version(specificVersion: "CURRENT") {
      item {
        type {
          ...DocumentActivityCaptionFileFragment
        }
      }
    }
  }

  ${DOCUMENT_ACTIVITY_CAPTION_FILE_FRAGMENT}
`;
