// @flow
import React from 'react';
import { Page } from 'components/layout';
import { Outlet } from 'libs/routing';
import UserSettingsNav from './UserSettingsNav';

function UserSettingsLayout(): React$Node {
  return (
    <Page>
      <Page.Aside size={3} styleName="mx-auto mb-3 mb-md-0">
        <UserSettingsNav />
      </Page.Aside>
      <Page.Main size={9}>
        <Outlet />
      </Page.Main>
    </Page>
  );
}

export default UserSettingsLayout;
