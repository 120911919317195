// @flow
import React from 'react';
import { View } from 'components/core';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  source: {
    uri: string,
    type?: string,
  },
  controls?: boolean,
  poster?: string,
  aspectRatio?: number,
  style?: any,
  styleName?: ?string,
};

const styles = StyleSheet.create({
  container: css`
    flex-direction: row;
  `,
});

const makeWebUri = (uri, poster) => {
  if (poster || uri.includes('#t=')) {
    return uri;
  }

  return `${uri}#t=0.001`;
};

function Video({
  source,
  controls,
  poster,
  aspectRatio = 16 / 9,
  style,
  styleName,
}: Props): React$Node {
  /* eslint-disable jsx-a11y/media-has-caption */
  return (
    <View style={[styles.container, style]} styleName={styleName}>
      <video
        width="100%"
        preload="metadata"
        controls={controls}
        controlsList="nodownload"
        poster={poster}
        onContextMenu={(e) => e.preventDefault()}
        style={{ borderRadius: '0.25rem', aspectRatio }}
        key={source.uri}
      >
        <source type={source.type} src={makeWebUri(source.uri, poster)} key={source.uri} />
      </video>
    </View>
  );
  /* eslint-enable */
}

export default Video;
