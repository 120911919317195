// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { Blockquote as BaseBlockquote } from 'components/core';

type Props = {
  children: React$Node,
  spacing: boolean,
};

const styles = StyleSheet.create({
  spacing: css`
    margin-bottom: 1rem;
  `,
});

function Blockquote({ children, spacing }: Props): React$Node {
  return <BaseBlockquote style={spacing && styles.spacing}>{children}</BaseBlockquote>;
}

export default Blockquote;
