// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const ReportList = {
  name: 'ReportList',
  bundle: () => import('./ReportList'),
};

const REPORT_COLLECTION_QUERY = gql`
  query ReportCollectionQuery($collectionId: ClientID!) {
    reportCollection(collectionId: $collectionId) {
      trainee {
        name
      }
      type
      startDate
      endDate
      reportsStartDate
      reports {
        beginAt
        endAt
        completed
        approved
      }
    }
  }
`;

const Container = createAsyncContainer(ReportList, (props) => {
  const {
    params: { collectionId },
  } = props;

  const loaders = {
    query: query(REPORT_COLLECTION_QUERY, { variables: { collectionId } }),
  };

  const selectors = {
    reportCollection: select(REPORT_COLLECTION_QUERY, { variables: { collectionId } }),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
