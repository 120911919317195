// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { Card as BaseCard } from 'bootstrap-rn';
import CardHeader from './CardHeader';
import CardFooter from './CardFooter';

type Props = {
  children: React$Node,
  style?: any,
};

const styles = StyleSheet.create({
  card: css`
    @include media-breakpoint-down(xs) {
      border-radius: 0;
      border-left-width: 0;
      border-right-width: 0;
    }
  `,
});

const Card = React.forwardRef<Props, any>((props, ref) => {
  const { children, style, ...elementProps } = props;

  return (
    <BaseCard {...elementProps} ref={ref} style={[styles.card, style]}>
      {children}
    </BaseCard>
  );
});

Card.displayName = 'Card';

// $FlowFixMe[prop-missing]
Card.Header = CardHeader;
// $FlowFixMe[prop-missing]
Card.Body = BaseCard.Body;
// $FlowFixMe[prop-missing]
Card.Footer = CardFooter;

export default (Card: any);
