// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';
import { DOCUMENT_ACTIVITY_VERSION_ICON_FRAGMENT } from '../DocumentActivityVersionIcon/fragments';
import { ARTICLE_ACTIVITY_CAPTION_FRAGMENT } from './ArticleActivityCaption/fragments';
import { DOCUMENT_ACTIVITY_CAPTION_FRAGMENT } from './DocumentActivityCaption/fragments';
import { QUIZ_ACTIVITY_CAPTION_FRAGMENT } from './QuizActivityCaption/fragments';
import { SURVEY_ACTIVITY_CAPTION_FRAGMENT } from './SurveyActivityCaption/fragments';
import { EXAM_ACTIVITY_CAPTION_FRAGMENT } from './ExamActivityCaption/fragments';
import { VIDEO_ACTIVITY_CAPTION_FRAGMENT } from './VideoActivityCaption/fragments';
import { EXAM_ACTIONS_FRAGMENT } from '../ExamActions/fragments';
import { QUIZ_ACTIONS_FRAGMENT } from '../QuizActions/fragments';

export const ACTIVITIES_FRAGMENT: DocumentNode = gql`
  fragment ActivitiesFragment on Course {
    slug
    features {
      hasTilesView
    }
    categories {
      name
      expanded
    }
    activities(type: $activityType) {
      title
      published
      slug
      license
      category {
        name
      }
      viewerResult {
        versionNumber
      }
      versions {
        versionNumber
        current
        releasedAt
      }
      version(specificVersion: "CURRENT") {
        versionNumber
        current
        releasedAt
        ...DocumentActivityVersionIconFragment
      }
      author {
        name
      }
      file {
        name
        extension
        info {
          image {
            height
            width
          }
        }
      }
      ...ArticleActivityCaptionFragment
      ...DocumentActivityCaptionFragment
      ...QuizActivityCaptionFragment
      ...SurveyActivityCaptionFragment
      ...ExamActivityCaptionFragment
      ...VideoActivityCaptionFragment
      ...ExamActionsFragment
      ...QuizActionsFragment
      viewerCanUpdate
      viewerCanCopy
      viewerCanDelete
    }
    author {
      id
    }
    viewerIsMember
    viewerIsObserver
    viewerIsAdmin
  }

  ${DOCUMENT_ACTIVITY_VERSION_ICON_FRAGMENT}
  ${ARTICLE_ACTIVITY_CAPTION_FRAGMENT}
  ${DOCUMENT_ACTIVITY_CAPTION_FRAGMENT}
  ${QUIZ_ACTIVITY_CAPTION_FRAGMENT}
  ${SURVEY_ACTIVITY_CAPTION_FRAGMENT}
  ${EXAM_ACTIVITY_CAPTION_FRAGMENT}
  ${VIDEO_ACTIVITY_CAPTION_FRAGMENT}
  ${EXAM_ACTIONS_FRAGMENT}
  ${QUIZ_ACTIONS_FRAGMENT}
`;
