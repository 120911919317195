// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const SubdomainList = {
  name: 'SubdomainList',
  bundle: () => import('./SubdomainList'),
};

const DOMAIN_SUBDOMAINS_QUERY = gql`
  query DomainSubdomainsQuery($slug: String!) {
    organization(slug: $slug) {
      ownsDomain
      domain {
        allowsSubdomains
        subDomains {
          host
          state
          organizations {
            name
            proUntil
            trialUntil
          }
        }
      }
      viewerIsAdmin
    }
  }
`;

const Container = createAsyncContainer(SubdomainList, (props) => {
  const {
    params: { slug },
  } = props;

  const variables = { slug };

  const loaders = {
    query: query(DOMAIN_SUBDOMAINS_QUERY, { variables }),
  };

  const selectors = {
    organization: select(DOMAIN_SUBDOMAINS_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
