// @flow
import { gql } from 'libs/graphql';
import type { DocumentNode } from 'graphql';

export const DOCUMENT_FILE_ICON_FRAGMENT: DocumentNode = gql`
  fragment DocumentFileIconFragment on DocumentFile {
    file {
      info {
        mimeType
      }
    }
  }
`;
