// @flow
import React from 'react';
import { View, Image, Jumbotron, Button } from 'components/core';
import { Icon } from 'components/common';
import { BrandHeading, BrandLargeText } from 'components/pages';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { createDict, Trans } from 'libs/intl';

const styles = StyleSheet.create({
  jumbotron: css`
    background-color: #ebf4f9;
    align-items: center;
    padding-bottom: 10rem;
    margin-bottom: -6rem;
  `,
  jumbotronDesktop: css`
    margin-top: -2rem;
  `,
  jumbotronMobile: css`
    margin-top: -4rem;
  `,
  content: css`
    flex-shrink: 1;
  `,
  contentDesktop: css`
    margin: 2rem;
  `,
  heading: css`
    color: #001f3f;
    margin-bottom: 2rem;
  `,
  options: css`
    margin-top: 1.5rem;
  `,
  imageDesktop: css`
    width: 536px;
    height: 250px;
    margin-vertical: 4rem;
    margin-right: 2rem;
    margin-left: 4rem;
  `,
  imageMobile: css`
    width: 464px;
    height: 289px;
    margin-vertical: 2rem;
  `,
  feature: css`
    flex-direction: row;
    flex-shrink: 1;
    padding-top: 1rem;
  `,
  icon: css`
    margin-right: 1rem;
    font-weight: bold;
    color: #001f3f;
  `,
  largeBoldText: css`
    text-align: left;
    font-weight: bold;
  `,
  atAGlance: css`
    flex-grow: 1;
    flex-shrink: 1;
  `,
  atAGlanceDesktop: css`
    max-width: 48rem;
    align-items: flex-start;
    justify-content: flex-start;
  `,
  atAGlanceMobile: css`
    margin-vertical: 2rem;
    justify-content: center;
  `,
  color: css`
    color: #ff6186;
  `,
});

const dict = createDict({
  text: 'Accompany your apprentices online',
  caption: 'Benefit from exclusive features for training companies.',
  caption_aga: 'Now with special conditions for AGA members!',
  perfect_for_exam_preparation_and_continuous_learning:
    'Perfect for exam preparation and continuous learning',
  interactive_exams_videos_and_quizzes_at_work_and_on_the_go:
    'Interactive exams, videos and quizzes at work and on the go',
  no_set_up_costs_and_ready_to_get_started_in_a_few_hours:
    'No set up costs and ready to get started in a few hours',
  accompany_your_trainees_with_learning_analytics:
    'Accompany your trainees with learning analytics',
  tutoring_by_azubinet_team:
    'Professional support for your learners from our Azubinet team (for selected professions)',
  special_conditions_for_aga_members: 'Special conditions for AGA members',
  fourteen_day_trial_for_companies: '14-day trial for companies',
});

function HeroSection(): React$Node {
  const media = useMedia();

  return (
    <Jumbotron
      style={[styles.jumbotron, media.up('md') ? styles.jumbotronDesktop : styles.jumbotronMobile]}
    >
      <View styleName="jc-center ai-center flex-md-row">
        <View style={[styles.content, media.up('md') && styles.contentDesktop]}>
          <BrandHeading size={1} style={styles.heading}>
            <Trans {...dict('text')} />
          </BrandHeading>
          <BrandHeading size={5} style={styles.heading}>
            <Trans {...dict('caption')} /> <Trans {...dict('caption_aga')} />
          </BrandHeading>
          <View style={styles.options} styleName="flex-md-row ai-md-start">
            <Button color="success" to="/request-access" size="lg">
              <Trans {...dict('fourteen_day_trial_for_companies')} />
            </Button>
          </View>
        </View>
        {media.up('sm') && (
          <Image
            source="/img/home/online-test.png"
            style={media.up('md') ? styles.imageDesktop : styles.imageMobile}
          />
        )}
      </View>
      <View
        style={[
          styles.atAGlance,
          media.up('md') ? styles.atAGlanceDesktop : styles.atAGlanceMobile,
        ]}
      >
        <View style={styles.feature}>
          <Icon glyph="ok" style={styles.icon} />
          <BrandLargeText style={styles.largeBoldText}>
            <Trans {...dict('perfect_for_exam_preparation_and_continuous_learning')} />
          </BrandLargeText>
        </View>
        <View style={styles.feature}>
          <Icon glyph="ok" style={styles.icon} />
          <BrandLargeText style={styles.largeBoldText}>
            <Trans {...dict('interactive_exams_videos_and_quizzes_at_work_and_on_the_go')} />
          </BrandLargeText>
        </View>
        <View style={styles.feature}>
          <Icon glyph="ok" style={styles.icon} />
          <BrandLargeText style={styles.largeBoldText}>
            <Trans {...dict('no_set_up_costs_and_ready_to_get_started_in_a_few_hours')} />
          </BrandLargeText>
        </View>
        <View style={styles.feature}>
          <Icon glyph="ok" style={styles.icon} />
          <BrandLargeText style={styles.largeBoldText}>
            <Trans {...dict('accompany_your_trainees_with_learning_analytics')} />
          </BrandLargeText>
        </View>
        <View style={styles.feature}>
          <Icon glyph="ok" style={styles.icon} />
          <BrandLargeText style={styles.largeBoldText}>
            <Trans {...dict('tutoring_by_azubinet_team')} />
          </BrandLargeText>
        </View>
        <View style={styles.feature}>
          <Icon glyph="plus" style={[styles.icon, styles.color]} />
          <BrandLargeText style={[styles.largeBoldText, styles.color]}>
            <Trans {...dict('special_conditions_for_aga_members')} />
          </BrandLargeText>
        </View>
      </View>
    </Jumbotron>
  );
}

export default HeroSection;
