// @flow
import React from 'react';
import { View, Text, Image, Button } from 'components/core';
import { Video } from 'components/common';
import { StyleSheet, css, useMedia } from 'libs/ui';
import { Section, DevicesStripe, Spacing, BrandHeading } from 'components/pages';
import { createDict, Trans } from 'libs/intl';
import { useDocumentTitle, useToggleState } from 'libs/misc';
import References from 'views/(pages)/_shared/References';
import { Wrapper } from 'components/layout';
import Content from 'views/(pages)/business/Content';
import FeaturesSection from 'views/(pages)/business/FeaturesSection';
import TestimonialsBoxTrainers from 'views/(pages)/business/TestimonialsBoxTrainers';
import TestimonialsBoxTrainees from 'views/(pages)/_shared/AzubinetTestimonialsBoxTrainees';
import InfoActionBox from 'views/(pages)/business/InfoActionBox';
import PlansInfo from 'views/(pages)/business/PlansInfo';
import ContactSection from '../../_shared/ContactSection';
import HeroSection from './HeroSection';
import BauvistaProfessionsModal from './BauvistaProfessionsModal';

const styles = StyleSheet.create({
  logoBauvista: css`
    width: 288px;
    height: 74px;
    margin-top: 4rem;
  `,
  azubinetLogo: css`
    width: 250px;
    height: 42px;
  `,
  textAlign: css`
    text-align: center;
  `,
  presentationSectionDesktop: css`
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-shrink: 1;
  `,
  presentationSectionMobile: css`
    margin-top: -3rem;
  `,
  box: css`
    border-radius: 0.125rem;
    border-width: 0.125rem;
    border-color: $brand-primary;
    align-self: center;
    background-color: white;
  `,
  boxDesktop: css`
    max-width: 1028px;
  `,
  boxMobile: css`
    margin-horizontal: 1rem;
  `,
});

const dict = createDict({
  fourteen_day_trial_for_companies: '14-day trial for companies',
  view_professions: 'View professions',
});

function Comcave(): React$Node {
  useDocumentTitle('Bauvista');
  const media = useMedia();
  const modal = useToggleState(false);

  return (
    <>
      <Wrapper>
        <Section color="white">
          <View styleName="flex-md-row ai-end jc-center">
            <Image
              source="/img/organizations/bauvista/bauvista.png"
              label="Bauvista"
              style={styles.logoBauvista}
              styleName="mr-md-3 mb-2 mb-md-0"
            />
            <Image
              source="/img/azubinet.png"
              label="Azubinet"
              style={styles.azubinetLogo}
              styleName="ml-md-3 mb-md-3 mt-2 mt-md-0"
            />
          </View>
          <View styleName="pt-4 px-3 jc-center ai-center">
            <BrandHeading size={4} align="center">
              Willkommen auf Bauvista Azubinet - deiner Lernplattform für die Ausbildung
            </BrandHeading>
          </View>
          <View styleName="mt-4 ai-center">
            <Button to="/login" size="lg" color="dark">
              <Text>Anmelden</Text>
            </Button>
          </View>
          <Spacing />
          <Spacing />
          <HeroSection modal={modal} />
          <View
            style={
              media.up('md') ? styles.presentationSectionDesktop : styles.presentationSectionMobile
            }
          >
            <View style={[styles.box, media.up('md') ? styles.boxDesktop : styles.boxMobile]}>
              <Video
                source={{ uri: '/video/azubinet-fuer-betriebe.mp4' }}
                poster="/video/azubinet_fuer_betriebe.jpg"
                controls
              />
            </View>
          </View>
          <Spacing />
          <TestimonialsBoxTrainers />
          <Spacing />
          <Content />
          <View styleName="flex-md-row jc-md-center ai-md-center my-3 mx-3 mx-md-0">
            <Button color="success" to="/request-access" size="lg">
              <Trans {...dict('fourteen_day_trial_for_companies')} />
            </Button>
            <Button
              color="secondary"
              size="lg"
              styleName="ml-md-3 mt-2 mt-md-0"
              onPress={() => {
                modal.setOn();
              }}
            >
              <Trans {...dict('view_professions')} />
            </Button>
          </View>
          <Spacing />
        </Section>
        <Section color="gray">
          <FeaturesSection />
        </Section>
        <Section color="white" styleName="jc-center ai-center">
          <TestimonialsBoxTrainees />
          <Spacing />
          <InfoActionBox />
          <PlansInfo />
        </Section>
        <Section color="white">
          <ContactSection />
        </Section>
        <Section color="white">
          <References />
          <Spacing />
          <View styleName="pt-4 px-3 jc-center ai-center">
            <BrandHeading size={4} align="center">
              Schon registriert? Hier geht es direkt zur Anmeldung
            </BrandHeading>
          </View>
          <View styleName="mt-4 ai-center">
            <Button to="/login" size="lg" color="dark">
              <Text>Anmelden</Text>
            </Button>
          </View>
        </Section>
        <Section color="gray">
          <DevicesStripe noAction />
        </Section>
      </Wrapper>
      <BauvistaProfessionsModal modal={modal} />
    </>
  );
}

export default Comcave;
