// @flow
import React from 'react';
import { useDocumentTitle, Domain } from 'libs/misc';
import { Form } from 'components/forms';
import { useMutation } from 'libs/graphql';
import { createDict, Trans, useIntl } from 'libs/intl';
import { Text, Card, Link, Heading } from 'components/core';
import { registerUser } from './mutations';

type Props = {
  domain: Entity<GQLDomain>,
};

const dict = createDict({
  create_account: 'Create account',
  account_created: 'Account created',
  first_name: 'First Name',
  last_name: 'Last Name',
  company: 'Company',
  email: 'Email',
  password: 'Password',
  password_length: 'Password must be at least 8 characters long',
  password_retype: 'Retype password',
  register: 'Register',
  account_created_message:
    'Please check your emails to verify your email address. After the email is verified, you are able to log in.',
  already_have_an_account: 'Already have an account?',
  log_in: 'Log in',
  on_this_domain_you_cannot_register_on_your_own:
    'On this domain, you cannot register on your own.',
});

function Registration({ domain }: Props): React$Node {
  useDocumentTitle(dict('create_account'));

  const intl = useIntl();
  const mutation = useMutation();

  return (
    <Card>
      {!domain.permissions.usersCanSelfRegister && (
        <Card.Body>
          <Trans {...dict('on_this_domain_you_cannot_register_on_your_own')} />
        </Card.Body>
      )}
      {!mutation.ok && domain.permissions.usersCanSelfRegister && (
        <>
          <Card.Body>
            <Heading size={4} styleName="mx-auto mt-3 mb-4">
              <Trans {...dict('create_account')} />
            </Heading>
            <Form
              initialValues={{
                lastName: null,
                firstName: null,
                organization: null,
                email: null,
                password: null,
                password_confirmation: null,
              }}
              validate={{
                lastName: 'required|max:255',
                firstName: 'required|max:255',
                organization: 'max:255',
                email: 'required|max:255|email|unique:email',
                password: 'required|min:8|confirmed',
              }}
              onSubmit={async (values) => {
                await mutation.dispatch(
                  registerUser(
                    values.firstName,
                    values.lastName,
                    values.email,
                    values.organization,
                    values.password,
                  ),
                );
              }}
            >
              <Form.Input name="firstName" title={dict('first_name')} placeholder="Jane/John" />
              <Form.Input name="lastName" title={dict('last_name')} placeholder="Doe" />
              {Domain.is('www.azubinet.de') && (
                <Form.Input name="organization" title={dict('company')} />
              )}
              <Form.Input
                name="email"
                title={dict('email')}
                placeholder="doe@example.com"
                inputMode="email"
                autoCorrect={false}
                autoCapitalize="none"
              />
              <Form.Input
                name="password"
                title={dict('password')}
                info={dict('password_length')}
                autoComplete="new-password"
                secureTextEntry
              />
              <Form.Input
                name="password_confirmation"
                title={dict('password_retype')}
                autoComplete="new-password"
                secureTextEntry
              />
              <Text small styleName="mt-2 mb-1">
                {intl.locale === 'en' && (
                  <>
                    When finishing the registration process you accept the{' '}
                    <Link to="/terms-of-use">Terms of Use</Link> and the{' '}
                    <Link to="/privacy-policy">Privacy Policy</Link> regulations.
                  </>
                )}
                {intl.locale === 'de' && (
                  <>
                    Mit der Registrierung akzeptierst du die{' '}
                    <Link to="/terms-of-use">Nutzungsbedingungen</Link> und die{' '}
                    <Link to="/privacy-policy">Datenschutzbestimmungen</Link>.
                  </>
                )}
              </Text>
              <Form.Button size="lg" type="submit" styleName="mt-2">
                <Trans {...dict('register')} />
              </Form.Button>
            </Form>
          </Card.Body>
          <Card.Footer styleName="py-3 flex-row">
            <Trans styleName="mr-2" {...dict('already_have_an_account')} />
            <Link to="/login">
              <Trans {...dict('log_in')} />
            </Link>
          </Card.Footer>
        </>
      )}
      {mutation.ok && (
        <Card.Body>
          <Heading size={4} styleName="mx-auto mt-3 mb-4">
            <Trans {...dict('account_created')} />
          </Heading>
          <Trans {...dict('account_created_message')} />
        </Card.Body>
      )}
    </Card>
  );
}

export default Registration;
