// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, select, query } from 'libs/graphql';

const GradeList = {
  name: 'GradeList',
  bundle: () => import('./GradeList'),
};

const VIEWER_GRADE_QUERY = gql`
  query ViewerGradeQuery {
    viewer {
      grades {
        subject
        grade
      }
      createdAt
    }
  }
`;

const Container = createAsyncContainer(GradeList, () => {
  const loaders = {
    query: query(VIEWER_GRADE_QUERY),
  };

  const selectors = {
    viewer: select(VIEWER_GRADE_QUERY),
  };

  return {
    loaders,
    selectors,
  };
});

export default (Container: React$AbstractComponent<any, mixed>);
