// @flow
import { gql, createMutation } from 'libs/graphql';

const CREATE_COOKIE_CONSENT = gql`
  mutation CreateCookieConsent($input: CookieConsentCreateInput!) {
    cookieConsent_create(input: $input) {
      id
    }
  }
`;

export function createCookieConsent(consent: boolean): MutationAction {
  return createMutation(CREATE_COOKIE_CONSENT, {
    variables: {
      input: {
        consent,
      },
    },
  });
}
