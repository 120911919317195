// @flow
import React from 'react';
import { Platform } from 'react-native';
import { View } from 'bootstrap-rn';
import { Formik } from 'formik';
import { validateSchema } from 'libs/validation';
import FormButton from './FormButton';
import FormInput from './FormInput';
import FormPicker from './FormPicker';
import FormDatePicker from './FormDatePicker';
import FormChoice from './FormChoice';
import FormCheckbox from './FormCheckbox';
import FormFileInput from './FormFileInput';
import FormSearchableList from './FormSearchableList';

type ValidationRule =
  | string
  | ((attribute: string, value: any, fail: (message: string | Intlized$Message) => void) => void);

type Props = {
  children: ((props: any) => React$Node) | React$Node,
  initialValues: Object,
  validate?: ?{ [string]: string | ValidationRule[] },
  onSubmit: (Object, Object) => any,
};

const defaultProps = {
  validate: null,
};

function Form({
  children,
  initialValues,
  onSubmit: handleSubmit,
  validate,
  ...elementProps
}: Props): React$Node {
  const role = Platform.OS === 'web' ? 'form' : null;

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validate={validate ? validateSchema(validate) : undefined}
      onSubmit={handleSubmit}
    >
      {(form) => (
        <View {...elementProps} role={role}>
          {typeof children === 'function' ? children(form) : children}
        </View>
      )}
    </Formik>
  );
}

Form.defaultProps = defaultProps;

Form.Button = FormButton;
Form.Input = FormInput;
Form.Picker = FormPicker;
Form.DatePicker = FormDatePicker;
Form.Choice = FormChoice;
Form.Checkbox = FormCheckbox;
Form.FileInput = FormFileInput;
Form.SearchableList = FormSearchableList;

export default Form;
