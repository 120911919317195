// @flow
import { createAsyncContainer } from 'libs/container';
import { gql, query, select } from 'libs/graphql';
import { ATTACHMENTS_FRAGMENT } from 'views/(app)/courses.[courseSlug]/_shared/MediaGalleryModal';

const BlogDetails = {
  name: 'BlogDetails',
  bundle: () => import('./BlogDetails'),
};

const BLOG_DETAILS_QUERY = gql`
  query BlogDetailsQuery($slug: String!) {
    blog(slug: $slug) {
      title
      text
      slug
      createdAt
      viewerCanUpdate
      viewerCanDelete
      ...AttachmentsFragment
    }
  }

  ${ATTACHMENTS_FRAGMENT}
`;

const Container = createAsyncContainer(BlogDetails, (props) => {
  const {
    params: { slug },
  } = props;

  const variables = { slug };

  const loaders = {
    query: query(BLOG_DETAILS_QUERY, { variables }),
  };

  const selectors = {
    blog: select(BLOG_DETAILS_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
