// @flow
import { createAsyncContainer } from 'libs/container';

const PublishersAuthors = {
  name: 'PublishersAuthors',
  bundle: () => import('./PublishersAuthors'),
};

const Container = createAsyncContainer(PublishersAuthors, () => ({}));

export default (Container: React$AbstractComponent<any, mixed>);
