// @flow
import React from 'react';
import { createDict, Trans, DateTime } from 'libs/intl';
import { StyleSheet, css } from 'libs/ui';
import { Icon, OptionButton } from 'components/common';
import { Text, View, Nav, Badge } from 'components/core';

type Props = {
  viewer: Entity<GQLUser>,
  expanded: ToggleState,
  onSelect: (Entity<GQLChecklistResult>) => void,
};

const styles = StyleSheet.create({
  indented: css`
    padding-horizontal: 0.75rem;
  `,
  marker: css`
    background-color: $warning;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.25rem;
  `,
  dirIcon: css`
    width: 1rem;
    align-items: center;
  `,
});

const dict = createDict({
  checklists: 'Checklists',
  no_deadline: 'No deadline',
});

const isOverDue = (checklist, checklistResult) => {
  const now = new Date();
  const notComplete = checklistResult.todoResultsCount < checklist.todosCount;
  return checklistResult.doneUntil && notComplete
    ? new Date(checklistResult.doneUntil) <= now
    : false;
};

function MenuChecklists({ viewer, expanded, onSelect: handleSelect }: Props): React$Node {
  const { checklistResults } = viewer;

  if (checklistResults.length === 0) {
    return null;
  }

  return (
    <View styleName="mb-3">
      <OptionButton onPress={expanded.toggle} styleName="jc-start">
        <View style={styles.dirIcon}>
          {expanded.on ? <Icon glyph="down-dir" /> : <Icon glyph="right-dir" />}
        </View>
        <Trans {...dict('checklists')} bold styleName="ml-2" />
        <Badge color="light" styleName="ml-1">
          <Text>{checklistResults.length}</Text>
        </Badge>
      </OptionButton>
      {expanded.on && (
        <Nav variant="pills" styleName="flex-column">
          {checklistResults
            .sort((left, right) => {
              const titleLeft = left.checklist.name.toLowerCase();
              const titleRight = right.checklist.name.toLowerCase();
              if (titleLeft < titleRight) {
                return -1;
              }
              if (titleLeft > titleRight) {
                return 1;
              }
              return 0;
            })
            .map((result) => (
              <Nav.Link
                onPress={() => {
                  handleSelect(result);
                }}
                style={styles.indented}
                key={result.id}
              >
                <View styleName="flex-grow-1 flex-shrink-1">
                  <Text numberOfLines={1}>{result.checklist.name}</Text>
                  <Text color={isOverDue(result.checklist, result) ? 'danger' : 'muted'} small>
                    {result.doneUntil ? (
                      <DateTime value={result.doneUntil} />
                    ) : (
                      <Trans {...dict('no_deadline')} />
                    )}
                  </Text>
                </View>
                <View styleName="flex-row ai-center ml-2">
                  {result.todoResultsCount < result.checklist.todosCount && (
                    <View style={styles.marker} styleName="mr-2" />
                  )}
                  <Text small>
                    {result.todoResultsCount}/{result.checklist.todosCount}
                  </Text>
                  <Icon glyph="right-circled" color="muted" styleName="ml-2" />
                </View>
              </Nav.Link>
            ))}
        </Nav>
      )}
    </View>
  );
}

export default MenuChecklists;
