// @flow
import { createAsyncContainer } from 'libs/container';
import { query, select, gql } from 'libs/graphql';

const StatsViewer = {
  name: 'StatsViewer',
  bundle: () => import('./StatsViewer'),
};

const COURSE_SETTINGS_QUERY = gql`
  query CourseQuery($courseSlug: String!) {
    course(slug: $courseSlug) {
      name
      viewerIsAdmin
      caption
      description
      examActivities {
        slug
        title
        versions {
          item {
            exam {
              viewerAttempts {
                createdAt
                completedAt
                number
                result {
                  points
                  evaluatedPoints
                }
                user {
                  name
                }
              }
              points
              selfEvaluable
            }
          }
        }
      }
      quizActivities {
        title
        slug
        versions {
          item {
            totalBoxes
            viewerResults {
              createdAt
              number
              correctCount
              wrongCount
              progress
              user {
                id
              }
            }
          }
        }
      }
    }
  }
`;

const Container = createAsyncContainer(StatsViewer, (props) => {
  const {
    params: { courseSlug },
  } = props;

  const variables = { courseSlug };

  const loaders = {
    query: query(COURSE_SETTINGS_QUERY, {
      variables,
    }),
  };

  const selectors = {
    course: select(COURSE_SETTINGS_QUERY, { variables }),
  };

  return { loaders, selectors };
});

export default (Container: React$AbstractComponent<any, mixed>);
