// @flow
import React from 'react';
import { StyleSheet, css } from 'libs/ui';
import { View } from 'components/core';

/* eslint-disable react/require-default-props */
type Props = {
  children: React$Node,
  style?: any,
};
/* eslint-enable */

const styles = StyleSheet.create({
  paper: css`
    background-color: #fff;
    padding: 1rem;
    border-color: $gray-300;
    border-width: 1px 0 1px 0;

    @include media-breakpoint-up(sm) {
      border-width: 1px 1px 1px 1px;
      border-radius: 0.5rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 5rem;
    }
  `,
  // Workaround for https://github.com/facebook/react-native/issues/15826
  paperWorkaround: css`
    background-color: #fff;
  `,
});

const Paper = React.forwardRef<Props, any>((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { children, style, ...elementProps } = props;

  return (
    <View {...elementProps} ref={ref} style={[styles.paper, style]}>
      <View style={styles.paperWorkaround}>{children}</View>
    </View>
  );
});

export default (Paper: React$AbstractComponent<Props, any>);
