// @flow
import React from 'react';
import { View, Link, Alert } from 'components/core';
import { createDict, Trans } from 'libs/intl';
import { Divider } from 'components/common';
import { Form } from 'components/forms';
import { useUpload } from 'libs/filesystem';
import { useDispatch } from 'libs/graphql';
import { useToggleState } from 'libs/misc';
import { createFileAttachment } from './mutations';

type Props = {
  attachable: Entity<GQLArticle | GQLDocument | GQLSurvey | GQLQuiz | GQLTaskCollection | GQLVideo>,
};

const dict = createDict({
  file: 'File',
  choose_file_dots: 'Choose file...',
  upload: 'Upload',
  file_uploaded_successfully: 'File uploaded successfully.',
  upload_another_file: 'Upload another file',
});

function MediaGalleryUpload({ attachable }: Props): React$Node {
  const dispatch = useDispatch();
  const [handleUpload] = useUpload();
  const showForm = useToggleState(true);

  return (
    <>
      {showForm.on ? (
        <View>
          <Form
            initialValues={{ file: null }}
            validate={{ file: 'required' }}
            onSubmit={async (values) => {
              const fileId = await handleUpload(values.file);

              await dispatch(createFileAttachment(fileId, attachable.id, attachable.__typename));

              showForm.setOff();
            }}
            styleName="flex-md-row ai-md-center"
          >
            <View styleName="flex-grow-1">
              <Form.FileInput
                name="file"
                niceName={dict('file')}
                label={dict('choose_file_dots')}
                accept={['image/jpeg', 'image/png']}
                styleName="mb-0"
              />
            </View>
            <Form.Button
              color="success"
              type="submit"
              styleName="mt-3 mt-md-0 ml-md-3 as-end as-md-center"
            >
              <Trans {...dict('upload')} />
            </Form.Button>
          </Form>
        </View>
      ) : (
        <Alert color="success" styleName="mb-0 ai-start">
          <Trans {...dict('file_uploaded_successfully')} />
          <Link
            onPress={() => {
              showForm.setOn();
            }}
            styleName="mt-2"
          >
            <Trans {...dict('upload_another_file')} />
          </Link>
        </Alert>
      )}
      <Divider />
    </>
  );
}

export default MediaGalleryUpload;
