// @flow
import React, { useEffect, useState } from 'react';
import { useFixedElement } from 'bootstrap-rn';
import { StyleSheet, css } from 'libs/ui';
import { Overlay } from 'libs/overlays';
import { Container, Grid, View } from 'components/core';
import { useMenu } from 'libs/foundation';
import { useToggleState } from 'libs/misc';

type Props = {
  children: React$Node,
  size?: number,
  center?: boolean,
  end?: boolean,
};

const styles = StyleSheet.create({
  container: css`
    bottom: 0;
    width: 100%;
    z-index: 1030;
  `,
  inner: css`
    position: absolute;
    // We need to add left: 0, because otherwise there is a conflict with offcanvas on web.
    left: 0;
    bottom: 0;
    width: 100%;
  `,
  innerWithMenu: css`
    @include media-breakpoint-up(xxl) {
      padding-left: 305px;
    }
  `,
});

const getPosition = (center, end) => {
  if (center) {
    return 'mx-auto';
  }

  if (end) {
    return 'ml-auto';
  }

  return undefined;
};

function Controls({ children, size = 4, center = false, end = false }: Props): React$Node {
  const [mounted, setMounted] = useState(false);
  const menu = useMenu();
  const expanded = useToggleState(menu.expanded);
  const ref = useFixedElement();

  useEffect(() => {
    const unsubscribe = menu.subscribe((value) => {
      expanded.set(value);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <Overlay style={[styles.container]}>
      <View ref={ref} style={[styles.inner, expanded.on && styles.innerWithMenu]}>
        <Container>
          <Grid>
            <Grid.Box size={12} sizeMd={size} styleName={getPosition(center, end)}>
              <View>{children}</View>
            </Grid.Box>
          </Grid>
        </Container>
      </View>
    </Overlay>
  );
}

export default Controls;
