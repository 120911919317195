// @flow
import React from 'react';
import { createDict, DateTime, Trans } from 'libs/intl';
import { StyleSheet, css } from 'libs/ui';
import { Modal, CloseButton, ListGroup, Text, View } from 'components/core';
import { Icon } from 'components/common';

type Props = {|
  modal: ToggleState,
  course: Entity<GQLCourse>,
|};

const dict = createDict({
  related_checklists: 'Related checklists',
  no_deadline: 'No deadline',
  x_todos: '{count} {count, plural, =1 {todo} other {todos}}',
});

const styles = StyleSheet.create({
  marker: css`
    background-color: $warning;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.25rem;
  `,
});

const isOverDue = (checklist, checklistResult) => {
  if (!checklistResult) {
    return null;
  }

  const now = new Date();
  const notComplete = checklistResult.todoResultsCount < checklist.todosCount;
  return checklistResult.doneUntil && notComplete
    ? new Date(checklistResult.doneUntil) <= now
    : false;
};

function CourseChecklistsModal({ modal, course }: Props): React$Node {
  return (
    <Modal visible={modal.on} onToggle={modal.toggle}>
      <Modal.Header styleName="flex-row">
        <Modal.Title>
          <Icon styleName="mr-2" glyph="checklist" />
          <Text>
            <Trans {...dict('related_checklists')} /> ({course.relatedChecklists.length})
          </Text>
        </Modal.Title>
        <CloseButton onPress={modal.toggle} />
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          {course.relatedChecklists.map((checklist) => {
            const isOverDueValue = isOverDue(checklist, checklist.viewerResult);

            return (
              <ListGroup.ItemAction
                to={`/checklists/${checklist.slug}`}
                styleName="flex-row ai-center"
                key={checklist.id}
              >
                <View styleName="flex-grow-1 flex-shrink-1">
                  <Text>{checklist.name}</Text>
                  {checklist.viewerResult ? (
                    <Text small>
                      {checklist.viewerResult.doneUntil ? (
                        <Text color={isOverDueValue ? 'danger' : undefined}>
                          <Icon glyph="clock" />{' '}
                          <DateTime value={checklist.viewerResult.doneUntil} />
                        </Text>
                      ) : (
                        <Trans {...dict('no_deadline')} color="muted" />
                      )}
                    </Text>
                  ) : (
                    <Trans
                      {...dict('x_todos', {
                        count: checklist.todosCount,
                      })}
                      color="muted"
                      small
                    />
                  )}
                </View>
                {checklist.viewerResult && (
                  <View styleName="flex-row ai-center ml-2">
                    {checklist.viewerResult.todoResultsCount < checklist.todosCount && (
                      <View style={styles.marker} styleName="mr-2" />
                    )}
                    <Text>
                      {checklist.viewerResult.todoResultsCount}/{checklist.todosCount}
                    </Text>
                  </View>
                )}
              </ListGroup.ItemAction>
            );
          })}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
}

export default CourseChecklistsModal;
