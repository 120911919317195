// @flow
import React from 'react';
import { View } from 'bootstrap-rn';
import { StyleSheet, css } from 'libs/ui';

type Props = {
  style?: any,
};

const styles = StyleSheet.create({
  content: css`
    flex-grow: 1;
    flex-shrink: 1;
  `,
});

const NavbarContent = React.forwardRef<Props, any>(({ style, ...props }, ref) => {
  return <View {...props} ref={ref} style={[styles.content, style]} />;
});

export default (NavbarContent: React$AbstractComponent<Props, any>);
